import { useEffect, useMemo, useState } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import { updatePlatFormCostAPI } from '../../services/customers';
import { toast } from 'react-toastify';
import Button from '../widgets/button';

const Table = ({ columns: userColumns, data }: any) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }: any = useTable<any>(
    {
      columns: userColumns,
      data,
    },
    useFlexLayout
  );

  const generateRow = (rows: any) => {
    const row: any = rows;
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell: any, i: any) => {
          return (
            <td
              {...cell.getCellProps()}
              key={cell?.column?.id}
              className={`${cell?.column?.id} text-truncate`}
              title={cell.value}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      <div className='table-wrap mt-0'>
        <div {...getTableProps()} className='table mb-0'>
          <div className='table-header'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps()} className='th' key={column.id}>
                    {column.render('Header')}
                  </th>
                ))}
              </div>
            ))}
          </div>
          <div className='table-body' {...getTableBodyProps()}>
            {rows.map(generateRow)}
          </div>
        </div>
      </div>
    </>
  );
};

const PlatformCost = (props: any) => {
  const [updateButton, setUpdateButton] = useState(false);
  const data = props.rows;
  const button = props.button;

  const [newdata, setNewData] = useState<any>(() => data);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState('');
  const [validationErrorStatus, setValidationErrorStatus] = useState(false);
  const [vastErrorMsg, sVastErrorMsg] = useState('');
  const [SSAIErrorMsg, sSSAIErrorMsg] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [borderVast, setBorderVast] = useState(false);
  const [borderSSAI, setBorderSSAI] = useState(false);
  const [borderTo, setBorderTo] = useState(false);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Tier',
        accessor: 'tier',
        disableSortBy: false,
      },
      {
        Header: 'Monthly Video Impressions',
        columns: [
          {
            Header: 'From',
            accessor: 'lower_range',
            disableSortBy: false,
            Cell: ({ value, row }: any) => (
              <>
                <input
                  className='form-control table-input disable-field'
                  type='number'
                  value={value}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const newData = [...data];
                    newData[row.index].lower_range = newValue;
                    setNewData(newData);
                  }}
                  readOnly // Add readOnly attribute here
                />
              </>
            ),
          },
          {
            Header: 'To',
            accessor: 'upper_range',
            disableSortBy: false,
            Cell: ({ value, row }: any) => (
              <>
                <input
                  className={`form-control table-input 
                  ${row.index === 0 && isNaN(value) ? 'error' : ''}
                  ${row.index === 1 && isNaN(value) ? 'error' : ''}
                  ${row.index === 2 && isNaN(value) ? 'error' : ''}
                  ${row.index === 3 ? 'disable-field' : ''}
                  ${row.index === 0 && value === newdata[0]?.lower_range ? 'error' : ''}
                  ${row.index === 1 && value === newdata[1]?.lower_range ? 'error' : ''}
                  ${row.index === 2 && value === newdata[2]?.lower_range ? 'error' : ''}
                  ${row.index === 1 && value < newdata[0]?.upper_range ? 'error' : ''}
                  ${row.index === 2 && value < newdata[1]?.upper_range ? 'error' : ''}
                `}
                  type='number'
                  value={value}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    const newData = [...data];
                    newData[row.index].upper_range = newValue;
                    if (row.index < data.length - 1) {
                      newData[row.index + 1].lower_range = newValue + 1;
                    }
                    setNewData(newData);

                    // Calculate the condition for setting the border
                    const shouldSetBorderTo =
                      (row.index === 0 && isNaN(newValue)) ||
                      (row.index === 1 && isNaN(newValue)) ||
                      (row.index === 2 && isNaN(newValue)) ||
                      (row.index === 0 && newValue === newdata[0]?.lower_range) ||
                      (row.index === 1 && newValue === newdata[1]?.lower_range) ||
                      (row.index === 2 && newValue === newdata[2]?.lower_range) ||
                      (row.index === 1 && newValue < newdata[0]?.upper_range) ||
                      (row.index === 2 && newValue < newdata[1]?.upper_range) ||
                      (row.index === 3 && newValue < newdata[2]?.upper_range);

                    // Update the shouldSetBorder state
                    setBorderTo(shouldSetBorderTo);

                    // Set the border based on the condition
                    if (shouldSetBorderTo) {
                      e.target.classList.add('error'); // Apply the 'error' class
                    } else {
                      e.target.classList.remove('error'); // Remove the 'error' class
                    }
                  }}
                  readOnly={!updateButton}
                />
              </>
            ),
          },
        ],
      },
      {
        Header: 'CPM Cost For VAST',
        accessor: 'ad_price',
        disableSortBy: false,
        Cell: ({ value, row }: any) => (
          <>
            <span className={updateButton ? 'dollar-icon me-1' : 'dollar-icon'}>$</span>
            <input
              className={`form-control table-input 
                ${row.index === 0 && value.length === 0 ? 'error' : ''}
                ${row.index === 1 && value.length === 0 ? 'error' : ''}
                ${row.index === 2 && value.length === 0 ? 'error' : ''}
                ${row.index === 3 && value.length === 0 ? 'error' : ''}
              `}
              type='number'
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
                const newData = [...data];
                newData[row.index].ad_price = newValue;
                setNewData(newData);

                // Calculate the condition for setting the border
                const shouldSetBorderVAST =
                  (row.index === 0 && newValue.length === 0) ||
                  (row.index === 1 && newValue.length === 0) ||
                  (row.index === 2 && newValue.length === 0) ||
                  (row.index === 3 && newValue.length === 0);

                // Update the shouldSetBorder state
                setBorderVast(shouldSetBorderVAST);

                // Set the border based on the condition
                if (shouldSetBorderVAST) {
                  e.target.classList.add('error'); // Apply the 'error' class
                } else {
                  e.target.classList.remove('error'); // Remove the 'error' class
                }
              }}
              readOnly={!updateButton}
            />
          </>
        ),
      },
      {
        Header: 'CPM Cost For SSAI',
        accessor: 'ssai_price',
        disableSortBy: false,
        Cell: ({ value, row }: any) => (
          <>
            <span className={updateButton ? 'dollar-icon me-1' : 'dollar-icon'}>$</span>
            <input
              className={`form-control table-input 
                ${row.index === 0 && value.length === 0 ? 'error' : ''}
                ${row.index === 1 && value.length === 0 ? 'error' : ''}
                ${row.index === 2 && value.length === 0 ? 'error' : ''}
                ${row.index === 3 && value.length === 0 ? 'error' : ''}
              `}
              type='number'
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
                const newData = [...data];
                newData[row.index].ssai_price = newValue;
                setNewData(newData);

                // Calculate the condition for setting the border
                const shouldSetBorderVAST =
                  (row.index === 0 && newValue.length === 0) ||
                  (row.index === 1 && newValue.length === 0) ||
                  (row.index === 2 && newValue.length === 0) ||
                  (row.index === 3 && newValue.length === 0);

                // Update the shouldSetBorder state
                setBorderSSAI(shouldSetBorderVAST);

                // Set the border based on the condition
                if (shouldSetBorderVAST) {
                  e.target.classList.add('error'); // Apply the 'error' class
                } else {
                  e.target.classList.remove('error'); // Remove the 'error' class
                }
              }}
              readOnly={!updateButton}
            />
          </>
        ),
      },
    ],
    [data, setNewData, updateButton]
  );

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setNewData((old: any) =>
      old.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const resetData = () => {
    setUpdateButton(false);
    setValidationErrorMsg('');
    sVastErrorMsg('');
    sSSAIErrorMsg('');
    setBorderTo(false);
    setBorderVast(false);
    setBorderSSAI(false);
    props.updateData(updateButton);
  };

  // Update Platform cost
  const initiateUpdateTableData = () => {
    setUpdateButton(true);
    const payload = {
      data: newdata,
    };
    setIsUpdating(true);
    updatePlatFormCostAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          props.updateData(updateButton);
          toast.success(data.message);
          setUpdateButton(false);
        }
        setTimeout(() => {
          setIsUpdating(false);
        }, 300);
      })
      .catch((error: any) => {
        //toast.error(glossary.dsp_update_rtb_faild_server_msg);
      });
  };

  const initiateEdit = () => {
    setUpdateButton(true);
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex justify-content-between'>
            <h3>{props.title}</h3>
            {button && (
              <div>
                {updateButton && (
                  <button className='button button-secondary me-3' onClick={resetData}>
                    Cancel
                  </button>
                )}
                {updateButton ? (
                  <>
                    {isUpdating ? (
                      <Button text={'Updating...'} kind={'spinner'} type={'button'} />
                    ) : (
                      <button
                        className={
                          borderTo || borderSSAI || borderVast
                            ? 'button button-disabled'
                            : 'button button-primary'
                        }
                        onClick={initiateUpdateTableData}
                      >
                        Update
                      </button>
                    )}
                  </>
                ) : (
                  <button className='button button-primary' onClick={initiateEdit}>
                    Edit
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={updateButton ? 'editable' : 'editable-disbale'}>
        <Table
          columns={columns}
          data={newdata}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
        />
        {borderTo && updateButton && (
          <div className='mt-3 small text-danger'>
            Please enter valid values. The column(&quot;To&quot;) of each row must be greater than
            the column(&quot;From&quot;) of the next row.
          </div>
        )}
      </div>
    </>
  );
};

export default PlatformCost;
