import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
//import validator from 'validator';
import store from '../../../states';
//import Input from '../../widgets/input';
import Button from '../../widgets/button';
import { updateCustomerAPI } from '../../../services/customers';
import PaymentTerms from '../customerDetails/payment-terms';
import FormikController from '../../comman/form/FormikController';

interface Props {
  customerDetails?: any;
  customers?: any;
}

// interface CustomerForm {
//     marginValue: string;
//     upperMargin: string;
//     lowerMargin: string;
//   }

//   const customerFormInitialValue = {
//     marginValue: '',
//     upperMargin: '',
//     lowerMargin: '',
//   };

//   const customerFormInitialErrors = {
//     marginValue: '',
//     upperMargin: '',
//     lowerMargin: '',
//   };

export default (props: Props) => {
  const { customerDetails, customers } = props;

  const initialValuesDSP = {
    minFloor: customerDetails.lower_margin ?? '',
    maxFloor: customerDetails.upper_margin ?? '',
  };

  const initialValuesSSP = {
    marginValue: customerDetails.margin_value ?? '',
  };

  // const [earning, sEarning] = useState('1');
  // const [marginType, sMarginType] = useState('1');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // const [motivationPercent, sMotivationPercent] = useState('0');
  // const [profitSharePercent, sProfitSharePercent] = useState(0);
  // const [marginValue, sMarginValue] = useState(customerDetails.margin_value ?? '0');
  // const [upperMargin, sUpperMargin] = useState('');
  // const [lowerMargin, sLowerMargin] = useState('');
  const [on_change, sOn_Change] = useState(false);
  const [isEarningUpdating, setIsEarningUpdating] = useState(false);
  // const [paymentTermsValue, setPaymentTermsValue] = useState('');
  // const [customerForm, setCustomerForm] = useState<CustomerForm>(customerFormInitialValue);
  // const [errors, setErrors] = useState<CustomerForm>(customerFormInitialErrors);

  // const updateCustomerValues = (key: string, value: string) => {
  //   sOn_Change(true);
  //   if (key === 'marginValue') sMarginValue(value);
  //   if (key === 'upperMargin') sUpperMargin(value);
  //   if (key === 'lowerMargin') sLowerMargin(value);

  //   setErrors(customerFormInitialErrors);
  //   const newCustomerForm = { ...customerForm };
  //   newCustomerForm[key as keyof typeof customerFormInitialValue] = value;
  //   setCustomerForm(newCustomerForm);
  // };

  // const decimalVal = (str: any) => {
  //     return !str.match(/^\d+(\.\d+)?$/);
  //   };

  //   const spaceNotAllow = (str: any) => {
  //     return str === null || str.match(/^ *$/) !== null;
  //   };

  //   const highestValue = (val: any) => {
  //     return val > 100;
  //   };

  //   const comapareMinMax = (lower: any, upper: any) => {
  //     return lower > upper;
  //   };

  // const validateCustomerForm = () => {
  //     let valid = true;
  //     const newErrors = { ...errors };
  //     if (customerDetails.customer_type !== 'demand') {
  //       if (spaceNotAllow(customerForm.marginValue)) {
  //         valid = false;
  //         newErrors['marginValue'] = 'Space not allow';
  //       }
  //       if (highestValue(customerForm.marginValue)) {
  //         valid = false;
  //         newErrors['marginValue'] = 'Not allow more than 100';
  //       }
  //       if (!validator.isNumeric(customerForm.marginValue) || decimalVal(customerForm.marginValue)) {
  //         valid = false;
  //         newErrors['marginValue'] = 'Enter valid margin value';
  //       }
  //       if (validator.isEmpty(customerForm.marginValue)) {
  //         valid = false;
  //         newErrors['marginValue'] = 'Enter margin value';
  //       }
  //     } else {
  //       if (!validator.isNumeric(customerForm.upperMargin)) {
  //         valid = false;
  //         newErrors['upperMargin'] = 'Valid Margin value';
  //       }
  //       if (!validator.isNumeric(customerForm.lowerMargin)) {
  //         valid = false;
  //         newErrors['lowerMargin'] = 'Valid Margin value';
  //       }
  //       if (validator.isEmpty(customerForm.lowerMargin)) {
  //         valid = false;
  //         newErrors['lowerMargin'] = 'Enter Min Floor Price';
  //       }
  //       if (validator.isEmpty(customerForm.upperMargin)) {
  //         valid = false;
  //         newErrors['upperMargin'] = 'Enter Max Floor Price';
  //       }
  //       if (comapareMinMax(customerForm.lowerMargin, customerForm.upperMargin)) {
  //         valid = false;
  //         newErrors['upperMargin'] = glossary.admin_dsp_cus_detail_tab_min_max_compare_error;
  //       }
  //     }
  //     if (valid) {
  //       return true;
  //     } else {
  //       setErrors(newErrors);
  //       return false;
  //     }
  //   };

  // const updateEarning = () => {
  //     if (validateCustomerForm()) {
  //       let earning_type = '';
  //       if (earning === '1') earning_type = 'flat';
  //       if (earning === '2') earning_type = 'motivation';
  //       if (earning === '3') earning_type = 'profit_sharing';
  //       let payload = {};
  //       if (customerDetails.customer_type === 'demand') {
  //         payload = {
  //           customer_id: customerDetails.id,
  //           upper_margin: parseFloat(customerForm.upperMargin),
  //           lower_margin: parseFloat(customerForm.lowerMargin),
  //         };
  //       } else {
  //         payload = {
  //           earning_type,
  //           customer_id: customerDetails.id,
  //           margin_type_percent: marginType === '1' ? 'yes' : 'no',
  //           margin_value: parseFloat(customerForm.marginValue),
  //           motivation_percent: motivationPercent,
  //           profit_share_percent: profitSharePercent,
  //         };
  //       }
  //       setIsEarningUpdating(true);
  //       updateCustomerAPI(payload)
  //         .then((data: any) => {
  //           sOn_Change(false);
  //           if (data.error === 400 && data.message.margin_value) {
  //             toast.error(data.message.margin_value);
  //           }
  //           if (!data.status) {
  //             toast.error(data.msg);
  //           } else {
  //             toast.success(glossary.admin_cus_detail_tab_earning_update_success_msg);
  //             const customer = customers?.find((x: any) => x?.id === customerDetails?.id) ?? null;
  //             if (customer) {
  //               customer.upper_margin = upperMargin;
  //               customer.lower_margin = lowerMargin;
  //               customer.margin_type_percent = marginType === '1' ? 'yes' : 'no';
  //               customer.margin_value = marginValue;
  //               customer.motivation_percent = motivationPercent;
  //               customer.profit_share_percent = profitSharePercent;
  //             }
  //           }
  //           setTimeout(() => {
  //             setIsEarningUpdating(false);
  //           }, 300);
  //         })
  //         .catch(() => {
  //           // toast.error('Service unavailable, Check back after sometime.');
  //         });
  //     }
  //   };

  // useEffect(() => {
  // if (customerDetails) {
  //     if (customerDetails.earning_type === 'flat') sEarning('1');
  //     if (customerDetails.earning_type === 'motivation') sEarning('2');
  //     if (customerDetails.earning_type === 'profit_sharing') sEarning('3');
  //     sMarginType(customerDetails.margin_type_percent ? '1' : '2');
  //     //sMarginValue(customerDetails.margin_value);
  //     sMotivationPercent(customerDetails.motivation_percent);
  //     sUpperMargin(customerDetails.upper_margin);
  //     sLowerMargin(customerDetails.lower_margin);
  //     sProfitSharePercent(customerDetails?.profit_share_percent ?? 5);
  //     setPaymentTermsValue(customerDetails?.payment_term);
  // }
  // }, [customerDetails]);

  const valReg = /^\d+(\.\d{1,2})?$/;
  const validationSchemaDSP = Yup.object().shape({
    minFloor: Yup.number()
      .required(glossary.ssp_createext_input_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_min_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.ssp_createext_input_floor_price_valid_error)
      .max(99999, glossary.dsp_add_endpoint_input_min_floor_max_length_error),

    maxFloor: Yup.number()
      .required(glossary.ssp_createext_input_floor_price_ad_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_max_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.ssp_createext_input_floor_price_ad_valid_error)
      .test('superior', glossary.dsp_add_endpoint_input_compare_error, function (f2: any) {
        const ref2: any = Yup.ref('minFloor');
        return f2 > this.resolve(ref2);
      })
      .max(99999, glossary.dsp_add_endpoint_input_max_floor_price_max_length_error),
  });

  const validationSchemaSSP = Yup.object().shape({
    marginValue: Yup.number()
      .required('Enter margin value')
      .test('less-than-100', 'Not allow more than 100', function (value: any) {
        return value <= 100;
      }),
  });

  const handleEvent = (e: any) => {
    let payload = {};
    if (customerDetails.customer_type === 'demand') {
      payload = {
        customer_id: customerDetails.id,
        upper_margin: parseFloat(e.maxFloor),
        lower_margin: parseFloat(e.minFloor),
      };
    } else {
      payload = {
        earning_type: 'flat',
        customer_id: customerDetails.id,
        margin_type_percent: 'yes',
        margin_value: parseFloat(e.marginValue),
        motivation_percent: 0,
        profit_share_percent: 0,
      };
    }
    setIsEarningUpdating(true);
    updateCustomerAPI(payload)
      .then((data: any) => {
        sOn_Change(false);
        if (data.error === 400 && data.message.margin_value) {
          toast.error(data.message.margin_value);
        }
        if (!data.status) {
          toast.error(data.msg);
        } else {
          toast.success(glossary.admin_cus_detail_tab_earning_update_success_msg);
        }
        setIsEarningUpdating(false);
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  return (
    <>
      <div className={localStorage.getItem('role_id') === '2' ? 'd-none' : 'main__container__box'}>
        <div className='row justify-content-between'>
          <div className='col-7'>
            <Formik
              validationSchema={
                customerDetails.customer_type === 'demand'
                  ? validationSchemaDSP
                  : validationSchemaSSP
              }
              initialValues={
                customerDetails.customer_type === 'demand' ? initialValuesDSP : initialValuesSSP
              }
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(e) => {
                handleEvent(e);
              }}
            >
              {({ dirty }) => (
                <Form className='extension'>
                  <div className='row'>
                    <div className='col-12'>
                      <h3 className='mb-4'>
                        {customerDetails.customer_type === 'demand'
                          ? glossary.admin_dsp_cus_detail_tab_title
                          : glossary.admin_ssp_cus_detail_tab_field_label_publisher_margin}
                      </h3>
                    </div>
                    <div className='col-12 d-flex gap-3'>
                      {customerDetails.customer_type === 'demand' ? (
                        <>
                          <div className='form-group'>
                            <FormikController
                              kind='withiconstart'
                              control='input'
                              type='text'
                              label={glossary.admin_dsp_cus_detail_tab_input_field_label_min}
                              placeholder={glossary.admin_dsp_cus_detail_tab_input_min_placeholder}
                              name='minFloor'
                            />
                          </div>
                          <div className='form-group'>
                            <FormikController
                              kind='withiconstart'
                              control='input'
                              type='text'
                              label={glossary.admin_dsp_cus_detail_tab_input_field_label_max}
                              placeholder={glossary.admin_dsp_cus_detail_tab_input_max_placeholder}
                              name='maxFloor'
                            />
                          </div>
                        </>
                      ) : (
                        <div className='form-group'>
                          <FormikController
                            kind='withiconstart'
                            iconType='percentage'
                            control='input'
                            type='text'
                            label={'\u00A0'}
                            placeholder={'Enter your margin'}
                            name='marginValue'
                          />
                        </div>
                      )}
                      <div className='d-flex flex-column'>
                        <label className='label'>&nbsp;</label>
                        {isEarningUpdating ? (
                          <Button type='button' kind={'spinner'} text={glossary.updating} />
                        ) : (
                          <Button
                            type='submit'
                            kind={dirty ? 'primary' : 'disabled-secondary'}
                            text={'Update'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {/* {customerDetails.customer_type === 'demand' ? (
                <div className='col-7'>
                <h3 className='mb-4'>{glossary.admin_dsp_cus_detail_tab_title}</h3>
                <div className='d-flex gap-3'>
                    <div className='d-flex flex-column' style={{ maxWidth: 300 }}>
                    <Input
                        type={'cpmnumber'}
                        kind={'percentage'}
                        name={'lowerMargin'}
                        value={lowerMargin}
                        change={updateCustomerValues}
                        error={errors.lowerMargin}
                        length={5}
                        label={glossary.admin_dsp_cus_detail_tab_input_field_label_min}
                        placeholder={
                        glossary.admin_dsp_cus_detail_tab_input_min_placeholder
                        }
                    />
                    </div>
                    <div className='d-flex flex-column' style={{ maxWidth: 300 }}>
                    <Input
                        type={'cpmnumber'}
                        kind={'inputicon'}
                        name={'upperMargin'}
                        value={upperMargin}
                        change={updateCustomerValues}
                        error={errors.upperMargin}
                        length={5}
                        label={glossary.admin_dsp_cus_detail_tab_input_field_label_max}
                        placeholder={
                        glossary.admin_dsp_cus_detail_tab_input_max_placeholder
                        }
                    />
                    </div>
                    <div className='d-flex flex-column'>
                    <label className='label'>&nbsp;</label>
                    {isEarningUpdating ? (
                        <Button type='button' kind={'spinner'} text={glossary.updating} />
                    ) : (
                        <Button
                        click={updateEarning}
                        kind={on_change === true ? 'primary' : 'disabled-secondary'}
                        text={
                            glossary.admin_dsp_cus_detail_tab_floor_range_button_update
                        }
                        />
                    )}
                    </div>
                </div>
                </div>
            ) : (
                <div className='col-6'>
                <h3 className='mb-4'>
                    {glossary.admin_ssp_cus_detail_tab_field_label_publisher_margin}
                </h3>
                <div className='d-flex gap-3'>
                    <div
                    className='d-flex flex-column main__container__box__margin'
                    style={{ maxWidth: 300 }}
                    >
                    <Input
                        // id={'numeric_input'}
                        type={'cpmnumber'}
                        kind={'percentage'}
                        name={'marginValue'}
                        value={marginValue}
                        change={updateCustomerValues}
                        error={errors.marginValue}
                        length={5}
                        label={''}
                        placeholder={
                        glossary.admin_ssp_cus_detail_tab_field_input_margin_placeholder
                        }
                    />
                    </div>
                    <div>
                    {isEarningUpdating ? (
                        <Button type='button' kind={'spinner'} text={glossary.updating} />
                    ) : (
                        <Button
                        type='button'
                        click={updateEarning}
                        kind={on_change === true ? 'primary' : 'disabled-secondary'}
                        text={glossary.admin_ssp_cus_detail_tab_margin_button_update}
                        />
                    )}
                    </div>
                </div>
                </div>
            )} */}

          <PaymentTerms customerDetails={customerDetails} customers={customers} />
        </div>
      </div>
    </>
  );
};
