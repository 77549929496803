import { Modal } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../widgets/button';
import FormikController from '../comman/form/FormikController';
import { useState } from 'react';
import { raiseDisputeAPI, rejectDisputeAPI, solveDisputeAPI } from '../../services/settings';
import { toast } from 'react-toastify';

interface Props {
  show: boolean;
  onHide(): void;
  type: string;
  paymentID: number;
  updateList(): void;
}

export default ({ show, onHide, type, paymentID, updateList }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schemaValidation = Yup.object().shape({
    reason: Yup.string().required(type !== 'solve' ? 'Enter your reason.' : 'Enter your solution.'),
  });

  const submitReason = (e: any) => {
    setIsSubmitting(true);
    const payload = {
      payment_id: paymentID,
      ...(type === 'raise' && { reason: e.reason }),
      ...((type === 'solve' || type === 'reject') && { solution: e.reason }),
    };

    setTimeout(() => {
      if (type === 'raise') {
        toast.success('Dispute has successfully raised.');
        raiseDisputeAPI(payload)
          .then((data: any) => {
            if (data.status) {
              toast.success(data.message);
              updateList && updateList();
            } else {
              toast.error(data.message);
            }
            onHide();
            setIsSubmitting(false);
          })
          .catch((error: any) => {
            // error
          });
      }

      if (type === 'solve') {
        solveDisputeAPI(payload)
          .then((data: any) => {
            if (data.status) {
              toast.success(data.message);
              updateList && updateList();
            } else {
              toast.error(data.message);
            }
            onHide();
            setIsSubmitting(false);
          })
          .catch((error: any) => {
            // error
          });
      }

      if (type === 'reject') {
        rejectDisputeAPI(payload)
          .then((data: any) => {
            if (data.status) {
              toast.success(data.message);
              updateList && updateList();
            } else {
              toast.error(data.message);
            }
            onHide();
            setIsSubmitting(false);
          })
          .catch((error: any) => {
            // error
          });
      }
    }, 300);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        centered
      >
        <Modal.Header>
          <h2>
            {type === 'solve'
              ? 'Solve Dispute'
              : type === 'raise'
              ? 'Raise Dispute'
              : 'Reject Dispute'}
          </h2>
        </Modal.Header>
        <Formik
          validationSchema={schemaValidation}
          initialValues={{ reason: '' }}
          onSubmit={(e: any) => {
            submitReason(e);
          }}
        >
          {({ errors }) => (
            <Form>
              <Modal.Body>
                <FormikController
                  kind='withouticon'
                  control='textArea'
                  type='text'
                  label={type === 'solve' ? 'Solution' : 'Reason'}
                  placeholder={type === 'solve' ? 'Enter Your Solution' : 'Enter Your Reason'}
                  name='reason'
                  maxLength={1024}
                />
              </Modal.Body>
              <Modal.Footer className='gap-2'>
                <Button
                  type={'button'}
                  kind={'secondary'}
                  text={'Close'}
                  click={() => {
                    onHide();
                  }}
                />
                {isSubmitting ? (
                  <Button type='button' kind={'spinner'} text={'Loading...'} />
                ) : (
                  <Button type={'submit'} kind={'primary'} text={'Submit'} />
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
