import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { BiEditAlt } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { MdInfoOutline, MdOutlinePauseCircle } from 'react-icons/md';

import Button from '../widgets/button';
import Switch from '../widgets/switch';

import store from '../../states';

import menu from '../../assets/icons/menu.svg';
import infy from '../../assets/icons/infy.svg';
import pendingIcon from '../../assets/icons/dot-pending.svg';
import activeIcon from '../../assets/icons/dot-active.svg';
import pausedIcon from '../../assets/icons/dot-failed.svg';
import googleAdManager from '../../assets/icons/google-admanager.svg';

import {
  updateTagAPI,
  sspEndpointToggleActiveAPI,
  sspEndpointToggleInactiveAPI,
} from '../../services/tags';
import ResumePause from '../modals/resume-pause';
import {
  customerPauseSPOAPI,
  customerActiveSPOAPI,
  customerActiveSSPAPI,
  customerPauseSSPAPI,
} from '../../services/spo';
import { roundTime } from '../helpers/functions';

interface Props {
  id: string;
  icon?: string;
  text?: string;
  size: 'collapsed' | 'expanded';
  kind:
    | 'tag'
    | 'customer'
    | 'inventory'
    | 'deal'
    | 'dsp'
    | 'req-deal'
    | 'SPO'
    | 'PublisherSPO'
    | 'creative';
  info: {
    items?: any;
    cpm?: string;
    thumb?: string;
    duration?: string;
    impressions?: string;
    fillRate?: string;
    created?: string;
    floorPrice?: string;
    earning?: string;
    traffic?: string;
    type?: string;
    publishers?: string;
    config?: any;
    endpointItem?: any;
    status?: 'active' | 'pause' | 'failed' | 'pending' | 'reject' | 'on_hold' | 'offered';
    endpointType?: any;
    floorPriceFormatted?: string;
    minCpm?: string;
    maxCpm?: string;
    userType?: string;
    statusFormatted?: string;
  };
  css?: {
    margin: string;
  };
  selected?: string;
  click(id: string): void;
  getTags?(updated: any): void;
  setSelected?(): void;
  tagDetails?(tagId: any): void;
  tagDetails1?(tagId: any): void;
  TagsListApi?(id: any): void;
  getEndpointApi?(data: any, x: string): void;
  expandClass?: boolean;
  startDate?: any;
  endDate?: any;
  allData?: boolean;
}

export default (props: Props) => {
  const {
    id,
    text,
    size,
    info,
    kind,
    click,
    selected,
    getTags,
    // setSelected,
    tagDetails,
    tagDetails1,
    // TagsListApi,
    getEndpointApi,
    expandClass,
    startDate,
    endDate,
    allData,
  } = props;

  const [className, setClassName] = useState('card');
  const [contextMenu, setContextMenu] = useState(false);
  const location = useLocation();
  // const [deleteShow, sDeleteShow] = useState(false);
  const [statusDialog, sStatusDialog] = useState(false);

  const currentDate = new Date();

  const [tagStatus, setTagStatus] = useState('');

  const startNewDate = startDate ? new Date(startDate?.toString()) : roundTime(new Date());
  const endNewDate = endDate ? new Date(endDate?.toString()) : roundTime(new Date());

  useEffect(() => {
    if (endNewDate < currentDate) {
      setTagStatus('expired');
    } else if (startNewDate > currentDate) {
      setTagStatus('upcoming');
    } else {
      setTagStatus('running');
    }
  }, []);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const toggle = (chec: any, id: any) => {
    const payload = {
      endpoint_id: id,
    };
    if (!chec) {
      sspEndpointToggleInactiveAPI(payload)
        .then((data: any) => {
          if (data.status === true) {
            // TagsListApi && TagsListApi(payload.endpoint_id);
            getEndpointApi && getEndpointApi(data, 'card');
            toast.success(data.messages);
          }
        })
        .catch((error: any) => {
          //ok
        });
    } else {
      sspEndpointToggleActiveAPI(payload)
        .then((data: any) => {
          if (data.status === true) {
            // TagsListApi && TagsListApi(payload.endpoint_id);
            getEndpointApi && getEndpointApi(data, 'card');
            toast.success(data.messages);
          }
        })
        .catch((error: any) => {
          //ok
        });
    }
  };

  const toggleContextMenu = (e: Event) => {
    const allow = document.getElementById(`open-${id}`);
    const target = e.target;
    if (target === allow) {
      setContextMenu(true);
    } else {
      setContextMenu(false);
    }
  };

  const updateTag = (status: any, id: any) => {
    const payload = {
      id,
      status,
    };
    updateTagAPI(payload)
      .then((data: any) => {
        if (data.status && getTags) {
          getTags(data.data);
        }
      })
      .catch(() => {
        //
      });
  };

  const updatePublisherDSP = (chec: any, status: any, id: any) => {
    const payload = {
      id,
      status,
    };
    if (!chec) {
      customerPauseSPOAPI(payload)
        .then((data: any) => {
          if (data.status && getTags) {
            getTags(data.customer_obj);
            toast.success(data.message);
          }
        })
        .catch(() => {
          //
        });
    } else {
      customerActiveSPOAPI(payload)
        .then((data: any) => {
          if (data.status && getTags) {
            getTags(data.customer_obj);
            toast.success(data.message);
          }
        })
        .catch(() => {
          //
        });
    }
  };

  const updatePublisherSSP = (chec: any, status: any, id: any) => {
    const payload = {
      id,
      status,
    };
    if (!chec) {
      customerPauseSSPAPI(payload)
        .then((data: any) => {
          if (data.status === true) {
            getEndpointApi && getEndpointApi(data, 'card');
            toast.success(data.message);
          }
        })
        .catch(() => {
          //
        });
    } else {
      customerActiveSSPAPI(payload)
        .then((data: any) => {
          if (data.status === true) {
            getEndpointApi && getEndpointApi(data, 'card');
            toast.success(data.message);
          }
        })
        .catch(() => {
          //
        });
    }
  };

  // const deleteTag = () => {
  //   const payload = {
  //     id,
  //   };
  //   deleteTagAPI(payload)
  //     .then(() => {
  //       if (getTags) getTags();
  //       if (setSelected) setSelected();
  //     })
  //     .catch(() => {
  //       //
  //     });
  // };

  useEffect(() => {
    if (id === selected) {
      setClassName('card card-selected');
    } else {
      if (allData) {
        setClassName('card');
      } else {
        setClassName('card disable-field');
      }
    }
  }, [selected, allData]);

  useEffect(() => {
    document.addEventListener('click', toggleContextMenu, false);
    return () => document.removeEventListener('click', toggleContextMenu, false);
  }, []);

  const imageUrl = info.thumb; // Replace this with the URL you want to check
  const [imagePreviewAvailable, setImagePreviewAvailable] = useState(false);

  const handleImageLoad = () => {
    setImagePreviewAvailable(true);
  };

  // const handleImageError = () => {
  //   setImagePreviewAvailable(false);
  // };

  return (
    <>
      <ResumePause
        onHide={() => {
          sStatusDialog(false);
        }}
        show={statusDialog}
        api={() => updateTag('pause', selected)}
        status={info.status === 'active' ?? 'pause'}
      />
      {/* <DeleteTag
        onHide={() => {
          sDeleteShow(false);
        }}
        show={deleteShow}
        api={() => deleteTag()}
      /> */}
      <div className={className} onClick={() => click(id)}>
        {!expandClass &&
          kind !== 'SPO' &&
          kind !== 'PublisherSPO' &&
          kind !== 'req-deal' &&
          kind !== 'deal' &&
          kind !== 'creative' && (
            <div className={'card-row align-items-center'}>
              <div className={'card-row'}>
                {!info?.config?.ep &&
                  info?.type !== 'ssai' &&
                  info?.type !== 'extension' &&
                  info?.endpointType !== 'vast_url' &&
                  info?.endpointType !== 'vast_xml' &&
                  info?.endpointType !== 'hb_connector' &&
                  info?.endpointType !== 'end_point' &&
                  info?.endpointType !== 'GAM' &&
                  info?.endpointType !== 'contextual_reel' && (
                    <div className={'card-col me-2'}>
                      {kind === 'tag' ? (
                        info.type === 'infy' ? (
                          infy
                        ) : (
                          <p className='align-items-center d-flex justify-content-center rounded-circle text-white'>
                            {glossary.ssp_supply_vasttag_card_icon}
                          </p>
                        )
                      ) : (
                        <>
                          <div>
                            <img
                              src={imageUrl}
                              onLoad={handleImageLoad}
                              //onError={handleImageError}
                              style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                              alt='profile'
                            />
                            {imagePreviewAvailable ? (
                              <div className='card-image available'>
                                <img src={imageUrl} alt='' width={28} />
                              </div>
                            ) : (
                              <div className='card-image not-available'>*</div>
                            )}
                          </div>
                          {/* <Avatar src={info.thumb} size={'28'} round /> */}
                        </>
                      )}
                    </div>
                  )}
                {info?.type === 'ssai' && (
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '10px' }}
                    >
                      {glossary.ssp_supply_ssai_card_icon}
                    </p>
                  </div>
                )}
                {info?.type === 'extension' && (
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '10px' }}
                    >
                      EXT
                    </p>
                  </div>
                )}

                {info?.endpointType === 'hb_connector' && (
                  <div className={'card-col me-2'}>
                    <div className='card-image available'>
                      <img src={info?.config?.bidderLogo} alt='' width={28} />
                    </div>
                  </div>
                )}

                {/* {info?.config?.name === 'Infy.TV' && (
                  <div className={'card-col me-2'}>
                    <span
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ backgroundColor: '#fff' }}
                    >
                      <Avatar src={info?.config?.bidderLogo} size={'28'} round />
                    </span>
                  </div>
                )} */}

                {info?.endpointType === 'GAM' && (
                  <div className={'card-col me-2'}>
                    <div className='card-image available'>
                      <img src={googleAdManager} alt='' width={28} />
                    </div>
                  </div>
                )}

                {info?.endpointType === 'end_point' && info?.config?.name !== 'Infy.TV' && (
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '10px', paddingTop: '2px' }}
                    >
                      {glossary.dsp_rtb_card_icon}
                    </p>
                  </div>
                )}

                {(info?.endpointType === 'vast_url' || info?.endpointType === 'vast_xml') && (
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '9px', paddingTop: '2px' }}
                    >
                      {glossary.dsp_vast_card_icon}
                    </p>
                  </div>
                )}

                {info?.endpointType === 'contextual_reel' && (
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '9px', paddingTop: '2px' }}
                    >
                      REEL
                    </p>
                  </div>
                )}

                {info?.config?.integrationName && (
                  <div className={'card-col'} style={{ width: '0px' }}>
                    <h6 className={'truncate-hb'} style={{ fontSize: '14px' }}>
                      {info?.config?.integrationName}
                    </h6>
                  </div>
                )}

                {info?.endpointType !== 'hb_connector' && (
                  <div className={'card-col'} style={{ width: '112px' }}>
                    <h6
                      className={'truncate'}
                      style={{
                        fontSize: '14px',
                        width: kind === 'tag' ? '110px' : kind === 'dsp' ? '110px' : '130px',
                      }}
                      title={text}
                    >
                      {text}
                    </h6>
                  </div>
                )}
              </div>

              {(info?.endpointItem?.type === 'vast_url' ||
                info?.endpointItem?.type === 'vast_xml' ||
                info?.endpointItem?.type === 'contextual_reel') &&
                location.pathname.startsWith('/publisher/demand') &&
                localStorage.getItem('customer_type') === 'publisher' && (
                  <>
                    {info.status === 'pause' ? (
                      <>
                        <div className='card-col'>
                          <img
                            alt='pause-icon'
                            src={pausedIcon}
                            width={8}
                            height={8}
                            style={{ marginRight: 10 }}
                          />
                        </div>
                        <div className='card-col'>
                          <label className={'card-status'}>Paused</label>
                        </div>
                      </>
                    ) : (
                      <p
                        className={
                          tagStatus === 'running'
                            ? 'badge badge-primary'
                            : tagStatus === 'expired'
                            ? 'badge badge-expired'
                            : tagStatus === 'upcoming'
                            ? 'badge badge-secondary'
                            : ''
                        }
                      >
                        {tagStatus}
                      </p>
                    )}
                  </>
                )}

              <div className={'card-col'}>
                <div className={'card-row'}>
                  <div className={'card-col'}>
                    {info?.endpointItem?.type !== 'vast_url' &&
                      info?.endpointItem?.type !== 'vast_xml' &&
                      info?.endpointItem?.type !== 'contextual_reel' &&
                      location.pathname !== '/publisher/demand' &&
                      localStorage.getItem('customer_type') === 'publisher' && (
                        <>
                          <div className={'card-row'}>
                            {info.type !== 'supply' && (
                              <>
                                <div className={'card-col'}>
                                  {info.status === 'pause' && (
                                    <img
                                      alt='pause-status'
                                      src={pausedIcon}
                                      width={8}
                                      height={8}
                                      style={{ marginRight: 10 }}
                                    />
                                  )}
                                  {info.status === 'active' && (
                                    <img
                                      alt='active-status'
                                      src={activeIcon}
                                      width={8}
                                      height={8}
                                      style={{ marginRight: 10 }}
                                    />
                                  )}
                                  {info.status === 'pending' && (
                                    <img
                                      alt='pending-status'
                                      src={pendingIcon}
                                      width={8}
                                      height={8}
                                      style={{ marginRight: 10 }}
                                    />
                                  )}
                                  {info.status === 'reject' && (
                                    <img
                                      alt='reject-status'
                                      src={pendingIcon}
                                      width={8}
                                      height={8}
                                      style={{ marginRight: 10 }}
                                    />
                                  )}
                                  {info.status === 'on_hold' && (
                                    <img
                                      alt='onhold-status'
                                      src={pendingIcon}
                                      width={8}
                                      height={8}
                                      style={{ marginRight: 10 }}
                                    />
                                  )}
                                </div>
                                <div className={'card-col'}>
                                  <label className='card-status'>
                                    {info.status === 'pause' && glossary.card_status_paused}
                                    {info.status === 'active' && glossary.card_status_active}
                                    {info.status === 'pending' && glossary.card_status_pending}
                                    {info.status === 'reject' && glossary.card_status_rejected}
                                    {info.status === 'on_hold' && glossary.card_status_on_hold}
                                  </label>
                                </div>
                                {(info?.type === 'normal' ||
                                  info?.type === 'ssai' ||
                                  info.type === 'extension') && (
                                  <div
                                    className={
                                      selected === id
                                        ? 'card-col switch-status'
                                        : 'card-col switch-status visible'
                                    }
                                  >
                                    <div
                                      className={info.status !== 'pending' ? 'active' : 'disbale'}
                                    >
                                      <Switch
                                        api={(chec: any) => {
                                          chec === true
                                            ? updateTag('active', selected)
                                            : updateTag('pause', selected);
                                        }}
                                        status={info.status === 'active' ?? 'pause'}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {info.type === 'supply' && (
                              <label className='card-status'>
                                {glossary.admin_sidebar_dsp_card_field_label_type_supply}
                              </label>
                            )}
                          </div>
                        </>
                      )}

                    {localStorage.getItem('customer_type') !== 'publisher' && (
                      <>
                        <div className={'card-row'}>
                          {info.type !== 'supply' && (
                            <>
                              <div className={'card-col'}>
                                {info.status === 'pause' && (
                                  <img
                                    alt='status-pause'
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'active' && (
                                  <img
                                    alt='status-active'
                                    src={activeIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'pending' && (
                                  <img
                                    alt='status-pending'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'reject' && (
                                  <img
                                    alt='status-reject'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'on_hold' && (
                                  <img
                                    alt='status-onhold'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                              </div>
                              <div className={'card-col'}>
                                <label className='card-status'>
                                  {info.status === 'pause' && glossary.card_status_paused}
                                  {info.status === 'active' && glossary.card_status_active}
                                  {info.status === 'pending' && glossary.card_status_pending}
                                  {info.status === 'reject' && glossary.card_status_rejected}
                                  {info.status === 'on_hold' && glossary.card_status_on_hold}
                                </label>
                              </div>
                              {(info?.type === 'normal' ||
                                info?.type === 'ssai' ||
                                info.type === 'extension') && (
                                <div
                                  className={
                                    selected === id
                                      ? 'card-col switch-status'
                                      : 'card-col switch-status visible'
                                  }
                                >
                                  <div className={info.status !== 'pending' ? 'active' : 'disbale'}>
                                    <Switch
                                      api={(chec: any) => {
                                        chec === true
                                          ? updateTag('active', selected)
                                          : updateTag('pause', selected);
                                      }}
                                      status={info.status === 'active' ?? 'pause'}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {info.type === 'supply' && (
                            <label className='card-status'>
                              {glossary.admin_sidebar_dsp_card_field_label_type_supply}
                            </label>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {/* <div className={'card-col'}>
                    {(info?.endpointItem?.type === 'end_point' ||
                      info?.endpointItem?.type === 'vast_url' ||
                      info?.endpointItem?.type === 'hb_connector' ||
                      info?.endpointItem?.type === 'vast_xml' ||
                      info?.endpointItem?.type === 'contextual_reel' ||
                      info?.endpointItem?.type === 'GAM') &&
                    location.pathname.startsWith('/publisher/demand') ? (
                      ''
                    ) : (
                      <>
                        <div className={'card-row'}>
                          {info.type !== 'supply' && location.pathname !== '/publisher/demand' && (
                            <>
                              <div className={'card-col'}>
                                {info.status === 'pause' && (
                                  <img
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'active' && (
                                  <img
                                    src={activeIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'pending' && (
                                  <img
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'reject' && (
                                  <img
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'on_hold' && (
                                  <img
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                              </div>
                              <div className={'card-col'}>
                                <label className='card-status'>
                                  {info.status === 'pause' && glossary.card_status_paused}
                                  {info.status === 'active' && glossary.card_status_active}
                                  {info.status === 'pending' && glossary.card_status_pending}
                                  {info.status === 'reject' && glossary.card_status_rejected}
                                  {info.status === 'on_hold' && glossary.card_status_on_hold}
                                </label>
                              </div>
                              {(info?.type === 'normal' ||
                                info?.type === 'ssai' ||
                                info.type === 'extension') && (
                                <div
                                  className={
                                    selected === id
                                      ? 'card-col switch-status'
                                      : 'card-col switch-status visible'
                                  }
                                >
                                  <div className={info.status !== 'pending' ? 'active' : 'disbale'}>
                                    <Switch
                                      api={(chec: any) => {
                                        chec === true
                                          ? updateTag('active', selected)
                                          : updateTag('pause', selected);
                                      }}
                                      status={info.status === 'active' ?? 'pause'}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {info.type === 'supply' && (
                            <label className='card-status'>
                              {glossary.admin_sidebar_dsp_card_field_label_type_supply}
                            </label>
                          )}
                        </div>
                      </>
                    )}
                  </div> */}

                  {location.pathname.startsWith('/publisher/demand') && kind === 'dsp' && (
                    <div
                      className={
                        selected === id
                          ? 'card-col switch-status'
                          : 'card-col switch-status visible'
                      }
                    >
                      <div className={info.status !== 'pending' ? 'active' : 'disbale'}>
                        <Switch
                          api={(chec: any) => {
                            toggle(chec, info?.endpointItem?.id);
                          }}
                          status={info.status === 'active' ?? 'pause'}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

        {kind === 'tag' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div className={'expanded-row-img'}>
                    {(info?.type === 'normal' || info?.type === 'connector') && 'Ad'}
                    {info?.type === 'ssai' && <>{glossary.ssp_supply_ssai_card_icon}</>}
                    {info?.type === 'extension' && <>EXT</>}
                  </div>
                  <div className='expanded-row-title text-truncate' title={text}>
                    {text}
                  </div>
                </div>
                <div className='expanded-row-item date'>
                  {info?.items?.created_on_formatted ?? '-'}
                </div>
                <div className='expanded-row-item impression'>
                  {info?.items?.impression_formatted ?? '-'}
                </div>
                <div className='expanded-row-item floorprice'>
                  {info?.items?.floor_price_formatted ?? '-'}
                </div>
                <div className='expanded-row-item earnings'>
                  {info?.items?.earning_formatted ?? '-'}
                </div>
                <div className='expanded-row-item status'>
                  {info.status === 'pause' && (
                    <img
                      alt='pause'
                      src={pausedIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'active' && (
                    <img
                      alt='active'
                      src={activeIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'pending' && (
                    <img
                      alt='pending'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'reject' && (
                    <img
                      alt='reject'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'on_hold' && (
                    <img
                      alt='on_hold'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <label style={{ fontWeight: 200 }}>
                    {info.status === 'pending' && glossary.card_status_pending}
                    {info.status === 'active' && glossary.card_status_active}
                    {info.status === 'pause' && glossary.card_status_paused}
                    {info.status === 'reject' && glossary.card_status_rejected}
                    {info.status === 'on_hold' && glossary.card_status_on_hold}
                  </label>
                </div>
                <div className='expanded-row-item action'>
                  {contextMenu ? (
                    <div className='table-menu'>
                      <img
                        alt={`close-${id}`}
                        id={`close-${id}`}
                        className={'svg-icon'}
                        src={menu}
                        height={16}
                        width={16}
                      />
                      <div className={'context table-menu-list'}>
                        {info.status === 'active' ? (
                          <p
                            className='table-menu-list-item'
                            onClick={() => {
                              sStatusDialog(true);
                            }}
                          >
                            <MdOutlinePauseCircle
                              className='svg-icon'
                              style={{ marginRight: 10, marginTop: -2 }}
                              fontSize={18}
                            />
                            {glossary.ssp_tags_sidebar_expand_contextmenu_option_pause}
                          </p>
                        ) : (
                          <>
                            {info.status !== 'pending' && (
                              <p
                                className='table-menu-list-item'
                                onClick={() => {
                                  updateTag('active', selected);
                                }}
                              >
                                <MdOutlinePauseCircle
                                  className='svg-icon'
                                  style={{ marginRight: 10, marginTop: -2 }}
                                  fontSize={18}
                                />
                                {glossary.ssp_tags_sidebar_expand_contextmenu_option_resume}
                              </p>
                            )}
                          </>
                        )}
                        <p
                          className='table-menu-list-item'
                          onClick={() => {
                            tagDetails && tagDetails(id);
                          }}
                        >
                          <MdInfoOutline
                            className='svg-icon'
                            style={{ marginRight: 10, marginTop: -2 }}
                            fontSize={18}
                          />
                          {glossary.ssp_tags_sidebar_expand_contextmenu_option_details}
                        </p>
                        {/* <p
                        className='table-menu-list-item'
                        onClick={() => {
                          sDeleteShow(true);
                        }}
                      >
                        <DeleteIcon
                          className='svg-icon'
                          height={16}
                          width={16}
                          style={{ marginRight: 10, marginTop: -2 }}
                        />
                        Delete
                      </p> */}
                      </div>
                    </div>
                  ) : (
                    <>
                      {kind === 'tag' && (
                        <img
                          alt={`open-${id}`}
                          id={`open-${id}`}
                          className={'svg-icon'}
                          src={menu}
                          height={16}
                          width={16}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' && (
                  <div className={'card-expanded'}>
                    <div className={'card-row'}>
                      <div className={'card-col'}>
                        <label>{glossary.ssp_supply_card_field_label_floorprice}</label>
                        <b>
                          <label>{info?.items?.floor_price_formatted ?? '-'}</label>
                        </b>
                      </div>
                      <div className={'card-col'}>
                        <label>{glossary.ssp_supply_card_field_label_impressions}</label>
                        <b>
                          <label>{info?.items?.impression_formatted ?? '-'}</label>
                        </b>
                      </div>
                      <div className={'card-col'}>
                        <label>{glossary.ssp_supply_card_field_label_earnings}</label>
                        <b>
                          <label>{info?.items?.earning_formatted ?? '-'}</label>
                        </b>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {kind === 'dsp' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div
                    className={
                      info?.endpointType === 'hb_connector' || info?.endpointType === 'GAM'
                        ? 'expanded-row-img bg-transparent'
                        : 'expanded-row-img'
                    }
                  >
                    {info?.endpointType === 'hb_connector' && (
                      <div className='card-image available'>
                        <img src={info?.config?.bidderLogo} alt='' width={28} />
                      </div>
                    )}
                    {info?.endpointType === 'GAM' && (
                      <div className='card-image available'>
                        <img src={googleAdManager} alt='' width={28} />
                      </div>
                    )}
                    {info?.endpointType === 'end_point' && info?.config?.name !== 'Infy.TV' && (
                      <>{glossary.dsp_rtb_card_icon}</>
                    )}
                    {(info?.endpointType === 'vast_url' || info?.endpointType === 'vast_xml') && (
                      <>{glossary.dsp_vast_card_icon}</>
                    )}
                  </div>

                  {info?.endpointType === 'hb_connector' && info?.config?.integrationName ? (
                    <div
                      className='expanded-row-title text-truncate'
                      title={info?.config?.integrationName}
                    >
                      {info?.config?.integrationName}
                    </div>
                  ) : (
                    <div className='expanded-row-title text-truncate' title={text}>
                      {text}
                    </div>
                  )}
                </div>
                <div className='expanded-row-item date'>{info?.created}</div>
                <div className='expanded-row-item impression'>
                  {info?.endpointItem?.impression_formatted ?? '-'}
                </div>
                <div className='expanded-row-item spending'>
                  {info?.endpointItem?.earning_formatted ?? '-'}
                </div>
                <div className='expanded-row-item bidrate'>
                  {info.fillRate ?? '-'} {glossary.percentage_symbol}
                </div>
                <div className='expanded-row-item status'>
                  {info.status === 'pause' && (
                    <img
                      alt='status-pause'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'active' && (
                    <img
                      alt='status-active'
                      src={activeIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'pending' && (
                    <img
                      alt='status-pending'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'reject' && (
                    <img
                      alt='status-reject'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'on_hold' && (
                    <img
                      alt='status-onhold'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <label style={{ fontWeight: 200 }}>
                    {info.status === 'pending' && glossary.card_status_pending}
                    {info.status === 'active' && glossary.card_status_active}
                    {info.status === 'pause' && glossary.card_status_paused}
                    {info.status === 'reject' && glossary.card_status_rejected}
                    {info.status === 'on_hold' && glossary.card_status_on_hold}
                  </label>
                </div>
                <div className='expanded-row-item action'>
                  {contextMenu ? (
                    <div className='table-menu'>
                      <img
                        id={`close-${id}`}
                        className={'svg-icon'}
                        src={menu}
                        height={16}
                        width={16}
                      />
                      <div className={'context table-menu-list'}>
                        <p
                          className='table-menu-list-item'
                          onClick={() => {
                            tagDetails && tagDetails(id);
                          }}
                        >
                          <MdInfoOutline
                            className='svg-icon'
                            style={{ marginRight: 10, marginTop: -2 }}
                            fontSize={18}
                          />
                          {glossary.dsp_sidebar_card_contextmenu_option_details}
                        </p>
                        <p
                          className='table-menu-list-item'
                          onClick={() => {
                            tagDetails1 && tagDetails1(id);
                          }}
                        >
                          <BiEditAlt
                            className='svg-icon'
                            style={{ marginRight: 10, marginTop: -2 }}
                            fontSize={18}
                          />
                          {glossary.dsp_sidebar_card_contextmenu_options_edit}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      {kind === 'dsp' && (
                        <img
                          id={`open-${id}`}
                          className={'svg-icon'}
                          src={menu}
                          height={16}
                          width={16}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' && (
                  <div className={'card-expanded'}>
                    <div className={'card-row'}>
                      <div className={'card-col'}>
                        <label>{glossary.dsp_card_field_label_floor}</label>
                        <b>
                          <label>{info?.endpointItem?.floor_price_formatted ?? '-'}</label>
                        </b>
                      </div>
                      <div className={'card-col'}>
                        <label>{glossary.dsp_card_field_label_impressions}</label>
                        <b>
                          <label>{info?.endpointItem?.impression_formatted ?? '-'}</label>
                        </b>
                      </div>
                      <div className={'card-col'}>
                        <label>{glossary.dsp_card_field_label_earnings}</label>
                        <b>
                          <label>
                            <label>{info?.endpointItem?.earning_formatted ?? '-'}</label>
                          </label>
                        </b>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {kind === 'SPO' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div className={'expanded-row-img'}>
                    <div>
                      <img
                        src={imageUrl}
                        onLoad={handleImageLoad}
                        //onError={handleImageError}
                        style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                        alt='img-preview'
                      />
                      {imagePreviewAvailable ? (
                        <div className='card-image available'>
                          <img src={imageUrl} alt='' width={28} />
                        </div>
                      ) : (
                        <div className='card-image not-available'>*</div>
                      )}
                    </div>
                    {/* <Avatar src={info?.thumb} size={'25'} round /> */}
                  </div>
                  <div className='expanded-row-title text-truncate' title={text}>
                    {text}
                  </div>
                </div>

                <div className='expanded-row-item date'>
                  {info?.endpointItem?.created_on_formatted}
                </div>
                <div className='expanded-row-item impression'>
                  {info?.endpointItem?.impression_formatted ?? '-'}
                </div>
                <div className='expanded-row-item spending'>
                  {info?.endpointItem?.earning_formatted ?? '-'}
                </div>
                <div className='expanded-row-item bidrate'>
                  {info.fillRate ?? '-'} {glossary.percentage_symbol}
                </div>

                <div className='expanded-row-item status'>
                  {info?.endpointItem?.assigned_status === 'pause' && (
                    <img
                      alt='status-pause'
                      src={pausedIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'active' && (
                    <img
                      alt='status-active'
                      src={activeIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'pending' && (
                    <img
                      alt='status-pending'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'registered' && (
                    <img
                      alt='status-registered'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <label style={{ fontWeight: 200 }}>
                    {info?.endpointItem?.assigned_status === 'active' &&
                      glossary.card_status_active}
                    {info?.endpointItem?.assigned_status === 'pause' && glossary.card_status_paused}
                    {info?.endpointItem?.assigned_status === 'pending' &&
                      glossary.card_status_pending}
                    {info?.endpointItem?.assigned_status === 'registered' &&
                      glossary.card_status_pending}
                  </label>
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' ? (
                  <>
                    <div className={'card-row'}>
                      <div className={'card-row'}>
                        <div className={'card-col me-2'}>
                          <div>
                            <img
                              src={imageUrl}
                              onLoad={handleImageLoad}
                              //onError={handleImageError}
                              style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                              alt='img-preview'
                            />
                            {imagePreviewAvailable ? (
                              <div className='card-image available'>
                                <img src={imageUrl} alt='' width={28} />
                              </div>
                            ) : (
                              <div className='card-image not-available'>*</div>
                            )}
                          </div>
                          {/* <Avatar src={info.thumb} size={'28'} round /> */}
                        </div>
                        <div className={'card-col'} style={{ width: '112px' }}>
                          <h6
                            className={'truncate'}
                            style={{
                              fontSize: '14px',
                              width: '130px',
                            }}
                            title={text}
                          >
                            {text}
                          </h6>
                        </div>
                      </div>

                      <div className={'card-col'}>
                        <div className={'card-row'}>
                          <div className={'card-col'}>
                            <div className={'card-row'}>
                              <div className={'card-col'}>
                                {info?.endpointItem?.assigned_status === 'pause' && (
                                  <img
                                    alt='status-pause'
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'active' && (
                                  <img
                                    alt='status-active'
                                    src={activeIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'pending' && (
                                  <img
                                    alt='status-pending'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'registered' && (
                                  <img
                                    alt='status-registered'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'reject' && (
                                  <img
                                    alt='status-reject'
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                              </div>
                              <div className={'card-col'}>
                                <label className='card-status'>
                                  {info?.endpointItem?.assigned_status === 'pause' &&
                                    glossary.card_status_paused}
                                  {info?.endpointItem?.assigned_status === 'active' &&
                                    glossary.card_status_active}
                                  {info?.endpointItem?.assigned_status === 'pending' &&
                                    glossary.card_status_pending}
                                  {info?.endpointItem?.assigned_status === 'registered' &&
                                    glossary.card_status_pending}
                                  {info?.endpointItem?.assigned_status === 'reject' && 'Reject'}
                                </label>
                              </div>
                              {(info?.endpointItem?.assigned_status === 'pause' ||
                                info?.endpointItem?.assigned_status === 'active') && (
                                <div
                                  className={
                                    selected === id
                                      ? 'card-col switch-status'
                                      : 'card-col switch-status visible'
                                  }
                                >
                                  <Switch
                                    api={(chec: any) => {
                                      chec == true
                                        ? updatePublisherDSP(chec, 'active', selected)
                                        : updatePublisherDSP(chec, 'pause', selected);
                                    }}
                                    status={
                                      info?.endpointItem?.assigned_status === 'active' ?? 'pause'
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={'card-expanded'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <label>{glossary.spo_sidebar_card_field_label_1}</label>
                          <b>
                            <label>{info?.endpointItem?.created_on_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.spo_sidebar_card_field_label_2}</label>
                          <b>
                            <label>{info?.endpointItem?.impression_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.spo_sidebar_card_field_label_3}</label>
                          <b>
                            <label>
                              <label>{info?.endpointItem?.earning_formatted ?? '-'}</label>
                            </label>
                          </b>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={'card-row'}>
                    <div className={'card-row'}>
                      <div className={'card-col me-2'}>
                        <div>
                          <img
                            src={imageUrl}
                            onLoad={handleImageLoad}
                            //onError={handleImageError}
                            style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                            alt='img-preview'
                          />
                          {imagePreviewAvailable ? (
                            <div className='card-image available'>
                              <img src={imageUrl} alt='' width={28} />
                            </div>
                          ) : (
                            <div className='card-image not-available'>*</div>
                          )}
                        </div>
                        {/* <Avatar src={info.thumb} size={'28'} round /> */}
                      </div>
                      <div className={'card-col'} style={{ width: '112px' }}>
                        <h6
                          className={'truncate'}
                          style={{
                            fontSize: '14px',
                            width: '130px',
                          }}
                          title={text}
                        >
                          {text}
                        </h6>
                      </div>
                    </div>
                    <div className={'card-col'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <div className={'card-row'}>
                            <div className={'card-col'}>
                              {info?.endpointItem?.assigned_status === 'pause' && (
                                <img
                                  alt='status-pause'
                                  src={pausedIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'active' && (
                                <img
                                  alt='status-active'
                                  src={activeIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'pending' && (
                                <img
                                  alt='status-pending'
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'registered' && (
                                <img
                                  alt='status-registered'
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'reject' && (
                                <img
                                  alt='status-reject'
                                  src={pausedIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                            </div>
                            <div className={'card-col'}>
                              <label className='card-status'>
                                {info?.endpointItem?.assigned_status === 'pause' &&
                                  glossary.card_status_paused}
                                {info?.endpointItem?.assigned_status === 'active' &&
                                  glossary.card_status_active}
                                {info?.endpointItem?.assigned_status === 'pending' &&
                                  glossary.card_status_pending}
                                {info?.endpointItem?.assigned_status === 'registered' &&
                                  glossary.card_status_pending}
                                {info?.endpointItem?.assigned_status === 'reject' && 'Reject'}
                              </label>
                            </div>
                            {(info?.endpointItem?.assigned_status === 'pause' ||
                              info?.endpointItem?.assigned_status === 'active') && (
                              <div
                                className={
                                  selected === id
                                    ? 'card-col switch-status'
                                    : 'card-col switch-status visible'
                                }
                              >
                                <Switch
                                  api={(chec: any) => {
                                    chec == true
                                      ? updatePublisherDSP(chec, 'active', selected)
                                      : updatePublisherDSP(chec, 'pause', selected);
                                  }}
                                  status={
                                    info?.endpointItem?.assigned_status === 'active' ?? 'pause'
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {kind === 'PublisherSPO' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div className={'expanded-row-img'}>
                    <div>
                      <img
                        src={imageUrl}
                        onLoad={handleImageLoad}
                        //onError={handleImageError}
                        style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                        alt='img-preview'
                      />
                      {imagePreviewAvailable ? (
                        <div className='card-image available'>
                          <img src={imageUrl} alt='' width={28} />
                        </div>
                      ) : (
                        <div className='card-image not-available'>*</div>
                      )}
                    </div>
                    {/* <Avatar src={info?.thumb} size={'25'} round /> */}
                  </div>
                  <div className='expanded-row-title text-truncate' title={text}>
                    {text}
                  </div>
                </div>
                <div className='expanded-row-item date'>
                  {info?.endpointItem?.created_on_formatted}
                </div>
                <div className='expanded-row-item impression'>
                  {info?.endpointItem?.impression_formatted ?? '-'}
                </div>
                <div className='expanded-row-item spending'>
                  {info?.endpointItem?.earning_formatted ?? '-'}
                </div>
                <div className='expanded-row-item bidrate'>
                  {info.fillRate ?? '-'} {glossary.percentage_symbol}
                </div>

                <div className='expanded-row-item status'>
                  {info?.endpointItem?.assigned_status === 'pause' && (
                    <img
                      alt='status-pause'
                      src={pausedIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'active' && (
                    <img
                      alt='status-active'
                      src={activeIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'pending' && (
                    <img
                      alt='status-pending'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'registered' && (
                    <img
                      alt='status-registered'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.endpointItem?.assigned_status === 'reject' && (
                    <img
                      alt='status-reject'
                      src={pausedIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <label style={{ fontWeight: 200 }}>
                    {info?.endpointItem?.assigned_status === 'active' &&
                      glossary.card_status_active}
                    {info?.endpointItem?.assigned_status === 'pause' && glossary.card_status_paused}
                    {info?.endpointItem?.assigned_status === 'pending' &&
                      glossary.card_status_pending}
                    {info?.endpointItem?.assigned_status === 'registered' &&
                      glossary.card_status_pending}
                    {info?.endpointItem?.assigned_status === 'reject' && 'Reject'}
                  </label>
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' ? (
                  <>
                    <div className={'card-row'}>
                      <div className={'card-row'}>
                        <div className={'card-col me-2'}>
                          <div>
                            <img
                              src={imageUrl}
                              onLoad={handleImageLoad}
                              //onError={handleImageError}
                              style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                              alt='img-preview'
                            />
                            {imagePreviewAvailable ? (
                              <div className='card-image available'>
                                <img src={imageUrl} alt='' width={28} />
                              </div>
                            ) : (
                              <div className='card-image not-available'>*</div>
                            )}
                          </div>
                          {/* <Avatar src={info.thumb} size={'28'} round /> */}
                        </div>
                        <div className={'card-col'} style={{ width: '112px' }}>
                          <h6
                            className={'truncate'}
                            style={{
                              fontSize: '14px',
                              width: '130px',
                            }}
                            title={text}
                          >
                            {text}
                          </h6>
                        </div>
                      </div>
                      <div className={'card-col'}>
                        <div className={'card-row'}>
                          <div className={'card-col'}>
                            <div className={'card-row'}>
                              <div className={'card-col'}>
                                {info?.endpointItem?.assigned_status === 'pause' && (
                                  <img
                                    alt='status-pause'
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'active' && (
                                  <img
                                    alt='status-active'
                                    src={activeIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'pending' && (
                                  <img
                                    alt='status-pending'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'registered' && (
                                  <img
                                    alt='status-registered'
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info?.endpointItem?.assigned_status === 'reject' && (
                                  <img
                                    alt='status-reject'
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                              </div>
                              <div className={'card-col'}>
                                <label className='card-status'>
                                  {info?.endpointItem?.assigned_status === 'pause' &&
                                    glossary.card_status_paused}
                                  {info?.endpointItem?.assigned_status === 'active' &&
                                    glossary.card_status_active}
                                  {info?.endpointItem?.assigned_status === 'pending' &&
                                    glossary.card_status_pending}
                                  {info?.endpointItem?.assigned_status === 'registered' &&
                                    glossary.card_status_pending}
                                  {info?.endpointItem?.assigned_status === 'reject' && 'Reject'}
                                </label>
                              </div>
                              {(info?.endpointItem?.assigned_status === 'pause' ||
                                info?.endpointItem?.assigned_status === 'active') && (
                                <div
                                  className={
                                    selected === id
                                      ? 'card-col switch-status'
                                      : 'card-col switch-status visible'
                                  }
                                >
                                  <Switch
                                    api={(chec: any) => {
                                      chec == true
                                        ? updatePublisherSSP(chec, 'active', selected)
                                        : updatePublisherSSP(chec, 'pause', selected);
                                    }}
                                    status={
                                      info?.endpointItem?.assigned_status === 'active' ?? 'pause'
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={'card-expanded'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <label>{glossary.spo_sidebar_card_field_label_1}</label>
                          <b>
                            <label>{info?.endpointItem?.created_on_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.spo_sidebar_card_field_label_2}</label>
                          <b>
                            <label>{info?.endpointItem?.impression_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.spo_sidebar_card_field_label_3}</label>
                          <b>
                            <label>
                              <label>{info?.endpointItem?.earning_formatted ?? '-'}</label>
                            </label>
                          </b>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={'card-row'}>
                    <div className={'card-row'}>
                      <div className={'card-col me-2'}>
                        <div>
                          <img
                            src={imageUrl}
                            onLoad={handleImageLoad}
                            //onError={handleImageError}
                            style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                            alt='profile'
                          />
                          {imagePreviewAvailable ? (
                            <div className='card-image available'>
                              <img src={imageUrl} alt='' width={28} />
                            </div>
                          ) : (
                            <div className='card-image not-available'>*</div>
                          )}
                        </div>
                        {/* <Avatar src={info.thumb} size={'28'} round /> */}
                      </div>
                      <div className={'card-col'} style={{ width: '112px' }}>
                        <h6
                          className={'truncate'}
                          style={{
                            fontSize: '14px',
                            width: '130px',
                          }}
                          title={text}
                        >
                          {text}
                        </h6>
                      </div>
                    </div>
                    <div className={'card-col'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <div className={'card-row'}>
                            <div className={'card-col'}>
                              {info?.endpointItem?.assigned_status === 'pause' && (
                                <img
                                  alt='status-pause'
                                  src={pausedIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'active' && (
                                <img
                                  alt='status-active'
                                  src={activeIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'pending' && (
                                <img
                                  alt='status-pending'
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info?.endpointItem?.assigned_status === 'registered' && (
                                <img
                                  alt='status-registered'
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                            </div>
                            <div className={'card-col'}>
                              <label className='card-status'>
                                {info?.endpointItem?.assigned_status === 'pause' &&
                                  glossary.card_status_paused}
                                {info?.endpointItem?.assigned_status === 'active' &&
                                  glossary.card_status_active}
                                {info?.endpointItem?.assigned_status === 'pending' &&
                                  glossary.card_status_pending}
                                {info?.endpointItem?.assigned_status === 'registered' &&
                                  glossary.card_status_pending}
                              </label>
                            </div>
                            {(info?.endpointItem?.assigned_status === 'pause' ||
                              info?.endpointItem?.assigned_status === 'active') && (
                              <div
                                className={
                                  selected === id
                                    ? 'card-col switch-status'
                                    : 'card-col switch-status visible'
                                }
                              >
                                <Switch
                                  api={(chec: any) => {
                                    chec == true
                                      ? updatePublisherSSP(chec, 'active', selected)
                                      : updatePublisherSSP(chec, 'pause', selected);
                                  }}
                                  status={
                                    info?.endpointItem?.assigned_status === 'active' ?? 'pause'
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {kind === 'customer' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div className={'expanded-row-img bg-transparent'}>
                    <div>
                      <img
                        src={imageUrl}
                        onLoad={handleImageLoad}
                        //onError={handleImageError}
                        style={{ display: 'none' }} // Hide the image element, so it won't be visible on the page
                        alt='img-preview'
                      />
                      {imagePreviewAvailable ? (
                        <div className='card-image available'>
                          <img src={imageUrl} alt='' width={28} />
                        </div>
                      ) : (
                        <div className='card-image not-available'>*</div>
                      )}
                    </div>
                  </div>
                  <div className='expanded-row-title text-truncate' title={text}>
                    {text}
                  </div>
                </div>
                <div className='expanded-row-item date'>{info?.created}</div>
                <div className='expanded-row-item impression'>
                  {info?.items?.impression_formatted ?? '-'}
                </div>
                <div className='expanded-row-item earnings'>
                  {info?.items?.earning_formatted ?? '-'}
                </div>
                <div className='expanded-row-item fillrate'>
                  {info.fillRate ?? '-'} {glossary.percentage_symbol}
                </div>
                <div className='expanded-row-item status'>
                  {info.status === 'pause' && (
                    <img
                      alt='status-pause'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'active' && (
                    <img
                      alt='status-active'
                      src={activeIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'pending' && (
                    <img
                      alt='status-pending'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'reject' && (
                    <img
                      alt='status-reject'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info.status === 'on_hold' && (
                    <img
                      alt='status-onhold'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <label style={{ fontWeight: 200 }}>
                    {info.status === 'pending' && glossary.card_status_pending}
                    {info.status === 'active' && glossary.card_status_active}
                    {info.status === 'pause' && glossary.card_status_paused}
                    {info.status === 'reject' && glossary.card_status_rejected}
                    {info.status === 'on_hold' && glossary.card_status_on_hold}
                  </label>
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' && (
                  <div className={'card-expanded'}>
                    <div className={'card-row'}>
                      {info.status === 'pending' ? (
                        <>
                          <div className={'card-col'}>
                            <label>{glossary.admin_sidebar_ssp_req_card_field_label_date}</label>
                            <b>
                              <label>{info?.items?.created_on_formatted ?? '-'}</label>
                            </b>
                          </div>
                          <div className={'card-col'}>
                            <label>{glossary.admin_sidebar_ssp_req_card_field_label_traffic}</label>
                            <b>
                              <label>{info?.items?.traffic_formatted ?? '-'}</label>
                            </b>
                          </div>
                          <div className={'card-col'}>
                            <Button
                              kind={'link'}
                              text={glossary.admin_sidebar_ssp_req_card_button_view}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={'card-col'}>
                            <label>{glossary.admin_sidebar_card_field_label_since}</label>
                            <b>
                              <label>{info?.items?.created_on_formatted ?? '-'}</label>
                            </b>
                          </div>
                          <div className={'card-col'}>
                            <label>
                              {info.type === 'demand'
                                ? glossary.admin_sidebar_dsp_card_field_label_bids
                                : glossary.admin_sidebar_ssp_card_field_label_impressions}
                            </label>
                            <b>
                              <label>{info.items?.impression_formatted ?? '-'}</label>
                            </b>
                          </div>
                          <div className={'card-col'}>
                            <label>
                              {info.type === 'demand'
                                ? glossary.admin_sidebar_dsp_card_field_label_spending
                                : glossary.admin_sidebar_ssp_card_field_label_earnings}
                            </label>
                            <b>
                              <label>{info.items?.earning_formatted ?? '-'}</label>
                            </b>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {kind === 'deal' && (
          <>
            <div className={'card-row'}>
              <div className={'card-row'}>
                <div className={'card-col me-2'}>
                  <p
                    className='align-items-center d-flex justify-content-center rounded-circle text-white'
                    style={{ fontSize: '10px', paddingTop: '2px' }}
                  >
                    {info?.type === 'pmp' ? 'PMP' : 'PG'}
                  </p>
                </div>
                <div className={'card-col'} style={{ width: '112px' }}>
                  <h6
                    className={'truncate'}
                    style={{
                      fontSize: '14px',
                      width: '130px',
                    }}
                    title={text}
                  >
                    {text}
                  </h6>
                </div>
              </div>
              <div className='card-row'>
                <div className={'card-col'}>
                  {info?.status === 'pause' && (
                    <img
                      alt='status-pause'
                      src={pausedIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.status === 'active' && (
                    <img
                      alt='status-active'
                      src={activeIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {info?.status === 'pending' && (
                    <img
                      alt='status-pending'
                      src={pendingIcon}
                      width={8}
                      height={8}
                      style={{ marginRight: 10 }}
                    />
                  )}
                </div>
                <div className={'card-col'}>
                  <label className='card-status'>
                    {info?.status === 'pause' && glossary.card_status_paused}
                    {info?.status === 'active' && glossary.card_status_active}
                    {info?.status === 'pending' && glossary.card_status_pending}
                  </label>
                </div>
              </div>
            </div>
            {size === 'expanded' && (
              <div>
                <div
                  className={info.userType === 'admin' ? 'card-expanded admin' : 'card-expanded'}
                >
                  <div className={'card-row'}>
                    <div className={'card-col'}>
                      <label>{glossary.admin_deal_card_field_label_id}</label>
                      <b>
                        <label>{id}</label>
                      </b>
                    </div>
                    {info.userType === 'admin' ? (
                      <>
                        <div className={'card-col'}>
                          <label>{info?.type === 'pmp' ? 'Min CPM' : 'CPM'}</label>
                          <b>
                            <label>{info?.type === 'pmp' ? info?.minCpm : info?.cpm}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{info?.type === 'pmp' ? 'Max CPM' : 'IMP'}</label>
                          <b>
                            <label>
                              <label>
                                {info?.type === 'pmp' ? info?.maxCpm : info?.impressions}
                              </label>
                            </label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.admin_deal_card_field_label_publishers}</label>
                          <b>
                            <label>{info.publishers}</label>
                          </b>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={'card-col'}>
                          <label>{info?.type === 'pmp' ? 'Min CPM' : 'CPM'}</label>
                          <b>
                            <label>{info?.type === 'pmp' ? info?.minCpm : info?.cpm}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{info?.type === 'pmp' ? 'Max CPM' : 'Impressions'}</label>
                          <b>
                            <label>
                              <label>
                                {info?.type === 'pmp' ? info?.maxCpm : info?.impressions}
                              </label>
                            </label>
                          </b>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {kind === 'req-deal' && (
          <>
            <div className={'card-row'}>
              <div className={'card-row'}>
                <div className={'card-col me-2'}>
                  <p
                    className='align-items-center d-flex justify-content-center rounded-circle text-white'
                    style={{ fontSize: '9px', paddingTop: '2px' }}
                  >
                    {glossary.dsp_req_deal_card_icon}
                  </p>
                </div>
                <div className={'card-col'} style={{ width: '112px' }}>
                  <h6
                    className={'truncate'}
                    style={{
                      fontSize: '14px',
                      width: '130px',
                    }}
                    title={text}
                  >
                    {text}
                  </h6>
                </div>
              </div>
              {info?.userType === 'demand' && (
                <div className='card-row'>
                  <div className={'card-col'}>
                    {info?.status === 'pause' && (
                      <img
                        alt='status-pause'
                        src={pausedIcon}
                        width={8}
                        height={8}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    {info?.status === 'active' && (
                      <img
                        alt='status-active'
                        src={activeIcon}
                        width={8}
                        height={8}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    {info?.status === 'pending' && (
                      <img
                        alt='status-pending'
                        src={pendingIcon}
                        width={8}
                        height={8}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    {info?.status === 'offered' && (
                      <div
                        style={{
                          backgroundColor: '#731780',
                          height: '8px',
                          width: '8px',
                          borderRadius: '50%',
                          marginRight: 10,
                        }}
                      />
                    )}
                  </div>
                  <div className={'card-col'}>
                    <label className='card-status'>{info.statusFormatted}</label>
                  </div>
                </div>
              )}
            </div>
            {size === 'expanded' && (
              <div className='card-expanded'>
                <div className={'card-row'}>
                  <div className={'card-col'}>
                    <label>{glossary.dsp_req_deal_card_field_label_created_on}</label>
                    <b>
                      <label>{info?.created ?? '-'}</label>
                    </b>
                  </div>
                  <div className={'card-col'}>
                    <label>
                      {info?.items?.requested_deal_type === 'pmp'
                        ? glossary.dsp_req_deal_card_field_label_mincpm
                        : glossary.dsp_req_deal_card_field_label_cpm}
                    </label>
                    <b>
                      <label>{info?.minCpm ?? '-'}</label>
                    </b>
                  </div>
                  <div className={'card-col'}>
                    <label>
                      {info?.items?.requested_deal_type === 'pmp'
                        ? glossary.dsp_req_deal_card_field_label_maxcpm
                        : glossary.dsp_req_deal_card_field_label_impressions}
                    </label>
                    <b>
                      <label>
                        <label>
                          {info?.items?.requested_deal_type === 'pmp'
                            ? info?.maxCpm
                            : info?.items?.pg_impression_formatted ?? '-'}
                        </label>
                      </label>
                    </b>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {/* {kind === 'creative' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '10px' }}
                    >
                      EXT
                    </p>
                  </div>
                  <div className='expanded-row-title text-truncate' title={text}>
                    {text}
                  </div>
                </div>

                <div className='expanded-row-item date'>
                  {info?.items?.created_on_formatted ?? '-'}
                </div>
                <div className='expanded-row-item impression'>
                  {info?.items?.impression_formatted ?? '-'}
                </div>
                <div className='expanded-row-item floorprice'>
                  {info?.items?.floor_price_formatted ?? '-'}
                </div>
                <div className='expanded-row-item earnings'>
                  {info?.items?.earning_formatted ?? '-'}
                </div>

                <div className='expanded-row-item status'>
                  {info.status === 'pause' && (
                    <img src={pausedIcon} width={8} height={8} style={{ marginRight: 10 }} />
                  )}
                  {info.status === 'active' && (
                    <img src={activeIcon} width={8} height={8} style={{ marginRight: 10 }} />
                  )}
                  {info.status === 'pending' && (
                    <img src={pendingIcon} width={8} height={8} style={{ marginRight: 10 }} />
                  )}
                  {info.status === 'reject' && (
                    <img src={pendingIcon} width={8} height={8} style={{ marginRight: 10 }} />
                  )}
                  {info.status === 'on_hold' && (
                    <img src={pendingIcon} width={8} height={8} style={{ marginRight: 10 }} />
                  )}
                  <label style={{ fontWeight: 200 }}>
                    {info.status === 'pending' && glossary.card_status_pending}
                    {info.status === 'active' && glossary.card_status_active}
                    {info.status === 'pause' && glossary.card_status_paused}
                    {info.status === 'reject' && glossary.card_status_rejected}
                    {info.status === 'on_hold' && glossary.card_status_on_hold}
                  </label>
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' ? (
                  <>
                    <div className={'card-row'}>
                      <div className={'card-row'}>
                        <div className={'card-col me-2'}>
                          <p
                            className='align-items-center d-flex justify-content-center rounded-circle text-white'
                            style={{ fontSize: '10px' }}
                          >
                            EXT
                          </p>
                        </div>
                        <div className={'card-col'} style={{ width: '112px' }}>
                          <h6
                            className={'truncate'}
                            style={{
                              fontSize: '14px',
                              width: '130px',
                            }}
                            title={text}
                          >
                            {text}
                          </h6>
                        </div>
                      </div>
                      <div className={'card-col'}>
                        <div className={'card-row'}>
                          <div className={'card-col'}>
                            <div className={'card-row'}>
                              <div className={'card-col'}>
                                {info.status === 'pause' && (
                                  <img
                                    src={pausedIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'active' && (
                                  <img
                                    src={activeIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'pending' && (
                                  <img
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'reject' && (
                                  <img
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {info.status === 'on_hold' && (
                                  <img
                                    src={pendingIcon}
                                    width={8}
                                    height={8}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                              </div>
                              <div className={'card-col'}>
                                <label className='card-status'>
                                  {info.status === 'pending' && glossary.card_status_pending}
                                  {info.status === 'active' && glossary.card_status_active}
                                  {info.status === 'pause' && glossary.card_status_paused}
                                  {info.status === 'reject' && glossary.card_status_rejected}
                                  {info.status === 'on_hold' && glossary.card_status_on_hold}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={'card-expanded'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <label>{glossary.ssp_supply_card_field_label_floorprice}</label>
                          <b>
                            <label>{info?.items?.floor_price_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.ssp_supply_card_field_label_impressions}</label>
                          <b>
                            <label>{info?.items?.impression_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>{glossary.ssp_supply_card_field_label_earnings}</label>
                          <b>
                            <label>{info?.items?.earning_formatted ?? '-'}</label>
                          </b>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={'card-row'}>
                    <div className={'card-row'}>
                      <div className={'card-col me-2'}>
                        <p
                          className='align-items-center d-flex justify-content-center rounded-circle text-white'
                          style={{ fontSize: '10px' }}
                        >
                          EXT
                        </p>
                      </div>

                      <div className={'card-col'} style={{ width: '112px' }}>
                        <h6
                          className={'truncate'}
                          style={{
                            fontSize: '14px',
                            width: '130px',
                          }}
                          title={text}
                        >
                          {text}
                        </h6>
                      </div>
                    </div>
                    <div className={'card-col'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <div className={'card-row'}>
                            <div className={'card-col'}>
                              {info.status === 'pause' && (
                                <img
                                  src={pausedIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info.status === 'active' && (
                                <img
                                  src={activeIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info.status === 'pending' && (
                                <img
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info.status === 'reject' && (
                                <img
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                              {info.status === 'on_hold' && (
                                <img
                                  src={pendingIcon}
                                  width={8}
                                  height={8}
                                  style={{ marginRight: 10 }}
                                />
                              )}
                            </div>
                            <div className={'card-col'}>
                              <label className='card-status'>
                                {info.status === 'pending' && glossary.card_status_pending}
                                {info.status === 'active' && glossary.card_status_active}
                                {info.status === 'pause' && glossary.card_status_paused}
                                {info.status === 'reject' && glossary.card_status_rejected}
                                {info.status === 'on_hold' && glossary.card_status_on_hold}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )} */}

        {kind === 'creative' && (
          <>
            {expandClass ? (
              <div className='expanded-row'>
                <div className='expanded-row-item title'>
                  <div className={'card-col me-2'}>
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white'
                      style={{ fontSize: '10px' }}
                    >
                      EXT
                    </p>
                  </div>
                  <div className='expanded-row-title text-truncate' title={text}>
                    {text}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {size === 'expanded' ? (
                  <>
                    <div className={'card-row'}>
                      <div className={'card-row'}>
                        <div className={'card-col'} style={{ width: '112px' }}>
                          <h6
                            className={'truncate'}
                            style={{
                              fontSize: '14px',
                              width: '236px',
                            }}
                            title={text}
                          >
                            {text}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className={'card-expanded'}>
                      <div className={'card-row'}>
                        <div className={'card-col'}>
                          <label>Tag Impressions</label>
                          <b>
                            <label>{info?.items?.floor_price_formatted ?? '-'}</label>
                          </b>
                        </div>
                        <div className={'card-col'}>
                          <label>Tag Spend ($)</label>
                          <b>
                            <label>{info?.items?.floor_price_formatted ?? '-'}</label>
                          </b>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={'card-row'}>
                    <div className={'card-row'}>
                      <div className={'card-col me-2'}>
                        <p
                          className='align-items-center d-flex justify-content-center rounded-circle text-white'
                          style={{ fontSize: '10px' }}
                        >
                          EXT
                        </p>
                      </div>

                      <div className={'card-col'} style={{ width: '112px' }}>
                        <h6
                          className={'truncate'}
                          style={{
                            fontSize: '14px',
                            width: '130px',
                          }}
                          title={text}
                        >
                          {text}
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
