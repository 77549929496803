const Box = () => {
    return (
        <>
            <div className='skeleton'>
                <div className='skeleton__box skeleton__comman'></div>
            </div>
        </>
    );
};

export default Box;
