import { useState, useEffect, useContext } from 'react';
import JWT from 'jsonwebtoken';
import Radio from '../../widgets/radio';
import SendEmail from '../../comman/sendEmail/sendEmail';
import MacrosDropDown from '../../helpers/MacrosDropDown';
import { toast } from 'react-toastify';

import { IoCopyOutline } from 'react-icons/io5';
import { MdInfoOutline } from 'react-icons/md';
import { UserInfoContext } from '../../contexts/userInfoContext';
//import AdsTxt from '../../comman/adsTxt/AdsTxt';

const TagDetails = ({
  tagId,
  createTag,
  selectedTagDetails,
  glossary,
  type,
  records,
  createID,
  selectedAdTagFormat,
  selectedAdPlacement,
}: any) => {
  const { genuin }: any = useContext(UserInfoContext);

  const [selectedTag, sSelectedTag] = useState(selectedAdTagFormat || '1');
  const [selectedAd, sSelectedAd] = useState(selectedAdPlacement || '1');
  const [appParamFinalUrl, setAppParamFinalUrl] = useState('');
  const [siteParamFinalUrl, setSiteParamFinalUrl] = useState('');
  const [impParamFinalUrl, setImpParamFinalUrl] = useState('');
  const [deviceParamFinalUrl, setDeviceParamFinalUrl] = useState('');
  const [contentParamFinalUrl, setContentParamFinalUrl] = useState('');
  const [siteContentParamFinalUrl, setSiteContentParamFinalUrl] = useState('');
  const [userParamFinalUrl, setUserParamFinalUrl] = useState('');
  const [customParamFinalUrl, setCustomParamFinalUrl] = useState('');
  const [selectedAppParamFinalUrl, setSelectedAppParamFinalUrl] = useState('');
  const [selectedSiteParamFinalUrl, setSelectedSiteParamFinalUrl] = useState('');
  const [companyID, sCompanyID] = useState();

  /* Get Company id from JWT token */
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    sCompanyID(decoded.payload.company_id);
  }, []);
  /* Get Company id from JWT token */
  const selectTag = (id: string) => sSelectedTag(id);
  const selectAd = (id: string) => sSelectedAd(id);

  const globalUrl = `${genuin.serverURL}/tagxml/customer/${companyID}/tag/${tagId}?`;
  const globalUrlCreated = `${genuin.serverURL}/tagxml/customer/${companyID}/tag/${createID}?`;

  const tagFinalURL = (
    globalUrl +
    appParamFinalUrl +
    siteParamFinalUrl +
    impParamFinalUrl +
    deviceParamFinalUrl +
    contentParamFinalUrl +
    siteContentParamFinalUrl +
    userParamFinalUrl +
    customParamFinalUrl
  ).replace('?&', '?');

  const tagAppFinalURLCreated = (
    globalUrlCreated +
    selectedAppParamFinalUrl +
    appParamFinalUrl +
    impParamFinalUrl +
    deviceParamFinalUrl +
    contentParamFinalUrl +
    siteContentParamFinalUrl +
    userParamFinalUrl +
    customParamFinalUrl
  ).replace('?&', '?');

  const tagWebFinalURLCreated = (
    globalUrlCreated +
    selectedSiteParamFinalUrl +
    siteParamFinalUrl +
    impParamFinalUrl +
    deviceParamFinalUrl +
    contentParamFinalUrl +
    siteContentParamFinalUrl +
    userParamFinalUrl +
    customParamFinalUrl
  ).replace('?&', '?');

  const GetAppParamFinalUrl = (data: any) => {
    setAppParamFinalUrl(data);
  };

  const GetSiteParamFinalUrl = (data: any) => {
    setSiteParamFinalUrl(data);
  };

  const GetImpParamUFinalUrl = (data: any) => {
    setImpParamFinalUrl(data);
  };

  const GetDeviceParamFinalUrl = (data: any) => {
    setDeviceParamFinalUrl(data);
  };

  const GetContentParamFinalUrl = (data: any) => {
    setContentParamFinalUrl(data);
  };

  const GetSiteContentParamFinalUrl = (data: any) => {
    setSiteContentParamFinalUrl(data);
  };

  const GetUserParamFinalUrl = (data: any) => {
    setUserParamFinalUrl(data);
  };

  const GetCustomParamFinalUrl = (data: any) => {
    setCustomParamFinalUrl(data);
  };

  const GetSelectedAppParamFinalUrl = (data: any) => {
    setSelectedAppParamFinalUrl(data);
  };

  const GetSelectedSiteParamFinalUrl = (data: any) => {
    setSelectedSiteParamFinalUrl(data);
  };
  return (
    <>
      <div className='row mb-3'>
        <SendEmail tagId={tagId} glossary={glossary} />
        {selectedTagDetails && (
          <div className='col-6'>
            <div className='row mt-2'>
              <div className={'col-2'}>
                <label className='label'>
                  {glossary.ssp_createtag_success_field_label_floor_price}
                </label>
                <p>{selectedTagDetails?.floor_price_formatted}</p>
              </div>
              <div className={'col-2'}>
                <label className='label'>
                  {glossary.ssp_createtag_success_field_label_is_soft}
                </label>
                <p>{selectedTagDetails?.is_soft_floor === true ? 'On' : 'Off'}</p>
              </div>
              <div className={'col-auto'}>
                <label className='label'>
                  {glossary.ssp_createtag_success_field_label_duration_range}
                </label>
                <p>
                  {selectedTagDetails?.config?.min_duration}-
                  {selectedTagDetails?.config?.max_duration}
                </p>
              </div>
              <div className={'col-auto'}>
                <label className='label'>Slot Count</label>
                <p>{selectedTagDetails?.config?.slot_count}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={'row mb-3'}>
        <label className='form-label mb-0'>{glossary.ssp_createtag_success_tag_format_label}</label>
        <div className={'col-auto mt-0'}>
          <Radio
            size={'mini'}
            label={glossary.ssp_createtag_success_radio_button_url}
            id={'1'}
            selected={selectedTag}
            click={selectTag}
          />
        </div>
        <div className={'col-auto mt-0'}>
          <Radio
            size={'mini'}
            label={glossary.ssp_createtag_success_radio_button_xml}
            id={'2'}
            selected={selectedTag}
            click={selectTag}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <label className='form-label'>{glossary.ssp_createtag_field_label_ad_placement}</label>
        <div className='d-flex gap-3 disable-field'>
          <div className={'col-auto mt-0'}>
            <Radio
              size={'mini'}
              label={glossary.ssp_createtag_radio_button_pre_roll}
              id={'preroll'}
              selected={selectedAd}
              click={selectAd}
            />
          </div>
          <div className={'col-auto mt-0'}>
            <Radio
              size={'mini'}
              label={glossary.ssp_createtag_radio_button_mid_roll}
              id={'midroll'}
              selected={selectedAd}
              click={selectAd}
            />
          </div>
          <div className={'col-auto mt-0'}>
            <Radio
              size={'mini'}
              label={glossary.ssp_createtag_radio_button_end_roll}
              id={'endroll'}
              selected={selectedAd}
              click={selectAd}
            />
          </div>
        </div>
      </div>
      <MacrosDropDown
        createTag={createTag}
        selectedTagDetails={selectedTagDetails}
        deviceType={type}
        appParamFinalUrl={GetAppParamFinalUrl}
        siteParamFinalUrl={GetSiteParamFinalUrl}
        impParamUFinalUrl={GetImpParamUFinalUrl}
        deviceParamFinalUrl={GetDeviceParamFinalUrl}
        contentParamFinalUrl={GetContentParamFinalUrl}
        siteContentParamFinalUrl={GetSiteContentParamFinalUrl}
        userParamFinalUrl={GetUserParamFinalUrl}
        customParamFinalUrl={GetCustomParamFinalUrl}
        selectedAppParamFinalUrl={GetSelectedAppParamFinalUrl}
        selectedSiteParamFinalUrl={GetSelectedSiteParamFinalUrl}
      />
      <div className={'row align-items-start'}>
        <div className={'col-12'}>
          <div className='createtag-vasturl'>
            {selectedTag === '1' && (
              <>
                {createTag ? (
                  <>
                    <p className='createtag-macrosurl'>
                      {type === 'app' ? tagAppFinalURLCreated : tagWebFinalURLCreated}
                    </p>
                  </>
                ) : (
                  <p className='createtag-macrosurl'>{tagFinalURL}</p>
                )}
                {createTag ? (
                  <div className='createtag-vasturl-icon'>
                    <IoCopyOutline
                      className='svg-icon'
                      onClick={() => {
                        navigator.clipboard.writeText(
                          type === 'app' ? tagAppFinalURLCreated : tagWebFinalURLCreated
                        );
                        toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                      }}
                      title={'Copy URL'}
                      fontSize={20}
                    />
                  </div>
                ) : (
                  <div className='createtag-vasturl-icon'>
                    <IoCopyOutline
                      className='svg-icon'
                      onClick={() => {
                        navigator.clipboard.writeText(tagFinalURL);
                        toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                      }}
                      title={'Copy URL'}
                      fontSize={20}
                    />
                  </div>
                )}
              </>
            )}
            {selectedTag === '2' && (
              <>
                {createTag ? (
                  <p>
                    {`<VAST
                                    xmlns="http://www.iab.com/VAST" version="3.0">
                                    <Ad id="[ad_id]">
                                        <Wrapper>
                                            <AdSystem version="3.0">
                                                <![CDATA[ Infy.tv ]]>
                                            </AdSystem>
                                            <Extensions/>
                                            <Creatives/>
                                            <VASTAdTagURI>
                                                <![CDATA[ ${
                                                  type === 'app'
                                                    ? tagAppFinalURLCreated
                                                    : tagWebFinalURLCreated
                                                } ]]>
                                            </VASTAdTagURI>
                                        </Wrapper>
                                    </Ad>
                                </VAST>`}
                  </p>
                ) : (
                  <p>
                    {`<VAST
                                    xmlns="http://www.iab.com/VAST" version="3.0">
                                    <Ad id="[ad_id]">
                                        <Wrapper>
                                            <AdSystem version="3.0">
                                                <![CDATA[ Infy.tv ]]>
                                            </AdSystem>
                                            <Extensions/>
                                            <Creatives/>
                                            <VASTAdTagURI>
                                                <![CDATA[ ${tagFinalURL} ]]>
                                            </VASTAdTagURI>
                                        </Wrapper>
                                    </Ad>
                                </VAST>`}
                  </p>
                )}
                {createTag ? (
                  <div className='createtag-vasturl-icon'>
                    <IoCopyOutline
                      className='svg-icon'
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `<VAST
                                    xmlns="http://www.iab.com/VAST" version="3.0">
                                    <Ad id="[ad_id]">
                                        <Wrapper>
                                            <AdSystem version="3.0">
                                                <![CDATA[ Infy.tv ]]>
                                            </AdSystem>
                                            <Extensions/>
                                            <Creatives/>
                                            <VASTAdTagURI>
                                                <![CDATA[ ${tagFinalURL} ]]>
                                            </VASTAdTagURI>
                                        </Wrapper>
                                    </Ad>
                                </VAST>`
                          )
                          .toString();
                        toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                      }}
                      title={'Copy URL'}
                      fontSize={20}
                    />
                  </div>
                ) : (
                  <div className='createtag-vasturl-icon'>
                    <IoCopyOutline
                      className='svg-icon'
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `<VAST
                                  xmlns="http://www.iab.com/VAST" version="3.0">
                                  <Ad id="[ad_id]">
                                      <Wrapper>
                                          <AdSystem version="3.0">
                                              <![CDATA[ Infy.tv ]]>
                                          </AdSystem>
                                          <Extensions/>
                                          <Creatives/>
                                          <VASTAdTagURI>
                                              <![CDATA[ ${
                                                type === 'app'
                                                  ? tagAppFinalURLCreated
                                                  : tagWebFinalURLCreated
                                              } ]]>
                                          </VASTAdTagURI>
                                      </Wrapper>
                                  </Ad>
                              </VAST>`
                          )
                          .toString();
                        toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                      }}
                      title={'Copy URL'}
                      fontSize={20}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <label className='form-label mt-1'>
            <MdInfoOutline className='s-svg-icon me-1' fontSize={20} />
            <span>{glossary.ssp_createtag_success_instruction_one}</span>
          </label>
        </div>
        {/* Temporary hide Ads.txt */}
        {/* <div className={'col'}>
          <AdsTxt records={records} glossary={glossary} />
        </div> */}
      </div>
      <div className={'row align-items-start mt-0'}>
        <div className='col'>
          <a
            href='https://help.infy.tv/en/articles/7046545-how-to-integrate-vast-tag-in-the-player'
            target='_blank'
            rel='noreferrer'
            style={{ width: 'fit-content' }}
          >
            Read more
          </a>
        </div>
      </div>
    </>
  );
};

export default TagDetails;
