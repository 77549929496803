import { Modal } from 'react-bootstrap';
import Button from '../widgets/button';
// import { AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai';

interface VideoPlayerProps {
  show: boolean;
  onHide(): void;
  video?: string;
}

function VideoPlayer({ show, onHide, video = '' }: VideoPlayerProps) {
  return (
    <>
      <Modal show={show} onHide={onHide} size='lg' centered>
        <Modal.Body style={{ height: '500px' }} className='p-3'>
          <video
            width={'100%'}
            height={'100%'}
            // autoPlay
            // muted
            // onEnded={handleVideoEnd}
            controls
            controlsList='nodownload'
            disablePictureInPicture
            // style={{ borderRadius: '15px' }}
          >
            <source src={video} type={'video/mp4'} />
          </video>
        </Modal.Body>
        <Modal.Footer className='py-2 gap-2'>
          <Button kind='secondary' type='button' text='Close' click={onHide} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VideoPlayer;
