import { useContext, useState } from 'react';
import ReactJoyride, { TooltipRenderProps } from 'react-joyride';
import Button from '../../widgets/button';
import { updateTourAPI } from '../../../services/tags';
// import { useIntercom } from 'react-use-intercom';
// import JWT from 'jsonwebtoken';
import { UserInfoContext } from '../../contexts/userInfoContext';

const TourAfterCreateCardDSP = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
}: TooltipRenderProps) => (
  <div {...tooltipProps} style={{ background: 'white', padding: 24, borderRadius: 5 }}>
    <p style={{ textAlign: 'left', fontWeight: 200 }}>{step.title}</p>
    <br />
    <p style={{ textAlign: 'left' }}>{step.content}</p>
    <br />
    <br />
    <div className='d-flex justify-content-between'>
      <div className='d-flex gap-3 align-items-center'>
        <div {...skipProps}>
          <Button kind={'secondary'} text={'Skip'} />
        </div>
        <div>
          {step.target === '.tour-finance' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-spending' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-settings' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-addnew-dsp' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.overview-tiles' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-chart' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-notif' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-help' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
        </div>
      </div>
      <div className='d-flex'>
        {index > 0 && (
          <div {...backProps}>
            <Button kind={'link'} text={'Prev'} />
          </div>
        )}
        {continuous && index !== 7 && (
          <div {...primaryProps}>
            <Button kind={'primary'} text={'Next'} />
          </div>
        )}
        {continuous && index === 7 && (
          <div {...closeProps}>
            <Button kind={'primary'} text={"Let's Go"} />
          </div>
        )}
        {!continuous && (
          <div {...closeProps}>
            <Button kind={'primary'} text={'Close'} />
          </div>
        )}
      </div>
    </div>
  </div>
);

const TourCardDSP = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
}: TooltipRenderProps) => (
  <div {...tooltipProps} style={{ background: 'white', padding: 24, borderRadius: 5 }}>
    <p style={{ textAlign: 'left', fontWeight: 200 }}>{step.title}</p>
    <br />
    <p style={{ textAlign: 'left' }}>{step.content}</p>
    <br />
    <br />
    <div className='d-flex justify-content-between'>
      <div className='d-flex gap-3 align-items-center'>
        <div {...skipProps}>
          <Button kind={'secondary'} text={'Skip'} />
        </div>
        <div>
          {step.target === '.tour-settings' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-rtb-endpoint' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-hb-connector' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-notif' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-help' && (
            <a
              href='https://help.infy.tv/en/collections/3796998-faq'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
        </div>
      </div>
      <div className='d-flex'>
        {index > 0 && (
          <div {...backProps}>
            <Button kind={'link'} text={'Prev'} />
          </div>
        )}
        {continuous && index !== 5 && (
          <div {...primaryProps}>
            <Button kind={'primary'} text={'Next'} />
          </div>
        )}
        {continuous && index === 5 && (
          <div {...closeProps}>
            <Button kind={'primary'} text={"Let's Go"} />
          </div>
        )}
        {!continuous && (
          <div {...closeProps}>
            <Button kind={'primary'} text={'Close'} />
          </div>
        )}
      </div>
    </div>
  </div>
);

interface Props {
  tourDoneProps?: any;
  endPointStatus?: any;
  loader?: any;
  steps?: any;
}

const DemandTour = (props: Props) => {
  // Context API
  const { setUserInfo, userInfo }: any = useContext(UserInfoContext);

  const { endPointStatus, loader, steps, tourDoneProps } = props;

  if (!userInfo?.user?.tour) {
    // If it's empty or undefined, add a class to the body
    document.body.classList.add('tour-empty');
  } else {
    // If it's not empty, remove the class from the body
    document.body.classList.remove('tour-empty');
  }

  const [tourAfterCreateEndpointStepsDSP] = useState<any>([
    {
      target: '.tour-finance',
      title: 'Manage Your Finance',
      content: 'Shortcut to manage your finance, add paymemt details and manage payouts.',
    },
    {
      target: '.tour-spending',
      title: 'Manage Your Spending',
      content:
        'Stay on top of your finances with real-time access to your Spending balance at any time.',
    },
    {
      target: '.tour-settings',
      title: 'Settings',
      content: 'Click on settings to manage your profile, company and financial settings.',
      placement: 'bottom',
    },
    {
      target: '.tour-addnew-dsp',
      title: 'Add New Endpoint',
      content: 'Create new endpoint.',
      placement: 'right',
    },
    {
      target: '.overview-tiles',
      title: 'Overview',
      content: 'You can check Impressions, Average CPM, Spending and Average VCR of your source.',
    },
    {
      target: '.tour-chart',
      title: 'Impression',
      content: 'Here you can get performace visualisation of your source.',
    },
    {
      target: '.tour-notif',
      title: 'Notification',
      content: 'Check your notification regularly for updates on your platform.',
      placement: 'left',
    },
    {
      target: '.tour-help',
      title: 'Help',
      content:
        'Get instant assistance with all your queries by reaching out to our dedicated support team.',
      placement: 'left',
    },
  ]);
  const [tourStepsDSP] = useState<any>([
    {
      target: '.tour-settings',
      title: 'Settings',
      content: 'Click on settings to manage your profile, company and financial settings.',
    },

    {
      target: ".on-bording-tabs > [data-rb-event-key='RTBEndpoint']",
      title: 'Create RTB EndPoint',
      content:
        'Fill in the "Default Network Parameters" fields with the information that you obtained from your demand partner.',
      placement: 'bottom',
    },
    {
      target: ".on-bording-tabs > [data-rb-event-key='HeaderBidder']",
      title: 'Create Header Bidder',
      content:
        'Fill in the "Default Adapter Parameters" fields with the information that you obtained from your header bidding connector.',
      placement: 'top',
    },
    {
      target: ".on-bording-tabs > [data-rb-event-key='InsertionOrder']",
      title: 'Create Insertion Order',
      content:
        'Fill in the "Default Network Parameters" fields with the information that you obtained from your demand partner.',
      placement: 'bottom',
    },
    {
      target: '.tour-notif',
      title: 'Notification',
      content: 'Check your notification regularly for updates on your platform.',
      placement: 'left',
    },
    {
      target: '.tour-help',
      title: 'Help',
      content:
        'Get instant assistance with all your queries by reaching out to our dedicated support team.',
      placement: 'left',
    },
  ]);
  const [tourDone, sTourDone] = useState('');

  // const { update, boot } = useIntercom(); //Intercom

  const handleTourCallback = (data: any) => {
    const { action } = data;
    if (action === 'close' || action === 'skip') {
      updateTourAPI({ tour: 'done' })
        .then((data: any) => {
          setUserInfo((prev: any) => ({
            ...prev,
            user: {
              ...prev.user,
              tour: 'done',
            },
          }));
          sTourDone(data.status);

          //Intercom
          // if (data.status) {
          //   const token = localStorage.getItem('token') || '';
          //   const decoded = JWT.decode(token, { complete: true });

          //   // @ts-ignore
          //   const user_id = decoded?.payload?.user_id;
          //   // @ts-ignore
          //   const customer_id = decoded?.payload?.company_id;
          //   // @ts-ignore
          //   const email = decoded?.payload?.identity;
          //   // @ts-ignore
          //   const userName = JSON.parse(localStorage?.getItem('user'))?.firstName ?? '';
          //   bootWithProps({ user_id, customer_id, email, userName });
          // }
        })
        .catch(() => {
          //
        });
    }
  };

  // //Intercom
  // const bootWithProps = ({ user_id, customer_id, email, userName }: any) => {
  //   //@ts-ignore
  //   boot({
  //     name: userName,
  //     email: email,
  //     customAttributes: { user_id: user_id, customerID: customer_id },
  //   });
  // };

  props.tourDoneProps(tourDone);

  return (
    <>
      {!endPointStatus && loader && steps && (
        <ReactJoyride
          callback={handleTourCallback}
          continuous={true}
          steps={tourStepsDSP}
          spotlightClicks={true}
          tooltipComponent={TourCardDSP}
          showSkipButton={true}
          disableScrolling={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
        />
      )}
      {endPointStatus && loader && steps && (
        <ReactJoyride
          callback={handleTourCallback}
          continuous={true}
          steps={tourAfterCreateEndpointStepsDSP}
          spotlightClicks={true}
          tooltipComponent={TourAfterCreateCardDSP}
          showSkipButton={true}
          disableScrolling={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
        />
      )}
    </>
  );
};

export default DemandTour;
