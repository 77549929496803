export const headers: any = {
  'Content-Type': 'application/json',
};

export let api = 'https://business.qa.begenuin.com/';

if (api.endsWith('/')) {
  api = api.substring(0, api.length - 1);
}

export const routes = {
  login: api + '/user/login',
  findTeams: api + '/find-team',
  signup: api + '/user/register',
  setPassword: api + '/user/setpassword',
  generateToken: api + '/generate-token',
  changePassword: api + '/user/change-password',
  userTokenVerify: api + '/user/verify_token',
  getUserInfo: api + '/info',
  overview: api + '/reports/v2/fb/tags/overview',
  getTagDetails: api + '/reports/v2/fb/tag',
  createTag: api + '/tag/add',
  editTag: api + '/tag/edit',
  sendEmail: api + '/tag/sendmail',
  getTags: api + '/tags',
  updateTag: api + '/tag/status/update',
  getCustomers: api + '/customers',
  getCustomer: api + '/reports/v2/fb/customer',
  getEndpoints: api + '/end_point',
  getEndpoint: api + '/reports/endpoint',
  getEndpointReport: api + '/reports/v2/fb/endpoint',
  getPartnerReport: api + '/reports/v2/fb/partner',
  inviteCustomer: api + '/invite/customers',
  validateCustomer: api + '/customer/approve',
  updateCustomer: api + '/admin/customer/update',
  getCustomerInfo: api + '/customer-info',
  getCustomerUsers: api + '/users',
  getCustomerUser: api + '/users-by-id',
  approveUser: api + '/user/approve',
  getPaymentMethods: api + '/payment_methods',
  getPaymentDetails: api + '/payment_details',
  setPaymentDetails: api + '/payment_detail/update',
  getPayoutRequests: api + '/payout_requests',
  getPayouts: api + '/admin/payout_requests',
  getSources: api + '/admin/sources',
  payoutRequestUpdate: api + '/payout_request/update',
  paymentProofUpload: api + '/payment_proof/upload',
  updateAdConfig: api + '/reports/ads_config',
  uploadProfilePhoto: api + '/profile_photo/upload',
  deleteProfilePhoto: api + '/profile_photo/remove',
  uploadCompanyPhoto: api + '/customer_photo/upload',
  uploadProfileImage: api + '/profile_photo/v2/upload',
  uploadCustomerImage: api + '/customer_photo/v2/upload',
  inviteUser: api + '/invite/users',
  updateUser: api + '/user/update',
  updateAccount: api + '/customer/update',
  payables: api + '/payables',
  receivables: api + '/receivables',
  earnings: api + '/earnings',
  platformCost: api + '/platform_cost',
  addPaymentMethod: api + '/payment_method/add',
  deletePaymentMethod: api + '/payment_method/delete',
  updatePaymentMethod: api + '/payment_method/update',
  getGlossary: api + '/lang-wise-glossary/en',
  updateGlossary: api + '/lang-glossary/update',
  addGlossary: api + '/lang-glossary/add',
  getNotifications: api + '/notification_toggle_list',
  getManagers: api + '/admin/managers',
  updateRole: api + '/role/update',
  updateTour: api + '/frontend-tour/update',
  updateTagName: api + '/tag/edit',
  payRequest: api + '/payout_request/add',
  getCountries: api + '/countries',
  getStates: api + '/states',
  getCities: api + '/cities',
  getInventories: api + '/inventories-groups',
  getDeals: api + '/deals',
  createDeal: api + '/deal/add',
  dealInventories: api + '/deal/inventories',
  deleteTag: api + '/tag/delete',
  deleteUser: api + '/company_user/remove',
  notifications: api + '/notifications',
  inventoryOverview: api + '/inventories/overview',
  sspInventoryOverview: api + '/publisher-inventories/overview',
  inventoryCards: api + '/inventories_cards',
  editDeal: api + '/deal/update',
  deleteDeal: api + '/deal/delete',
  viewNotification: api + '/notification/view',
  viewNotifications: api + '/notification/viewall',
  addCustomers: api + '/deal/add-customers',
  getDealCustomers: api + '/deal/customers',
  removeCustomer: api + '/deal/remove-customers',
  inviteClient: api + '/invite/ssp-dsp',
  customerStatus: api + '/customers/update',
  loginCustomer: api + '/customer/login',
  reportToggleStatusChange: api + '/reports/v2/fb/ads_config/change_status',
  getTrafficDashboard: api + '/reports/traffic-dashboard',
  inventoryToggleInclude: api + '/reports/ads_config/add',
  inventoryToggleExclude: api + '/reports/ads_config/remove',
  trafficDashToggleInclude: api + '/utility/traffic-dashboard/include',
  trafficDashToggleExclude: api + '/utility/traffic-dashboard/exclude',
  trafficDashTrafficMultiplier: api + '/utility/traffic-dashboard/traffic_multiplier',
  getBidderList: api + '/utility/hb_connectors',
  updateEndpoint: api + '/end_point/update',
  addEndpoint: api + '/end_point/add',
  getEndpointList: api + '/end_point/endpoints',
  getHbCode: api + '/tag/generate_hb_code',
  getEndpointTags: api + '/end_point/tags',
  sourceTagsExclude: api + '/end_point/source/exclude',
  sourceTagsInclude: api + '/end_point/source/include',
  getPublisherAdstxt: api + '/adtxt/get_ssp',
  addPublisherAdstxt: api + '/adtxt/add_ssp',
  demandAdTxtAdd: api + '/adtxt/add',
  getDemandAdTxt: api + '/adtxt/get_dsp',
  demandAdsTxtRemove: api + '/adtxt/remove_dsp',
  sspEndpointToggleInactive: api + '/end_point/endpoint/inactive',
  sspEndpointToggleActive: api + '/end_point/endpoint/active',
  getDemandEndpointInventories: api + '/endpoint-inventories',
  getPlatformMacrosList: api + '/platform_macros/get_list',
  addPlatformMacros: api + '/platform_macros/add',
  updatePlatformMacros: api + '/platform_macros/update',
  removePlatformMacros: api + '/platform_macros/remove',
  getObjectMacrosList: api + '/platform_macros/get_object_list',
  clearPublisherAdstxt: api + '/adtxt/reset_ssp',
  updateEndpointTagPercentage: api + '/end_point/tag/update_percentage',
  getPublisherInventories: api + '/publisher-inventories',
  getPublisherDealDemand: api + '/end_point/sub_endpoints',
  sspAssignedDealDemandList: api + '/deal/endpoints',
  sspDemandAssignToDeal: api + '/deal/add-endpoints',
  sspRemoveDemandFromDeal: api + '/deal/remove-endpoints',
  createMsaSignForm: api + '/docu_sign/sign_msa',
  getMsaDoc: api + '/docu_sign/get_doc',
  getMsaDetails: api + '/docu_sign/msa_details',
  updateTagSetting: api + '/tag',
  sspInventoriesCard: api + '/publisher_inventories_cards',
  getGAMList: api + '/gam/ad_units?show_subrows=true',
  postGAMLogin: api + '/gam/user_login',
  GAMLogout: api + '/gam/user_logout',
  adUnitGAMId: api + '/gam/ad_units',
  getVastXml: api + '/end_point',
  getPlatFormCost: api + '/customers',
  updatePlatFormCost: api + '/customers/set_platform_cost',
  getManagersList: api + '/customer',
  assignManagerToCustomer: api + '/company_manager',
  resetTour: api + '/user/reset-tour',
  receivableAccept: api + '/receivable/accept',
  dspRequestDealAdd: api + '/requested_deal/add',
  getRequestedDealList: api + '/requested_deal/list',
  deleteRequestedDeal: api + '/requested_deal/remove',
  updateRequestedDeal: api + '/requested_deal/update',
  assignRequestedDeal: api + '/requested_deal/deal/add',
  unassignRequestedDeal: api + '/requested_deal/deal/remove',
  getRequestedDealInventories: api + '/requested_deal/deal_inventories',
  reqDealInclude: api + '/requested_deal/deal_inventories/remove',
  reqDealExclude: api + '/requested_deal/deal_inventories/add',
  verifyExtension: api + '/extension/verify',
  createExtension: api + '/extension/tag/create',
  updateExtension: api + '/extension/tag/update',
  sendOtpExtension: api + '/extension/send_otp',
  verifyOtpExtension: api + '/extension/verify_otp',
  extentionDetails: api + '/reports/extension',
  getHeaderBidderEmail: api + '/utility/hb_connectors/info',
  receiptDownload: api + '/finance/receipt/download',
  invoiceDownload: api + '/finance/invoice/download',
  sendInvoice: api + '/invoice/send',
  sendReminder: api + '/payout_request/send_reminder',
  getRtbArtistList: api + '/rtb_values/artist',
  getRtbTitleList: api + '/rtb_values/title',
  getPublishersList: api + '/publishers',
  getRtbGenreList: api + '/rtb_values/genre',
  updateTagFloorprice: api + '/end_point/tag/update_floor_price',
  HBLoginUrl: api + '/send_mail/get_login_url',
  HBSendEmailLogin: api + '/send_mail/login',
  getHBlogout: api + '/send_mail/logout',
  getHBSendEmails: api + '/send_mail/send_emails',

  //get publisher list in modal
  customerSPO: api + '/publisher', // spo login
  customerSSP: api + '/spo', //ssp login

  //get sidebar lis
  customerListSPO: api + '/spo/assigns', // spo login
  customerListSSP: api + '/publisher/assigns', //ssp login

  //invite publisher
  customerInviteSPO: api + '/publisher/invite', // spo login
  customerInviteSSP: api + '/spo/invite', // ssp login
  customerReInviteList: api + '/partner', // reinvite customer list
  customerReinvite: api + '/partner/invite/users', // reinvite user
  customerApprove: api + '/publisher/active',
  customerReject: api + '/publisher/reject',

  //sidebar action
  customerPauseSPO: api + '/publisher/pause', //spo login
  customerActiveSPO: api + '/publisher/active', //spo login
  customerPauseSSP: api + '/spo/pause', //ssp login
  customerActiveSSP: api + '/spo/active', //ssp login

  //get tag list for spo publisher
  tagListSSP: api + '/spo/tags', //ssp login

  //table action for tag list
  excludeTagSSP: api + '/spo/tag/exclude', //ssp login
  includeTagSSP: api + '/spo/tag/include', //ssp login
  updateTagSSP: api + '/spo/tag/update', //ssp login

  //table row loggle for spo/ssp tags
  toggleTagSSP: api + '',

  partnerOverview: api + '/reports/v2/fb/partners/overview',
  inviteCheckEmail: api + '/user/check_email',

  uploadCreative: api + '/utility/upload/video',
  loginWithGoogle: api + '/google/login',
  demandOverview: api + '/reports/v2/fb/endpoints/overview',
  dealOverview: api + '/reports/v2/fb/deals/overview',
  getDealDetails: api + '/reports/v2/fb/deal/',
  getLoopList: api + '/extension/get_loop_list',
  activeRequestDeal: api + '/requested_deal/status/active',
  offerDealToAgencies: api + '/deal/offer',
  getPartnersFilter: api + '/partners/filter_list',

  // Stripe
  getCustomerStripeLink: api + '/stripe/get_customer_link',
  getPublisherStripeAccount: api + '/stripe/get_publisher_account_link',
  postPaymentMethod: api + '/stripe/set_payment_method',
  getSubscriptions: api + '/stripe/creative/checkout_session',
  getCreativeStripeLink: api + '/stripe/creative/get_customer_link',

  //ads
  setAdsSubdomain: api + '/set_ads_domain',

  // New Finance Fireboltt
  publisherPayout: api + '/finance/publisher/earning',
  publisherPlatformCost: api + '/finance/publisher/platform_cost',
  publisherEarningByPartner: api + '/finance/publisher/earning_by_partner',

  spoSpending: api + '/finance/spo/spending',
  spoPlatformCost: api + '/finance/spo/platform_cost',
  spoEarningByPartner: api + '/finance/spo/earning_by_partner',

  demandSpending: api + '/finance/demand/spending',

  adminPlatformCost: api + '/finance/admin/platform_cost',
  adminEarningByPartner: api + '/finance/admin/earning_by_partner',

  raiseDispute: api + '/finance/dispute/raise',
  resolveDispute: api + '/finance/dispute/solve_dispute',
  rejectDispute: api + '/finance/dispute/reject',

  adReel: api + '/tag/cxr/upsert',

  // Creatives User
  creativeSendOtp: api + '/genuin/send_otp',
  creativeVerifyOtp: api + '/genuin/verify_otp',
  creativeCompleteProfile: api + '/genuin/complete_profile',
  creativeUserValidate: api + '/genuin/validate_user',
  creativeLooplist: api + '/genuin/get_loop_list',
  creativeUserProfile: api + '/genuin/get_profile_details',
  creativeLoopVideos: api + '/genuin/get_loop_videos',
  creativeProfileVideos: api + '/genuin/get_profile_videos',
  creativeSetVideoLibrary: api + '/genuin/set_video_library',
  creativeSetSocialProfile: api + '/genuin/set_brand_integration',
  creativeGetSocialProfile: api + '/genuin/get_brand_integration',
  creativeGetSocialProfileNew: api + '/genuin/get_social_profile',
  creativeAddSocialProfile: api + '/genuin/add_social_profile',
  creativeRemoveSocialProfile: api + '/genuin/remove_social_profile',
  creativeSyncSocialProfile: api + '/genuin/sync_social_profile',
  creativeBrandConfigLogoUpload: api + '/utility/upload/photo',
  creativeAgencyInfoUpdate: api + '/genuin/agency_info',
  creativeProfileVideoUpload: api + '/genuin/upload/profile_video',
  creativeGetCommunitiesList: api + '/genuin/communities',
  creativeGetCommunityLoop: api + '/genuin/community/loops',
};
