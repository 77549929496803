import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { inviteClientAPI } from '../../services/customers';
import store from '../../states';

import Button from '../widgets/button';

export default ({ id, onHide, show }: any) => {
  const [isInviting, setIsInviting] = useState(false);

  const inviteCustomer = () => {
    const payload = {
      id,
    };
    setIsInviting(true);
    inviteClientAPI(payload).then((data: any) => {
      if (data?.status) {
        onHide();
        toast.success(glossary.admin_ssp_invite_success_msg);
      } else {
        toast.error(data?.message);
      }
      setTimeout(() => {
        setIsInviting(false);
      }, 300);
    });
  };

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2 style={{ margin: 10 }}>{glossary.admin_ssp_invite_popup_title}</h2>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        {glossary.admin_ssp_invite_popup_instruction}
      </Modal.Body>
      <Modal.Footer className={'gap-2'}>
        <Button
          kind={'secondary'}
          text={glossary.admin_ssp_invite_popup_button_cancel}
          click={() => onHide()}
        />
        {isInviting ? (
          <Button kind={'primary'} text={glossary.inviting} type={'button'} />
        ) : (
          <Button
            type={'submit'}
            kind={'primary'}
            text={glossary.admin_ssp_invite_popup_button_invite}
            click={() => inviteCustomer()}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
