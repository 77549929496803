import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import store from '../../states';
import CreateVideoExtension from '../components/extension/Create';
import SSAIEndpoint from '../components/ssai/SSAIEndpoint';
import VastTag from '../components/VAST/VAST-create';
import AddReel from '../components/add-reel/add-reel';

const TagEdit = (props: any) => {
  const {
    kind,
    show,
    onHide,
    getTags,
    pSection,
    scriptProp,
    adsTxt,
    tagId,
    tagType,
    createTag,
    selectedTagDetails,
  } = props;

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Modal
        className='header-bidder-modal'
        onHide={() => {
          onHide();
        }}
        show={show}
        centered
        size={'sm'}
      >
        {/* <div className='modal-header px-3'>
          <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
            {tagType === 'normal'
              ? 'Update VAST Tag'
              : tagType === 'ssai'
              ? 'Update SSAI End Point'
              : 'Update Video Extensions'}
          </h3>
        </div> */}
        {tagType === 'normal' ? (
          <VastTag
            kind={kind}
            scriptProp={scriptProp}
            pSection={pSection}
            getTags={getTags}
            onHide={onHide}
            adsTxt={adsTxt}
            tagId={tagId}
            createTag={createTag}
            selectedTagDetails={selectedTagDetails}
          />
        ) : tagType === 'ssai' ? (
          <SSAIEndpoint
            kind={kind}
            pSection={pSection}
            getTags={getTags}
            onHide={onHide}
            adsTxt={adsTxt}
            tagId={tagId}
            createTag={createTag}
            selectedTagDetails={selectedTagDetails}
          />
        ) : (
          <>
            <AddReel
              kind={kind}
              pSection={pSection}
              getTags={getTags}
              onHide={onHide}
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
            />
            {/* <CreateVideoExtension
              kind={kind}
              pSection={'1'}
              getTags={getTags}
              onHide={onHide}
              //adsTxt={adsTxt}
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
            /> */}
          </>
        )}
      </Modal>
    </>
  );
};

export default TagEdit;
