import Button from '../../widgets/button';
import { useEffect, useState } from 'react';
import AddMacros from '../../modals/macros-add';
import store from '../../../states';

import { MdInfoOutline } from 'react-icons/md';
import { routes } from '../../../services/constants';
import { useLocation } from 'react-router-dom';
import InfyTableComponent from '../../comman/table/InfyTableComponent';

export default ({ toast /*store*/ }: any) => {
  const [addMacrosModal, setAddMacrosModal] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [macrosList, sMacrosList] = useState({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const { pathname } = useLocation();
  const [macrosApiPath, sMacrosApiPath] = useState('');

  useEffect(() => {
    if (pathname === '/settings/macros') {
      sMacrosApiPath(routes.getPlatformMacrosList);
    }
  }, [pathname, routes]);

  const getMacros = (data: any) => {
    sMacrosList(data);
  };

  return (
    <>
      <AddMacros
        show={addMacrosModal}
        onHide={() => {
          setAddMacrosModal(false);
        }}
        getMacros={getMacros}
        //api={fetchMoreData}
      />
      <div className='row'>
        <div className='col-6'>
          <h2>
            {glossary.setting_ssp_macros_title}{' '}
            <a
              href='https://help.infy.tv/en/articles/6926417-macros'
              target={'_blank'}
              rel='noreferrer'
              title='Help'
            >
              <MdInfoOutline className='svg-icon' fontSize={20} />
            </a>
          </h2>
        </div>
        <div className='col-6 text-end'>
          <Button
            text={glossary.setting_ssp_macros_button_add_custom_macros}
            type='submit'
            kind='primary'
            click={() => {
              setAddMacrosModal(true);
            }}
          />
        </div>
      </div>
      <InfyTableComponent
        columnWidth={130}
        api={macrosApiPath}
        method={'GET'}
        tableName='Macros'
        downloadCSV={false}
        search={true}
        insertNewData={macrosList}
        insertIndex={0}
      />
    </>
  );
};
