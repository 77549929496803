import { useContext, useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { uploadProfileImageAPI, uploadCustomerImageAPI } from '../../services/settings';
// import { getUserInfoAPI } from '../../services/authentication';
// import { getCustomerInfoAPI } from '../../services/customers';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { UserInfoContext } from '../contexts/userInfoContext';

const defaultSrc = '';
interface Props {
  localStoreURL?: string;
  profileURL?: string;
}
export const ImageCropper = (props: Props) => {
  const location = useLocation();

  const { setCustomerInfo, customerInfo, setUserInfo, userInfo }: any = useContext(UserInfoContext);

  //const { localStoreURL } = props;
  const [error, setError] = useState<any>(null);
  const [image, setImage] = useState<any>(defaultSrc);
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState<any>();
  const [fileData, sFileData] = useState({});
  const [profileURL, sProfileURL] = useState('');
  const [customerProfileURL, sCustomerProfileURL] = useState('');
  const [uploadFileStaus, sUploadFileStaus] = useState(false);
  const [profileLoader, sProfileLoader] = useState(false);

  const uploadProfilePhoto = (e: any) => {
    sUploadFileStaus(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    // Check if the file size is within the allowed limit (in bytes)
    const maxSize = 2 * 1024 * 1024; // 2 MB
    if (file.size > maxSize) {
      setError('File size is too large. Please select a file smaller than 2MB.');
      setImage(null);
      return;
    }

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    //const reader = new FileReader();
    // reader.onload = () => {
    //   setImage(reader.result as any);
    // };
    reader.onload = () => {
      // Check if the file type is valid (in this example, we only allow images)
      if (file.type.split('/')[0] !== 'image') {
        setError('Invalid file type. Please select an image file.');
        setImage(null);
      } else {
        setError(null);
        setImage(reader.result as any);
      }
    };
    reader.readAsDataURL(files[0]);

    sFileData(e?.target?.files[0]);
  };

  const getCropData = (data: any) => {
    sProfileLoader(true);
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    if (location.pathname.startsWith('/settings/profile') && userInfo?.user?.thumb) {
      sProfileURL(userInfo?.user?.thumb);
    }

    // While customer type is creative
    if (
      location.pathname.startsWith('/settings/company') &&
      customerInfo?.customer_type === 'creative'
    ) {
      sCustomerProfileURL(
        customerInfo?.rtb_config?.creative_data?.advertiser_logo ||
          'https://media.qa.begenuin.com/webapp_assets/assets/avatar/penguin.gif'
      );
    }

    if (
      location.pathname.startsWith('/settings/company') &&
      customerInfo?.thumb &&
      customerInfo?.customer_type !== 'creative'
    ) {
      sCustomerProfileURL(customerInfo?.thumb);
    }
  }, [
    customerInfo?.rtb_config,
    customerInfo?.customer_type,
    location.pathname,
    customerInfo?.thumb,
    userInfo?.user?.thumb,
  ]);

  useEffect(() => {
    if (location.pathname.startsWith('/settings/profile')) {
      if (cropData && uploadFileStaus) {
        const payload = {
          file_data: cropData,
        };
        sProfileLoader(true);
        uploadProfileImageAPI(payload)
          .then((data: any) => {
            sUploadFileStaus(false);
            if (data.status === true) {
              setUserInfo((prev: any) => ({
                ...prev,
                user: {
                  ...prev.user,
                  thumb: data.url,
                },
              }));
              sProfileURL(data?.url);
              sProfileLoader(false);
              toast.success('Photo Uploaded.');
            }
          })
          .catch((error) => {
            // toast.error(glossary.dsp_add_endpoint_server_error);
          });
      }
    }
    if (location.pathname.startsWith('/settings/company')) {
      if (cropData && uploadFileStaus) {
        const payload = {
          file_data: cropData,
        };
        uploadCustomerImageAPI(payload)
          .then((data: any) => {
            sProfileLoader(false);
            sUploadFileStaus(false);
            if (data.status === true) {
              toast.success('Photo Uploaded.');
              setCustomerInfo((prev: any) => {
                return { ...prev, thumb: data.url };
              });
              // getCustomerInfoAPI().then((data: any) => {
              //   sCustomerProfileURL(data?.data?.thumb);
              //   setCustomerInfo(data.data);
              // });
              //window.location.reload();
            }
          })
          .catch((error) => {
            // toast.error(glossary.dsp_add_endpoint_server_error);
          });
      }
    }
  }, [cropData]);

  //props.profileURLProps(fileDataUpload);
  // example: https://codesandbox.io/s/wonderful-pine-i7fs3?file=/src/Demo.tsx:2283-2291

  return (
    <>
      <div className='row'>
        <div className='col-4'>
          {uploadFileStaus ? (
            <div className='position-relative' style={{ width: 200 }}>
              <Cropper
                style={{
                  height: 200,
                  width: 200,
                  borderRadius: '50%',
                  //overflow: 'hidden',
                  backgroundColor: 'gray',
                }}
                zoomable={false}
                zoomTo={1}
                initialAspectRatio={1}
                preview='.img-preview'
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={true}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
          ) : (
            <div className='position-relative' style={{ width: 200 }}>
              <div
                className='align-items-center d-flex justify-content-center'
                style={{ width: '200px', height: '200px', borderRadius: '50%', overflow: 'hidden' }}
              >
                {location.pathname.startsWith('/settings/profile') ? (
                  <img
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    src={profileURL}
                    alt=''
                  />
                ) : (
                  <img
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    src={customerProfileURL}
                    alt=''
                  />
                )}
              </div>
            </div>
          )}
          <div className='position-relative' style={{ width: 200 }}>
            <div
              className={`upload ${
                customerInfo?.customer_type === 'creative' &&
                location.pathname.startsWith('/settings/company') &&
                'd-none'
              }`}
            >
              {uploadFileStaus && !error ? (
                <>
                  {profileLoader ? (
                    <div className='upload-loading'></div>
                  ) : (
                    <div className='upload-button'>
                      <span title='upload image' onClick={() => getCropData(fileData)}>
                        &nbsp;
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <div className='upload-file'>
                  <input
                    type='file'
                    accept='image/*'
                    title='upload image'
                    onChange={(e) => uploadProfilePhoto(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {error && (
        <p
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            color: 'red',
            margin: '0 auto',
            width: '150px',
          }}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default ImageCropper;
