import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateCustomerAPI } from '../../../services/customers';
import Button from '../../widgets/button';
import Dropdown from '../../widgets/dropdown';
import store from '../../../states';

interface Props {
  customerDetails?: any;
  customers?: any;
}

export default (props: Props) => {
  const { customerDetails, customers } = props;

  const [paymentTermsValue, setPaymentTermsValue] = useState(customerDetails?.payment_term ?? '');
  const [paymentTermBtn, sPaymentTermBtn] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const paymentTerms = (e: any) => {
    setPaymentTermsValue(e.value);
    sPaymentTermBtn(true);
  };

  const updatePaymentTerms = () => {
    const payload = {
      payment_term: paymentTermsValue,
      customer_id: customerDetails?.id,
    };
    updateCustomerAPI(payload)
      .then((data: any) => {
        if (data.status) {
          toast.success('Payment Terms Update.');
          //getCustomers();
          const customer = customers?.find((x: any) => x?.id === customerDetails?.id) ?? null;
          if (customer) {
            customer.payment_term = paymentTermsValue;
          }
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
    sPaymentTermBtn(false);
  };

  return (
    <>
      <div className='col-auto'>
        <h3 className='mb-4'>Payment Terms:</h3>
        <div className='d-flex gap-3'>
          <div
            className={
              customerDetails?.customer_type === 'demand'
                ? 'd-flex flex-column'
                : 'd-flex flex-column main__container__box__margin'
            }
            style={{ maxWidth: 300 }}
          >
            <Dropdown
              name={'paymentTerms'}
              label={''}
              option={[
                { label: 'NET95', value: 'NET95' },
                { label: 'NET75', value: 'NET75' },
                { label: 'NET60', value: 'NET60' },
                { label: 'NET45', value: 'NET45' },
                { label: 'NET30', value: 'NET30' },
              ]}
              value={{
                label: paymentTermsValue,
                value: paymentTermsValue,
              }}
              onchange={(value: any) => paymentTerms(value)}
              search={false}
            />
          </div>
          <div className='d-flex flex-column'>
            {customerDetails?.customer_type === 'demand' && <label className='label'>&nbsp;</label>}
            <Button
              click={updatePaymentTerms}
              kind={paymentTermBtn ? 'primary' : 'disabled-secondary'}
              text={glossary?.admin_ssp_cus_detail_tab_margin_button_update}
            />
          </div>
        </div>
      </div>
    </>
  );
};
