import Switch from '../widgets/switch';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import Avatar from 'react-avatar';
import { assignManagerToCustomerAPI } from '../../services/customers';
import { toast } from 'react-toastify';

const CustomerDetailsManage = (props: any) => {
  const { accessor, row, selectedCustomer, getManagersList, rowsId } = props;

  const updateManager = (managerID: any, customerID: any, status: any) => {
    const payload = {
      manager_id: managerID,
      customer_id: customerID,
    };
    assignManagerToCustomerAPI(payload, status)
      .then((data: any) => {
        if (data.status) {
          getManagersList && getManagersList(customerID);
          toast.success(`Manager ${status ? 'Add' : 'Removed'} successfully. `);
        }
      })
      .catch((err: any) => {
        //ok
      });
  };

  return (
    <>
      {/* User & Manager Table in show avatar */}
      {accessor === 'firstname' && (
        <Avatar className='me-2' src={row?.thumb ?? 'U'} size={'30'} round />
      )}

      {/* Manager Assign Funtionality */}
      {accessor === 'assigned' && (
        <>
          <div className='d-flex gap-2'>
            <Switch
              key={rowsId}
              api={() => {
                updateManager(row.id, selectedCustomer, !row.assigned);
              }}
              status={row.assigned}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CustomerDetailsManage;
