import { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { FixedSizeList } from 'react-window';

import Card from '../widgets/card';
import CardLoader from '../loaders/card';
import { ReactComponent as SizeIcon } from '../../assets/icons/size.svg';
import { ReactComponent as CompactIcon } from '../../assets/icons/compact.svg';

interface Props {
  title: string;
  kind: 'deal';
  size: 'collapsed' | 'expanded';
  card: 'collapsed' | 'expanded';
  items: any[];
  sidebar?(size: string): void;
  getTags?(): void;
  empty?: boolean;
  info?(card: string): void;
  selected?: string;
  currentPage?: number;
  loading?: boolean;
  hasMore?: boolean;
  userType?: string;
  params?: any;
  handleParams?: any;
}

const DealsSidebar = (props: Props) => {
  const {
    title,
    kind,
    size,
    card,
    items,
    info,
    getTags,
    currentPage,
    loading,
    hasMore,
    userType,
    params,
    handleParams,
  } = props;

  const [displayItems, sDisplayItems] = useState(items);
  const [selected, sSelected] = useState(props.selected);
  const [sideSize, sSideSize] = useState(size);
  const [cardSize, sCardSize] = useState(card);
  const [searchBar, sSearchBar] = useState(false);
  const [searchContent, sSearchContent] = useState('');

  useEffect(() => {
    sSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    sDisplayItems(items);
  }, [items]);

  const select = (id: string, item?: any) => {
    sSelected(id);
    if (info) info(id);
  };

  useEffect(() => {
    sSideSize(size);
  }, [size]);

  useEffect(() => {
    if (searchContent !== params.query) {
      const getData: any = setTimeout(() => {
        handleParams(0, searchContent, true);
        sDisplayItems([]);
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchContent]);

  /***** Data Render on scroll *****/
  const renderCard = ({ index, style }: any) => {
    const item = displayItems && displayItems[index];
    return (
      <div style={style}>
        <Card
          id={item.id}
          key={index}
          text={item.name || item.company_name}
          kind={kind}
          size={cardSize}
          info={{
            items: item,
            type: item.config.deal_type ?? 'pg',
            cpm: item.cpm,
            minCpm: item.config.min_cpm_formatted ?? '-',
            maxCpm: item.config.max_cpm_formatted ?? '-',
            impressions: item.config.pg_impression_formatted ?? '-',
            publishers: item.publishers,
            endpointItem: item,
            status: item.status,
            userType: userType,
          }}
          selected={selected}
          click={() => select(item.id, item)}
          getTags={getTags}
          allData={true}
        />
      </div>
    );
  };

  const lastItem = displayItems[displayItems.length - 1];
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (
        params.page === currentPage &&
        hasMore &&
        visibleStopIndex === displayItems.length - 1 &&
        lastItem
      ) {
        handleParams(params.page + 1, params.query, true);
      }
    },
    [params.page, params.query, displayItems.length, lastItem]
  );

  return (
    <div className='newsidebar'>
      <div className={'sidebar-row sidebar-row-first'}>
        <div className={'sidebar-col'}>
          <h3>{title}</h3>
        </div>
        <div className={'sidebar-col'}>
          <div className={'sidebar-row'}>
            {searchBar && (
              <div className={'sidebar-col'}>
                <input
                  autoFocus
                  onChange={(e) => {
                    if (e.target.value === '') {
                      handleParams(0, '', params.sortBy, true);
                      sDisplayItems([]);
                    }
                    sSearchContent(e.target.value);
                  }}
                  id={'search-bar'}
                  value={searchContent}
                  type={'search'}
                  maxLength={32}
                  placeholder={'Search Keywords..'}
                  className='search-input ps-0'
                />
              </div>
            )}
            <div className={'sidebar-col'}>
              <MdSearch
                className='svg-icon'
                id={'search-btn'}
                onClick={() => {
                  sSearchBar(searchBar ? false : true);
                }}
                title={'Search'}
                fontSize={20}
                style={{ marginLeft: 8, marginRight: 5 }}
              />
            </div>

            {!searchBar && (
              <>
                <div
                  className={'sidebar-col compact-card'}
                  onClick={() => sCardSize(cardSize === 'collapsed' ? 'expanded' : 'collapsed')}
                >
                  {cardSize === 'expanded' ? (
                    <SizeIcon
                      className='svg-icon'
                      width={20}
                      height={20}
                      title='Collapse'
                      style={{ marginLeft: 8, cursor: 'pointer' }}
                    />
                  ) : (
                    <CompactIcon
                      className='svg-icon'
                      width={20}
                      height={20}
                      title='Expand'
                      style={{ marginLeft: 8, cursor: 'pointer' }}
                    />
                  )}
                </div>
              </>
            )}

            {/* {!searchBar && (
                <div className={'sidebar-col'}>
                  <Dropdown className='dropdown-sort'>
                    <Dropdown.Toggle>
                      <FilterIcon
                        className='svg-icon'
                        title={'Filter'}
                        style={{ fontSize: '19px' }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className='dropdown-title'>
                        <span>Sort By</span>
                        <img
                          id={'sort-menu'}
                          onClick={() => {
                            if (sortIcon === sortDescendingIcon) {
                              sSortIcon(sortAscendingIcon);
                            } else {
                              sSortIcon(sortDescendingIcon);
                            }
                            sorter(crSrt.sorter);
                          }}
                          src={sortIcon}
                          width={16}
                          height={16}
                          style={{ cursor: 'pointer' }}
                          className='float-end'
                        />
                      </div>
                      <Dropdown.Divider className='m-0' />
                      <Dropdown.Item
                        className={
                          crSrt.sorter === 'nme'
                            ? 'siderbar-filter-menu-list-item active'
                            : 'siderbar-filter-menu-list-item'
                        }
                        onClick={() => sorter('nme')}
                      >
                        {glossary.dsp_dash_sidebar_sorting_option_name}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          crSrt.sorter === 'crt'
                            ? 'siderbar-filter-menu-list-item active'
                            : 'siderbar-filter-menu-list-item'
                        }
                        onClick={() => sorter('crt')}
                      >
                        {glossary.dsp_dash_sidebar_sorting_option_created}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          crSrt.sorter === 'mincpm'
                            ? 'siderbar-filter-menu-list-item active'
                            : 'siderbar-filter-menu-list-item'
                        }
                        onClick={() => sorter('mincpm')}
                      >
                        {glossary.dsp_dash_sidebar_sorting_option_min_floor}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          crSrt.sorter === 'maxcpm'
                            ? 'siderbar-filter-menu-list-item active'
                            : 'siderbar-filter-menu-list-item'
                        }
                        onClick={() => sorter('maxcpm')}
                      >
                        {glossary.dsp_dash_sidebar_sorting_option_max_floor}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
          </div>
        </div>
      </div>

      <div className={'sidebar-list'} style={{ maxWidth: 334, height: '100%' }}>
        {/***** List of Sidebar Card *****/}
        <FixedSizeList
          height={1000}
          width={300}
          itemCount={displayItems.length}
          itemSize={cardSize === 'collapsed' ? 75 : 130}
          onItemsRendered={handleItemsRendered}
          style={{
            width: '100%',
            height: displayItems.length > 0 ? `calc(100vh - 140px)` : `0px`,
            overflowY: 'auto',
          }}
        >
          {renderCard}
        </FixedSizeList>

        {/***** Skeleton View *****/}
        {loading && (
          <>
            <CardLoader />
            <CardLoader />
            <CardLoader />
          </>
        )}

        {/***** When data not found on search *****/}
        {(!displayItems || displayItems.length < 1) && (
          <>{props.empty && <p style={{ textAlign: 'center', padding: 24 }}>No Deals</p>}</>
        )}
      </div>
    </div>
  );
};

export default DealsSidebar;
