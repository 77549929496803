import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../widgets/button';
import FormikController from '../comman/form/FormikController';
import {
  creativeGetCommunitiesListAPI,
  creativeGetCommunityLoopAPI,
  creativeProfileVideoUploadAPI,
} from '../../services/creative';
import VPlayer from '../components/video-popup/VPlayer';
import InfyScrollDropdown from '../widgets/IDropdown';
import { communityListMap, communityLoopMap } from '../helpers/dropdowns';

interface UploadCreativeForm {
  show: boolean;
  onHide(): void;
  updateVideoList(): void;
  glossary: any;
  genuinInfo: any;
}

const HashtagField = ({ value: initialValue, handleValue, disabled }: any) => {
  const [inputText, setInputText] = useState(initialValue?.join(' '));
  const [resultText, setResultText] = useState('');
  const [typeError, setTypeError] = useState(false);

  const processInput = (text: string) => {
    const tags = text.split(' ')?.filter((tag: string) => tag !== '');
    const filteredTags = tags?.filter((tag: string) =>
      tag === '#' ? tags?.indexOf(tag) < 4 : true
    );
    return filteredTags?.join(' ');
  };

  const handleInputChange = (event: any) => {
    const newText = event?.target?.value;

    if (newText?.includes('  ')) {
      return;
    }

    if (newText?.split('#').length > 4) {
      return;
    }
    if (newText?.endsWith(' ') && newText?.split('#')?.length === 4) {
      return;
    }

    if (!newText?.startsWith('#')) {
      return;
    }

    setInputText(newText);
    const processedText = processInput(newText);
    setResultText(processedText);
  };

  const handleKeyDown = (event: any) => {
    if (event?.key === 'Enter') {
      event?.preventDefault();
    }

    if (event?.key === 'Backspace' && inputText?.endsWith('#')) {
      setInputText(inputText?.slice(0, -1));
      const processedText = processInput(inputText?.slice(0, -1));
      setResultText(processedText);
    }
  };

  const onBlur = () => {
    handleValue(inputText?.length > 0 ? inputText?.split(' ') : []);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setInputText(initialValue?.join(' '));
  }, [initialValue]);

  return (
    <>
      <label className='label'>Hashtags</label>
      <input
        className='text-input'
        placeholder={'Enter hashtags seperated by space'}
        value={inputText ?? ''}
        onChange={handleInputChange}
        onKeyDown={(e: any) => {
          setTypeError(inputText === '' ? true : false);
          handleKeyDown(e);
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
      {typeError && <p className='small text-danger'>Add &quot;#&quot; to the beginning</p>}
    </>
  );
};

const CreativeUpload = ({
  show,
  onHide,
  updateVideoList,
  glossary,
  genuinInfo,
}: UploadCreativeForm) => {
  //
  //
  //

  const [uploadFileError, setUploadFileError] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [rawVideoFile, setRawVideoFile] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  // Communities & Loop list handle
  const [communityRef, setCommunityRef] = useState(0);
  const [communityLoading, setCommunityLoading] = useState(true);
  const [communityList, setCommunityList] = useState<any>([]);
  const [communityEnd, setCommunityEnd] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState<any>([]);

  const [loopRef, setLoopRef] = useState(0);
  const [loopLoading, setLoopLoading] = useState(false);
  const [loopList, setLoopList] = useState<any>([]);
  const [loopEnd, setLoopEnd] = useState(false);
  const [selectedLoop, setSelectedLoop] = useState<any>([]);

  const fileInputRef: any = useRef(null);

  const validationSchema = Yup.object().shape({
    cta_text: Yup.string().nullable(),
    cta_link: Yup.string().url('Enter valid URL').typeError('Enter valid URL'),
    pixel_url: Yup.string().url('Enter valid pixel URL').typeError('Enter valid pixel URL'),
    ad_copy: Yup.string().max(60, 'Maximum 60 characters allowed'),
  });

  const handleClearFile = () => {
    fileInputRef.current.value = ''; // reset the value to an empty string
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const fileType = file?.type;
    if (file && file.size <= 400 * 1024 * 1024 && fileType === 'video/mp4') {
      setRawVideoFile(URL.createObjectURL(file));
      setSelectedFile(file);
    } else {
      setUploadFileError(
        fileType !== 'video/mp4'
          ? 'Invalid file type. Only .mp4 is supported.'
          : 'Selected file is too large.'
      );
      setSelectedFile('');
      handleClearFile();
    }
  };

  const clearStates = () => {
    setSelectedFile('');
    handleClearFile();
    setRawVideoFile('');
    setUploadFileError('');
  };

  const uploadCreative = (_e: any) => {
    const formdata = new FormData();
    formdata.append('video', _e?.video);
    formdata.append('descriptions', '');
    formdata.append('link', '');
    formdata.append(
      'video_library_props',
      `{
          "cta": { 
            "text": "${_e?.cta_text}", 
            "link": "${_e?.cta_link}", 
            "ad_copy": "${_e?.ad_copy}",
            "show_url_meta": ${_e?.show_url_meta}
          },
          "pixel_url": "${_e?.pixel_url}",
          "config": {
            "size_mode": "${_e?.size_mode}"
          }
        }`
    );
    formdata.append('hashtags', _e?.hash_tags);

    if (selectedLoop[0]?.value) {
      formdata.append('loop_id', selectedLoop[0]?.value);
    } else {
      formdata.append('community_id', selectedCommunity[0]?.id);
    }

    setIsUploading(true);
    creativeProfileVideoUploadAPI(formdata)
      .then((data: any) => {
        if (data?.status) {
          toast.success(data?.message);
          updateVideoList();
          setHashtags([]);
          clearStates();
          onHide();
        } else {
          clearStates();
          toast.error(data?.message);
        }
        setIsUploading(false);
      })
      .catch((error: any) => {
        clearStates();
        setIsUploading(false);
        toast.error(glossary.server_error);
      });
  };

  const handleButtonClick = () => {
    clearStates();
    fileInputRef?.current?.click();
  };

  const handleCommunitySelect = (_e: any) => {
    setLoopList([]);
    setSelectedLoop([]);
    setSelectedCommunity(_e);
    setTimeout(() => {
      setLoopLoading(true);
      getLoopList(0, _e[0]?.value);
    }, 300);
  };

  const getCommunityList = (_ref: number) => {
    const payload = {
      limit: 50,
      ref: _ref,
    };
    setCommunityLoading(true);
    creativeGetCommunitiesListAPI(payload)
      .then((data: any) => {
        if (data?.list) {
          setCommunityEnd(!data?.end_page);
          setCommunityRef(data?.ref);
          setCommunityList((prev: any) => [...prev, ...data?.list]);
          if (_ref === 0 && data?.list?.length > 0) {
            setSelectedCommunity(communityListMap([data?.list[0]]) || []);
            getLoopList(0, data?.list[0]?.handle);
          }
        }
        setCommunityLoading(false);
      })
      .catch((error: any) => {
        // error
      });
  };

  const getLoopList = (_ref: number, _communityHandle: string) => {
    const payload = {
      //@ts-ignore
      community_handle: _communityHandle,
      limit: 25,
      ref: _ref,
    };
    setLoopLoading(true);
    creativeGetCommunityLoopAPI(payload)
      .then((data: any) => {
        if (data) {
          setLoopEnd(!data?.end_page);
          setLoopRef(data?.ref);
          setLoopList((prev: any) => [...prev, ...data?.list]);
          if (_ref === 0 && data?.list?.length > 0) {
            setSelectedLoop(communityLoopMap([data?.list[0]]) || []);
          }
        }
        setLoopLoading(false);
      })
      .catch((error: any) => {
        // error
      });
  };

  useEffect(() => {
    getCommunityList(0);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setHashtags([]);
          clearStates();
          onHide();
        }}
        backdrop={isUploading ? 'static' : true}
        centered
        size={'lg'}
      >
        <Modal.Header>
          <h4>Upload Creative</h4>
        </Modal.Header>

        <Formik
          initialValues={{
            cta_text: '',
            cta_link: '',
            show_url_meta: false,
            ad_copy: '',
            pixel_url: '',
            size_mode: 'fit-to-width',
          }}
          validationSchema={validationSchema}
          onSubmit={(e) => uploadCreative({ ...e, video: selectedFile, hash_tags: hashtags })}
        >
          {({ values }) => (
            <Form className='video-config-form'>
              <Modal.Body
                style={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto',
                }}
              >
                <div className='row'>
                  <div className='col-lg-6 col-md-12'>
                    <div className='d-flex flex-column align-items-center gap-3'>
                      {rawVideoFile && (
                        <div className='vp'>
                          <div className='vp_player'>
                            <VPlayer
                              videoType={
                                values?.size_mode === 'fit-to-height'
                                  ? 'cover'
                                  : values?.size_mode === 'fill'
                                  ? 'fill'
                                  : 'contain'
                              }
                              items={{ ...values, hash_tags: hashtags, video_url: rawVideoFile }}
                              genuinInfo={genuinInfo}
                            />
                          </div>
                        </div>
                      )}

                      <div
                        className='d-flex flex-column justify-content-center gap-2'
                        style={{ marginTop: rawVideoFile ? '0px' : '300px' }}
                      >
                        <Button
                          text='Choose File'
                          kind={isUploading ? 'disabled' : 'secondary'}
                          type='button'
                          click={handleButtonClick}
                        />
                        <label className='label'>Supported File: MP4</label>
                        <p className='small text-danger'>{uploadFileError}</p>
                      </div>

                      <input
                        ref={fileInputRef}
                        type='file'
                        accept='video/mp4'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-12 pe-lg-5'>
                    <div className='row'>
                      <div className='col-12 mb-3 d-flex justify-content-between'>
                        <label htmlFor='fit-to-height' className='d-flex gap-1 radio-label'>
                          <Field
                            type='radio'
                            name='size_mode'
                            className='input-radio radio-o me-1'
                            value='fit-to-height'
                            id='fit-to-height'
                            disabled={rawVideoFile ? false : true}
                          />
                          Fit to Height
                        </label>

                        <label htmlFor='fit-to-width' className='d-flex gap-1 radio-label'>
                          <Field
                            type='radio'
                            name='size_mode'
                            className='input-radio radio-o me-1'
                            value='fit-to-width'
                            id='fit-to-width'
                            disabled={rawVideoFile ? false : true}
                          />
                          Fit to Width
                        </label>

                        <label htmlFor='fill' className='d-flex gap-1 radio-label'>
                          <Field
                            type='radio'
                            name='size_mode'
                            className='input-radio radio-o me-1'
                            value='fill'
                            id='fill'
                            disabled={rawVideoFile ? false : true}
                          />
                          Fill
                        </label>
                      </div>

                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'cta_text'}
                          label={'CTA Text'}
                          placeholder={'Enter CTA Text'}
                          maxLength={24}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'cta_link'}
                          label={'CTA Link'}
                          placeholder={'Enter CTA Link'}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <InfyScrollDropdown
                          label={'Community'}
                          option={communityListMap(communityList)}
                          value={selectedCommunity}
                          onSelect={handleCommunitySelect}
                          hasMore={communityEnd}
                          params={{ page: communityRef, query: '' }}
                          isLoading={communityLoading}
                          handleParams={(p: number) => {
                            getCommunityList(p - 1);
                          }}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <InfyScrollDropdown
                          label={'Loop'}
                          option={communityLoopMap(loopList)}
                          value={selectedLoop}
                          onSelect={setSelectedLoop}
                          hasMore={loopEnd}
                          params={{ page: loopRef, query: '' }}
                          isLoading={loopLoading}
                          handleParams={(p: number) => {
                            //@ts-ignore
                            getLoopList(p - 1, selectedCommunity[0]?.value);
                          }}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <HashtagField
                          value={hashtags}
                          handleValue={(val: any) => setHashtags(val)}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                      <div className='col-12 mb-3 d-flex align-items-center'>
                        <Field
                          type='checkbox'
                          name='show_url_meta'
                          disabled={rawVideoFile ? false : true}
                        />
                        <p className='ms-2'>URL Meta</p>
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'ad_copy'}
                          label={'Ad Copy'}
                          placeholder={'Enter Ad Copy Text'}
                          maxLength={61}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'pixel_url'}
                          label={'Pixel URL'}
                          placeholder={'Please put your pixel URL here'}
                          disabled={rawVideoFile ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className='gap-2'>
                <Button
                  text='Cancel'
                  kind={isUploading ? 'disabled' : 'secondary'}
                  type='button'
                  click={() => {
                    setHashtags([]);
                    clearStates();
                    onHide();
                  }}
                />
                {isUploading ? (
                  <Button text='Uploading...' kind='spinner' type='button' />
                ) : (
                  <Button
                    text='Upload'
                    kind={uploadFileError === '' && selectedFile ? 'primary' : 'disabled'}
                    type='submit'
                  />
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CreativeUpload;
