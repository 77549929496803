// import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

const HelpSectionFAQ = () => {
  const root = document.documentElement;
  const primary2 = getComputedStyle(root).getPropertyValue('--primary-2');

  return (
    <>
      {/* Publisher FAQ Start */}
      <Accordion>
        {localStorage.getItem('customer_type') !== 'creative' ? (
          <>
            {/* THIS IS ALSO IN BOTH */}
            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='3'>
              How can I invite my team on the platform?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='3'>
              <Card.Body className='px-2 accordion-body'>
                <p>
                  You can invite your team members by executing the following steps.
                  <ol style={{ paddingLeft: '1rem' }}>
                    <li>Click on settings under the profile icon.</li>
                    <li>Go to Users.</li>
                    <li>Click “Invite”.</li>
                    <li>
                      You can easily change the level of access you want to give by assigning them
                      role.
                    </li>
                    <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                      <a
                        style={{ color: primary2 }}
                        href='https://help.infy.tv/en/articles/6950989-how-to-invite-my-team-members'
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        Know More
                      </a>
                    </li>
                  </ol>
                </p>
              </Card.Body>
            </Accordion.Collapse>

            {/* THIS ONE IS ALSO IN BOTH */}
            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='0'>
              Cross-reference of macros between Infy-TV & other platforms.
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>
                    Here, Infy-TV macros and other platform macros mentioned with all details check
                    to click to know more.
                  </li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6950966-cross-reference-of-macros-between-infy-tv-other-platforms'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            {/* THIS ONE IS ALSO IN BOTH */}
            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='1'>
              List of supported macros.
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='1'>
              <Card.Body className='px-2 accordion-body'>
                <p>Macros enable you to dynamically populate values in your ad-related URLs.</p>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>VAST tags for direct campaigns</li>
                  <li>Brand safety tracking URLs</li>
                  <li>
                    Get further details on the list of supported macros with the example click on
                    below button.
                  </li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6926417-list-of-supported-macros'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            {/* THIS ONE IS ALSO IN BOTH */}
            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='2'>
              What is the importance of App-Ads.txt and Ads.txt? Is it mandatory to update
              App-Ads.txt and Ads.txt?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='2'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>
                    App-Ads.txt and Ads.txt are important for preventing ad fraud and increasing
                    transparency in programmatic advertising, and it is mandatory to update them
                    regularly to ensure that the authorized sellers list is up-to-date.
                  </li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            {localStorage.getItem('customer_type') !== 'demand' && (
              <>
                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='5'>
                  How to Onboarding Inventories?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>
                        Get information about Onboarding Inventories to click on below button.
                      </li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='6'>
                  How to create and assign a deal to supply?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='6'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Go to Deal Section.</li>
                      <li>Select inventorie and click to Create PMP Deal button.</li>
                      <li>Then, select deal which you created.</li>
                      <li>Goto Assigned Demand tab and click to Add Demand.</li>
                      <li>Select Demand from list and click to Add.</li>
                      <li>Click Know More for further information.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='7'>
                  How to control traffic distribution for supply?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='7'>
                  <Card.Body className='px-2 accordion-body'>
                    <p>Easy way to manage endpoint traffic on our platform.</p>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Manage particular Endpoint traffic with applying Max QPS.</li>
                      <li>Select Endpoint.</li>
                      <li>Enter Max QPS value and click to update button.</li>
                      <li>Further details click to below button.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951015-how-to-limit-traffic-qps-for-a-specific-demand-source'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='8'>
                  How to check earnings and request for payouts?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='8'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Here, you can check your earnings and payouts details.</li>
                      <li>Goto Settings.</li>
                      <li>Select Finance.</li>
                      <li>Check Earnings list and request for payout.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951016-how-to-check-earnings-and-request-payouts'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='9'>
                  How to manage Ads.txt for your channels and apps?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='9'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Here, you can update Ads.txt/App-Ads.txt easily.</li>
                      <li>Goto Settings.</li>
                      <li>Select Ads.txt there you can see all details about Ads.txt.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951017-how-to-manage-ads-txt-for-your-channels-and-apps'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='10'>
                  How do I create an AdTag and integrate it in my inventory?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='10'>
                  <Card.Body className='px-2 accordion-body'>
                    <p>You can create Ad Tag or Infy Tag in following manner.</p>
                    <p>
                      <ol style={{ paddingLeft: '1rem' }}>
                        <li>Click create tag on Home screen.</li>
                        <li>Assign a Name to the Tag.</li>
                        <li>Select the kind of tag you want to create-Ad tag or Infy Tag.</li>
                        <li>Select Ad placement.</li>
                        <li>Set minimum CPM for your inventory.</li>
                        <li>Duration of the tag to run.</li>
                        <li>Click on create and your Tag code is ready.</li>
                        <li
                          className='d-flex justify-content-end'
                          style={{ listStyleType: 'none' }}
                        >
                          <a
                            style={{ color: primary2 }}
                            href='https://help.infy.tv/en/'
                            target={'_blank'}
                            rel='noreferrer'
                          >
                            Know More
                          </a>
                        </li>
                      </ol>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='11'>
                  {`How can I block unwanted Advertisers & Creatives from publishing Ads on my inventory?`}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='11'>
                  <Card.Body className='px-2 accordion-body'>
                    <p>VAST-XML & VAST-URL integration steps.</p>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Copy your VAST XML Tags.</li>
                      <li>Appending Parameters and Update the Macros.</li>
                      <ul style={{ paddingLeft: '1rem' }}>
                        <li>
                          (The VAST XML URL may contain macros that will need to be populated by the
                          publisher prior to sending a request to Ad server. Macros are placeholders
                          for data values that are dependent on the user of the page. The population
                          of macros is crucial to campaign targeting and can have a significant
                          impact on fill rate. For Parameter details follow this doc)
                        </li>
                      </ul>
                      <li>Add VAST XML in your player.</li>
                      <p>App-ads.txt and Ads.txt integration steps.</p>
                      <li>How to Create and Upload Ads.txt/app-ads.txt</li>
                      <ul style={{ paddingLeft: '1rem' }}>
                        <li>
                          Ads.txt/app-ads.txt is a simple text file that can be created using a text
                          editor. Enter the details of sellers and save them.
                        </li>
                      </ul>

                      <p style={{ paddingLeft: '1rem' }}>
                        <b>&#9632; Domain Name:</b>
                        <br /> Name or domain of the seller/reseller authorized to sell publisher’s
                        inventory–to establish the ownership of the domain for the advertiser’s
                        knowledge. Example: google.com
                        <br />
                        <b>&#9632; Publisher ID/Seller ID:</b>
                        <br /> A unique identification number of the seller or the reseller. You can
                        ask for seller IDs from your respective sellers and ad networks. Also,
                        Google Adsense can help you get your publisher ID.Example:
                        pub-0000000000000000.
                        <br />
                        <b>&#9632; Type of Relationship:</b>
                        <br /> Direct seller: communicates directly with the publishers to sell a
                        part of inventory such as AdSense.Reseller: vendors authorized by another
                        company (like ad exchange or SSP) to sell space on the domain; Example:
                        DIRECT.
                        <br />
                        <b>&#9632; Certification Authority ID:</b>
                        <br />
                        (Optional) A unique identification code of the advertising system is
                        certified by TAG (Trustworthy Accountability Group). Also known as TAG ID.
                        Example: f08c47fec0942fa0.
                      </p>

                      <ul style={{ paddingLeft: '1rem' }}>
                        <li>
                          Once you have these details in place, simply put them one after another in
                          a text file, separated by a comma (,).
                        </li>
                        <li>
                          {' '}
                          &lt;Field #1&gt;, &lt;Field #2&gt;, &lt;Field #3&gt;, &lt;Field #4&gt;
                        </li>
                      </ul>
                      <p style={{ paddingLeft: '1rem' }}>
                        &#9632; google.com, pub-0000000000000000, DIRECT, f08c47fec0942fa0.
                      </p>
                      <li>
                        Save the file with the ‘txt’ file extension. Lastly, upload it to a
                        website–just like a new page.
                      </li>
                      <li>
                        <b>For ads.txt:</b> Hosted on the top-level domain and not in a subdirectory
                        or subdomain.(For example, your website is example.com, then ads.txt should
                        be accessible via example.com/ads.txt.)
                      </li>
                      <li>
                        <b>For app-ads.txt:</b> Establish your developer website. Hosted on the
                        top-level of developer website.(For example, your developer website is
                        example.com, then app-ads.txt should be accessible via
                        example.com/app-ads.txt.)
                      </li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='12'>
                  What are the criterias to initiate payout requests?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='12'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Added bank details.</li>
                      <li>
                        500+ in earning of prior 60-days period from current date, we keep last
                        60-days of earning as reserve for security purpose.
                      </li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='13'>
                  How can I set up the bank details to receive payouts?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='13'>
                  <Card.Body className='px-2 accordion-body'>
                    <p>You can receive payout in the following manner.</p>
                    <ol style={{ paddingLeft: '1rem' }}>
                      <li>Click on the payout option on the home screen.</li>
                      <li>Add your bank details.</li>
                      <li>
                        And you can withdraw payments once you have accrued a minimum of $500 (US)
                        in your account.
                      </li>
                      <li>Difference between admin, manager and editor.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2, paddingBottom: '40px' }}
                          href='https://help.infy.tv/en/'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ol>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='14'>
                  How to block unwanted advertisers?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='14'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to block unwanted advertisers.</li>
                      <li>Select Endpoint and then click to Advertisers tab.</li>
                      <li>Then, Click to toggle and off toggle.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951023-how-to-block-unwanted-advertisers'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='15'>
                  How to block demand from unwanted Device Types?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='15'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to block unwanted Device Type.</li>
                      <li>Select Endpoint and then click to Device Tyep tab.</li>
                      <li>Then, Click to toggle and off toggle.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6966677-how-to-block-demand-from-unwanted-device-types'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='16'>
                  How to block demand from unwanted Publishers?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='16'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to block unwanted Publisher.</li>
                      <li>Select Endpoint and then click to Publisher tab.</li>
                      <li>Then, Click to toggle and off toggle.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6966683-how-to-block-demand-from-unwanted-publishers'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='17'>
                  How to block unwanted campaigns & creatives?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='17'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to block unwanted Campaigns & Creatives.</li>
                      <li>
                        Select Endpoint and then click to Campaigns or Creatives tab which you want
                        to block.
                      </li>
                      <li>Then, Click to toggle and off toggle.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951022-how-to-block-unwanted-campaigns-creatives'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='18'>
                  How to block demand from unwanted locations?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='18'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to block unwanted locations.</li>
                      <li>Select Endpoint and then click to Locations tab.</li>
                      <li>Then, Click to toggle and off toggle.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951021-how-to-block-demand-from-unwanted-locations'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='19'>
                  How to block demand from unwanted IAB Categories?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='19'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to block unwanted IAB Categories.</li>
                      <li>Select Endpoint and then click to IAB Categories tab.</li>
                      <li>Then, Click to toggle and off toggle.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951020-how-to-block-demand-from-unwanted-iab-categories'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='20'>
                  How to check and download reports for a specific demand/supply source?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='20'>
                  <Card.Body className='px-2 accordion-body'>
                    <ul style={{ paddingLeft: '1rem' }}>
                      <li>Easy way to download reports.</li>
                      <li>
                        Select Endpoint and then select tab from below table which data you want to
                        download.
                      </li>
                      <li>Then, Click to Download CSV button.</li>
                      <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                        <a
                          style={{ color: primary2 }}
                          href='https://help.infy.tv/en/articles/6951018-how-to-check-and-download-reports-for-a-specific-demand-supply-source'
                          target={'_blank'}
                          rel='noreferrer'
                        >
                          Know More
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </>
            )}

            {/* This FAQ is use in both */}
            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='21'>
              How to create and assign a deal to a demand source?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='21'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Go to Deal Section.</li>
                  <li>Select inventorie and click to Create PMP Deal button.</li>
                  <li>Then, select deal which you created.</li>
                  <li>Goto Assigned Demand tab and click to Add Demand.</li>
                  <li>Select Demand from list and click to Add.</li>
                  <li>Click Know More for further information.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6951008-how-to-create-and-assign-a-deal-to-a-demand-source'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='22'>
              How to connect demand using Google Ad Manager(GAM)?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='22'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Click on Add New button.</li>
                  <li>Select GAM Tab from above modal tabs.</li>
                  <li>Login with Google Ad Manager.</li>
                  <li>Further Information click to Know More.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6951007-how-to-connect-demand-using-google-ad-manager-gam'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='23'>
              How to connect demand using VAST XML?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='23'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Click on Add New button in left sidebar.</li>
                  <li>Select VAST XML tab from opened modal.</li>
                  <li>Fill required field and click to Add VAST Xml button.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6951006-how-to-connect-demand-using-vast-xml'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='24'>
              How to connect demand using VAST URL?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='24'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Click on Add New button in left sidebar.</li>
                  <li>Select VAST URL tab from opened modal.</li>
                  <li>Fill required field and click on Check Macros button.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6951004-how-to-connect-demand-using-vast-url'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='25'>
              How to connect demand using Header Bidding Connectors?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='25'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Click on Add New button in left sidebar.</li>
                  <li>Select Header Bidding tab from opened modal.</li>
                  <li>You can see Add button on hovering in HB and click to that button.</li>
                  <li>Then, fill required field and click to Test Integration button.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6951001-how-to-connect-demand-using-header-bidding-connectors'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>

            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='26'>
              How to connect demand using RTB Endpoint?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='26'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Click to Add New button in left side sidebar.</li>
                  <li>Fill reqiured field.</li>
                  <li>Enter RTB endpoint URL.</li>
                  <li>Then, click to Test Integraion.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/6950998-how-to-connect-demand-using-rtb-endpoint'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
            {/* IN BOTH END */}
          </>
        ) : (
          <>
            <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='26'>
              How to integrate Reel Creative HTML code?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='26'>
              <Card.Body className='px-2 accordion-body'>
                <ul style={{ paddingLeft: '1rem', fontSize: '14px', lineHeight: '18px' }}>
                  <li>Begin by copying the HTML code for the ad creative.</li>
                  <li>Paste the HTML ad creative code into the designated field.</li>
                  <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                    {/* <a
                      style={{ color: primary2 }}
                      href='https://help.infy.tv/en/articles/8413771-how-to-integrate-reel-creative-html-code'
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Know More
                    </a> */}
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </>
        )}
      </Accordion>

      {/* <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='2'>
          How can I block unwanted advertisers & creatives from publishing Ads on my inventory?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='2'>
          <Card.Body className='px-2 accordion-body'>
            <p>VAST-XML & VAST-URL integration steps.</p>
            <ul style={{ paddingLeft: '1rem' }}>
              <li>Copy your VAST XML Tags.</li>
              <li>Appending Parameters and Update the Macros.</li>
              <ul style={{ paddingLeft: '1rem' }}>
                <li>
                  (The VAST XML URL may contain macros that will need to be populated by the
                  publisher prior to sending a request to Ad server. Macros are placeholders for
                  data values that are dependent on the user of the page. The population of macros
                  is crucial to campaign targeting and can have a significant impact on fill rate.
                  For Parameter details follow this doc)
                </li>
              </ul>
              <li>Add VAST XML in your player.</li>
              <p>App-ads.txt and Ads.txt integration steps.</p>
              <li>How to Create and Upload Ads.txt/app-ads.txt</li>
              <ul style={{ paddingLeft: '1rem' }}>
                <li>
                  Ads.txt/app-ads.txt is a simple text file that can be created using a text editor.
                  Enter the details of sellers and save them.
                </li>
              </ul>

              <p style={{ paddingLeft: '1rem' }}>
                <b>&#9632; Domain Name:</b>
                <br /> Name or domain of the seller/reseller authorized to sell publisher’s
                inventory–to establish the ownership of the domain for the advertiser’s knowledge.
                Example: google.com
                <br />
                <b>&#9632; Publisher ID/Seller ID:</b>
                <br /> A unique identification number of the seller or the reseller. You can ask for
                seller IDs from your respective sellers and ad networks. Also, Google Adsense can
                help you get your publisher ID.Example: pub-0000000000000000.
                <br />
                <b>&#9632; Type of Relationship:</b>
                <br /> Direct seller: communicates directly with the publishers to sell a part of
                inventory such as AdSense.Reseller: vendors authorized by another company (like ad
                exchange or SSP) to sell space on the domain; Example: DIRECT.
                <br />
                <b>&#9632; Certification Authority ID:</b>
                <br />
                (Optional) A unique identification code of the advertising system is certified by
                TAG (Trustworthy Accountability Group). Also known as TAG ID. Example:
                f08c47fec0942fa0.
              </p>

              <ul style={{ paddingLeft: '1rem' }}>
                <li>
                  Once you have these details in place, simply put them one after another in a text
                  file, separated by a comma (,).
                </li>
                <li> &lt;Field #1&gt;, &lt;Field #2&gt;, &lt;Field #3&gt;, &lt;Field #4&gt;</li>
              </ul>
              <p style={{ paddingLeft: '1rem' }}>
                &#9632; google.com, pub-0000000000000000, DIRECT, f08c47fec0942fa0.
              </p>
              <li>
                Save the file with the ‘txt’ file extension. Lastly, upload it to a website–just
                like a new page.
              </li>
              <li>
                <b>For ads.txt:</b> Hosted on the top-level domain and not in a subdirectory or
                subdomain.(For example, your website is example.com, then ads.txt should be
                accessible via example.com/ads.txt.)
              </li>
              <li>
                <b>For app-ads.txt:</b> Establish your developer website. Hosted on the top-level of
                developer website.(For example, your developer website is example.com, then
                app-ads.txt should be accessible via example.com/app-ads.txt.)
              </li>
              <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                <a
                  style={{ color: primary2 }}
                  href='https://help.infy.tv/en/'
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Know More
                </a>
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse> */}

      {/* <Accordion.Toggle className='border-0 p-2 accordion-title' eventKey='2'>
          How can I block unwanted advertisers & creatives from publishing Ads on my inventory?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='2'>
          <Card.Body className='px-2 accordion-body'>
            <p>VAST-XML & VAST-URL integration steps.</p>
            <ul style={{ paddingLeft: '1rem' }}>
              <li>Copy your VAST XML Tags.</li>
              <li>Appending Parameters and Update the Macros.</li>
              <ul style={{ paddingLeft: '1rem' }}>
                <li>
                  (The VAST XML URL may contain macros that will need to be populated by the
                  publisher prior to sending a request to Ad server. Macros are placeholders for
                  data values that are dependent on the user of the page. The population of macros
                  is crucial to campaign targeting and can have a significant impact on fill rate.
                  For Parameter details follow this doc)
                </li>
              </ul>
              <li>Add VAST XML in your player.</li>
              <p>App-ads.txt and Ads.txt integration steps.</p>
              <li>How to Create and Upload Ads.txt/app-ads.txt</li>
              <ul style={{ paddingLeft: '1rem' }}>
                <li>
                  Ads.txt/app-ads.txt is a simple text file that can be created using a text editor.
                  Enter the details of sellers and save them.
                </li>
              </ul>

              <p style={{ paddingLeft: '1rem' }}>
                <b>&#9632; Domain Name:</b>
                <br /> Name or domain of the seller/reseller authorized to sell publisher’s
                inventory–to establish the ownership of the domain for the advertiser’s knowledge.
                Example: google.com
                <br />
                <b>&#9632; Publisher ID/Seller ID:</b>
                <br /> A unique identification number of the seller or the reseller. You can ask for
                seller IDs from your respective sellers and ad networks. Also, Google Adsense can
                help you get your publisher ID.Example: pub-0000000000000000.
                <br />
                <b>&#9632; Type of Relationship:</b>
                <br /> Direct seller: communicates directly with the publishers to sell a part of
                inventory such as AdSense.Reseller: vendors authorized by another company (like ad
                exchange or SSP) to sell space on the domain; Example: DIRECT.
                <br />
                <b>&#9632; Certification Authority ID:</b>
                <br />
                (Optional) A unique identification code of the advertising system is certified by
                TAG (Trustworthy Accountability Group). Also known as TAG ID. Example:
                f08c47fec0942fa0.
              </p>

              <ul style={{ paddingLeft: '1rem' }}>
                <li>
                  Once you have these details in place, simply put them one after another in a text
                  file, separated by a comma (,).
                </li>
                <li> &lt;Field #1&gt;, &lt;Field #2&gt;, &lt;Field #3&gt;, &lt;Field #4&gt;</li>
              </ul>
              <p style={{ paddingLeft: '1rem' }}>
                &#9632; google.com, pub-0000000000000000, DIRECT, f08c47fec0942fa0.
              </p>
              <li>
                Save the file with the ‘txt’ file extension. Lastly, upload it to a website–just
                like a new page.
              </li>
              <li>
                <b>For ads.txt:</b> Hosted on the top-level domain and not in a subdirectory or
                subdomain.(For example, your website is example.com, then ads.txt should be
                accessible via example.com/ads.txt.)
              </li>
              <li>
                <b>For app-ads.txt:</b> Establish your developer website. Hosted on the top-level of
                developer website.(For example, your developer website is example.com, then
                app-ads.txt should be accessible via example.com/app-ads.txt.)
              </li>
              <li className='d-flex justify-content-end' style={{ listStyleType: 'none' }}>
                <a
                  style={{ color: primary2 }}
                  href='https://help.infy.tv/en/collections/3796998-faq'
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Know More
                </a>
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse> */}
    </>
  );
};

export default HelpSectionFAQ;
