export const overview = (data: any) => {
  const newArray = data.map(({ title, value, increase, by_date, by_tag }: any, index: any) => ({
    id: index,
    label: title,
    value,
    arrow: increase === 'True' ? 'up' : 'down',
    lineData: by_date,
    barData: by_tag,
  }));
  return newArray ?? [];
};

export const overviewDsp = (data: any) => {
  const newArray = data.map(
    ({ title, value, increase, by_date, by_endpoint }: any, index: any) => ({
      id: index,
      label: title,
      value,
      arrow: increase === 'True' ? 'up' : 'down',
      lineData: by_date,
      barData: by_endpoint,
    })
  );
  return newArray ?? [];
};

export const overviewSPO = (data: any) => {
  const newArray = data.map(
    ({ title, value, increase, by_date, by_customer }: any, index: any) => ({
      id: index,
      label: title,
      value,
      arrow: increase === 'True' ? 'up' : 'down',
      lineData: by_date,
      barData: by_customer,
    })
  );
  return newArray ?? [];
};

export const tag = (data: any) => {
  const newArray = data.map(
    ({ title, value, increase, by_date, by_tag, info }: any, index: any) => ({
      id: index,
      label: title,
      value,
      info: info,
      arrow: increase === 'True' ? 'up' : 'down',
      lineData: by_date,
      barData: by_tag,
    })
  );
  return newArray ?? [];
};

// export const inventory = (data: any) => {
//   const newArray = data.map(({ title, value, increase }: any, index: any) => ({
//     id: index,
//     label: title,
//     value,
//     arrow: increase === 'True' ? 'up' : 'down',
//   }));
//   return newArray ?? [];
// };

export const inventory = (data: any) => {
  if (!Array.isArray(data)) {
    // Handle the case where data is not an array (e.g., return an empty array or show an error message)
    return [];
  }

  const newArray = data?.map(({ title, value, increase }: any, index: any) => ({
    id: index,
    label: title,
    value,
    arrow: increase === 'True' ? 'up' : 'down',
  }));
  return newArray;
};


export const traffic = (data: any) => {
  const newArray = data.map(({ title, value }: any, index: any) => ({
    id: index,
    label: title,
    value,
  }));
  return newArray ?? [];
};
