import { useEffect, useState } from 'react';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import sortDescendingIcon from '../../assets/icons/sort-za.svg';
import sortAscendingIcon from '../../assets/icons/sort-az.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import store from '../../states';

interface Props {
  kind: 'tag' | 'endpoint' | 'deal' | 'requestDeal' | 'customer' | 'publishers' | 'creative';
  sortBy?(crSrt: any): void;
  sortedBy?: any;
}

const Sorting = (props: Props) => {
  const { kind, sortBy, sortedBy } = props;
  const [sortIcon, sSortIcon] = useState(sortAscendingIcon);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [crSrt, sCrSrt] = useState(sortedBy);

  useEffect(() => {
    sCrSrt(sortedBy);
  }, [sortedBy]);

  const sorter = (key: string, desc: boolean) => {
    if (key === crSrt.id && (desc || !desc)) {
      sCrSrt({
        id: key,
        desc: desc,
      });
    } else {
      sCrSrt({
        id: key,
        desc: desc,
      });
    }
  };

  useEffect(() => {
    if (crSrt) {
      sortBy && sortBy(crSrt);
    }

    if (crSrt.desc) {
      sSortIcon(sortDescendingIcon);
    } else {
      sSortIcon(sortAscendingIcon);
    }
  }, [crSrt?.id, crSrt?.desc]);

  return (
    <Dropdown className='dropdown-sort'>
      <Dropdown.Toggle style={{ padding: '1px' }}>
        <FilterIcon
          className='svg-icon'
          height={17}
          width={17}
          title={'Sort'}
          style={{ fontSize: '19px' }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='dropdown-title'>
          <span>Sort By</span>
          <img
            id={'sort-menu'}
            onClick={() => {
              if (sortIcon === sortDescendingIcon) {
                sSortIcon(sortAscendingIcon);
              } else {
                sSortIcon(sortDescendingIcon);
              }
              sorter(crSrt.id, !crSrt.desc);
            }}
            src={sortIcon}
            width={16}
            height={16}
            style={{ cursor: 'pointer' }}
            className='float-end'
          />
        </div>
        <Dropdown.Divider className='m-0' />

        {/***** Tags Dropdown *****/}
        {kind === 'tag' && (
          <>
            <Dropdown.Item
              className={
                crSrt.id === 'status'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('status', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_status}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'name'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('name', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_name}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'impression'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => {
                sorter('impression', !crSrt.desc);
              }}
            >
              {glossary.ssp_dash_sidebar_sorting_option_impression}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'floor_price'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('floor_price', !crSrt.desc)}
            >
              Floor Price
              {/* {glossary.ssp_dash_sidebar_sorting_option_fillrate} */}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'created_on'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('created_on', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_created}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'earning'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('earning', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_earnings}
            </Dropdown.Item>
          </>
        )}

        {/***** SSP > Demand Endpoint Dropdown *****/}
        {kind === 'endpoint' && (
          <>
            <Dropdown.Item
              className={
                crSrt.id === 'name'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('name', !crSrt.desc)}
            >
              {glossary.dsp_dash_sidebar_sorting_option_name}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'created_on'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('created_on', !crSrt.desc)}
            >
              {glossary.dsp_dash_sidebar_sorting_option_created}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'fill_rate'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('fill_rate', !crSrt.desc)}
            >
              Bid Rate
              {/* {glossary.dsp_dash_sidebar_sorting_option_min_floor} */}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'impression'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('impression', !crSrt.desc)}
            >
              Bids
              {/* {glossary.dsp_dash_sidebar_sorting_option_min_floor} */}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'earning'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('earning', !crSrt.desc)}
            >
              Spending
              {/* {glossary.dsp_dash_sidebar_sorting_option_max_floor} */}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'status'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('status', !crSrt.desc)}
            >
              Status
              {/* {glossary.dsp_dash_sidebar_sorting_option_max_floor} */}
            </Dropdown.Item>
          </>
        )}

        {kind === 'publishers' && (
          <>
            <Dropdown.Item
              className={
                crSrt.id === 'name'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('name', !crSrt.desc)}
            >
              {glossary.spo_dash_sidebar_sorting_option_name}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'created_on'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('created_on', !crSrt.desc)}
            >
              {glossary.spo_dash_sidebar_sorting_option_created}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'impression'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('impression', !crSrt.desc)}
            >
              {glossary.spo_dash_sidebar_sorting_option_impression}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'earning'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('earning', !crSrt.desc)}
            >
              {glossary.spo_dash_sidebar_sorting_option_earnings}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'fill_rate'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('fill_rate', !crSrt.desc)}
            >
              {glossary.spo_dash_sidebar_sorting_option_fillrate}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'status'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('status', !crSrt.desc)}
            >
              {glossary.spo_dash_sidebar_sorting_option_status}
            </Dropdown.Item>
          </>
        )}

        {/***** SSP > Deals > Requested Deal Dropdown *****/}
        {kind === 'requestDeal' && (
          <>
            <Dropdown.Item
              className={
                crSrt.id === 'name'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('name', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_name}
            </Dropdown.Item>

            <Dropdown.Item
              className={
                crSrt.id === 'created_on'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('created_on', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_created}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'min_cpm'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('min_cpm', !crSrt.desc)}
            >
              Min CPM
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'max_cpm'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('max_cpm', !crSrt.desc)}
            >
              Max CPM
            </Dropdown.Item>
          </>
        )}

        {/***** Admin Sidebar *****/}
        {kind === 'customer' && (
          <>
            <Dropdown.Item
              className={
                crSrt.id === 'name'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('name', !crSrt.desc)}
            >
              {glossary.admin_dash_sidebar_sorting_option_name}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'created_on'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('created_on', !crSrt.desc)}
            >
              {glossary.dsp_dash_sidebar_sorting_option_created}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'impression'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('impression', !crSrt.desc)}
            >
              Impressions
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'earning'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('earning', !crSrt.desc)}
            >
              {glossary.admin_dash_sidebar_sorting_option_earnings}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.sorter === 'fill_rate'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('fill_rate', !crSrt.desc)}
            >
              {glossary.admin_dash_sidebar_sorting_option_fill_rate}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.sorter === 'status'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('status', !crSrt.desc)}
            >
              Status
            </Dropdown.Item>
          </>
        )}

        {/***** Creative > My Creatives *****/}
        {kind === 'creative' && (
          <>
            <Dropdown.Item
              className={
                crSrt.id === 'name'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('name', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_name}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'impression'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => {
                sorter('impression', !crSrt.desc);
              }}
            >
              {glossary.ssp_dash_sidebar_sorting_option_impression}
            </Dropdown.Item>

            <Dropdown.Item
              className={
                crSrt.id === 'created_on'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('created_on', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_created}
            </Dropdown.Item>
            <Dropdown.Item
              className={
                crSrt.id === 'earning'
                  ? 'siderbar-filter-menu-list-item active'
                  : 'siderbar-filter-menu-list-item'
              }
              onClick={() => sorter('earning', !crSrt.desc)}
            >
              {glossary.ssp_dash_sidebar_sorting_option_earnings}
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Sorting;
