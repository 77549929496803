const InvetoryCard = () => {
  return (
    <>
      <div className='invetory-card-s'>
        <div className='card'></div>
      </div>
    </>
  );
};

export default InvetoryCard;
