import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../widgets/button';
import Radio from '../widgets/radio';
import Select from 'react-select';

import { updateUserRoleAPI } from '../../services/settings';
import store from '../../states';
import Dropdown from '../widgets/dropdown';

export default (props: any) => {
  const { id, show, toast, getUsers, onHide, userRole, usersRoleId, usersRoleName } = props;

  const [s1, sS1] = useState('3');
  const [s2, sS2] = useState('3');
  const [s3, sS3] = useState('2');
  const [s4, sS4] = useState('2');

  const [role, sRole] = useState<any>({});

  const [btnDisabled, setBtnDisabled] = useState(false);

  const select1 = (id: string) => sS1(id);
  const select2 = (id: string) => sS2(id);
  const select3 = (id: string) => sS3(id);
  const select4 = (id: string) => sS4(id);

  const updateRole = () => {
    setBtnDisabled(true);
    const payload = {
      user_id: id,
      role_id: role.value,
    };
    updateUserRoleAPI(payload)
      .then(() => {
        onHide();
        getUsers();
        toast.success('Role Updated.');
        setBtnDisabled(false);
      })
      .catch(() => {
        // toast.error('Service unavailable, try again later.');
      });
  };

  useEffect(() => {
    sRole({ label: usersRoleName, value: usersRoleId });
  }, [show]);

  useEffect(() => {
    if (localStorage.getItem('r') === 'infy-admin') {
      if (role.value === 1) {
        sS1('3');
        sS2('3');
        sS3('2');
        sS4('2');
      } else if (role.value === 2) {
        sS1('3');
        sS2('2');
        sS3('2');
        sS4('1');
      } else if (role.value === 3) {
        sS1('2');
        sS2('2');
        sS3('1');
        sS4('1');
      }
    } else {
      if (role.value === 4) {
        sS1('3');
        sS2('3');
        sS3('2');
        sS4('2');
      } else if (role.value === 5) {
        sS1('3');
        sS2('2');
        sS3('2');
        sS4('1');
      } else if (role.value === 6) {
        sS1('2');
        sS2('2');
        sS3('1');
        sS4('1');
      }
    }
  }, [role]);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <Modal
      onHide={() => {
        onHide();
        sRole('');
      }}
      show={show}
      centered
      size={'lg'}
    >
      <Modal.Header>
        <h3>User access control</h3>
      </Modal.Header>
      <Modal.Body className='py-2 px-3'>
        <>
          <div className='d-flex align-items-center'>
            <h4 style={{ marginTop: 10, marginBottom: 10, marginRight: 40 }}>
              {glossary.setting_user_access_popup_field_label_user_role}
            </h4>
            {localStorage.getItem('r') === 'infy-admin' && userRole === 1 ? (
              <div style={{ minWidth: '250px', marginBottom: 20 }}>
                <Dropdown
                  label=''
                  option={[
                    { label: 'Admin', value: 1 },
                    { label: 'Manager', value: 2 },
                    { label: 'Editor', value: 3 },
                  ]}
                  onchange={(e: any) => sRole(e)}
                  value={{ label: usersRoleName, value: usersRoleId }}
                  search={false}
                />
              </div>
            ) : (
              ''
            )}
            {localStorage.getItem('r') === 'customer' && userRole === 4 ? (
              <div style={{ minWidth: '250px', marginBottom: 20 }}>
                <Dropdown
                  label=''
                  option={[
                    { label: 'Admin', value: 4 },
                    { label: 'Manager', value: 5 },
                    { label: 'Editor', value: 6 },
                  ]}
                  onchange={(e: any) => sRole(e)}
                  value={{ label: usersRoleName, value: usersRoleId }}
                  search={false}
                />
              </div>
            ) : (
              ''
            )}
            {localStorage.getItem('r') === 'customer' && userRole === 5 ? (
              <div style={{ minWidth: '250px', marginBottom: 20 }}>
                <Dropdown
                  label=''
                  option={[
                    { label: 'Manager', value: 5 },
                    { label: 'Editor', value: 6 },
                  ]}
                  onchange={(e: any) => sRole(e)}
                  value={{ label: usersRoleName, value: usersRoleId }}
                  search={false}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {localStorage.getItem('r') !== 'infy-admin' ? (
            <div className={'row'} style={{ marginTop: 42 }}>
              <div className={'col'} style={{ minWidth: 200 }}>
                {glossary.setting_user_access_popup_field_label_company_setting}
              </div>
              <div className={'col'} />
              <div className={'col'}>
                <Radio
                  size={'mini'}
                  label={glossary.setting_user_access_popup_checkbox_none}
                  id={'1'}
                  selected={s1}
                  click={select1}
                  disabled={true}
                />
              </div>
              <div className={'col'}>
                <Radio
                  size={'mini'}
                  label={glossary.setting_user_access_popup_checkbox_view}
                  id={'2'}
                  selected={s1}
                  click={select1}
                  disabled={true}
                />
              </div>
              <div className={'col'}>
                <Radio
                  size={'mini'}
                  label={glossary.setting_user_access_popup_checkbox_edit}
                  id={'3'}
                  selected={s1}
                  click={select1}
                  disabled={true}
                />
              </div>
            </div>
          ) : null}
          <div className={'row'} style={{ marginTop: 32 }}>
            <div className={'col'} style={{ minWidth: 200 }}>
              {glossary.setting_user_access_popup_field_label_finance}
            </div>
            <div className={'col'} />
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_none}
                id={'1'}
                selected={s2}
                click={select2}
                disabled={true}
              />
            </div>
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_view}
                id={'2'}
                selected={s2}
                click={select2}
                disabled={true}
              />
            </div>
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_edit}
                id={'3'}
                selected={s2}
                click={select2}
                disabled={true}
              />
            </div>
          </div>
          <div className={'row'} style={{ marginTop: 32 }}>
            <div className={'col'} style={{ minWidth: 200 }}>
              {glossary.setting_user_access_popup_field_label_customers}
            </div>
            <div className={'col'} />
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_view}
                id={'1'}
                selected={s3}
                click={select3}
                disabled={true}
              />
            </div>
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_edit}
                id={'2'}
                selected={s3}
                click={select3}
                disabled={true}
              />
            </div>
            <div className={'col'} />
          </div>
          <div className={'row'} style={{ marginTop: 32 }}>
            <div className={'col'} style={{ minWidth: 200 }}>
              {glossary.setting_user_access_popup_field_label_customer_access}
            </div>
            <div className={'col'} />
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_assigned}
                id={'1'}
                selected={s4}
                click={select4}
                disabled={true}
              />
            </div>
            <div className={'col'}>
              <Radio
                size={'mini'}
                label={glossary.setting_user_access_popup_checkbox_all}
                id={'2'}
                selected={s4}
                click={select4}
                disabled={true}
              />
            </div>
            <div className={'col'} />
          </div>
          <br />
          <br />
        </>
      </Modal.Body>
      <Modal.Footer className='py-2 gap-2'>
        <Button
          kind={'secondary'}
          text={glossary.setting_user_access_popup_button_cancel}
          click={() => {
            onHide();
          }}
        />
        <Button
          kind={btnDisabled ? 'disabled' : 'primary'}
          text={glossary.setting_user_access_popup_button_done}
          click={() => updateRole()}
        />
      </Modal.Footer>
    </Modal>
  );
};
