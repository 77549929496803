import { useContext, useEffect, useState } from 'react';
import { loginWithGoogleAPI } from '../../services/authentication';
import { useGoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
// import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import JWT from 'jsonwebtoken';
import validator from 'validator';
import ReactGA from 'react-ga4';

import Input from '../widgets/input';
import Button from '../widgets/button';
import MessageBar from '../widgets/message-bar';

import store from '../../states';

import { UserInfoContext } from '../contexts/userInfoContext';
import { cretivesDomain } from '../helpers/functions';
import CreativesLogin from '../components/creatives/CreativesLogin';

interface LoginForm {
  email: string;
}

const loginFormInitialValues = {
  email: '',
};

const loginFormInitialErrors: LoginForm = {
  email: '',
};

export default () => {
  const { push, location } = useHistory<{ email: string; companySize: any }>();

  const { handleInitialState, getUserInfo }: any = useContext(UserInfoContext);

  const [errors, sErrors] = useState<LoginForm>(loginFormInitialErrors);
  const [loginForm, sLoginForm] = useState<LoginForm>(loginFormInitialValues);
  const [glossary, sGlossary] = useState<any>({});
  // const { shutdown } = useIntercom();

  const hasCreatives = cretivesDomain();
  const [genuinOpen, setGenuinOpen] = useState(false);

  useEffect(() => {
    // shutdown();
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const updateLoginFormValues = (key: string, value: any) => {
    sErrors(loginFormInitialErrors);
    const newLoginForm = { ...loginForm };
    newLoginForm[key as keyof typeof loginFormInitialValues] = value;
    sLoginForm(newLoginForm);
  };

  const validateLoginForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (!validator.isEmail(loginForm.email)) {
      valid = false;
      newErrors['email'] = glossary.login_email_input_field_valid_error;
    }
    if (validator.isEmpty(loginForm.email)) {
      valid = false;
      newErrors['email'] = glossary.login_email_input_field_empty_error;
    }
    if (valid) {
      return true;
    } else {
      sErrors(newErrors);
      return false;
    }
  };

  const initiateLogin = () => {
    if (validateLoginForm()) {
      ReactGA.event('login', {
        category: 'login',
        action: 'click',
        label: 'login_button',
      });
      push('/login-password', { email: loginForm.email, companySize: null });
    }
  };

  useEffect(() => {
    if (location?.state?.email) {
      const newLoginForm = { ...loginForm };
      newLoginForm['email'] = location?.state?.email;
      sLoginForm(newLoginForm);
    } else {
      push('/login');
    }
  }, []);

  // User Token Verify Message
  const changePassMsg: any = location?.state;
  const [barMsg] = useState(changePassMsg?.msg || '');
  const [scope, setScope] = useState('');
  const [scopeCode, setScopeCode] = useState('');

  // const BASE_URL = process.env.REACT_APP_API_URL ? 'https://app.infy.tv' : 'https://sbapp.infy.tv';

  useEffect(() => {
    if (scope) {
      const payload = {
        code: scopeCode,
        redirect_uri: window.location.origin,
        scope: scope,
      };
      loginWithGoogleAPI(payload)
        .then((data: any) => {
          const email = data.res.user_data.email;
          const companySize = data.res.customer_list;
          if (data.res.login === false && data.res.status === true && companySize.length === 0) {
            push('/signup-pass', {
              email: email,
              companySize: companySize,
            });
          }
          if (data.res.login === false && data.res.status === true && companySize.length > 0) {
            push('/signup', { email: data.res.user_data.email, companySize: companySize });
          }
          if (data.res.login === true && data.res.status === true) {
            const token = data.res.access_token;
            localStorage.setItem('token', token.access_token);
            if (token) {
              const tokenPayload: any = JWT.decode(token.access_token);
              localStorage.setItem('role_id', tokenPayload?.role);
              if (tokenPayload?.admin) {
                localStorage.setItem('r', 'infy-admin');
                push('/customers', { email: data.res.user_data.email, companySize: companySize });
                getUserInfo();
              } else {
                localStorage.setItem('r', 'customer');
                localStorage.setItem('customer_type', token.customer_type);
                localStorage.setItem('is_google_login', data.res.access_token.is_google_login);
                if (data.customer_type === 'demand' || token.customer_type === '') {
                  push('/sources/demand');
                  handleInitialState();
                } else {
                  localStorage.setItem('customer_type', token.customer_type);
                  push('/publisher/supply');
                  handleInitialState();
                }
              }
            }
          }
          if (data.error === 400) {
            toast.error(data.message);
          } else {
            toast.success(data.message);
          }
        })
        .catch((error: any) => {
          toast.error(error);
        });
    }
  }, [scope]);

  const scopes =
    'openid email profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/dfp';

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: scopes,
    onSuccess: async (codeResponse) => {
      setScopeCode(codeResponse.code);
      setScope(codeResponse.scope);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      <CreativesLogin
        show={genuinOpen}
        onHide={() => {
          setGenuinOpen(false);
        }}
      />

      {/* User Token Verify Message Start */}
      {location.pathname === '/login' && barMsg ? <MessageBar show={true} message={barMsg} /> : ''}
      {/* User Token Verify Message End */}

      <div className='login__wrap'>
        <div className={`login__wrap__left ${hasCreatives ? 'genuin' : ''}`}>
          <div className='login__wrap__left__box card-box'>
            <h3 className='fw-light'>
              {hasCreatives ? 'Login with Genuin' : glossary.login_title}
            </h3>
            <p>{glossary.login_title_description}</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                initiateLogin();
              }}
              noValidate
            >
              <div style={{ marginBottom: 16 }}>
                <Input
                  name={'email'}
                  kind='text'
                  label={glossary.login_email_field_title}
                  change={updateLoginFormValues}
                  value={loginForm.email}
                  type={'email'}
                  error={errors.email}
                  placeholder={glossary.login_email_field_title_placeholder}
                  length={320}
                  autoFocus
                />
              </div>

              <Button
                kind={'primary'}
                type={'submit'}
                text={glossary.login_button}
                size={'full'}
                click={() =>
                  ReactGA.event({ category: 'login', action: 'click', label: 'login_button' })
                }
              />

              {hasCreatives ? (
                <button
                  className='btn genuin-btn mt-3'
                  type='button'
                  onClick={() => {
                    setGenuinOpen(true);
                  }}
                >
                  <p className='btn-text'>
                    <img
                      className='genuin-icon'
                      src='https://i.postimg.cc/4NYMFwHP/begenuin-White.png'
                    />
                    <span>Login with Genuin</span>
                  </p>
                </button>
              ) : (
                <button
                  className='btn google-btn'
                  type='button'
                  onClick={() => {
                    googleLogin();
                  }}
                >
                  <p className='btn-text'>
                    <img
                      className='google-icon'
                      src='https://i.postimg.cc/PJVtc2LR/google-logo.png'
                    />
                    <span>{glossary.login_button_google}</span>
                  </p>
                </button>
              )}

              {location?.state?.email && (
                <p
                  className='mt-3 p-2'
                  style={{
                    backgroundColor: '#f9f6f9',
                    fontSize: '12px',
                    borderRadius: 4,
                    padding: '5px 10px',
                  }}
                >
                  <strong className='fw-lighter'>{location?.state?.email}</strong>{' '}
                  {glossary.signup_already_registered}
                </p>
              )}
            </form>
            <p className=' d-md-none'>
              {glossary.login_header_text}
              <Link to='/signup' className='d-inline-block fw-lighter ms-1'>
                {glossary.login_header_create_account_button}
              </Link>
            </p>
          </div>
        </div>
        <div className={`login__wrap__right ${hasCreatives ? 'genuin' : ''}`} />
      </div>
    </>
  );
};
