import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useHistory, useLocation, NavLink } from 'react-router-dom';

// import { BsWallet2 } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import JWT from 'jsonwebtoken';
// import LogRocket from 'logrocket';

import store, { update } from '../../states';
import { api } from '../../services/constants';
import { UserInfoContext } from '../contexts/userInfoContext';

// import Tour from '../modals/tour';
// import DemandTour from '../comman/tour/Demand';
import ButtonSkeleton from '../loaders/button';
import consumerData from '../contexts/OverViewContext';
import logo from '../../assets/images/logo.svg';
import { cretivesDomain } from '../helpers/functions';
import CreativeTour from '../components/tour/Creative';

const hasCreatives = cretivesDomain();

const CreativeHeader = () => {
  // Context API
  const { customerInfo, userInfo, clearAll }: any = useContext(UserInfoContext);
  const { clearAllStates }: any = consumerData();

  const { push, replace } = useHistory();
  const location = useLocation();

  // const [balance, sBalance] = useState({
  //   spending: '$0.00',
  //   platformCost: '$0.00',
  // });

  // const [tour, sTour] = useState(false);
  const [tourCompleted, sTourCompleted] = useState(false);
  const [steps, sSteps] = useState(false);
  const [tourStepDone, sTourStepDone] = useState(false);
  const [companyLogo, sCompanyLogo] = useState();
  const [companyName, sCompanyName] = useState();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [loader, sLoader] = useState(false);
  const [tourStage, setTourStage] = useState('');

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* Update Company Profile picture in header */
  useEffect(() => {
    if (customerInfo?.rtb_config?.creative_data?.advertiser_logo)
      sCompanyLogo(
        customerInfo?.rtb_config?.creative_data?.advertiser_logo ||
          'https://media.qa.begenuin.com/webapp_assets/assets/avatar/penguin.gif'
      );
    if (customerInfo?.company_name) sCompanyName(customerInfo?.company_name);
    if (customerInfo?.stripe_data?.subscribe) {
      setIsSubscribed(customerInfo?.stripe_data?.subscribe ?? false);
    }
  }, [customerInfo?.rtb_config?.creative_data?.advertiser_logo, customerInfo?.company_name]);

  const completeTour = () => {
    sTourCompleted(false);
    setTourStage('info');
  };

  useEffect(() => {
    if (loader) {
      if (location?.pathname?.startsWith('/my-creatives') && tourStage === '' && steps) {
        // Step Tour
        setTimeout(() => {
          //@ts-ignore
          document.getElementsByClassName('react-joyride__beacon')[0]?.click();
        }, 200);
      }
    }
  }, [tourStage, loader, steps, location]);

  useEffect(() => {
    fetch(api + '/frontend-glossary/en')
      .then((response) => response.json())
      .then((result) => {
        store.dispatch(update(result.data));
      });
  }, []);

  useEffect(() => {
    // User Info
    if (userInfo?.user?.id && customerInfo?.id) {
      sLoader(true);

      setTourStage(userInfo?.user?.tour);
      // sBalance({
      //   spending: userInfo?.balance?.spending_formatted ?? '-',
      //   platformCost: userInfo?.balance?.platform_cost_formatted ?? '-',
      // });

      localStorage.setItem('iTour', userInfo?.user?.tour ?? 'false'); //Intercom
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: userInfo?.user?.email ?? '',
          firstName: userInfo?.user?.firstname ?? '',
          lastName: userInfo?.user?.lastname ?? '',
          photo: userInfo?.user?.thumb ?? '',
        })
      );

      // if (
      //   userInfo?.user?.tour !== 'info' &&
      //   userInfo?.user?.tour !== 'done' &&
      //   !userInfo?.user?.infyadmin
      // ) {
      //   sTour(true);
      // }

      // Step Tour
      if (userInfo?.user?.tour !== 'done' && !userInfo?.user?.infyadmin) {
        sSteps(true);
      }

      // If payment is overdue so customer redirect on home page.
      if (
        (!customerInfo?.stripe_data?.subscribe || customerInfo?.stripe_data?.over_due) &&
        !location.pathname.startsWith('/my-creatives')
      ) {
        push('/my-creatives');
      }

      // if (userInfo?.user?.tour !== 'done' && !userInfo?.user?.infyadmin) {
      //   sSteps(true);
      // }
    }
  }, [userInfo, customerInfo]);

  // const [clickOnCreate, setClickOnCreate] = useState(false);
  const [countdown, setCountdown] = useState(100);
  const refCreate = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (tourStepDone && isSubscribed) {
        // if (!tour && tourStepDone && isSubscribed) {
        //@ts-ignore
        refCreate?.current?.click();
      }
      // setClickOnCreate(true);
      setCountdown(countdown - 1);
    }, 2000); //miliseconds
    return () => {
      clearTimeout(timeout);
    };
  }, [tourStepDone, isSubscribed]);

  // tour step
  const getTourDoneProps = (data: any) => {
    sTourStepDone(data);
  };

  useEffect(() => {
    if (tourStepDone) sSteps(false);
  }, [tourStepDone]);

  const onImageError = (e: any) => {
    e.target.src = 'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png';
  };

  // Intercom & LogRocket Initialization

  // useEffect(() => {
  //   if (localStorage.getItem('iTour') === 'done' || tourStage === 'done') {
  //     const token = localStorage.getItem('token') || '';
  //     const decoded = JWT.decode(token, { complete: true });
  //     const { user_id, company_id: customer_id, identity: email }: any = decoded?.payload || {};
  //     const userName = JSON.parse(localStorage.getItem('user') || '{}').firstName || '';
  // bootWithProps({ user_id, customer_id, email, userName });
  // bootLogRocket({ user_id, email, userName });
  // }
  // }, [tourStage, localStorage.getItem('iTour')]);

  // const bootWithProps = ({ user_id, customer_id, email, userName }: any) => {
  //   boot({
  //     name: userName,
  //     email: email,
  //     customAttributes: { user_id: user_id, customerID: customer_id },
  //   });
  // };

  // const bootLogRocket = ({ user_id, email, userName }: any) => {
  //   LogRocket.identify(user_id, {
  //     email: email,
  //     name: userName,
  //     subscriptionType: customerInfo?.customer_type || localStorage.getItem('customer_type'),
  //   });
  // };

  // const customIsActive = (match: any, _location: any) => {
  //   if (_location?.pathname?.startsWith('/settings')) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  return (
    <>
      {/* Step Tour Start */}
      {location.pathname.startsWith('/my-creatives') && tourStage === '' && (
        <CreativeTour
          isSubscribe={isSubscribed}
          steps={steps}
          loader={loader}
          tourDoneProps={getTourDoneProps}
        />
      )}

      {/* Modal Tour */}
      {/* {location.pathname.startsWith('/sources') && tour && (
        <Tour
          show={tour}
          onHide={() => {
            sTour(false);
          }}
          tourCompleted={completeTour}
        />
      )} */}

      <div className={'header'}>
        <div
          className={
            tourCompleted && !tourStepDone
              ? 'header-menu d-flex justify-content-between align-items-center my-0 w-auto tour-overlay'
              : 'header-menu d-flex justify-content-between align-items-center my-0 w-auto'
          }
        >
          <div className={'col col-auto header__left'}>
            <Link
              className='d-flex align-items-center'
              to={{ pathname: '/my-creatives', state: 'clickLogo' }}
            >
              {hasCreatives ? (
                <img
                  src={'https://i.postimg.cc/prSWyYwS/logo-1.png'}
                  alt={'genuin-logo'}
                  height='30px'
                  // width='150px'
                />
              ) : (
                <img src={logo} alt={'infy-tv-logo'} height='50px' width='150px' />
              )}
            </Link>
          </div>

          {isSubscribed && (
            <div className={'col header__middle'}>
              <NavLink
                to={{ pathname: '/my-creatives', state: 'clickLogo' }}
                activeClassName='active'
                className={'tour-mycreatives'}
              >
                My Creatives
              </NavLink>

              <NavLink
                to={{ pathname: '/video-library' }}
                activeClassName='active'
                className={'tour-video-library'}
              >
                Video Library
              </NavLink>

              <NavLink
                to={{ pathname: '/brand-config' }}
                // isActive={customIsActive}
                activeClassName='active'
                // onClick={() => {
                // localStorage.setItem('section', 'profile');
                // localStorage.setItem('selectedTabSetting', 'profile');
                // }}
                className={'tour-brand-config'}
              >
                Brand Config
              </NavLink>
            </div>
          )}

          <div className={'col col-auto header__right d-none d-md-block'}>
            <div className={'d-flex align-items-center'}>
              <>
                {/* <div className='header__right__wrap'> */}
                {/* <div className='header__right__wrap__box'>
                    <div className={'tour-finance'}>
                      <button
                        className='button button-secondary'
                        onClick={() => {
                          push('/settings/finance', { section: 'finance' });
                          localStorage.setItem('section', 'finance');
                          localStorage.setItem('selectedTabSetting', 'finance');
                        }}
                      >
                        <BsWallet2 className='svg-icon' strokeWidth='0.3' />
                        {glossary.dsp_header_button_finance}
                      </button>
                    </div>
                  </div> */}
                {/* <div className='header__right__wrap__box tour-spending'>
                      <span className='header__right__wrap__box__lable'>
                        {glossary.dsp_header_field_label_spending}
                      </span>
                      <span className='header__right__wrap__box__value'>{balance?.spending}</span>
                    </div> */}
                {/* </div> */}

                {/* Header Menu */}

                {!companyName ? (
                  <ButtonSkeleton width={150} height={50} />
                ) : (
                  <Dropdown className='tour-settings'>
                    <Dropdown.Toggle
                      className={
                        'button button-secondary d-flex justify-content-end align-items-center'
                      }
                      style={{ minWidth: '110px' }}
                    >
                      {/* Any Issue width: '110px' */}
                      <span
                        className='text-truncate text-start'
                        style={{ maxWidth: '110px', minWidth: '70px' }}
                        title={companyName}
                      >
                        {companyName === '' ? 'New User' : companyName}
                      </span>
                      <img
                        // @ts-ignore
                        src={
                          companyLogo ??
                          'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png'
                        }
                        height={42}
                        width={42}
                        className='rounded-circle ms-2'
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          objectFit: 'contain',
                        }}
                        onError={onImageError}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {isSubscribed && (
                        <Dropdown.Item
                          onClick={() => {
                            localStorage.removeItem('selectedTabSetting');
                            push('/settings/profile');
                          }}
                        >
                          {glossary.account_ddl_option_settings}
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        onClick={() => {
                          localStorage.clear();
                          clearAll();
                          clearAllStates();
                          replace('/login');
                        }}
                      >
                        {glossary.account_ddl_option_logout}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreativeHeader;
