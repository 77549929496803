import { useState } from 'react';
import { RiChatFollowUpLine } from 'react-icons/ri';
import { TbFileInvoice } from 'react-icons/tb';
import { BsCheck2Circle } from 'react-icons/bs';
import {
  followUpAPI,
  invoiceDownloadAPI,
  receivableAcceptAPI,
  sendInvoiceAPI,
} from '../../services/settings';
import { AiOutlineSend } from 'react-icons/ai';
import { toast } from 'react-toastify';

const FinanceReceivableManage = (Props: any) => {
  const { accessor, row, controller } = Props;

  const dueDate = new Date(row?.due_date);
  const paymentStatus = row?.status !== 'received' && row?.status !== 'paid';
  const [isFollowUpVisible, setIsFollowUpVisible] = useState(false);

  const isDueDatePassed = () => {
    const currentDate = new Date();
    return currentDate >= dueDate;
  };

  if (isDueDatePassed() && !isFollowUpVisible) {
    setIsFollowUpVisible(true);
  }

  const receivableAccept = (id: number, customerId: number) => {
    const payload = {
      id: id,
      customer_id: customerId,
    };
    receivableAcceptAPI(payload)
      .then((data: any) => {
        if (data.status) controller();
        toast.success(data.message);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const downloadInvoice = (rowData: any) => {
    invoiceDownloadAPI(rowData)
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${rowData.company_name}-Invoice.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        //ok
      });
  };

  const sendInvoice = (rowData: any) => {
    sendInvoiceAPI(rowData)
      .then((data: any) => {
        toast.success(data);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const followUp = (rowData: any) => {
    followUpAPI(rowData)
      .then((data: any) => {
        toast.success(data);
      })
      .catch((error: any) => {
        //ok
      });
  };

  return (
    <>
      {accessor === 'action' && (
        <div className='gap-3 d-flex'>
          <TbFileInvoice
            className='svg-icon'
            fontSize={20}
            title={'Download Invoice'}
            onClick={() => downloadInvoice(row)}
          />
          <AiOutlineSend
            className='svg-icon'
            fontSize={20}
            title={'Send Invoice'}
            onClick={() => sendInvoice(row)}
          />
          {/* {isFollowUpVisible && paymentStatus && ( */}
          {paymentStatus && (
            <RiChatFollowUpLine
              className={'svg-icon'}
              fontSize={20}
              title={'Follow Up'}
              onClick={() => followUp(row)}
            />
          )}
          {paymentStatus && (
            <BsCheck2Circle
              className='svg-icon'
              fontSize={20}
              title={'Mark as paid'}
              style={{ strokeWidth: '0.4px' }}
              onClick={() => receivableAccept(row.id, row.customer_id)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FinanceReceivableManage;
