import { useState } from 'react';
// import { FiDownload, FiUpload } from 'react-icons/fi';
// import { RiQuestionnaireLine } from 'react-icons/ri';
// import { TbFileInvoice } from 'react-icons/tb';
import PaymentDispute from '../modals/Payment-dispute';
import { Dropdown, Spinner } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { invoiceDownloadAPI, receiptDownloadAPI } from '../../services/settings';

const SpendingByPartner = (props: any) => {
  const { accessor, row, controller } = props;

  const [raiseDisputeShow, setRaiseDisputeShow] = useState(false);
  const [isInprogress, sIsInprogress] = useState(false);

  const isDownloadInvoice = row?.status !== 'current_running' && row?.status !== 'pending';
  const isUploadReceipt = row?.status === 'invoice';
  const isDownloadReceipt = row?.status === 'received' && row?.status === 'paid'; // TODO: Add some key here (&& stripe_receipt_id | payment_proof_link)
  const isRaiseDispute = row?.status === 'invoice';

  const downloadInvoice = (rowData: any) => {
    const payload = {
      ...rowData,
      type: 'spending_by_partner',
    };
    sIsInprogress(true);
    invoiceDownloadAPI(payload)
      .then((data: any) => {
        const link = document.createElement('a');
        link.href = data?.url;
        link.setAttribute('download', 'Invoice.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        sIsInprogress(false);
      })
      .catch((error: any) => {
        sIsInprogress(false);
        // error
      });
  };

  const downloadReceipt = (rowData: any) => {
    const payload = {
      ...rowData,
      type: 'spending_by_partner',
    };
    sIsInprogress(true);
    receiptDownloadAPI(payload)
      .then((data: any) => {
        const link = document.createElement('a');
        link.href = data?.url;
        link.setAttribute('download', 'Receipt.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        sIsInprogress(false);
      })
      .catch((error: any) => {
        sIsInprogress(false);
        // error
      });
  };

  return (
    <>
      <PaymentDispute
        show={raiseDisputeShow}
        onHide={() => {
          setRaiseDisputeShow(false);
        }}
        type={'raise'}
        paymentID={row?.payment_id}
        updateList={controller}
      />

      {accessor === 'action' && (
        <>
          <Dropdown>
            {isInprogress ? (
              <span className='suggestion-input'>
                <Spinner
                  className='ms-1'
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              </span>
            ) : (
              <Dropdown.Toggle>
                <BsThreeDotsVertical className='svg-icon' style={{ marginLeft: '2px' }} />
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu className='table-action-cell'>
              <Dropdown.Item disabled={!isDownloadInvoice} onClick={() => downloadInvoice(row)}>
                Download Invoice
              </Dropdown.Item>
              <Dropdown.Item disabled={!isUploadReceipt} onClick={() => downloadReceipt(row)}>
                Pay
              </Dropdown.Item>
              <Dropdown.Item disabled={!isDownloadReceipt} onClick={() => downloadReceipt(row)}>
                Download Receipt
              </Dropdown.Item>

              <Dropdown.Item disabled={!isRaiseDispute} onClick={() => setRaiseDisputeShow(true)}>
                Raise Dispute
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </>
  );
};

export default SpendingByPartner;
