import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Input from '../widgets/input';
import Button from '../widgets/button';
import { validateCustomerAPI } from '../../services/customers';
import store from '../../states';

export default (props: any) => {
  const { id, show, toast, onHide, getCustomers } = props;

  const [error] = useState('');
  const [reason, sReason] = useState('');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const validateCustomer = () => {
    const payload = {
      customer_id: id,
      approve: 'no',
      reason: reason ?? '',
    };
    validateCustomerAPI(payload)
      .then((data: any) => {
        if (data && data.status) {
          getCustomers(data.data, 'update', 'reject');
          onHide();
        } else {
          toast.error(data.msg);
        }
      })
      .catch(() => {
        // props.toast.error('Service unavailable, Check back after sometime.');
      });
  };

  return (
    <Modal
      onHide={() => {
        onHide();
        sReason('');
      }}
      show={show}
      centered
      size={'lg'}
    >
      <div className='modal-header px-3'>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Reason for Rejection</h3>
      </div>
      <div className='modal-body p-3'>
        <div className={'row'}>
          <div className={'col-12'}>
            <label className='label'>Type rejection message below</label>
            <textarea
              className='form-control auto-textarea h-auto'
              placeholder={'Type a Message'}
              onChange={(e) => sReason(e.target.value)}
              value={reason}
              maxLength={158}
              rows={3}
              style={{ resize: 'none' }}
            />
          </div>
        </div>
      </div>

      <div className='modal-footer px-3 gap-2'>
        <Button
          kind={'secondary'}
          text='Close'
          click={() => {
            onHide();
            sReason('');
          }}
        />
        <Button kind={'primary'} text={'Send'} click={() => validateCustomer()} />
      </div>
    </Modal>
  );
};
