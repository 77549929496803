import { useContext } from 'react';
import { UserInfoContext } from '../contexts/userInfoContext';

interface ICommonEmbedCode {
  tagId: number | string | undefined;
  companyId: number | string;
  height: string;
  width: string;
  isCopy?: boolean;
}

const CommonEmbedCode = ({ tagId, companyId, height, width, isCopy }: ICommonEmbedCode) => {
  const { genuin }: any = useContext(UserInfoContext);

  if (isCopy) {
    return `
      <html>
        <body>
          <div 
            class="gen-ext" 
            data-tag-id="${tagId}" 
            data-company-id="${companyId}" 
            style="width: ${width}; height: ${height};">
          </div>
          <script src="${genuin.scriptURL}"></script>
        </body>
      </html>
      `;
  }

  return `
   <html>
      <body>
        <div class="gen-ext" data-tag-id="${tagId}" data-company-id="${companyId}" style="width: ${width}; height: ${height};"></div>
        <script src="${genuin.scriptURL}"></script>
      </body>
    </html>
  `;
};

export default CommonEmbedCode;
