import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';
import NoDataFound from '../../../assets/images/no-data-found.svg';

interface Props {
  cardLoading?: any;
}

export default (props: Props) => {
  const { cardLoading } = props;

  return (
    <>
      {cardLoading ? (
        <>
          <div className={'row'} style={{ marginTop: '60px', justifyContent: 'space-between' }}>
            <div className='col-10'>
              <div className='d-flex gap-4'>
                {[1, 2, 3, 4, 5, 6].map((index) => {
                  return <Box key={index} />;
                })}
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col w-100 m-auto'>
              <XYChart />
            </div>
          </div>
        </>
      ) : (
        <div
          className='align-items-center d-flex justify-content-center'
          style={{
            height: 'calc(100vh - 140px)',
          }}
        >
          <div className='text-center'>
            <img src={NoDataFound} alt='No data found' width='100%' />
            <h4>No Data Found</h4>
          </div>
        </div>
      )}
    </>
  );
};
