import { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UserInfoContext } from '../../contexts/userInfoContext';
import FormikController from '../../comman/form/FormikController';
import CreativeBrandLogo from '../../components/creatives/Creative-brand-logo';
import { creativeAgencyInfoUpdateAPI } from '../../../services/creative';
import ButtonSkeleton from '../../loaders/button';

const BrandConfig = () => {
  // Context API
  const { customerInfo, setCustomerInfo, userInfo }: any = useContext(UserInfoContext);

  const [pageSkeleton, setPageSkeleton] = useState(true);

  const hexCodeRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  const advertiserSchema = Yup.object().shape({
    advertiserName: Yup.string().nullable(),
    brandColor: Yup.string()
      .matches(/^#/g, "Please include '#' at the beginning of the hex code")
      .matches(hexCodeRegex, 'Use "#" followed by alphanumeric characters (0-9, A-F, a-f)'),
    agencyName: Yup.string().nullable(),
  });

  const advertiserFormSubmit = (_e: any) => {
    const payload = {
      advertiser_brand_color: _e?.brandColor === '' ? '#123787' : _e?.brandColor,
      advertiser_name: _e?.advertiserName,
      agency_name: _e?.agencyName,
    };
    creativeAgencyInfoUpdateAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setCustomerInfo((prev: any) => {
            return {
              ...prev,
              rtb_config: {
                ...prev.rtb_config,
                creative_data: {
                  ...prev.rtb_config.creative_data,
                  ...payload,
                },
              },
            };
          });
        }
      })
      .catch((error: any) => {
        // error
      });
  };

  useEffect(() => {
    if (customerInfo.id) {
      setPageSkeleton(false);
    }
  }, [customerInfo]);

  return (
    <>
      <div className={'content d-flex'}>
        <div className='view-section' style={{ marginLeft: 0 }}>
          {pageSkeleton ? (
            <div className='row'>
              <div className='col-lg-1'></div>

              <div className='col-lg-4 col-md-12'>
                <h5 className='mb-3'>
                  <ButtonSkeleton height={20} width={'80%'} />
                </h5>
                <div className='mb-5 d-flex justify-content-center'>
                  <div
                    className='skeleton'
                    style={{
                      width: '200px',
                      height: '200px',
                    }}
                  >
                    <div className='skeleton__circle skeleton__comman'></div>
                  </div>
                </div>

                <div className='form-group mb-4'>
                  <ButtonSkeleton height={38} width={'100%'} />
                </div>

                <div className='form-group'>
                  <ButtonSkeleton height={38} width={'100%'} />
                </div>
              </div>

              <div className='col-lg-2'></div>

              <div className='col-lg-4 col-md-12'>
                <h5 className='mb-3'>
                  <ButtonSkeleton height={20} width={'80%'} />
                </h5>
                <div className='mb-5 d-flex justify-content-center'>
                  <div
                    className='skeleton'
                    style={{
                      width: '200px',
                      height: '200px',
                    }}
                  >
                    <div className='skeleton__circle skeleton__comman'></div>
                  </div>
                </div>

                <div className='form-group mb-2'>
                  <ButtonSkeleton height={38} width={'100%'} />
                </div>
              </div>

              <div className='col-lg-1'></div>
            </div>
          ) : (
            <Formik
              initialValues={{
                advertiserName: userInfo?.genuine_user_name,
                // advertiserName: customerInfo?.rtb_config?.creative_data?.advertiser_name,
                brandColor: customerInfo?.rtb_config?.creative_data?.advertiser_brand_color,
                agencyName: customerInfo?.rtb_config?.creative_data?.agency_name,
              }}
              validationSchema={advertiserSchema}
              onSubmit={advertiserFormSubmit}
              validateOnChange
              enableReinitialize
            >
              {({ values, touched, errors, handleSubmit }) => (
                <Form>
                  <div className='row'>
                    <div className='col-lg-1'></div>

                    <div className='col-lg-4 col-md-12'>
                      <h5 className='mb-2'>Advertiser Logo</h5>
                      <div className='mb-5 d-flex justify-content-center'>
                        <CreativeBrandLogo type={'advertiser'} />
                      </div>

                      <div className='form-group mb-3'>
                        <FormikController
                          kind='withouticon'
                          control='input'
                          type='text'
                          label={'Advertiser Name'}
                          placeholder={'Enter Advertiser Name'}
                          name='advertiserName'
                          onBlur={handleSubmit}
                          maxLength={35}
                          disabled
                        />
                      </div>

                      <div className='form-group'>
                        <div className='input-color'>
                          <FormikController
                            kind='withouticon'
                            control='input'
                            type='text'
                            label={'Advertiser brand color'}
                            placeholder={'e.g. #123787'}
                            name='brandColor'
                            onBlur={handleSubmit}
                            maxLength={8}
                          />

                          <div
                            className='color_box'
                            style={{ backgroundColor: values?.brandColor || '#123787' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-2'></div>

                    <div className='col-lg-4 col-md-12'>
                      <h5 className='mb-2'>Agency Logo</h5>
                      <div className='mb-5 d-flex justify-content-center'>
                        <CreativeBrandLogo type={'agency'} />
                      </div>

                      <div className='form-group mb-2'>
                        <FormikController
                          kind='withouticon'
                          control='input'
                          type='text'
                          label={'Agency Name'}
                          placeholder={'Enter Agency Name'}
                          name='agencyName'
                          onBlur={handleSubmit}
                          maxLength={35}
                        />
                      </div>
                    </div>

                    <div className='col-lg-1'></div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

export default BrandConfig;
