import { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import GAMEndpoints from '../../tables/GAMEndpoints';
import { toast } from 'react-toastify';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-icon.svg';
import {
  getGAMListAPI,
  postGAMLoginAPI,
  adUnitGAMIdAPI,
  GAMLogoutAPI,
} from '../../../services/gam';
import TableSkeleton from '../../loaders/table';
import Button from '../../widgets/button';
import store from '../../../states';

interface Props {
  onHide?: any;
  getEndPointListCard?: any;
}

const GoogleAdsLogin = (props: any) => {
  const { onHide, getEndPointListCard } = props;

  const BASE_URL = process.env.REACT_APP_API_URL ? 'https://app.infy.tv' : 'https://sbapp.infy.tv';

  const [GAMDataList, setGAMDataList] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [GAMCode, setGAMCode] = useState('');
  const [scope, setScope] = useState('');
  const [GAMLoginStatus, setGAMLoginStatus] = useState(false);
  const [skeleton, sSkeleton] = useState(true);

  // const selectedRowData = (selectedRow: any) => {
  //   setSelectedRow(selectedRow);
  // };

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const getGAMList = () => {
    sSkeleton(true);
    getGAMListAPI()
      .then((data: any) => {
        setGAMDataList(data.data);
        setGAMLoginStatus(data.user_login);
        sSkeleton(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  useEffect(() => {
    getGAMList();
  }, []);

  useEffect(() => {
    if (scope) {
      const payload = {
        code: GAMCode,
        scope: scope,
        redirect_uri: window.location.origin,
      };
      postGAMLoginAPI(payload)
        .then((data: any) => {
          //setLoginStatus(data.status);
          if (data.data) {
            getGAMList();
          }
          if (data.status === false) {
            toast.error(data.message);
          }
        })
        .catch((error: any) => {
          //@ts-ignore
        });
    }
  }, [scope]);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/admanager',
    onSuccess: async (codeResponse) => {
      setGAMCode(codeResponse.code);
      setScope(codeResponse.scope);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const intialAdUnitGAMIdAPI = () => {
    const payload = {
      ad_unit_ids: selectedRow,
    };
    adUnitGAMIdAPI(payload)
      .then((data: any) => {
        if (data?.status === true) {
          getEndPointListCard(data, 'add', 'GAM');
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error: any) => {
        toast.error('');
      });
  };

  const googleLogout = () => {
    GAMLogoutAPI()
      .then((data: any) => {
        if (data.status) {
          setGAMDataList([]);
          setGAMLoginStatus(data.user_login);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error: any) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div
        className='modal-body p-3'
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <div className='row'>
          <div className='col-12'>
            {skeleton ? (
              <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
            ) : (
              <>
                {GAMLoginStatus ? (
                  <>
                    <div className='position-absolute me-3 end-0'>
                      <Button kind='secondary' text='Logout' click={googleLogout} />
                    </div>
                    <GAMEndpoints
                      rows={GAMDataList ?? []}
                      selectedRowData={(selectedRow: any) => setSelectedRow(selectedRow)}
                      invIds={selectedRow ?? []}
                    />
                  </>
                ) : (
                  <div className='d-flex justify-content-center my-4'>
                    <button
                      className='btn google-btn w-auto'
                      type='button'
                      onClick={() => {
                        googleLogin();
                      }}
                    >
                      <p className='btn-text ps-4 pe-4'>
                        <img
                          className='google-icon'
                          src='https://i.postimg.cc/PJVtc2LR/google-logo.png'
                        />
                        {glossary.signup_button_google}
                      </p>
                    </button>
                  </div>
                  // <div
                  //   className='button button-google m-auto my-4 p-0'
                  //   onClick={() => {
                  //     googleLogin();
                  //   }}
                  // >
                  //   <span className='button-google-icon'>
                  //     <GoogleIcon height={25} width={25} />
                  //   </span>
                  //   <span className='px-3'>Login with Google</span>
                  // </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={'modal-footer px-3'}>
        <div className='d-flex justify-content-end gap-3'>
          <button
            onClick={() => {
              onHide();
            }}
            type='button'
            className={'button button-large button-secondary float-end'}
          >
            Close
          </button>
          {GAMLoginStatus && (
            <button
              onClick={() => intialAdUnitGAMIdAPI()}
              type='submit'
              className={
                selectedRow?.length > 0
                  ? 'button button-large button-primary float-end'
                  : 'button button-large button-primary float-end button-disabled'
              }
            >
              Add Endpoint
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default GoogleAdsLogin;
