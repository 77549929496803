export const roundTime = (date: any) => {
  const roundedDate = new Date(date);
  const minutes = roundedDate.getMinutes();
  roundedDate.setMinutes(0); // reset minutes to 0
  roundedDate.setSeconds(0); // reset seconds to 0
  roundedDate.setMilliseconds(0); // reset milliseconds to 0
  if (minutes >= 30) {
    // if minutes is greater than or equal to 30, round up to next hour
    roundedDate.setHours(roundedDate.getHours() + 1);
  }
  // return rounded date object
  return roundedDate;
};

export const genuinTab = (title: any) => {
  return (
    <div>
      <img
        src={
          'https://s3.us-east-2.amazonaws.com/infywebapp/profile_images/248664321048_LX5lQW4OM4rYg1ErJqyY.png'
        }
        alt='icon'
        className='tab-image me-2'
      />
      {title}
    </div>
  );
};

export const firstLetterCap = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const windowReload = () => {
  return window.location.reload();
};

export const redirectToPath = (path: string) => {
  return window.location.replace(path);
};

export const openNewTab = (url: string) => {
  return window.open(url, '_blank', 'noreferrer');
};

export const openSameTab = (url: string) => {
  return window.open(url, '_self');
};

export const cretivesDomain = () => {
  const hostname = window.location.hostname;
  const hostnameParts = hostname.split('.');
  // const hasCreatives = hostnameParts.includes('creatives') ? true : false;
  return hostnameParts.includes('creatives') ||
    hostnameParts.includes('adreels') ||
    hostnameParts.includes('creative') ||
    hostnameParts.includes('brands')
    ? true
    : false;
};

export const formatDobDate = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const modifiedID = (_id: any) => {
  const stringValue = _id?.toString();
  const parts = stringValue.split('.');
  const integerValue = parseInt(parts[0], 10);
  return integerValue;
};

export const checkIdSequence = (_updatedList: any) => {
  const modifiedArr = _updatedList?.flat();
  const response = modifiedArr.some((x: any, i: any) => (x.id !== i.toString() ? true : false));
  return response;
};

export const DownloadCreativeSizeCSV = (tag_id: any, company_id: any, scriptURL: string) => {
  const data = [
    { size: `Creative Sizes`, code: `HTML Code` },
    {
      size: `300x600`,
      code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 300px; height: 600px;"></div><script src="${scriptURL}"></script></body></html>`,
    },
    // {
    //   size: `240x400`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 240px; height: 400px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
    // {
    //   size: `120x240`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 120px; height: 240px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
    // {
    //   size: `250x250`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 250px; height: 250px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
    {
      size: `300x250`,
      code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 300px; height: 250px;"></div><script src="${scriptURL}"></script></body></html>`,
    },
    // {
    //   size: `336x280`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 336px; height: 280px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
    // {
    //   size: `720x300`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 720px; height: 300px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
    // {
    //   size: `160x600`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 160px; height: 600px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
    // {
    //   size: `120x600`,
    //   code: `<html><body><div class="gen-ext" data-tag-id="${tag_id}" data-company-id="${company_id}" style="width: 120px; height: 600px;"></div><script src="${scriptURL}"></script></body></html>`,
    // },
  ];

  // Create a CSV string
  // const csv = data.map((row) => Object.values(row).join("\t")).join("\n");
  const csv = data.map((row) => `${row.size}\t${row.code}`).join('\n');

  // Create a Blob object with the CSV data
  const blob = new Blob([csv], { type: 'text/csv' });

  // Create a download link for the CSV file
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${tag_id}-creative.csv`;

  // Trigger a click event to download the CSV file
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const verifyURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const selectedIndices = (data: any) => {
  const indices: any = {};
  if (data) {
    data.forEach((_i: any, index: any) => {
      if (index !== -1) {
        indices[index.toString()] = true;
      }
    });
  }
  return indices || [];
};

// Utility function to update nested properties
const updateNestedProperty = (obj: any, keys: any, value: any) => {
  const updatedObj = { ...obj };
  let currentObj = updatedObj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    currentObj[key] = { ...currentObj[key] };
    currentObj = currentObj[key];
  }

  currentObj[keys[keys.length - 1]] = value;

  return updatedObj;
};

export function reducer(state: any, action: any) {
  switch (action.type) {
    case 'update_cell':
      return {
        ...state,
        skipReset: true,
        data: state.data.map((row: any, index: any) => {
          if (index === action.rowIndex) {
            return {
              ...state.data[action.rowIndex],
              [action.columnId]: action.value,
            };
          }
          return row;
        }),
      };

    case 'update_nested_cell':
      return {
        ...state,
        skipReset: true,
        data: state.data.map((row: any, index: any) => {
          if (index === action.rowIndex) {
            const existingConfig = state.data[action.rowIndex][action.columnId] || {};

            const updatedRow = {
              ...state.data[action.rowIndex],
              [action.columnId]: updateNestedProperty(
                existingConfig,
                action.nestedKey.split('.'),
                action.value
              ),
            };
            return updatedRow;
          }
          return row;
        }),
      };

    case 'update_row':
      return {
        ...state,
        skipReset: true,
        data: state.data.map((row: any, index: any) => {
          if (row.video_id === action.value.video_id) {
            return {
              ...action.value,
            };
          }
          return row;
        }),
      };

    case 'update_list':
      return {
        ...state,
        data: action.data,
      };
  }
}
