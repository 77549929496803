import { useState, useEffect, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import Switch from '../../widgets/switch';
import JWT from 'jsonwebtoken';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import store from '../../../states';
import Button from '../../widgets/button';
import FormikController from '../../comman/form/FormikController';
import Dropdown from '../../widgets/dropdown';
import { resolutionOpt, addRellviewTypeOpt, feedTypeOpt } from '../extension/ExtOptions';
import { categories } from '../../helpers/dropdowns';
import WebsiteUrl from '../extension/WebsiteUrl';
import VideosUrl from './videos-url';
import VideosIds from './videos-id';
import { adReelAPI } from '../../../services/tags';
import SendEmail from '../extension/SendEmail';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import JavaScriptCode from '../extension/JavaScriptCode';
import HtmlCode from '../extension/HtmlCode';
import DeviceView from '../extension/DeviceView';
import Radio from '../../widgets/radio';
import { getVerifyExtensionAPI } from '../../../services/extension';
import { IoMdCheckmark } from 'react-icons/io';
import { windowReload } from '../../helpers/functions';
import { UserInfoContext } from '../../contexts/userInfoContext';

export default ({
  kind,
  pSection,
  onHide,
  tagId,
  createTag,
  getTags,
  selectedTagDetails,
  initialCreate, //On first time after login create
}: any) => {
  const { genuin }: any = useContext(UserInfoContext);

  const reelInitialValues = {
    extensionName: createTag ? '' : selectedTagDetails?.name,
    genuinUser: createTag ? '' : selectedTagDetails?.config?.genuin_user_name,
    loopShare: createTag ? '' : selectedTagDetails?.config?.loop_share_string,
    noVideos: selectedTagDetails?.config?.feed_max_videos ?? 5,
    swipe: '',
    floorPriceSwipe: createTag ? 0 : selectedTagDetails?.floor_price,
    floorPriceAdPlacement: createTag ? 0 : selectedTagDetails?.config?.ads_slot_cpm,
    ctaLink: createTag ? '' : selectedTagDetails?.config?.cta?.link,
    ctaText: createTag ? '' : selectedTagDetails?.config?.cta?.text,
  };

  const [extentionData, sExtentionData] = useState<any>({});
  const [cutomerData, sCutomerData] = useState<any>({});
  const [section, sSection] = useState<any>(pSection);
  const [spinnerBtn, sSpinnerBtn] = useState(false);
  const [count, setCount] = useState(5);
  const [websiteTodos, sWebsiteTodos] = useState<any>([]);
  const [videosTodos, sVideosTodos] = useState<any>([]);
  const [videosIdTodos, sVideosIdTodos] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  //config object state
  const [selectedOnCLick, sSelectedOnCLick] = useState(
    createTag ? 'fullscreen' : selectedTagDetails?.config?.on_click
  );
  const [showQuestion, sShowQuestion] = useState(
    createTag ? false : selectedTagDetails?.config?.enable_ask_question
  );
  const [showComments, sShowComments] = useState(
    createTag ? false : selectedTagDetails?.config?.show_comments
  );
  const [showCTA, sShowCTA] = useState(createTag ? false : selectedTagDetails?.config?.show_cta);
  const [showLogo, sShowLogo] = useState(createTag ? false : selectedTagDetails?.config?.show_logo);
  const [showOwnerDetails, sShowOwnerDetails] = useState(
    createTag ? false : selectedTagDetails?.config?.show_owner_details
  );
  //const [showPreFeedVast, sShowPreFeedVast] = useState(selectedTagDetails?.config?.show_pre_feed_vast ?? false);
  const [showReply, sShowReply] = useState(
    createTag ? false : selectedTagDetails?.config?.show_reply
  );
  const [showReport, sShowReport] = useState(
    createTag ? false : selectedTagDetails?.config?.show_report
  );
  const [showRepost, sShowRepost] = useState(
    createTag ? false : selectedTagDetails?.config?.show_repost
  );
  const [showSave, sShowSave] = useState(createTag ? false : selectedTagDetails?.config?.show_save);
  const [showShare, sShowShare] = useState(
    createTag ? false : selectedTagDetails?.config?.show_share
  );
  const [showVideoURL, sShowVideoURL] = useState(
    createTag ? false : selectedTagDetails?.config?.show_video_url
  );

  //const [smartFeed, setSmartFeed] = useState(false);
  const [videoPlacement, sVideoPlacement] = useState(
    selectedTagDetails?.config?.is_ad_at_end_of_video ?? true
  );

  const [validGenuinUser, setValidGenuinUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // const [swipeStatus, sSwipeStatus] = useState(
  //   selectedTagDetails?.config?.extension_user?.is_ads_freq_enabled ?? true
  // );
  // const [genuinOpenContent, setGenuinOpenContent] = useState(
  //   selectedTagDetails?.config?.genuin_open_content ?? false
  // );

  const [genuinUser, sGenuinUser] = useState<any>('');
  const [isVerified, setIsVerified] = useState(false);
  const isIdEmpty = validGenuinUser !== '' ? false : true;

  const haandleSelectedOnCLick = (id: string) => sSelectedOnCLick(id);

  const [isDomainAllowed, sIsDomainAllowed] = useState(
    selectedTagDetails?.config?.is_domain_permissible ?? true
  );

  const [selectedFeedOption, setSelectedFeedOption] = useState<any>(
    createTag
      ? {
          value: 'smart',
          label: 'Smart Feed',
        }
      : {
          value: selectedTagDetails?.config?.feed_type,
          label:
            selectedTagDetails?.config?.feed_type === 'smart'
              ? 'Smart Feed'
              : selectedTagDetails?.config?.feed_type === 'loop'
              ? 'Loop Table'
              : selectedTagDetails?.config?.feed_type === 'fixed'
              ? 'Added videos'
              : selectedTagDetails?.config?.feed_type === 'user'
              ? 'My profile'
              : 'Smart Feed',
        }
  );

  const [selectedOption, setSelectedOption] = useState<any>(
    createTag
      ? {
          value: 'interstitial',
          label: 'Interstitial',
        }
      : {
          value: selectedTagDetails?.config?.view_type,
          label:
            selectedTagDetails?.config?.view_type === 'interstitial'
              ? 'Interstitial'
              : selectedTagDetails?.config?.view_type === 'mid_roll'
              ? 'Mid Roll'
              : selectedTagDetails?.config?.view_type === 'end_roll'
              ? 'End Roll'
              : selectedTagDetails?.config?.view_type === 'convert_pip'
              ? 'Convert pip'
              : '',
        }
  );

  const [selectedResolution, sSelectedResolution] = useState(
    createTag
      ? {
          label: 'Medium Rectangle (300 x 250 pixels)',
          value: 'Medium Rectangle (300 x 250 pixels)',
          width: '250px',
          height: '300px',
        }
      : resolutionOpt?.find((x: any) => x?.value === selectedTagDetails?.config?.feed_resolution) ||
          resolutionOpt[3]
  );

  const [loopShareMsg, setLoopShareMsg] = useState('');

  const [glossary, sGlossary] = useState<any>({});

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    sSection(pSection);
  }, [pSection]);

  //GET customer details
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded: any = JWT.decode(token, { complete: true });
    sCutomerData({ id: decoded?.payload?.company_id });
  }, []);

  useEffect(() => {
    if (selectedTagDetails?.config?.iab_cat && selectedTagDetails?.config?.iab_cat.length > 0) {
      const newArr: any = [];
      categories.forEach((e1: any) => {
        selectedTagDetails?.config?.iab_cat?.forEach((e2: any) => {
          if (e1.value === e2) {
            newArr.push(e1);
          }
        });
      });
      setSelectedCategories(createTag ? [] : newArr);
    }
    if (selectedTagDetails?.config) {
      //setSmartFeed(selectedTagDetails?.config?.smart_feed ?? false);
      sVideoPlacement(selectedTagDetails?.config?.is_ad_at_end_of_video ?? true);
      setCount(selectedTagDetails?.config?.ads_freq ?? 5);
      sGenuinUser(kind === 'update' ? selectedTagDetails?.config?.genuin_user_name : '');
      // setSelectedOption(
      //   selectedTagDetails?.config
      //     ? {
      //         value: selectedTagDetails?.config?.view_type,
      //         label: selectedTagDetails?.config?.view_type,
      //       }
      //     : {
      //         value: 'interstitial',
      //         label: 'Interstitial',
      //       }
      // );
    }

    // if (selectedTagDetails?.config) {
    //   // console.log("---", resolutionOpt?.find((x: any) => x?.value === selectedTagDetails?.config?.feed_resolution) ||
    //   // resolutionOpt[3])
    //   sSelectedResolution(
    //     resolutionOpt?.find((x: any) => x?.value === selectedTagDetails?.config?.feed_resolution) ||
    //       resolutionOpt[3]
    //   );
    // }
  }, [selectedTagDetails?.config, createTag]);

  useMemo(() => {
    // if (count === 0) {
    //   sSwipeStatus(false);
    // } else {
    //   sSwipeStatus(true);
    // }

    if (selectedOption?.value === 'mid_roll') {
      setCount(count < 3 ? 3 : count);
    }
  }, [count, selectedOption?.value]);

  const getWebsiteURLprops = (array: any) => {
    sWebsiteTodos(array);
  };

  const getVideosURLprops = (array: any) => {
    sVideosTodos(array);
  };

  const getVideosIdsURLprops = (array: any) => {
    sVideosIdTodos(array);
  };

  /* validation schema */
  const integerReg = /^\d+(\.\d+)?$/;
  const reelSchema = Yup.object().shape({
    extensionName: Yup.string().required(glossary.addreel_modal_reel_name_error),
    ctaLink: Yup.string().url(glossary.addreel_modal_CTA_link_error),
    loopShare: Yup.string().matches(
      /^[a-z0-9]{14,16}$/,
      'Invalid format. Must be 14 to 16 characters, consisting of lowercase letters and digits.'
    ),
    floorPriceSwipe: Yup.number()
      .required(glossary.ssp_createext_input_floor_price_empty_error)
      .test('superior', glossary.ssp_createext_input_floor_price_valid_error, function (f2: any) {
        return integerReg.test(f2);
      })
      .typeError(glossary.ssp_createext_input_floor_price_valid_error),
    floorPriceAdPlacement: Yup.number()
      .required(glossary.ssp_createext_input_floor_price_ad_empty_error)
      .test(
        'superior',
        glossary.ssp_createext_input_floor_price_ad_valid_error,
        function (f2: any) {
          return integerReg.test(f2);
        }
      )
      .typeError(glossary.ssp_createext_input_floor_price_ad_valid_error),
    noVideos: Yup.number()
      .required('Enter feed video limit.')
      .typeError('Enter valid feed video limit')
      .test('superior', 'Minimum 3 feed video limit required.', function (f2: any) {
        return f2 >= this.resolve(3);
      })
      .test('superior', 'Maximum 10 feed video allowed.', function (f2: any) {
        return f2 <= this.resolve(10);
      }),
  });

  /*  Add End point data start */
  const handCreateleReel = (e: any) => {
    const payload: any = {
      ad_placement: videoPlacement ? 'endroll' : 'endroll',
      ads_freq: count,
      ads_slot_cpm: parseFloat(e.floorPriceAdPlacement),
      allowed_domains: websiteTodos ?? ['https://ads.infy.tv/'],
      config: {
        enable_ask_question: showQuestion,
        on_click: selectedOnCLick,
        show_comments: showComments,
        show_cta: showCTA,
        show_logo: showLogo,
        show_owner_details: showOwnerDetails,
        //show_pre_feed_vast: showPreFeedVast,
        show_reply: showReply,
        show_report: showReport,
        show_repost: showRepost,
        show_save: showSave,
        show_share: showShare,
        show_video_url: showVideoURL,
      },
      feed_max_videos: selectedOption.value === 'mid_roll' ? e.noVideos : 5,
      feed_resolution: selectedResolution?.value,
      feed_type: selectedFeedOption?.value,
      genuin_user_name: genuinUser,
      iab_cat: selectedCategories.map((x: any) => x.value),
      is_ad_at_end_of_video: videoPlacement,
      is_domain_permissible: isDomainAllowed,
      is_soft_floor: false,
      loop_share_string: e.loopShare,
      max_duration: 300,
      min_duration: 15,
      min_cpm: parseFloat(e.floorPriceSwipe),
      cta_text: e.ctaText,
      //smart_feed: smartFeed,
      status: 'active',
      tag_name: e.extensionName,
      videos: videosTodos,
      videos_ids: videosIdTodos,
      view_type: selectedOption.value,
    };
    // Check if CTA Link is available before adding it to the payload
    if (e.ctaLink) {
      payload.cta_link = e.ctaLink;
    }
    sSpinnerBtn(true);
    adReelAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          sExtentionData(data.data);
          sSection('2');
          getTags(data);
          toast.success(data.message);
        } else {
          setLoopShareMsg(data.message);
          //toast.error(data.message);
        }
        sSpinnerBtn(false);
      })
      .catch((error) => {
        //toast.error(glossary.service_unavailable);
      });
  };
  /*  Add End point data end */
  const handlUpdateeReel = (e: any) => {
    const payload: any = {
      ad_placement: videoPlacement ? 'endroll' : 'endroll',
      ads_freq: count,
      ads_slot_cpm: parseFloat(e.floorPriceAdPlacement),
      allowed_domains: websiteTodos ?? ['https://ads.infy.tv/'],
      config: {
        enable_ask_question: showQuestion,
        on_click: selectedOnCLick,
        show_comments: showComments,
        show_cta: showCTA,
        show_logo: showLogo,
        show_owner_details: showOwnerDetails,
        //show_pre_feed_vast: showPreFeedVast,
        show_reply: showReply,
        show_report: showReport,
        show_repost: showRepost,
        show_save: showSave,
        show_share: showShare,
        show_video_url: showVideoURL,
      },
      feed_max_videos: selectedOption.value === 'mid_roll' ? e.noVideos : 5,
      feed_resolution: selectedResolution?.value,
      feed_type: selectedFeedOption?.value,
      genuin_user_name: genuinUser,
      iab_cat: selectedCategories.map((x: any) => x.value),
      is_ad_at_end_of_video: videoPlacement,
      is_domain_permissible: isDomainAllowed,
      is_soft_floor: false,
      loop_share_string: e.loopShare,
      max_duration: 300,
      min_duration: 15,
      min_cpm: parseFloat(e.floorPriceSwipe),
      cta_text: e.ctaText,
      //smart_feed: smartFeed,
      status: 'active',
      tag_id: tagId,
      tag_name: e.extensionName,
      videos: videosTodos,
      videos_ids: videosIdTodos,
      view_type: selectedOption.value,
    };
    // Check if CTA Link is available before adding it to the payload
    if (e.ctaLink) {
      payload.cta_link = e.ctaLink;
    }
    sSpinnerBtn(true);
    adReelAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          sExtentionData(data.data);
          sSection('2');
          getTags(data.data);
          toast.success(data.message);
        } else {
          setLoopShareMsg(data.message);
          //toast.error(data.message);
        }
        sSpinnerBtn(false);
      })
      .catch((error) => {
        //toast.error(glossary.service_unavailable);
      });
  };

  const verifyGenuinId = (user_id: string) => {
    const payload = {
      user_name: user_id,
    };
    setIsLoading(true);
    getVerifyExtensionAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          setIsVerified(true);
          sGenuinUser(data.data.nickname);
        } else {
          setIsVerified(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        // Server Error
      });
  };

  //verify Genuin ID
  useEffect(() => {
    if (validGenuinUser) {
      setIsLoading(true);
      setIsVerified(false);
      const getData = setTimeout(() => {
        verifyGenuinId(validGenuinUser);
      }, 500);

      return () => clearTimeout(getData);
    }
  }, [validGenuinUser]);

  //Appned script tag when get tag id
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Define the script source URL based on the environment
    const scriptSrc = genuin?.scriptURL;

    script.src = scriptSrc;
    script.async = true; // Load the script asynchronously
    script.defer = true; // Add the defer attribute for better performance

    // Define an error handler for script loading errors
    const handleScriptError = (event: any) => {
      console.error('Error loading script:', event);
    };

    // Attach the error event listener to the script
    script.addEventListener('error', handleScriptError);

    // Append the script element to the document's body
    document.body.appendChild(script);

    // Cleanup: Remove the script element and event listener on unmount
    return () => {
      script.removeEventListener('error', handleScriptError);
      document.body.removeChild(script);
    };
  }, [extentionData?.id]);

  return (
    <>
      {section === '1' && (
        <>
          {initialCreate && (
            <div className='d-flex'>
              <div className={`${initialCreate ? 'pt-4' : 'mt-4'} px-3 w-50`}>
                <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Create Ad Reels</h3>
              </div>
            </div>
          )}
          {kind === 'update' && (
            <div className='modal-header px-3'>
              <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Update Ad Reel</h3>
            </div>
          )}
          <Formik
            validationSchema={reelSchema}
            initialValues={reelInitialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(e) => {
              if (createTag) {
                handCreateleReel(e);
              } else {
                handlUpdateeReel(e);
              }
              //handleReel(values); // Call your submission logic
            }}
          >
            {({ values }) => (
              <Form className={'rtb-form edit'}>
                <div
                  className={'modal-body p-3'}
                  style={
                    initialCreate
                      ? {
                          maxHeight: 'auto',
                          overflowY: 'auto',
                        }
                      : {
                          maxHeight: 'calc(100vh - 210px)',
                          overflowY: 'auto',
                        }
                  }
                >
                  <div className='row mb-3'>
                    <div className={initialCreate ? 'col-md-6' : 'col-md-6 mx-auto'}>
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='text'
                              label={glossary.addreel_modal_reel_name_label}
                              placeholder={glossary.addreel_modal_reel_name_placeholder}
                              name='extensionName'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-12'>
                          <label className='label'>
                            {glossary.ssp_createext_input_label_genuin_id}
                          </label>
                          <div className={'input-group'}>
                            <span className='input-group-text'>@</span>
                            <Field
                              type='text'
                              className='form-control'
                              onChange={(event: any) => {
                                setValidGenuinUser(event.target.value);
                              }}
                              placeholder={
                                createTag
                                  ? glossary.ssp_createext_input_genuin_id_placeholder
                                  : selectedTagDetails?.config?.genuin_user_name
                              }
                              value={validGenuinUser}
                              name='genuinUser'
                              defaultValue={
                                createTag ? '' : selectedTagDetails?.config?.genuin_user_name
                              }
                              disabled={kind === 'update' ? true : false}
                            />
                            {!isLoading && isVerified && !isIdEmpty && (
                              <span className='input-group-text bg-transparent'>
                                <IoMdCheckmark fontSize={20} color={'green'} />
                              </span>
                            )}
                          </div>
                          {validGenuinUser.length < 1 && (
                            <ErrorMessage
                              name='genuinUser'
                              component='div'
                              className='small text-danger'
                            />
                          )}
                          {/* User not exist error msg */}
                          {!isLoading && !isIdEmpty && validGenuinUser !== '' && !isVerified && (
                            <p className='text-danger small'>
                              <b className='fw-lighter me-1'>{validGenuinUser}</b>
                              {glossary.ssp_createext_input_genuin_id_validation_error}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 mb-3'>
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='text'
                              label={glossary.addreel_modal_loop_share_label}
                              placeholder={glossary.addreel_modal_loop_share_placeholder}
                              name='loopShare'
                            />
                          </div>
                          {selectedFeedOption.value === 'loop' && values.loopShare === '' && (
                            <div className='col-12'>
                              <p className='small text-danger'>
                                {glossary.addreel_modal_loop_share_error}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='text'
                              label={glossary.addreel_modal_CTA_link_label}
                              placeholder={glossary.addreel_modal_CTA_link_placeholder}
                              name='ctaLink'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='text'
                              label={glossary.addreel_modal_CTA_text_label}
                              placeholder={glossary.addreel_modal_CTA_text_placeholder}
                              name='ctaText'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <hr />
                          <p className='sub-title mb-3'>
                            {glossary.addreel_modal_hr_feed_configure_label}
                          </p>
                        </div>
                        <div className='col-md-12 mb-3'>
                          <Dropdown
                            label={'Feed Type'}
                            option={feedTypeOpt}
                            onchange={setSelectedFeedOption}
                            value={selectedFeedOption}
                            search={true}
                            classNamePrefix={'feeds-type'}
                            // placement={'top'}
                          />
                        </div>
                        <div className='col-12'>
                          <div className='row'>
                            <div className='col-12 mb-3'>
                              <Dropdown
                                kind='multi'
                                name='iabCategory'
                                label={glossary.ssp_createext_ddl_label_iab_category}
                                onchange={(e: any) => setSelectedCategories(e)}
                                value={selectedCategories}
                                option={categories}
                                search={true}
                                //disabled={smartFeed ? false : true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 mb-3'>
                          <Dropdown
                            label={glossary.ssp_createext_ddl_label_view_type}
                            option={addRellviewTypeOpt}
                            onchange={setSelectedOption}
                            value={selectedOption}
                            search={true}
                            classNamePrefix={'view-type'}
                            // placement={'top'}
                          />
                        </div>
                        {selectedOption?.value === 'interstitial' && (
                          <div className='col-md-12 mb-3'>
                            <Dropdown
                              label={'Resolution'}
                              option={resolutionOpt}
                              onchange={sSelectedResolution}
                              value={selectedResolution}
                              search={false}
                              defaultValue={selectedResolution}
                              // placement={'top'}
                            />
                          </div>
                        )}
                        {selectedOption?.value !== 'interstitial' && (
                          <div className='col-md-12 mb-3'>
                            <div className='form-group'>
                              <FormikController
                                kind='withouticon'
                                control='input'
                                type='number'
                                label={glossary.ssp_createext_input_label_videos}
                                placeholder={glossary.ssp_createext_input_videos_placeholder}
                                name='noVideos'
                                disabled={selectedOption?.value === 'mid_roll' ? false : true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label'>
                            {glossary.addreel_modal_radio_action_performed_label}
                          </label>
                          <div className='d-flex gap-3'>
                            <div className={'col-auto mt-0'}>
                              <Radio
                                size={'mini'}
                                label={glossary.addreel_modal_radio_action_full_screen_label}
                                id={'fullscreen'}
                                selected={selectedOnCLick}
                                click={haandleSelectedOnCLick}
                              />
                            </div>
                            <div className={'col-auto mt-0'}>
                              <Radio
                                size={'mini'}
                                label={glossary.addreel_modal_radio_action_modal_label}
                                id={'modal'}
                                selected={selectedOnCLick}
                                click={haandleSelectedOnCLick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowQuestion(chec)}
                            status={
                              createTag ? false : selectedTagDetails?.config?.enable_ask_question
                            }
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_to_show_question_modal}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowComments(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_comments}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_comment_icon}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowCTA(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_cta}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_botton_CTA}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowLogo(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_logo}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_logo}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowOwnerDetails(chec)}
                            status={
                              createTag ? false : selectedTagDetails?.config?.show_owner_details
                            }
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_videos_owner_details}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowReply(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_reply}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_reply_icon}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowReport(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_report}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_report_icon}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowRepost(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_repost}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_repost_icon}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowSave(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_save}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_save_icon}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowShare(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_share}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_share_icon}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='pt-4 col-12 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => sShowVideoURL(chec)}
                            status={createTag ? false : selectedTagDetails?.config?.show_video_url}
                            //disabled={genuinOpenContent ? true : false}
                          />
                          <label className='label mb-0 d-flex justify-content-center'>
                            {glossary.addreel_modal_toggle_show_videos_web_link}
                          </label>
                        </div>
                      </div>

                      <div className='row mb-3 justify-content-between'>
                        <div className='col-7 pt-4 d-flex gap-3 align-items-center switch'>
                          <div className='align-items-center d-flex gap-2 label mb-0'>
                            <span>{glossary.ssp_createext_field_text_after}</span>
                            <div className='d-flex'>
                              <Button
                                type='button'
                                kind={
                                  count <= (selectedOption?.value === 'mid_roll' ? 3 : 0)
                                    ? 'disabled-secondary'
                                    : 'primary'
                                }
                                click={() => {
                                  setCount((prev: number) => prev - 1);
                                }}
                                text='-'
                                css={{
                                  minWidth: 40,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              />
                              <div
                                className='d-flex align-items-center justify-content-center'
                                style={{
                                  borderTop: '1px solid #cbcbcb',
                                  borderBottom: '1px solid #cbcbcb',
                                  width: 50,
                                }}
                              >
                                <p className='text-black'>{count}</p>
                              </div>
                              <Button
                                type='button'
                                kind={count >= 5 ? 'disabled-secondary' : 'primary'}
                                click={() => {
                                  setCount((prev: number) => prev + 1);
                                }}
                                text='+'
                                css={{
                                  minWidth: 40,
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                              />
                            </div>
                            <span>{glossary.ssp_createext_field_text_swipe_show}</span>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='form-group'>
                            <FormikController
                              kind='withiconstart'
                              control='input'
                              type='text'
                              label={glossary.ssp_createext_input_label_floor_price}
                              placeholder={glossary.ssp_createext_input_floor_price_placeholder}
                              name='floorPriceSwipe'
                              maxLength={5}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-7 pt-4 mb-3 d-flex gap-3 align-items-center switch'>
                          <Switch
                            api={(chec: any) => {
                              chec === true ? sVideoPlacement(true) : sVideoPlacement(false);
                            }}
                            status={videoPlacement}
                          />
                          <label className='label mb-0'>
                            {glossary.ssp_createext_field_text_ad_placement}
                          </label>
                        </div>
                        <div className='col-5'>
                          <div
                            className={
                              videoPlacement === false ? 'disable-field form-group' : 'form-group'
                            }
                          >
                            <FormikController
                              kind='withiconstart'
                              control='input'
                              type='text'
                              label={glossary.ssp_createext_input_label_floor_price_ad}
                              placeholder={glossary.ssp_createext_input_floor_price_ad_placeholder}
                              name='floorPriceAdPlacement'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <hr />
                          <div className='d-flex align-items-center mb-3'>
                            <label className='label dark m-0 me-2'>
                              {glossary.addreel_modal_domain_configure_label}
                            </label>
                            <Switch
                              api={(chec: any) => {
                                chec === true ? sIsDomainAllowed(true) : sIsDomainAllowed(false);
                              }}
                              status={isDomainAllowed}
                            />
                          </div>
                          {isDomainAllowed && (
                            <WebsiteUrl
                              websiteURLprops={getWebsiteURLprops}
                              selectedTagDetails={selectedTagDetails}
                              createTag={createTag}
                            />
                          )}
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <VideosUrl
                            websiteURLprops={getVideosURLprops}
                            selectedTagDetails={selectedTagDetails}
                            createTag={createTag}
                            selectedFeedOption={selectedFeedOption}
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-12'>
                          <VideosIds
                            websiteURLprops={getVideosIdsURLprops}
                            selectedTagDetails={selectedTagDetails}
                            createTag={createTag}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={initialCreate ? 'px-3 pb-3' : 'modal-footer px-3'}>
                  <div className='d-flex w-100 justify-content-between'>
                    <div className='col-auto'>
                      <div className='d-flex gap-2 justify-content-around'>
                        <div
                          className={
                            section === '1' ? 'createtag-pagination active' : 'createtag-pagination'
                          }
                        ></div>
                        <div
                          className={
                            section === '2' ? 'createtag-pagination active' : 'createtag-pagination'
                          }
                        ></div>
                      </div>
                      <label className='mt-2'>{glossary.ssp_createssai_note_one}</label>
                    </div>
                    <div className='col-auto'>
                      <div className='d-flex gap-3'>
                        {!initialCreate && (
                          <Button kind='secondary' text='Close' type='button' click={onHide} />
                        )}
                        {spinnerBtn ? (
                          <Button
                            type='button'
                            kind={'spinner'}
                            customClass={'col-auto'}
                            text={'Loading...'}
                          />
                        ) : (
                          <>
                            {kind === 'update' ? (
                              <Button type='submit' kind={'primary'} text={'Update'} />
                            ) : (
                              <Button
                                type='submit'
                                kind={isVerified && !isIdEmpty ? 'primary' : 'disabled-secondary'}
                                text={glossary.ssp_createtag_create_button}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}

      {section === '2' && (
        <>
          <div className={createTag ? 'mt-4 px-3 d-inline-block' : 'modal-header px-3'}>
            <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>AdReel Details</h3>
          </div>
          <div
            className='modal-body p-3'
            style={{
              maxHeight: initialCreate ? '100%' : 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <div className='custome-tabs'>
              <div className='row'>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-12 align-items-center mb-3'>
                      <SendEmail tagId={tagId} />
                    </div>
                    <div className='col-12 modal-details-tabs'>
                      <Tabs defaultActiveKey='java'>
                        <Tab eventKey='java' title={glossary.ssp_createext_details_tab_javascript}>
                          <JavaScriptCode
                            tagId={tagId}
                            createTag={createTag}
                            selectedTagDetails={selectedTagDetails}
                            cutomerData={cutomerData}
                            extentionData={extentionData}
                            websiteTodos={websiteTodos}
                            resolution={selectedResolution}
                          />
                        </Tab>
                        <Tab eventKey='html' title={glossary.ssp_createext_details_tab_html_code}>
                          <HtmlCode
                            tagId={tagId}
                            createTag={createTag}
                            selectedTag={selectedTagDetails}
                            cutomerData={cutomerData}
                            extentionData={extentionData}
                            websiteTodos={websiteTodos}
                            resolution={selectedResolution}
                          />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
                <div className='col-6 mt-0'>
                  <DeviceView
                    tagId={tagId}
                    selectedTagDetails={selectedTagDetails}
                    cutomerData={cutomerData}
                    createTag={createTag}
                    extentionData={extentionData}
                    websiteTodos={websiteTodos}
                    selectedOption={selectedOption}
                    selectedResolution={selectedResolution}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={initialCreate ? 'px-3 p-3' : 'modal-footer px-3'}>
            <div
              className={
                createTag && section === '2'
                  ? 'd-flex w-100 justify-content-between'
                  : 'd-flex w-100 justify-content-end'
              }
            >
              <div className={createTag && section === '2' ? 'col-auto' : 'd-none'}>
                <div className='d-flex gap-2 justify-content-around'>
                  <div
                    className={
                      section === '1' ? 'createtag-pagination active' : 'createtag-pagination'
                    }
                  ></div>
                  <div
                    className={
                      section === '2' ? 'createtag-pagination active' : 'createtag-pagination'
                    }
                  ></div>
                </div>
                <label className='mt-2'>{glossary.ssp_createssai_success_note_one}</label>
              </div>
              <div className='col-auto d-flex gap-3'>
                {!initialCreate && (
                  <button
                    onClick={() => {
                      onHide();
                    }}
                    type='button'
                    className='button button-large button-secondary float-end'
                  >
                    Close
                  </button>
                )}
                <button
                  onClick={() => {
                    initialCreate ? windowReload() : onHide();
                    //sMailSent(false);
                  }}
                  type='submit'
                  className='button button-large button-primary float-end'
                >
                  {glossary.ssp_createext_details_button_done}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
