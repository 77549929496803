import { Modal } from 'react-bootstrap';

import Button from '../widgets/button';
import store from '../../states';
import { useEffect, useState } from 'react';

export default ({ api, onHide, show }: any) => {
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header className='px-3'>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
          {glossary.ssp_supply_tag_delete_popup_title}
        </h3>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <p className='py-4'>{glossary.ssp_supply_tag_delete_popup_instruction}</p>
      </Modal.Body>
      <Modal.Footer className='px-3 gap-2'>
        <Button
          kind={'secondary'}
          text={glossary.ssp_supply_tag_delete_popup_button_cancel}
          click={() => {
            onHide();
          }}
        />
        <Button
          kind={'primary'}
          text={glossary.ssp_supply_tag_delete_popup_button_delete}
          click={() => {
            api();
            onHide();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
