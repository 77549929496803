import { useEffect, useState, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import { useLocation, useHistory } from 'react-router';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Tile from '../../widgets/tile';
import Line from '../../charts/line';
import Reports from '../../helpers/reports';
import { tag as tagTiles } from '../../helpers/tiles';

import { updateEndpointAPI, getEndpointReportAPI, getVastXMLAPI } from '../../../services/endpoint';
import { getEndpointTagsAPI } from '../../../services/tags';

import { MdInfoOutline } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import add from '../../../assets/icons/add.svg';

import AddEndpoint from '../../modals/endpoint-add';
import DetailsEndpoint from '../../modals/endpoint-details';
import EditEndpoint from '../../modals/endpoint-edit';
import MsaSign from '../../modals/msa-sign';
import TagsListTable from '../../tables/tagsListTable';
import DemandSidebar from '../../sidebar/Demand';
import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';
import DemandOverview from '../../components/overview/demand';
import TableSkeleton from '../../loaders/table';
import store from '../../../states';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import Button from '../../widgets/button';
import { UserInfoContext } from '../../contexts/userInfoContext';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

interface Props {
  sExpandStatusProp: any;
  sSearchToggleProp: any;
}

const SourcesPage = (props: Props) => {
  /* Context API's */
  const { msaDetails, customerInfo }: any = useContext(UserInfoContext);
  const { getEndpointsListAPI, getBidderListAPI }: any = useAuthentication();
  const {
    endPointData,
    sEndPointData,
    bidderListData,
    sBidderListData,
    dateRangeSources,
    sDateRangeSources,
  }: any = consumerData();
  const location = useLocation();

  const [selectedTile, sSelectedTile] = useState(0);
  const [selectedCustomer, sSelectedCustomer] = useState('');
  const [customer, sCustomer] = useState(false);
  const [singleTileData, sSingleTileData] = useState<any>([]);
  const [lineData, sLineData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [empty, sEmpty] = useState(false);
  //const [endPointData, sEndPointData] = useState<any>([]);
  const [customerDetails, sCustomerDetails] = useState<any>(null);
  //const [partnerDetails, sPartnerDetails] = useState<any>(null);
  const [pDatatables, sPDatatables] = useState([]);
  const [random, sRandom] = useState(Math.random());
  const [editHBandRTBShow, sEditHBandRTBShow] = useState(false);
  const [editHBandRTBShow1, sEditHBandRTBShow1] = useState(false);
  const [detailsModal, sDetailsModal] = useState(false);
  const [endpointTagsData, sEndpointTagsData] = useState([]);
  const [tableSkeleton, setTableSkeleton] = useState(true);
  const [qps, setQps] = useState('');
  const [updatedData, sUpdatedData] = useState<any>([]);
  const [vastXML, setVastXML] = useState<any>();
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [sidebarSkeleton, sSidebarSkeleton] = useState(false);
  //const [btnSkeleton, sBtnSkeleton] = useState(true);
  //const [sidebarTabs, sSidebarTabs] = useState('1');
  const [customerLoader, sCustomerLoader] = useState(true);
  const [isQpsUpdating, setIsQpsUpdating] = useState(false);
  //const [allData, setAllData] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // const [selectionRange, setSelectionRange] = useState<any>();

  const [hasMore, sHasMore] = useState(true);
  const [currentPage, sCurrentPage] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'impression',
      desc: true,
    },
  });
  const [paramsStatus, setParamsStatus] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();

  // useEffect(() => {
  //   setSelectionRange(dateRangeSources);
  // }, [dateRangeSources]);

  const GetSelectionRangeProps = (data: any) => {
    // setSelectionRange(data);
    sDateRangeSources(data);
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  /* overview data above chart */
  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  /* select card */
  const selectedCard = (id: string) => {
    if (selectedCustomer !== id) {
      history.push(`/publisher/demand/sources/${id}`);
      sPDatatables([]);
      sSingleTileData([]);
      sRefinedLineData([]);
      sLineData([]);
      sSelectedCustomer(id);
      // getCustomerDetails(id);
      //getSourceTagsList(id);
      sSelectedTabSources('performance');
    }
  };

  useEffect(() => {
    if (selectedCustomer) getSourceTagsList(selectedCustomer);
  }, [selectedCustomer]);

  // Get Source Tags
  const getSourceTagsList = (id: any) => {
    const payload = {
      end_point_id: id,
    };
    getEndpointTagsAPI(payload)
      .then((data: any) => {
        if (data) {
          setTableSkeleton(false);
          sEndpointTagsData(data?.data);
        }
      })
      .catch(() => {
        //ok
      });
  };

  /* Get costomer data */
  const getCustomerDetails = (id: string) => {
    const payload = {
      id,
      start: convert(dateRangeSources?.startDate) || '',
      end: convert(dateRangeSources?.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    //setAllData(false);
    if (dateRangeSources?.startDate !== dateRangeSources?.endDate) {
      sCustomerLoader(true);
      getEndpointReportAPI(payload)
        .then((data: any) => {
          if (data) {
            const tileData = tagTiles(data.total);
            sSingleTileData(tileData);
            sSelectedTile(0);
            sRandom(Math.random());
            sPDatatables(endPointData.find((x: any) => x.id === id)?.tables ?? []);
            sCustomerLoader(false);
            //setAllData(true);
          }
        })
        .catch(() => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
      // }
    }
  };

  /***** Handle on scroll API's start *****/
  const handleParamsChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
      setParamsStatus(status);
    }
  };

  useEffect(() => {
    const id = pathname.split('/publisher/demand/sources/')[1];
    if (endPointData.length === 0 || params.query === '') {
      getEndpointListInfo(params, id);
    }
    if (paramsStatus === true) {
      getEndpointListInfo(params, id);
    }
  }, [params.page, params.query, params.sortBy, paramsStatus]);

  /* Get Endpoint List Info */
  const getEndpointListInfo = (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    sSidebarSkeleton(true);
    getEndpointsListAPI(payload)
      .then((data: any) => {
        if (data) {
          sSidebarSkeleton(false);
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sEndPointData([...endPointData, ...data.data]);
            } else {
              // selectedCard(params.page === 0 ? endPointData[0].id : selectedCustomer);
              sEndPointData(endPointData);
              sHasMore(false);
              sEmpty(data.data.length < 1);
            }
            // sSidebarSkeleton(false);
          } else {
            sEndPointData(data.data);
            sEmpty(data.data.length < 1);
            // if (data.data.length === 0 && sidebarTabs !== '2' && params?.query === '') {
            //   sSidebarTabs('2');
            // }
            sHasMore(data.data.length === 0 ? false : true);
            //compare id when type id in param
            if (_id) {
              selectedCard(data.data.find((x: any) => x.id == _id)?.id);
            }
            //setAllData(true);
            // sSidebarSkeleton(false);
          }
          sCurrentPage(params.page);
          //sSidebarSkeleton(false);
          sEditHBandRTBShow(false);
          sEditHBandRTBShow1(false);
          //sBtnSkeleton(false);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  /***** UPDATE ENDPOINT LIST BY ADD/UPDATE ENDPOINT START *****/
  const handleEndpointLists = (endpointObject: any, kind: string, enType?: string) => {
    if (kind === 'add') {
      if (enType !== 'GAM') {
        const newEndpointObject = { ...endpointObject.data };
        const newArr = [newEndpointObject, ...endPointData];
        // newArr.splice(1, 0, newEndpointObject);
        selectedCard(newEndpointObject.id);
        sEndPointData(newArr);
        sCustomer(false);
      } else {
        const newEndpointArray = endpointObject.data;
        const newArr = [...endPointData.slice(0, 1), ...newEndpointArray, ...endPointData.slice(1)];
        selectedCard(newEndpointArray[0].id);
        sEndPointData(newArr);
        sCustomer(false);
      }
    }
    if (kind === 'update') {
      const newEndpointObj = { ...endpointObject.data };
      const newArray = [...endPointData];
      const index = newArray.findIndex((item) => item.id === newEndpointObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...newEndpointObj };
      }
      sEndPointData(newArray);
      sUpdatedData(newArray);
      sEditHBandRTBShow(false);
    }
  };
  /***** UPDATE ENDPOINT LIST BY ADD/UPDATE ENDPOINT END *****/

  /* Handle Card Toggle & Traffic Table Toggle of Endpoint */
  const manageToggleUpdate = (data: any, kind: string) => {
    // console.log('data', data, kind);
    if (kind === 'card') {
      const newArray = [...endPointData];
      const index = newArray.findIndex((item) => item.id === data.endpoint.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...data.endpoint };
        //newArray[index] = { ...newArray[index], status: data.endpoint.status };
      }
      sEndPointData(newArray);
      sUpdatedData(newArray);
      sEndpointTagsData(data.data);
    }
    if (kind === 'list') {
      sEndpointTagsData(data.data);
      const newArray = [...endPointData];
      const index = newArray.findIndex((item) => item.id === data.endpoint.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...data.endpoint };
        //newArray[index] = { ...newArray[index], status: data.endpoint.status };
      }
      sCustomerDetails(data.endpoint);
      sEndPointData(newArray);
      sUpdatedData(newArray);
      // }
    }
  };

  useEffect(() => {
    if (updatedData.length > 0) {
      sEndPointData(updatedData ?? []);
      //sPublisherData(updatedData ?? []);
      sPDatatables(updatedData.tables);
      sCustomer(false);
      sEditHBandRTBShow(false);
      sEditHBandRTBShow1(false);
      const getCustomer: any = updatedData.find((x: any) => x.id === selectedCustomer);
      sCustomerDetails(getCustomer);
    }
  }, [updatedData]);

  /* Set Max QPS for Endpoint Update API */
  const updateMaxQPS = (e: any) => {
    setQps(e);
    const payload = {
      id: selectedCustomer,
      qps: parseFloat(e),
    };
    setIsQpsUpdating(true);
    updateEndpointAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          handleEndpointLists(data, 'update');
          toast.success('Successfully QPS Updated.');
          sMaxQpsBtn(false);
        } else {
          toast.error(data.message);
          sMaxQpsBtn(true);
        }
        setIsQpsUpdating(false);
      })
      .catch((error: any) => {
        toast.error(error);
      });
  };

  const editHBandEndPoint = (tagId: any) => {
    sEditHBandRTBShow(true);
    if (tagId) sDetailsModal(true);
  };

  const editHBandEndPoint1 = (tagId: any) => {
    sEditHBandRTBShow1(true);
    if (tagId) sDetailsModal(true);
  };

  // On Page Refresh reporting table load
  useMemo(() => {
    if (endPointData?.length > 0 || selectedCustomer) {
      sPDatatables(endPointData.find((x: any) => x.id === selectedCustomer)?.tables ?? []);
    }
  }, [endPointData, selectedCustomer]);

  //Update QPS & Get VAST_XML Hash Code
  useEffect(() => {
    if (selectedCustomer) {
      setQps(endPointData?.find((x: any) => x.id === selectedCustomer)?.config?.qps);
      sMaxQpsBtn(false);
      const getCustomer: any = endPointData.find((x: any) => x.id === selectedCustomer);
      sCustomerDetails(getCustomer);

      /* get xml hash code number */
      if (selectedCustomer && getCustomer?.type === 'vast_xml') {
        const xmlEndpoint = endPointData?.find((x: any) => x.id === selectedCustomer)?.endpoint;
        initiateGetVastXml(xmlEndpoint);
      }
    }
  }, [selectedCustomer, endPointData]);

  useEffect(() => {
    if (selectedCustomer !== '') {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  useEffect(() => {
    //if (selectedCustomer) getCustomerDetails(selectedCustomer);
    if (selectedCustomer && dateRangeSources?.endDate && dateRangeSources?.startDate) {
      getCustomerDetails(selectedCustomer);
    }
  }, [selectedCustomer, dateRangeSources]);

  /* Chart sata start */
  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      //sRefinedLineData({ labels: newlabels, values: newValues });
      sRefinedLineData({ labels: newValues, values: newlabels });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  /* get xml vast code */
  const initiateGetVastXml = (xmlCode: any) => {
    const payload = {
      xml_code: xmlCode,
    };
    getVastXMLAPI(payload)
      .then((data: any) => {
        setVastXML(data);
      })
      .catch((error: any) => {
        // error
      });
  };

  const createSource = () => {
    ReactGA.event('ssp_demand_sidebar_addnew_button', {
      category: 'ssp_demand',
      action: 'click',
      label: 'ssp_demand_sidebar_addnew_button',
    });
    if (msaDetails.status) {
      sCustomer(true);
    }
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  const [maxQpsBtn, sMaxQpsBtn] = useState(false);
  const handleMaxQps = (e: any) => {
    const newValue = e.target.value;
    if (newValue.match(/^[0-9]*\.?\d{0,2}$/) && newValue <= 10000) {
      setQps(newValue);
    } else {
      //
    }
    if (newValue !== '' && newValue.toString().length <= 7) {
      sMaxQpsBtn(true);
    } else {
      sMaxQpsBtn(false);
    }
  };

  /* Selected while you reload page */
  const [selectedTabSources, sSelectedTabSources] = useState('performance');

  const handleSourcesTabs = (key: any) => {
    sSelectedTabSources(key);
  };
  /* selected while you reload page */

  /* Get Bidder list api */
  useEffect(() => {
    if (Object.keys(bidderListData).length === 0 && customerInfo?.milestone?.tag) {
      // Your code when bidderListData is empty
      getHeaderBiderList();
    }
  }, [customerInfo, bidderListData]);

  const getHeaderBiderList = () => {
    getBidderListAPI()
      .then((data: any) => {
        sBidderListData(data);
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const [expandStatus, sExpandStatus] = useState<any>(true);
  const [searchToggle, sSearchToggle] = useState<any>(true);

  const getExpandSidebar = (expand: boolean) => {
    sExpandStatus(expand);
  };
  const getSearchToggle = (expand: boolean) => {
    sSearchToggle(expand);
  };

  props.sExpandStatusProp(expandStatus);
  props.sSearchToggleProp(searchToggle);

  return (
    <>
      {/* Modal Component For MSA*/}
      <MsaSign
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />

      {/* Modal Component For Add Endpoint */}
      <AddEndpoint
        show={customer}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sCustomer(false);
        }}
        customerID={customerInfo?.id}
      />

      {/* Modal Component For Endpoint Details */}
      <DetailsEndpoint
        show={editHBandRTBShow1}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sEditHBandRTBShow1(false);
        }}
        enpointListData={endPointData?.find((x: any) => x.id === selectedCustomer)}
        enpointListDataConfigObj={endPointData?.find((x: any) => x.id === selectedCustomer)?.config}
        endpointId={endPointData?.find((x: any) => x.id === selectedCustomer)?.id}
        Hbkey={endPointData?.find((x: any) => x.id === selectedCustomer)?.config?.bidderTitle}
        openDetailsModal={detailsModal}
        xmlEndpoint={vastXML}
        bidderListData={bidderListData}
      />

      {/* Modal Component For Edit Endpoint Details */}
      <EditEndpoint
        show={editHBandRTBShow}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sEditHBandRTBShow(false);
        }}
        enpointListData={endPointData?.find((x: any) => x.id === selectedCustomer)}
        enpointListDataConfigObj={endPointData?.find((x: any) => x.id === selectedCustomer)?.config}
        endpointId={endPointData?.find((x: any) => x.id === selectedCustomer)?.id}
        Hbkey={endPointData?.find((x: any) => x.id === selectedCustomer)?.config?.bidderTitle}
        openDetailsModal={detailsModal}
        xmlEndpoint={vastXML}
        bidderListData={bidderListData}
      />

      <DemandSidebar
        title={glossary.dsp_dash_sidebar_title_sources}
        kind={'dsp'}
        size={'collapsed'}
        card={'expanded'}
        action={{
          icon: add,
          text: glossary.dsp_dash_sidebar_button_add_new,
          click: createSource,
          className: 'tour-addnew-dsp',
        }}
        overviewAction={{
          text: glossary.overview_button,
          click: () => {
            sSelectedCustomer('');
            history.push('/publisher/demand/sources');
          },
          isOverview: pathname === '/publisher/demand/sources' ? true : false,
        }}
        items={endPointData}
        info={selectedCard}
        tagDetails={editHBandEndPoint1}
        tagDetails1={editHBandEndPoint}
        empty={empty}
        getEndpointApi={manageToggleUpdate} //Get Publisher Endpoint list data for card toggle
        selected={selectedCustomer}
        currentPage={currentPage}
        loading={sidebarSkeleton}
        hasMore={hasMore}
        params={params}
        handleParams={handleParamsChange}
        expandSidebar={getExpandSidebar}
        searchToggle={getSearchToggle}
        allData={true}
      />
      <div className='view-section'>
        {selectedCustomer === '' &&
        endPointData &&
        !location.pathname.split('/publisher/demand/sources/')[1] ? (
          <DemandOverview empty={empty} />
        ) : (
          <Tab.Container
            defaultActiveKey={selectedTabSources}
            activeKey={selectedTabSources}
            onSelect={(key: any) => handleSourcesTabs(key)}
          >
            <div className='row align-items-center'>
              <div className='col-4 mt-0'>
                <div className='d-flex gap-3 align-items-center'>
                  {selectedCustomer !== '' && customerDetails && (
                    <>
                      <h2 className='text-truncate'>{customerDetails.name}</h2>
                      {selectedCustomer !== '0' && (
                        <>
                          <MdInfoOutline
                            className='svg-icon'
                            onClick={() => {
                              editHBandEndPoint1(
                                endPointData?.find((x: any) => x.id === selectedCustomer).id
                              );
                            }}
                            title='Details'
                            fontSize={20}
                          />
                          <BiEditAlt
                            className='svg-icon'
                            onClick={() => {
                              sEditHBandRTBShow(true);
                            }}
                            title='Edit'
                            fontSize={20}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className='col-4'>
                <Nav variant='pills'>
                  <Nav.Item>
                    <Nav.Link eventKey='performance'>Performance</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='traffic1'>Management</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div className='col-4'>
                <div
                  className={
                    selectedTabSources === 'performance'
                      ? 'calendar__wrap tour-calendar-wrap'
                      : 'calendar__wrap tour-calendar-wrap d-none'
                  }
                  style={{ position: 'relative' }}
                >
                  <div className={'calendar__wrap tour-calendar-wrap'}>
                    {selectedCustomer !== '' && customerDetails && (
                      // <DateRangePickerComponent
                      //   selectionRangeProps={GetSelectionRangeProps}
                      // />
                      <DateRangeFilter
                        onChange={GetSelectionRangeProps}
                        selectionRange={dateRangeSources}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Tab.Content>
                  <Tab.Pane eventKey='performance'>
                    {selectedCustomer !== '' && customerDetails && (
                      <>
                        {customerLoader ? (
                          <>
                            <div
                              className={'row'}
                              style={{ marginTop: '56px', justifyContent: 'space-between' }}
                            >
                              <div className='d-flex gap-4'>
                                {[1, 2, 3, 4].map((index) => {
                                  return <Box key={index} />;
                                })}
                              </div>
                            </div>
                            <div className='row mt-4'>
                              <div className='col w-100 m-auto'>
                                <XYChart />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={'row'}
                              style={{
                                marginTop: '56px',
                              }}
                            >
                              {singleTileData.map((tile: any, index: any) => {
                                return (
                                  <div key={index} className={'col-auto'}>
                                    {refinedLineData.labels < 1 &&
                                    refinedLineData.values < 1 ? null : (
                                      <Tile
                                        id={tile.id}
                                        selected={selectedTile}
                                        click={selectTile}
                                        label={tile.label}
                                        value={tile.value}
                                        info={tile.info}
                                        arrow={tile.arrow}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              className={'row tour-linechart-2'}
                              style={{
                                height: '320px',
                                marginTop: '24px',
                              }}
                            >
                              <Line
                                label={'Impressions'}
                                data={refinedLineData}
                                attribute={attribute}
                              />
                            </div>
                          </>
                        )}
                        <div className={'row mt-4 report-table'}>
                          <Reports
                            id={selectedCustomer}
                            start={dateRangeSources?.startDate}
                            end={dateRangeSources?.endDate}
                            tabs={pDatatables}
                            random={random}
                            customerId={customerInfo.id}
                            entityType={'end_point'}
                          />
                        </div>
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey='traffic1'>
                    <div className={'row position-relative'}>
                      {tableSkeleton || customerLoader ? (
                        <div className='row' style={{ marginTop: 56 }}>
                          <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
                        </div>
                      ) : (
                        <div style={{ marginTop: 25 }}>
                          <div
                            className='d-flex gap-3 justify-content-end position-absolute'
                            style={{ right: '15px' }}
                          >
                            <div className='d-flex flex-column'>
                              <label className='label'>Max QPS *</label>
                              <input
                                name='maxqps'
                                className='text-input'
                                type={'number'}
                                placeholder={'Enter Max QPS'}
                                maxLength={7}
                                value={qps}
                                onChange={(e) => handleMaxQps(e)}
                              />
                            </div>
                            <div className='d-flex flex-column'>
                              <label className='label'>&nbsp;</label>
                              {isQpsUpdating ? (
                                <Button kind={'spinner'} type={'button'} text={glossary.updating} />
                              ) : (
                                <Button
                                  type={maxQpsBtn ? 'button' : 'submit'}
                                  kind={maxQpsBtn ? 'primary' : 'disabled'}
                                  click={() => updateMaxQPS(qps)}
                                  text='Update'
                                />
                              )}
                            </div>
                          </div>

                          <TagsListTable
                            rows={endpointTagsData ?? []}
                            getEndpointListInfoAPI={manageToggleUpdate}
                            selectedCustomer={selectedCustomer}
                          />
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        )}
      </div>
    </>
  );
};

export default SourcesPage;
