const BidderList = () => {
    return (
        <>
            {[1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13].map((index) => {
                return (
                    <div key={index} className='skeleton my-3'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <div className='d-flex gap-3'>
                                    <div className='skeleton__logo skeleton__comman flex-shrink-0'></div>
                                    <div className='skeleton__line skeleton__comman'></div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='skeleton__line skeleton__comman'></div>
                            </div>
                        </div>
                    </div>
                );
            })}

        </>
    );
};

export default BidderList;
