import Switch from '../widgets/switch';
import { GoLinkExternal } from 'react-icons/go';
import VideoPlayer from '../modals/video-player';
import { useState } from 'react';
import { openNewTab } from './functions';

const ReportingTable = (props: any) => {
  const {
    accessor,
    row,
    tableType,
    customerId,
    selectedCardId,
    toggleInclude,
    toggleExclude,
    //videoModal,
    //video,
    rowsId,
  } = props;

  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const handleVideo = (videoUrl: any) => {
    //videoModal(true);
    //video(videoUrl);
    setVideoUrl(videoUrl);
    setShowVideo(true);
  };

  return (
    <>
      <VideoPlayer show={showVideo} onHide={() => setShowVideo(false)} video={videoUrl} />

      {/* Toggle for all Included Columns */}
      {accessor === 'included' && (
        <div className='d-flex gap-2'>
          <Switch
            key={rowsId}
            api={(chec: any) => {
              toggleExclude(row);
              // chec !== true
              //   ? toggleInclude(row.id, tableType, selectedCardId, customerId)
              //   : toggleExclude(row.id, tableType, selectedCardId, customerId);
            }}
            status={row?.included}
          />
        </div>
      )}

      {/* Publisher Advertiser Domain Link Click */}
      {/* {accessor === 'advertiser_domain' && (
        <>
          {row.advertiser_domain !== '-' && (
            <a href={`http://${row.advertiser_domain}`} target='_blank' rel='noreferrer'>
              {row.advertiser_domain}
            </a>
          )}
        </>
      )} */}

      {/* Open Video Player click on Creatives  */}
      {accessor === 'creative_name' && (
        <>
          {row.creative_url !== '-' && (
            <>
              <GoLinkExternal
                className='me-2 svg-icon'
                fontSize={18}
                onClick={() => handleVideo(row.creative_url)}
              />
              <span data-timeframe={row.creative_url}>{row.creative_url}</span>
            </>
          )}
        </>
      )}

      {/* Genuin Reporting Video Open */}
      {accessor === 'video_share_string' && (
        <>
          {row.video_url !== '-' && (
            <>
              <GoLinkExternal
                className='me-2 svg-icon'
                fontSize={18}
                onClick={() => openNewTab(row.video_url)}
              />
              <a href={row.video_url} target='_blank' rel='noreferrer'>
                {row.video_share_string}
              </a>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReportingTable;
