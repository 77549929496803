import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikController from '../../comman/form/FormikController';
import Button from '../../widgets/button';
import VPlayer from './VPlayer';

interface PlayerProps {
  items: any;
  genuinInfo: any;
  show: boolean;
  showConfigure: boolean;
  onHide(): void;
  configFormSubmit(formData: any): void;
}

const AdreelPlayer = ({
  items,
  genuinInfo,
  show,
  showConfigure,
  onHide,
  configFormSubmit,
}: PlayerProps) => {
  const [updatedItems, setUpdatedItems] = useState(items || {});
  const [formUpdated, setFormUpdated] = useState(false);

  const FormSchema = Yup.object().shape({
    cta_text: Yup.string().nullable(),
    cta_link: Yup.string().url('Enter valid URL').typeError('Enter valid URL'),
    ad_copy: Yup.string().max(60, 'Maximum 60 characters allowed'),
    pixel_url: Yup.string().url('Enter valid pixel URL').typeError('Enter valid pixel URL'),
    hash_tags: Yup.string()
      .nullable()
      .matches(
        /^(#\w+\b\s*){1,3}(?!\S)|^#\w+\b$/,
        'Include "#" at the beginning, up to three, separated by spaces'
      ),
  });

  const FormInitialValues = {
    cta_text: updatedItems?.cta_text || '',
    cta_link: updatedItems?.cta_link || '',
    show_url_meta: updatedItems?.show_url_meta || false,
    ad_copy: updatedItems?.ad_copy || '',
    pixel_url: updatedItems?.pixel_link || '',
    hash_tags: updatedItems?.hash_tags?.join(' ') || '',
    size_mode: updatedItems?.config?.size_mode || 'fit-to-width',
  };

  const handleOnChange = (_e: any) => {
    setFormUpdated((prev: boolean) => (prev === false ? true : prev));
    const { name, value, checked } = _e.target;
    setUpdatedItems((prevItems: any) => {
      if (name !== 'size_mode') {
        return {
          ...prevItems,
          [name]:
            name === 'hash_tags'
              ? value?.length > 0
                ? value?.split(' ')
                : []
              : name === 'show_url_meta'
              ? checked
              : value,
        };
      }

      if (name === 'size_mode') {
        return {
          ...items,
          config: { ...items.config, size_mode: value },
        };
      }

      return prevItems;
    });
  };

  useEffect(() => {
    setUpdatedItems(items);
    setFormUpdated(false);
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size='lg' centered>
      <Formik
        validationSchema={FormSchema}
        initialValues={FormInitialValues}
        validateOnChange
        validateOnBlur
        onSubmit={(e: any) => {
          configFormSubmit(updatedItems);
          onHide();
        }}
        enableReinitialize
      >
        {({ values, errors }) => (
          <Form onChange={handleOnChange}>
            <Modal.Body
              style={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'auto' }}
              className='p-3'
            >
              <div className='row'>
                {/* Left Video Player */}
                <div className={`${showConfigure && 'col-6'} d-flex justify-content-center left`}>
                  <div className='vp'>
                    <div className='vp_player'>
                      <VPlayer
                        videoType={
                          updatedItems?.config?.size_mode === 'fit-to-height'
                            ? 'cover'
                            : updatedItems?.config?.size_mode === 'fill'
                            ? 'fill'
                            : 'contain'
                        }
                        items={updatedItems}
                        genuinInfo={genuinInfo}
                      />
                    </div>
                  </div>
                </div>

                {/* Right Side Form */}
                {showConfigure && (
                  <div className='col-6 pe-5 right'>
                    <div className='row'>
                      <h3 className='mb-3'>Video Configuration</h3>
                      <div className='col-12 mb-3 d-flex justify-content-between'>
                        <label htmlFor='fit-to-height' className='d-flex gap-1 radio-label'>
                          <Field
                            type='radio'
                            name='size_mode'
                            className='input-radio radio-o me-1'
                            value='fit-to-height'
                            id='fit-to-height'
                          />
                          Fit to Height
                        </label>

                        <label htmlFor='fit-to-width' className='d-flex gap-1 radio-label'>
                          <Field
                            type='radio'
                            name='size_mode'
                            className='input-radio radio-o me-1'
                            value='fit-to-width'
                            id='fit-to-width'
                          />
                          Fit to Width
                        </label>

                        <label htmlFor='fill' className='d-flex gap-1 radio-label'>
                          <Field
                            type='radio'
                            name='size_mode'
                            className='input-radio radio-o me-1'
                            value='fill'
                            id='fill'
                          />
                          Fill
                        </label>
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'cta_text'}
                          label={'CTA Text'}
                          placeholder={'Enter CTA Text'}
                          maxLength={24}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'cta_link'}
                          label={'CTA Link'}
                          placeholder={'Enter CTA Link'}
                        />
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'hash_tags'}
                          label={'Hashtags'}
                          placeholder={'Up to 3 hashtags seperated by space'}
                        />
                      </div>
                      <div className='col-12 mb-3 d-flex align-items-center'>
                        <Field type='checkbox' name='show_url_meta' />
                        <p className='ms-2'>URL Meta</p>
                      </div>
                      <div className='col-12 mb-3'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'ad_copy'}
                          label={'Ad Copy'}
                          placeholder={'Enter Ad Copy Text'}
                          maxLength={61}
                        />
                      </div>
                      <div className='col-12'>
                        <FormikController
                          type='text'
                          control='input'
                          kind='withouticon'
                          name={'pixel_url'}
                          label={'Pixel URL'}
                          placeholder={'Please put your pixel URL here'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className='d-flex gap-2'>
              <Button kind='secondary' type='button' text='Close' click={onHide} />
              {showConfigure && (
                <Button kind={formUpdated ? 'primary' : 'disabled'} type='submit' text='Save' />
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AdreelPlayer;
