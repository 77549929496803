import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import Switch from '../../widgets/switch';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { addEndpointAPI, getLoopListAPI, updateEndpointAPI } from '../../../services/endpoint';
import store from '../../../states';
import Button from '../../widgets/button';
import FormikController from '../../comman/form/FormikController';
import DatePicker from '../../comman/datepicker/DatePicker';
import { IoMdCheckmark } from 'react-icons/io';
import { getVerifyExtensionAPI } from '../../../services/extension';
import UserProfile from '../extension/UserProfile';
import LoginWithMobile from '../extension/LoginWithMobile';
import SoicalUrl from '../extension/SoicalUrl';
import { roundTime, windowReload } from '../../helpers/functions';
import GenuinViewonly from './reel-io-login';
import InfyScrollDropdown from '../../widgets/IDropdown';
import { categories, loopList } from '../../helpers/dropdowns';
import Dropdown from '../../widgets/dropdown';

interface Props {
  enpointListDataConfigObj?: any;
  onHide?: any;
  endpointId?: any;
  enpointListData?: any;
  getEndPointListCard?: any;
  endpontDataStatus?: any;
  editDetailsDSPModal?: any;
  openDetailsModal?: any;
  getEndpointListInfoAPI?: any;
  xmlEndpoint?: any;
  // selectedTagDetails?: any;
  customerID?: number;
}

const ContextualReel = (props: Props) => {
  const {
    onHide,
    enpointListDataConfigObj,
    endpointId,
    getEndPointListCard,
    endpontDataStatus,
    openDetailsModal,
    enpointListData,
    // selectedTagDetails,
    customerID,
  } = props;

  const [impCapping, setImpCapping] = useState(
    enpointListDataConfigObj?.daily_imp_cap > 0 ? true : false
  );
  const [editDetailsDSPModal, sEditDetailsDSPModal] = useState<any>(false);
  // Genuin Section

  const [validGenuinUser, setValidGenuinUser] = useState(
    enpointListDataConfigObj?.extension_config?.genuin_user_name ?? ''
  );

  const [validateOTP, sValidateOTP] = useState(false);
  const [genuinUser, sGenuinUser] = useState<any>('');
  const [userProfile, sUserProfile] = useState('');
  const [videos, sVideos] = useState(enpointListDataConfigObj?.extension_user?.videos ?? 0);
  const [genuinData, sGenuinData] = useState<any>();
  const [phoneNo, setPhoneNo] = useState('');
  const [importMoreVideo, sImportMoreVideo] = useState(false);
  const [createAccount, sCreateAccount] = useState(false);
  const [socialUrlList, sSocialUrlList] = useState<any>();
  const [genuinOpenContent, setGenuinOpenContent] = useState(
    enpointListDataConfigObj?.genuin_open_content ?? false
  );

  const [startDate, setStartDate] = useState(
    enpointListDataConfigObj?.start_date
      ? new Date(enpointListDataConfigObj?.start_date.toString())
      : roundTime(new Date())
  );
  const [endDate, setEndDate] = useState(
    enpointListDataConfigObj?.end_date
      ? new Date(enpointListDataConfigObj?.end_date.toString())
      : roundTime(new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000))
  );
  // const [isAccountValid, setIsAccountValid] = useState<any>({});
  const [budget, setBudget] = useState(enpointListDataConfigObj?.budget ?? '');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [section, sSection] = useState('1');

  // Genuin
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [urlError, sUrlError] = useState(false);

  const genuinUserConfig = enpointListDataConfigObj?.genuin_user_data ?? '';
  const genuinUserUrl = enpointListDataConfigObj?.import_video_data ?? '';

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const ContextualFormInitialValues = {
    integrationName: enpointListData?.name ?? '',
    integrationCPM: enpointListData?.floor_price ?? '',
    // integrationBudget: enpointListDataConfigObj?.budget ?? '',
    advertiserDomain: enpointListDataConfigObj?.advertiser_domain ?? '',
    impressionCapping: enpointListDataConfigObj?.daily_imp_cap ?? '',
  };

  /* Budget Input Field */
  const handleBudget = (e: any) => {
    const newValue = e.target.value;
    if (newValue.match(/^[0-9]*\.?\d{0,2}$/)) {
      setBudget(newValue);
    }
  };

  /* Endpoint Add Function */
  const initiateReel = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_contextualreel_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });
    const CPMFloat = parseFloat(e.integrationCPM);
    const ImpCappingFloat = parseFloat(e.impressionCapping);

    const payload = {
      customer_id: customerID,
      endpoint: genuinOpenContent ? 'genuin' : genuinData?.nickname,
      endpoint_type: 'contextual_reel',
      name: e.integrationName,
      floor_price: CPMFloat,
      config: {
        min_cpm: CPMFloat,
        max_cpm: 0,
        is_soft_floor: false,
        is_all_traffic: false,
        budget: budget !== '' ? parseFloat(budget) : null,
        advertiser_domain: e.advertiserDomain,
        start_date: startDate.toUTCString(),
        end_date: endDate.toUTCString(),
        import_video_data: {
          insta_url: socialUrlList?.insta,
          tik_toc_url: socialUrlList?.tiktok,
          yt_url: socialUrlList?.youtube,
        },
        genuin_user_data: genuinData,
        daily_imp_cap: !impCapping ? null : ImpCappingFloat,
        genuin_loop_list: selectedLoop[0],
        iab_cat: selectedCategories.map((x: any) => x.value),
        genuin_open_content: genuinOpenContent,
      },
    };
    setIsSubmitting(true);
    addEndpointAPI(payload)
      .then((data: any) => {
        if (endpontDataStatus === false) {
          windowReload();
        }
        if (data?.status === true) {
          getEndPointListCard(data, 'add');
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setIsSubmitting(false);
      })
      .catch((error: any) => {
        //toast.error(glossary.dsp_add_endpoint_server_error);
      });
  };

  /* Endpoint Update Function */
  const updateEndpoint = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });

    const CPMFloat = parseFloat(e.integrationCPM);
    const ImpCappingFloat = parseFloat(e.impressionCapping);

    const payload = {
      id: endpointId,
      customer_id: customerID,
      endpoint: enpointListData.endpoint,
      endpoint_type: 'contextual_reel',
      name: e.integrationName,
      config: {
        min_cpm: CPMFloat,
        max_cpm: 0,
        is_soft_floor: false,
        is_all_traffic: false,
        budget: budget !== '' ? parseFloat(budget) : null,
        advertiser_domain: e.advertiserDomain,
        start_date: startDate.toUTCString(),
        end_date: endDate.toUTCString(),
        import_video_data: genuinUserUrl,
        genuin_user_data: genuinUserConfig,
        daily_imp_cap: !impCapping ? null : ImpCappingFloat,
        genuin_loop_list: enpointListDataConfigObj.genuin_loop_list,
        iab_cat: selectedCategories.map((x: any) => x.value),
        genuin_open_content: genuinOpenContent,
      },
    };
    setIsSubmitting(true);
    updateEndpointAPI(payload)
      .then((data: any) => {
        if (data?.status === true) {
          toast.success(data.message);
          getEndPointListCard(data, 'update');
        } else {
          toast.error(data?.message);
        }
        setIsSubmitting(false);
      })
      .catch((error: any) => {
        toast.error(glossary.dsp_update_rtb_faild_server_msg);
      });
  };

  const valReg = /^\d+(\.\d{1,2})?$/;
  const domainReg =
    /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const integerReg = /^\d+$/;

  /* validation schema */
  const SignupSchema = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_reel_popup_input_name_empty_error),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_reel_popup_input_cpm_empty_error)
      .test('superior', glossary.dsp_add_reel_popup_input_cpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .test('superior', glossary.dsp_add_reel_popup_input_cpm_error, function (f1: any) {
        return f1 > 0;
      })
      .max(99999, glossary.dsp_add_reel_popup_input_cpm_length_error)
      .typeError(glossary.dsp_add_reel_popup_input_cpm_type_error),
    advertiserDomain: Yup.string()
      .required(glossary.dsp_add_reel_popup_input_adv_domain_empty_error)
      .matches(domainReg, glossary.dsp_add_reel_popup_input_adv_domain_type_error)
      .typeError(glossary.dsp_add_reel_popup_input_adv_domain_type_error),
  });

  const SignupSchema1 = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_reel_popup_input_name_empty_error),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_reel_popup_input_cpm_empty_error)
      .test('superior', glossary.dsp_add_reel_popup_input_cpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .test('superior', glossary.dsp_add_reel_popup_input_cpm_error, function (f1: any) {
        return f1 > 0;
      })
      .max(99999, glossary.dsp_add_reel_popup_input_cpm_length_error)
      .typeError(glossary.dsp_add_reel_popup_input_cpm_type_error),
    advertiserDomain: Yup.string()
      .required(glossary.dsp_add_reel_popup_input_adv_domain_empty_error)
      .matches(domainReg, glossary.dsp_add_reel_popup_input_adv_domain_type_error)
      .typeError(glossary.dsp_add_reel_popup_input_adv_domain_type_error),
    impressionCapping: Yup.number()
      .required(glossary.dsp_add_reel_popup_input_imp_cap_empty_error)
      .test('superior', glossary.dsp_add_reel_popup_input_imp_cap_valid_error, function (f2: any) {
        return integerReg.test(f2);
      })
      .typeError(glossary.dsp_add_reel_popup_input_imp_cap_type_error),
  });

  /* Get Date Function */
  const getStartDate = (data: any) => {
    if (data) {
      setStartDate(data);
    }
  };

  const getEndDate = (data: any) => {
    if (data) {
      setEndDate(data);
    }
  };

  /* Get Looplist */
  const [loopLists, setLoopLists] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    query: '',
  });
  const [ddlLoading, setDdlLoading] = useState(false);
  const [selectedLoop, sSelectedLoop] = useState<any>([]);

  const getRoundtableList = (para: any, nickname: string) => {
    setParams({ page: para.page, query: '' });
    const payload = {
      nick_name: nickname,
      page: para.page,
      page_size: 50,
    };
    setHasMore(true);
    setDdlLoading(true);
    getLoopListAPI(payload)
      .then((data: any) => {
        setDdlLoading(false);
        if (!data.status) toast.error(data.message);

        if (para.page >= 0) {
          if (data.data.length !== 0) {
            setLoopLists([...loopLists, ...data.data]);
            const obj = data.data[0];
            if (para.page === 0)
              sSelectedLoop([{ group_name: obj.group_name, group_name_id: obj.group_name_id }]);
          } else {
            setHasMore(false);
            setLoopLists(loopLists);
          }
        }
      })
      .catch((error: any) => {
        // console.log('loopListError', error);
      });
  };

  const handleSelectLoop = (data: any) => {
    const select = loopLists.find((x: any) => x?.group_name_id === data[0]?.value);
    sSelectedLoop([select]);
  };

  /***** For Genuin Section *****/

  const clearGenuinRes = () => {
    setIsLoggedIn(false);
    sValidateOTP(false);
    setLoopLists([]);
    sSelectedLoop([]);
    setIsLoading(true);
    setPhoneNo('');
    sImportMoreVideo(false);
    sCreateAccount(false);
  };

  const verifyGenuinId = (user_id: string) => {
    const payload = {
      user_name: user_id,
    };
    getVerifyExtensionAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          setIsVerified(true);
          // setIsAccountValid(data);
          sGenuinUser(data.data.nickname);
          sVideos(data.data.videos);
          sUserProfile(data.data.profile_image_s);
          sGenuinData(data.data);
          setPhoneNo(data.data.phone);
          data?.is_login && getRoundtableList({ page: 0 }, data?.data?.nickname);
        } else {
          setIsVerified(false);
        }
        if (data.is_login === true) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        // Server Error
      });
  };

  /* Verifying Genuin ID's */
  useEffect(() => {
    if (validGenuinUser.length > 0 || validateOTP) {
      setIsLoading(true);
      setIsVerified(false);
      const getData = setTimeout(() => {
        verifyGenuinId(validGenuinUser);
      }, 500);

      return () => clearTimeout(getData);
    }

    if (validGenuinUser === '') {
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
      setIsLoggedIn(false);
      sValidateOTP(false);
      setLoopLists([]);
      sSelectedLoop([]);
    }
  }, [validGenuinUser.length, validateOTP]);

  const isIdEmpty = validGenuinUser !== '' ? false : true;

  const getValidateOTPProps = (event: boolean) => {
    sValidateOTP(event);
  };

  const getSocialURLprops = (url: any) => {
    sSocialUrlList(url);
  };

  const differenceInHours = Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  const dateCompare = endDate > startDate ? true : false; // Enddate Must be greater than Startdate validation

  useEffect(() => {
    if (enpointListDataConfigObj?.iab_cat && enpointListDataConfigObj?.iab_cat?.length > 0) {
      const newArr: any = [];
      categories.forEach((e1: any) => {
        enpointListDataConfigObj?.iab_cat?.forEach((e2: any) => {
          if (e1.value === e2) {
            newArr.push(e1);
          }
        });
      });
      setSelectedCategories(newArr);
    }
  }, [enpointListDataConfigObj?.iab_cat]);

  return (
    <>
      {section === '1' && (
        <>
          <div
            className={getEndPointListCard && 'modal-body p-3'}
            style={{
              maxHeight: 'calc(100vh - 210px)',
              minHeight: '350px',
              overflowY: 'auto',
            }}
          >
            <div className='row'>
              {!enpointListDataConfigObj ? (
                <>
                  <div className='col-6'>
                    <div className='d-flex mb-3 gap-2'>
                      <label className='label dark m-0'>Genuin Open Content</label>
                      <Switch
                        api={(chec: boolean) => {
                          if (chec === true) {
                            setGenuinOpenContent(true);
                            setValidGenuinUser('');
                            clearGenuinRes();
                            setIsLoading(false);
                          } else {
                            setGenuinOpenContent(false);
                          }
                        }}
                        status={genuinOpenContent}
                      />
                    </div>

                    {genuinOpenContent === true && (
                      <p>In this endpoint, the feed will be generated by AI.</p>
                    )}

                    {genuinOpenContent === false && (
                      <>
                        <div className='row mb-3'>
                          <div className='col-7'>
                            <label className='label'>
                              {glossary.dsp_add_reel_popup_input_label_genuin_id}
                            </label>
                            <div
                              className={
                                enpointListData ? 'input-group disable-extentsion' : 'input-group'
                              }
                            >
                              <span className='input-group-text'>@</span>
                              <input
                                type='text'
                                className='form-control'
                                onChange={(event: any) => {
                                  setValidGenuinUser(event.target.value);
                                  clearGenuinRes();
                                }}
                                placeholder={
                                  glossary.dsp_add_reel_popup_input_placeholder_genuin_id
                                }
                                defaultValue={
                                  enpointListDataConfigObj?.extension_config?.genuin_user_name ?? ''
                                }
                                value={validGenuinUser}
                              />

                              {!isLoading && isVerified && !isIdEmpty && (
                                <span className='input-group-text bg-transparent'>
                                  <IoMdCheckmark fontSize={20} color={'green'} />
                                </span>
                              )}
                            </div>

                            {!isLoading && !isIdEmpty && isLoggedIn && videos >= 0 && (
                              <UserProfile
                                userProfile={userProfile}
                                genuinUser={genuinUser}
                                genuinData={genuinData}
                              />
                            )}

                            {/* User not exist error msg */}
                            {!isLoading && !isIdEmpty && !isVerified && (
                              <p className='text-danger small'>
                                <b className='fw-lighter me-1'>{validGenuinUser}</b>
                                {glossary.dsp_add_reel_popup_input_genuin_id_not_exist_error}
                              </p>
                            )}

                            {/* {genuinError !== '' && !isLoading && (
                              <p className='text-danger small'>{genuinError}</p>
                            )} */}
                          </div>
                          <div className='col-auto d-flex flex-column'>
                            <label className='label'>&nbsp;</label>

                            {isLoading && (
                              <button className='button button-primary' type='button' disabled>
                                <span
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                                <span className='visually-hidden'>Loading...</span>
                              </button>
                            )}

                            {!isLoading && isIdEmpty && (
                              <Button
                                text={glossary.create_btn}
                                type={'button'}
                                kind={'disabled'}
                              />
                            )}

                            {/* Import More Videos Button */}
                            {!isLoading && !isIdEmpty && (isLoggedIn || validateOTP) && (
                              <button
                                type='button'
                                className='button button-primary'
                                onClick={() => sImportMoreVideo(true)}
                              >
                                {glossary.dsp_add_reel_popup_button_import_more_video}
                              </button>
                            )}

                            {/* Login Button */}
                            {!isIdEmpty && isVerified && !isLoggedIn && (
                              <Button
                                text={'Login'}
                                type={'button'}
                                kind={'primary'}
                                click={() => sImportMoreVideo(true)}
                              />
                            )}

                            {/* Create Button */}
                            {!isLoading &&
                              !isIdEmpty &&
                              !isVerified &&
                              !isLoggedIn &&
                              !validateOTP && (
                                <button
                                  type='button'
                                  className='button button-primary'
                                  onClick={() => sCreateAccount(true)}
                                >
                                  {glossary.dsp_add_reel_popup_button_id_create}
                                </button>
                              )}
                          </div>
                        </div>

                        <div className='row mb-3'>
                          {!isIdEmpty && isVerified && videos >= 0 && isLoggedIn && (
                            <>
                              <div className='col-7'>
                                <InfyScrollDropdown
                                  label={glossary.dsp_add_reel_popup_ddl_label_loop_list}
                                  option={loopList(loopLists)}
                                  value={loopList(selectedLoop)}
                                  onSelect={handleSelectLoop}
                                  hasMore={hasMore}
                                  params={params}
                                  isLoading={ddlLoading}
                                  handleParams={(p: number) => {
                                    getRoundtableList({ page: p }, genuinUser);
                                  }}
                                />
                              </div>
                              <div className='col-5 d-flex align-items-end'>
                                {selectedLoop[0]?.group_no_of_views && (
                                  <div className='d-flex gap-3'>
                                    <div className='text-center'>
                                      <p
                                        className='fw-lighter'
                                        style={{ fontSize: '12px', lineHeight: '16px' }}
                                      >
                                        {glossary.dsp_add_reel_popup_field_label_view}
                                      </p>
                                      <p style={{ fontSize: '12px', lineHeight: '16px' }}>
                                        {selectedLoop[0]?.group_no_of_views}
                                      </p>
                                    </div>
                                    <div className='text-center'>
                                      <p
                                        className='fw-lighter'
                                        style={{ fontSize: '12px', lineHeight: '16px' }}
                                      >
                                        {glossary.dsp_add_reel_popup_field_label_videos}
                                      </p>
                                      <p style={{ fontSize: '12px', lineHeight: '16px' }}>
                                        {selectedLoop[0]?.group_no_of_videos}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div className='mb-3'>
                          {/* login with mobile number and verify OTP component */}

                          <LoginWithMobile
                            importMoreVideo={importMoreVideo}
                            createAccount={createAccount}
                            loginSuccessful={isLoggedIn}
                            validGenuinUser={validGenuinUser}
                            genuinUser={genuinUser}
                            loader={isVerified}
                            validateOTPProps={getValidateOTPProps}
                            LoginphoneNo={phoneNo}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className='col-6'>
                    {/* insta, youtube and tiktok profile URL component */}
                    {!isIdEmpty && (
                      <SoicalUrl
                        importMoreVideo={importMoreVideo}
                        loginSuccessful={isLoggedIn}
                        selectedTagDetails={enpointListData}
                        validateOTP={validateOTP}
                        socialURLprops={getSocialURLprops}
                        urlError={sUrlError}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex mb-3 gap-2'>
                    <label className='label dark m-0'>Genuin Open Content</label>
                    <Switch status={genuinOpenContent} disabled={true} />
                  </div>
                  {genuinOpenContent ? (
                    <p>In this endpoint, the feed will be generated by AI.</p>
                  ) : (
                    <GenuinViewonly
                      genuinUserConfig={genuinUserConfig}
                      genuinUserUrl={genuinUserUrl}
                      config={enpointListDataConfigObj}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className={getEndPointListCard && 'modal-footer px-3 gap-2'}>
            <Button
              type='button'
              text={glossary.close_btn}
              kind='secondary'
              click={() => {
                onHide();
              }}
            />
            {enpointListDataConfigObj ? (
              <Button
                type='submit'
                text={glossary.next_btn}
                kind={'primary'}
                click={() => {
                  sSection('2');
                }}
              />
            ) : (
              <Button
                type='submit'
                text={glossary.next_btn}
                kind={
                  genuinOpenContent || (isLoggedIn && !isIdEmpty && !urlError)
                    ? 'primary'
                    : 'disabled'
                }
                click={() => {
                  sSection('2');
                }}
              />
            )}
          </div>
        </>
      )}

      {section === '2' && (
        <Formik
          validationSchema={impCapping ? SignupSchema1 : SignupSchema}
          initialValues={ContextualFormInitialValues}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={(e) => {
            if (differenceInHours >= 24 && dateCompare) {
              if (enpointListDataConfigObj) {
                updateEndpoint(e);
              } else {
                initiateReel(e);
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className={editDetailsDSPModal ? 'rtb-form edit' : 'rtb-form disable'}>
              <div
                className={getEndPointListCard && 'modal-body p-3'}
                style={{
                  maxHeight: 'calc(100vh - 210px)',
                  minHeight: '350px',
                  overflowY: 'auto',
                }}
              >
                <div className='row '>
                  <div className='col-6 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.dsp_add_reel_popup_input_label_name}
                        placeholder={glossary.dsp_add_reel_popup_input_placeholder_name}
                        name='integrationName'
                        maxLength={32}
                      />
                    </div>
                  </div>

                  <div className='form-group col-3 mb-2'>
                    <FormikController
                      kind='withiconstart'
                      control='input'
                      type='number'
                      label={glossary.dsp_add_reel_popup_input_label_cpm}
                      placeholder={glossary.dsp_add_reel_popup_input_placeholder_cpm}
                      name='integrationCPM'
                    />
                  </div>
                  <div className='form-group col-3 mb-2'>
                    <div className='d-flex flex-column'>
                      <label className='label'>
                        {glossary.dsp_add_reel_popup_input_label_budget}
                      </label>
                      <div className='input-group flex-nowrap'>
                        <span className='input-group-text'>$</span>
                        <input
                          name='budget'
                          className='text-input'
                          type={'text'}
                          placeholder={glossary.dsp_add_reel_popup_input_placeholder_budget}
                          maxLength={10}
                          value={budget}
                          onChange={(e) => handleBudget(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-6 mb-2 form-group domain-input-icon'>
                    <FormikController
                      kind='withiconstart'
                      icon={glossary.dsp_add_reel_popup_input_adv_domain_icon}
                      control='input'
                      type='text'
                      label={glossary.dsp_add_reel_popup_input_label_adv_domain}
                      placeholder={glossary.dsp_add_reel_popup_input_placeholder_adv_domain}
                      name='advertiserDomain'
                      maxLength={255}
                    />
                  </div>

                  <div className='col-3 mb-2'>
                    <DatePicker
                      selectedDate={getStartDate}
                      selected={startDate}
                      label={glossary.dsp_add_reel_popup_input_label_start_date}
                      time={true}
                    />
                  </div>
                  <div className='col-3 mb-2'>
                    <DatePicker
                      selectedDate={getEndDate}
                      selected={endDate}
                      label={glossary.dsp_add_reel_popup_input_label_end_date}
                      time={true}
                    />
                    <p className='small text-danger'>
                      {dateCompare && differenceInHours < 24
                        ? glossary.dsp_add_reel_popup_date_difference_error
                        : ''}
                      {!dateCompare ? glossary.dsp_add_reel_popup_date_compare_error : ''}
                    </p>
                  </div>

                  <div className='col-6 mb-2'>
                    <Dropdown
                      kind='multi'
                      name='iabCategory'
                      label={glossary.dsp_add_reel_popup_ddl_label_iab_cat}
                      onchange={(e: any) => setSelectedCategories(e)}
                      value={selectedCategories}
                      option={categories}
                      search={true}
                      // placeHolder={glossary.dsp_add_reel_popup_ddl_iab_cat_placeholder}
                    />
                  </div>

                  <div className='col-6 mb-2'>
                    <div
                      className='mt-2 align-items-center d-flex switch'
                      style={{ paddingTop: '30px', height: '40px' }}
                    >
                      <label className='label mb-0 me-2 pt-1 text-body'>
                        {glossary.dsp_add_reel_popup_field_label_impression_capping}
                      </label>
                      <Switch
                        api={(chec: any) => {
                          chec ? setImpCapping(true) : setImpCapping(false);
                        }}
                        status={impCapping}
                      />
                      <div className='form-group ms-4'>
                        {impCapping && (
                          <FormikController
                            kind='withouticon'
                            control='input'
                            type='number'
                            placeholder={glossary.dsp_add_reel_popup_input_placeholder_imp_capping}
                            name='impressionCapping'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={getEndPointListCard && 'modal-footer px-3'}>
                <div className='d-flex justify-content-end gap-3'>
                  <Button
                    type='button'
                    kind={'secondary'}
                    text={glossary.back_btn}
                    click={() => {
                      sSection('1');
                    }}
                  />
                  {isSubmitting ? (
                    <>
                      <Button
                        kind={'spinner'}
                        text={enpointListDataConfigObj ? glossary.updating : glossary.creating}
                        type={'button'}
                      />
                    </>
                  ) : (
                    <>
                      {enpointListDataConfigObj ? (
                        <>
                          {openDetailsModal && (
                            <Button
                              type={'button'}
                              kind={'primary'}
                              click={() => {
                                sEditDetailsDSPModal(true);
                              }}
                              text={glossary.edit_btn}
                              customClass={editDetailsDSPModal ? 'd-none' : ''}
                            />
                          )}
                          {(!openDetailsModal || editDetailsDSPModal) && (
                            <Button kind={'primary'} text={glossary.update_btn} type={'submit'} />
                          )}
                        </>
                      ) : (
                        <Button
                          type={'submit'}
                          kind={'primary'}
                          text={glossary.create_btn}
                          // click={() => handleGenuinData()}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ContextualReel;
