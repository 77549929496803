import React, { useState } from 'react';
import Button from '../../widgets/button';
import { BsCheckCircle } from 'react-icons/bs';
import { getCreativeStripeLinkAPI, getSubscriptionsAPI } from '../../../services/stripe';
import { openNewTab, openSameTab } from '../../helpers/functions';
import { toast } from 'react-toastify';

interface Props {
  glossary: any;
  isOverDue: any;
}

const Subscriptions = ({ glossary, isOverDue }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const primary2 = getComputedStyle(document.documentElement).getPropertyValue('--primary-2');

  const items = [
    { rights: 'Unlimited creatives' },
    { rights: '1M Impressions at no cost' },
    { rights: 'Realtime reporting' },
    { rights: 'User support' },
    { rights: 'Up to 10 users' },
    { rights: 'Streamlined user management' },
  ];

  const getSubscriptions = () => {
    setIsLoading(true);
    const payload = `?redirect_url=${window?.location?.href}`;
    getSubscriptionsAPI(payload)
      .then((data: any) => {
        if (data?.data?.status) {
          openSameTab(data?.data?.url);
        }
        setIsLoading(false);
        toast.error(data?.message);
      })
      .catch((error: any) => {
        toast.error(glossary.service_unavailable);
        setIsLoading(false);
      });
  };

  const renewSubscriptions = () => {
    setIsLoading(true);
    const payload = `?redirect_url=${window?.location?.href}`;
    getCreativeStripeLinkAPI(payload)
      .then((data: any) => {
        if (data?.url) {
          openNewTab(data?.url);
        } else {
          toast.error(data?.message);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        toast.error(glossary.service_unavailable);
      });
  };

  return (
    <>
      <div className='creative-subscriptions'>
        <div className='col-12 text-center'>
          <h2>{isOverDue ? 'Action Due!' : 'Subscriptions'}</h2>
          <div className='h-discription'>
            {isOverDue ? (
              <div className='d-flex justify-content-center'>
                <p className='w-50'>
                  Your payment is due. For a smooth service experience, Please make sure your
                  payment method is up-to-date and you have enough funds.
                </p>
              </div>
            ) : (
              <>
                <p>
                  Repurpose your social media content and control the entire social media experience
                </p>
                <p> throughout the open web from content, feed, placement and execution.</p>
              </>
            )}
          </div>
        </div>

        <div className='creative-subscriptions-plans'>
          {/* Plan Card */}
          <div className='card-box'>
            <div className='card-row mb-3' style={{ alignItems: 'baseline' }}>
              <h5>Enterprise</h5>
              <h3
                style={{
                  color: primary2,
                }}
              >
                $3000.00
              </h3>
            </div>

            <div className='d-flex justify-content-center mb-3'>
              <div className='pricing'>
                <p style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '0.2px' }}>
                  As low as
                </p>
                <h1
                  style={{
                    fontSize: '32px',
                    lineHeight: '40px',
                  }}
                >
                  $3
                </h1>
                <p style={{ fontWeight: 'bold', letterSpacing: '0.2px' }}>Per 1000 Ad Calls</p>
              </div>
            </div>

            <div>
              {items.map((_i: any, index: any) => (
                <p key={index} className='d-flex gap-2 align-items-center mb-1'>
                  <BsCheckCircle fontSize={16} color={primary2} />
                  {_i.rights}
                </p>
              ))}
            </div>

            <div className='card-row mt-3'>
              {isLoading ? (
                <Button kind='spinner' text={'Loading...'} size='full' />
              ) : (
                <Button
                  kind='primary'
                  text={isOverDue ? 'Pay Now' : 'Subscribe Now'}
                  type='button'
                  size='full'
                  click={isOverDue ? renewSubscriptions : getSubscriptions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
