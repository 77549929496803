import { useEffect, useState } from 'react';
import JWT from 'jsonwebtoken';
import JSONSchemaForm from 'react-jsonschema-form';
import additionalMetaSchemas from 'ajv/lib/refs/json-schema-draft-04.json';

import Modal from 'react-bootstrap/Modal';
import Button from '../widgets/button';
import { toast } from 'react-toastify';

import { updateEndpointAPI } from '../../services/endpoint';
import Addrtbendpoint from '../components/RTB/RTB-endpoint';
import store from '../../states';

import GAMdetails from '../components/GAM/GAM-details';
import InsertionOrder from '../components/insertion-order/InsertionOrder';
import ContextualReel from '../components/reel-IO/reel-io-create';

const DetailsHBandRTB = (props: any) => {
  const {
    onHide,
    show,
    enpointListData,
    enpointListDataConfigObj,
    endpointId,
    getEndPointList,
    openDetailsModal,
    getEndpointListInfoAPI,
    xmlEndpoint,
    bidderListData,
  } = props;

  const [customerID, sCustomerID] = useState();
  const [editDetailsDSPModal, sEditDetailsDSPModal] = useState<any>(false);
  const [newObjHBEdit, setNewObjHBEdit] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* add custom class form schema form */
  const uiSchema = {
    classNames: 'headerbidder-form px-3 py-3',
  };

  /* Update end point start */
  const updateHBinfo = (e: any) => {
    const payload = {
      id: endpointId,
      endpoint: enpointListData?.endpoint?.toLowerCase(),
      endpoint_type: 'hb_connector',
      config: e.formData,
      customer_id: customerID,
    };
    setIsSubmitting(true);
    updateEndpointAPI(payload)
      .then((data: any) => {
        if (data.status) {
          getEndPointList(data, 'update');
          toast.success(glossary.dsp_update_hb_success_msg);
        } else {
          toast.error(glossary.dsp_update_hb_update_error);
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 300);
      })
      .catch(() => {
        toast.error(glossary.dsp_update_hb_server_error);
      });
  };
  /* Update end point end */

  /* Get customer id from JWT token */
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    sCustomerID(decoded.payload.company_id);
  }, []);
  /* Get customer id from JWT token */

  useEffect(() => {
    if (enpointListData?.endpoint !== '') {
      const results = Object.values(bidderListData).filter((user: any) => {
        return user.title?.toLowerCase().startsWith(enpointListData?.endpoint?.toLowerCase());
      });
      setNewObjHBEdit(results?.[0]);
    }
  }, [endpointId, bidderListData]);

  // headerbidder schema object
  const obj1: any = newObjHBEdit;

  // config object from enpoint list api
  const obj2: any = enpointListData?.config;

  //create new object for default key
  const objConvert: any =
    obj2 &&
    Object.keys(obj2).map((key: any) => ({
      key: key,
      default: obj2[key],
    }));

  //get key from config object
  const configKeys = obj2 && Object.keys(obj2);

  //filter object key and value as per config key
  const getNewObj =
    obj1 &&
    Object?.keys(obj1?.properties)
      .filter((key: any) => configKeys?.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = obj1?.properties[key];
        return obj;
      }, {});

  // get new object keys
  const filterKey = getNewObj && Object.keys(getNewObj);

  // get new object if key match return new object
  const filtered =
    objConvert &&
    objConvert.length &&
    objConvert?.filter(
      (person: any) => filterKey && filterKey.length && filterKey.includes(person.key)
    );

  //filter and push new object to old object if key match
  filtered &&
    filtered.forEach(function (el: any) {
      const key = el.key;
      const defaultValue = el.default;
      const temp = Object.assign({}, getNewObj[key]);
      temp.default = defaultValue;
      getNewObj[key] = temp;
    });

  const finalSchemaObj =
    obj1 &&
    Object.assign({}, obj1, {
      ...obj1,
      properties: {
        integrationName: {
          description: 'Header Bidder Name',
          type: 'string',
          default: enpointListData?.config?.integrationName,
        },
        ...getNewObj,
      },
      //required: ['integrationName', 'min_cpm', 'max_cpm', ...(obj1.required ?? '')],
      required: ['integrationName', ...(obj1.required ?? '')],
    });

  delete finalSchemaObj?.oneOf;
  delete finalSchemaObj?.anyOf;

  return (
    <Modal
      className={openDetailsModal ? 'header-bidder-modal edit' : 'header-bidder-modal'}
      onHide={() => {
        onHide();
        sEditDetailsDSPModal(false);
      }}
      show={show}
      centered
      size={'xl'}
    >
      <div className='modal-header px-3'>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
          {enpointListData?.type === 'end_point'
            ? glossary.dsp_details_rtb_popup_title
            : enpointListData?.type === 'vast_url'
            ? glossary.dsp_details_io_popup_title_vast_url
            : enpointListData?.type === 'vast_xml'
            ? glossary.dsp_details_io_popup_title_vast_xml
            : enpointListData?.type === 'contextual_reel'
            ? glossary.dsp_details_reel_popup_title
            : enpointListData?.type === 'GAM'
            ? glossary.dsp_details_gam_popup_title
            : glossary.dsp_details_hb_popup_title}
        </h3>
      </div>
      {enpointListData?.type === 'end_point' ? (
        <Addrtbendpoint
          enpointListDataConfigObj={enpointListDataConfigObj}
          endpointId={endpointId}
          getEndPointListCard={getEndPointList}
          onHide={onHide}
          openDetailsModal={openDetailsModal}
          enpointListData={enpointListData}
          getEndpointListInfoAPI={getEndpointListInfoAPI}
        />
      ) : enpointListData?.type === 'vast_url' ? (
        <>
          <InsertionOrder
            enpointListDataConfigObj={enpointListDataConfigObj}
            enpointListData={enpointListData}
            endpointId={endpointId}
            getEndPointListCard={getEndPointList}
            onHide={onHide}
            openDetailsModal={openDetailsModal}
            getEndpointListInfoAPI={getEndpointListInfoAPI}
          />
          {/* <CreateVastTagDsp
          enpointListDataConfigObj={enpointListDataConfigObj}
          enpointListData={enpointListData}
          endpointId={endpointId}
          getEndPointListCard={getEndPointList}
          onHide={onHide}
          openDetailsModal={openDetailsModal}
          getEndpointListInfoAPI={getEndpointListInfoAPI}
        /> */}
        </>
      ) : enpointListData?.type === 'vast_xml' ? (
        <>
          <InsertionOrder
            enpointListDataConfigObj={enpointListDataConfigObj}
            enpointListData={enpointListData}
            endpointId={endpointId}
            getEndPointListCard={getEndPointList}
            onHide={onHide}
            openDetailsModal={openDetailsModal}
            getEndpointListInfoAPI={getEndpointListInfoAPI}
            xmlEndpoint={xmlEndpoint}
          />
          {/* <CreateVastXml
          enpointListDataConfigObj={enpointListDataConfigObj}
          enpointListData={enpointListData}
          endpointId={endpointId}
          getEndPointListCard={getEndPointList}
          onHide={onHide}
          openDetailsModal={openDetailsModal}
          getEndpointListInfoAPI={getEndpointListInfoAPI}
          xmlEndpoint={xmlEndpoint}
        /> */}
        </>
      ) : enpointListData?.type === 'GAM' ? (
        <GAMdetails
          enpointListDataConfigObj={enpointListDataConfigObj}
          enpointListData={enpointListData}
          endpointId={endpointId}
          getEndPointListCard={getEndPointList}
          onHide={onHide}
          openDetailsModal={openDetailsModal}
          getEndpointListInfoAPI={getEndpointListInfoAPI}
          xmlEndpoint={xmlEndpoint}
        />
      ) : enpointListData?.type === 'contextual_reel' ? (
        <ContextualReel
          enpointListDataConfigObj={enpointListDataConfigObj}
          enpointListData={enpointListData}
          endpointId={endpointId}
          getEndPointListCard={getEndPointList}
          onHide={onHide}
          openDetailsModal={openDetailsModal}
          getEndpointListInfoAPI={getEndpointListInfoAPI}
          xmlEndpoint={xmlEndpoint}
        />
      ) : (
        <>
          <div className={editDetailsDSPModal ? 'rtb-form edit' : 'rtb-form disable'}>
            <JSONSchemaForm
              additionalMetaSchemas={[additionalMetaSchemas]}
              liveValidate
              schema={finalSchemaObj}
              uiSchema={uiSchema}
              showErrorList={false}
              onSubmit={updateHBinfo}
              noHtml5Validate
            >
              <div className='modal-footer px-3'>
                <div className='d-flex gap-3'>
                  <Button
                    type='button'
                    kind={'secondary'}
                    text={glossary.dsp_details_hb_popup_button_close}
                    click={() => {
                      onHide();
                    }}
                  />
                  {openDetailsModal && !editDetailsDSPModal && (
                    <Button
                      type={'button'}
                      kind={'primary'}
                      click={() => sEditDetailsDSPModal(true)}
                      text={glossary.dsp_details_hb_popup_button_edit_hb}
                    />
                  )}
                  {isSubmitting ? (
                    <Button type={'button'} kind={'spinner'} text={glossary.updating} />
                  ) : (
                    <>
                      {(!openDetailsModal || editDetailsDSPModal) && (
                        <Button
                          type={'submit'}
                          kind={'primary'}
                          text={glossary.dsp_update_hb_popup_button_update_hb}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </JSONSchemaForm>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DetailsHBandRTB;
