import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { IoCalendarOutline } from 'react-icons/io5';

interface Props {
  onChange(_date: any): void;
  label: string;
}

const Calender = ({ onChange, label }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date('2000/01/01'));

  const primary2 = getComputedStyle(document.documentElement).getPropertyValue('--primary-2');

  useEffect(() => {
    onChange(selectedDate);
  }, [selectedDate]);

  return (
    <div className='date-range-picker'>
      <div className='d-flex flex-column'>
        <label className='label'>{label}</label>
        <div className='input-group flex-nowrap'>
          <span className='input-group-text'>
            <IoCalendarOutline fontSize={18} />
          </span>
          <input
            className='text-input'
            type='text'
            value={`${new Date(selectedDate).toLocaleDateString()}`}
            onClick={() => setShowModal(true)}
            readOnly
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      {showModal && (
        <div className='calendar__wrap__modal'>
          <Calendar
            onChange={setSelectedDate}
            date={selectedDate}
            dateDisplayFormat='dd/MM/yyyy'
            color={primary2}
            maxDate={new Date()}
          />
          <div className='calendar__wrap__overlay' onClick={() => setShowModal(false)}></div>
        </div>
      )}
    </div>
  );
};

export default Calender;
