import { useEffect, useRef, useState } from 'react';

import { BsEye, BsEyeSlash } from 'react-icons/bs';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
  name?: string;
  label: string;
  text?: string;
  size?: 'full';
  kind?: 'text' | 'mini' | 'inputicon' | 'percentage' | 'suggestion';
  type?: string;
  value?: string;
  error?: string;
  ecss?: {
    margin: string;
  };
  placeholder?: string;
  read?: boolean;
  blur?(): void;
  change(key: string, value: string): void;
  typing?(i: any): void;
  length?: any;
  style?: any;
  id?: any;
  option?: any;
  isLoading?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
}

export default (props: Props) => {
  const {
    kind,
    type,
    blur,
    read,
    value,
    label,
    error,
    typing,
    change,
    name = '',
    placeholder,
    length,
    style,
    id,
    option,
    isLoading,
    disabled,
    autoFocus,
  } = props;

  const [inputType, sInputType] = useState(type);
  const [showPassword, sShowPassword] = useState(false);
  const [val, sVal] = useState('');

  //@ts-ignore
  const [mProps] = useState(type === 'tel' ? { maxLength: 10 } : {});
  const [nProps] = useState(type === 'cpmnumber' ? { maxLength: length } : {});
  const [lProps] = useState(type === 'alphabets' ? { maxLength: length } : {});

  /* Suggestion Input Start */
  const [suggestioBox, setSuggestionBox] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (ddl: any) => {
    setSuggestionBox(!ddl);
  };

  useEffect(() => {
    if (option && option?.length > 0) {
      setSuggestionBox(true);
    } else {
      setSuggestionBox(false);
    }
  }, [option]);

  // Auto close Dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef?.current?.contains(event.target)) {
        setSuggestionBox(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  /* Suggestion Input End */

  return (
    <>
      {(kind === 'inputicon' || kind === 'percentage') && (
        <>
          <label className='label'>{label}</label>
          <div className='input-group' style={{ height: '38px' }}>
            <span className='input-group-text'>{kind === 'inputicon' ? '$' : '%'}</span>
            <input
              id={id}
              style={style}
              onBlur={blur}
              name={name}
              maxLength={length}
              type={inputType}
              {...lProps}
              readOnly={read}
              defaultValue={value}
              placeholder={placeholder}
              onChange={(e) => {
                sVal(e.target.value);
                change(name, e.target.value);
                if (typing) typing(e);
              }}
              onKeyDown={(e) => {
                if (val.length <= 0) {
                  const key = e.keyCode;
                  if (key === 32) {
                    e.preventDefault();
                  }
                }
                if (type === 'alphabets') {
                  if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 8 && e.keyCode <= 46)) {
                    // ok
                  } else {
                    e.preventDefault();
                  }
                }
                if (type === 'cpmnumber') {
                  if (
                    (e.shiftKey === false && e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    e.keyCode === 8 ||
                    e.keyCode === 46 ||
                    e.keyCode === 9 ||
                    e.keyCode === 190 ||
                    e.keyCode === 110 ||
                    (e.keyCode >= 37 && e.keyCode <= 40)
                  ) {
                    // ok
                  } else {
                    e.preventDefault();
                  }
                }
                if (id === 'numeric_input') {
                  if (
                    (e.shiftKey === false ??
                      (e.keyCode === 107 && 187 && 189 && 109 && 16) === false) &&
                    ((e.keyCode >= 48 && e.keyCode <= 57) ||
                      (e.keyCode >= 96 && e.keyCode <= 105) ||
                      e.keyCode === 8 ||
                      e.keyCode === 46 ||
                      e.keyCode === 17 ||
                      e.keyCode === 9 ||
                      (e.keyCode >= 37 && e.keyCode <= 40))
                  ) {
                    //ok
                  } else {
                    e.preventDefault();
                  }
                }
              }}
              className={`${error ? 'form-control text-input-error' : 'form-control'}`}
            />
            {/* <span className="input-group-text">.00</span> */}
          </div>
          {error && (
            <label style={{ marginTop: 5 }} className={'text-input-error-label'}>
              {error}
            </label>
          )}
        </>
      )}
      {kind === 'text' && (
        <>
          <label className='label'>{label}</label>
          <div style={{ position: 'relative' }}>
            <input
              id={id}
              style={style}
              onBlur={blur}
              name={name}
              maxLength={length}
              type={inputType}
              {...lProps}
              readOnly={read}
              defaultValue={value}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onChange={(e) => {
                sVal(e.target.value);
                change(name, e.target.value);
                if (typing) typing(e);
              }}
              onKeyDown={(e) => {
                if (val.length <= 0) {
                  const key = e.keyCode;
                  if (key === 32) {
                    e.preventDefault();
                  }
                }
                if (type === 'alphabets') {
                  if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 8 && e.keyCode <= 46)) {
                    // ok
                  } else {
                    e.preventDefault();
                  }
                }
                if (id === 'numeric_input') {
                  if (
                    (e.shiftKey === false ??
                      (e.keyCode === 107 && 187 && 189 && 109 && 16) === false) &&
                    ((e.keyCode >= 48 && e.keyCode <= 57) ||
                      (e.keyCode >= 96 && e.keyCode <= 105) ||
                      e.keyCode === 8 ||
                      e.keyCode === 46 ||
                      e.keyCode === 17 ||
                      e.keyCode === 9 ||
                      (e.keyCode >= 37 && e.keyCode <= 40))
                  ) {
                    //ok
                  } else {
                    e.preventDefault();
                  }
                }
              }}
              className={`${error ? 'text-input text-input-error' : 'text-input'}`}
            />
            {type === 'password' && (
              <>
                {!showPassword ? (
                  <BsEye
                    className='svg-icon'
                    fontSize={20}
                    onClick={() => {
                      sInputType('text');
                      sShowPassword(true);
                    }}
                    style={{
                      position: 'absolute',
                      marginTop: 8,
                      marginLeft: -32,
                    }}
                  />
                ) : (
                  <BsEyeSlash
                    className='svg-icon'
                    fontSize={20}
                    onClick={() => {
                      sInputType('password');
                      sShowPassword(false);
                    }}
                    style={{
                      position: 'absolute',
                      marginTop: 8,
                      marginLeft: -32,
                    }}
                  />
                  // <img
                  //   onClick={() => {
                  //     sInputType('password');
                  //     sShowPassword(false);
                  //   }}
                  //   src={eyeO}
                  //   width={20}
                  //   style={{
                  //     position: 'absolute',
                  //     marginTop: 8,
                  //     marginLeft: -32,
                  //     cursor: 'pointer',
                  //   }}
                  // />
                )}
              </>
            )}
          </div>
          {error && (
            <label style={{ marginTop: 5 }} className={'text-input-error-label'}>
              {error}
            </label>
          )}
        </>
      )}
      {kind === 'mini' && (
        <>
          <label className='label'>{label}</label>
          <input
            maxLength={length}
            style={{ fontSize: 14 }}
            onBlur={blur}
            name={name}
            type={type}
            {...mProps}
            {...nProps}
            {...lProps}
            readOnly={read}
            defaultValue={value}
            disabled={disabled ?? false}
            onKeyDown={(e) => {
              if (type === 'tel') {
                if (
                  (e.keyCode >= 48 && e.keyCode <= 57) ||
                  (e.keyCode >= 96 && e.keyCode <= 105) ||
                  e.keyCode === 8 ||
                  e.keyCode === 46
                ) {
                  // ok
                } else {
                  e.preventDefault();
                }
              }
              if (type === 'cpmnumber') {
                if (
                  (e.shiftKey === false && e.keyCode >= 48 && e.keyCode <= 57) ||
                  (e.keyCode >= 96 && e.keyCode <= 105) ||
                  e.keyCode === 8 ||
                  e.keyCode === 46 ||
                  e.keyCode === 9 ||
                  e.keyCode === 190 ||
                  e.keyCode === 110 ||
                  (e.keyCode >= 37 && e.keyCode <= 40)
                ) {
                  // ok
                } else {
                  e.preventDefault();
                }
              }
              if (type === 'alphabets') {
                if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 8 && e.keyCode <= 46)) {
                  // ok
                } else {
                  e.preventDefault();
                }
              }
              if (val.length <= 0) {
                const key = e.keyCode;
                if (key === 32) {
                  e.preventDefault();
                }
              }
              if (id === 'numeric_input_mini') {
                if (
                  (e.shiftKey === false ??
                    (e.keyCode === 107 && 187 && 189 && 109 && 16) === false) &&
                  ((e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    e.keyCode === 8 ||
                    e.keyCode === 46 ||
                    e.keyCode === 17 ||
                    e.keyCode === 9 ||
                    (e.keyCode >= 37 && e.keyCode <= 40))
                ) {
                  //ok
                } else {
                  e.preventDefault();
                }
              }
            }}
            placeholder={placeholder}
            onChange={(e) => {
              sVal(e.target.value);
              change(name, e.target.value);
            }}
            className={`${error ? 'text-input-mini text-input-mini-error' : 'text-input-mini'}`}
          />
          {error && (
            <label style={{ marginTop: 5 }} className={'text-input-error-label'}>
              {error}
            </label>
          )}
        </>
      )}
      {kind === 'suggestion' && (
        <>
          <label className='label'>{label}</label>
          <div className='rmsc multi-select position-relative'>
            <input
              autoComplete='off'
              maxLength={length}
              name={name}
              className='text-input'
              type={'text'}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                change(name, e.target.value);
              }}
            />
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '10px',
                }}
              >
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              </div>
            )}
            {option.length > 0 && (
              <div ref={dropdownRef} className={suggestioBox ? 'dropdown-content' : 'd-none'}>
                <div className='panel-content'>
                  <div className='select-panel' role='list-box'>
                    <ul className='options'>
                      {option.map((result: any, key: any) => (
                        <li
                          key={key}
                          onClick={() => {
                            handleSelect(suggestioBox);
                            change(name, result);
                          }}
                        >
                          <label className='select-item' role='option'>
                            <div className='item-renderer'>
                              <span>{result}</span>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
