import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import FormB from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Radio from '../../widgets/radio';
import Switch from '../../widgets/switch';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import DeviceTypeCheckBox from '../../helpers/DeviceTypeCheckBox';
import { addEndpointAPI, updateEndpointAPI } from '../../../services/endpoint';
// import Button from '../widgets/button';
import store from '../../../states';
import Button from '../../widgets/button';
import FormikController from '../../comman/form/FormikController';
import { windowReload } from '../../helpers/functions';
// import MsaSign from '../modals/msaSign';

interface Props {
  enpointListDataConfigObj?: any;
  onHide?: any;
  endpointId?: any;
  enpointListData?: any;
  getEndPointListCard?: any;
  //customerDetailsinfo?: any;
  endpontDataStatus?: any;
  editDetailsDSPModal?: any;
  openDetailsModal?: any;
  getEndpointListInfoAPI?: any;
  customerID?: number;
  initialCreate?: boolean;
}

const Addrtbendpoint = (props: Props) => {
  const {
    onHide,
    enpointListDataConfigObj,
    endpointId,
    getEndPointListCard,
    endpontDataStatus,
    openDetailsModal,
    enpointListData,
    // getEndpointListInfoAPI,
    customerID,
    initialCreate,
  } = props;

  const RtbFormInitialValues = {
    integrationName: enpointListData?.name ?? '',
    integrationEndPoint: enpointListData?.endpoint ?? '',
    integrationMinCPM: enpointListDataConfigObj?.min_cpm ?? 0,
    integrationMaxCPM: enpointListDataConfigObj?.max_cpm ?? 0,
    integrationCPM: enpointListData?.floor_price ?? 0,
  };

  const [RTG, sRTG] = useState(enpointListDataConfigObj?.is_openrtb_26.toString() ?? 'false');
  const [softFloor, sSoftFloor] = useState(
    enpointListDataConfigObj?.is_soft_floor?.toString() ?? 'true'
  );
  const [deviceTypeArr, setDeviceTypeArr] = useState([]);
  const [allTraffic, sAllTraffic] = useState(enpointListDataConfigObj?.is_all_traffic ?? true);
  const [editDetailsDSPModal, sEditDetailsDSPModal] = useState<any>(false);
  const [checkboxError, setCheckboxError] = useState(deviceTypeArr?.length > 0 ? false : true);
  const [endpointStatus, sEndpointStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const selectRTG = (id: string) => {
    sRTG(id);
  };

  const selectSoftFloor = (id: string) => {
    sSoftFloor(id);
  };

  /*  Add End point data start */
  const initiateRTG = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });
    const minCPMFloat = parseFloat(e.integrationMinCPM);
    const maxCPMFloat = parseFloat(e.integrationMaxCPM);
    const CPMFloat = parseFloat(e.integrationCPM);

    const payload = {
      customer_id: customerID,
      endpoint: e.integrationEndPoint,
      endpoint_type: 'end_point',
      name: e.integrationName,
      config: {
        device_types: deviceTypeArr,
        //ep: e.integrationEndPoint,
        //name: e.integrationName,
        min_cpm: softFloor === 'true' ? minCPMFloat : CPMFloat,
        max_cpm: softFloor === 'true' ? maxCPMFloat : 0,
        is_openrtb_26: JSON.parse(RTG),
        is_soft_floor: softFloor === 'true' ? true : false,
        is_all_traffic: allTraffic === true ? true : false,
      },
    };
    setIsLoading(true);
    addEndpointAPI(payload)
      .then((data: any) => {
        if (endpontDataStatus === false && data?.status) {
          windowReload();
        }
        if (data?.status === true) {
          getEndPointListCard && getEndPointListCard(data, 'add');
          localStorage.setItem('connect_your_demand', 'true');
          toast.success(data?.message);
          sEndpointStatus(true);
        } else {
          toast.error(data?.message);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        toast.error(error?.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
  };
  /*  Add End point data end */

  /*  Update End point data start */
  const updateEndpoint = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });

    const minCPMFloat = parseFloat(e.integrationMinCPM);
    const maxCPMFloat = parseFloat(e.integrationMaxCPM);
    const CPMFloat = parseFloat(e.integrationCPM);

    const payload = {
      id: endpointId,
      customer_id: customerID,
      endpoint: e.integrationEndPoint,
      endpoint_type: 'end_point',
      name: e.integrationName,
      config: {
        device_types: deviceTypeArr,
        //ep: e.integrationEndPoint,
        //name: e.integrationName,
        min_cpm: softFloor === 'true' ? minCPMFloat : CPMFloat,
        max_cpm: softFloor === 'true' ? maxCPMFloat : 0,
        is_openrtb_26: JSON.parse(RTG),
        is_soft_floor: softFloor === 'true' ? true : false,
        is_all_traffic: allTraffic == true ? true : false,
      },
    };
    setIsLoading(true);
    updateEndpointAPI(payload)
      .then((data: any) => {
        if (data?.status === true) {
          toast.success(data?.message);
          getEndPointListCard(data, 'update');
          // getEndpointListInfoAPI && getEndpointListInfoAPI();
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error: any) => {
        //toast.error(glossary.service_unavailable);
      });
  };
  /*  Update End point data start */

  const valReg = /^\d+(\.\d{1,2})?$/;
  // const valReg = /^[0-9]+([.][0-9]+)?$/;

  /* validation schema */
  const SignupSchema = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_endpoint_input_name_empty_error),
    integrationMinCPM: Yup.number()
      .required(glossary.dsp_add_endpoint_input_min_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_min_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.dsp_add_endpoint_input_min_floor_price_valid_error)
      .max(99999, glossary.dsp_add_endpoint_input_min_floor_max_length_error),
    // .test('superior', 'Min must be smaller than max', function (f1: any) {
    //     const ref1: any = Yup.ref('integrationMaxCPM');
    //     return f1 >= this.resolve(ref1);
    // }),
    integrationMaxCPM: Yup.number()
      .required(glossary.dsp_add_endpoint_input_max_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_max_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.dsp_add_endpoint_input_max_floor_price_valid_error)
      .test('superior', glossary.dsp_add_endpoint_input_compare_error, function (f2: any) {
        const ref2: any = Yup.ref('integrationMinCPM');
        return f2 >= this.resolve(ref2);
      })
      .max(99999, glossary.dsp_add_endpoint_input_max_floor_price_max_length_error),
    integrationEndPoint: Yup.string()
      .url(glossary.dsp_add_endpoint_input_url_valid_error)
      .required(glossary.dsp_add_endpoint_input_url_empty_error),
  });

  const SignupSchema1 = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_endpoint_input_name_empty_error),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_endpoint_input_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.dsp_add_endpoint_input_floor_price_valid_error)
      .max(99999, glossary.dsp_add_endpoint_input_floor_price_max_length_error),
    // .test('superior', 'Min must be smaller than max', function (f1: any) {
    //     const ref1: any = Yup.ref('integrationMaxCPM');
    //     return f1 >= this.resolve(ref1);
    // }),
    integrationEndPoint: Yup.string()
      .url(glossary.dsp_add_endpoint_input_url_valid_error)
      .required(glossary.dsp_add_endpoint_input_url_empty_error),
  });
  /* validation schema */

  /* validation checkbox */
  const checkBoxRequired = () => {
    if (deviceTypeArr?.length > 0) {
      setCheckboxError(true);
    } else {
      setCheckboxError(false);
    }
  };

  return (
    <>
      <Formik
        validationSchema={softFloor === 'true' ? SignupSchema : SignupSchema1}
        initialValues={RtbFormInitialValues}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(e) => {
          if (enpointListDataConfigObj) {
            if (checkboxError) {
              updateEndpoint(e);
            }
          } else {
            if (checkboxError) {
              initiateRTG(e);
            }
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className={editDetailsDSPModal ? 'rtb-form edit' : 'rtb-form disable'}>
            <div
              className={getEndPointListCard && 'modal-body p-3'}
              style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto',
              }}
            >
              <div className='row mb-3 mt-1'>
                <div className='col-md-5'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.dsp_add_endpoint_input_name_label}
                      placeholder={glossary.dsp_add_endpoint_input_name_placeholder}
                      name='integrationName'
                    />
                  </div>
                </div>
                <div className='col-auto'>
                  <FormB.Group className='d-flex form-group mt-3 pt-1 flex-column'>
                    <Radio
                      label={glossary.dsp_add_endpoint_radio_rtb_one}
                      size={'mini'}
                      id={'false'}
                      selected={RTG}
                      click={selectRTG}
                    />
                    <Radio
                      label={glossary.dsp_add_endpoint_radio_rtb_two}
                      size={'mini'}
                      id={'true'}
                      selected={RTG}
                      click={selectRTG}
                    />
                  </FormB.Group>
                </div>
                <div className='col-auto'>
                  <div className='align-items-center d-flex switch' style={{ paddingTop: '30px' }}>
                    <Switch
                      api={(chec: any) => {
                        chec == true ? sAllTraffic(true) : sAllTraffic(false);
                      }}
                      status={allTraffic}
                    />
                    <label className='label mb-0 ms-2 pt-1 text-body'>
                      {glossary.dsp_add_endpoint_toggle_all_traffic}
                    </label>
                  </div>
                </div>
                {allTraffic === false && (
                  <>
                    <div className='col-auto'>
                      <FormB.Group className='d-flex form-group mt-3 pt-1 flex-column'>
                        <Radio
                          label={glossary.dsp_add_endpoint_radio_button_floor_range}
                          size={'mini'}
                          id={'true'}
                          selected={softFloor}
                          click={selectSoftFloor}
                        />
                        <Radio
                          label={glossary.dsp_add_endpoint_radio_button_fix_floor}
                          size={'mini'}
                          id={'false'}
                          selected={softFloor}
                          click={selectSoftFloor}
                        />
                      </FormB.Group>
                    </div>
                    <div className='col'>
                      <div className='d-flex gap-3'>
                        {softFloor === 'true' ? (
                          <>
                            <div className='form-group'>
                              <FormikController
                                kind='withiconstart'
                                control='input'
                                type='number'
                                label={glossary.dsp_add_endpoint_input_min_floor_price_label}
                                placeholder={
                                  glossary.dsp_add_endpoint_input_min_floor_price_placeholder
                                }
                                name='integrationMinCPM'
                                onChangeText={enpointListDataConfigObj?.min_cpm ?? ''}
                              />
                            </div>
                            <div className='form-group'>
                              <FormikController
                                kind='withiconstart'
                                control='input'
                                type='number'
                                label={glossary.dsp_add_endpoint_input_max_floor_price_label}
                                placeholder={
                                  glossary.dsp_add_endpoint_input_max_floor_price_placeholder
                                }
                                name='integrationMaxCPM'
                                onChangeText={enpointListDataConfigObj?.max_cpm ?? ''}
                              />
                            </div>
                          </>
                        ) : (
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='number'
                              label={glossary.dsp_add_endpoint_input_floor_price_label}
                              placeholder={glossary.dsp_add_endpoint_input_floor_price_placeholder}
                              name='integrationCPM'
                              onChangeText={enpointListDataConfigObj?.floor_price ?? ''}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='row mb-3'>
                <label style={{ fontSize: '14px', color: '#a2a2a2' }}>
                  {glossary.dsp_add_endpoint_field_label_device_type}
                </label>
                <DeviceTypeCheckBox
                  setArrFunc={setDeviceTypeArr}
                  enpointListDataConfigObj={enpointListDataConfigObj}
                />
                {checkboxError === false && deviceTypeArr?.length === 0 && (
                  <div className='text-input-error-label small'>
                    {glossary.dsp_add_endpoint_field_device_type_empty_error}
                  </div>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.dsp_add_endpoint_input_url_label}
                      placeholder={glossary.dsp_add_endpoint_input_url_placeholder}
                      name='integrationEndPoint'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={getEndPointListCard && 'modal-footer px-3'}>
              <div className='d-flex justify-content-end gap-3'>
                {(endpointStatus || endpointStatus === undefined) && (
                  <Button
                    type='button'
                    kind={'secondary'}
                    text={glossary.dsp_add_endpoint_button_close}
                    click={() => {
                      onHide();
                    }}
                  />
                )}

                {enpointListDataConfigObj ? (
                  <>
                    {openDetailsModal && (
                      <>
                        <Button
                          type='button'
                          kind={'secondary'}
                          text={glossary.dsp_add_endpoint_button_close}
                          click={() => {
                            onHide();
                          }}
                        />

                        {!editDetailsDSPModal && (
                          <Button
                            kind={'primary'}
                            type='button'
                            text={glossary.dsp_details_rtb_popup_button_edit}
                            click={() => sEditDetailsDSPModal(true)}
                          />
                        )}
                      </>
                    )}
                    {(!openDetailsModal || editDetailsDSPModal) && (
                      <>
                        <Button
                          type='button'
                          kind={'secondary'}
                          text={glossary.dsp_add_endpoint_button_close}
                          click={() => {
                            onHide();
                          }}
                          customClass={editDetailsDSPModal ? 'd-none' : ''}
                        />

                        {isLoading ? (
                          <Button type='button' kind={'spinner'} text={glossary.updating} />
                        ) : (
                          <Button
                            kind={'primary'}
                            type='submit'
                            text={glossary.dsp_upadte_rtb_popup_button_update}
                            click={() => checkBoxRequired()}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {endpontDataStatus && (
                      <Button
                        type='button'
                        kind={'secondary'}
                        text={glossary.dsp_add_endpoint_button_close}
                        click={() => {
                          onHide();
                        }}
                      />
                    )}

                    {isLoading ? (
                      <Button type='button' kind={'spinner'} text={glossary.loading} />
                    ) : (
                      <>
                        {!initialCreate && (
                          <Button
                            type='button'
                            kind={'secondary'}
                            text={glossary.dsp_add_endpoint_button_close}
                            click={() => {
                              onHide();
                            }}
                          />
                        )}

                        <Button
                          kind={'primary'}
                          type='submit'
                          text={glossary.dsp_add_endpoint_button_test_integration}
                          click={() => checkBoxRequired()}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Addrtbendpoint;
