import React from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { BsCreditCard } from 'react-icons/bs';
import { openNewTab } from '../../helpers/functions';

const StripeCard = ({ paymentMethodAddUrl }: any) => {
  return (
    <>
      <div className={'d-flex'}>
        <div
          style={{
            width: '100%',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.04)',
            borderRadius: '5px',
            padding: '10px',
            display: 'flex',
          }}
        >
          <div className='col-8 d-flex align-items-center gap-3'>
            <BsCreditCard fontSize={24} className='s-svg-icon' />

            <b style={{ fontSize: '16px', color: '#731780', paddingTop: '3px' }}>
              **** **** **** ****
            </b>

            <span
              style={{
                fontWeight: '100',
                color: 'darkgray',
                backgroundColor: '#f4f4f4',
                padding: '0px 7px',
                borderRadius: '4px',
              }}
            >
              Default
            </span>
          </div>

          <div className='col-4 d-flex align-items-center justify-content-end gap-2'>
            {/* <p className='text-button' style={{ fontSize: '14px' }}>
              Make Default
            </p> */}
            <BiEditAlt
              className='svg-icon'
              fontSize={19}
              style={{ marginRight: '10px', cursor: 'pointer' }}
              onClick={() => openNewTab(paymentMethodAddUrl)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StripeCard;
