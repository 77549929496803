import { useEffect, useState } from 'react';
import InfyTable from '../../tables/InfyTable';
import { headers } from '../../../services/constants';

const InfyTableComponent = ({
  api,
  method,
  payload,
  tableName,
  columnWidth,
  downloadCSV,
  search,
  selectedCustomerID,
  insertNewData,
  insertIndex,
  searchPlaceholder,
}: any) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [paltFormCostData, setPaltFormCostData] = useState([]);
  const [paltFormCostcolumns, setPaltFormCostColumns] = useState([]);
  const [scrollXevent, setScrollXevent] = useState<number | null>(null);
  const [sortby, setSortby] = useState({});

  const getMoreData = async (pageIndex: number, keyword: string, sort: any) => {
    setLoading(true);

    // update existing query parameter
    const queryParams: any = new URLSearchParams();
    queryParams.append('page', pageIndex);
    queryParams.append('page_size', '50');
    if (keyword.length > 0) queryParams.set('query', keyword);
    if (sort.id)
      queryParams.append(
        'sort_by',
        JSON.stringify({
          sortBy: [sort],
        })
      );
    // create a new URL with the modified query parameters
    const newUrlGet = api + '?' + queryParams.toString();
    const newUrlPost = api;

    const newPayload = {
      page: pageIndex,
      page_size: 50,
      query: keyword,
      sort_by: sort
        ? {
            sortBy: [sort],
          }
        : {},
      ...payload,
    };

    const optionsGet = {
      headers,
      method: method,
    };

    const optionsPost = {
      headers,
      method: method,
      body: JSON.stringify(newPayload),
    };

    const response = await fetch(
      method === 'GET' ? newUrlGet : newUrlPost,
      method === 'GET' ? optionsGet : optionsPost
    );

    try {
      const newData = await response.json();
      // handle parsed data
      if (newData?.data?.length === 0) {
        setHasMore(false);
      }

      // While user do any action on table so hasMore is true
      if (pageIndex === 0 && newData?.data?.length !== 0) {
        setHasMore(true);
      }

      if (pageIndex > 0) {
        const mergeData: any = [...data, ...newData.data];
        setData(mergeData);
        setPaltFormCostData(mergeData);
      } else {
        setData(newData?.data);
        setColumns(newData?.columns);

        setPaltFormCostData(newData?.data?.platform_cost?.data);
        setPaltFormCostColumns(newData?.data?.platform_cost?.columns);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      // handle parsing error
    }
  };

  useEffect(() => {
    if (api) {
      setSortby({});
      setKeyword('');
      getMoreData(0, '', {});
    }
  }, [api, method]);

  /* Insert Data in Initial data with Dynamically Index */
  useEffect(() => {
    if (insertNewData !== undefined && insertIndex !== undefined) {
      const newArr: any = [
        ...data.slice(0, insertIndex),
        insertNewData,
        ...data.slice(insertIndex),
      ];
      setData(newArr);
    }
  }, [insertNewData]);

  // On scroll next page
  function onNextPage(pageNumber: number) {
    // Perform the API call for vertical scroll
    getMoreData(pageNumber, keyword, sortby);

    // Check if there's a horizontal scroll in progress
    if (scrollXevent !== null) {
      return; // Skip the API call for horizontal scroll
    }
    // Check if there's no more data to fetch
    if (!hasMore) {
      return; // Skip the API call if no more data
    }
  }

  // Get Search Input Value
  function onSearchChage(val: string) {
    setKeyword(val);
    //if (val.length) getMoreData(0, val, {});
    if (val.length > 0) {
      getMoreData(0, val, sortby);
    } else {
      getMoreData(0, '', {});
      setHasMore(true);
    }
  }

  // Sorting Data
  function handleSort(sortBy: any) {
    getMoreData(0, keyword, sortBy);
    setLoading(false);
    setSortby(sortBy);
  }

  // Call Back function for any action event
  const apiCallBack = () => {
    getMoreData(0, keyword, sortby);
  };

  // handle table scroll event for api call
  const handleScrollXevent = (e: any) => {
    //const { scrollLeft } = e?.target;
    setScrollXevent(e);
  };

  const tableData = tableName === 'Platform Cost Rate' ? paltFormCostData : data;
  const tableColumns = tableName === 'Platform Cost Rate' ? paltFormCostcolumns : columns;

  return (
    <InfyTable
      data={tableData}
      columns={tableColumns}
      onNextPage={onNextPage}
      onSearchChage={onSearchChage}
      onSort={handleSort}
      hasMore={hasMore}
      loading={loading}
      columnWidth={tableName === 'Platform Cost Rate' ? 200 : columnWidth}
      apiCallBack={apiCallBack}
      tableName={tableName}
      downloadCSV={tableName === 'Platform Cost Rate' ? false : downloadCSV}
      search={tableName === 'Platform Cost Rate' ? false : search}
      selectedCardId={selectedCustomerID}
      searchPlaceholder={searchPlaceholder}
      scrollXeventProp={handleScrollXevent}
    />
  );
};

export default InfyTableComponent;
