import { type ComponentProps } from 'react';

interface IconProps extends ComponentProps<'svg'> {
  color?: 'default' | 'genuin';
}

export const GenuinIcon = {
  icon: Icon,
  text: Text,
  logo: Logo,
};

const colors = {
  default: '',
  genuin: '#0645ff',
};

function Icon({ color, ...props }: IconProps) {
  return (
    <svg {...props} viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M32.0002 0C14.3291 0 0.0713381 14.1867 0.000226976 31.8578C-0.0531064 46.1867 9.298 58.3467 22.2402 62.4889C28.3202 64.4267 34.9869 61.6889 37.7069 55.9111C38.5602 54.0978 39.0402 52.0711 39.0402 49.92V49.3156C39.0402 48.7644 38.4891 48.3733 37.9736 48.5689C36.0002 49.28 33.8669 49.6533 31.6447 49.6C22.0447 49.4044 14.3113 41.4222 14.4002 31.8222C14.4891 22.1867 22.3469 14.4 32.0002 14.4C41.7247 14.4 49.6002 22.2756 49.6002 32V49.92C49.6002 53.5289 48.818 56.96 47.4313 60.0356C57.3158 54.5956 64.0002 44.0711 64.0002 32C64.0002 14.3289 49.6713 0 32.0002 0Z'
        fill={colors[color ?? 'default']}
      />
    </svg>
  );
}

function Text({ color, ...props }: IconProps) {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='120' height='42' viewBox='0 0 120 42'>
      <path d='M33.4965 8.74713C27.7136 8.74713 23.0187 13.638 23.2423 19.5402C23.4509 24.9896 27.8477 29.3672 33.2133 29.5182C36.1494 29.5937 38.8173 28.4162 40.74 26.469C41.202 26.001 41.0679 25.2311 40.5015 24.9292L37.2822 23.2688C36.9692 23.1027 36.5966 23.1329 36.3134 23.3291C35.5085 23.8877 34.5398 24.2198 33.4816 24.2198C31.6781 24.2198 30.1132 23.2688 29.2189 21.8196C29.0103 21.5026 29.2487 21.08 29.6213 21.08H42.7222C43.2141 21.08 43.6165 20.7026 43.6761 20.2044C43.7059 19.8572 43.7357 19.51 43.7357 19.1478C43.7506 13.4418 39.1452 8.76223 33.4965 8.76223V8.74713ZM33.4965 14.0758C35.2999 14.0758 36.8648 15.0268 37.7591 16.4759C37.9678 16.7929 37.7293 17.2156 37.3567 17.2156H29.6511C29.2785 17.2156 29.0401 16.7929 29.2487 16.4759C30.1281 15.0268 31.693 14.0758 33.4965 14.0758Z' />
      <path d='M10.5244 8.74713C4.66469 8.74713 -0.0738685 13.5305 0.000872162 19.4051C0.0756128 24.9359 4.47036 29.51 9.98622 29.779C11.1821 29.8388 12.3181 29.6894 13.4093 29.3904C13.798 29.2708 14.1418 29.6744 13.9774 30.0481C12.7666 32.7089 10.2104 34.6372 7.17597 34.9511C6.68268 35.0109 6.30898 35.4145 6.30898 35.8928V40.3174C6.30898 40.8854 6.78732 41.304 7.3404 41.2741C14.9789 40.736 21.0478 34.3531 21.0478 26.5802V19.2706C21.0329 13.4558 16.3242 8.74713 10.5094 8.74713H10.5244ZM10.5244 23.8148C8.01307 23.8148 5.98012 21.7818 5.98012 19.2706C5.98012 16.7593 8.01307 14.7264 10.5244 14.7264C13.0356 14.7264 15.0686 16.7593 15.0686 19.2706C15.0686 21.7818 13.0207 23.8148 10.5244 23.8148Z' />
      <path d='M51.9149 28.9448C52.4566 28.9448 52.878 28.5237 52.878 27.9823V17.8609C52.878 16.0862 53.8261 14.5372 55.5266 14.5372C57.2271 14.5372 57.6786 16.4772 57.6786 17.8308V27.9823C57.6786 28.5237 58.0999 28.9448 58.6417 28.9448H63.5476C64.0893 28.9448 64.5107 28.5237 64.5107 27.9823V16.4923C64.5107 9.00274 58.4611 8.74707 57.5732 8.74707C56.6853 8.74707 54.413 8.92754 52.8479 10.5819V10.3112C52.8479 9.76974 52.4265 9.34864 51.8848 9.34864H47.1595C46.6177 9.34864 46.1964 9.76974 46.1964 10.3112V28.0124C46.1964 28.5538 46.6177 28.9749 47.1595 28.9749H51.8848L51.9149 28.9448Z' />
      <path d='M94.0315 7.65376C96.1393 7.65376 97.8584 5.93465 97.8584 3.82688C97.8584 1.71911 96.1543 0 94.0315 0C91.9088 0 90.2047 1.71911 90.2047 3.82688C90.2047 5.93465 91.9238 7.65376 94.0315 7.65376Z' />
      <path d='M91.7283 28.9749H96.6095C97.1587 28.9749 97.5858 28.5523 97.5858 28.009V10.2598C97.5858 9.71642 97.1587 9.29382 96.6095 9.29382H91.7283C91.1792 9.29382 90.7521 9.71642 90.7521 10.2598V28.0241C90.7521 28.5523 91.1792 28.9749 91.7283 28.9749Z' />
      <path d='M80.7439 9.29367C80.2102 9.29367 79.7951 9.71508 79.7951 10.2569V20.3858C79.7951 22.1618 78.8612 23.712 77.1861 23.712C75.511 23.712 75.0662 21.7705 75.0662 20.4159V10.2569C75.0662 9.71508 74.6512 9.29367 74.1175 9.29367H69.2848C68.7511 9.29367 68.3361 9.71508 68.3361 10.2569V21.7705C68.3361 29.2656 74.2954 29.5215 75.17 29.5215C76.0446 29.5215 78.2831 29.3409 79.8248 27.6853V27.9562C79.8248 28.498 80.2399 28.9194 80.7735 28.9194H85.4283C85.962 28.9194 86.3771 28.498 86.3771 27.9562V10.2569C86.3771 9.71508 85.962 9.29367 85.4283 9.29367H80.7735H80.7439Z' />
      <path d='M107.404 28.9448C107.946 28.9448 108.367 28.5237 108.367 27.9823V17.8609C108.367 16.0863 109.315 14.5372 111.016 14.5372C112.716 14.5372 113.168 16.4773 113.168 17.8308V27.9823C113.168 28.5237 113.604 28.9448 114.131 28.9448H119.037C119.579 28.9448 120 28.5237 120 27.9823V16.4923C120 9.0028 113.935 8.74713 113.062 8.74713C112.19 8.74713 109.902 8.9276 108.337 10.5819V10.3112C108.337 9.7698 107.916 9.3487 107.374 9.3487H102.649C102.107 9.3487 101.686 9.7698 101.686 10.3112V28.0124C101.686 28.5538 102.107 28.9749 102.649 28.9749H107.374L107.404 28.9448Z' />
    </svg>
  );
}

function Logo({ color, ...props }: IconProps) {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='158' height='40' viewBox='0 0 205 52'>
      <path
        d='M23.9999 0C10.7467 0 0.0535029 10.64 0.00017023 23.8933C-0.0398293 34.6399 6.97341 43.7599 16.68 46.8666C21.2399 48.3199 26.2398 46.2666 28.2798 41.9333C28.9198 40.5733 29.2798 39.0533 29.2798 37.4399V36.9866C29.2798 36.5733 28.8665 36.2799 28.4798 36.4266C26.9998 36.9599 25.3998 37.2399 23.7332 37.1999C16.5333 37.0533 10.7334 31.0666 10.8 23.8666C10.8667 16.64 16.76 10.8 23.9999 10.8C31.2931 10.8 37.1997 16.7066 37.1997 24V37.4399C37.1997 40.1466 36.613 42.7199 35.573 45.0266C42.9863 40.9466 47.9996 33.0533 47.9996 24C47.9996 10.7466 37.253 0 23.9999 0Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M96.3188 11.0928C89.0389 11.0928 83.1456 17.1594 83.4256 24.5061C83.6923 31.2661 89.2122 36.7194 95.9721 36.8927C99.6654 36.9861 103.025 35.5194 105.439 33.1061C106.012 32.5327 105.852 31.5594 105.132 31.1861L101.079 29.1194C100.692 28.9194 100.225 28.9461 99.8654 29.1861C98.8521 29.8794 97.6254 30.2927 96.3054 30.2927C94.0388 30.2927 92.0655 29.1061 90.9455 27.3061C90.6922 26.9061 90.9855 26.3861 91.4522 26.3861H107.932C108.545 26.3861 109.065 25.9194 109.132 25.3061C109.172 24.8794 109.199 24.4394 109.199 23.9861C109.225 16.8928 103.425 11.0928 96.3188 11.0928ZM96.3188 17.7061C98.5854 17.7061 100.559 18.8928 101.679 20.6928C101.932 21.0928 101.639 21.6128 101.172 21.6128H91.4788C91.0122 21.6128 90.7188 21.0928 90.9722 20.6928C92.0788 18.8928 94.0521 17.7061 96.3188 17.7061Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M67.2185 10.8018C59.8719 10.8018 53.9253 16.8017 54.0187 24.1751C54.112 31.1084 59.6319 36.8417 66.5518 37.1884C68.0452 37.2684 69.4851 37.0817 70.8451 36.695C71.3384 36.5484 71.7651 37.055 71.5651 37.5217C70.0451 40.8684 66.8318 43.2817 63.0319 43.6684C62.4186 43.735 61.9519 44.2417 61.9519 44.855V50.4017C61.9519 51.1083 62.5519 51.6417 63.2452 51.6017C72.8318 50.935 80.4317 42.9217 80.4317 33.1617V24.0017C80.405 16.7084 74.4984 10.8018 67.2185 10.8018ZM67.2185 29.7084C64.0719 29.7084 61.5119 27.1617 61.5119 24.0017C61.5119 20.8551 64.0585 18.2951 67.2185 18.2951C70.3651 18.2951 72.9251 20.8417 72.9251 24.0017C72.9118 27.1484 70.3651 29.7084 67.2185 29.7084Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M119.294 36.294C119.961 36.294 120.494 35.7607 120.494 35.094V22.4807C120.494 20.2674 121.668 18.3341 123.801 18.3341C125.934 18.3341 126.481 20.7607 126.481 22.4407V35.094C126.481 35.7607 127.014 36.294 127.681 36.294H133.801C134.468 36.294 135.001 35.7607 135.001 35.094V20.7607C135.001 11.4141 127.454 11.1074 126.361 11.1074C125.268 11.1074 122.428 11.3341 120.481 13.3874V13.0408C120.481 12.3741 119.948 11.8408 119.281 11.8408H113.401C112.734 11.8408 112.201 12.3741 112.201 13.0408V35.1074C112.201 35.774 112.734 36.3074 113.401 36.3074H119.294V36.294Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M172.196 9.59998C174.847 9.59998 176.996 7.45095 176.996 4.79999C176.996 2.14903 174.847 0 172.196 0C169.545 0 167.396 2.14903 167.396 4.79999C167.396 7.45095 169.545 9.59998 172.196 9.59998Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M169.202 36.1593H175.202C175.869 36.1593 176.402 35.626 176.402 34.9594V12.9061C176.402 12.2394 175.869 11.7061 175.202 11.7061H169.202C168.535 11.7061 168.002 12.2394 168.002 12.9061V34.9727C168.002 35.626 168.535 36.1593 169.202 36.1593Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M155.521 11.707C154.854 11.707 154.321 12.2404 154.321 12.907V25.5203C154.321 27.7337 153.148 29.667 151.014 29.667C148.881 29.667 148.334 27.2403 148.334 25.5603V12.907C148.334 12.2404 147.801 11.707 147.134 11.707H141.014C140.348 11.707 139.814 12.2404 139.814 12.907V27.2537C139.814 36.6003 147.361 36.907 148.454 36.907C149.548 36.907 152.388 36.6803 154.334 34.627V34.9737C154.334 35.6403 154.868 36.1737 155.534 36.1737H161.414C162.081 36.1737 162.614 35.6403 162.614 34.9737V12.907C162.614 12.2404 162.081 11.707 161.414 11.707H155.521V11.707Z'
        fill={colors[color ?? 'default']}
      />
      <path
        d='M188.892 36.2931C189.559 36.2931 190.092 35.7597 190.092 35.0931V22.4798C190.092 20.2664 191.265 18.3331 193.399 18.3331C195.532 18.3331 196.079 20.7598 196.079 22.4398V35.0931C196.079 35.7597 196.612 36.2931 197.279 36.2931H203.399C204.065 36.2931 204.599 35.7597 204.599 35.0931V20.7598C204.599 11.4131 197.052 11.1064 195.959 11.1064C194.865 11.1064 192.025 11.3331 190.079 13.3864V13.0398C190.079 12.3731 189.545 11.8398 188.879 11.8398H182.999C182.332 11.8398 181.799 12.3731 181.799 13.0398V35.1064C181.799 35.7731 182.332 36.3064 182.999 36.3064H188.892V36.2931Z'
        fill={colors[color ?? 'default']}
      />
    </svg>
  );
}
