import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaTelegramPlane } from 'react-icons/fa';
import { FormCheck, Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikController from '../comman/form/FormikController';
import { inviteCustomerAPI } from '../../services/customers';
import Button from '../widgets/button';
import Radio from '../widgets/radio';
import store from '../../states';

interface Props {
  show: boolean;
  updateList: any;
  onHide: any;
}

export default (props: Props) => {
  const { show, onHide, updateList } = props;

  const [customerType, setCustomerType] = useState('publisher');
  const [firstLogin, setFirstLogin] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [msaBypass, setMsaBypass] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [section, setSection] = useState('1');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

  const inviteValidationSchema = Yup.object().shape({
    companyName: Yup.string()
      .required(glossary.admin_invite_popup_input_company_name_empty_error)
      .trim()
      .notOneOf([''], glossary.admin_invite_popup_input_company_name_empty_error),
    firstName: Yup.string()
      .required(glossary.admin_invite_popup_input_first_name_empty_error)
      .trim()
      .notOneOf([''], glossary.admin_invite_popup_input_first_name_empty_error),
    email: Yup.string()
      .required(glossary.admin_invite_popup_input_email_empty_error)
      .email(glossary.admin_invite_popup_input_email_valid_error),
    // .test('superior', glossary.admin_invite_popup_input_email_valid_error, function (f2: any) {
    //   return emailRegex.test(f2);
    // }),
  });

  const handlePassword = (e: any) => {
    const { value } = e.target;
    if (value !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setFirstLogin(false);
    }
  };

  const initialValue = () => {
    setCustomerType('publisher');
    setIsDisabled(true);
    setFirstLogin(false);
    setMsaBypass(false);
    setTimeout(() => {
      setSection('1');
    }, 500);
  };

  const handleSubmit = (e: any) => {
    const payload = {
      customers: [
        {
          name: e.companyName,
          first_name: e.firstName,
          last_name: e.lastName,
          email: e.email,
          password: e.password,
          first_login: firstLogin,
          customer_type: customerType,
          bypass_msa: msaBypass,
        },
      ],
    };
    setIsSubmitting(true);
    inviteCustomerAPI(payload)
      .then((data: any) => {
        if (data?.failed?.length < 1) {
          updateList(data.success[0], 'add');
          setSection('2');
        } else {
          toast.error(data.failed[0]?.msg);
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 300);
      })
      .catch((error: any) => {
        toast.error(glossary.server_error);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          initialValue();
          onHide();
        }}
        centered
        size={'lg'}
      >
        {section === '1' && (
          <>
            <Modal.Header>
              <h2 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                {glossary.admin_invite_popup_title}
              </h2>
            </Modal.Header>
            <Formik
              initialValues={{
                companyName: '',
                firstName: '',
                lastName: '',
                email: '',
                password: '',
              }}
              validationSchema={inviteValidationSchema}
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {({ values }) => (
                <Form>
                  <Modal.Body>
                    <div className='row'>
                      <div className='col-2 pt-3'>
                        <Radio
                          label={glossary.admin_invite_popup_radio_button_publisher}
                          size={'mini'}
                          id={'publisher'}
                          selected={customerType}
                          click={setCustomerType}
                        />
                        <Radio
                          label={glossary.admin_invite_popup_radio_button_dsp}
                          size={'mini'}
                          id={'dsp'}
                          selected={customerType}
                          click={setCustomerType}
                        />
                        <Radio
                          label={'SPO'}
                          size={'mini'}
                          id={'spo'}
                          selected={customerType}
                          click={setCustomerType}
                        />
                      </div>
                      <div className='col-10'>
                        <div className='row'>
                          <div className='col-4 mb-3'>
                            <FormikController
                              type='text'
                              control='input'
                              kind='withouticon'
                              name={'companyName'}
                              maxLength={256}
                              label={glossary.admin_invite_popup_input_field_label_company_name}
                              placeholder={
                                glossary.admin_invite_popup_input_company_name_placeholder
                              }
                            />
                          </div>
                          <div className='col-4'>
                            <FormikController
                              type='text'
                              control='input'
                              kind='withouticon'
                              name={'firstName'}
                              maxLength={64}
                              label={glossary.admin_invite_popup_input_field_label_first_name}
                              placeholder={glossary.admin_invite_popup_input_first_name_placeholder}
                            />
                          </div>
                          <div className='col-4'>
                            <FormikController
                              type='text'
                              control='input'
                              kind='withouticon'
                              name={'lastName'}
                              maxLength={20}
                              label={glossary.admin_invite_popup_input_field_label_last_name}
                              placeholder={glossary.admin_invite_popup_input_last_name_placeholder}
                            />
                          </div>
                          <div className='col-4'>
                            <FormikController
                              type='text'
                              control='input'
                              kind='withouticon'
                              name={'email'}
                              maxLength={320}
                              label={glossary.admin_invite_popup_input_field_label_email}
                              placeholder={glossary.admin_invite_popup_input_email_placeholder}
                            />
                          </div>
                          <div className='col-4'>
                            <FormikController
                              control='input'
                              kind='password'
                              name={'password'}
                              maxLength={320}
                              label={glossary.admin_invite_popup_input_field_label_password}
                              placeholder={glossary.admin_invite_popup_input_password_placeholder}
                              onKeyUp={handlePassword}
                            />
                          </div>
                          <div className='col-4'>
                            <label className='label'>
                              {glossary.admin_invite_popup_field_label_password_change}
                            </label>
                            <FormCheck
                              checked={firstLogin}
                              disabled={isDisabled}
                              type='checkbox'
                              label={glossary.admin_invite_popup_checkbox_label_on_first_login}
                              className='mt-2'
                              onChange={() => setFirstLogin(!firstLogin)}
                            />
                          </div>
                          <div className='col-4 mt-4'>
                            <FormCheck
                              checked={msaBypass}
                              type='checkbox'
                              label={glossary.admin_invite_popup_checkbox_label_msa}
                              className='m-0'
                              onChange={() => setMsaBypass(!msaBypass)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className='gap-2'>
                    <Button
                      type='button'
                      text={'Cancel'}
                      kind={'secondary'}
                      click={() => {
                        onHide();
                        initialValue();
                      }}
                    />
                    {isSubmitting ? (
                      <Button text={'Inviting...'} kind={'spinner'} type={'button'} />
                    ) : (
                      <Button type='submit' text={'Invite'} kind={'primary'} />
                    )}
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </>
        )}

        {section === '2' && (
          <div style={{ display: 'flex', margin: 40 }}>
            <div
              className={'row'}
              style={{
                margin: 'auto',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: 300,
              }}
            >
              <FaTelegramPlane fontSize={130} color={'#731780'} />
              <br />
              <br />
              <h2>{glossary.admin_invite_success_popup_title}</h2>
              <br />
              <br />
              <p>{glossary.admin_invite_success_popup_instructions}</p>
              <br />
              <br />
              <div style={{ marginTop: 20 }}>
                <Button
                  kind={'primary'}
                  text={glossary.admin_invite_success_popup_button_done}
                  click={() => {
                    onHide();
                    initialValue();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
