import { useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import Dropdown from 'react-bootstrap/Dropdown';

import Tile from '../../widgets/tile';
import Button from '../../widgets/button';
import Sidebar from '../../widgets/sidebar';

import { inventory as inventoryTiles } from '../../helpers/tiles';

import Deal from '../../modals/deal';

import { toast } from 'react-toastify';
import Inventories from '../../tables/inventories';
import {
  getInventoriesAPI,
  getInventoriesCSVAPI,
  getInventoryCardsAPI,
  getInventoryOverviewAPI,
} from '../../../services/inventory';
import store from '../../../states';
import InvetoryCard from '../../loaders/invetory-card';
import Box from '../../loaders/box';
import { useLocation } from 'react-router-dom';
import Switch from '../../widgets/switch';
import ButtonSkeleton from '../../loaders/button';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';
import TableSkeleton from '../../loaders/table';

const ChartSection = ({ title, data }: any) => {
  return (
    <div
      style={{
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 14,
        borderRadius: 4,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.05)',
        height: '300px',
        overflowY: 'auto',
      }}
    >
      <h4>{title}</h4>
      {data &&
        data.length > 0 &&
        data.map((el: any, index: any) => {
          return (
            <div key={index}>
              <ProgressBar
                completed={parseFloat(el.monthly_imp_percent)}
                bgColor={'#731780'}
                height={'3px'}
                borderRadius={'0px'}
                baseBgColor={'#fff'}
                isLabelVisible={false}
                animateOnRender={true}
              />
              <p style={{ margin: 2, fontSize: 12 }}>
                <b>{el.name}</b> {el.monthly_imp_percent}%
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default () => {
  const { getInventoriesAPI, getInventoryOverviewAPI }: any = useAuthentication();
  const { inventories, sInventories, inventoryTilesData, sInventoryTilesData }: any =
    consumerData();

  //const [inventoryTilesData, sInventoryTilesData] = useState<any>([]);
  //const [inventories, sInventories] = useState<any>([]);
  const [show, sShow] = useState(false);
  const [dealTerms, sDealTerms] = useState<any>({
    config: {
      'platform.type': [],
      'content.cat': [],
      'device.type': [],
      'user.geo.country': [],
      'user.geo.region': [],
      'user.geo.city': [],
    },
  });
  const [cards, sCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cardloading, setCardLoading] = useState(true);
  const [glossary, sGlossary] = useState<any>({});
  const [dataNotFound, setDataNotFound] = useState(false);
  // const [submitDataNotFound, setsubmitDataNotFound] = useState(false);
  const [onswitch, sOffSwitch] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { pathname } = useLocation();

  const [selectedRow, setSelectedRow] = useState([]);
  // const selectedRowData = (selectedRow: any) => {
  //   setSelectedRow(selectedRow);
  // };

  const updateDealTerms = (terms: any) => {
    sDealTerms(terms);
    getInventories(terms);
  };

  useEffect(() => {
    if (inventories.length === 0) {
      getInventories();
    } else {
      setLoading(false);
    }
  }, []);

  const getInventories = (newObj?: any) => {
    let payload: any;
    if (newObj) {
      payload = {
        iab_category: newObj?.config['content.cat'],
        device_type: newObj?.['device.type'],
        platform_category: newObj.p_cat,
        // platform_type: newObj?.config['platform.type'],
        country: newObj?.config['user.geo.country'],
        state: newObj?.config['user.geo.region'],
        city: newObj?.config['user.geo.city'],
        ssp_id: newObj.ssp_id,
        lower_cpm: newObj.lower_cpm,
        upper_cpm: newObj.upper_cpm,
      };
    } else {
      payload = {
        iab_category: [],
        device_type: [],
        platform_category: [],
        // platform_type: [],
        country: [],
        state: [],
        city: [],
        ssp_id: [],
        lower_cpm: 0,
        upper_cpm: 0,
      };
    }
    setLoading(true);
    getInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setLoading(false);
          // setsubmitDataNotFound(false);
          inventoryOverview(newObj && newObj);
          if (data.data.length > 0) {
            sInventories(data);
            setDataNotFound(false);
          } else {
            setDataNotFound(true);
          }
        }
      })
      .catch(() => {
        // console.log("data not found")
      });
  };

  const inventoryOverview = (newObj?: any) => {
    let payload: any;
    if (newObj) {
      payload = {
        iab_category: newObj?.config['content.cat'],
        device_type: newObj?.config['device.type'],
        platform_category: newObj.p_cat,
        // platform_type: newObj?.config['platform.type'],
        country: newObj?.config['user.geo.country'],
        state: newObj?.config['user.geo.region'],
        city: newObj?.config['user.geo.city'],
        ssp_id: newObj.ssp_id,
        lower_cpm: newObj.lower_cpm,
        upper_cpm: newObj.upper_cpm,
      };
    } else {
      payload = {
        iab_category: [],
        device_type: [],
        platform_category: [],
        // platform_type: [],
        country: [],
        state: [],
        city: [],
        ssp_id: [],
        lower_cpm: 0,
        upper_cpm: 0,
      };
    }
    getInventoryOverviewAPI(payload).then((data: any) => {
      const tileData = inventoryTiles(data?.data ?? []);
      sInventoryTilesData(tileData);
      inventoryCards(newObj && newObj);
    });
  };

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const inventoryCards = (newObj?: any) => {
    setCardLoading(true);
    let payload: any;
    if (newObj) {
      payload = {
        iab_category: newObj?.config['content.cat'],
        device_type: newObj?.config['device.type'],
        platform_category: newObj.p_cat,
        platform_type: newObj?.config['platform.type'],
        country: newObj?.config['user.geo.country'],
        state: newObj?.config['user.geo.region'],
        city: newObj?.config['user.geo.city'],
        ssp_id: newObj.ssp_id,
        lower_cpm: newObj.lower_cpm,
        upper_cpm: newObj.upper_cpm,
      };
    } else {
      payload = {
        iab_category: [],
        device_type: [],
        platform_category: [],
        platform_type: [],
        country: [],
        state: [],
        city: [],
        ssp_id: [],
        lower_cpm: 0,
        upper_cpm: 0,
      };
    }
    getInventoryCardsAPI(payload).then((data: any) => {
      sCards(data.data);
      setCardLoading(false);
    });
  };

  const downloadCSV = () => {
    const payload = {
      platform_category: dealTerms.p_cat,
      platform_type: dealTerms?.config['platform.type'],
      lower_cpm: dealTerms.lower_cpm ?? 0,
      upper_cpm: dealTerms.upper_cpm ?? 0,
      iab_category: dealTerms?.config['content.cat'],
      device_type: dealTerms?.config['device.type'],
      country: dealTerms?.config['user.geo.country'],
      state: dealTerms?.config['user.geo.region'],
      city: dealTerms?.config['user.geo.city'],
      export_type: 'xlsx',
      ssp_id: dealTerms.ssp_id,
      export_data_format: 'all',
    };
    setIsDownloading(true);
    getInventoriesCSVAPI(payload).then(async (data: any) => {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Publisher-with-inventories.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setIsDownloading(false);
      }, 300);
    });
  };
  const downloadCSV1 = () => {
    const payload = {
      platform_category: dealTerms.p_cat,
      lower_cpm: dealTerms.lower_cpm ?? 0,
      platform_type: dealTerms?.config['platform.type'],
      upper_cpm: dealTerms.upper_cpm ?? 0,
      iab_category: dealTerms?.config['content.cat'],
      device_type: dealTerms?.config['device.type'],
      country: dealTerms?.config['user.geo.country'],
      state: dealTerms?.config['user.geo.region'],
      city: dealTerms?.config['user.geo.city'],
      export_type: 'xlsx',
      ssp_id: dealTerms.ssp_id,
      export_data_format: 'inventories',
    };
    setIsDownloading(true);
    getInventoriesCSVAPI(payload).then(async (data: any) => {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Inventories.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setIsDownloading(false);
      }, 300);
    });
  };
  const downloadCSV2 = () => {
    const payload = {
      platform_category: dealTerms.p_cat,
      platform_type: dealTerms?.config['platform.type'],
      lower_cpm: dealTerms.lower_cpm ?? 0,
      upper_cpm: dealTerms.upper_cpm ?? 0,
      iab_category: dealTerms?.config['content.cat'],
      device_type: dealTerms?.config['device.type'],
      country: dealTerms?.config['user.geo.country'],
      state: dealTerms?.config['user.geo.region'],
      city: dealTerms?.config['user.geo.city'],
      export_type: 'xlsx',
      ssp_id: dealTerms.ssp_id,
      export_data_format: 'publishers',
    };

    setIsDownloading(true);
    getInventoriesCSVAPI(payload).then(async (data: any) => {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Publishers.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    setTimeout(() => {
      setIsDownloading(false);
    }, 300);
  };

  // const setsubmitnotFound = () => {
  //   setsubmitDataNotFound(true);
  // };

  return (
    <>
      <Deal
        dealTerms={dealTerms}
        toast={toast}
        show={show}
        onHide={() => sShow(false)}
        selectedInventories={selectedRow}
        dealTermsConfig={{
          ...dealTerms?.config,
          'content.series': '',
          'content.isrc': '',
          'content.producer.name': '',
          'content.producer.domain': '',
          'content.contentrating': '',
          'content.userrating': '',
          'content.keywords': '',
          'content.len': '',
          'content.language': '',
          'content.network.name': '',
          'content.channel.name': '',
          'content.network.domain': '',
          'content.channel.domain': '',
        }}
      />

      <div className='content d-flex'>
        <Sidebar
          title={glossary.admin_inv_sidebar_title}
          kind={'inventory'}
          dealTerms={updateDealTerms}
          // setsubmitnotFound={setsubmitnotFound}
          loading={loading}
        />

        <div className='view-section'>
          {loading ? (
            <div className='row'>
              <div className='d-flex align-items-center'>
                <div className='col-6 d-flex gap-4'>
                  {[1, 2, 3, 4].map((index) => {
                    return <Box key={index} />;
                  })}
                </div>
                <div className='col-6 d-flex align-items-center justify-content-end gap-4'>
                  <ButtonSkeleton height={25} width={60} />
                  <ButtonSkeleton height={38} width={146} />
                  <ButtonSkeleton height={38} width={113} />
                </div>
              </div>
              <div style={{ marginTop: 50, overflow: 'scroll' }}>
                <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
              </div>
            </div>
          ) : (
            <>
              <div className='row'>
                {/* Overview Tile */}
                <div className={'col-6'}>
                  <div className={dataNotFound ? 'd-none' : 'row'}>
                    {inventoryTilesData.length > 0 &&
                      inventoryTilesData.map((tile: any, index: any) => {
                        return (
                          <div key={index} className={'col-auto'}>
                            <Tile
                              onHover={'disabled'}
                              id={tile.id}
                              selected={99}
                              click={() => null}
                              label={tile.label}
                              value={tile.value}
                              info={tile.info}
                              arrow={tile.arrow}
                              deals
                              type={'number'}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                {/* Create, Download, Chart Buttons */}
                <div className='col-6 d-flex justify-content-end'>
                  <div className='d-flex align-items-center gap-4'>
                    <div className='d-flex align-items-center'>
                      <p className='pt-1 me-2'>{glossary.admin_inv_toggle_button_label_chart}</p>
                      <Switch api={(chec: boolean) => sOffSwitch(chec)} status={onswitch} />
                    </div>
                    <Button
                      kind={selectedRow.length > 0 ? 'primary' : 'disabled'}
                      text={glossary.admin_inv_button_create_pmp_deal}
                      click={() => sShow(true)}
                      css={{
                        border:
                          selectedRow.length > 0 ? '1px solid transparent' : '1px solid #e7e7e7',
                      }}
                    />

                    <Dropdown className='w-auto ps-1'>
                      <Dropdown.Toggle
                        className={
                          dataNotFound ? 'button button-disabled' : 'button button-primary'
                        }
                        disabled={isDownloading}
                      >
                        {glossary.admin_inv_ddl_button_label_download}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={downloadCSV}>
                          {glossary.admin_inv_ddl_option_publisher_with_inventories}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={downloadCSV1}>
                          {glossary.admin_inv_ddl_option_only_inventories}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={downloadCSV2}>
                          {glossary.admin_inv_ddl_option_only_publishers}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              {/* Chart */}
              <div className='row'>
                {onswitch && (
                  <div className={'row'} style={{ marginTop: 15, marginBottom: 20 }}>
                    {cardloading ? (
                      <>
                        {[1, 2, 3, 4].map((x: any, index: any) => {
                          return (
                            <div key={index} className={'col'} style={{ height: '100%' }}>
                              <InvetoryCard />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {cards &&
                          cards.length > 0 &&
                          cards.map((card: any, index: any) => {
                            return (
                              <div key={index} className={'col'} style={{ height: '100%' }}>
                                <ChartSection title={card.title} data={card.data} />
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                )}
              </div>

              {/* Table View */}
              <div className={dataNotFound ? 'row' : 'row inventory-table'}>
                {dataNotFound ? (
                  <div className='text-center mt-5'>
                    <h1 className='mt-5'>{glossary.admin_inv_nodata_error_msg}</h1>
                  </div>
                ) : (
                  <Inventories
                    rows={inventories.data ?? []}
                    columns={inventories.columns ?? []}
                    selectedRowData={(selectedRow: any) => setSelectedRow(selectedRow)}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
