import { useState, useEffect } from 'react';
import up from '../../assets/icons/up.svg';
import down from '../../assets/icons/down.svg';
import Tooltip from '../comman/tooltip/Tooltip';

interface Props {
  id: number;
  label: string;
  value: string;
  info?: any;
  arrow: string;
  selected: number;
  deals?: boolean;
  click(id: number): void;
  type?: string;
  onHover?: string;
  dummy?: boolean;
}

export default (props: Props) => {
  const { id, label, value, arrow, selected, click, deals, info, type, onHover, dummy } = props;

  const [className, sClassName] = useState(`tile ${onHover}`);

  useEffect(() => {
    if (id === selected) {
      sClassName(`tile tile-selected ${onHover}`);
    } else {
      sClassName(`tile ${onHover}`);
    }
  }, [selected]);

  const nFormatter = (value: any) => {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return value;
  };

  return (
    <>
      <div
        className={className}
        style={{ cursor: deals ? 'default' : 'pointer' }}
        onClick={() => {
          if (!deals) {
            click(id);
          }
        }}
      >
        <p>
          {label}
          {info && <Tooltip text={info} kind='info' iconSize={18} />}
        </p>
        <div className={'tile-row'}>
          <div className={'tile-col'}>
            {dummy ? (
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  width: '40px',
                  height: '20px',
                  borderRadius: '4px',
                }}
              />
            ) : (
              <b>
                <p>{type === 'number' ? nFormatter(value) : value}</p>
              </b>
            )}
          </div>
          {!deals && (
            <div className={'tile-col'}>
              <img src={arrow === 'up' ? up : down} width={14} height={14} alt={'arrow'} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
