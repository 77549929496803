import { Line } from 'react-chartjs-2';

export default (props: any) => {
  const { data } = props;

  const root = document.documentElement;
  const primary = getComputedStyle(root).getPropertyValue('--primary-2');

  const colors = {
    bgColor: `${primary}33`,
    borderColor: primary,
  };

  const values = {
    labels: data ? data.labels : [],
    datasets: [
      {
        label: [],
        data: data ? data.values : [],
        fill: {
          target: 'origin',
          above: colors.bgColor,
        },
        borderColor: colors.borderColor,
        tension: 0,
      },
    ],
  };
  const settings = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    animations: false,
    maintainAspectRatio: false,
  };
  // @ts-ignore
  return <Line data={values} options={settings} />;
};
