export default {
  /* Using in whole app */
  currency_symbol: '$',

  //Card status
  card_status_pending: 'Pending',
  card_status_active: 'Active',
  card_status_paused: 'Paused',
  card_status_rejected: 'Rejected',
  card_status_on_hold: 'On Hold',

  // Common Button
  overview_button: 'Overview',
  loading: 'Loading...',
  updating: 'Updating...',
  creating: 'Creating...',
  inviting: 'Inviting...',
  back_btn: 'Back',
  close_btn: 'Close',
  next_btn: 'Next',
  create_btn: 'Create',
  update_btn: 'Update',
  done_btn: 'Done',
  edit_btn: 'Edit',
  add_btn: 'Add',

  // STRIPE Component
  stripe_title: 'Stripe',
  stripe_payment_method_title: 'Add Payment Method',
  stripe_tab_label_card: 'Card',
  stripe_tab_label_bank: 'Bank Account',
  stripe_button_submit: 'Submit',

  srtipe_card_instruction:
    'Experience hassle-free payouts by adding your card details on Stripe. (Ex. Platform Cost, Media Cost, etc...)',
  srtipe_card_note: 'Please note that your card is secure with Stripe.',

  stripe_button_view_payment_method: 'View Payment Method',
  stripe_payment_method_instruction:
    'Update payment method which will use during platform cost transactions.',
  stripe_default_payment_method_error: 'Please set default payment method',

  stripe_button_update_business_details: 'Update Business Details',
  stripe_button_view_dashboard: 'View Dashboard',
  stripe_account_connect_instruction:
    'In order to receive payments, you need to connect your Stripe account.',
  stripe_business_details_instruction:
    'Update business information which will use to auto complete media cost transactions.',
  stripe_page_reload_error: 'Page reload required.',
  stripe_fill_business_details_error: 'Please fill your all business details.',
  stripe_fill_payout_details_method_error:
    'Please fill your payout details method and also make sure you have enabled payment method.',

  //Common Dropdown Label & Placeholder
  ddl_country: 'Country',
  ddl_country_placeholder: 'Select Country',
  ddl_countries: 'Countries',
  ddl_countries_placeholder: 'Select Countries',
  ddl_state: 'State',
  ddl_state_placeholder: 'Select State',
  ddl_states: 'States',
  ddl_states_placeholder: 'Select States',
  ddl_city: 'City',
  ddl_city_placeholder: 'Select City',
  ddl_cities: 'Cities',
  ddl_cities_placeholder: 'Select Cities',

  //Common Errors In App
  error_data_not_found: 'Data not found',
  server_error: 'Something went wrong, please try later.',
  service_unavailable: 'Service unavailable, Check back after sometime.',

  //Account > Contextmenu > Dropdown
  account_ddl_option_settings: 'Settings',
  account_ddl_option_logout: 'Logout',
  admin_account_ddl_option_inventory: 'Inventory',
  admin_account_ddl_option_traffic: 'Traffic',

  //Account > Settings
  setting_sidebar_title: 'Settings',

  setting_sidebar_option_profile: 'Profile Settings',
  setting_sidebar_option_company_settings: 'Company Settings',
  setting_sidebar_option_users: 'Users',
  setting_sidebar_option_finance: 'Finance',
  setting_sidebar_option_email_notifications: 'Email Notifications',
  setting_sidebar_option_adstxt: 'Ads.txt',
  setting_sidebar_option_customers: 'Customers',
  setting_sidebar_option_customize: 'Customize',
  setting_sidebar_option_payout_request: 'Payout Request',
  setting_sidebar_option_macros: 'Macros',
  setting_sidebar_option_payout: 'Payout',

  //Account > Settings > Profile Settings
  setting_profile_title: 'Profile Settings',
  setting_profile_input_field_label_first_name: 'First Name',
  setting_profile_input_field_first_name_placeholder: 'Enter Your First Name',
  setting_profile_input_field_label_last_name: 'Last Name',
  setting_profile_input_field_last_name_placeholder: 'Enter Your Last Name',

  setting_profile_image_size_error: 'File size exceeds 1 Mb.',
  setting_profile_image_valid_error: 'Please select valid file.',

  setting_profile_field_label_email: 'Email',
  setting_profile_field_email_text: 'Your email address is',
  setting_profile_field_label_password: 'Password',
  setting_profile_password_button_change: 'Change',

  // Account > Settings > Company
  setting_company_title: 'Company',

  setting_company_input_field_label_company_name: 'Company Name',
  setting_company_input_field_company_name_placeholder: '',
  setting_company_ddl_label_industry_type: 'Industry Type',
  setting_company_ddl_label_company_size: 'Company Size',
  setting_company_input_field_label_company_website: 'Company Website',
  setting_company_input_company_website_url_valid_error: 'Invalid URL',
  setting_company_mas_field_label: 'MSA Sign:',
  setting_company_mas_button_download: 'Download MSA',
  setting_company_mas_button_request: 'Request',

  //Account > Settings > Customers
  setting_customers_title: 'Customers',
  setting_customers_table_search_placeholder: 'Search Customer Here',
  setting_customers_context_menu_option_put_on_active: 'Put on Active',
  setting_customers_context_menu_option_put_on_hold: 'Put on Hold',

  //Account > Settings > Users
  setting_users_title: 'Users',
  setting_users_button_invite: 'Invite',
  setting_users_search_placeholder: 'Search User Here',

  setting_users_context_menu_option_remove: 'Remove',
  setting_users_context_menu_option_access_control: 'Access Control',
  setting_users_context_menu_option_approve: 'Approve',

  //Account > Settings > Users > Invite Popup
  setting_user_invite_popup_title: 'Invite',
  setting_user_invite_popup_input_field_label_name: 'Name *',
  setting_user_invite_popup_input_name_placeholder: 'Enter User Name',
  setting_user_invite_popup_input_name_empty_error: 'Enter user name',

  setting_user_invite_popup_input_field_label_email: 'Email *',
  setting_user_invite_popup_input_email_placeholder: 'example@abc.com',
  setting_user_invite_popup_input_email_empty_error: 'Enter an email',
  setting_user_invite_popup_input_email_valid_error: 'Enter valid email',

  setting_user_invite_popup_ddl_field_label_role: 'Role',

  setting_user_invite_popup_input_field_label_password: 'Password',
  setting_user_invite_popup_input_password_placeholder: '*****',

  setting_user_invite_popup_field_label_password_change: 'Password Change',
  setting_user_invite_popup_password_checkbox_label: 'On First Login',

  setting_user_invite_popup_button_cancel: 'Cancel',
  setting_user_invite_popup_button_next: 'Invite',
  setting_user_invite_popup_user_exists_error: 'User already exists.',

  setting_user_invite_success_popup_title: 'User Created',
  setting_user_invite_success_popup_instruction: 'User has been created.',
  setting_user_invite_success_popup_button_done: 'Done',

  //Account > Settings > User Access Control Popup
  setting_user_access_popup_field_label_user_role: 'User Role',

  setting_user_access_popup_field_label_company_setting: 'Company Setting',
  setting_user_access_popup_field_label_finance: 'Finance',
  setting_user_access_popup_field_label_customers: 'Customers',
  setting_user_access_popup_field_label_customer_access: 'Customer Access',

  setting_user_access_popup_checkbox_none: 'None',
  setting_user_access_popup_checkbox_view: 'View',
  setting_user_access_popup_checkbox_edit: 'Edit',
  setting_user_access_popup_checkbox_assigned: 'Assigned',
  setting_user_access_popup_checkbox_all: 'All',

  setting_user_access_popup_button_cancel: 'Cancel',
  setting_user_access_popup_button_done: 'Done',

  //Account > Settings > User Delete Popup
  setting_user_remove_popup_title: 'Delete a User',
  setting_user_remove_popup_instruction: 'Are you sure you want to delete this User?',
  setting_user_remove_popup_button_cancel: 'Cancel',
  setting_user_remove_popup_button_delete: 'Delete',

  //Account > Settings > Email Notifications
  setting_email_notification_label_supply: 'Supply Notifications',
  setting_email_notification_label_demand: 'Demand Notifications',
  setting_email_notification_label_finance: 'Finance Notifications',

  //Account > Settings > SSP Ads.txt
  setting_ssp_adstxt_title: 'Ads.txt',

  setting_ssp_adstxt_field_label_redirect_url: 'Ads.txt/App-ads.txt Redirect URL',
  setting_ssp_adstxt_field_redirect_url_copy_success_msg: 'Copied',

  setting_ssp_adstxt_field_publish_adstxt_label: 'Publish your ADS.TXT file',
  setting_ssp_adstxt_publish_adstxt_field_instructions:
    'Redirect your “/ads.txt” path to the above URL with the status codes 301, 302, or 307. The advertising system will follow the redirect and consume the data as authoritative for the source of the redirect',

  setting_ssp_adstxt_field_label_example: 'For example:',
  setting_ssp_adstxt_example_field_instructions: 'yourdomain.com/ads.txt redirecting to',

  setting_ssp_adstxt_field_publish_app_adstxt_label: 'Publish your APP-ADS.TXT file',
  setting_ssp_adstxt_publish_app_adstxt_field_instructions:
    'Declare this URL as the publisher URL in your app listing. Make sure the app developers’ website has been correctly set up in Google Play, the Apple Store, or any other app store listing the above URL',

  setting_ssp_adstxt_field_add_adstxt_label: 'Ads.txt/App-Ads.txt',
  setting_ssp_adstxt_field_add_adstxt_instructions:
    'You can Add and Update Ads.txt/App-Ads.txt record of your demand partner which will automatically populate in your Ads.txt redirect URL.',
  setting_ssp_adstxt_button_update: 'Update',
  setting_ssp_adstxt_button_clear_adstxt: 'Clear Ads.txt',

  setting_ssp_adstxt_add_valid_error: 'Enter valid ads.txt',
  setting_ssp_adstxt_add_update_success_msg: 'Ads.txt records updated successfully.',

  //Account > Settings > Macros
  setting_ssp_macros_title: 'Macros',
  setting_ssp_macros_button_add_custom_macros: 'Add Custom Macros',
  setting_ssp_macros_input_search_placeholder: 'Search Parameter',
  setting_ssp_macros_list_edit_icon_title: 'Edit',
  setting_ssp_macros_list_delete_icon_title: 'Delete',

  //Macros Add Popup
  setting_ssp_add_macros_popup_title: 'Add Custom Macros',

  setting_ssp_add_macros_popup_input_field_label_name: 'Name *',
  setting_ssp_add_macros_popup_input_name_placeholder: 'Enter Name',
  setting_ssp_add_macros_popup_input_name_empty_error: 'Enter name',

  setting_ssp_add_macros_popup_input_field_label_parameter: 'Parameter *',
  setting_ssp_add_macros_popup_input_parameter_placeholder: 'Enter Parameter',
  setting_ssp_add_macros_popup_input_parameter_empty_error: 'Enter parameter',

  setting_ssp_add_macros_popup_input_field_label_value: 'Value *',
  setting_ssp_add_macros_popup_input_value_placeholder: 'Enter Value',
  setting_ssp_add_macros_popup_input_value_empty_error: 'Enter value',

  setting_ssp_add_macros_popup_ddl_label_p_category: 'Parameter Category',

  setting_ssp_add_macros_popup_button_cancel: 'Cancel',
  setting_ssp_add_macros_popup_button_add_macros: 'Add Macros',
  setting_ssp_add_macros_popup_success_msg: 'Macros Added Successfully.',
  setting_ssp_add_macros_popup_add_error: 'Enter valid details.',

  //Macros Update Popup
  setting_ssp_update_macros_popup_title: 'Update Custom Macros',
  setting_ssp_update_macros_popup_success_msg: 'Macros Updated Successfully.',
  setting_ssp_update_macros_popup_update_error: 'Enter valid details.',
  setting_ssp_update_macros_popup_button_update_macros: 'Update Macros',

  //Macros Delete Popup
  setting_ssp_delete_macros_popup_title: 'Delete Macros',
  setting_ssp_delete_macros_popup_instructions: 'Are you sure you want to delete this Macros?',
  setting_ssp_delete_macros_popup_button_cancel: 'Cancel',
  setting_ssp_delete_macros_popup_button_delete: 'Delete',

  //Account > Settings > DSP Ads.txt
  setting_dsp_adstxt_title: 'Ads.txt',

  setting_dsp_adstxt_field_label_direct: 'Direct:',
  setting_dsp_adstxt_button_add_direct: 'Add',

  setting_dsp_adstxt_field_label_reseller: 'Reseller:',
  setting_dsp_adstxt_button_add_reseller: 'Add',

  setting_dsp_adstxt_copy_icon_title: 'Copy',
  setting_dsp_adstxt_edit_icon_title: 'Edit',
  setting_dsp_adstxt_delete_icon_title: 'Delete',
  setting_dsp_adstxt_copy_success_msg: 'Copied',

  //DSP Add Direct Adstxt Popup
  setting_dsp_adstxt_add_direct_popup_title: 'Add Direct',
  setting_dsp_adstxt_add_direct_popup_input_field_seller_label: 'Seller Account ID *',
  setting_dsp_adstxt_add_direct_popup_input_seller_placeholder: 'Enter Seller Account ID',
  setting_dsp_adstxt_add_direct_popup_input_seller_empty_error: 'Enter Seller Account ID',
  setting_dsp_adstxt_add_direct_popup_input_seller_min_error: 'Enter two or more digits.',

  setting_dsp_adstxt_add_direct_popup_input_field_domain_label: 'Exchange Domain *',
  setting_dsp_adstxt_add_direct_popup_input_domain_placeholder: 'Enter Exchange Domain',
  setting_dsp_adstxt_add_direct_popup_input_domain_empty_error: 'Enter Exchange Domain',
  setting_dsp_adstxt_add_direct_popup_input_domain_valid_error: 'Enter valid domain',

  setting_dsp_adstxt_add_direct_popup_input_field_tagid_label: 'Tag ID (Optional)',
  setting_dsp_adstxt_add_direct_popup_input_tagid_placeholder: 'Enter Tag ID',
  setting_dsp_adstxt_add_direct_popup_input_tagid_min_error: 'Enter two or more digits.',

  setting_dsp_adstxt_add_direct_popup_input_blank_space_error:
    'This field cannot contain only blankspaces',

  setting_dsp_adstxt_add_direct_popup_button_cancel: 'Cancel',
  setting_dsp_adstxt_add_direct_popup_button_add: 'Add',
  setting_dsp_adstxt_add_direct_success_msg: 'Record Added Successfully.',

  //DSP Update Direct Adstxt Popup
  setting_dsp_adstxt_update_direct_popup_title: 'Update Direct',
  setting_dsp_adstxt_update_direct_popup_button_update: 'Update',
  setting_dsp_adstxt_update_direct_success_msg: 'Record Updated Successfully.',

  //DSP Add Reseller Adstxt Popup
  setting_dsp_adstxt_add_reseller_popup_title: 'Add Reseller',
  setting_dsp_adstxt_add_reseller_popup_input_field_seller_label: 'Seller Account ID *',
  setting_dsp_adstxt_add_reseller_popup_input_seller_placeholder: 'Enter Seller Account ID',
  setting_dsp_adstxt_add_reseller_popup_input_seller_empty_error: 'Enter Seller Account ID',
  setting_dsp_adstxt_add_reseller_popup_input_seller_min_error: 'Enter two or more digits.',

  setting_dsp_adstxt_add_reseller_popup_input_field_domain_label: 'Exchange Domain *',
  setting_dsp_adstxt_add_reseller_popup_input_domain_placeholder: 'Enter Exchange Domain',
  setting_dsp_adstxt_add_reseller_popup_input_domain_empty_error: 'Enter Exchange Domain',
  setting_dsp_adstxt_add_reseller_popup_input_domain_valid_error: 'Enter valid domain',

  setting_dsp_adstxt_add_reseller_popup_input_field_tagid_label: 'Tag ID (Optional)',
  setting_dsp_adstxt_add_reseller_popup_input_tagid_placeholder: 'Enter Tag ID',
  setting_dsp_adstxt_add_reseller_popup_input_tagid_min_error: 'Enter two or more digits.',

  setting_dsp_adstxt_add_reseller_popup_input_blank_space_error:
    'This field cannot contain only blankspaces',

  setting_dsp_adstxt_add_reseller_popup_button_cancel: 'Cancel',
  setting_dsp_adstxt_add_reseller_popup_button_add: 'Add',
  setting_dsp_adstxt_add_reseller_success_msg: 'Record Added Successfully.',

  //DSP Update Direct Adstxt Popup
  setting_dsp_adstxt_update_reseller_popup_title: 'Update Reseller',
  setting_dsp_adstxt_update_reseller_popup_button_update: 'Update',
  setting_dsp_adstxt_update_reseller_success_msg: 'Record Updated Successfully.',

  ////////////////////////////////////////////////////

  /* Login Screen */
  login_header_text: "Don't have an account?",
  login_header_create_account_button: 'Create Account',
  login_title: 'Login to Infy',
  login_title_description:
    'Please enter the email you used to create your account, along with your password.',
  login_email_field_title: 'Email *',
  login_email_field_title_placeholder: 'Enter Your Email',
  login_button: 'Login',
  login_email_input_field_valid_error: 'Enter valid email',
  login_email_input_field_empty_error: 'Enter your email',
  login_button_google: 'Login with Google',

  /* Login Password Screen */
  password_title: 'Login to Infy',
  password_title_description:
    'Please enter the email you used to create your account, along with your password.',
  password_input_field_label: 'Password *',
  password_input_field_placeholder: 'Enter Your Password',
  password_button: 'Login',
  password_hyperlink: 'Forgot Password?',
  password_input_field_empty_error: 'Enter your password',

  /* Forgot Password Screen */
  forget_password_title: 'Forgot Password',
  forget_password_description:
    'Enter your email that you used to create your account to receive a password reset link.',
  forget_password_input_field_label: 'Email *',
  forget_password_input_field_placeholder: 'Enter Your Email',
  forget_password_button: 'Send Recovery Link',
  forgot_password_email_valid_error: 'Enter valid email',
  forgot_password_email_empty_error: 'Enter your email',

  /* Forgot Password Recovery Screen */
  forget_password_recovery_title: 'Recovery Email Sent',
  forget_password_recovery_description:
    'If the email address you provided matches your account email you should receive your recovery email shortly. Try checking your spam folder if you don’t find it.',
  forget_password_recovery_text: "Didn't get the Email?",
  forget_password_recovery_hyperlink: 'Send Again',
  forget_password_linksent_message: 'Link Sent',

  /* Create Password Screen */
  create_password_title: 'Create New Password',
  create_password_title_description:
    'Enter a new password that is different from any previous passwords.  It is best not to use the same password that you use for other accounts.',
  create_password_input_field_label_newpassword: 'New Password *',
  create_password_input_field_placeholder_newpassword: 'Enter New Password',
  create_password_input_field_label_confirmpassword: 'Confirm Password *',
  create_password_input_field_placeholder_confirmpassword: 'Re-Enter new password',
  create_password_error: "Passwords don't Match",
  create_password_validation_one: 'Minimum 6 characters',
  create_password_validation_two: 'Have 1 number',
  create_password_validation_three: 'Have 1 uppercase letter',
  create_password_validation_four: 'Have 1 special character',
  create_password_button: 'Create Password',
  create_password_newpassword_empty_error: 'Enter your new password',
  create_password_confirmpassword_empty_error: 'Re-Enter your new password',

  /* Password Set Screen */
  password_create_title: 'Password Create Successfully',
  password_create_description: 'Looks like you’re all set to Login in your account.',
  password_create_button: 'Login',

  /* Create Account Screen */
  signup_title: 'Join Us',
  signup_description: 'Create a Publisher account and begin to grow your CTV Advertising Revenue.',
  signup_email_field_title: 'Email *',
  signup_email_field_placeholder: 'Enter Your Email',
  signup_input_field_empty_error: 'Enter your email',
  signup_input_validation_error: 'Enter valid email',
  signup_button: 'Create Account',
  signup_button_loading: 'Creating...',
  signup_header_text: 'Already have an account?',
  signup_header_button: 'Login',
  signup_already_registered: 'is already registered with Infy.Tv, Please try login.',
  signup_button_google: 'Sign up with Google',

  /* Create Account Join The Team Screen */
  signup_join_title: 'Join The Team',
  signup_join_description:
    'Looks like your team already has an account with us. Click “Join” to send the request to your Admin.',
  signup_hyperlink: 'Join',
  signup_startnew_button: 'Start New',

  /* Create Account (Create A/C) */
  signup_details_title: 'Join Us',
  signup_details_description:
    'Please complete the information below so that we can Verify & Create your publisher account.',
  signup_details_field_one_title: 'Company Name *',
  signup_details_field_one_placeholder: 'Enter Your Company Name',
  signup_details_field_one_empty_error: 'Enter your company name',
  signup_details_field_two_title: 'Your Name *',
  signup_details_field_two_placeholder: 'Enter Your Name',
  signup_details_field_two_empty_error: 'Enter your name',
  signup_details_field_three_title: 'Email *',
  signup_details_field_three_placeholder: 'Enter your Email',

  signup_details_field_four_title: 'Publisher Name *',
  signup_details_field_four_placeholder: 'Enter Publisher Name',
  signup_details_field_four_empty_error: 'Enter publisher name',
  signup_details_field_five_title: 'Website URL *',
  signup_details_field_five_placeholder: 'Enter Your Website URL',
  signup_details_field_five_empty_error: 'Enter URL',
  signup_details_field_five_valid_error: 'Enter a valid URL',
  signup_details_field_six_title: 'Average Monthly Traffic *',
  signup_details_field_six_placeholder: 'Enter Your Traffic',
  signup_details_field_seven_title: 'Demand Name *',
  signup_details_field_seven_placeholder: 'Enter Demand Name',
  signup_details_field_seven_empty_error: 'Enter your demand name',

  signup_details_cancel_button: 'Cancel',
  signup_details_next_button: 'Next',
  signup_details_hyperlink_privacy_policy: 'Privacy Policy',
  signup_details_hyperlink_terms: 'Terms',

  /* Signup Done */
  signup_request_title: 'We are getting your Account Ready',
  signup_request_description:
    "We usually takes 1-2 business days, but it can sometimes take a bit longer. We'll let you know when everything is ready.",
  signup_request_hyperlink_privacy_policy: 'Privacy Policy',
  signup_request_hyperlink_terms: 'Terms and Conditions',

  //

  /* For ALL */
  step_tour_button_one: 'Prev',
  step_tour_button_two: 'Next',
  step_tour_button_three: "Let's Go",
  step_tour_button_four: 'Close',

  /* SSP DASHBOARD */

  //tour
  ssp_tour_heading_one: "Let's take a Tour",
  ssp_tour_description_one:
    "Welcome to infy.TV! Let's go through some of the platform features which might be exciting for you. Press next to begin the tour.",
  ssp_tour_heading_two: 'Create Tags',
  ssp_tour_description_two:
    'You can create Ad Tags on your own by choosing the placement, duration and floor-price. You can integrate the Ad Tag in your channel on your own.',
  ssp_tour_heading_three: 'Manage Finance',
  ssp_tour_description_three:
    'You can track performance and earning for each created Ad Tag as well as manage your finances. Requesting payout is quite easy!',
  ssp_tour_heading_four: 'Optimise Performance',
  ssp_tour_description_four:
    'Get detailed reporting based on Location, Advertisers and Creative as well as optimize your preferences to maximize your earning.',
  ssp_tour_button_next: 'Next',
  ssp_tour_button_done: 'Done',

  //step tour
  ssp_step_tour_title_one: 'Settings',
  ssp_step_tour_description_one:
    'Click on settings to manage your profile, company and financial settings.',
  ssp_step_tour_title_two: 'Notifications',
  ssp_step_tour_description_two: 'Check youmr notification regularly for updates on your platform',
  ssp_step_tour_title_three: 'Status Bar',
  ssp_step_tour_description_three:
    'Here you will find quick access to an overview of key stats about Impressions & Earning',

  // Onboarding
  onboarding_title: 'Welcome to infy! Let’s complete your onboarding',
  onboarding_step_create_account: 'Create Account',
  ssp_onboarding_step_two_text: 'On-board Your Demand',
  onboarding_step_msa: 'MSA Execute',
  ssp_onboarding_step_msa: 'Connect Your Demand',
  ssp_onboarding_step_four_text: 'Add Payout Method',
  onboarding_hide_button: 'Hide for Now',

  //create tag
  ssp_createtag_title: 'Create VAST Tag',
  ssp_createtag_input_tagname_label: 'Tag Name *',
  ssp_createtag_input_tagname_placeholder: 'Enter Tag Name',
  ssp_createtag_input_tagname_empty_error: 'Enter tag name',
  ssp_createtag_input_tagname_valid_error: 'Enter valid tag name',
  ssp_createtag_input_floorprice_label: 'Floor Price *',
  ssp_createtag_input_floorprice_placeholder: 'Eg. 15.30',
  ssp_createtag_input_floorprice_empty_error: 'Enter floor price',
  ssp_createtag_input_floorprice_valid_error: 'Enter Number',
  ssp_createtag_input_floorprice_zero_error: 'Enter more than 0.',

  ssp_createtag_softfloor_toggle_label: 'Soft Floor',
  ssp_createtag_softfloor_toggle_enabled_label: 'Enabled',
  ssp_createtag_softfloor_toggle_disabled_label: 'Disabled',

  ssp_createtag_duration_rangebar_label: 'Min-max Ad Duration (in Sec)',
  ssp_createtag_pod_radio_buttons_title: 'Pod Construction',
  ssp_createtag_pod_radio_button_pod_duration: 'Pod Duration',
  ssp_createtag_pod_radio_button_slot_count: 'Slot Count',
  ssp_createtag_pod_duration_rangebar_label: 'Min-max Pod Duration (in Sec)',
  ssp_createtag_slot_count_duration_rangebar_label: 'Min-max Slot Duration (in Sec)',
  ssp_createtag_slot_count_rangebar_label: 'Slot Count (1-10)',

  ssp_createtag_instruction:
    'Our smart floor dynamically choose a fair floor-price to maximize your earning',
  ssp_createtag_instruction_hyperlink: 'know more',

  ssp_createtag_field_label_ad_tagformat: 'Select Ad Tag Format',
  ssp_createtag_radio_button_url: 'Vast URL',
  ssp_createtag_radio_button_xml: 'Vast XML',
  ssp_createtag_field_label_ad_placement: 'Select Ad Placement',
  ssp_createtag_radio_button_pre_roll: 'Pre Roll',
  ssp_createtag_radio_button_mid_roll: 'Mid Roll',
  ssp_createtag_radio_button_end_roll: 'End Roll',
  ssp_createtag_note_one: '1 of 2 Steps',
  ssp_createtag_create_button: 'Create',

  // Update Tag Modal
  ssp_updatetag_modal_title: 'Update VAST Tag',

  //create tag success
  ssp_createtag_success_title: 'Tag Details',
  ssp_createtag_success_input_email: 'Email',
  ssp_createtag_success_input_placeholder_email: 'Enter Email to send Tag',
  ssp_createtag_success_email_empty_error: 'Enter an Email',
  ssp_createtag_success_email_valid_error: 'Enter a valid email',
  ssp_createtag_success_button_send: 'Send',
  ssp_createtag_success_mailsent_msg: 'Mail Sent',

  ssp_createtag_success_field_label_floor_price: 'Floor Price',
  ssp_createtag_success_field_label_is_soft: 'Is Soft',
  ssp_createtag_success_field_label_duration_range: 'Duration Range (in Sec)',

  ssp_createtag_success_tag_format_label: 'Choose Ad Tag Format',
  ssp_createtag_success_radio_button_url: 'Vast URL',
  ssp_createtag_success_radio_button_xml: 'Vast XML',

  ssp_createtag_success_macros_instruction: 'Macros for passing viewer & contextual information',
  ssp_createtag_success_macros_app_ddl_label: 'App Parameters',
  ssp_createtag_success_macros_device_ddl_label: 'Device Parameters',
  ssp_createtag_success_macros_stream_ddl_label: 'Stream Parameters',
  ssp_createtag_success_macros_privacy_ddl_label: 'Privacy Parameters',
  ssp_createtag_success_vast_copy_success_msg: 'Copied',
  ssp_createtag_success_adstxt_copy_success_msg: 'Copied',
  ssp_createtag_success_instruction_one:
    'Copy or Email this code to developer. Place the code where user is engaged easily.',
  ssp_createtag_success_instruction_two: 'App-ads.txt / Ads.txt Records',
  ssp_createtag_success_note_one: '2 of 2 Steps',
  ssp_createtag_success_button_done: 'Done',
  ssp_createtag_failed_msg: 'Something went wrong, please try again later.',

  /* Publisher Create SSAI Endpoint Initially */

  ssp_createssai_title: 'Create SSAI End Point',
  ssp_createssai_input_endpointname_label: 'Endpoint Name *',
  ssp_createssai_input_endpointname_placeholder: 'Enter Endpoint Name',
  ssp_createssai_input_endpointname_empty_error: 'Enter your source name',

  ssp_createssai_type_radio_buttons_label: 'Type',
  ssp_createssai_type_radio_button_app: 'App',
  ssp_createssai_type_radio_button_website: 'Website',
  ssp_createssai_streamtype_radio_buttons_title: 'Stream Type',
  ssp_createssai_streamtype_radio_button_vod: 'VOD',
  ssp_createssai_streamtype_radio_button_live: 'LIVE',

  ssp_createssai_streamtype_live_input_url_label: 'Origin URL *',
  ssp_createssai_streamtype_live_input_url_placeholder: 'Enter Raw HLS/DASH Stream URL',
  ssp_createssai_streamtype_live_input_url_empty_error: 'Enter stream url',
  ssp_createssai_streamtype_live_input_url_error: 'Enter valid url',
  ssp_createssai_streamtype_live_input_url_valid_error: 'Please enter .m3u8 or .mpd url',

  ssp_createssai_input_stream_timeout_label: 'Stream Timeout *',
  ssp_createssai_input_stream_timeout_placeholder: 'Integer in ms',
  ssp_createssai_input_stream_timeout_unit: 'ms',
  ssp_createssai_input_stream_empty_error: 'Enter stream timeout',
  ssp_createssai_input_stream_type_error: 'Enter valid stream timeout',
  ssp_createssai_input_stream_zero_error: 'Enter more than 0.',

  ssp_createssai_input_inventory_timeout_label: 'Inventory Timeout *',
  ssp_createssai_input_inventory_timeout_placeholder: 'Integer in ms',
  ssp_createssai_input_inventory_timeout_unit: 'ms',
  ssp_createssai_input_inventory_timeout_empty_error: 'Enter inventory timeout',
  ssp_createssai_input_inventory_timeout_type_error: 'Enter valid inventory timeout',
  ssp_createssai_input_inventory_timeout_zero_error: 'Enter more than 0.',

  ssp_createssai_definition_radio_buttons_title: 'Definition',
  ssp_createssai_definition_radio_button_vmap: 'VMAP',
  ssp_createssai_definition_radio_button_query_params: 'Query Params',
  ssp_createssai_definition_radio_button_hls_ad: 'HLS Ad Markers',

  ssp_createssai_input_label_fallback_floor_price: 'Fallback Floor Price *',
  ssp_createssai_input_placeholder_fallback_floor_price: 'Eg. 30.97',
  ssp_createssai_input_fallback_empty_error: 'Enter fallback floor price',
  ssp_createssai_input_fallback_type_error: 'Enter valid fallback floor price',
  ssp_createssai_input_fallback_zero_error: 'Enter more than 0.',

  ssp_createssai_softfloor_toggle_label: 'Soft Floor',
  ssp_createssai_softfloor_toggle_enabled_label: 'Enabled',
  ssp_createssai_softfloor_toggle_disabled_label: 'Disabled',

  ssp_createssai_pod_radio_buttons_title: 'Pod Construction',
  ssp_createssai_pod_radio_button_pod_duration: 'Pod Duration',
  ssp_createssai_pod_radio_button_slot_count: 'Slot Count',

  ssp_createssai_pod_duration_rangebar_label: 'Min-max Pod Duration (in Sec)',
  ssp_createssai_slot_count_duration_rangebar_label: 'Min-max Slot Duration (in Sec)',

  ssp_createssai_instruction_one:
    'When the floor price is not available is query parameter(macro), we will use fallback floor price',
  ssp_createssai_softfloor_toggle_instruction:
    'Our smart floor dynamically choose a fair floor-price to maximize your earning',

  ssp_createssai_note_one: '1 of 2 Steps',
  ssp_createssai_create_button: 'Create',
  ssp_createssai_failed_msg: 'Something went wrong, please try again later.',

  // Create SSAI End point Success Section in initially

  ssp_createssai_success_title: 'SSAI End Point Details',
  ssp_createssai_success_input_email: 'Send Integration Instruction',
  ssp_createssai_success_input_placeholder_email: 'Enter an Email',
  ssp_createssai_success_email_empty_error: 'Enter an email',
  ssp_createssai_success_email_valid_error: 'Invalid email',
  ssp_createssai_success_button_send: 'Send',
  ssp_createssai_success_mailsent_msg: 'Mail Sent',
  ssp_createssai_success_macros_instruction: 'Macros for passing viewer & contextual information',
  ssp_createssai_success_macros_app_ddl_label: 'App Parameters',
  ssp_createssai_success_macros_imp_ddl_label: 'Impressions',
  ssp_createssai_success_macros_device_ddl_label: 'Device',
  ssp_createssai_success_macros_content_ddl_label: 'Content',
  ssp_createssai_success_macros_user_ddl_label: 'User',
  ssp_createssai_success_macros_custom_ddl_label: 'Custom',
  ssp_createssai_success_macros_stream_ddl_label: 'Stream Parameters',
  ssp_createssai_success_macros_privacy_ddl_label: 'Privacy Parameters',
  ssp_createssai_success_macros_publisher_ddl_label: 'Publisher',
  ssp_createssai_success_endpoint_url_copy_success_msg: 'Copied',
  ssp_createssai_success_adstxt_copy_success_msg: 'Copied',
  ssp_createssai_success_instruction_one:
    'Copy or Email this code to developer. Place the code where user is engaged easily.',
  ssp_createssai_success_instruction_two: 'App-ads.txt / Ads.txt Records',
  ssp_createssai_success_note_one: '2 of 2 Steps',
  ssp_createssai_success_button_done: 'Done',

  /***** Create Video Extension Section *****/
  ssp_createext_title: 'Create Video Extensions',

  ssp_createext_input_label_extension_name: 'Extension Name *',
  ssp_createext_input_extension_placeholder: 'Enter Your Extension Name',
  ssp_createext_input_extension_empty_error: 'Enter your extension name.',

  ssp_createext_input_label_ctalink: 'CTA Link',
  ssp_createext_input_ctalink_placeholder: 'Enter CTA Link',
  ssp_createext_input_ctalink_empty_error: 'Please enter CTA link.',
  ssp_createext_input_ctalink_valid_error: 'Enter correct url!',

  ssp_createext_input_label_ctatext: 'CTA Text',
  ssp_createext_input_ctatext_placeholder: 'Enter CTA Text',

  ssp_createext_input_label_videos: 'Feed Video Limit',
  ssp_createext_input_videos_placeholder: 'Enter number of videos',

  ssp_createext_ddl_label_view_type: 'View Type',

  ssp_createext_field_text_after: 'After',
  ssp_createext_field_text_swipe_show: 'swipe show the Ads',

  ssp_createext_input_label_floor_price: 'Floor Price',
  ssp_createext_input_floor_price_placeholder: 'Enter floor price',
  ssp_createext_input_floor_price_empty_error: 'Enter floor price',
  ssp_createext_input_floor_price_valid_error: 'Enter valid floor price',

  ssp_createext_field_text_ad_placement: 'Ad Placement end of the each video.',

  ssp_createext_input_label_floor_price_ad: 'Floor Price',
  ssp_createext_input_floor_price_ad_placeholder: 'Enter floor price',
  ssp_createext_input_floor_price_ad_empty_error: 'Enter floor price',
  ssp_createext_input_floor_price_ad_valid_error: 'Enter valid floor price',

  ssp_createext_switch_label_smart_feed: 'Smart Feed',
  ssp_createext_switch_smart_feed_inst:
    'Choosing the smart feed option will result in our AI generating a feed tailored to your context.',
  ssp_createext_ddl_label_iab_category: 'IAB Category',

  ssp_createext_input_label_website_url: 'Website URL',
  ssp_createext_input_website_url_placeholder: 'Enter Website URL',

  ssp_createext_input_label_genuin_id: 'Genuin ID *',
  ssp_createext_input_genuin_id_placeholder: 'Enter your Genuin ID',
  ssp_createext_input_genuin_id_validation_error: 'id is not available please create.',

  ssp_createext_button_import_videos: 'Import More Videos',
  ssp_createext_button_account_create: 'Create',

  ssp_createext_button_close: 'Close',
  ssp_createext_button_create: 'Create',

  //User Profile
  ssp_createext_field_label_view: 'View',
  ssp_createext_field_label_videos: 'Videos',

  //User Mobile Verify Section
  ssp_createext_input_field_label_phone: 'Phone No.',
  ssp_createext_input_phone_valid_error: 'Please enter a valid phone number.',

  ssp_createext_input_field_label_genuin_username: 'Genuin Username',

  ssp_createext_button_send_otp: 'Send OTP',

  ssp_createext_otp_instruction: 'Please enter the one time password to verify your account.',
  ssp_createext_otp_instruction_code_sent: 'A code has been sent to',
  ssp_createext_field_label_time_remaining: 'Time Remaining:',
  ssp_createext_field_label_validate: 'Validate',

  //Social URL Section
  ssp_createext_input_label_instagram: 'Instagram',
  ssp_createext_input_instagram_valid_error: 'Please enter valid instagram link.',

  ssp_createext_input_label_youtube: 'YouTube',
  ssp_createext_input_youtube_valid_error: 'Please enter valid youtube link.',

  ssp_createext_input_label_tiktok: 'TikTok',
  ssp_createext_input_tiktok_valid_error: 'Please enter valid tiktok link.',

  /* Create Tag Prebid */
  ssp_prebid_title: 'Integrate Via Prebid',
  ssp_prebid_text: 'Prebid Connector Name: InfyTV',
  ssp_prebid_input_label_one: 'Publisher ID:',
  ssp_prebid_input_label_two: 'Placement ID:',
  ssp_prebid_button_generate: 'Generate',
  ssp_prebid_json_input_label: 'Prebid JSON',
  ssp_prebid_copied_msg: 'Copied',

  /// Dashboard & dashboard Sidebar & Header

  ssp_header_tab_button_supply: 'Inventory',
  ssp_header_tab_button_demand: 'Demand',
  ssp_header_tab_button_deals: 'PG/PMP',
  ssp_header_button_payouts: 'Payouts',
  ssp_header_available_balance: 'Available Balance',
  ssp_header_platform_cost: 'Platform Cost',

  ssp_dash_title: 'Overview',

  //sidebar button & title
  ssp_dash_sidebar_title_tags: 'Sources',
  ssp_dash_sidebar_button_add_new: 'Add New',

  // Add New Modal Tabs
  ssp_supply_add_popup_tab_tag: 'VAST Tag',
  ssp_supply_add_popup_tab_ssai: 'SSAI',
  ssp_supply_add_popup_tab_adreels: 'AdReels',

  //SSP Sorting DDL
  ssp_dash_sidebar_sorting_option_name: 'Name',
  ssp_dash_sidebar_sorting_option_duration: 'Duration',
  ssp_dash_sidebar_sorting_option_impression: 'Impression',
  ssp_dash_sidebar_sorting_option_fillrate: 'Fill Rate',
  ssp_dash_sidebar_sorting_option_created: 'Created',
  ssp_dash_sidebar_sorting_option_floorprice: 'Floor Price',
  ssp_dash_sidebar_sorting_option_earnings: 'Earnings',
  ssp_dash_sidebar_sorting_option_status: 'Status',

  // sidebar expand view

  //context menu
  ssp_tags_sidebar_expand_contextmenu_option_details: 'Details',
  ssp_tags_sidebar_expand_contextmenu_option_pause: 'Pause',
  ssp_tags_sidebar_expand_contextmenu_option_resume: 'Resume',

  ssp_tags_sidebar_expand_duration_unit: 'Seconds',

  ssp_tags_sidebar_expand_column_name: 'Name',
  ssp_tags_sidebar_expand_column_duration: 'Duration',
  ssp_tags_sidebar_expand_column_impressions: 'Impressions',
  ssp_tags_sidebar_expand_column_fillrate: 'Fill Rate',
  ssp_tags_sidebar_expand_column_created: 'Created',
  ssp_tags_sidebar_expand_column_floorprice: 'Floor Price',
  ssp_tags_sidebar_expand_column_earnings: 'Earnings',
  ssp_tags_sidebar_expand_column_status: 'Status',

  //Supply Card
  ssp_supply_vasttag_card_icon: 'Ad',
  ssp_supply_ssai_card_icon: 'SSAI',
  ssp_supply_card_field_label_floorprice: 'Floor Price',
  ssp_supply_card_field_label_impressions: 'Impressions',
  ssp_supply_card_field_label_earnings: 'Earnings',
  ssp_supply_card_field_label_fillrate: 'Fill Rate',
  ssp_supply_card_field_label_created: 'Created',
  ssp_supply_card_field_label_duration: 'Duration',

  // Tag Status Popup
  ssp_tag_activity_popup_title: 'Change Status',
  ssp_tag_activity_popup_instruction: 'Are you sure you want to Pause this tag?',
  ssp_tag_activity_popup_button_one: 'Cancel',
  ssp_tag_activity_popup_button_two: 'Pause',

  // Empty tags on sidebar
  ssp_tags_empty_sidebar_text_one: 'Click on Create tag button & create your first tag.',
  ssp_tags_empty_sidebar_text_two: 'No Results',

  //context menu
  ssp_card_contextmenu_label_one: 'Details',
  ssp_card_contextmenu_label_two: 'Delete',

  // Delete Tag Popup
  ssp_supply_tag_delete_popup_title: 'Delete a Tag',
  ssp_supply_tag_delete_popup_instruction: 'Are you sure you want to delete this tag? ',
  ssp_supply_tag_delete_popup_button_cancel: 'Cancel',
  ssp_supply_tag_delete_popup_button_delete: 'Delete',

  //Publisher Deals Sidebar
  ssp_deals_sidebar_title: '',
  ssp_deals_sidebar_tab_my_deals: 'My Deals',
  ssp_deals_sidebar_tab_incoming_requests: 'Incoming Requests',

  //Publisher Deals Dashboard
  ssp_deals_edit_icon_title: 'Edit',
  ssp_deals_delete_icon_title: 'Delete',

  ssp_deals_button_create_pmp_deal: 'Create PMP Deal',
  ssp_deals_button_download_xsl: 'Download XSL',
  ssp_deals_toggle_button_filter: 'Filter',
  ssp_deals_toggle_button_chart: 'Chart',

  ssp_deals_filter_title: 'Filter',

  ssp_deals_no_inventories_msg: 'No Inventories Found',

  ssp_deals_tab_performance: 'Performace',
  ssp_deals_tab_inventory: 'Details',
  ssp_deals_tab_assigned_demand: 'Assigned To',

  ssp_deals_button_update_deal: 'Update Deal',
  ssp_deals_button_add_demand: 'Assign To',
  ssp_deals_update_inv_list_success_msg: 'Deal update successfully.',

  ssp_deals_no_demand_msg: 'No Demand Found',
  ssp_deals_no_demand_instruction: "Click on 'Assign To' Button and Add your demand.",

  ssp_deals_input_field_seach_placeholder: 'Search Inventory Data',

  //Publisher Add Demand Popup
  ssp_deals_add_demand_popup_tab_deal_requests: 'Deal Requests',
  ssp_deals_add_demand_popup_tab_deal_requests_title_deal_id: 'Deal ID',
  ssp_deals_add_demand_popup_tab_deal_requests_title_deal_name: 'Deal Name',
  ssp_deals_add_demand_popup_tab_deal_requests_empty_msg: 'Deal Requests Not Found',
  ssp_deals_add_demand_popup_tab_deal_requests_assigned_msg:
    'You have assigned all deal requests to this deal.',
  ssp_deals_add_demand_popup_tab_my_demands: 'My Demands',
  ssp_deals_add_demand_popup_tab_my_demands_title_demand_id: 'Demand ID',
  ssp_deals_add_demand_popup_tab_my_demands_title_demand_name: 'Demand Name',
  ssp_deals_add_demand_popup_title: 'Add Demand',
  ssp_deals_add_demand_popup_no_demand_msg: "You don't have any demand",
  ssp_deals_add_demand_popup_all_demand_selected_msg:
    'You have assigned all customers to this deal.',
  ssp_deals_add_demand_popup_tab_offer_deal: 'Offer Deal',
  ssp_deals_add_demand_popup_button_cancel: 'Close',
  ssp_deals_add_demand_popup_button_add: 'Add',

  //Publisher Dashboard
  ssp_supply_dash_tab_performance: 'Performance',
  ssp_supply_dash_tab_settings: 'Settings',

  // Payout Settings
  ssp_payouts_tabs_button_payouts: 'Payouts',
  ssp_payouts_tabs_button_platformcost: 'Platform Cost',
  ssp_payouts_tabs_button_earnings: 'Monthly Earnings',
  ssp_payouts_tabs_button_details: 'Details',
  ssp_payouts_tabs_button_earning_by_partner: 'Earning By Partner',
  ssp_payouts_box_add_payment_method: 'Add New Payment Method',

  ssp_payouts_method_add_popup_title: 'Add Payment Details',
  ssp_payouts_method_add_popup_input_name: 'Name as per Bank Account',
  ssp_payouts_method_add_popup_input_placeholder_name: 'Enter Name',
  ssp_payouts_method_add_popup_input_error_name: 'Enter Account Holder Name',

  ssp_payouts_method_add_popup_input_ifsc: 'IFSC Code',
  ssp_payouts_method_add_popup_input_placeholder_ifsc: 'Enter bank IFSC Code',
  ssp_payouts_method_add_popup_input_empty_error_ifsc: 'Enter IFSC Code',

  ssp_payouts_method_add_popup_input_acc_num: 'Account Number',
  ssp_payouts_method_add_popup_input_placeholder_acc_num: 'Enter bank Account  Number',
  ssp_payouts_method_add_popup_input_error_acc_num: 'Enter Account Number',
  ssp_payouts_method_add_popup_input_valid_error_acc_num: 'Enter valid Account Number',

  ssp_payouts_method_add_popup_input_bank_name: 'Bank Name',
  ssp_payouts_method_add_popup_input_placeholder_bank_name: 'Enter Bank Name',
  ssp_payouts_method_add_popup_input_empty_error_bank_name: 'Enter Bank Name',

  ssp_payouts_method_add_popup_input_swift_code: 'Swift Code',
  ssp_payouts_method_add_popup_input_placeholder_swift_code: 'Enter Bank Swift Code',
  ssp_payouts_method_add_popup_input_empty_error_swift_code: 'Enter Swift Code',

  ssp_payouts_method_add_popup_input_retype_acc_num: 'Re-Type Account Number',
  ssp_payouts_method_add_popup_input_placeholder_retype_acc_num: 'Re-Type Bank Account Number',
  ssp_payouts_method_add_popup_input_empty_error_retype_acc_num: 'Re-enter Account Number',
  ssp_payouts_method_add_popup_input_valid_error_acc_num_match: 'Account Numbers do not match',

  ssp_payouts_method_add_popup_button_save: 'Save',
  ssp_payouts_method_add_popup_button_cancel: 'Cancel',

  // Publisher Payouts Payment Method Delete Popup
  ssp_payouts_method_delete_popup_title: 'Delete a Method',
  ssp_payouts_method_delete_popup_instruction: 'Are you sure you want to delete this method?',
  ssp_payouts_method_delete_popup_button_cancel: 'Cancel',
  ssp_payouts_method_delete_popup_button_delete: 'Delete',
  ssp_payouts_method_delete_not_eligible_msg: 'User is not authorized to do this operation.',

  ssp_payouts_payment_title: 'Billing Information',
  ssp_payouts_payment_details_input_field_label_name: 'Name on Invoice',
  ssp_payouts_payment_details_input_field_placeholder_name: 'Enter your Name',
  ssp_payouts_payment_details_input_field_label_address_one: 'Address Line 1',
  ssp_payouts_payment_details_input_field_placeholder_address_one: 'Enter your Address',
  ssp_payouts_payment_details_input_field_label_address_two: 'Address Line 2',
  ssp_payouts_payment_details_input_field_placeholder_address_two: 'Enter your Address',
  ssp_payouts_payment_details_input_field_label_country: 'Country',
  ssp_payouts_payment_details_input_field_placeholder_country: 'Enter your Country',
  ssp_payouts_payment_details_input_field_label_state: 'State',
  ssp_payouts_payment_details_input_field_placeholder_state: 'Enter your State',
  ssp_payouts_payment_details_input_field_label_city: 'City',
  ssp_payouts_payment_details_input_field_placeholder_city: 'Enter your City',
  ssp_payouts_payment_details_input_field_label_postal_code: 'Postal code',
  ssp_payouts_payment_details_input_field_placeholder_postal_code: 'Enter your postal code',
  ssp_payouts_payment_details_input_field_label_phone: 'Phone',
  ssp_payouts_payment_details_input_field_placeholder_phone: 'Enter your Phone number',

  //Third Tabs (Payouts)
  ssp_payouts_tab_payouts_balance_card_field_label: 'Available Balance',
  ssp_payouts_tab_payouts_withdrawal_card_field_label: 'Available Withdrawal',
  ssp_payouts_tab_payouts_withdraw_card_button: 'Withdraw',

  //Publisher Withdrawal Popup
  ssp_payouts_third_tab_withdraw_popup_title: 'Withdraw',
  ssp_payouts_third_tab_withdraw_popup_field_label_withdrawal: 'Available Withdrawal',
  ssp_payouts_third_tab_withdraw_popup_input_field_label_withdrawal: 'Enter Amount of Withdraw',
  ssp_payouts_third_tab_withdraw_popup_input_field_placeholder_withdrawal: 'Ex $1500',
  ssp_payouts_third_tab_withdraw_popup_dropdown_label_payment: 'Select Payment Method',
  ssp_payouts_third_tab_withdraw_popup_button_request: 'Request',
  ssp_payouts_third_tab_withdraw_error_insuficient_balance: 'You have insufficient balance',
  ssp_payouts_third_tab_withdraw_amount_valid_error: 'Enter valid amount',
  ssp_payouts_third_tab_withdraw_request_failed_error: 'Something went wrong, try again later.',
  ssp_payouts_third_tab_withdraw_popup_note:
    '*Amount will be credited when admin approves the request.',

  ssp_payout_third_tab_withdraw_success_popup_title: 'Payout Requested',
  ssp_payout_third_tab_withdraw_success_popup_instruction:
    'Our team will be processing your payout request. We usually take 4-5 business days to process your payout request, but sometimes it can take a bit longer.',
  ssp_payout_third_tab_withdraw_success_popup_button_done: 'Done',

  /* SSP Create Deal Modal */
  ssp_createdeal_title: 'Create Deal',

  ssp_createdeal_input_field_name_label: 'Deal Name *',
  ssp_createdeal_input_field_name_placeholder: 'Enter Your Deal Name',
  ssp_createdeal_input_field_name_empty_error: 'Enter your deal name',

  ssp_createdeal_radio_pg: 'PG',
  ssp_createdeal_radio_pmp: 'PMP',

  ssp_createdeal_input_field_mincpm_label: 'Min CPM *',
  ssp_createdeal_input_field_mincpm_placeholder: 'Eg. 18.50',
  ssp_createdeal_input_field_mincpm_empty_error: 'Enter min cpm',
  ssp_createdeal_input_field_mincpm_valid_error: 'Enter valid min cpm',
  ssp_createdeal_input_field_mincpm_length_error: 'Min cpm field is allow max 5-digits.',

  ssp_createdeal_input_field_maxcpm_label: 'Max CPM *',
  ssp_createdeal_input_field_maxcpm_placeholder: 'Eg. 35.70',
  ssp_createdeal_input_field_maxcpm_empty_error: 'Enter max cpm',
  ssp_createdeal_input_field_maxcpm_valid_error: 'Enter valid max cpm',
  ssp_createdeal_input_field_maxcpm_length_error: 'Max cpm field is allow max 5-digits.',

  ssp_createdeal_input_field_min_max_compare_error: 'Max cpm must be more than min cpm',

  ssp_createdeal_input_field_cpm_label: 'CPM *',
  ssp_createdeal_input_field_cpm_placeholder: 'Eg. 20.75',
  ssp_createdeal_input_field_cpm_empty_error: 'Enter cpm',
  ssp_createdeal_input_field_cpm_valid_error: 'Enter valid cpm',
  ssp_createdeal_input_field_cpm_length_error: 'CPM field is allow max 5-digits.',

  ssp_createdeal_input_field_imp_label: 'Impressions *',
  ssp_createdeal_input_field_imp_placeholder: 'Eg. 1000000',
  ssp_createdeal_input_field_imp_empty_error: 'Enter impressions',
  ssp_createdeal_input_field_imp_valid_error: 'Enter valid impressions',

  ssp_createdeal_input_field_description_label: 'Description',
  ssp_createdeal_input_field_description_placeholder: 'Enter Deal Description Here',

  ssp_createdeal_ddl_field_iab_category_label: 'IAB Category',

  ssp_createdeal_checkbox_field_device_type_label: 'Device Type',
  ssp_createdeal_checkbox_label_tv: 'TV',
  ssp_createdeal_checkbox_label_desktop: 'Desktop',
  ssp_createdeal_checkbox_label_mobile: 'Mobile',

  ssp_createdeal_checkbox_field_platform_type_label: 'Platform Type',
  ssp_createdeal_checkbox_label_ott: 'OTT',
  ssp_createdeal_checkbox_label_ctv: 'CTV',

  ssp_createdeal_checkbox_field_application_type_label: 'Application Type',
  ssp_createdeal_checkbox_label_app: 'App',
  ssp_createdeal_checkbox_label_site: 'Site',

  ssp_createdeal_input_field_title_label: 'Title',
  ssp_createdeal_input_field_title_placeholder: 'Enter Title',

  ssp_createdeal_input_field_series_label: 'Series',
  ssp_createdeal_input_field_series_placeholder: 'Enter Series',

  ssp_createdeal_input_field_artist_label: 'Artist',
  ssp_createdeal_input_field_artist_placeholder: 'Enter Artist',

  ssp_createdeal_ddl_field_genre_label: 'Genre',

  ssp_createdeal_input_field_isrc_code_label: 'ISRC Code',
  ssp_createdeal_input_field_isrc_code_placeholder: 'Enter ISRC Code',

  ssp_createdeal_input_field_producer_label: 'Producer Name',
  ssp_createdeal_input_field_producer_placeholder: 'Enter Producer Name',

  ssp_createdeal_input_field_producer_domain_label: 'Producer Domain',
  ssp_createdeal_input_field_producer_domain_placeholder: 'Enter Producer Domain',

  ssp_createdeal_ddl_field_product_qlt_label: 'Product Quality',
  ssp_createdeal_ddl_field_context_label: 'Context',

  ssp_createdeal_input_field_content_rating_label: 'Content Rating',
  ssp_createdeal_input_field_content_rating_placeholder: 'Enter Content Rating',

  ssp_createdeal_input_field_user_rating_label: 'User Rating',
  ssp_createdeal_input_field_user_rating_placeholder: 'Enter User Rating',

  ssp_createdeal_ddl_field_media_rating_label: 'Media Rating Per IQG g',

  ssp_createdeal_input_field_keywords_label: 'Keywords',
  ssp_createdeal_input_field_keywords_placeholder: 'Enter Keywords',

  ssp_createdeal_input_field_keyword_array_label: 'Keyword Array',
  ssp_createdeal_input_field_keyword_array_placeholder: 'Enter Keyword Array',

  ssp_createdeal_ddl_field_livestream_label: 'Live Stream',
  ssp_createdeal_ddl_field_source_label: 'Source Relationship',

  ssp_createdeal_input_field_content_length_label: 'Content Length',
  ssp_createdeal_input_field_content_length_placeholder: 'Enter Content Length',

  ssp_createdeal_input_field_lan_code_label: 'Language',
  ssp_createdeal_input_field_lan_code_placeholder: 'Enter Language Code',

  ssp_createdeal_ddl_field_embeddable_label: 'Embeddable',

  ssp_createdeal_input_field_network_name_label: 'Network Name',
  ssp_createdeal_input_field_network_name_placeholder: 'Enter Network Name',

  ssp_createdeal_input_field_network_domain_label: 'Network Domain',
  ssp_createdeal_input_field_network_domain_placeholder: 'Enter Network Domain',

  ssp_createdeal_input_field_channel_name_label: 'Channel Name',
  ssp_createdeal_input_field_channel_name_placeholder: 'Enter Channel Name',

  ssp_createdeal_input_field_channel_domain_label: 'Channel Domain',
  ssp_createdeal_input_field_channel_domain_placeholder: 'Enter Channel Domain',

  ssp_createdeal_button_close: 'Close',
  ssp_createdeal_button_next: 'Next',
  ssp_createdeal_button_create: 'Create',
  ssp_createdeal_button_create_loading: 'Creating...',

  // SSP Deal Update Modal
  ssp_updatedeal_title: 'Edit Deal',
  ssp_updatedeal_button_update: 'Update',
  ssp_updatedeal_button_update_loading: 'Updating...',

  ////////////////////////// DSP DASHBOARD ////////////////////////////////////////

  // First Tour
  dsp_tour_title_one: "Let's Take a Tour",
  dsp_tour_instruction_one:
    'Welcome to infy.TV! Let’s go through some of the platform features which might be exciting for you. Press next to begin the tour.',
  dsp_tour_title_two: 'Real Time Reporting Dashboard & Optimization',
  dsp_tour_instruction_two:
    'Experience real-time reporting and optimize your operations with our dashboard solution.',
  dsp_tour_title_three: 'Filter Your Traffic, By Inventory',
  dsp_tour_instruction_three:
    'Take control of your traffic by filtering it according to inventory. Seamlessly manage your resources and target your audience more effectively with our user-friendly tools.',
  dsp_tour_title_four: 'Manage Your Finance & Spending',
  dsp_tour_instruction_four:
    'Take control of your finances and spending with our easy-to-use management tools.',
  dsp_tour_button_next: 'Next',
  dsp_tour_button_done: 'Done',

  //Second Tour
  dsp_step_tour_one_title: 'Settings',
  dsp_step_tour_one_descriptions:
    'Click on settings to manage your profile, company and financial settings.',
  dsp_step_tour_two_title: 'Notifications',
  dsp_step_tour_two_descriptions: 'Check your notification regularly for updates on your platform',
  dsp_step_tour_three_title: 'Status Bar',
  dsp_step_tour_three_descriptions:
    'Here you will find quick access to an overview of key stats about Impressions & Earning',
  dsp_step_tour_four_title: 'Create RTB EndPoint',
  dsp_step_tour_four_descriptions:
    'Here you will find quick access to an overview of key stats about Impressions & Earning',
  dsp_step_tour_five_title: 'Create Header Bidder',
  dsp_step_tour_five_descriptions:
    'Here you will find quick access to an overview of key stats about Impressions & Earning',

  //Onboarding
  dsp_onboarding_step_create_ednpoint: 'Create Endpoint',

  // Add Endpoint Initially
  dsp_add_endpoint_title: 'Create RTB Endpoint',
  dsp_add_endpoint_input_name_label: 'Integration Name *',
  dsp_add_endpoint_input_name_placeholder: 'Enter Integration Name',
  dsp_add_endpoint_input_name_empty_error: 'Enter your integration name',

  dsp_add_endpoint_radio_rtb_one: 'RTB 2.5',
  dsp_add_endpoint_radio_rtb_two: 'RTB 2.6',

  dsp_add_endpoint_toggle_all_traffic: 'All Traffic',

  dsp_add_endpoint_radio_button_floor_range: 'Floor Range',
  dsp_add_endpoint_radio_button_fix_floor: 'Fix Floor',

  dsp_add_endpoint_input_min_floor_price_label: 'Min Floor Price *',
  dsp_add_endpoint_input_min_floor_price_placeholder: '0',
  dsp_add_endpoint_input_min_floor_price_empty_error: 'Enter your min floor price',
  dsp_add_endpoint_input_min_floor_price_valid_error: 'Enter valid min floor price',
  dsp_add_endpoint_input_min_floor_max_length_error: 'Min Floor Price maximum 5-digits allow',

  dsp_add_endpoint_input_max_floor_price_label: 'Max Floor Price *',
  dsp_add_endpoint_input_max_floor_price_placeholder: '0',
  dsp_add_endpoint_input_max_floor_price_empty_error: 'Enter your max floor price',
  dsp_add_endpoint_input_max_floor_price_valid_error: 'Enter valid max floor price',
  dsp_add_endpoint_input_max_floor_price_max_length_error: 'Max floor price maximum 5-digit allow',

  dsp_add_endpoint_input_floor_price_label: 'Floor Price *',
  dsp_add_endpoint_input_floor_price_placeholder: '0',
  dsp_add_endpoint_input_floor_price_empty_error: 'Enter your floor price',
  dsp_add_endpoint_input_floor_price_valid_error: 'Enter valid floor price',
  dsp_add_endpoint_input_floor_price_max_length_error: 'Floor price maximum 5 digits allow',

  dsp_add_endpoint_field_label_device_type: 'Device Type *',
  dsp_add_endpoint_field_device_type_empty_error: 'Select device type',

  dsp_add_endpoint_input_url_label: 'RTB URL *',
  dsp_add_endpoint_input_url_placeholder: 'Enter RTB Endpoint URL',
  dsp_add_endpoint_input_url_empty_error: 'Enter RTB endpoint URL',
  dsp_add_endpoint_input_url_valid_error: 'Enter Valid Url',

  dsp_add_endpoint_success_msg: 'End point Added!',
  dsp_add_rtb_endpoint_exist_error: 'End point with this name already exist.',
  dsp_add_endpoint_server_error: 'Service unavailable, Check back after sometime.',

  dsp_add_endpoint_button_close: 'Close',
  dsp_add_endpoint_button_test_integration: 'Test Integration',

  // Add Header Bidder Initially
  dsp_add_hb_title: 'Select Header Bidding Connector',
  dsp_add_hb_input_search_placeholder: 'Search Bidder Name',

  dsp_add_hb_list_onhover_button: 'Add',

  dsp_add_hb_popup_button_back: 'Back',
  dsp_add_hb_popup_button_test_integration: 'Test Integration',
  dsp_hb_email_login_button_title_1: 'Approach All',
  dsp_hb_email_login_button_title_2: `Let's do it!`,
  dsp_hb_email_login_button_title_3: 'Send Bulk Emails',
  dsp_hb_email_login_button_logout: 'Logout',
  dsp_hb_email_login_button_close: 'Close',
  dsp_hb_email_login_modal_content_p1: 'Outreach emails will be sent through',
  // 'Please confirm that you would like to send an outreach email to all header bidder connectors using your email account',
  dsp_hb_email_login_modal_content_p2:
    'to all about header bidder connectors any reply from theme will be received in',
  // 'Note that any replies will be sent to',

  // Header

  dsp_header_button_finance: 'Finance',
  dsp_settings_header_field_label_spending: 'Spending',
  dsp_header_field_label_spending: 'Spending',
  dsp_header_field_label_platformcost: 'Platform Cost',

  //Sidebar

  //sidebar_sorting options
  dsp_dash_sidebar_sorting_option_name: 'Name',
  dsp_dash_sidebar_sorting_option_duration: 'Duration',
  dsp_dash_sidebar_sorting_option_created: 'Created',
  dsp_dash_sidebar_sorting_option_min_floor: 'Min Floor Price',
  dsp_dash_sidebar_sorting_option_max_floor: 'Max Floor Price',

  //Sidebar Tabs
  dsp_dash_sidebar_tab_sources: 'Sources',
  dsp_dash_sidebar_tab_partners: 'Partners',

  //sidebar_title
  dsp_dash_sidebar_title_sources: 'Sources',
  dsp_dash_sidebar_title_partners: 'Partners',
  //sidebar_button
  dsp_dash_sidebar_button_add_new: 'Add New',
  dsp_dash_sidebar_button_invite: 'Invite',

  //sidebar_expanded_columns
  dsp_dash_sidebar_expand_column_name: 'Name',
  dsp_dash_sidebar_expand_column_created: 'Created',
  dsp_dash_sidebar_expand_column_impressions: 'Impressions',
  dsp_dash_sidebar_expand_column_spending: 'Spending',
  dsp_dash_sidebar_expand_column_bid_rate: 'Bid Rate',
  dsp_dash_sidebar_expand_column_status: 'Status',

  //sidebar_card
  dsp_rtb_card_icon: 'RTB',
  dsp_vast_card_icon: 'VAST',
  dsp_card_field_label_floor: 'Floor',
  dsp_card_field_label_impressions: 'Impressions',
  dsp_card_field_label_earnings: 'Earnings',
  dsp_card_field_label_rtb_version: 'RTB Version',
  dsp_card_field_label_bidder_name: 'Bidder Name',
  dsp_card_field_label_min_floor: 'Min Floor Price',
  dsp_card_field_label_max_floor: 'Max Floor Price',

  //Demand Request Deal Card
  dsp_req_deal_card_icon: 'REQ',
  dsp_req_deal_card_field_label_created_on: 'Created On',
  dsp_req_deal_card_field_label_mincpm: 'Min CPM',
  dsp_req_deal_card_field_label_maxcpm: 'Max CPM',
  dsp_req_deal_card_field_label_cpm: 'CPM',
  dsp_req_deal_card_field_label_impressions: 'Impressions',

  //Sidebar expand context menu
  dsp_sidebar_card_contextmenu_option_details: 'Details',
  dsp_sidebar_card_contextmenu_options_edit: 'Edit',

  //Add RTB, HB, VAST Popup
  dsp_source_add_popup_tab_rtb: 'RTB Endpoint Integration',
  dsp_source_add_popup_tab_hb: 'Header Bidding',
  dsp_source_add_popup_tab_vast: 'VAST URL',
  dsp_source_add_popup_tab_insertion_order: 'Insertion Order',
  dsp_source_add_popup_tab_contextual_reel: 'Contextual Reel',

  //Add HB
  dsp_add_hb_success_msg: 'End point Added!',
  dsp_add_hb_exist_error: 'End point with this name already exist.',
  dsp_add_hb_faild_server_msg: 'Service unavailable, Check back after sometime.',

  // Add Vast Url Popup
  dsp_add_vast_popup_input_label_name: 'Integration Name',
  dsp_add_vast_popup_input_placeholder_name: 'Enter Integration Name',
  dsp_add_vast_popup_input_name_empty_error: 'Enter your integration name',

  dsp_add_vast_popup_toggle_label_all_traffic: 'All Traffic',

  dsp_add_vast_popup_radio_button_floor_range: 'Floor Range',
  dsp_add_vast_popup_radio_button_fix_floor: 'Fix Floor',

  dsp_add_vast_popup_input_label_min_floor: 'Min Floor Price',
  dsp_add_vast_popup_input_placeholder_min_floor: '0',
  dsp_add_vast_popup_input_min_floor_empty_error: 'Enter your Min Floor Price',
  dsp_add_vast_popup_input_min_floor_valid_error: 'Enter Valid Min Floor Price',

  dsp_add_vast_popup_input_label_max_floor: 'Max Floor Price',
  dsp_add_vast_popup_input_placeholder_max_floor: '0',
  dsp_add_vast_popup_input_max_floor_empty_error: 'Enter your Max Floor Price',
  dsp_add_vast_popup_input_max_floor_valid_error: 'Enter Valid Max Floor Price',
  dsp_add_vast_popup_input_max_floor_length_error: 'The Max Floor Price Maximum 5-digit allow',

  dsp_add_vast_popup_min_max_compare_error: 'Max must be more than min',

  dsp_add_vast_popup_input_label_floor_price: 'Floor Price',
  dsp_add_vast_popup_input_placeholder_floor_price: '0',
  dsp_add_vast_popup_input_floor_price_empty_error: 'Enter your Floor Price',
  dsp_add_vast_popup_input_floor_price_valid_error: 'Enter Valid Floor Price',
  dsp_add_vast_popup_input_floor_price_length_error: 'The Floor Price maximum 5-digit allow',

  dsp_add_vast_popup_field_label_device_type: 'Device Type',
  dsp_add_vast_popup_field_device_type_empty_error: 'Select device type',

  dsp_add_vast_popup_input_label_vast_url: 'VAST URL',
  dsp_add_vast_popup_input_placeholder_vast_url: '',
  dsp_add_vast_popup_input_vast_url_empty_error: 'Enter your VAST URL',
  dsp_add_vast_popup_input_vast_url_valid_error: 'URL is not valid',

  dsp_add_vast_popup_button_close: 'Close',
  dsp_add_vast_popup_button_check_macros: 'Check Macros',

  //Add VAST URL Section-2
  dsp_add_vast_popup_section_two_column_params: 'Detected Params',
  dsp_add_vast_popup_section_two_column_values: 'Detected Values',
  dsp_add_vast_popup_section_two_column_macros: 'Platform Params & Macros',

  dsp_add_vast_popup_section_two_button_back: 'Back',
  dsp_add_vast_popup_section_two_button_done: 'Done',
  dsp_add_vast_popup_section_two_button_close: 'Close',

  dsp_add_vast_add_success_msg: 'End point Added!',
  dsp_add_vast_exist_error: 'End point with this name already exist.',
  dsp_add_vast_add_server_error: 'Service unavailable, Check back after sometime.',

  // Details RTB Endpoint Popup
  dsp_details_rtb_popup_title: 'RTB End point Details',
  dsp_details_rtb_popup_button_edit: 'Edit',
  dsp_details_rtb_popup_button_close: 'Close',

  // Update RTB Endpoint Popup
  dsp_update_rtb_popup_title: 'Update RTB End point',
  dsp_upadte_rtb_popup_button_update: 'Update',
  dsp_upadte_rtb_popup_button_close: 'Close',
  dsp_update_rtb_success_msg: 'Update Successfully.',
  dsp_update_rtb_faild_server_msg: 'Service unavailable, Check back after sometime.',

  // HB Deatils Popup (Using in both Details / Update Popup)
  dsp_details_hb_popup_title: 'Header Bidder Details',

  dsp_details_hb_popup_input_label_name: 'Integration Name',
  dsp_details_hb_popup_input_placeholder_name: 'Integration Name',
  dsp_details_hb_popup_input_name_empty_error: 'Enter your Integration Name',

  dsp_details_hb_popup_input_label_min_floor: 'Min Floor Price',
  dsp_details_hb_popup_input_placeholder_min_floor: '0',
  dsp_details_hb_popup_input_min_floor_empty_error: 'Enter your Min Floor Price',
  dsp_details_hb_popup_input_min_floor_valid_error: 'Enter Valid Min Floor Price',
  dsp_details_hb_popup_input_min_floor_length_error: 'The Min Floor price maximum 5-digits allow',

  dsp_details_hb_popup_input_label_max_floor: 'Max Floor Price',
  dsp_details_hb_popup_input_placeholder_max_floor: '0',
  dsp_details_hb_popup_input_max_floor_empty_error: 'Enter your Max Floor Price',
  dsp_details_hb_popup_input_max_floor_valid_error: 'Enter Valid Max Floor Price',
  dsp_details_hb_popup_input_max_floor_length_error: 'The Min Floor price maximum 5-digits allow',

  dsp_details_hb_popup_min_max_compare_error: 'Max must be more than min',

  dsp_details_hb_popup_button_close: 'Close',
  dsp_details_hb_popup_button_edit_hb: 'Edit Header Bidder',

  // HB Update Popup
  dsp_update_hb_popup_title: 'Update Header Bidder',
  dsp_update_hb_popup_button_close: 'Close',
  dsp_update_hb_popup_button_update_hb: 'Update Header Bidder',
  dsp_update_hb_success_msg: 'Update successfully',
  dsp_update_hb_update_error: 'Something went wrong.',
  dsp_update_hb_server_error: 'Something went wrong, please try again later.',

  // Details Vast URL Popup
  dsp_details_vast_popup_title: 'VAST Details',
  dsp_details_vast_popup_button_edit_macros: 'Edit',

  // Update Vast URL Popup
  dsp_update_vast_popup_title: 'Update VAST',
  // dsp_update_vast_popup_button_check_macros
  dsp_update_vast_success_msg: 'Update Successfully',
  dsp_update_vast_server_error: 'Service unavailable, Check back after sometime.',

  // ADD INSERTION ORDER MODAL (DSP/SSP)
  dsp_add_io_popup_title: 'Create Insertion Order',
  dsp_add_io_popup_input_label_name: 'IO Name *',
  dsp_add_io_popup_input_placeholder_name: 'Enter IO Name For Your Reference',
  dsp_add_io_popup_input_name_empty_error: 'Enter your IO name',

  dsp_add_io_popup_input_label_cpm: 'CPM *',
  dsp_add_io_popup_input_placeholder_cpm: 'Eg. 15.50',
  dsp_add_io_popup_input_cpm_empty_error: 'Enter your CPM price',
  dsp_add_io_popup_input_cpm_valid_error: 'Enter valid CPM price',
  dsp_add_io_popup_input_cpm_length_error: 'The CPM price maximum 5-digit allow',
  dsp_add_io_popup_input_cpm_type_error: 'Enter valid CPM price',

  dsp_add_io_popup_input_label_budget: ' Daily Budget',
  dsp_add_io_popup_input_placeholder_budget: 'Eg. 15000.00',
  dsp_add_io_popup_input_budget_empty_error: 'Enter your daily budget price',
  dsp_add_io_popup_input_budget_valid_error: 'Enter valid daily budget price',
  dsp_add_io_popup_input_budget_type_error: 'Enter valid daily budget price',

  dsp_add_io_popup_input_label_adv_domain: 'Advertiser Domain *',
  dsp_add_io_popup_input_adv_domain_icon: 'WWW.',
  dsp_add_io_popup_input_placeholder_adv_domain: 'Enter Your Advertiiser Domain',
  dsp_add_io_popup_input_adv_domain_empty_error: 'Enter your advertiser domain',
  dsp_add_io_popup_input_adv_domain_type_error: 'Enter valid advertiser domain',

  dsp_add_io_popup_input_label_start_date: 'Start Date *',
  dsp_add_io_popup_input_label_end_date: 'End Date *',

  dsp_add_io_popup_date_difference_error: 'Minimum 24 hours is required.',
  dsp_add_io_popup_date_compare_error: 'End date must be greater than start date.',

  dsp_add_io_popup_field_label_video_creative: 'Video Creative',
  dsp_add_io_popup_field_video_creative_radio_upload: 'Upload',
  dsp_add_io_popup_field_video_creative_radio_vastxml: 'VAST XML',
  dsp_add_io_popup_field_video_creative_radio_vasturl: 'VAST URL',

  dsp_add_io_popup_video_upload_success_msg: 'Creative uploaded successfully',

  dsp_add_io_popup_field_label_impression_capping: 'Daily Impression Capping',
  dsp_add_io_popup_input_placeholder_imp_capping: 'Eg. 10,000,000',
  dsp_add_io_popup_input_imp_cap_empty_error: 'Enter daily impression capping value',
  dsp_add_io_popup_input_imp_cap_valid_error: 'Enter valid daily impression capping value',
  dsp_add_io_popup_input_imp_cap_type_error: 'Enter valid daily impression capping value',

  dsp_add_io_popup_field_label_upload: 'Upload',
  dsp_add_io_popup_field_upload_button_select_video_ad: 'Select Video Ad',
  dsp_add_io_popup_field_upload_button_video_uploaded: 'Video Uploaded',
  dsp_add_io_popup_field_upload_button_change_video: 'Change Video',
  dsp_add_io_popup_field_upload_button_loading: 'Uploading...',
  dsp_add_io_popup_field_upload_hyperlink_video_upload_guidelines: 'Read Video upload guidelines',

  dsp_add_io_popup_field_label_vastxml: 'VAST XML *',
  dsp_add_io_popup_field_input_vastxml_empty_error: 'Enter your VAST XML',

  dsp_add_io_popup_field_label_vasturl: 'VAST URL *',
  dsp_add_io_popup_field_input_vasturl_empty_error: 'Enter your VAST URL',
  dsp_add_io_popup_field_input_vasturl_valid_error: 'Enter valid VAST URL',

  dsp_add_io_popup_field_upload_video_empty_error: 'Video uploading is required.',
  dsp_add_io_popup_field_upload_video_file_type_error:
    'Invalid file type. Only videos are allowed.',
  dsp_add_io_popup_field_upload_video_file_size_error: 'Selected file is too large.',

  dsp_add_io_popup_button_close: 'Close',
  dsp_add_io_popup_button_run_io: 'Run IO',
  dsp_add_io_popup_button_check_macros: 'Check Macros',

  // Insertion Order Details Modal
  dsp_details_io_popup_title_vast_xml: 'Insertion Order Details',
  dsp_details_io_popup_title_vast_url: 'Insertion Order Details',
  dsp_details_ip_popup_button_edit: 'Edit',

  // Insertion Order Update Modal
  dsp_update_io_popup_title_vast_xml: 'Update Insertion Order',
  dsp_update_io_popup_title_vast_url: 'Update Insertion Order',
  dsp_update_ip_popup_button_update: 'Update',
  dsp_update_io_popup_button_check_macros: 'Check Macros',

  // Add Contextual Reel Modal
  dsp_add_reel_popup_input_label_name: 'IO Name *',
  dsp_add_reel_popup_input_placeholder_name: 'Enter IO Name For Your Reference',
  dsp_add_reel_popup_input_name_empty_error: 'Enter your IO Name',

  dsp_add_reel_popup_ddl_label_iab_cat: 'IAB Categories',
  dsp_add_reel_popup_ddl_iab_cat_placeholder: 'Select IAB Categories',

  dsp_add_reel_popup_input_label_cpm: 'CPM *',
  dsp_add_reel_popup_input_placeholder_cpm: 'Eg. 15.50',
  dsp_add_reel_popup_input_cpm_empty_error: 'Enter your CPM price',
  dsp_add_reel_popup_input_cpm_valid_error: 'Enter valid CPM price',
  dsp_add_reel_popup_input_cpm_length_error: 'The CPM price maximum 5-digit allow',
  dsp_add_reel_popup_input_cpm_type_error: 'Enter valid CPM price',
  dsp_add_reel_popup_input_cpm_error: 'Enter at least 1 "CPM".',

  dsp_add_reel_popup_input_label_budget: 'Daily Budget',
  dsp_add_reel_popup_input_placeholder_budget: 'Eg. 15000.00',
  dsp_add_reel_popup_input_budget_empty_error: 'Enter your daily budget price',
  dsp_add_reel_popup_input_budget_valid_error: 'Enter valid daily budget price',
  dsp_add_reel_popup_input_budget_type_error: 'Enter valid daily budget price',

  dsp_add_reel_popup_input_label_start_date: 'Start Date *',
  dsp_add_reel_popup_input_label_end_date: 'End Date *',

  dsp_add_reel_popup_date_difference_error: 'Minimum 24 hours is required.',
  dsp_add_reel_popup_date_compare_error: 'End date must be greater than start date.',

  dsp_add_reel_popup_input_label_adv_domain: 'Advertiser Domain *',
  dsp_add_reel_popup_input_adv_domain_icon: 'WWW.',
  dsp_add_reel_popup_input_placeholder_adv_domain: 'Enter Your Advertiser Domain',
  dsp_add_reel_popup_input_adv_domain_empty_error: 'Enter your advertiser domain',
  dsp_add_reel_popup_input_adv_domain_type_error: 'Enter valid advertiser domain',

  dsp_add_reel_popup_field_label_impression_capping: 'Daily Impression Capping',
  dsp_add_reel_popup_input_placeholder_imp_capping: 'Eg. 10,000,000',
  dsp_add_reel_popup_input_imp_cap_empty_error: 'Enter daily impression capping value',
  dsp_add_reel_popup_input_imp_cap_valid_error: 'Enter valid daily impression capping value',
  dsp_add_reel_popup_input_imp_cap_type_error: 'Enter valid daily impression capping value',

  dsp_add_reel_popup_input_label_genuin_id: 'Genuin ID *',
  dsp_add_reel_popup_input_placeholder_genuin_id: 'Enter Your Genuin ID',
  dsp_add_reel_popup_input_genuin_id_empty_error: 'Genuin ID is required',
  dsp_add_reel_popup_input_genuin_id_not_exist_error: 'id is not available please create.',

  dsp_add_reel_popup_button_import_more_video: 'Import More Videos',
  dsp_add_reel_popup_button_id_create: 'Create',

  dsp_add_reel_popup_button_create: 'Create',
  dsp_add_reel_popup_button_close: 'Close',

  //Contextual Reel Details Modal
  dsp_details_reel_popup_title: 'Contextual Reel Details',
  dsp_details_reel_popup_button_edit: 'Edit',

  //Contextual Reel Update Modal
  dsp_update_reel_popup_title: 'Update Contextual Reel',
  dsp_update_reel_popup_button_update: 'Update',

  //GAM Details Modal
  dsp_details_gam_popup_title: 'GAM Details',

  //GAM Update Modal
  dsp_update_gam_popup_title: 'Update GAM',

  /***** DSP REQUEST DEAL *****/
  dsp_reqdeal_sidebar_title: 'Request Deal',
  dsp_reqdeal_sidebar_add_button: 'Add Deal',

  dsp_reqdeal_section_tab_details: 'Details',
  dsp_reqdeal_section_tab_submitted_deals: 'Submitted Deals',

  dsp_reqdeal_title: 'Create Request Deal',

  dsp_reqdeal_input_field_name_label: 'Request Deal Name *',
  dsp_reqdeal_input_field_name_placeholder: 'Enter Your Request Deal Name',
  dsp_reqdeal_input_field_name_empty_error: 'Enter your request deal name',

  dsp_reqdeal_radio_pg: 'PG',
  dsp_reqdeal_radio_pmp: 'PMP',

  dsp_reqdeal_input_field_mincpm_label: 'Min CPM *',
  dsp_reqdeal_input_field_mincpm_placeholder: 'Eg. 18.50',
  dsp_reqdeal_input_field_mincpm_empty_error: 'Enter min cpm',
  dsp_reqdeal_input_field_mincpm_valid_error: 'Enter valid min cpm',
  dsp_reqdeal_input_field_mincpm_length_error: 'Min cpm field is allow max 5-digits.',

  dsp_reqdeal_input_field_maxcpm_label: 'Max CPM *',
  dsp_reqdeal_input_field_maxcpm_placeholder: 'Eg. 35.70',
  dsp_reqdeal_input_field_maxcpm_empty_error: 'Enter max cpm',
  dsp_reqdeal_input_field_maxcpm_valid_error: 'Enter valid max cpm',
  dsp_reqdeal_input_field_maxcpm_length_error: 'Max cpm field is allow max 5-digits.',

  dsp_reqdeal_input_field_min_max_compare_error: 'Max cpm must be more than min cpm',

  dsp_reqdeal_input_field_cpm_label: 'CPM *',
  dsp_reqdeal_input_field_cpm_placeholder: 'Eg. 20.75',
  dsp_reqdeal_input_field_cpm_empty_error: 'Enter cpm',
  dsp_reqdeal_input_field_cpm_valid_error: 'Enter valid cpm',
  dsp_reqdeal_input_field_cpm_length_error: 'CPM field is allow max 5-digits.',

  dsp_reqdeal_input_field_imp_label: 'Impressions *',
  dsp_reqdeal_input_field_imp_placeholder: 'Eg. 1000000',
  dsp_reqdeal_input_field_imp_empty_error: 'Enter impressions',
  dsp_reqdeal_input_field_imp_valid_error: 'Enter valid impressions',

  dsp_reqdeal_input_field_description_label: 'Description',
  dsp_reqdeal_input_field_description_placeholder: 'Enter Deal Description Here',

  dsp_reqdeal_ddl_field_iab_category_label: 'IAB Category',

  dsp_reqdeal_checkbox_field_device_type_label: 'Device Type',
  dsp_reqdeal_checkbox_label_tv: 'TV',
  dsp_reqdeal_checkbox_label_desktop: 'Desktop',
  dsp_reqdeal_checkbox_label_mobile: 'Mobile',

  dsp_reqdeal_checkbox_field_platform_type_label: 'Platform Type',
  dsp_reqdeal_checkbox_label_ott: 'OTT',
  dsp_reqdeal_checkbox_label_ctv: 'CTV',

  dsp_reqdeal_checkbox_field_application_type_label: 'Application Type',
  dsp_reqdeal_checkbox_label_app: 'App',
  dsp_reqdeal_checkbox_label_site: 'Site',

  dsp_reqdeal_input_field_title_label: 'Title',
  dsp_reqdeal_input_field_title_placeholder: 'Enter Title',

  dsp_reqdeal_input_field_series_label: 'Series',
  dsp_reqdeal_input_field_series_placeholder: 'Enter Series',

  dsp_reqdeal_input_field_artist_label: 'Artist',
  dsp_reqdeal_input_field_artist_placeholder: 'Enter Artist',

  dsp_reqdeal_ddl_field_genre_label: 'Genre',

  dsp_reqdeal_input_field_isrc_code_label: 'ISRC Code',
  dsp_reqdeal_input_field_isrc_code_placeholder: 'Enter ISRC Code',

  dsp_reqdeal_input_field_producer_label: 'Producer Name',
  dsp_reqdeal_input_field_producer_placeholder: 'Enter Producer Name',

  dsp_reqdeal_input_field_producer_domain_label: 'Producer Domain',
  dsp_reqdeal_input_field_producer_domain_placeholder: 'Enter Producer Domain',

  dsp_reqdeal_ddl_field_product_qlt_label: 'Product Quality',
  dsp_reqdeal_ddl_field_context_label: 'Context',

  dsp_reqdeal_input_field_content_rating_label: 'Content Rating',
  dsp_reqdeal_input_field_content_rating_placeholder: 'Enter Content Rating',

  dsp_reqdeal_input_field_user_rating_label: 'User Rating',
  dsp_reqdeal_input_field_user_rating_placeholder: 'Enter User Rating',

  dsp_reqdeal_ddl_field_media_rating_label: 'Media Rating Per IQG g',

  dsp_reqdeal_input_field_keywords_label: 'Keywords',
  dsp_reqdeal_input_field_keywords_placeholder: 'Enter Keywords',

  dsp_reqdeal_input_field_keyword_array_label: 'Keyword Array',
  dsp_reqdeal_input_field_keyword_array_placeholder: 'Enter Keyword Array',

  dsp_reqdeal_ddl_field_livestream_label: 'Live Stream',
  dsp_reqdeal_ddl_field_source_label: 'Source Relationship',

  dsp_reqdeal_input_field_content_length_label: 'Content Length',
  dsp_reqdeal_input_field_content_length_placeholder: 'Enter Content Length',

  dsp_reqdeal_input_field_lan_code_label: 'Language',
  dsp_reqdeal_input_field_lan_code_placeholder: 'Enter Language Code',

  dsp_reqdeal_ddl_field_embeddable_label: 'Embeddable',

  dsp_reqdeal_input_field_network_name_label: 'Network Name',
  dsp_reqdeal_input_field_network_name_placeholder: 'Enter Network Name',

  dsp_reqdeal_input_field_network_domain_label: 'Network Domain',
  dsp_reqdeal_input_field_network_domain_placeholder: 'Enter Network Domain',

  dsp_reqdeal_input_field_channel_name_label: 'Channel Name',
  dsp_reqdeal_input_field_channel_name_placeholder: 'Enter Channel Name',

  dsp_reqdeal_input_field_channel_domain_label: 'Channel Domain',
  dsp_reqdeal_input_field_channel_domain_placeholder: 'Enter Channel Domain',

  dsp_reqdeal_ddl_field_publishers_label: 'Publishers',

  dsp_reqdeal_button_close: 'Close',
  dsp_reqdeal_button_create: 'Create',
  dsp_reqdeal_button_create_loading: 'Creating...',

  // DSP Deal Update Modal

  dsp_update_reqdeal_title: 'Edit Request Deal',
  dsp_update_reqdeal_button_update: 'Update',
  dsp_update_reqdeal_button_update_loading: 'Updating...',

  //Offer Deal Approve
  dsp_offerdeal_title: 'New Deal Offer',
  dsp_offerdeal_msg_one: 'has offer your',
  dsp_offerdeal_button_review_inventories: 'Review Inventories',
  dsp_offerdeal_button_approve: 'Approve',
  dsp_offerdeal_approve_success_msg: 'Offered deal approved successfully.',

  //Request Deal Data
  dsp_reqdeal_data_empty_msg: 'No Data Found',

  //Dashboard
  dsp_dash_tab_performance: 'Performance',
  dsp_dash_tab_traffic: 'Traffic',

  dsp_dash_traffic_toggle_label_filter: 'Filter',
  dsp_dash_traffic_toggle_label_chart: 'Chart',
  dsp_dash_traffic_input_field_placeholder_search: 'Search Inventories Data',

  ///////////////////////////// SPO DASHBOARD /////////////////////////////

  //Sidebar
  spo_dash_sidebar_expand_column_name: 'Name',
  spo_dash_sidebar_expand_column_created: 'Created',
  spo_dash_sidebar_expand_column_impression: 'Impressions',
  spo_dash_sidebar_expand_column_earnings: 'Earnings',
  spo_dash_sidebar_expand_column_fillrate: 'Fill Rate',
  spo_dash_sidebar_expand_column_status: 'Status',

  spo_sidebar_card_field_label_since: 'Since',
  spo_sidebar_card_field_label_impression: 'Impression',
  spo_sidebar_card_field_label_earnings: 'Earnings',

  spo_invite_popup_filter_title: 'Discover By',
  spo_invite_popup_filter_field_country: 'Country',
  spo_invite_popup_filter_field_technology: 'Technology',
  spo_invite_popup_filter_field_keyword: 'Keywords',
  spo_invite_popup_filter_field_category: 'Category',

  spo_invite_popup_title: 'Invite Publisher',
  spo_invite_popup_selected_publisher: 'Selected Publishers',
  spo_invite_popup_selected_agencies: 'Selected Agencies',
  spo_invite_table_column_remove: 'Remove',
  spo_invite_table_column_name: 'Name',
  spo_invite_table_column_domain: 'Domain',

  //sidebar_sorting options
  spo_dash_sidebar_sorting_option_name: 'Name',
  spo_dash_sidebar_sorting_option_created: 'Created',
  spo_dash_sidebar_sorting_option_impression: 'Impression',
  spo_dash_sidebar_sorting_option_earnings: 'Earnings',
  spo_dash_sidebar_sorting_option_fillrate: 'Fill Rate',
  spo_dash_sidebar_sorting_option_status: 'Status',

  //Invite Modal
  spo_invite_modal_tab_title1: 'Your Connection',
  spo_invite_modal_tab_title2: 'Publisher Library',
  spo_invite_modal_tab_title2_agencies: 'Agencies Library',

  spo_invite_popup_input_field_label_company_name: 'Company Name *',
  spo_invite_popup_input_company_name_placeholder: 'Enter Company Name Here',
  spo_invite_popup_input_company_name_empty_error: 'Company name is required',

  spo_invite_popup_input_field_label_first_name: 'First Name *',
  spo_invite_popup_input_first_name_placeholder: 'Enter First Name Here',
  spo_invite_popup_input_first_name_empty_error: 'First name is required',

  spo_invite_popup_input_field_label_last_name: 'Last Name *',
  spo_invite_popup_input_last_name_placeholder: 'Enter Last Name Here',
  spo_invite_popup_input_last_name_empty_error: 'Last name is required',

  spo_invite_popup_input_field_label_email: 'Email *',
  spo_invite_popup_input_email_placeholder: 'Enter Email Here',
  spo_invite_popup_input_email_empty_error: 'Email is required',
  spo_invite_popup_input_email_valid_error: 'Enter valid email',
  spo_invite_popup_input_email_same_email_login_error:
    'Not allow to send invitation with same login email address.',

  spo_invite_popup_button_invite: 'Invite',
  spo_invite_popup_button_cancel: 'Cancel',
  spo_invite_popup_instruction: 'Selected Agencies will receive a connection invite from',
  spo_publisher_invite_popup_instruction:
    'Selected Publishers will receive a connection invite from',

  spo_invited_ssp_title: 'Invited',
  spo_invited_ssp_description: `An invitation has been sent to your partner. After successfully completing the
  onboard process with us, you will have access to associated demand.`,

  spo_invited_spo_title: 'Invited',
  spo_invited_spo_description: `An invitation has been sent to your partner. After successfully completing the
  onboard process with us, you will have access to associated inventories.`,

  spo_invited_csv_table_title: 'CSV Agencies List',
  spo_invited_csv_upload_label: 'Bulk Upload',
  spo_invited_csv_button_upload: 'Upload CSV',
  spo_invited_csv_button_import: 'Import CSV',
  spo_invited_csv_button_sample: 'Sample CSV',

  ///////////////////////////// ADMIN DASHBOARD /////////////////////////////

  //Sidebar
  admin_dash_sidebar_sorting_option_name: 'Name',
  admin_dash_sidebar_sorting_option_created: 'Created',
  admin_dash_sidebar_sorting_option_earnings: 'Earnings',
  admin_dash_sidebar_sorting_option_fill_rate: 'Fill Rate',

  // Sidebar Filter Tabs
  admin_sidebar_search_placeholder: 'Search Keywords',
  admin_sidebar_tab_button_all: 'All',
  admin_sidebar_tab_button_demand: 'DSP',
  admin_sidebar_tab_button_publisher: 'PUB',
  admin_sidebar_tab_button_spo: 'SPO',
  admin_sidebar_tab_button_creative: 'CR',

  admin_header_button_finance: 'Finance',
  admin_header_field_label_payout: 'Payout',
  admin_header_field_label_spending: 'Spending',
  admin_header_field_label_earnings: 'Earnings',

  //Sidebar Card
  admin_sidebar_card_field_label_since: 'Since',

  admin_sidebar_dsp_card_field_label_type_demand: 'Demand',
  admin_sidebar_dsp_card_field_label_type_supply: 'Supply',
  admin_sidebar_dsp_card_field_label_bids: 'Bids',
  admin_sidebar_dsp_card_field_label_spending: 'Spending',

  admin_sidebar_ssp_card_field_label_impressions: 'Impressions',
  admin_sidebar_ssp_card_field_label_earnings: 'Earnings',
  admin_sidebar_ssp_req_card_field_label_traffic: 'Traffic',
  admin_sidebar_ssp_req_card_button_view: 'View',
  admin_sidebar_ssp_req_card_field_label_date: 'Date',

  //Sidebar Expanded
  admin_sidebar_expand_column_name: 'Name',
  admin_sidebar_expand_column_created: 'Created',
  admin_sidebar_expand_column_impressions: 'Impressions',
  admin_sidebar_expand_column_earnings: 'Earnings',
  admin_sidebar_expand_column_fill_rate: 'Fill Rate',
  admin_sidebar_expand_column_status: 'Status',

  //Admin Customer Invite Popup
  admin_invite_popup_title: 'Invite',
  admin_invite_popup_radio_button_publisher: 'Publisher',
  admin_invite_popup_radio_button_dsp: 'DSP',

  admin_invite_popup_input_field_label_company_name: 'Company Name *',
  admin_invite_popup_input_company_name_placeholder: 'Enter Text Here',
  admin_invite_popup_input_company_name_empty_error: 'Enter company name',

  admin_invite_popup_input_field_label_first_name: 'First Name *',
  admin_invite_popup_input_first_name_placeholder: 'Enter Text Here',
  admin_invite_popup_input_first_name_empty_error: 'Enter first name',

  admin_invite_popup_input_field_label_last_name: 'Last Name',
  admin_invite_popup_input_last_name_placeholder: 'Enter Text Here',
  admin_invite_popup_input_last_name_empty_error: 'Enter last name',

  admin_invite_popup_input_field_label_email: 'Email *',
  admin_invite_popup_input_email_placeholder: 'Enter Text Here',
  admin_invite_popup_input_email_empty_error: 'Enter an email',
  admin_invite_popup_input_email_valid_error: 'Enter valid email',

  admin_invite_popup_input_field_label_password: 'Password',
  admin_invite_popup_input_password_placeholder: '*****',

  admin_invite_popup_field_label_password_change: 'Password Change',
  admin_invite_popup_checkbox_label_on_first_login: 'On First Login',
  admin_invite_popup_checkbox_label_msa: 'MSA Bypass',

  admin_invite_popup_button_invite: 'Invite',
  admin_invite_popup_button_cancel: 'Cancel',
  admin_invite_customer_exist_error: 'Customer already exists.',

  //Admin Customer Invite Successfully Popup
  admin_invite_success_popup_title: 'Customer Created',
  admin_invite_success_popup_instructions: 'Invitation has sent successfully.',
  admin_invite_success_popup_button_done: 'Done',

  //Admin Dashboard
  admin_dash_tab_performance: 'Performance',
  admin_dash_tab_customer_details: 'Customer Details',

  //Admin Performance Tab
  admin_prf_tab_empty_graph_text: 'No Data Available',

  //Admin Customer Details Tab
  admin_cus_detail_tab_title: 'Customer Detail',
  admin_cus_detail_tab_field_label_customer_id: 'Customer ID',
  admin_cus_detail_tab_field_label_admin: 'Admin',
  admin_cus_detail_tab_field_label_since: 'Since',
  admin_cus_detail_tab_field_label_publisher: 'Publisher',
  admin_cus_detail_tab_field_label_demand: 'Demand',
  admin_cus_detail_tab_field_label_country: 'Country',
  admin_cus_detail_tab_field_label_users: 'Users',

  admin_cus_detail_tab_earning_update_success_msg: 'Earnings Update',

  //Admin > Customer Detail > Earning Section > For Demand
  admin_dsp_cus_detail_tab_title: 'Floor Price Range:',
  admin_dsp_cus_detail_tab_input_field_label_min: 'Min Floor Price',
  admin_dsp_cus_detail_tab_input_min_placeholder: 'Example: 10',

  admin_dsp_cus_detail_tab_input_field_label_max: 'Max Floor Price',
  admin_dsp_cus_detail_tab_input_max_placeholder: 'Example: 10',

  admin_dsp_cus_detail_tab_min_max_compare_error: 'Max must be more than Min.',
  admin_dsp_cus_detail_tab_floor_range_button_update: 'Update',

  // Admin > Customer Detail > Earning Section > For Publisher
  admin_ssp_cus_detail_tab_link_copy_icon_title: 'Copy Link',
  admin_ssp_cus_detail_tab_link_copied_msg: 'Copied',
  admin_ssp_cus_detail_tab_button_invite: 'Invite',
  admin_ssp_cus_detail_tab_button_re_invite: 'Re-Invite',
  admin_ssp_cus_detail_tab_field_label_publisher_margin: 'Publisher Margin:',
  admin_ssp_cus_detail_tab_field_input_margin_placeholder: 'Example: 20',
  admin_ssp_cus_detail_tab_margin_button_update: 'Update',

  //Admin > Publisher > Customer Detail > Invite Button
  admin_ssp_invite_popup_title: 'Invite a Customer',
  admin_ssp_invite_popup_instruction: 'Are you sure you want to invite this Customer?',
  admin_ssp_invite_popup_button_cancel: 'Cancel',
  admin_ssp_invite_popup_button_invite: 'Invite',
  admin_ssp_invite_success_msg: 'Customer Invited',

  //Admin Customer Detail Pending Request
  admin_customer_ssp_req_field_label_company: 'Company Name',
  admin_customer_ssp_req_field_label_name: 'Name',
  admin_customer_ssp_req_field_label_email: 'Official Email',
  admin_customer_ssp_req_field_label_name_of: 'Name of',
  admin_customer_ssp_req_field_label_name_of_publisher: 'Publisher',
  admin_customer_ssp_req_field_label_name_of_demand: 'Demand',
  admin_customer_ssp_req_field_label_avg_traffic: 'Average Traffic',
  admin_customer_ssp_req_field_label_web_url: 'Website URL',
  admin_customer_ssp_req_button_approve: 'Approve',

  //Admin Finance Section
  admin_finance_tab_label_payables: 'Payables',
  admin_finance_tab_label_platformcost: 'Platform Cost',
  admin_finance_tab_label_receivables: 'Receivables',
  admin_finance_tab_label_earnings: 'Earnings',

  admin_finance_tab_input_field_search_placeholder: 'Search',

  //////////// Admin Inventory //////////////////////
  admin_inv_header_tab_open_exchange: 'Open Exchange',
  admin_inv_header_tab_pmp_deals: 'PMP Deals',

  //Admin Invetory Sidebar
  admin_inv_sidebar_title: 'Search',
  admin_inv_sidebar_ddl_label_iab_category: 'IAB Category',
  admin_inv_sidebar_field_label_device_type: 'Device Type',
  admin_inv_sidebar_checkbox_tv: 'TV',
  admin_inv_sidebar_checkbox_desktop: 'Desktop',
  admin_inv_sidebar_checkbox_mobile: 'Mobile',
  admin_inv_sidebar_ddl_label_platform: 'Platform',
  admin_inv_sidebar_input_field_label_lower_floor: 'Lower CPM',
  admin_inv_sidebar_input_lower_floor_placeholder: 'Example: $6.92',
  admin_inv_sidebar_input_field_label_upper_floor: 'Upper CPM',
  admin_inv_sidebar_input_upper_floor_placeholder: 'Example: $20.5',
  admin_inv_sidebar_ddl_label_countries: 'Countries',
  admin_inv_sidebar_ddl_label_states: 'States',
  admin_inv_sidebar_ddl_label_cities: 'Cities',
  admin_inv_sidebar_ddl_label_sources: 'Sources',
  admin_inv_sidebar_button_submit: 'Apply Filter',

  admin_inv_toggle_button_label_chart: 'Chart',
  admin_inv_button_create_pmp_deal: 'Create PMP Deal',
  admin_inv_ddl_button_label_download: 'Download',
  admin_inv_ddl_option_publisher_with_inventories: 'Publisher with Inventories',
  admin_inv_ddl_option_only_inventories: 'Only Inventories',
  admin_inv_ddl_option_only_publishers: 'Only Publisher',
  admin_inv_input_inventory_search_placeholder: 'Search Inventory Data',
  admin_inv_nodata_error_msg: 'Data Not Found',

  //Admin > Create PMP Deal Popup
  admin_create_deal_popup_title: 'Create a Deal',
  admin_create_deal_popup_input_field_label_name: 'Name',
  admin_create_deal_popup_input_name_placeholder: 'Enter Deal Name',
  admin_create_deal_popup_input_name_empty_error: 'Enter Name',
  admin_create_deal_popup_input_field_label_cpm: 'CPM',
  admin_create_deal_popup_input_cpm_placeholder: 'Ex. $1.5',
  admin_create_deal_popup_input_cpm_empty_error: 'Enter CPM',
  admin_create_deal_popup_input_cpm_valid_error: 'Enter valid cpm',
  admin_create_deal_popup_input_field_label_description: 'Description',
  admin_create_deal_popup_input_description_placeholder: 'Enter Description',
  admin_create_deal_popup_button_cancel: 'Cancel',
  admin_create_deal_popup_button_create: 'Create',
  admin_create_deal_popup_not_selected_inv_error: 'Select inventories for create deal.',
  admin_create_deal_success_msg: 'Deal successfully created.',

  //Admin > PMP Deals TAB
  admin_deal_sidebar_title: 'Deals',

  //Admin Deal Card
  admin_deal_card_field_label_id: 'ID',
  admin_deal_card_field_label_cpm: 'CPM',
  admin_deal_card_field_label_publishers: 'Publishers',

  //Admin Deal Dashboard
  admin_deal_tab_inventory: 'Inventory',
  admin_deal_tab_customers: 'Customers',

  admin_deal_inventory_edit_icon_title: 'Edit',
  admin_deal_inventory_delete_icon_title: 'Delete',

  admin_deal_inventory_toggle_button_filter: 'Filter',
  admin_deal_inventory_toggle_button_chart: 'Chart',
  admin_deal_inventory_button_download_xsl: 'Download XSL',

  //Admin > PMP Deals > Edit Popup
  admin_edit_deal_popup_title: 'Edit a Deal',

  admin_edit_deal_popup_ddl_label_iab_category: 'IAB Category',
  admin_edit_deal_popup_field_label_device_type: 'Device Type',
  admin_edit_deal_popup_device_checkbox_tv: 'TV',
  admin_edit_deal_popup_device_checkbox_desktop: 'Desktop',
  admin_edit_deal_popup_device_checkbox_mobile: 'Mobile',
  admin_edit_deal_popup_field_label_platform_type: 'Platform Type',
  admin_edit_deal_popup_device_checkbox_ott: 'OTT',
  admin_edit_deal_popup_device_checkbox_ctv: 'CTV',
  admin_edit_deal_popup_ddl_label_countries: 'Countries',
  admin_edit_deal_popup_ddl_label_states: 'States',
  admin_edit_deal_popup_ddl_label_cities: 'Cities',
  admin_edit_deal_popup_field_label_duration: 'Duration',

  admin_edit_deal_popup_button_edit: 'Save',
  admin_edit_deal_success_msg: 'Deal update successfully.',

  //Admin > PMP Deals > Delete Popup
  admin_deal_delete_popup_title: 'Delete a Deal',
  admin_deal_delete_popup_instruction: 'Are you sure you want to delete this deal?',
  admin_deal_delete_popup_button_cancel: 'Cancel',
  admin_deal_delete_popup_button_delete: 'Delete',
  admin_deal_delete_popup_succss_message: 'Deal successfully deleted.',

  //Admin > PMP Deals > Customers Tab
  admin_deal_customer_button_add_customer: 'Add Customer',

  admin_deal_customer_nocustomer_error_msg: 'Customer Not Found',
  admin_deal_customer_nocustomer_instruction: "Click on 'Add Customer' button and add customers.",

  //Admin > PMP Deals > Customers Tab > Add Customer Popup
  admin_deal_customer_add_popup_title: 'Add a Customer',
  admin_deal_customer_add_popup_field_label_customer_id: 'Customer ID',
  admin_deal_customer_add_popup_field_label_customer_name: 'Customer Name',
  admin_deal_customer_add_popup_button_cancel: 'Cancel',
  admin_deal_customer_add_popup_button_add: 'Add',

  admin_deal_customer_add_popup_all_customer_selected_error: 'Customer Not Found',
  admin_deal_customer_add_popup_nocustomer_error: "Don't have any customer",

  //Admin Traffic Dashboard
  admin_traffic_dash_toggle_button_dsp_view: 'DSP View',
  admin_traffic_dash_button_refresh: 'Refresh',
  admin_traffic_dash_text_button_utc_timezone: 'UTC Timezone',
  admin_traffic_table_input_search_field_placeholder: 'Search Supply/Demand Partner',

  //***** Reports Brandsafety Modal *****//
  brand_safety_modal_title: 'Brand Safety',
  brand_safety_toggle_label: 'Whitelist/Blacklist',
  brand_safety_radio_label_whitelist: 'Whitelist',
  brand_safety_radio_label_blacklist: 'Blacklist',
  brand_safety_whitelist_inst:
    'By choosing this option, all advertiser domains will be included for serving.',
  brand_safety_blacklist_inst:
    'By selecting this option, all advertiser domains will be removed from the serving process.',
  brand_safety_modal_inst_heading: 'Upload CSV',
  brand_safety_modal_inst_one: 'Download',
  brand_safety_modal_inst_two: 'Sample CSV',
  brand_safety_modal_inst_three: 'and editing that CSV file and upload it.',
  brand_safety_list_header_adv_domain: 'Advertiser Domain',
  brand_safety_list_header_remove: 'Remove',
  brand_safety_button_upload_csv: 'Upload CSV',
  brand_safety_button_sample_csv: 'Sample CSV',
  brand_safety_button_close: 'Close',
  brand_safety_button_apply: 'Apply',
  brand_safety_loading_button: 'Applying...',
  brand_safety_whitelist_success_msg: 'Added all advertiser whitelisted successfully.',
  brand_safety_blacklist_success_msg: 'Added all advertiser blacklisted successfully.',

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  // Customers Row Context Menu
  settings_users_row_contextmenu_option_one: 'Remove',
  settings_users_row_contextmenu_option_two: 'Approve',
  settings_users_row_contextmenu_option_three: 'Access Control',

  settings_remove_user_popup_title: 'Delete a User',
  settings_remove_user_popup_instruction: 'Are you sure you want to delete this User?',
  settings_remove_user_popup_button_one: 'Cancel',
  settings_remove_user_popup_button_two: 'Delete',

  settings_access_control_popup_field_label_one: 'User Role',
  settings_access_control_popup_ddl_option_one: 'Admin',
  settings_access_control_popup_ddl_option_two: 'Manager',
  settings_access_control_popup_ddl_option_three: 'Editor',
  settings_access_control_popup_field_label_two: 'Company Settings',
  settings_access_control_popup_field_two_radio_button_text_one: 'None',
  settings_access_control_popup_field_two_radio_button_text_two: 'View',
  settings_access_control_popup_field_two_radio_button_text_three: 'Edit',
  settings_access_control_popup_field_label_three: 'Finance',
  settings_access_control_popup_field_three_radio_button_text_one: 'None',
  settings_access_control_popup_field_three_radio_button_text_two: 'View',
  settings_access_control_popup_field_three_radio_button_text_three: 'Edit',
  settings_access_control_popup_field_label_four: 'Customers',
  settings_access_control_popup_field_four_radio_button_text_one: 'View',
  settings_access_control_popup_field_four_radio_button_text_two: 'Edit',
  settings_access_control_popup_field_label_five: 'Customer Access',
  settings_access_control_popup_field_five_radio_button_text_one: 'Assigned',
  settings_access_control_popup_field_five_radio_button_text_two: 'All',
  settings_access_control_popup_button_one: 'Cancel',
  settings_access_control_popup_button_two: 'Done',

  // Header Profile Dropdown Menu
  admin_header_ddl_option_one: 'Inventory',
  admin_header_ddl_option_two: 'Traffic',
  admin_dsp_ssp_header_ddl_option_one: 'Settings',
  admin_dsp_ssp_header_ddl_option_two: 'Logout',

  //Publisher Demand traffic table
  ssp_demand_table_traffic_allocation:
    'Traffic Allocation % defines maximum amount of traffic capping for this Demand Source. Actual Traffic Allocation may be lesser depending upon serving from this Demand Source.',
  ssp_demand_table_Actual_traffic_allocation:
    '2% is actual traffic allocation decided by our platform based on serving',

  //Ads.txt Delete Modal
  dsp_setting_adstxt_remove_popup_title: 'Delete a Ads.txt',
  dsp_setting_adstxt_remove_popup_body: 'Are you sure you want to delete this Ads.txt?',

  //Delete modal for assigned deals
  ssp_assigned_deal_delete_popup_title: 'Delete a Deal',
  ssp_assigned_deal_delete_popup_body: 'Are you sure you want to delete this deal?',

  //Delete modal for customer deals
  ssp_assigned_customer_delete_popup_title: 'Delete a Customer',
  ssp_assigned_customer_delete_popup_body: 'Are you sure you want to delete this customer?',

  //addreel component
  addreel_modal_reel_name_label: 'Reel Name*',
  addreel_modal_reel_name_error: 'Enter your reel name.',
  addreel_modal_reel_name_placeholder: 'Enter Your Reel Name',
  addreel_modal_loop_share_label: 'Loop Share',
  addreel_modal_loop_share_placeholder: 'Enter Loop Share.',
  addreel_modal_loop_share_error: 'Loop Share is required when Feed Type is "Loop Table".',
  addreel_modal_loop_share_format_error:
    'Invalid format. Must be 14 to 16 characters, consisting of lowercase letters and digits.',
  addreel_modal_CTA_link_label: 'CTA Link',
  addreel_modal_CTA_link_error: 'Must be a valid URL',
  addreel_modal_CTA_link_placeholder: 'Enter Your CTA Link',
  addreel_modal_CTA_text_label: 'CTA Text',
  addreel_modal_CTA_text_placeholder: 'Enter Your CTA Text',
  addreel_modal_hr_feed_configure_label: 'Feed Configure',
  addreel_modal_radio_action_performed_label: 'Action performed on click',
  addreel_modal_radio_action_full_screen_label: 'Full Screen',
  addreel_modal_radio_action_modal_label: 'Modal',
  addreel_modal_toggle_to_show_question_modal: 'To Show question modal',
  addreel_modal_toggle_show_comment_icon: 'Show Comment Icon',
  addreel_modal_toggle_show_botton_CTA: 'Show bottom CTA',
  addreel_modal_toggle_show_logo: 'Show Logo',
  addreel_modal_toggle_show_videos_owner_details: 'Show videos owner details',
  addreel_modal_toggle_show_reply_icon: 'Show Reply icon',
  addreel_modal_toggle_show_report_icon: 'Show Report icon',
  addreel_modal_toggle_show_repost_icon: 'Show Repost Icon',
  addreel_modal_toggle_show_save_icon: 'Show Save icon',
  addreel_modal_toggle_show_share_icon: 'Show Share icon',
  addreel_modal_toggle_show_videos_web_link: 'Allow to Show Videos Web link',
  addreel_modal_domain_configure_label: 'Domain Configure',
  addreel_modal_list_videos_url_label: 'List of URLs which we want to show as a feed',
  addreel_modal_list_videos_url_placeholder: 'Enter Videos URL',
  addreel_modal_list_videos_url_error_1: 'Video is required when Feed Type is "Added Video".',
  addreel_modal_list_videos_url_error_2: 'Enter valid list URL.',
  addreel_modal_list_videos_uuid_label:
    'UUIDs of the video which will be used for the feed type as a fixed',
  addreel_modal_list_videos_uuid_placeholder: 'Enter UUIDs',
  addreel_modal_list_videos_uuid_error_1: 'Enter valid UUIDs.',
};
