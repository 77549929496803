import { useContext, useEffect, useState } from 'react';

import Input from '../../widgets/input';
import Button from '../../widgets/button';
import Password from '../../modals/password';

import store from '../../../states';
import ImageCropper from '../../helpers/ImageCrop';
import { resetTourAPI } from '../../../services/settings';
import { updateUserAPI } from '../../../services/settings';
import { UserInfoContext } from '../../contexts/userInfoContext';

export default ({ toast /*store*/ }: any) => {
  // const inputFile = useRef<HTMLInputElement>(null);

  // Context API
  const { userInfo, setUserInfo }: any = useContext(UserInfoContext);

  const [firstName, sFirstName] = useState(userInfo?.user?.firstname ?? '');
  const [lastName, sLastName] = useState(userInfo?.user?.lastname ?? '');
  const [passwordModal, sPasswordModal] = useState(false);
  const [isTourReseting, setIsTourReseting] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const updateProfileValues = (key: string, value: any) => {
    if (key === 'profileFirstName') sFirstName(value);
    if (key === 'profileLastName') sLastName(value);
  };

  const updateUserDetails = () => {
    const payload = {
      firstname: firstName ?? '',
      lastname: lastName ?? '',
      username: '',
      //email_settings: [],
    };
    updateUserAPI(payload)
      .then((data: any) => {
        if (data.status) {
          setUserInfo((prev: any) => ({
            ...prev,
            user: {
              ...prev.user,
              firstname: firstName,
              lastname: lastName,
            },
          }));
          sFirstName(data.user.firstname);
          sLastName(data.user.lastname);
        }
      })
      .catch(() => {
        //
      });
  };

  useEffect(() => {
    if (userInfo) {
      sFirstName(userInfo?.user?.firstname);
      sLastName(userInfo?.user?.lastname);
    }
  }, [userInfo]);

  const resetTour = () => {
    setIsTourReseting(true);
    resetTourAPI()
      .then((data: any) => {
        if (data.status === true) {
          setUserInfo((prev: any) => ({
            ...prev,
            user: {
              ...prev.user,
              tour: '',
            },
          }));
          toast.success('Tour Reset');
        }
        setTimeout(() => {
          setIsTourReseting(false);
        }, 300);
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  return (
    <div className={'row'}>
      <Password
        show={passwordModal}
        onHide={() => {
          sPasswordModal(false);
        }}
      />
      <div className={'row'} style={{ justifyContent: 'space-between' }}>
        <div className={'col'}>
          <h2 style={{ fontWeight: 200 }}>{glossary.setting_profile_title}</h2>
        </div>
      </div>

      <div className={'col profile__main'}>
        <div className={'row'}>
          <div className={'col col-auto'}>
            <div className={'profile__main__image me-4'}>
              <div className='profile__main__image__icon 234'>
                <ImageCropper />
                <div className='profile__main__image__icon__inner d-none'></div>
              </div>
            </div>
          </div>
          <div className={'col'}>
            <div className={'row align-items-baseline flex-nowrap mt-5 mt-lg-0 profile_lfname'}>
              <div className={'col-4'}>
                <Input
                  name={'profileFirstName'}
                  kind={'mini'}
                  type={'text'}
                  length={64}
                  change={updateProfileValues}
                  blur={updateUserDetails}
                  label={glossary.setting_profile_input_field_label_first_name}
                  value={firstName}
                  placeholder={glossary.setting_profile_input_field_first_name_placeholder}
                />
              </div>
              <div className={'col-4'} style={{ rowGap: 10 }}>
                <div className={'d-flex flex-column'}>
                  <Input
                    name={'profileLastName'}
                    kind={'mini'}
                    type={'text'}
                    length={20}
                    change={updateProfileValues}
                    label={glossary.setting_profile_input_field_label_last_name}
                    blur={updateUserDetails}
                    value={lastName}
                    placeholder={glossary.setting_profile_input_field_last_name_placeholder}
                  />
                </div>
                <br />
              </div>
            </div>
            {localStorage.getItem('r') === 'customer' && (
              <div className={'row align-items-baseline flex-nowrap mt-5 mt-lg-0'}>
                <div className={'col-auto'}>
                  <label className='label me-4'>Tour:</label>
                  {isTourReseting ? (
                    <Button kind={'spinner'} type={'button'} text={'Resetting...'} />
                  ) : (
                    <Button kind={'primary'} text={'Reset'} click={resetTour} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className='mt-5' />
        <div className={'row flex-column flex-lg-row'}>
          <div className={'col py-3'}>
            <div style={{ width: 'fit-content' }}>
              <label>{glossary.setting_profile_field_label_email}</label>
              <p>
                {glossary.setting_profile_field_email_text}{' '}
                <b>
                  {
                    // @ts-ignore
                    JSON.parse(localStorage.getItem('user'))?.email ?? ''
                  }
                </b>
              </p>
            </div>
          </div>
          <div className={'col py-3'}>
            <div style={{ width: 'fit-content' }}>
              <label>{glossary.setting_profile_field_label_password}</label>
              <p className='profile__main__link fw-lighter' onClick={() => sPasswordModal(true)}>
                {glossary.setting_profile_password_button_change}
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};
