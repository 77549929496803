import { useContext, useState } from 'react';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { newResolutionOpt } from '../extension/ExtOptions';
import Button from '../../widgets/button';
import CardSorting from './Creative-sortableitem';
import CreativeMobilePreview from './Creative-mobile';
import { openNewTab } from '../../helpers/functions';
import CommonEmbedCode from '../../comman/CommonEmbedCode';
import ClipboardCopyIcon from '../../comman/ClipboardCopy';

interface Props {
  tagID?: number | undefined;

  selectedVideos?: any;
  feedType?: any;
  genuinInfo: any;
  formSubmit(): void;
  getUpdatedList(videos: any): void;
}

const NewCreativeDetails = ({
  tagID,
  selectedVideos,
  feedType,
  genuinInfo,
  formSubmit,
  getUpdatedList,
}: Props) => {
  // Context API
  const { customerInfo, genuin }: any = useContext(UserInfoContext);
  const [resolution, setResolution] = useState({
    label: '300 x 600 pixels',
    value: '300 x 600 pixels',
    width: '300px',
    height: '600px',
  });

  // const desktopScreen = ['720 x 300 pixels', '160 x 600 pixels', '120 x 600 pixels'];
  // const activeTab: any = desktopScreen?.includes(resolution?.value) ? 'desktop' : 'mobile';

  const [activeDevice, setActiveDevice] = useState<any>('mobile');

  const handleDevice = (_type: string) => {
    if (_type !== activeDevice) {
      setActiveDevice(_type);
    }
  };

  const embedCode = CommonEmbedCode({
    tagId: tagID,
    companyId: customerInfo?.id,
    height: resolution.height,
    width: resolution.width,
  });

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div
            className='createtag-vasturl'
            style={{ borderRadius: '4px', minHeight: 'auto', height: 'auto', cursor: 'initial' }}
          >
            <pre className='m-0' style={{ fontSize: '12px' }}>
              {embedCode}
            </pre>
            <div className='createtag-vasturl-icon'>
              <ClipboardCopyIcon content={embedCode} />
            </div>
          </div>
          {/* <a
            href='https://help.infy.tv/en/articles/8413771-how-to-integrate-reel-creative-html-code'
            target='_blank'
            rel='noreferrer'
            className='mt-1'
            style={{ fontSize: '14px' }}
          >
            How to integrate HTML code?
          </a> */}
        </div>
      </div>

      <hr />

      {/* Device Selection */}
      {/* Demo Page Prod URL: https://demo.begenuin.com/demo/demo.html?tag_id=2508 */}

      <div className='d-flex py-1' style={{ backgroundColor: '#f0f0f0' }}>
        <div className='w-25'>
          <Button
            text='Preview Demo'
            kind='secondary'
            click={() => openNewTab(`${genuin.demoPageURL}?tag_id=${tagID}`)}
            customClass='ms-3'
          />
        </div>
        <div className='w-50 d-flex justify-content-between'>
          <Button
            text='Mobile'
            kind={activeDevice === 'mobile' ? 'primary' : 'secondary'}
            click={() => handleDevice('mobile')}
          />
          <Button
            text='Tablet'
            kind={activeDevice === 'tablet' ? 'primary' : 'secondary'}
            click={() => handleDevice('tablet')}
          />
          <Button
            text='Desktop'
            kind={activeDevice === 'desktop' ? 'primary' : 'secondary'}
            click={() => handleDevice('desktop')}
          />
        </div>
        <div className='w-25'></div>
      </div>

      <div className='row creative-details mt-3'>
        {/* Tag Sizes */}
        <div className='col-auto sizes-section'>
          <h4 className='mb-2'>Sizes</h4>
          <div className='d-flex flex-column gap-2'>
            {newResolutionOpt.map((size: any, i: any) => (
              <Button
                key={i}
                click={() => {
                  if (resolution?.label !== size?.label) {
                    setResolution(size);
                  }
                }}
                type='button'
                text={size?.label}
                kind={resolution?.label === size?.label ? 'primary' : 'secondary'}
                css={{
                  maxWidth: '150px',
                }}
              />
            ))}
          </div>
        </div>

        {/* Video Preview */}
        <div className='col align-items-center justify-content-start'>
          <CreativeMobilePreview
            companyID={customerInfo?.id}
            tagID={tagID}
            resolution={resolution}
            device={activeDevice}
          />
        </div>

        {/* Video Sequence */}
        {feedType === 'fixed' && selectedVideos?.length > 0 && (
          <div className='col col-3 justify-content-start position-relative'>
            <CardSorting
              cardList={selectedVideos.map((x: any, i: any) => ({ id: `${i}`, ...x }))}
              formSubmit={formSubmit}
              getUpdatedList={(_videos: any) => getUpdatedList(_videos)}
              genuinInfo={genuinInfo}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NewCreativeDetails;
