import { useState } from 'react';
import Button from '../../widgets/button';
import { MdDeleteOutline, MdOutlineSync } from 'react-icons/md';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import {
  creativeAddSocialProfileAPI,
  creativeRemoveSocialProfileAPI,
  creativeSyncSocialProfileAPI,
} from '../../../services/creative';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

interface SocialProfileProps {
  instagram: any;
  tiktok: any;
  youtube: any;
  socialProfiles: any;
  setSocialProfiles(profiles: any): void;
}

const CreativeSocialProfile = ({
  instagram,
  tiktok,
  youtube,
  socialProfiles,
  setSocialProfiles,
}: SocialProfileProps) => {
  const [instahandler, setInstahandler] = useState('');
  const [instaInputError, setInstaInputError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState({ isLoading: false, type: '' });
  const [actionID, setActionID] = useState(null);

  const addHadler = (handler: string, type: string) => {
    const payload = {
      social_handler: handler.replace(/@/g, ''),
      social_type: type,
    };
    setIsSubmitting({ isLoading: true, type: type });
    creativeAddSocialProfileAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setInstahandler('');
          toast.success(data?.message);
          setSocialProfiles(data?.data);
        } else {
          toast.error(data?.message);
        }
        setIsSubmitting({ isLoading: false, type: '' });
      })
      .catch((error: any) => {
        setIsSubmitting({ isLoading: false, type: '' });
        toast.error('Something went wrong, please try later.');
        // error
      });
  };

  const removeHandler = (obj: any, type: string) => {
    const payload = {
      social_handler: obj?.handler,
      social_type: type,
    };
    setActionID(obj?.id);
    creativeRemoveSocialProfileAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setSocialProfiles(data?.data);
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
        setActionID(null);
      })
      .catch((error: any) => {
        // error
      });
  };

  const handleProfileSync = (obj: any, type: string) => {
    const payload = {
      social_handler: obj?.handler,
      social_type: type,
    };
    setActionID(obj?.id);
    creativeSyncSocialProfileAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setSocialProfiles((prev: any) => {
            const updatedProfiles = prev?.map((_obj: any) => {
              if (_obj?.id === obj?.id) {
                return data?.data;
              }
              return _obj;
            });
            return updatedProfiles;
          });
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
        setActionID(null);
      })
      .catch((error: any) => {
        // error
      });
  };

  //   const usernameRegex = /^[a-zA-Z0-9_.]{3,25}$/;
  const usernameRegex = /^@[a-zA-Z0-9_.]{3,25}$|^[a-zA-Z0-9_.]{3,25}$/;

  const instahandlers = instagram?.map((x: any) => x?.handler);

  const handleInstaHandlerChange = (e: any) => {
    const { value } = e.target;
    setInstahandler(value);
    if (usernameRegex.test(value)) {
      setInstaInputError('');
      if (instahandlers.includes(value.replace(/@/g, ''))) {
        setInstaInputError('This username is already added');
      }
    } else {
      setInstaInputError('Enter valid username');
    }
  };

  return (
    <div className='row creative-videolibrary-social'>
      <div className='col-12'>
        <div className='row mb-3'>
          <div className='col-lg-6 col-md-12 d-flex gap-3'>
            <div>
              <label className='label'>Instagram</label>
              <div className='input-group flex-nowrap'>
                <span className='input-group-text'>
                  <img
                    src='https://i.postimg.cc/mgDkLjbL/Instagram-logo-2016-svg.png'
                    width={18}
                    alt='icon'
                  />
                </span>
                <input
                  placeholder='@username'
                  className='text-input'
                  value={instahandler}
                  name={'instagram'}
                  type='text'
                  onChange={handleInstaHandlerChange}
                  maxLength={25}
                />
              </div>
              <div className='text-input-error-label small'>
                <span>{instaInputError}</span>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <label className='label'>&nbsp;</label>
              {isSubmitting?.type === 'instagram' ? (
                <Button kind='spinner' text='Adding' />
              ) : (
                <Button
                  kind={instaInputError === '' && instahandler !== '' ? 'primary' : 'disabled'}
                  text='Add'
                  type={'button'}
                  click={() => addHadler(instahandler, 'instagram')}
                />
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-12 handler-list'>
            {instagram?.map((_i: any, index: any) => (
              <div className='handler-list-item' key={index}>
                <p>{_i?.handler}</p>
                <div className='handler-list-item-action gap-2'>
                  {_i?.sync_start ? (
                    <>
                      <IoMdCheckmarkCircleOutline className='synced-icon' />
                      <p>{_i?.sync_start_formatted}</p>
                    </>
                  ) : (
                    <>
                      {_i?.id === actionID ? (
                        <span className='suggestion-input'>
                          <Spinner
                            className='mt-1'
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        </span>
                      ) : (
                        <>
                          <MdDeleteOutline
                            className='svg-icon'
                            fontSize={18}
                            title='Remove Profile'
                            onClick={() => removeHandler(_i, 'instagram')}
                          />
                          <MdOutlineSync
                            className='sync-icon'
                            title='Sync Profile'
                            onClick={() => handleProfileSync(_i, 'instagram')}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr />
      <div className='col-12 disabled'>
        <div className='row mb-3'>
          <div className='d-flex gap-3'>
            <div>
              <label className='label'>TikTok</label>
              <div className='input-group flex-nowrap'>
                <span className='input-group-text'>
                  <img
                    src='https://i.postimg.cc/nch2CmSh/Screenshot-from-2023-10-17-13-11-51.png'
                    width={18}
                    alt='icon'
                  />
                </span>
                <input placeholder='@username' className='text-input' name={'tiktok'} disabled />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <label className='label'>&nbsp;</label>
              <Button kind={'disabled'} text='Add' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 disabled'>
        <div className='row mb-3'>
          <div className='d-flex gap-3'>
            <div>
              <label className='label'>YouTube</label>
              <div className='input-group flex-nowrap'>
                <span className='input-group-text'>
                  <img src='https://i.postimg.cc/hGYvbkcY/Youtube-logo.png' width={18} alt='icon' />
                </span>
                <input placeholder='@username' className='text-input' name={'youtube'} disabled />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <label className='label'>&nbsp;</label>
              <Button kind={'disabled'} text='Add' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeSocialProfile;
