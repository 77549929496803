import React, { useEffect, useMemo, forwardRef, useRef, useState } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
  useMountedLayoutEffect,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from './scrollbarWidth';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import store from '../../states';
import { GoLinkExternal } from 'react-icons/go';

const SelectCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;
  const [checked, setChecked] = useState(rest.checked);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChecked = (event: any) => {
    setChecked(!checked);
    rest.onChange(event);
  };

  return (
    <>
      <input
        type='checkbox'
        ref={resolvedRef}
        {...rest}
        onClick={handleChecked}
        checked={checked}
      />
    </>
  );
});

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className='search-wrap'>
      {/* <label className='label mb-0 me-1'>Search: </label>{' '} */}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        //placeholder={`${count} records...`}
        placeholder={glossary.ssp_deals_input_field_seach_placeholder}
        className='form-control'
        maxLength={32}
      />
    </div>
  );
};

const Table = ({ columns: userColumns, data, onSelectedRowsChange, isAdmin }: any) => {
  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    //selectedRowIds,
    totalColumnsWidth,
    state: { selectedRowIds },
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      defaultColumn,
      //   getSubRows: (row) => row.subRows || [],
      initialState: {
        //@ts-ignore
        selectedRowIds: data?.map((x: any) => x?.check_box), // IDs of the rows you want to initially select
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>
              <SelectCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <SelectCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          width: 40,
        },
        ...columns,
      ]);
    }
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <div {...cell.getCellProps()} className='td' key={index} title={cell.value}>
                <div
                  className={
                    cell?.column?.Header === 'Name/Bundle' ||
                    cell?.column?.Header === 'Inventory ID'
                      ? 'macros-truncate'
                      : ''
                  }
                >
                  {cell.render('Cell')}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );

  useMemo(() => {
    onSelectedRowsChange(selectedFlatRows.flatMap((item: any) => item?.original?.a_id));
  }, [selectedFlatRows]);

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className='table-wrap mt-0'>
        <div {...getTableProps()} className='table inventories mb-0'>
          <div className='table-header'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className='th'
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                        ) : (
                          <IoMdArrowDropup className='svg-icon' fontSize={20} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{
                width: '100%',
                height: isAdmin ? `calc(100vh - 325px)` : `calc(100vh - 390px)`,
                overflowX: 'hidden',
              }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </div>
    </>
  );
};

const NewInvTable = ({ data, handleSelect, isAdmin }: any) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name/Bundle',
        accessor: 'name',
        width: 100,
        Cell: ({ row }: any) => (
          <>
            <div className='d-flex align-items-center gap-2'>
              {row?.original?.url && (
                <a href={`http://${row?.original?.url}`} target='_blank' rel='noreferrer'>
                  <GoLinkExternal className='svg-icon' fontSize={16} title='Redirect URL' />
                </a>
              )}
              <p className='macros-truncate'>{row?.original?.name}</p>
            </div>
          </>
        ),
      },
      {
        Header: 'Inventory ID',
        accessor: 'inventory_id',
        width: 100,
      },
      {
        Header: 'Platform Type',
        accessor: 'platform',
        width: 120,
      },
      {
        Header: 'Unique',
        accessor: 'unique',
        width: 100,
      },
      {
        Header: 'Daily Impression',
        accessor: 'impressions',
        width: 100,
      },
      {
        Header: 'Observed CPM',
        accessor: 'avg_cpm_formatted',
        width: 100,
      },
    ],
    []
  );

  const adminColumns = useMemo(
    () => [
      {
        Header: 'Name/Bundle',
        accessor: 'name',
        width: 100,
        Cell: ({ row }: any) => (
          <>
            <div className='d-flex align-items-center gap-2'>
              {row?.original?.url && (
                <a href={`http://${row?.original?.url}`} target='_blank' rel='noreferrer'>
                  <GoLinkExternal className='svg-icon' fontSize={16} title='Redirect URL' />
                </a>
              )}
              <p className='macros-truncate'>{row?.original?.name}</p>
            </div>
          </>
        ),
      },
      {
        Header: 'Inventory ID',
        accessor: 'inventory_id',
        width: 100,
      },
      {
        Header: 'Platform Type',
        accessor: 'platform',
        width: 120,
      },
      {
        Header: 'Unique',
        accessor: 'unique',
        width: 100,
      },
      {
        Header: 'Daily Impression',
        accessor: 'impressions',
        width: 100,
      },
      {
        Header: 'Observed CPM',
        accessor: 'avg_cpm_formatted',
        width: 100,
      },
      {
        Header: 'Source',
        accessor: 'publisher_id',
        width: 100,
      },
    ],
    []
  );

  return (
    <>
      <Table
        columns={isAdmin ? adminColumns : columns}
        isAdmin={isAdmin}
        data={data}
        expandedRows={true}
        subRowsKey='inventories'
        onSelectedRowsChange={handleSelect && handleSelect}
      />
    </>
  );
};

export default NewInvTable;
