import { useEffect, useRef, useState } from 'react';
import PlayerOverlay from './PlayerOverlay';

const Player = ({ videoType = 'contain', items, genuinInfo }: any) => {
  const [isPlay, setIsPlay] = useState(false);
  const [isMute, setIsMute] = useState(false);

  const videoRef: any = useRef();

  const handlePlay = () => {
    if (videoRef.current.paused) {
      setIsPlay(true);
      videoRef.current.play();
    } else {
      setIsPlay(false);
      videoRef.current.pause();
    }
  };

  const handleMute = () => {
    if (videoRef.current.muted) {
      setIsMute(false);
      videoRef.current.muted = false;
    } else {
      setIsMute(true);
      videoRef.current.muted = true;
    }
  };

  // Event handler for video ended
  const handleVideoEnded = () => {
    setIsPlay(false);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', handleVideoEnded);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, []);

  return (
    <div className='video-player'>
      <div className='vp_overlay'>
        <PlayerOverlay
          isMute={isMute}
          isPlay={isPlay}
          items={items}
          onPlayClick={handlePlay}
          onMuteClick={handleMute}
          genuinInfo={genuinInfo}
        />
      </div>

      <video
        ref={videoRef}
        className={'video'}
        width='100%'
        height='100%'
        playsInline={false}
        style={{
          objectFit: videoType,
          position: 'absolute',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      >
        <source src={items?.video_url} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div
        className='vp_bgimg'
        style={{
          backgroundImage: `url(${items?.video_thumbnail_s})`,
        }}
      ></div>
    </div>
  );
};

export default Player;
