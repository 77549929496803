import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getCustomersAPI } from '../../services/customers';
import { addDealCustomersAPI } from '../../services/inventory';
import { Form } from 'react-bootstrap';

import Button from '../widgets/button';
import store from '../../states';
import TableSkeleton from '../loaders/table';
import { toast } from 'react-toastify';

export default ({ id, onHide, show, addedCustomers }: any) => {
  const [filteredCustomers, sFilteredCustomers] = useState([]);
  const [selectedCustomers, sSelectedCustomers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const getCustomers = () => {
    const payload = {
      order: 'desc',
      order_by: 'id',
      search_column: 'customer_type',
      search_key: 'demand',
    };
    setIsLoading(true);
    getCustomersAPI(payload)
      .then((data: any) => {
        if (data.status) {
          sFilteredCustomers(data?.data?.filter((x: any) => !addedCustomers.includes(x.id)));
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const selectCustomer = (id: any, e: any) => {
    if (e.target.checked) {
      sSelectedCustomers([...selectedCustomers, id]);
    } else {
      sSelectedCustomers(selectedCustomers.filter((x: any) => x !== id));
    }
  };

  const addCustomers = () => {
    const payload = {
      deal_id: id,
      customers: selectedCustomers,
    };
    setIsAdding(true);
    addDealCustomersAPI(payload)
      .then((data: any) => {
        onHide();
        setTimeout(() => {
          sSelectedCustomers([]);
          sFilteredCustomers([]);
          setIsAdding(false);
        }, 200);
        if (!data.status) {
          toast.error(data.message);
        }
      })
      .catch((error: any) => {
        //
      });
  };

  useEffect(() => {
    if (show === true) {
      getCustomers();
    }
  }, [show]);

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
          {glossary.admin_deal_customer_add_popup_title}
        </h2>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <>
            <TableSkeleton columns={2} rows={5} rowSpacing={10} tableHeight={150} />
          </>
        ) : (
          <>
            {filteredCustomers.length > 0 ? (
              <>
                <div className='row mb-3 ms-0'>
                  <div className='col ps-0'>
                    <p style={{ fontSize: '15px', fontWeight: 100 }}>
                      {glossary.admin_deal_customer_add_popup_field_label_customer_id}
                    </p>
                  </div>
                  <div className='col ps-0'>
                    <p style={{ fontSize: '15px', fontWeight: 100 }}>
                      {glossary.admin_deal_customer_add_popup_field_label_customer_name}
                    </p>
                  </div>
                  <div className='col' />
                </div>

                <div
                  style={{
                    maxHeight: 'calc(100vh - 300px)',
                    overflowY: 'auto',
                  }}
                >
                  {filteredCustomers.map((customer: any, index: any) => (
                    <>
                      <div className='row mb-3 ms-0' key={index}>
                        <div className='col'>
                          <Form.Check
                            name={'id'}
                            onChange={(e) => selectCustomer(customer.id, e)}
                            type='checkbox'
                            label={customer.id}
                          />
                        </div>
                        <div className='col'>{customer.company_name}</div>
                        <div className='col' />
                      </div>
                      <hr />
                    </>
                  ))}
                </div>
              </>
            ) : (
              <p className={'text-center my-4'}>
                Alreday all customers are added in this deal.
                {/* {glossary.admin_deal_customer_add_popup_all_customer_selected_error} */}
              </p>
            )}
          </>
        )}

        {/* noCustomers  */}
        {/* <div className='mb-3 text-center'>
          {glossary.admin_deal_customer_add_popup_nocustomer_error}
        </div> */}
      </Modal.Body>
      <Modal.Footer className='d-flex gap-2'>
        <Button
          kind='secondary'
          text={glossary.admin_deal_customer_add_popup_button_cancel}
          click={() => onHide()}
        />
        {filteredCustomers.length > 0 && (
          <>
            {isAdding ? (
              <Button kind={'spinner'} text={'Adding...'} type={'button'} />
            ) : (
              <Button
                kind={selectedCustomers.length > 0 ? 'primary' : 'disabled'}
                text={glossary.admin_deal_customer_add_popup_button_add}
                click={() => addCustomers()}
              />
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
