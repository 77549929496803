import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';

const MessageBar = (props: any) => {
  const { show, message } = props;
  const [open, setOpen] = useState(show);

  return (
    <>
      <div className={open ? 'messagebar' : 'd-none'}>
        <div className='col-11'>
          <p className='messagebar-message'>{message}</p>
        </div>
        <div className='col-1 d-flex justify-content-end'>
          <IoMdClose className='svg-icon' fontSize={20} onClick={() => setOpen(false)} />
        </div>
      </div>
    </>
  );
};

export default MessageBar;
