import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { GrDocumentTxt } from 'react-icons/gr';
import { MdOutlineDescription } from 'react-icons/md';

// import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
// import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
// import { ReactComponent as BellIcon } from '../../../assets/icons/bell.svg';
// import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
// import { ReactComponent as GearIcon } from '../../../assets/icons/gear.svg';
// import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
// import { ReactComponent as DollarIcon } from '../../../assets/icons/dollar.svg';
// import { ReactComponent as PayoutsIcon } from '../../../assets/icons/payouts.svg';

import { HiOutlineUserGroup } from 'react-icons/hi';
import { TbCurrencyDollar, TbUser, TbUsers } from 'react-icons/tb';
import { AiOutlineSetting } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineBell } from 'react-icons/hi';
import { TbArrowUpRightCircle } from 'react-icons/tb';

import store from '../../../states';

import Account from './account';
import Customers from './customers';
import Customize from './customize';
import Finance from './finance';
import Notifications from './notifications';
// import PayoutRequests from './payout-requests';
import Payouts from './payouts';
import Profile from './profile';
import Users from './users';
import SspAdstxt from './ssp-adstxt';
import AdsTxt from './ads-txt';
import Macros from './macros';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

export default () => {
  const { push } = useHistory();
  const subLocation = useLocation();

  const [section, sSection] = useState(localStorage.getItem('section') ?? 'profile');
  const [glossary, sGlossary] = useState<any>({});
  const ref = useRef(null);

  useEffect(() => {
    if (subLocation.state) {
      // @ts-ignore
      sSection(subLocation.state.section ?? 'profile');
    }
  }, [subLocation.pathname]);

  const navigate = (route: string) => {
    sSection(route);
    push(`/settings/${route}`);
  };

  useEffect(() => {
    if (subLocation.pathname.startsWith(`/settings/finance`)) sSection('finance');
    if (subLocation.pathname.startsWith(`/settings/payouts`)) sSection('payouts');
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* selected while you reload page */
  //const { push } = useHistory();
  const [selectedTabSetting, setSelectedTabSetting] = useState(
    localStorage.getItem('selectedTabSetting') ?? 'profile'
  );

  useEffect(() => {
    localStorage.setItem('section', selectedTabSetting);
  }, [selectedTabSetting]);

  const dismissAll = () => toast.dismiss();

  const handleTabs = (key: any) => {
    setSelectedTabSetting(key);
    push(`/settings/${key}`);
    dismissAll();
  };

  useEffect(() => {
    localStorage.setItem('selectedTabSetting', selectedTabSetting);
  }, [selectedTabSetting]);
  /* selected while you reload page */

  useEffect(() => {
    if (
      subLocation.pathname.startsWith(`/settings/payouts`) ||
      subLocation.pathname.startsWith(`/settings/finance`)
    ) {
      setTimeout(() => {
        //@ts-ignore
        ref.current.click();
      }, 100); //miliseconds
    }
  }, [subLocation.pathname]);

  //Publisher Payout Notification onclick slug redirect on payout section
  useEffect(() => {
    if (
      subLocation.pathname &&
      (localStorage.getItem('customer_type') === 'publisher' ||
        localStorage.getItem('customer_type') === 'supply')
    ) {
      const requestID = subLocation.pathname.split('/settings/payout/payouts/')[1];
      if (requestID && requestID) {
        push('/settings/payouts');
      }
    }
  }, [subLocation.pathname]);

  return (
    <>
      <div className='container-fluid setting'>
        <Tab.Container
          //defaultActiveKey='profile'

          defaultActiveKey={selectedTabSetting}
          onSelect={(key: any) => handleTabs(key)}
        >
          <div className='row'>
            <div className='col-3 setting-left'>
              <h3>Settings</h3>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='profile'>
                    <TbUser fontSize={24} />
                    Profile Settings
                  </Nav.Link>
                </Nav.Item>
                {(localStorage.getItem('customer_type') === 'demand' ||
                  localStorage.getItem('customer_type') === 'spo' ||
                  localStorage.getItem('customer_type') === 'publisher' ||
                  localStorage.getItem('customer_type') === 'supply' ||
                  localStorage.getItem('customer_type') === 'creative') && (
                  <Nav.Item>
                    <Nav.Link eventKey='company'>
                      <AiOutlineSetting fontSize={24} />
                      Company Settings
                    </Nav.Link>
                  </Nav.Item>
                )}
                {localStorage.getItem('r') === 'infy-admin' && (
                  <Nav.Item>
                    <Nav.Link eventKey='user'>
                      <TbUsers fontSize={24} />
                      Users
                    </Nav.Link>
                  </Nav.Item>
                )}
                {localStorage.getItem('r') === 'infy-admin' && (
                  <Nav.Item>
                    <Nav.Link eventKey='customers'>
                      <HiOutlineUserGroup fontSize={24} />
                      Customers
                    </Nav.Link>
                  </Nav.Item>
                )}

                {
                  // localStorage.getItem('customer_type') === 'demand' ||
                  // localStorage.getItem('customer_type') === 'spo' ||
                  localStorage.getItem('r') === 'infy-admin' && (
                    // || localStorage.getItem('customer_type') === 'creative'
                    <Nav.Item>
                      <Nav.Link eventKey='finance' ref={ref}>
                        <TbCurrencyDollar fontSize={24} />
                        Finance
                      </Nav.Link>
                    </Nav.Item>
                  )
                }
                {/* {localStorage.getItem('r') === 'infy-admin' && (
                  <Nav.Item>
                    <Nav.Link eventKey='payout-requests'>
                      <TbArrowUpRightCircle fontSize={24} />
                      Payout Requests
                    </Nav.Link>
                  </Nav.Item>
                )} */}
                {/* {(localStorage.getItem('customer_type') === 'publisher' ||
                  localStorage.getItem('customer_type') === 'supply') && (
                  <Nav.Item>
                    <Nav.Link eventKey='payout' ref={ref}>
                      <TbCurrencyDollar fontSize={24} />
                      Finance
                    </Nav.Link>
                  </Nav.Item>
                )} */}
                <Nav.Item>
                  <Nav.Link eventKey='email'>
                    <HiOutlineBell fontSize={24} />
                    Email Notifications
                  </Nav.Link>
                </Nav.Item>
                {localStorage.getItem('r') === 'infy-admin' && (
                  <Nav.Item>
                    <Nav.Link eventKey='customize'>
                      <FiEdit fontSize={22} />
                      Customize
                    </Nav.Link>
                  </Nav.Item>
                )}
                {(localStorage.getItem('customer_type') === 'demand' ||
                  localStorage.getItem('customer_type') === 'spo') && (
                  <Nav.Item>
                    <Nav.Link eventKey='adsdsp'>
                      <GrDocumentTxt fontSize={20} style={{ marginRight: '18px' }} />
                      Ads.txt
                    </Nav.Link>
                  </Nav.Item>
                )}
                {(localStorage.getItem('customer_type') === 'publisher' ||
                  localStorage.getItem('customer_type') === 'supply') && (
                  <>
                    <Nav.Item>
                      <Nav.Link eventKey='ssp-Adstxt'>
                        <GrDocumentTxt fontSize={20} style={{ marginRight: '18px' }} />
                        Ads.txt
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='macros'>
                        <MdOutlineDescription fontSize={24} />
                        Macros
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </Nav>
            </div>
            <div className='col-9 setting-right'>
              <div className='settings-container'>
                <Tab.Content>
                  <Tab.Pane eventKey='profile'>
                    <Profile toast={toast} store={store} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='company'>
                    <Account toast={toast} store={store} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='user'>
                    <Users toast={toast} store={store} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='customers'>
                    <Customers toast={toast} store={store} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='finance'>
                    <Finance toast={toast} store={store} />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey='payout-requests'>
                    <PayoutRequests toast={toast} store={store} />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey='payout'>
                    <Payouts toast={toast} store={store} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='email'>
                    <Notifications toast={toast} store={store} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='customize'>
                    <Customize toast={toast} store={store} section={section} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='ssp-Adstxt'>
                    <SspAdstxt toast={toast} store={store} section={section} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='adsdsp'>
                    <AdsTxt toast={toast} store={store} section={section} />
                  </Tab.Pane>
                  <Tab.Pane eventKey='macros'>
                    <Macros toast={toast} store={store} section={section} />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </div>
        </Tab.Container>
      </div>
      {/* <div className={'content d-flex'}>
        <div className={'settings-sidebar'}>
          <h3>{glossary.setting_sidebar_title}</h3>
          <div className={'settings-menu'}>
            {localStorage.getItem('r') === 'infy-admin'
              ? [
                  {
                    label: glossary.setting_sidebar_option_profile,
                    route: 'profile',
                    icon: <UserIcon className='svg-icon' />,
                    fill: <UserIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_users,
                    route: 'users',
                    icon: <UsersIcon className='svg-icon' />,
                    fill: <UsersIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_customers,
                    route: 'customers',
                    icon: <ListIcon className='svg-icon' />,
                    fill: <ListIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_finance,
                    route: 'finance',
                    icon: <DollarIcon className='svg-icon' />,
                    fill: <DollarIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_payout_request,
                    route: 'payout-requests',
                    icon: <PayoutsIcon className='svg-icon' />,
                    fill: <PayoutsIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_email_notifications,
                    route: 'email-notifications',
                    icon: <BellIcon className='svg-icon' />,
                    fill: <BellIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_customize,
                    route: 'customize',
                    icon: <EditIcon className='svg-icon' />,
                    fill: <EditIcon className='svg-icon' />,
                  },
                ].map((setting, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        (localStorage.getItem('role_id') === '2' && setting.route === 'finance') ||
                        (localStorage.getItem('role_id') === '2' &&
                          setting.route === 'payout-requests') ||
                        (localStorage.getItem('role_id') === '2' && setting.route === 'customize')
                          ? 'd-none'
                          : 'd-block'
                      }
                    >
                      <div
                        key={index}
                        className={`${
                          section === setting.route
                            ? 'settings-menu-list settings-menu-list-selected'
                            : 'settings-menu-list'
                        }`}
                        onClick={() => {
                          navigate(setting.route);
                        }}
                      >
                        {}
                        {section === setting.route ? setting.fill : setting.icon}
                        <p>{setting.label}</p>
                      </div>
                    </div>
                  );
                })
              : localStorage.getItem('customer_type') === 'demand'
              ? [
                  {
                    label: glossary.setting_sidebar_option_profile,
                    route: 'profile',
                    icon: <UserIcon className='svg-icon' />,
                    fill: <UserIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_company_settings,
                    route: 'account',
                    icon: <GearIcon className='svg-icon' />,
                    fill: <GearIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_users,
                    route: 'users',
                    icon: <UsersIcon className='svg-icon' />,
                    fill: <UsersIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_finance,
                    route: 'finance',
                    icon: <DollarIcon className='svg-icon' />,
                    fill: <DollarIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_email_notifications,
                    route: 'email-notifications',
                    icon: <BellIcon className='svg-icon' />,
                    fill: <BellIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_adstxt,
                    route: 'ads-txt',
                    icon: <GrDocumentTxt className='svg-icon ms-1' fontSize={19} />,
                    fill: <GrDocumentTxt className='svg-icon ms-1' fontSize={19} />,
                  },
                ].map((setting, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        section === setting.route
                          ? 'settings-menu-list settings-menu-list-selected'
                          : 'settings-menu-list'
                      }`}
                      onClick={() => {
                        navigate(setting.route);
                      }}
                    >
                      {section === setting.route ? setting.fill : setting.icon}
                      <p>{setting.label}</p>
                    </div>
                  );
                })
              : [
                  {
                    label: glossary.setting_sidebar_option_profile,
                    route: 'profile',
                    icon: <UserIcon className='svg-icon' />,
                    fill: <UserIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_company_settings,
                    route: 'account',
                    icon: <GearIcon className='svg-icon' />,
                    fill: <GearIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_users,
                    route: 'users',
                    icon: <UsersIcon className='svg-icon' />,
                    fill: <UsersIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_payout,
                    route: 'payouts',
                    icon: <PayoutsIcon className='svg-icon' />,
                    fill: <PayoutsIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_email_notifications,
                    route: 'email-notifications',
                    icon: <BellIcon className='svg-icon' />,
                    fill: <BellIcon className='svg-icon' />,
                  },
                  {
                    label: glossary.setting_sidebar_option_adstxt,
                    route: 'ssp-Adstxt',
                    icon: <GrDocumentTxt className='svg-icon ms-1' fontSize={19} />,
                    fill: <GrDocumentTxt className='svg-icon ms-1' fontSize={19} />,
                  },
                  {
                    label: glossary.setting_sidebar_option_macros,
                    route: 'macros',
                    icon: <MdOutlineDescription className='svg-icon' fontSize={25} />,
                    fill: <MdOutlineDescription className='svg-icon' fontSize={25} />,
                  },
                ].map((setting, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        section === setting.route
                          ? 'settings-menu-list settings-menu-list-selected'
                          : 'settings-menu-list'
                      }`}
                      onClick={() => {
                        navigate(setting.route);
                      }}
                    >
                      {section === setting.route ? setting.fill : setting.icon}
                      <p>{setting.label}</p>
                    </div>
                  );
                })}
          </div>
        </div>
        <div className={'settings-container'}>
          {section === 'users' && <Users toast={toast} store={store} />}
          {section === 'customers' && <Customers toast={toast} store={store} />}
          {section === 'finance' && <Finance toast={toast} store={store} />}
          {section === 'payout-requests' && <PayoutRequests toast={toast} store={store} />}
          {section === 'email-notifications' && <Notifications toast={toast} store={store} />}
          {section === 'profile' && <Profile toast={toast} store={store} />}
          {section === 'account' && <Account toast={toast} store={store} />}
          {section === 'payouts' && <Payouts toast={toast} store={store} />}
          {section === 'customize' && <Customize toast={toast} store={store} section={section} />}
          {section === 'ssp-Adstxt' && <SspAdstxt toast={toast} store={store} />}
          {section === 'ads-txt' && <AdsTxt toast={toast} store={store} />}
          {section === 'macros' && <Macros toast={toast} store={store} />}
        </div>
      </div> */}
    </>
  );
};
