import { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from '../../widgets/button';
import JSONSchemaForm from 'react-jsonschema-form';
import additionalMetaSchemas from 'ajv/lib/refs/json-schema-draft-04.json';
import { addEndpointAPI, getHeaderBidderEmailAPI } from '../../../services/endpoint';
import { getBidderListAPI } from '../../../services/endpoint';
import BidderList from '../../loaders/bidder-list';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import store from '../../../states';
import GoogleLogin from './google-login';
import { windowReload } from '../../helpers/functions';
//import Form from '@rjsf/bootstrap-4';
//import validator from '@rjsf/validator-ajv8';

interface Props {
  getEndPointListCard?: any;
  onHide?: any;
  endpontDataStatus?: any;
  customerID?: number;
  show?: boolean;
  initialCreate?: boolean;
}

const Headerbidder = (props: Props) => {
  const { getEndPointListCard, onHide, endpontDataStatus, customerID, show, initialCreate } = props;

  const [openModal, sOpenModal] = useState(false);
  const [newObj, setNewObj] = useState<any>({});
  const [bidderListData, sBidderListData] = useState<any>({});
  //const [bidderTitle, sBidderTitle] = useState();
  const [bidderLogo, sBidderLogo] = useState();
  const [loading, setLoading] = useState(true);
  const [bidderSearchDetails, sBidderSearchDetails] = useState<any>();
  const [searchName, sSearchName] = useState('');
  const [getBidderKey, sGetBidderKey] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* add custom class form schema form */
  const uiSchema = {
    classNames: 'headerbidder-form',
  };

  /* set object data in schema form */
  const getObjData = (value: any) => {
    setNewObj(
      Object.assign({}, value, {
        ...value,
        properties: {
          integrationName: { description: 'Header Bidder Name', type: 'string' },
          //min_cpm: { description: 'Min Floor Price', type: 'number', minimum: 0 },
          //max_cpm: { description: 'Max Floor Price', type: 'number', minimum: 0 },
          ...value.properties,
        },
        //required: finalArray,
        //required: ['integrationName', 'min_cpm', 'max_cpm', ...(value.required ?? '')],
        required: ['integrationName', ...(value.required ?? '')],
      })
    );
    //sBidderTitle(value.title);
    sBidderLogo(value.log_url);
  };

  /* Get Bidder list api */
  useEffect(() => {
    if (show === true || initialCreate === true) {
      getBidderListAPI()
        .then((data: any) => {
          sBidderListData(data);
          sBidderSearchDetails(data);
          setLoading(false);
        })
        .catch(() => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
    }
  }, [show, initialCreate]);

  /* Bidder search Filter */
  const filter = (e: any) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const filtered = Object.keys(bidderSearchDetails)
        .filter((key) => key.toLowerCase().startsWith(keyword?.toLowerCase()))
        .reduce((obj: any, key: any) => {
          return {
            ...obj,
            [key]: bidderSearchDetails[key],
          };
        }, {});
      sBidderListData(filtered);
    } else {
      sBidderListData(bidderSearchDetails);
    }
    sSearchName(keyword);
  };

  /* Schema form api call */
  const jsoneSchemaForm = (e: any) => {
    const { formData } = e;
    const config = Object.assign(
      {
        //bidderTitle,
        bidderLogo,
        bidderTitle: getBidderKey,
        name: e.formData.integrationName,
      },
      formData
    );
    const payload = {
      endpoint: getBidderKey,
      endpoint_type: 'hb_connector',
      config,
      customer_id: customerID,
      name: e.formData.integrationName,
    };
    setIsSubmitting(true);
    addEndpointAPI(payload)
      .then((data: any) => {
        if (endpontDataStatus === false && data.status) {
          windowReload();
        }
        if (data?.status === true) {
          sOpenModal(false);
          getEndPointListCard(data, 'add');
          toast.success(data.message);
          // toast.success(glossary.dsp_add_hb_success_msg);
        } else {
          toast.success(data.message);
          // toast.error(glossary.dsp_add_hb_exist_error);
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 300);
      })
      .catch(() => {
        //toast.error(glossary.dsp_add_hb_faild_server_msg);
      });
  };

  /*Sticky Table Header*/
  const [stickyClassName, setStickyClassName] = useState('');

  const handleScroll = (headerClassName: any) => {
    if (headerClassName !== 'sticky' && window.pageYOffset >= 400) {
      setStickyClassName('sticky');
    } else if (headerClassName === 'sticky' && window.pageYOffset < 400) {
      setStickyClassName('');
    }
  };

  useEffect(() => {
    window.onscroll = () => handleScroll(stickyClassName);
  }, [stickyClassName]);

  delete newObj?.oneOf;
  delete newObj?.anyOf;

  const [reqAccountBtn, sReqAccountBtn] = useState(false);

  const getHeaderBidderEmail = (bidderKey: any) => {
    sReqAccountBtn(true);
    getHeaderBidderEmailAPI(bidderKey)
      .then((data: any) => {
        if (data.status) {
          handleEmailButtonClick(data.data.email);
        }
        sReqAccountBtn(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const handleEmailButtonClick = (emailID: string) => {
    //@ts-ignore
    const user = JSON.parse(localStorage.getItem('user'));
    const publisherName: any = `${user.firstName + user.lastName}`;

    const email: any = emailID;
    const subject: any = 'Quick Request: Access to Header Bidder Connector';
    // encodeURI
    const _body: any = encodeURIComponent(`
    Hey there,
      I hope you're having a great day! I'm reaching out because I would like to get access
    to the Header Bidder Connector for our ad inventory.
    Our team believes that this will help us optimize our ad performance and increase revenue.
    Could you please guide me on the process for obtaining access? I'm eager to start testing 
    it out and see what magic it can work for us.

    Thanks a bunch, and looking forward to hearing from you soon!
    Cheers,
    ${publisherName}`);

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${_body}`;
    window.location.href = mailtoLink;
    window.open(mailtoLink, '_blank', 'noopener,noreferrer');

    // const mailtoLink = `mailto:${email}?subject=${subject}`;
    // const mailtoWindow = window.open(mailtoLink, '_blank', 'noopener,noreferrer');
    // if (mailtoWindow && mailtoWindow.focus) {
    //   mailtoWindow.focus();
    // }
  };

  function CustomFieldTemplate(props: any) {
    const { id, classNames, label, help, required, description, errors, children } = props;
    return (
      <div className={classNames}>
        <label htmlFor={id} className='text-capitalize'>
          {label}
          <span className='required'>{required ? '*' : null}</span>
        </label>
        <span className='ms-1'>{description}</span>
        {children}
        <div className={'headerbidder-form-error'}>
          <span className='me-1 text-danger'>
            {props?.rawErrors?.length > 0 && `${label} is a required.`}
          </span>
          {/* {errors} */}
        </div>
        {help}
      </div>
    );
  }

  return (
    <div className={endpontDataStatus ? `${stickyClassName}` : 'tour-hb-connector'}>
      <div>
        <div
          className='modal-body px-3 pb-3 pt-0 light-shadow'
          style={{
            maxHeight: openModal ? 'initial' : 'calc(80vh - 210px)',
            overflowY: 'auto',
          }}
        >
          {/* Search Bidder list start*/}
          {!openModal && (
            <Row
              className='align-items-center bg-white mb-2'
              style={{ position: 'sticky', top: 0 }}
            >
              <Col>
                <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                  {glossary.dsp_add_hb_title}
                </h3>
              </Col>
              <Col className='col-auto'>
                <input
                  type='search'
                  value={searchName}
                  maxLength={32}
                  onChange={filter}
                  className='text-input-mini mb-4'
                  placeholder={glossary.dsp_add_hb_input_search_placeholder}
                  style={{ maxWidth: '300px', minWidth: '250px' }}
                />
              </Col>
            </Row>
          )}
          {/* Search Bidder list end*/}

          {loading ? (
            <BidderList />
          ) : (
            <>
              {openModal ? (
                <div className='headerbidder-modal pt-3'>
                  <JSONSchemaForm
                    additionalMetaSchemas={[additionalMetaSchemas]}
                    liveValidate
                    schema={newObj}
                    uiSchema={uiSchema}
                    showErrorList={false}
                    onSubmit={jsoneSchemaForm}
                    FieldTemplate={CustomFieldTemplate}
                    noHtml5Validate
                  >
                    <div className='d-flex align-items-center justify-content-end gap-3 bidder-button mt-3'>
                      <Button
                        kind={'secondary'}
                        text={glossary.dsp_add_hb_popup_button_back}
                        click={() => {
                          sOpenModal(false);
                          ReactGA.event('hb_popup_cancel_button', {
                            category: 'hb_add',
                            action: 'click',
                            label: 'cancel_button',
                          });
                        }}
                      />
                      {isSubmitting ? (
                        <Button type='button' kind={'spinner'} text={glossary.loading} />
                      ) : (
                        <Button
                          type={'submit'}
                          kind={'primary'}
                          text={glossary.dsp_add_hb_popup_button_test_integration}
                          click={() => {
                            ReactGA.event('hb_popup_submit_button', {
                              category: 'hb_add',
                              action: 'click',
                              label: 'submit_button',
                            });
                          }}
                        />
                      )}
                    </div>
                  </JSONSchemaForm>
                </div>
              ) : (
                Object.entries(bidderListData).map(([key, value]: any) => {
                  return (
                    <>
                      <div key={key} className='my-1 bidder-item'>
                        <div className='align-items-center d-flex justify-content-between'>
                          <Col>
                            <div className='align-items-center d-flex'>
                              <div
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  overflow: 'hidden',
                                  marginRight: '10px',
                                }}
                              >
                                <img
                                  src={value?.log_url}
                                  alt={value?.title}
                                  style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                  }}
                                />
                              </div>
                              <p>{value.title.split(' ')[0]}</p>
                            </div>
                          </Col>
                          <Col>
                            <p style={{ textTransform: 'capitalize' }}>
                              <span className='fw-lighter'>Support:</span>{' '}
                              {value?.mediaTypes.join(', ')}
                            </p>
                          </Col>
                          <div className='me-3'>
                            <Button
                              kind={reqAccountBtn ? 'disabled' : 'secondary'}
                              text={'Request Account'}
                              click={() => getHeaderBidderEmail(key)}
                            />
                          </div>
                          <Button
                            kind={'primary'}
                            text={glossary.dsp_add_hb_list_onhover_button_add}
                            click={() => {
                              sGetBidderKey(key);
                              sOpenModal(true);
                              getObjData(value);
                              ReactGA.event('hb_add_button', {
                                category: 'hb_add',
                                action: 'click',
                                label: 'hb_add_button',
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </>
          )}
        </div>
        {/* Fist screen close button when visible hb list */}
        {!openModal && (
          <div className={'d-flex justify-content-between modal-footer px-3'}>
            <GoogleLogin openModal={openModal} />
            {getEndPointListCard && !openModal && (
              <Button
                kind={'secondary'}
                text={'Close'}
                click={() => {
                  onHide();
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Headerbidder;
