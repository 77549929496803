import React, { useEffect, useState } from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
} from 'react-table';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { CSVLink } from 'react-csv';
import Button from '../widgets/button';

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder,
}: any) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className='row align-items-sm-baseline'>
      <div className='search-wrap col mb-0'>
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          type={'search'}
          placeholder={placeholder}
          className='form-control'
          maxLength={64}
        />
      </div>
    </div>
  );
};

const Table = ({
  columns: userColumns,
  data,
  search,
  searchPlaceholder,
  downloadCSV,
  csvColumns,
}: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      getSubRows: (row) => row.subRows || [],
      data,
      // getSubRows: (row) => row.inventories || [],
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  const generateRow = (rows: any) => {
    const row: any = rows;
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell: any, i: any) => {
          return (
            <td
              {...cell.getCellProps()}
              key={cell?.column?.id}
              className={`${cell?.column?.id} ${cell?.column?.id !== 'action' && 'text-truncate'}`}
              title={cell.value}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <div className={search ? 'd-wrap mb-3' : 'd-none'}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={searchPlaceholder}
          />
        </div>
        {downloadCSV && (
          <CSVLink filename={`overview-report.csv`} headers={csvColumns} data={data}>
            <Button kind='secondary' text='Download CSV' />
          </CSVLink>
        )}
      </div>
      <div className={data.length > 20 ? 'infy-table mt-0' : 'infy-table auto mt-0'}>
        <table {...getTableProps()} className='table'>
          <thead className='table-header'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className={
                      column.class === 'sticky' ? 'th text-break sticky-column' : 'th text-break'
                    }
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoIosArrowDown className='ms-1 svg-icon' />
                        ) : (
                          <IoIosArrowUp className='ms-1 svg-icon' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>{rows.map(generateRow)}</tbody>
        </table>
      </div>
    </>
  );
};

const OverViewTable = (props: any) => {
  const { tableColumns, tableRows, search, searchPlaceholder, downloadCSV } = props;

  const [csvColumns, setCsvColumns] = useState([]);

  //Dynamically columns rendering
  const columns = tableColumns?.map((item: any) => {
    return {
      Header: item.title,
      accessor: item.key,
      width: 100,
    };
  });

  // Table Rows Data
  const data = tableRows;

  useEffect(() => {
    const csv: any = [];
    if (tableColumns?.length > 0) {
      tableColumns.forEach((item: any) => {
        csv.push({ label: item.title, key: item.key });
      });
    }
    setCsvColumns(csv);
  }, [tableColumns]);

  return (
    <>
      <Table
        columns={columns}
        data={data}
        expandedRows={true}
        subRowsKey='subRows'
        search={search}
        searchPlaceholder={searchPlaceholder}
        downloadCSV={downloadCSV}
        csvColumns={csvColumns}
      />
    </>
  );
};

export default OverViewTable;
