import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useFlexLayout, useSortBy } from 'react-table';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import TableSkeleton from '../loaders/table';
import InfyActionColumn from '../comman/table/InfyActionColumn';
import { CSVLink } from 'react-csv';
import Button from '../widgets/button';

export default function InfyTable({
  columns,
  data,
  onNextPage,
  loading,
  hasMore,
  onSearchChage,
  onSort,
  columnWidth,
  apiCallBack,
  tableName,
  customerId,
  reportTableType,
  selectedCardId,
  toggleInclude,
  toggleExclude,
  csvFileName,
  downloadCSV,
  search,
  action,
  scrollXeventProp,
  searchPlaceholder,
}: {
  columns: any;
  data: any;
  onNextPage?: any;
  loading?: boolean;
  hasMore?: boolean;
  onSearchChage?: any;
  onSort?: any;
  columnWidth?: number;
  apiCallBack?: any;
  tableName?: string;
  customerId?: any;
  reportTableType?: any;
  selectedCardId?: any;
  toggleInclude?: any;
  toggleExclude?: any;
  csvFileName?: any;
  downloadCSV?: boolean;
  search?: boolean;
  tabName?: string;
  tabPath?: string;
  scrollXeventProp?: any;
  action?:
    | {
        text: string;
        click(): void;
        customClass?: string;
        css?: {
          right: string;
        };
      }
    | undefined;
  searchPlaceholder?: string;
}) {
  const [pageIndex, setPageIndex] = useState(0);
  const [searchVal, setSearchVal] = React.useState('');
  const [csvColumns, setCsvColumns] = useState<any>([]);
  const [isSortedDesc, setIsSortedDesc] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);

  // render table rows
  const generateRow = (rows: any) => {
    const row: any = rows;
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell: any, i: any) => {
          return (
            <td
              {...cell.getCellProps()}
              key={cell?.column?.id}
              className={`${cell?.column?.id} ${cell?.column?.id !== 'action' && 'text-truncate'}`}
              title={cell.value}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  };

  //CSV files
  useEffect(() => {
    const csv: any = [];
    if (columns?.length > 0) {
      columns.forEach((item: any) => {
        csv.push({ label: item.title, key: item.key });
      });
    }
    setCsvColumns(csv);
  }, [columns]);

  // Action call
  const actionCell = (row: any) => {
    return (
      <InfyActionColumn
        columnKey={row?.column?.id}
        data={row}
        apiCallBack={handleAPICallback}
        tableName={tableName}
        customerId={customerId}
        reportTableType={reportTableType}
        selectedCardId={selectedCardId}
        toggleInclude={toggleInclude}
        toggleExclude={toggleExclude}
      />
    );
  };

  const col = React.useMemo(() => {
    if (columns) {
      return columns.map((column: any) => {
        return {
          Header: column.title,
          accessor: column.key,
          width: columnWidth,
          className: `th_${column.key} sort_${column.sort} sticky_${column.sticky}`,
          Cell: (row: any) => {
            return (
              <>
                {column.key &&
                  (column.key === 'action' ||
                    column.key === 'firstname' ||
                    column.key === 'company_name' ||
                    column.key === 'active' ||
                    column.key === 'role_name' ||
                    column.key === 'approve_reject' ||
                    column.key === 'payment_proof' ||
                    column.key === 'receipt' ||
                    column.key === 'included' ||
                    column.key === 'advertiser_domain' ||
                    column.key === 'creative_name' ||
                    column.key === 'assigned' ||
                    column.key === 'video_share_string' ||
                    column.key === 'default') &&
                  actionCell(row)}

                {/* Temporary solution for reporting Advertiserdomain key */}
                {row.column.id === 'advertiser_domain' && row.value !== '-' ? (
                  <a href={`http://${row.value}`} target='_blank' rel='noreferrer'>
                    {row.value}
                  </a>
                ) : (
                  <span data-timeframe={row.name}>{row.value}</span>
                )}
              </>
            );
          },
        };
      });
    }
    return [];
  }, [columns]);

  const tableInstance: any = useTable<any>(
    {
      columns: col,
      data,
      //@ts-ignore
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleSortBy } =
    tableInstance;

  useEffect(() => {
    if (pageIndex > 0) {
      onNextPage(pageIndex);
    }
  }, [pageIndex]);

  const [scrollXevent, setScrollXevent] = useState<any>();
  const handleScroll = (e: any) => {
    const { scrollTop, scrollLeft, clientHeight, scrollHeight } = e.target;

    setScrollXevent(scrollLeft);

    // Check if the user has reached the bottom of the content vertically
    if (scrollTop + clientHeight >= scrollHeight && hasMore) {
      setPageIndex((pageIndex) => pageIndex + 1);
    }

    // // Check if the scroll direction is horizontal
    // if (scrollLeft !== scrollXevent) {
    //     // Do not trigger API call for horizontal scroll
    //     return;
    // }

    scrollXeventProp(scrollLeft); // You can keep this line if needed
  };

  //handel search
  const handleSearch = (e: any) => {
    const searchTitle = e.target.value;
    setSearchVal(searchTitle);

    // Clear the previous timeout if there is one
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Create a new timeout to delay the search execution
    const newTimeoutId = setTimeout(() => {
      // Perform your search logic here using the debounced search value
      onSearchChage(searchTitle);
    }, 500); // Adjust the delay time (in milliseconds) as needed

    setTimeoutId(newTimeoutId);
    setPageIndex(0);
  };

  //handel sort
  const handleSortClick = (columnId: any) => {
    toggleSortBy(columnId);
    setIsSortedDesc(!isSortedDesc);
    const newSortBy = {
      id: columnId,
      desc: isSortedDesc,
    };
    setPageIndex(0);
    onSort(newSortBy);
  };

  // Handle API Callback function for setPageIndex 0
  const handleAPICallback = () => {
    apiCallBack();
    setPageIndex(0);
  };

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        {search && (
          <div className='search-wrap'>
            <input
              value={searchVal}
              onChange={handleSearch}
              type={'search'}
              className='form-control'
              placeholder={searchPlaceholder ?? 'Search data here..'}
              maxLength={64}
            />
          </div>
        )}

        {action && (
          <div className={action.customClass} style={action.css}>
            <Button text={action.text} click={action.click} kind={'primary'} type={'button'} />
          </div>
        )}

        {downloadCSV && (
          <CSVLink filename={`${csvFileName}.csv`} headers={csvColumns} data={data}>
            <Button kind='secondary' text='Download CSV' />
          </CSVLink>
        )}
      </div>

      <div className='infy-table mt-0' onScroll={handleScroll}>
        <table {...getTableProps()} className='table'>
          <thead className='table-header'>
            {headerGroups.map((headerGroup: any, i: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps({
                      className: column.className,
                      onClick: () => handleSortClick(column.id),
                    })}
                    key={column.id}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoIosArrowDown className='svg-icon' />
                        ) : (
                          <IoIosArrowUp className='svg-icon' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>{rows.map(generateRow)}</tbody>
        </table>
        {rows.length < 1 && !loading && <div>No Data Available</div>}
        {loading && pageIndex === 0 && (
          <>
            <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
          </>
        )}
        {loading && scrollXevent === 0 && (
          <TableSkeleton columns={5} rows={1} rowSpacing={2} tableHeight={30} />
        )}
      </div>
    </>
  );
}
