import { useEffect, useMemo } from 'react';
import { useTable, useFlexLayout, useResizeColumns, useSortBy, useRowSelect } from 'react-table';
import Tooltip from '../comman/tooltip/Tooltip';
import { Cell, HashtagCell } from './Cell';

interface TableProps {
  data: any;
  kind: string;
  dispatch: any;
  locator?: string;
  hasMore: boolean;
  getNextPage: any;
  isListLoading: boolean;
  preSelectedIndices?: any;
  handleSelect(selectedList: any, rowIndex: any): any;
  valueChange?: any;
  genuinInfo?: any;
  handleFormSubmit(): void;
}

export default function VideoTable({
  data,
  locator,
  hasMore,
  getNextPage,
  isListLoading,
  preSelectedIndices,
  dispatch: dataDispatch,
  handleSelect,
  valueChange,
  genuinInfo,
  handleFormSubmit,
}: TableProps) {
  //
  //
  //
  //
  //
  const coltitle = () => {
    return (
      <>
        CTA Link{' '}
        <Tooltip kind='info' text={'Example URL \n https://begenuin.com'} placement={'top'} />
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Videos',
        accessor: 'video_thumbnail_s',
        width: 210,
        type: 'video',
        genuinInfo: genuinInfo,
        Cell: Cell,
      },
      {
        Header: 'CTA Text',
        accessor: 'cta_text',
        width: 230,
        Cell: Cell,
        type: 'text',
      },
      {
        Header: coltitle,
        accessor: 'cta_link',
        width: 230,
        Cell: Cell,
        type: 'url',
      },
      {
        Header: 'Hashtags',
        accessor: 'hash_tags',
        width: 230,
        Cell: HashtagCell,
      },
      {
        Header: 'URL Meta',
        accessor: 'show_url_meta',
        width: 90,
        type: 'boolean',
        Cell: Cell,
      },
      {
        Header: 'Ad Copy',
        accessor: 'ad_copy',
        width: 230,
        Cell: Cell,
        type: 'text',
      },
      {
        Header: 'Pixel URL',
        accessor: 'pixel_link',
        width: 230,
        Cell: Cell,
        type: 'url',
      },
    ],
    []
  );

  const onKeyDownHook = () => {
    if (locator === 'video-library') {
      valueChange((prev: any) => (prev === false ? true : prev));
    }
  };

  const handleVideoConfigForm = () => {
    handleFormSubmit && handleFormSubmit();
  };

  const {
    headerGroups,
    rows,
    // @ts-ignore
    selectedFlatRows,
    // @ts-ignore
    state: { selectedRowIds },
    prepareRow,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data,
      dataDispatch,
      genuinInfo,
      initialState: {
        //@ts-ignore
        selectedRowIds: preSelectedIndices || {},
      },
      onKeyDownHook,
      handleVideoConfigForm,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>{/* <SelectCheckbox {...getToggleAllRowsSelectedProps()} /> */}</div>
          ),
          Cell: ({ row }: any) => (
            <>
              <input
                type='checkbox'
                onChange={() => row.toggleRowSelected()}
                checked={row?.isSelected}
              />
            </>
          ),
          width: 50,
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (data?.length > 0 && selectedFlatRows) {
      setTimeout(() => {
        handleSelect(
          selectedFlatRows?.flatMap((item: any) => item?.original),
          selectedRowIds
        );
      }, 200);
    }
  }, [data, selectedRowIds]);

  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight && hasMore && !isListLoading) {
      getNextPage((pageIndex: number) => pageIndex + 1);
    }
  };

  const _height = locator === 'video-library' ? 200 : 260;
  const calculatedHeight = data?.length === 0 ? 400 : window?.innerHeight - _height;

  return (
    <>
      <div className='table-wrap mt-0'>
        <table {...getTableProps()} className='table inventories mb-0'>
          <thead className='table-header'>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='tr' key={i}>
                {headerGroup.headers.map((column, _i) => (
                  <th {...column.getHeaderProps()} key={_i} className='th'>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className='table-body'>
            <div
              style={{
                height: calculatedHeight,
                position: 'relative',
                width: '100%',
                overflow: 'auto',
                willChange: 'transform',
                direction: 'ltr',
              }}
              onScroll={handleScroll}
            >
              {rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${
                      selectedFlatRows?.length >= 10 && row?.isSelected === false ? 'disabled' : ''
                    } tr p-0 border-0`}
                    key={i}
                  >
                    {row.cells.map((cell: any, _i: number) => (
                      <td {...cell.getCellProps()} className='td justify-content-center' key={_i}>
                        {isListLoading && rows.length - 1 === row.index ? (
                          <div className='creative-table-skeleton w-100'>
                            {cell?.column?.id === 'selection' ? (
                              <div className='creative-table-skeleton__checkbox creative-table-skeleton__comman'></div>
                            ) : (
                              <div
                                className={`${
                                  cell?.column?.id === 'video_thumbnail_s'
                                    ? 'creative-table-skeleton__thumbnail-col'
                                    : 'creative-table-skeleton__col'
                                } creative-table-skeleton__comman `}
                              ></div>
                            )}
                          </div>
                        ) : (
                          <>{cell.render('Cell')}</>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}

              {!isListLoading && data?.length === 0 && (
                <div className='text-center mt-5'>
                  <h4>No Videos Found</h4>
                </div>
              )}

              {isListLoading && data?.length === 0 && (
                <div className='mt-2 d-flex flex-column gap-3'>
                  {['1', '2', '3', '4'].map((_: any, index: any) => (
                    <div
                      className='creative-table-skeleton d-flex align-items-center justify-content-between'
                      key={index}
                    >
                      <div className='creative-table-skeleton__checkbox creative-table-skeleton__comman'></div>
                      <div className='creative-table-skeleton__thumbnail-col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__meta-col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </tbody>
        </table>
      </div>
    </>
  );
}
