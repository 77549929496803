import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deleteDealAPI } from '../../services/inventory';
import store from '../../states';

import Button from '../widgets/button';

export default ({ id, onHide, show, api }: any) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const delDeal = (api: any) => {
    const payload = {
      id: id,
    };
    setBtnDisabled(true);
    deleteDealAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          onHide();
          api(id);
          setBtnDisabled(false);
          toast.success(glossary.admin_deal_delete_popup_succss_message);
        } else {
          toast.error(data.message);
          setBtnDisabled(false);
        }
      })
      .catch(() => {
        //
      });
  };

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2>{glossary.admin_deal_delete_popup_title}</h2>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        {glossary.admin_deal_delete_popup_instruction}
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <Button
          kind={'secondary'}
          text={glossary.admin_deal_delete_popup_button_cancel}
          click={() => onHide()}
        />
        <Button
          kind={btnDisabled ? 'disabled' : 'primary'}
          text={glossary.admin_deal_delete_popup_button_delete}
          click={() => delDeal(api)}
        />
      </Modal.Footer>
    </Modal>
  );
};
