import { useEffect, useState, Fragment } from 'react';

interface Item {
  id: string;
  label: string;
  content: any;
}

interface Props {
  items: Item[];
  selected: string;
  tabLength?: any;
}

export default (props: Props) => {
  const { items, selected, tabLength } = props;

  const [active, sActive] = useState(selected);

  useEffect(() => {
    if (selected) sActive(selected);
  }, [selected]);

  return (
    <div className={'tab-container position-relative'} style={{ zIndex: 0 }}>
      <div className={tabLength > 0 ? `tabs tabs-length-${tabLength}` : 'tabs'}>
        {items.map((item, index) => {
          return (
            <Fragment key={index}>
              <button
                onClick={() => sActive(item.id)}
                className={active === item.id ? 'tab-button tab-button-active' : 'tab-button'}
              >
                {item.label}
              </button>
            </Fragment>
          );
        })}
      </div>
      <div className={tabLength > 0 ? `tabs-length-${tabLength}` : ''}>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={active === item.id ? 'tab-content tab-content-active' : 'tab-content'}
            >
              {item.content}
            </div>
          );
        })}
      </div>
    </div>
  );
};
