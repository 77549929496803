import { useState } from 'react';
import { loginAsCustomerAPI } from '../../../services/customers';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { GoLinkExternal } from 'react-icons/go';
import { redirectToPath } from '../../helpers/functions';

interface Props {
  customerDetails?: any;
}

export default (props: Props) => {
  const { customerDetails } = props;
  const [isRedirecting, setIsRedirecting] = useState(false);

  const loginAsCustomer = (id: any, customer_type: string) => {
    if (id) {
      const payload = {
        email: customerDetails?.email,
        customer_id: customerDetails?.id,
      };
      setIsRedirecting(true);
      loginAsCustomerAPI(payload)
        .then((data: any) => {
          if (data.access_token) {
            redirectToPath('/');
            localStorage.clear();
            localStorage.setItem('r', 'customer');
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('customer_type', customer_type);
            const w = window.open('/publisher/supply', '_blank');
            if (w) w.focus();
          } else {
            toast.error(data.msg);
          }
          setTimeout(() => {
            setIsRedirecting(false);
          }, 300);
        })
        .catch((error: any) => {
          toast.error('Something went wrong, try again later.');
        });
    }
  };

  const loginAsDSP = (id: any, customer_type: string) => {
    if (id) {
      const payload = {
        email: customerDetails?.email,
        customer_id: customerDetails?.id,
      };
      setIsRedirecting(true);
      loginAsCustomerAPI(payload)
        .then((data: any) => {
          if (data.access_token) {
            redirectToPath('/');
            localStorage.clear();
            localStorage.setItem('r', 'customer');
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('customer_type', customer_type);
            const w = window.open('/sources/demand', '_blank');
            if (w) w.focus();
          } else {
            toast.error(data.msg);
          }
          setTimeout(() => {
            setIsRedirecting(false);
          }, 300);
        })
        .catch((error: any) => {
          toast.error('Something went wrong, try again later.');
        });
    }
  };

  const loginAsCreative = (id: any, customer_type: string) => {
    if (id) {
      const payload = {
        email: customerDetails?.email,
        customer_id: customerDetails?.id,
      };
      setIsRedirecting(true);
      loginAsCustomerAPI(payload)
        .then((data: any) => {
          if (data.access_token) {
            redirectToPath('/login');
            localStorage.clear();
            localStorage.setItem('r', 'customer');
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('customer_type', customer_type);
            const w = window.open('/my-creative', '_blank');
            if (w) w.focus();
          } else {
            toast.error(data.msg);
          }
          setTimeout(() => {
            setIsRedirecting(false);
          }, 300);
        })
        .catch((error: any) => {
          toast.error('Something went wrong, try again later.');
        });
    }
  };

  return (
    <>
      {isRedirecting ? (
        <span className='suggestion-input'>
          <Spinner
            className='mt-1'
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
          />
        </span>
      ) : (
        <GoLinkExternal
          className='svg-icon flex-shrink-0'
          fontSize={20}
          onClick={() => {
            customerDetails?.customer_type === 'creative'
              ? loginAsCreative(customerDetails?.id, customerDetails?.customer_type)
              : customerDetails?.customer_type === 'demand'
              ? loginAsDSP(customerDetails?.id, customerDetails?.customer_type)
              : loginAsCustomer(customerDetails?.id, customerDetails?.customer_type);
          }}
          title='Redirect URL'
        />
      )}
    </>
  );
};
