import React, { useEffect, useState } from 'react';
import { AiFillInstagram } from 'react-icons/ai';
import { BsYoutube } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import UserProfile from '../extension/UserProfile';
import store from '../../../states';

const GenuinViewonly = (props: any) => {
  const { genuinUserConfig, genuinUserUrl, config } = props;

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const InputField = (item: any) => {
    return (
      <div className='col-12 mt-3 '>
        <label className='label'>{item.name}</label>
        <div className='input-group disable-extentsion'>
          <span className='input-group-text'>{item.icon}</span>
          <input
            type='text'
            className='form-control'
            aria-label='Amount (to the nearest dollar)'
            defaultValue={item.link}
            readOnly
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='col-6 extension-right'>
        <div className='row mb-3'>
          <div className='col-7'>
            <label className='label'>Genuin ID</label>
            <div className={'input-group disable-extentsion'}>
              <span className='input-group-text'>@</span>
              <input
                type='text'
                className='form-control'
                placeholder={'Enter your Genuin id'}
                defaultValue={genuinUserConfig?.nickname ?? '-'}
                readOnly
                style={{ backgroundColor: 'white' }}
              />
            </div>
            <div className='label'>
              <UserProfile
                userProfile={genuinUserConfig?.profile_image_s}
                genuinUser={genuinUserConfig?.nickname}
                genuinData={genuinUserConfig}
              />
            </div>
          </div>
        </div>

        {config?.genuin_loop_list && (
          <div className='row mb-3'>
            <div className='col-7'>
              <label className='label'>{glossary.dsp_add_reel_popup_ddl_label_loop_list}</label>
              <div className={'input-group disable-extentsion'}>
                <input
                  type='text'
                  className='form-control'
                  placeholder={''}
                  defaultValue={config?.genuin_loop_list?.group_name ?? ''}
                  readOnly
                  style={{ backgroundColor: 'white' }}
                />
              </div>
            </div>
            <div className='col-5 d-flex align-items-end label'>
              {config?.genuin_loop_list?.group_no_of_views && (
                <div className='d-flex gap-3'>
                  <div className='text-center'>
                    <p className='fw-lighter' style={{ fontSize: '12px', lineHeight: '16px' }}>
                      {glossary.dsp_add_reel_popup_field_label_view}
                    </p>
                    <p style={{ fontSize: '12px', lineHeight: '16px' }}>
                      {config?.genuin_loop_list?.group_no_of_views}
                    </p>
                  </div>
                  <div className='text-center'>
                    <p className='fw-lighter' style={{ fontSize: '12px', lineHeight: '16px' }}>
                      {glossary.dsp_add_reel_popup_field_label_videos}
                    </p>
                    <p style={{ fontSize: '12px', lineHeight: '16px' }}>
                      {config?.genuin_loop_list?.group_no_of_videos}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='col-6 extension-left m-0'>
        <div className='row'>
          <InputField
            name={'Instagram'}
            icon={<AiFillInstagram fontSize={20} />}
            link={genuinUserUrl?.insta_url ?? '-'}
          />
          <InputField
            name={'YouTube'}
            icon={<BsYoutube fontSize={20} />}
            link={genuinUserUrl?.yt_url ?? '-'}
          />
          <InputField
            name={'TikTok'}
            icon={<FaTiktok fontSize={20} />}
            link={genuinUserUrl?.tik_toc_url ?? '-'}
          />
        </div>
      </div>
    </>
  );
};

export default GenuinViewonly;
