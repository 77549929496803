import { headers, routes } from './constants';

export const getCustomerStripeLinkAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getCustomerStripeLink, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getPublisherStripeAccountAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getPublisherStripeAccount, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const postPaymentMethodAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.postPaymentMethod, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getSubscriptionsAPI = (_params: string) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getSubscriptions + _params, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getCreativeStripeLinkAPI = (_params: string) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getCreativeStripeLink + _params, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
