import { useEffect, useState } from 'react';
import {
  getHBlogoutAPI,
  getHBSendEmailsAPI,
  HBLoginUrlAPI,
  HBSendEmailLoginAPI,
} from '../../../services/headerbidder';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { MdInfoOutline } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import store from '../../../states';
import ButtonSkeleton from '../../loaders/button';

const GoogleLogin = ({ openModal, onHide }: any) => {
  const [HbLogin, sHbLogin] = useState(false);
  const [HbEmail, sHbEmail] = useState('');
  const [HBCode, sHBCode] = useState('');
  const [logout, sLogout] = useState(false);
  const [loginStatus, sLoginStatus] = useState(false);
  const [show, sShow] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [btnDisable, sBtnDisable] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  //intial call hb login
  useEffect(() => {
    const payload = {
      redirect_uri: window.location.origin,
    };
    setIsVerifying(true);
    HBLoginUrlAPI(payload)
      .then((data: any) => {
        sHbLogin(data.need_login);
        sHbEmail(data.email);
        setIsVerifying(false);
      })
      .catch((error: any) => {
        //ok
      });
  }, [openModal]);

  //google login modal
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.send',
    onSuccess: async (codeResponse) => {
      sHBCode(codeResponse.code);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  // if google login after call send email api
  useEffect(() => {
    if (HBCode) {
      const payload = {
        code: HBCode,
        redirect_uri: window.location.origin,
      };
      HBSendEmailLoginAPI(payload)
        .then((data: any) => {
          if (data.status === true) {
            sLoginStatus(data.status);
            getHBSendEmailsAPI()
              .then((data: any) => {
                sBtnDisable(true);
                toast.success(data.message);
              })
              .catch((error: any) => {
                //ok
              });
          } else {
            toast.error(data.message);
          }
        })
        .catch(() => {
          //@ts-ignore
        });
    }
  }, [HBCode]);

  // on click button send api call
  const sendEmails = () => {
    getHBSendEmailsAPI()
      .then((data: any) => {
        toast.success(data.message);
        sBtnDisable(true);
      })
      .catch((error: any) => {
        //ok
      });
  };

  // handle logout api
  const handleLogout = () => {
    getHBlogoutAPI()
      .then((data: any) => {
        toast.success(data.message);
        sLogout(false);
        sHbLogin(true);
        sLoginStatus(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  // handle open modal
  const handleModal = () => {
    sShow(true);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered className='hb-login-modal'>
        <Modal.Body className='py-5'>
          <div className='text-center mb-4'>
            <p>
              {glossary.dsp_hb_email_login_modal_content_p1} <b className='fw-lighter'>{HbEmail}</b>{' '}
              {glossary.dsp_hb_email_login_modal_content_p2} <b className='fw-lighter'>{HbEmail}</b>
              .
            </p>
          </div>
          <div className='d-flex gap-3 justify-content-center'>
            <button type='button' className='button button-secondary' onClick={() => sShow(false)}>
              {glossary.dsp_hb_email_login_button_close}
            </button>
            <button
              className='btn google-btn m-0 w-50'
              type='button'
              onClick={() => {
                sShow(false);
                googleLogin();
              }}
            >
              <p className='btn-text'>
                <img className='google-icon' src='https://i.postimg.cc/PJVtc2LR/google-logo.png' />
                {glossary.signup_button_google}
              </p>
            </button>
            {/* <button
              type='button'
              className='button button-primary'
              onClick={() => {
                sShow(false);
                googleLogin();
              }}
            >
              {glossary.dsp_hb_email_login_button_title_2}
            </button> */}
          </div>
        </Modal.Body>
      </Modal>

      {isVerifying ? (
        <ButtonSkeleton height={38} width={120} />
      ) : (
        <>
          {HbLogin && !loginStatus && !logout && (
            <button className='button button-primary' onClick={handleModal}>
              {glossary.dsp_hb_email_login_button_title_1}
            </button>
          )}

          {!HbLogin && loginStatus && logout && (
            <div className='d-flex gap-3' style={{ width: 'calc(100% - 100px)' }}>
              <button
                className={btnDisable ? 'button button-disabled ' : 'button button-primary'}
                onClick={() => {
                  sendEmails();
                }}
                style={{ width: '100%', maxWidth: '165px' }}
              >
                {glossary.dsp_hb_email_login_button_title_3}
              </button>
              <button
                className='button button-secondary'
                onClick={() => {
                  handleLogout();
                }}
              >
                {glossary.dsp_hb_email_login_button_logout}
              </button>
              <div className='d-flex gap-2'>
                <MdInfoOutline fontSize={25} />
                <p className='small' style={{ lineHeight: '20px' }}>
                  {glossary.dsp_hb_email_login_modal_content_p1}{' '}
                  <b className='fw-lighter'>{HbEmail}</b>{' '}
                  {glossary.dsp_hb_email_login_modal_content_p2}{' '}
                  <b className='fw-lighter'>{HbEmail}</b>
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GoogleLogin;
