import { useContext, useState } from 'react';
// import { RiChatFollowUpLine } from 'react-icons/ri';
// import { TbFileInvoice } from 'react-icons/tb';
import { BsCheck2Circle, BsThreeDotsVertical } from 'react-icons/bs';
import {
  followUpAPI,
  invoiceDownloadAPI,
  receiptDownloadAPI,
  receivableAcceptAPI,
  // sendInvoiceAPI,
} from '../../services/settings';
// import { AiOutlineSend } from 'react-icons/ai';
import { toast } from 'react-toastify';
// import { FiDownload, FiUpload } from 'react-icons/fi';
import { UserInfoContext } from '../contexts/userInfoContext';
import { Dropdown, Spinner } from 'react-bootstrap';
import { openNewTab } from './functions';

const PlatformCostTable = (props: any) => {
  const { accessor, row, controller } = props;

  const { customerInfo }: any = useContext(UserInfoContext);
  const [isInprogress, sIsInprogress] = useState(false);

  const isFollowUp =
    row?.status !== 'received' && (row?.due_date !== '-' || new Date() >= new Date(row?.due_date)); // Admin
  const isDownloadInvoice = row?.status !== 'current_running' && row?.status !== 'pending'; // Admin, SPO
  const isUploadReceipt = row?.status === 'invoice'; // SPO
  const isDownloadReceipt = row?.status === 'paid' || row?.status === 'received'; // TODO: Add some key here // Admin
  const isMarkAsPaid =
    row?.status !== 'received' && row?.status !== 'rejected' && row?.status !== 'cancel';

  const receivableAccept = (id: number, customerId: number) => {
    const payload = {
      id: id,
      customer_id: customerId,
    };
    receivableAcceptAPI(payload)
      .then((data: any) => {
        if (data.status) controller();
        toast.success(data.message);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const downloadInvoice = (rowData: any) => {
    const payload = {
      ...rowData,
      type: 'platform_cost',
    };
    sIsInprogress(true);
    invoiceDownloadAPI(payload)
      .then((data: any) => {
        if (data?.url) {
          const link = document.createElement('a');
          link.href = data?.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        sIsInprogress(false);
      })
      .catch((error: any) => {
        // error
      });
  };

  const downloadReceipt = (rowData: any) => {
    const payload = {
      ...rowData,
      type: 'platform_cost',
    };
    sIsInprogress(true);
    receiptDownloadAPI(payload)
      .then((data: any) => {
        if (data?.url) {
          openNewTab(data?.url);
        }
        sIsInprogress(false);
      })
      .catch((error: any) => {
        sIsInprogress(false);
        // error
      });
  };

  const followUp = (rowData: any) => {
    followUpAPI(rowData)
      .then((data: any) => {
        toast.success(data.message);
      })
      .catch((error: any) => {
        //ok
      });
  };

  return (
    <>
      {accessor === 'action' && localStorage.getItem('r') === 'infy-admin' && (
        <>
          <Dropdown>
            {isInprogress ? (
              <span className='suggestion-input'>
                <Spinner
                  className='ms-1'
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              </span>
            ) : (
              <Dropdown.Toggle>
                <BsThreeDotsVertical className='svg-icon' style={{ marginLeft: '2px' }} />
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu className='table-action-cell'>
              <Dropdown.Item disabled={!isFollowUp}>Follow Up</Dropdown.Item>

              <Dropdown.Item disabled={!isDownloadInvoice} onClick={() => downloadInvoice(row)}>
                Download Invoice
              </Dropdown.Item>

              <Dropdown.Item disabled={!isDownloadReceipt} onClick={() => downloadReceipt(row)}>
                Show Receipt
              </Dropdown.Item>

              <Dropdown.Item disabled={!isMarkAsPaid}>Mark as paid</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <div className='gap-3 d-flex'>
            {isFollowUp && (
              <RiChatFollowUpLine
                className={'svg-icon me-2'}
                fontSize={20}
                title={'Follow Up'}
                // onClick={() => followUp(row)}
              />
            )}

            {isMarkAsPaid && (
              <BsCheck2Circle
                className='svg-icon'
                fontSize={20}
                title={'Mark as paid'}
                style={{ strokeWidth: '0.4px' }}
                // onClick={() => receivableAccept(row.id, row.customer_id)}
              />
            )}
          </div> */}
        </>
      )}

      {accessor === 'action' && customerInfo?.customer_type === 'publisher' && (
        <>
          <Dropdown>
            {isInprogress ? (
              <span className='suggestion-input'>
                <Spinner
                  className='ms-1'
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              </span>
            ) : (
              <Dropdown.Toggle>
                <BsThreeDotsVertical className='svg-icon' style={{ marginLeft: '2px' }} />
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu className='table-action-cell'>
              <Dropdown.Item disabled={!isDownloadInvoice} onClick={() => downloadInvoice(row)}>
                Download Invoice
              </Dropdown.Item>

              <Dropdown.Item disabled={!isUploadReceipt} onClick={() => downloadReceipt(row)}>
                Pay
              </Dropdown.Item>

              <Dropdown.Item disabled={!isDownloadReceipt} onClick={() => downloadReceipt(row)}>
                Show Receipt
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}

      {accessor === 'action' && customerInfo?.customer_type === 'creative' && (
        <>
          <Dropdown>
            {isInprogress ? (
              <span className='suggestion-input'>
                <Spinner
                  className='ms-1'
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              </span>
            ) : (
              <Dropdown.Toggle>
                <BsThreeDotsVertical className='svg-icon' style={{ marginLeft: '2px' }} />
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu className='table-action-cell'>
              <Dropdown.Item disabled={!isDownloadInvoice} onClick={() => downloadInvoice(row)}>
                Download Invoice
              </Dropdown.Item>

              <Dropdown.Item disabled={!isDownloadReceipt} onClick={() => downloadReceipt(row)}>
                Show Receipt
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </>
  );
};

export default PlatformCostTable;
