import CreativeNewsPage from './Creative-newspage';

interface Props {
  companyID: number;
  tagID: number | undefined;
  resolution: any;
  device: 'mobile' | 'tablet' | 'desktop';
}

const CreativeMobilePreview = ({ companyID, tagID, resolution, device }: Props) => {
  return (
    <>
      {device === 'mobile' && (
        <div className='mobile-frame'>
          <img src='https://i.postimg.cc/kMRpRDSb/mobile.png' />
          <div className='mobile-frame-box'>
            <CreativeNewsPage
              companyID={companyID}
              tagID={tagID}
              resolution={resolution}
              device={device}
            />
          </div>
        </div>
      )}

      {device === 'tablet' && (
        <div className='tablet-frame'>
          <img src='https://i.postimg.cc/6Q19HTYX/tablet.png' />
          <div className='tablet-frame-box'>
            <CreativeNewsPage
              companyID={companyID}
              tagID={tagID}
              resolution={resolution}
              device={device}
            />
          </div>
        </div>
      )}

      {device === 'desktop' && (
        <div className='desktop-frame'>
          <img src='https://i.postimg.cc/4nCkD9hD/desktop.png' />
          <div className='desktop-frame-box'>
            <CreativeNewsPage
              companyID={companyID}
              tagID={tagID}
              resolution={resolution}
              device={device}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreativeMobilePreview;
