import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';

interface Props {
  kind?: string;
  name?: string;
  label: any;
  onchange(e: any): void;
  value?: any;
  option: any;
  placement?: any;
  search: boolean;
  placeHolder?: any;
  selectAll?: boolean;
  classNamePrefix?: string;
  defaultValue?: any;
  getOptionLabel?: any;
  isMulti?: any;
  closeMenuOnSelect?: any;
  disabled?: boolean;
}

// const CustomOption = (props: any) => {
//   return (
//     <p
//       style={{ backgroundColor: props.isFocused ? 'yellow' : 'inherit' }}
//       {...props.innerProps}
//       ref={props.innerRef}
//     >
//       {props.label}
//     </p>
//   );
// };

const Dropdown = (props: Props) => {
  const root = document.documentElement;
  const primary2 = getComputedStyle(root).getPropertyValue('--primary-2');
  const primary6 = getComputedStyle(root).getPropertyValue('--primary-6');

  const customStyles = {
    placeholder: (provided: any) => ({
      ...provided,
      '&:hover': {
        background: '#f4f4f4',
        color: primary2,
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? primary2 : state.isFocused ? primary6 : '#ffffff',
      color: state.isSelected ? '#ffffff' : state.isFocused ? primary2 : '#000000',
      '&:hover': {
        background: state.isSelected ? primary2 : primary6,
        color: state.isSelected ? '#ffffff' : primary2,
      },
    }),
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      //height: 52,
      border: state.isFocused ? `1.5px solid ${primary2}` : '1px solid #a2a2a2',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: `1.5px solid ${primary2}`,
        boxShadow: 'none',
      },
    }),
  };

  const {
    kind,
    name,
    label,
    onchange,
    value,
    option,
    placement,
    search,
    placeHolder,
    selectAll,
    classNamePrefix,
    defaultValue,
    getOptionLabel, // React-Select
    isMulti, // React-Select
    closeMenuOnSelect, // React-Select
    disabled,
  } = props;

  return (
    <>
      {kind === 'multi' ? (
        <>
          <div className='react-select-dropdown'>
            <div className={disabled ? 'dropdown-disable' : ''}>
              <label className='label'>{label}</label>
              <MultiSelect
                options={option}
                value={value}
                onChange={(e: any) => onchange(e)}
                labelledBy='Select'
                disableSearch={!search}
                hasSelectAll={selectAll ?? true}
                disabled={disabled ?? false}
                // isOpen={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <label className='label'>{label}</label>
            <Select
              onChange={(e: any) => {
                onchange(e);
              }}
              //   onChange={onchange}
              classNamePrefix={classNamePrefix ? classNamePrefix : 'select'}
              name={name}
              options={option}
              styles={customStyles}
              defaultValue={value}
              menuPlacement={placement}
              placeholder={placeHolder ?? 'Select...'}
              isSearchable={search}
              isMulti={isMulti ?? false}
              //classNamePrefix={classNamePrefix}
              value={defaultValue}
              // components={{ Option: CustomOption }}
              getOptionLabel={getOptionLabel}
              closeMenuOnSelect={closeMenuOnSelect}
              isDisabled={disabled ?? false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Dropdown;
