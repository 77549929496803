import { useContext } from 'react';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { openNewTab } from '../../helpers/functions';
import { IoIosArrowForward } from 'react-icons/io';

interface OverlayProps {
  isPlay: boolean;
  isMute: boolean;
  items: any;
  genuinInfo: any;
  onPlayClick(): void;
  onMuteClick(): void;
}

const PlayerOverlay = ({
  isMute = false,
  isPlay = false,
  items,
  genuinInfo,
  onPlayClick,
  onMuteClick,
}: OverlayProps) => {
  const { customerInfo, userInfo, genuin }: any = useContext(UserInfoContext);

  return (
    <>
      <div className='vp_overlay_topbar'>
        <div className='logo'>
          {/* <img
            className='logo_agency'
            src={
              customerInfo?.rtb_config?.creative_data?.agency_logo ||
              'https://i.postimg.cc/prSWyYwS/logo-1.png'
            }
            alt='agency_logo'
          /> */}

          <img
            className='logo_adreel'
            src='https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/adreels-logo_transparent.svg'
            alt='agency_logo'
          />
        </div>

        <div className='topbar_icons'>
          <div>
            <img
              className='me-2 icon_btn'
              src={`https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/${
                isMute ? 'mute' : 'unmute'
              }_shadows.svg`}
              onClick={onMuteClick}
              width={24}
              height={24}
              alt='mute_icon'
            />

            <img
              className='icon_btn'
              src={`https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/${
                !isPlay ? 'play' : 'pause'
              }_shadows.svg`}
              onClick={onPlayClick}
              width={24}
              height={24}
              alt='play_icon'
            />
          </div>

          <img
            src='https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/expand_shadows.svg'
            width={24}
            height={24}
            alt='expand_icon'
          />
        </div>
      </div>

      <div className='vp_overlay_newbottombar'>
        <div className='bottom_inner_container'>
          <div className='left'>
            <div className='owner'>
              <div
                className='owner__img'
                onClick={() =>
                  openNewTab(`${genuin?.website}/profile/${userInfo?.genuine_user_name}`)
                }
              >
                <img
                  src={
                    genuinInfo?.profile_image_s ||
                    'https://media.qa.begenuin.com/webapp_assets/assets/avatar/penguin.gif'
                  }
                  alt='user_profile_img'
                />
              </div>
              <p
                className='owner__name'
                onClick={() =>
                  openNewTab(`${genuin?.website}/profile/${userInfo?.genuine_user_name}`)
                }
              >
                {userInfo?.genuine_user_name}
              </p>
            </div>
          </div>
          <div className='right'>
            <img
              src={'https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/spark_shadows.svg'}
              width={24}
              height={24}
              alt='spark_icon'
            />

            <img
              src={
                'https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/comments_shadows.svg'
              }
              width={24}
              height={24}
              alt='comment_icon'
            />

            <img
              src={'https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/share_shadows.svg'}
              width={24}
              height={24}
              alt='share_icon'
            />

            <img
              src={'https://media.qa.begenuin.com/webapp_assets/assets/icons_v2/more_shadows.svg'}
              width={24}
              height={24}
              alt='more_icon'
            />
          </div>
        </div>
        <div className='cta'>
          {items?.ad_copy !== '' && (
            <div className='cta__adcopy'>
              <p className='cta__adcopy__text'>{items?.ad_copy}</p>
            </div>
          )}

          <div
            className='cta__button'
            style={{
              backgroundColor:
                customerInfo?.rtb_config?.creative_data?.advertiser_brand_color || '#123787',
            }}
            onClick={() => openNewTab(items?.cta_link || genuin?.website)}
          >
            <p>{items?.cta_text || 'Learn More'}</p>
            <IoIosArrowForward fontSize={18} className='arrow' />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerOverlay;
