import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import HeaderBidder from '../components/headerBidder/create';
import Addrtbendpoint from '../components/RTB/RTB-endpoint';
import { useEffect, useState } from 'react';
// import CreateVastTagDsp from '../helpers/CreateVastTagDsp-Delete';
import store from '../../states';
import GoogleAdsLogin from '../components/GAM/GAM-create';
// import CreateVastXml from '../helpers/CreateVastXml';
import InsertionOrder from '../components/insertion-order/InsertionOrder';
// import ContextualReel from '../components/reel-IO/reel-io-create';
// import { genuinTab } from '../helpers/functions';
// import CampaignBuild from '../components/campaignBuild/campaign-build';

export default (props: any) => {
  const { show, onHide, getEndPointList, customerID } = props;

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [isClosable, setIsClosable] = useState(true);
  const handleCloseModal = (val: boolean) => {
    setIsClosable(val);
  };

  return (
    <>
      <Modal
        className='tabs-modal'
        onHide={() => {
          onHide();
        }}
        show={show}
        centered
        size={'xl'}
        backdrop={isClosable ? true : 'static'}
        keyboard={isClosable ? true : false}
      >
        <Tabs defaultActiveKey='rtb'>
          <Tab eventKey='rtb' title={glossary.dsp_source_add_popup_tab_rtb} disabled={!isClosable}>
            <Addrtbendpoint
              getEndPointListCard={getEndPointList}
              onHide={onHide}
              customerID={customerID}
            />
          </Tab>
          <Tab eventKey='hb' title={glossary.dsp_source_add_popup_tab_hb} disabled={!isClosable}>
            <HeaderBidder
              getEndPointListCard={getEndPointList}
              onHide={onHide}
              customerID={customerID}
              show={show}
            />
          </Tab>
          <Tab
            eventKey='insertionOrder'
            title={glossary.dsp_source_add_popup_tab_insertion_order}
            disabled={!isClosable}
          >
            <InsertionOrder
              getEndPointListCard={getEndPointList}
              onHide={onHide}
              handleCloseModal={handleCloseModal}
              customerID={customerID}
            />
          </Tab>
          {/* <Tab eventKey='vastXML' title='VAST XML'>
            <CreateVastXml getEndPointListCard={getEndPointList} onHide={onHide} />
          </Tab>
          <Tab eventKey='vastURL' title={glossary.dsp_source_add_popup_tab_vast}>
            <CreateVastTagDsp
              getEndPointListCard={getEndPointList}
              onHide={onHide}
              getEndpointListInfoAPI={getEndpointListInfoAPI}
            />
          </Tab> */}
          <Tab eventKey='GAM' title='GAM' disabled={!isClosable}>
            <GoogleAdsLogin getEndPointListCard={getEndPointList} onHide={onHide} />
          </Tab>
          {/* <Tab
            tabClassName='genuin-tab'
            eventKey='contextualReel'
            title={genuinTab(glossary.dsp_source_add_popup_tab_contextual_reel)}
            disabled={!isClosable}
          >
            <ContextualReel
              getEndPointListCard={getEndPointList}
              onHide={onHide}
              customerID={customerID}
            />
          </Tab> */}
          {/* <Tab eventKey='camp' title='Campaign Builder' disabled={!isClosable}>
            <CampaignBuild onHide={onHide} />
          </Tab> */}
        </Tabs>
      </Modal>
    </>
  );
};
