import ContentLoader from 'react-content-loader';

const TextSkeleton = ({ width, height, lineCount }: any) => {
  const lineSpacing = 10; // adjust as needed
  const loaderHeight = height + (lineCount - 1) * lineSpacing;

  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${loaderHeight}`}
      height={loaderHeight}
      width={width}
      speed={2}
    >
      {[...Array(lineCount)].map((_, i) => (
        <rect
          key={i}
          x='0'
          y={i * (height + lineSpacing)}
          rx='3'
          ry='3'
          width={width}
          height={height}
        />
      ))}
    </ContentLoader>
  );
};

export default TextSkeleton;
