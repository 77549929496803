import Avatar from 'react-avatar';
import JWT from 'jsonwebtoken';
// import { IoRemoveCircleOutline, IoKeyOutline } from 'react-icons/io5';
import { approveUserAPI } from '../../services/settings';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import { useEffect, useState } from 'react';
import DeleteUser from '../modals/user-delete';
import Role from '../modals/role';

const SettingsUser = (props: any) => {
  const { accessor, row, getUsersList, rowsId } = props;

  //Settings > Users List > User Request Approve
  const approve = (id: number) => {
    const payload = {
      user_id: id,
      approve: 'yes',
    };
    approveUserAPI(payload)
      .then((data: any) => {
        if (data.status) getUsersList();
      })
      .catch(() => {
        //
      });
  };

  //Remove User & Get Users List Functionality
  const [userShow, sUserShow] = useState(false);
  const [rowId, setRowId] = useState('');

  const removeUser = (id: any) => {
    sUserShow(true);
    setRowId(id);
  };

  const [user, sUser] = useState('');
  const [roleModal, sRoleModal] = useState(false);
  const [userRole, sUserRole] = useState();
  const [usersRole, setUserRole] = useState<any>('');
  const [usersRoleId, setUsersRoleId] = useState<number>();
  const [customerID, sCustomerID] = useState<number>();

  const setRoleModal = (id: string, roleId: number, roleName: string) => {
    sUser(id);
    sRoleModal(true);
    setUserRole(roleName);
    setUsersRoleId(roleId);
  };

  useEffect(() => {
    setUserRole(row.role_id);
  }, [row]);

  // console.log('id', accessor, usersRole);

  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    sUserRole(decoded.payload.role);
    // @ts-ignore
    sCustomerID(decoded.payload.user_id);
  }, []);

  return (
    <>
      <Role
        id={user}
        //toast={toast}
        show={roleModal}
        onHide={() => {
          sRoleModal(false);
        }}
        getUsers={getUsersList}
        userRole={userRole}
        usersRoleId={usersRoleId}
        usersRoleName={usersRole}
      />
      <DeleteUser
        onHide={() => {
          sUserShow(false);
        }}
        show={userShow}
        id={rowId}
        api={getUsersList}
      />
      {/* User Avatar & Name for Settings > Users Table */}
      {accessor === 'firstname' && (
        <Avatar className='me-2' src={row?.thumb ?? 'U'} size={'30'} round />
      )}

      {/* Use for Setting > Users Table */}
      {accessor === 'active' && <p>{row.active ? 'Active' : 'Inactive'}</p>}

      {/* Use Only for Settings >  Users Table */}
      {accessor === 'action' && (
        <>
          {(userRole === 4 || userRole === 1) && row.id !== customerID ? (
            row.active ? (
              <>
                <MdDeleteOutline
                  className='svg-icon me-3 ms-2'
                  fontSize={20}
                  title={'Remove'}
                  onClick={() => {
                    removeUser(row.id);
                  }}
                />
              </>
            ) : (
              <p
                className='text-button ps-2'
                onClick={() => approve(row?.id)}
                style={{ fontWeight: 200 }}
              >
                Approve
              </p>
            )
          ) : (
            //Action Column in Last
            row.id !== customerID && '-'
          )}
        </>
      )}

      {/* User Role Assign Functionality & Icon */}
      {accessor === 'role_name' && (
        <>
          {row.id !== customerID && (
            <BiEditAlt
              className='svg-icon me-2'
              fontSize={19}
              title={'Access Control'}
              onClick={() => {
                setRoleModal(row?.id, row?.role_id, row?.role_name);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default SettingsUser;
