import Switch from '../widgets/switch';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import Avatar from 'react-avatar';
import { assignManagerToCustomerAPI } from '../../services/customers';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import store from '../../states';
import ReactGA from 'react-ga4';
import menu from '../../assets/icons/menu.svg';
import { updateCustomerStatusAPI } from '../../services/settings';

const AdminSettingsCustomerTable = (props: any) => {
  const { accessor, row, getCustomers } = props;

  const updateCustomer = (id: number, status: string, api: any) => {
    const payload = {
      id,
      status,
    };
    updateCustomerStatusAPI(payload)
      .then(() => {
        api();
      })
      .catch(() => {
        //
      });
  };

  const CusMenuCard = ({ id, flag, api }: any) => {
    const [contextMenu, sContextMenu] = useState(false);

    const toggleContextMenu = (e: any) => {
      const allow = document.getElementById(`open-${id}`);
      const target = e.target;
      if (target === allow) {
        sContextMenu(true);
      } else {
        sContextMenu(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', toggleContextMenu, false);
      return () => document.removeEventListener('click', toggleContextMenu, false);
    }, []);

    const [glossary, sGlossary] = useState<any>({});
    useEffect(() => {
      sGlossary(store.getState());
      const unsubscribe = store.subscribe(() => {
        sGlossary(store.getState());
      });
      return () => unsubscribe();
    }, []);

    return (
      <div className='mt-2'>
        {contextMenu ? (
          <div className='table-menu'>
            <img
              id={`close-${id}`}
              className='svg-icon'
              style={{ cursor: 'pointer' }}
              src={menu}
              height={16}
              width={16}
            />
            <div className={'context table-menu-list'}>
              {flag === 1 ? (
                <p
                  className='table-menu-list-item'
                  onClick={() => {
                    updateCustomer(id, 'on_hold', api);
                    ReactGA.event('sidebar_customers_list', {
                      category: 'update_customers_list',
                      action: 'click',
                      label: 'put_on_hold',
                    });
                  }}
                >
                  {glossary.setting_customers_context_menu_option_put_on_hold}
                </p>
              ) : (
                <p
                  className='table-menu-list-item'
                  onClick={() => {
                    updateCustomer(id, 'active', api);
                    ReactGA.event('sidebar_customers_list', {
                      category: 'update_customers_list',
                      action: 'click',
                      label: 'put_on_active',
                    });
                  }}
                >
                  {glossary.setting_customers_context_menu_option_put_on_active}
                </p>
              )}
            </div>
          </div>
        ) : (
          <>
            <img
              id={`open-${id}`}
              className='svg-icon'
              style={{ cursor: 'pointer' }}
              src={menu}
              height={16}
              width={16}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {/* Company Name Columne Avatar show in table */}
      {accessor === 'company_name' && (
        <>{row?.thumb && <Avatar className='me-2' src={row?.thumb ?? 'U'} size={'30'} round />}</>
      )}

      {/* For Actions column functionality */}
      {accessor === 'action' && (
        <>
          <CusMenuCard id={row.id} flag={row.status === 'active' ? 1 : 0} api={getCustomers} />
        </>
      )}
    </>
  );
};

export default AdminSettingsCustomerTable;
