import { useEffect, useState } from 'react';
import { FormCheck, Modal } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
// import { MdDeleteOutline } from 'react-icons/md';
import { FaTelegramPlane } from 'react-icons/fa';

import { inviteUserAPI } from '../../services/settings';
import store from '../../states';

import Button from '../widgets/button';
import Dropdown from '../widgets/dropdown';
import FormikController from '../comman/form/FormikController';

export default (props: any) => {
  const { show, onHide, getUsers, toast, userRole } = props;

  const [section, sSection] = useState('1');
  const [firstLogin, setFirstLogin] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [roleValue, sRoleValue] = useState<any>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // Multiple Toast Message Handle Start //
  // const [isToastShown, setIsToastShown] = useState(false);

  // const handleToast = (msg: string) => {
  //   if (!isToastShown) {
  //     toast.error(msg);
  //     // toast.error(glossary.setting_user_invite_popup_user_exists_error);
  //     setIsToastShown(true);
  //   }
  // };
  // Multiple Toast Message Handle End //

  const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

  const userInviteValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required(glossary.setting_user_invite_popup_input_name_empty_error)
      .trim()
      .notOneOf([''], glossary.setting_user_invite_popup_input_name_empty_error),
    email: Yup.string()
      .required(glossary.setting_user_invite_popup_input_email_empty_error)
      .email(glossary.setting_user_invite_popup_input_email_valid_error),
    // .test(
    //   'superior',
    //   glossary.setting_user_invite_popup_input_email_valid_error,
    //   function (f2: any) {
    //     return emailRegex.test(f2);
    //   }
    // ),
  });

  const selectRole = (value: any) => {
    sRoleValue(value.value);
  };

  const initiateUserInvitation = (e: any) => {
    ReactGA.event('invite_user_next_button', {
      category: 'invite',
      action: 'invite_button',
      label: 'user_invite_popup_next_button',
      name: e.name,
      email: e.email,
      role_id: e.role,
    });
    const payload = {
      users: [
        {
          name: e.name,
          email: e.email,
          role_id: roleValue.toString() || userRole.toString(),
          password: e.password,
          first_login: firstLogin,
        },
      ],
    };
    setIsSubmitting(true);
    inviteUserAPI(payload)
      .then((data: any) => {
        ReactGA.event('sidebar_user_invite_response', {
          category: 'api_response',
          action: 'api_response',
          label: data,
        });
        if (data?.failed?.length < 1) {
          getUsers(data.success[0]);
          sSection('2');
        } else {
          toast.error(data?.failed[0]?.msg);
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 300);
      })
      .catch(() => {
        //
      });
  };

  let roleOption: any = [];
  let defaultRole: any = {};

  switch (userRole) {
    case 1:
      roleOption = [
        { label: 'Admin', value: 1 },
        { label: 'Manager', value: 2 },
        { label: 'Editor', value: 3 },
      ];
      defaultRole = { label: 'Admin', value: 1 };
      break;
    case 4:
      roleOption = [
        { label: 'Admin', value: 4 },
        { label: 'Manager', value: 5 },
        { label: 'Editor', value: 6 },
      ];
      defaultRole = { label: 'Admin', value: 4 };
      break;
    case 5:
      roleOption = [
        { label: 'Manager', value: 5 },
        { label: 'Editor', value: 6 },
      ];
      defaultRole = { label: 'Manager', value: 5 };
      break;
    default:
      break;
  }

  const handlePassword = (e: any) => {
    const { value } = e.target;
    if (value !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setFirstLogin(false);
    }
  };

  const initialValue = () => {
    setIsDisabled(true);
    setFirstLogin(false);
    setTimeout(() => {
      sSection('1');
    }, 500);
  };

  const root = document.documentElement;
  const primary2 = getComputedStyle(root).getPropertyValue('--primary-2');

  return (
    <Modal
      onHide={() => {
        onHide();
        initialValue();
        // setIsToastShown(false);
      }}
      show={show}
      centered
      size={'lg'}
    >
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
        }}
        validationSchema={userInviteValidationSchema}
        onSubmit={(e) => {
          initiateUserInvitation(e);
        }}
      >
        {({ values }) => (
          <Form>
            {/* Invite Form Section */}
            {section === '1' && (
              <>
                <Modal.Header>
                  <h2>{glossary.setting_user_invite_popup_title}</h2>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    <div className='col-6 mb-3'>
                      <FormikController
                        type='text'
                        control='input'
                        kind='withouticon'
                        name={'name'}
                        maxLength={64}
                        label={glossary.setting_user_invite_popup_input_field_label_name}
                        placeholder={glossary.setting_user_invite_popup_input_name_placeholder}
                      />
                    </div>

                    <div className='col-6 mb-3'>
                      <FormikController
                        type='text'
                        control='input'
                        kind='withouticon'
                        name={'email'}
                        maxLength={256}
                        label={glossary.setting_user_invite_popup_input_field_label_email}
                        placeholder={glossary.setting_user_invite_popup_input_email_placeholder}
                      />
                    </div>

                    <div className='col-6 mb-3'>
                      <FormikController
                        control='input'
                        kind='password'
                        name={'password'}
                        maxLength={256}
                        label={glossary.admin_invite_popup_input_field_label_password}
                        placeholder={glossary.admin_invite_popup_input_password_placeholder}
                        onKeyUp={handlePassword}
                      />
                    </div>

                    <div className='col-6 mb-3'>
                      <Dropdown
                        label={glossary.setting_user_invite_popup_ddl_field_label_role}
                        option={roleOption}
                        onchange={selectRole}
                        value={defaultRole}
                        search={false}
                      />
                    </div>

                    <div className='col-6'>
                      <label className='label'>
                        {glossary.admin_invite_popup_field_label_password_change}
                      </label>
                      <FormCheck
                        checked={firstLogin}
                        disabled={isDisabled}
                        type='checkbox'
                        label={glossary.admin_invite_popup_checkbox_label_on_first_login}
                        className='mt-2'
                        onChange={() => setFirstLogin(!firstLogin)}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className='gap-2'>
                  <Button
                    type='button'
                    text={glossary.setting_user_invite_popup_button_cancel}
                    kind={'secondary'}
                    click={() => {
                      onHide();
                      initialValue();
                      ReactGA.event('invite_cancel_button', {
                        category: 'invite',
                        action: 'click',
                        label: 'invite_popup_cancel_button',
                      });
                    }}
                  />
                  {isSubmitting ? (
                    <Button text={glossary.loading} kind={'spinner'} type={'button'} />
                  ) : (
                    <Button
                      type='submit'
                      text={glossary.setting_user_invite_popup_button_next}
                      kind={'primary'}
                    />
                  )}
                </Modal.Footer>
              </>
            )}

            {/* Invite Success Section */}
            {section === '2' && (
              <div style={{ display: 'flex', margin: 40 }}>
                <div
                  className={'row'}
                  style={{
                    margin: 'auto',
                    justifyContent: 'center',
                    textAlign: 'center',
                    maxWidth: 300,
                  }}
                >
                  <FaTelegramPlane fontSize={130} color={primary2} />
                  <br />
                  <br />
                  <h2>{glossary.setting_user_invite_success_popup_title}</h2>
                  <br />
                  <br />
                  <p>{glossary.setting_user_invite_success_popup_instruction}</p>
                  <br />
                  <br />
                  <div style={{ marginTop: 20 }}>
                    <Button
                      type='button'
                      kind={'primary'}
                      text={glossary.setting_user_invite_success_popup_button_done}
                      click={() => {
                        onHide();
                        initialValue();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
