import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import store from '../../states';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { addMacrosAPI, updateMacrosAPI } from '../../services/macros';
import FormikController from '../comman/form/FormikController';
import Button from '../widgets/button';

export default (props: any) => {
  const { show, onHide, rowData, api, getMacros } = props;

  const [glossary, sGlossary] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // Field Validation
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(glossary.setting_ssp_add_macros_popup_input_name_empty_error),
    parameter: Yup.string().required(
      glossary.setting_ssp_add_macros_popup_input_parameter_empty_error
    ),
    value: Yup.string().required(glossary.setting_ssp_add_macros_popup_input_value_empty_error),
  });

  // Formik Initials value
  const RtbFormInitialValues = {
    name: rowData?.name ?? '',
    parameter: rowData?.parameters ?? '',
    value: rowData?.macros ?? '',
    default: rowData?.default ?? false,
  };

  // add macros api call
  const addMacros = (e: any) => {
    const payload = {
      name: e.name,
      macros: e.value,
      parameters: e.parameter,
      object: 'Custom',
      default: e.default,
    };
    setIsLoading(true);
    addMacrosAPI(payload)
      .then((data: any) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);

        if (data.status === true) {
          getMacros(data.data);
          toast.success(data.message);
          onHide();
          api();
        } else {
          toast.error(data.message);
        }
      })
      .catch(() => {
        //ok
      });
  };

  // update macros api call
  const updateMacros = (e: any, data: any) => {
    const payload = {
      platform_macro_id: data.id,
      // object:<str>,[optional],
      macros: e.value,
      name: e.name,
      parameters: e.parameter,
      object: 'Custom',
      default: e.default,
      // rtb_value_type:<str>[optional],
      // rtb_key:<str>[optional],
    };
    setIsLoading(true);
    updateMacrosAPI(payload)
      .then((data: any) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);

        if (data.status === true) {
          onHide();
          api();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch(() => {
        //ok
      });
  };

  return (
    <Modal
      centered
      size={'lg'}
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <div className='modal-header px-3'>
        <h2>
          {rowData
            ? glossary.setting_ssp_update_macros_popup_title
            : glossary.setting_ssp_add_macros_popup_title}
        </h2>
      </div>
      <Formik
        validationSchema={SignupSchema}
        initialValues={RtbFormInitialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(e) => {
          if (rowData) {
            updateMacros(e, rowData);
          } else {
            addMacros(e);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className='rtb-form'>
            <div
              className={'modal-body p-3'}
              style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto',
              }}
            >
              <div className='row mb-3'>
                <div className='col-12 mb-2'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.setting_ssp_add_macros_popup_input_field_label_name}
                      placeholder={glossary.setting_ssp_add_macros_popup_input_name_placeholder}
                      name='name'
                      maxLength={64}
                    />
                  </div>
                </div>
                <div className='col-12 mb-2'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.setting_ssp_add_macros_popup_input_field_label_parameter}
                      placeholder={
                        glossary.setting_ssp_add_macros_popup_input_parameter_placeholder
                      }
                      name='parameter'
                      maxLength={1024}
                    />
                  </div>
                </div>
                <div className='col-12 mb-2'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.setting_ssp_add_macros_popup_input_field_label_value}
                      placeholder={glossary.setting_ssp_add_macros_popup_input_value_placeholder}
                      name='value'
                      maxLength={1024}
                    />
                  </div>
                </div>
                <div className='col-12 mb-2'>
                  <label className='label'>
                    <Field type='checkbox' name='default' />
                    <span className='ms-2'>Default</span>
                  </label>
                </div>
              </div>
            </div>

            <Modal.Footer className='gap-2'>
              <Button
                text={glossary.setting_ssp_add_macros_popup_button_cancel}
                type={'button'}
                kind={'secondary'}
                click={() => {
                  onHide();
                }}
              />

              {isLoading ? (
                <Button kind={'spinner'} text={glossary.loading} type={'button'} />
              ) : (
                <Button
                  text={
                    rowData
                      ? glossary.setting_ssp_update_macros_popup_button_update_macros
                      : glossary.setting_ssp_add_macros_popup_button_add_macros
                  }
                  type={'submit'}
                  kind={'primary'}
                />
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
