import React from 'react';
import Input from './Input';
import TextArea from './TextArea';
// import Select from 'Select.js';
//import RadioButtons from './RadioButtons';
import CheckBoxes from './Checkboxes';

const FormikController = (props: any) => {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'textArea':
      return <TextArea {...rest} />;
    // case 'select':
    //   return <Select {...rest} />;
    // case 'radio':
    //   return <RadioButtons {...rest} />;
    case 'checkbox':
      return <CheckBoxes {...rest} />;
    default:
      return null;
  }
};
export default FormikController;
