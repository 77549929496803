import { FC, useState } from 'react';
import { IoCopyOutline } from 'react-icons/io5';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { useClipboard } from 'use-clipboard-copy';

interface IClipboardCopy {
  content: string | number;
  iconSize?: number | undefined;
}

const ClipboardCopyIcon: FC<IClipboardCopy> = ({ content, iconSize = 20 }) => {
  const clipboard = useClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    clipboard.copy(content.toString());

    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <button className={`copy-icon-btn ${isCopied ? '' : 'cursor-pointer'}`} onClick={handleCopy}>
      {isCopied ? (
        <IoCheckmarkSharp className='s-svg-icon' title={'Copied'} fontSize={iconSize} />
      ) : (
        <IoCopyOutline
          className='svg-icon'
          title={'Copy'}
          fontSize={iconSize}
          onClick={handleCopy}
        />
      )}
    </button>
  );
};

export default ClipboardCopyIcon;
