import { headers, routes } from './constants';

export const overviewAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.overview, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getTagDetailsAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getTagDetails + `/${payload.id}`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const createTagAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.createTag, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const editTagAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.editTag, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getTagsAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getTags, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateTagAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateTag, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sendEmailAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sendEmail, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const reportsAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(
      // (payload.type === 'admin' ? routes.getCustomer : routes.getTagDetails) +
      // `/${payload.id}${payload.path}`,
      (payload.type === 'endpoint'
        ? routes.getEndpointReport
        : payload.type === 'partner'
        ? routes.getPartnerReport
        : payload.type === 'admin'
        ? routes.getCustomer
        : routes.getTagDetails) + `/${payload.id}${payload.path}`,
      options
    )
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateAdConfigAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateAdConfig + `/${payload.config}`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateTourAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateTour, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const deleteTagAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.deleteTag, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getEndpointTagsAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getEndpointTags, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sourceTagsExcludeAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sourceTagsExclude, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sourceTagsIncludeAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sourceTagsInclude, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sspEndpointToggleActiveAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sspEndpointToggleActive, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sspEndpointToggleInactiveAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sspEndpointToggleInactive, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateEndpointTagPercentageAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateEndpointTagPercentage, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getPublisherInventoriesAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getPublisherInventories, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getPublisherDealDemandAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getPublisherDealDemand, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sspAssignedDealDemandListAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sspAssignedDealDemandList, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sspDemandAssignToDealAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sspDemandAssignToDeal, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const sspRemoveDemandFromDealAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.sspRemoveDemandFromDeal, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getSspInventoriesCSVAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getPublisherInventories, options)
      .then((data) => data.blob())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateTagSettingAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateTagSetting + `/${payload.id}/settings`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateTagFloorpriceAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateTagFloorprice, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getTagSettingAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateTagSetting + `/${payload.id}/settings`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const adReelAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.adReel, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const reportToggleStatusChangeAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.reportToggleStatusChange, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
