import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Redirect, BrowserRouter, useHistory } from 'react-router-dom';
import JWT from 'jsonwebtoken';
import ReactGA from 'react-ga4';
// import { IntercomProvider } from 'react-use-intercom';
// import LogRocket from 'logrocket';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './assets/styles/main.scss';

import Header from './modules/widgets/header';
import BarSide from './modules/widgets/barside';
import Signup from './modules/pages/signup';
import SignupPass from './modules/pages/signup-pass';
import SignupDone from './modules/pages/signup-done';
import SignupDsp from './modules/pages/signup-dsp';
import Login from './modules/pages/login';
import LoginPassword from './modules/pages/login-password';
import ForgotPassword from './modules/pages/forgot-password';
import LinkSent from './modules/pages/link-sent';
import CreatePass from './modules/pages/create-password';
import PasswordSet from './modules/pages/password-set';
import Customers from './modules/pages/admin/customers';
import Inventory from './modules/pages/admin/inventory';
import Deals from './modules/pages/admin/deals';
import Settings from './modules/pages/settings/index';
import Traffic from './modules/pages/admin/trafficDashboard';
import SignupPassDsp from './modules/pages/signup-pass-dsp';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import { getMsaDetailsCAPI } from './services/msaSign';
import { getCustomerInfoAPI } from './services/customers';
import { UserInfoContext } from './modules/contexts/userInfoContext';
import { getUserInfoAPI } from './services/authentication';
import PublisherSupply from './modules/pages/publisher/Supply';
import DemandSources from './modules/pages/demand/Demand';
import DemandDeals from './modules/pages/demand/Deals';
import PublisherDemand from './modules/pages/publisher/Demand';
import SPOPublisher from './modules/pages/spo/Publisher';
import PgPmp from './modules/pages/publisher/PgPmp';
import { OverviewDataProvider } from './modules/contexts/OverViewContext';
import MyCreatives from './modules/pages/creative/MyCreatives';
import store from './states';
import VideoLibrary from './modules/pages/creative/VideoLibrary';
import { cretivesDomain } from './modules/helpers/functions';
import BrandConfig from './modules/pages/creative/BrandConfig';

//Google Analytics
ReactGA.initialize('G-Z9G9HWSQ6E');
ReactGA.send('pageview');

//Intercom

// const INTERCOM_APP_ID =
//   process.env.REACT_APP_API_URL === 'https://webapp.infy.tv/' ? 'ecra7xvg' : 'k81ghcet';
// const onHide = () => console.log('Intercom did hide the Messenger');
// const onShow = () => console.log('Intercom did show the Messenger');

// LogRocket.init('zjhwxw/infytv');
// LogRocket.init(
//   process.env.REACT_APP_API_URL === 'https://webapp.infy.tv/'
//     ? 'fq10wa/adreels-dashboard'
//     : '9ex1kl/adreel-dashboard'
// );

const isAuthenticated = () => {
  const token = localStorage.getItem('token') || '';
  if (token !== null || token !== '' || token !== undefined) {
    const decoded = JWT.decode(token, { complete: true });
    if (decoded !== null) {
      if (decoded.payload) {
        // @ts-ignore
        if (decoded.payload.exp && decoded.payload.exp > Math.floor(Date.now() / 1000)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isAdmin = () => {
  const token = localStorage.getItem('token') || '';
  const decoded = JWT.decode(token, { complete: true });
  if (decoded !== null) {
    if (decoded.payload) {
      // @ts-ignore
      if (decoded.payload.admin) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const Public = (props: any) => {
  // localStorage.clear();
  return (
    <Route
      render={() => {
        return !isAuthenticated() ? props.children : <Redirect to='/' />;
      }}
    />
  );
};

const Private = (props: any) => {
  return (
    <Route
      render={() => {
        return isAuthenticated() ? props.children : <Redirect to='/login' />;
      }}
    />
  );
};

const Admin = (props: any) => {
  return (
    <Route
      render={() => {
        return isAuthenticated() && isAdmin() ? props.children : <Redirect to='/' />;
      }}
    />
  );
};

const SPO = (props: any) => {
  return (
    <Route
      render={() => {
        return isAuthenticated() && isSPO() ? props.children : <Redirect to='/' />;
      }}
    />
  );
};

const Creative = (props: any) => {
  return (
    <Route
      render={() => {
        return isAuthenticated() && isCreative() ? props.children : <Redirect to='/' />;
      }}
    />
  );
};

const Customer = (props: any) => {
  if (!isAuthenticated() || isAdmin()) {
    return <Redirect to='/' />;
  }

  if (isCreative()) {
    return <Redirect to='/my-creatives' />;
  }

  if (isDSP() || isSPO()) {
    if (props.path === '/publisher/supply') {
      return <Redirect to='/sources/demand' />;
    }
  } else {
    if (props.path === '/sources/demand') {
      return <Redirect to='/publisher/supply' />;
    }
  }

  return props.children;
};

// const Customer = (props: any) => {
//   return (
//     <Route
//       render={() => {
//         return isAuthenticated() && !isAdmin() ? (
//           isDSP() || isSPO() ? (
//             props.path === '/publisher/supply' ? (
//               <Redirect to='/sources/demand' />
//             ) : (
//               props.children
//             )
//           ) : props.path === '/sources/demand' ? (
//             <Redirect to='/publisher/supply' />
//           ) : (
//             props.children
//           )
//         ) : (
//           <Redirect to='/' />
//         );
//       }}
//     />
//   );
// };

const isDSP = () => {
  const customer_type = localStorage.getItem('customer_type') || '';
  if (customer_type === 'demand') {
    return true;
  } else {
    return false;
  }
};

const isPublisher = () => {
  const customer_type = localStorage.getItem('customer_type') || '';
  if (customer_type === 'publisher' || customer_type === 'supply') {
    return true;
  } else {
    return false;
  }
};

const isSPO = () => {
  const customer_type = localStorage.getItem('customer_type') || '';
  if (customer_type === 'spo') {
    return true;
  } else {
    return false;
  }
};

const isCreative = () => {
  const customer_type = localStorage.getItem('customer_type') || '';
  if (customer_type === 'creative') {
    return true;
  } else {
    return false;
  }
};

// Get the current domain from the URL
// const currentDomain = window.location.hostname;

// TODO: Update client_id as per environment currently same for qa and prod
// Define the default client ID
const CLIENT_ID = '467930681343-6b9gs2vu8rj0d6ni58ut93cafgldr9d3.apps.googleusercontent.com';

// PROD: 467930681343-6b9gs2vu8rj0d6ni58ut93cafgldr9d3.apps.googleusercontent.com

// Check if the domain matches 'sbapp.infy.tv'
// if (currentDomain === 'app.infy.tv') {
//   CLIENT_ID = '975554017795-1l2d2aeahhdkl0ob2moolt98fgpe31jr.apps.googleusercontent.com';
// }

// const CLIENT_ID = process.env.REACT_APP_API_URL
//   ? '975554017795-1l2d2aeahhdkl0ob2moolt98fgpe31jr.apps.googleusercontent.com'
//   : '573524062061-k5mbv50ojclk48q6mu7ma99o5050knvd.apps.googleusercontent.com';

//* FIND PARAMS FROM URL AND RETURN IT
const getUrlParams = (url: string) => {
  const params: any = new URLSearchParams(new URL(url).search);
  const result: any = {};

  for (const [key, value] of params.entries()) {
    result[key] = value;
  }

  return result;
};

/* Handle Initial Necessary API calls Start */
const MyProvider: any = ({ children }: any) => {
  const [initialApiCalled, setInitialApiCalled] = useState(false);
  const [msaDetails, setMsaDetails] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [glossary, sGlossary] = useState({});
  const { push } = useHistory();

  const clearAll = () => {
    setMsaDetails({});
    setUserInfo({});
    setCustomerInfo({});
  };

  const handleInitialState = () => {
    initialAPI();
    getUserInfo();
  };

  useEffect(() => {
    if (localStorage.getItem('r') === 'customer' && !initialApiCalled) {
      setInitialApiCalled(true);
      initialAPI();
      getUserInfo();
    }
    if (localStorage.getItem('r') === 'infy-admin' && !initialApiCalled) {
      setInitialApiCalled(true);
      getUserInfo();
    }

    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, [initialApiCalled]);

  const initialAPI = () => {
    if (localStorage.getItem('customer_type') !== 'creative') {
      getMsaDetailsCAPI()
        .then((data: any) => {
          setMsaDetails(data);
        })
        .catch((error) => {
          //
        });
    }
    getCustomerInfo();
  };

  const getUserInfo = () => {
    getUserInfoAPI()
      .then((data: any) => {
        setUserInfo(data);
      })
      .catch((error) => {
        //
      });
  };

  const getCustomerInfo = () => {
    getCustomerInfoAPI()
      .then((res: any) => {
        setCustomerInfo(res.data);
      })
      .catch((error) => {
        //
      });
  };

  //* DIRECT LOGIN FROM BCC DASHBOARD USING ACCESS TOKEN IN URL PARAMS
  const loginFromBcc = (params: any) => {
    if (params.access_token) {
      localStorage.setItem('token', params.access_token);
      localStorage.setItem('role_id', params?.role);

      if (params.first_login === 'true') {
        localStorage.clear();
        push(`/create-password/${params.reset_token}`, params.access_token);
      } else {
        localStorage.setItem('r', 'customer');
        localStorage.setItem('customer_type', params.customer_type);
        push('/my-creatives');
        handleInitialState();
      }
    } else {
      toast.error('Something went wrong!');
    }
  };

  const params = getUrlParams(window.location.href);

  useEffect(() => {
    if (params.access_token) {
      loginFromBcc(params);
    }
  }, [params.access_token]);

  //* LOGIN FROM BCC DASHBOARD END

  useEffect(() => {
    const root = document?.documentElement;
    const hasCreatives = cretivesDomain();

    root?.style.setProperty('--primary-1', hasCreatives ? '#123787' : '#4e0c59');
    root?.style.setProperty('--primary-2', hasCreatives ? '#0645ff' : '#731780');
    root?.style.setProperty('--primary-3', hasCreatives ? '#5173fb' : '#be69bf');
    root?.style.setProperty('--primary-4', hasCreatives ? '#7a93ff' : '#cc91cb');
    root?.style.setProperty('--primary-5', hasCreatives ? '#c2d0ff' : '#d9bdd7');
    root?.style.setProperty('--primary-6', hasCreatives ? '#e5ebff' : '#e6d8e4');
    root?.style.setProperty('--primary-7', hasCreatives ? '#f2f5ff' : '#f9f6f9');

    // if (hasCreatives) {
    //   const link: any = document?.querySelector("link[rel*='icon']");
    //   link.href = 'https://i.postimg.cc/4yDYq5FG/Frame-1.png';
    //   document.title = 'Genuin AdReels';
    // }
  }, []);

  const genuin: { scriptURL: string; demoPageURL: string; website: string; serverURL: string } = {
    scriptURL: 'https://genuin-qa-media.s3.us-west-2.amazonaws.com/cxr/gen_ext.min.js',
    demoPageURL: 'https://demo.begenuin.com/demo/qa-demo.html',
    website: 'https://app.qa.begenuin.com',
    serverURL: 'https://nxs.qa.begenuin.com',
  };

  return (
    <UserInfoContext.Provider
      value={{
        glossary,
        msaDetails,
        userInfo,
        customerInfo,
        genuin,
        setMsaDetails,
        handleInitialState,
        // balanceHeader,
        // setBalanceHeader,
        getCustomerInfo,
        setCustomerInfo,
        setUserInfo,
        getUserInfo,
        clearAll,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
/* Handle Initial Necessary API calls End */

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        {/* <IntercomProvider
          appId={INTERCOM_APP_ID}
          apiBase={'https://api-iam.intercom.io'}
          autoBoot={false}
          onHide={onHide}
          onShow={onShow}
        > */}
        <ToastContainer
          position='bottom-left'
          autoClose={3000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          limit={1}
          style={{ fontSize: 12, fontWeight: 700 }}
        />
        <OverviewDataProvider>
          <MyProvider>
            <Header />
            <div className='main-container position-relative'>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={() => {
                    return isAuthenticated() ? (
                      isAdmin() ? (
                        <Redirect to='/customers' />
                      ) : isDSP() ? (
                        <Redirect to='/sources/demand' />
                      ) : isPublisher() ? (
                        <Redirect to='/publisher/supply' />
                      ) : isSPO() ? (
                        <Redirect to='/sources/demand' />
                      ) : isCreative() ? (
                        <Redirect to='/my-creatives' />
                      ) : (
                        <></>
                        // <Redirect to='/login' />
                      )
                    ) : (
                      <Redirect to='/login' />
                    );
                  }}
                />
                <Public exact path='/signup'>
                  <Signup />
                </Public>
                <Public exact path='/signup-pass'>
                  <SignupPass />
                </Public>
                <Public exact path='/signup-done'>
                  <SignupDone />
                </Public>
                <Public exact path='/signup-dsp'>
                  <SignupDsp />
                </Public>
                <Public exact path='/signup-pass-dsp'>
                  <SignupPassDsp />
                </Public>
                <Public exact path='/login'>
                  <Login />
                </Public>
                <Public exact path='/login-password'>
                  <LoginPassword />
                </Public>
                <Public exact path='/forgot-password'>
                  <ForgotPassword />
                </Public>
                <Public exact path='/link-sent'>
                  <LinkSent />
                </Public>
                <Public exact path='/create-password/:token'>
                  <CreatePass />
                </Public>
                <Public exact path='/password-set'>
                  <PasswordSet />
                </Public>
                <Customer path='/publisher/supply'>
                  <PublisherSupply />
                </Customer>
                <Customer path='/publisher/demand/sources'>
                  <PublisherDemand />
                </Customer>
                {/* Hide header tab for publishers */}
                {/* <Customer path='/publisher/deals/my-deals'>
                  <PgPmp />
                </Customer> */}
                <Private path='/settings'>
                  <Settings />
                </Private>
                <Admin path='/customers'>
                  <Customers />
                </Admin>
                {/* <Admin exact path='/customers/:id'>
                    <Customers />
                  </Admin> */}
                <Admin exact path='/inventory'>
                  <Inventory />
                </Admin>
                <Admin path='/inventory/deals'>
                  <Deals />
                </Admin>
                <Admin exact path='/traffic-dashboard'>
                  <Traffic />
                </Admin>
                <Customer path='/sources/demand'>
                  <DemandSources />
                </Customer>
                <SPO path='/sources/publishers'>
                  <SPOPublisher />
                </SPO>
                <Creative path='/my-creatives'>
                  <MyCreatives />
                </Creative>
                <Creative path='/video-library'>
                  <VideoLibrary />
                </Creative>
                <Creative path='/brand-config'>
                  <BrandConfig />
                </Creative>
                <Customer path='/sources/deals'>
                  <DemandDeals />
                </Customer>
                <Redirect from='*' to='/' />
              </Switch>
              <BarSide />
            </div>
          </MyProvider>
        </OverviewDataProvider>
        {/* </IntercomProvider> */}
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('infy-tv')
);
