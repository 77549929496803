import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Dropdown from '../../widgets/dropdown';
import countryCodes from '../../../states/countryCodes';
import { sendOtpExtensionAPI, verifyOtpExtensionAPI } from '../../../services/extension';
// import FormikController from '../../comman/form/FormikController';
import OtpInput from './OtpInput';
import store from '../../../states';

const LoginWithMobile = ({
  createAccount,
  loginSuccessful,
  validGenuinUser,
  loader,
  importMoreVideo,
  genuinUser,
  validateOTPProps,
  LoginphoneNo,
}: any) => {
  const [selectedOption, setSelectedOption] = useState<any>({ value: 1, label: '+1' });
  const [phoneNo, setPhoneNo] = useState<any>('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [sendOTPLoader, sSendOTPLoader] = useState(false);
  const [verifyUserId, setVerifyUserId] = useState('');
  const [validateOTP, sValidateOTP] = useState(false);
  const [sendOTP, sSendOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [glossary, sGlossary] = useState<any>({});
  const [currentGenuinID, setCurrentGenuinID] = useState('');
  const [resendOtpBtn, sResendOtpBtn] = useState(false);
  const [otpVerifyLoader, sOTPVerifyLoader] = useState(false);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [delay, setDelay] = useState<any>();
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  //handle phone no
  const debounce = (func: any, delay: any) => {
    let timeoutId: any;
    return function (...args: any) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        //@ts-ignore
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedHandlePhoneNumber = useCallback(
    debounce((value: any) => {
      const regex = /^[0-9]{10,16}$/;
      if (regex.test(value)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
      }
    }, 1000),
    []
  );

  const handlePhoneNumber = (event: any) => {
    const value = event.target.value;
    //setPhoneNo(value);
    if (/^[0-9]*$/.test(value)) {
      setPhoneNo(value);
      debouncedHandlePhoneNumber(value);
    }
  };

  //handle send otp
  const handleSendOTP = () => {
    const payload = {
      nick_name: validGenuinUser,
      phone_number: loader ? `+${LoginphoneNo}` : `${selectedOption.label + phoneNo}`,
    };
    sSendOTPLoader(true);
    sendOtpExtensionAPI(payload)
      .then((data: any) => {
        if (data.status) {
          setDelay(data?.data?.retryTime);
          setVerifyUserId(data?.data?.user_id);
          toast.success(data?.message);
          sSendOTPLoader(false);
          sSendOTP(true);
          setOtp('');
        } else {
          toast.error(data.message);
          sSendOTPLoader(false);
        }
      })
      .catch((error: any) => {
        //console.log('error', error);
        //toast.error(error.message);
      });
  };

  //verify OTP
  const handleValidateOTP = () => {
    const payload = {
      gen_user_id: verifyUserId,
      nick_name: validGenuinUser,
      otp: otp,
    };
    sOTPVerifyLoader(true);
    verifyOtpExtensionAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          toast.success(data.message);
          sValidateOTP(true);
        } else {
          sValidateOTP(false);
          toast.error(data.message);
        }
        // if (data.is_login === false && data.status === false) {
        //   toast.error(data.message);
        // } else {
        //   toast.success(data.message);
        //   sValidateOTP(true);
        // }

        setCurrentGenuinID(validGenuinUser);
        sOTPVerifyLoader(false);
      })
      .catch(() => {
        //handleValidateOTP
        sOTPVerifyLoader(false);
      });
  };

  useEffect(() => {
    sSendOTP(false);
    if (validGenuinUser !== currentGenuinID) {
      sValidateOTP(false);
      sResendOtpBtn(false);
    }
  }, [validGenuinUser]);

  useEffect(() => {
    setPhoneNo('');
  }, [createAccount]);

  useEffect(() => {
    if (sendOTP && minutes === 0 && seconds === 0) sResendOtpBtn(true);
  }, [minutes, seconds, sendOTP]);

  validateOTPProps(validateOTP);

  const onChangeOTP = (value: string) => setOtp(value);

  return (
    <>
      {createAccount && !loginSuccessful && !validateOTP && (
        <div className='col-12 mb-3'>
          <label className='label'>{glossary.ssp_createext_input_field_label_phone}</label>
          <div className='row'>
            <div className='col'>
              <div className='input-group'>
                <span className='input-group-text p-0 country-select'>
                  <Dropdown
                    label=''
                    option={countryCodes}
                    onchange={setSelectedOption}
                    value={selectedOption}
                    search={true}
                    classNamePrefix={'country'}
                  />
                </span>
                <input
                  type={'text'}
                  className='form-control'
                  inputMode='numeric'
                  onChange={(event: any) => {
                    handlePhoneNumber(event);
                  }}
                  value={phoneNo}
                  maxLength={16}
                />
              </div>
            </div>
            <div className='col-auto'>
              {sendOTPLoader ? (
                <button className='button button-primary' type='button' disabled>
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  <span className='visually-hidden'>Loading...</span>
                </button>
              ) : (
                <>
                  {phoneNo.length >= 10 && !phoneNumberError ? (
                    <>
                      {!resendOtpBtn ? (
                        <button
                          type='button'
                          className={sendOTP ? 'button button-disabled' : 'button button-primary'}
                          onClick={handleSendOTP}
                        >
                          {glossary.ssp_createext_button_send_otp}
                        </button>
                      ) : (
                        <button
                          type='button'
                          className={'button button-primary'}
                          onClick={handleSendOTP}
                        >
                          Resend OTP
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      type='button'
                      className={'button button-disabled'}
                      onClick={handleSendOTP}
                    >
                      {glossary.ssp_createext_button_send_otp}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {phoneNumberError && (
            <p className='small text-danger'>{glossary.ssp_createext_input_phone_valid_error}</p>
          )}
        </div>
      )}

      {/* If user is alredy register than login */}
      {importMoreVideo && !loginSuccessful && !validateOTP && (
        <div className='mb-3 row'>
          <div className='col-5'>
            <div className='form-group disable-field'>
              <label className='label'>
                {glossary.ssp_createext_input_field_label_genuin_username}
              </label>
              <div className={'input-group'}>
                <span className='input-group-text'>@</span>
                <input
                  type='text'
                  className='form-control'
                  placeholder={genuinUser}
                  readOnly
                  style={{ backgroundColor: 'white' }}
                />
              </div>
            </div>
          </div>
          <div className='col-auto d-flex flex-column'>
            <label className='label'>&nbsp;</label>
            {sendOTPLoader ? (
              <button className='button button-primary' type='button' disabled>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Loading...</span>
              </button>
            ) : (
              <>
                {!resendOtpBtn ? (
                  <button
                    type='button'
                    className={sendOTP ? 'button button-disabled' : 'button button-primary'}
                    onClick={handleSendOTP}
                  >
                    {glossary.ssp_createext_button_send_otp}
                  </button>
                ) : (
                  <button type='button' className={'button button-primary'} onClick={handleSendOTP}>
                    Resend OTP
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {/* If user is send otp after view this section */}
      {sendOTP && !loginSuccessful && !validateOTP && (
        <div className='mb-3 row'>
          <div className='col-12'>
            <div className='text-left'>
              <h6>{glossary.ssp_createext_otp_instruction}</h6>
              <div>
                <span>{glossary.ssp_createext_otp_instruction_code_sent}</span>

                <small>
                  *******{phoneNo && phoneNo?.slice(-4)} {LoginphoneNo && LoginphoneNo?.slice(-4)}
                </small>
              </div>

              <p>
                {minutes === 0 && seconds === 0 ? null : (
                  <span>
                    {glossary.ssp_createext_field_label_time_remaining} {minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className='col-12'>
            <div id='otp' className='d-flex gap-4'>
              <OtpInput value={otp} valueLength={6} onChange={onChangeOTP} />
              {otpVerifyLoader ? (
                <button className='button button-primary' type='button' disabled>
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                </button>
              ) : (
                <button
                  type='button'
                  className={
                    otp.length === 6
                      ? 'col-auto button button-primary'
                      : 'col-auto button button-disabled'
                  }
                  onClick={handleValidateOTP}
                >
                  {glossary.ssp_createext_field_label_validate}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginWithMobile;

{
  /* <FormikController
  kind='withiconstart'
  control='input'
  type='text'
  label={glossary.ssp_createext_input_field_label_genuin_username}
  placeholder={genuinUser}
  name='extensionId'
  iconType={'atsign'}
/> */
}
