import { useState, useEffect, useContext, useReducer } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { adReelAPI } from '../../../services/tags';
import FormikController from '../../comman/form/FormikController';
import Dropdown from '../../widgets/dropdown';
import Button from '../../widgets/button';
// import CreativeDetails from './Creative-details';
import { creativeGetProfileVideosAPI } from '../../../services/creative';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { DownloadCreativeSizeCSV, selectedIndices, reducer } from '../../helpers/functions';
import { categories } from '../../helpers/dropdowns';
// import Switch from '../../widgets/switch';
// import Radio from '../../widgets/radio';
import { addRellviewTypeOpt, newResolutionOpt } from '../extension/ExtOptions';
import CardSorting from './Creative-sortableitem';
import NewCreativeDetails from './NewCreative-details';
import VideoTable from '../../tables/VideoTable';

interface Props {
  show: boolean;
  onHide(): void;
  glossary: any;
  pSection: string;
  genuinUserInfo: any;
  mode: string;
  creativeDetails: any;
  creativeConfig: any;
  controller(_data: any, t: string): void;
  _selectedVideos?: any; // Use only for while create tag directly from videolibrary
  getCurrentSection(pSection: string): void;
}

const NewCreativeCreate = ({
  show,
  glossary,
  pSection,
  genuinUserInfo,
  mode,
  creativeDetails,
  creativeConfig,
  _selectedVideos,
  onHide,
  controller,
  getCurrentSection,
}: Props) => {
  //
  // Context API
  const { customerInfo, genuin }: any = useContext(UserInfoContext);

  const [section, setSection] = useState('1');
  const [tag_id, setTag_id] = useState();
  const [_creativeDetails, setCreativeDetatils] = useState(creativeDetails ?? {});
  const [_creativeConfig, setCreativeConfig] = useState(creativeConfig ?? {});

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeedType, setSelectedFeedType] = useState({
    value: 'fixed',
    label: 'Sequentially',
  });
  const [selectedDelay, setSelectedDelay] = useState({
    value: 3,
    label: '3s',
  });
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedVideosIndex, setSelectedVideoIndex] = useState({});
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedViewType, setSelectedViewType] = useState<any>({
    value: 'interstitial',
    label: 'Interstitial',
  });
  const [selectedResolution, setSelectedResolution] = useState({
    label: '300 x 600 pixels',
    value: '300 x 600 pixels',
    width: '300px',
    height: '600px',
  });
  // const [count, setCount] = useState(0);
  const [videoLimitCount, setVideoLimitCount] = useState(3);
  const [videoPlacement, setVideoPlacement] = useState(false);

  const [state, dispatch] = useReducer(reducer, { data: [] });

  const CreativeSchema = Yup.object().shape({
    creativeName: Yup.string().required('Enter your reel name'),
    gtmID: Yup.string().typeError('Enter valid google tag manager ID.'),
  });

  const CreativeInitialValues = {
    creativeName: _creativeDetails?.name ?? '',
    gtmID: _creativeConfig?.gtm_id ?? '',
    show_spark: _creativeConfig?.show_spark ?? true,
    show_comments: _creativeConfig?.show_comments ?? true,
    show_share: _creativeConfig?.show_share ?? true,
    show_report: _creativeConfig?.show_report ?? true,
    show_owner_details: _creativeConfig?.show_owner_details ?? true,
    show_logo: _creativeConfig?.show_logo ?? true,
    auto_swipe: _creativeConfig?.auto_swipe ?? true,
    disable_profile_redirect: _creativeConfig?.disable_profile_redirect ?? true,
  };

  const hanleSubmit = (_e: any) => {
    setIsLoading(true);
    const payload = {
      ...(_creativeDetails?.name && { tag_id: _creativeDetails?.id }),
      allowed_domains: [],
      config: {
        on_click: 'fullscreen', //actionPerformed
        enable_ask_question: false, //showQuestion
        show_spark: _e?.show_spark, //showSpark
        auto_swipe: _e?.auto_swipe, //autoSwipe
        show_comments: _e.show_comments,
        show_cta: true, //showCTA
        show_logo: _e.show_logo, // Show agency logo
        show_owner_details: _e.show_owner_details,
        show_reply: false, //showReply
        show_report: _e.show_report,
        show_repost: false, //showRepost
        show_save: false, //showSave
        show_share: _e.show_share,
        show_video_url: false, //showVideoURL
        disable_profile_redirect: _e.disable_profile_redirect,
      },
      feed_type: selectedFeedType?.value,
      gtm_id: _e.gtmID,
      cta_color: customerInfo?.rtb_config?.creative_data?.advertiser_brand_color ?? '#123787',
      cta_delay: selectedDelay?.value,
      iab_cat: selectedCategories.map((x: any) => x.value),
      genuin_user_name: genuinUserInfo?.username,
      max_duration: 300,
      min_duration: 15,
      min_cpm: 0,
      tag_name: _e.creativeName,
      videos: selectedVideos?.map((x: any) => ({
        cta_text: x.cta_text,
        cta_link: x.cta_link,
        pixel_link: x.pixel_link ?? '',
        video_url: x.video_url,
        share_url: x.share_url,
        video_id: x.video_id,
        video_thumbnail_s: x.video_thumbnail_s,
        duration: x.duration,
        import_date: x.import_date,
        source: x.source,
        show_url_meta: x.show_url_meta ?? false,
        hash_tags: x.hash_tags ?? [],
        ad_copy: x.ad_copy ?? '',
        share_string: x.share_string,
        config: x.config,
        slug: x.slug,
      })),
      view_type: selectedViewType?.value,
      feed_max_videos: selectedViewType.value === 'mid_roll' ? videoLimitCount : 10,
      feed_resolution: selectedResolution?.value,
      ads_freq: 0,
      is_ad_at_end_of_video: videoPlacement,
    };
    adReelAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          controller(data?.data, payload.tag_id ? 'update' : 'add');
          setTag_id(data?.data?.id);
          setCreativeDetatils(data?.data);
          setCreativeConfig(data?.data?.config);
          toast.success(data?.message);
          getCurrentSection('3');
          setSection('3');
        } else {
          toast.error(glossary.server_error);
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        // error
        setIsLoading(false);
      });
  };

  const getProfileVideos = (_page: any) => {
    const payload = {
      page: _page,
    };

    // Use for selected videos top into the video table
    const _selected = _creativeConfig?.videos?.map(({ video_url, share_url, ...data }: any) => ({
      ...data,
      share_url: share_url ? share_url : video_url,
      video_url: video_url,
    }));

    // Modified array for filtering data with preselected videos
    const preselectedVideo = _creativeConfig?.videos?.map(({ video_id }: any) => video_id) || [];

    setIsVideoLoading(true);
    setHasMoreVideos(true);
    creativeGetProfileVideosAPI(payload)
      .then((data: any) => {
        if (data?.error === 400) {
          toast.error('Video fetching services is unavailable, Please try after some times.');
          setIsVideoLoading(false);
        } else {
          // Removed object which is already in array from selected video list
          const filteredArr = data?.data?.videos?.filter(
            (_video: any) => !preselectedVideo.includes(_video?.video_id)
          );

          // const filteredArr =
          //   data?.data?.videos.length !== 0
          //     ? data?.data?.videos?.filter(
          //         (_video: any) => !preselectedVideo.includes(_video?.video_id)
          //       )
          //     : state?.data?.filter((_video: any) => !preselectedVideo.includes(_video?.video_id));

          // FOR EDIT TAG
          if (mode === 'edit') {
            if (_page === 0 && data?.data?.end_page) {
              setHasMoreVideos(false);
              dispatch({ type: 'update_list', data: [..._selected, ...filteredArr] });
            }

            if (_page !== 0 && data?.data?.end_page) {
              setHasMoreVideos(false);
              dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            }

            if (_page === 0 && !data?.data?.end_page) {
              dispatch({ type: 'update_list', data: [..._selected, ...filteredArr] });
            }

            if (_page !== 0 && !data?.data?.end_page) {
              dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            }
            setIsVideoLoading(false);
          }

          // FOR NEW CREATE TAG
          if (mode === 'create') {
            if (data?.data?.end_page) {
              setHasMoreVideos(false);
              dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            } else {
              dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            }
            setIsVideoLoading(false);

            // if (_page === 0 && data?.data?.end_page) {
            //   setHasMoreVideos(false);
            //   dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            // }

            // if (_page !== 0 && data?.data?.end_page) {
            //   setHasMoreVideos(false);
            //   dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            // }

            // if (_page === 0 && !data?.data?.end_page) {
            //   dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            // }

            // if (_page !== 0 && !data?.data?.end_page) {
            //   dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
            // }
            // setIsVideoLoading(false);
          }

          // && mode !== 'edit' LAST ROW FLICKER ISSUE
          // if (data?.data?.end_page) {
          //   setHasMoreVideos(false);
          //   dispatch({ type: 'update_list', data: [...state?.data, ...filteredArr] });
          // } else {
          //   dispatch({
          //     type: 'update_list',
          //     data:
          //       _page === 0 && mode === 'edit'
          //         ? [..._selected, ...filteredArr]
          //         : [...state?.data, ...filteredArr],
          //   });
          // }
          // setIsVideoLoading(false);
        }
      })
      .catch((error: any) => {
        // error
      });
  };

  useEffect(() => {
    getProfileVideos(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (pSection) {
      getCurrentSection(pSection);
      setSection(pSection);
    }
  }, [show, pSection]);

  useEffect(() => {
    if (_creativeDetails?.id && _creativeConfig) {
      setTag_id(_creativeDetails?.id);
      setSelectedFeedType(
        _creativeConfig?.feed_type === 'engaged_videos'
          ? { value: 'engaged_videos', label: 'Smart Feed' }
          : { value: 'fixed', label: 'Sequentially' }
      );
      setSelectedVideos(
        _creativeConfig?.videos?.map(
          ({ video_url, share_url, ...data }: any) =>
            ({
              ...data,
              share_url: share_url ? share_url : video_url,
              video_url: video_url,
            } || [])
        )
      );

      setSelectedViewType(
        addRellviewTypeOpt.find((_i: any) => _i.value === _creativeConfig?.view_type) || {
          value: 'interstitial',
          label: 'Interstitial',
        }
      );
      setSelectedResolution(
        newResolutionOpt?.find((x: any) => x?.value === _creativeConfig?.feed_resolution) ||
          newResolutionOpt[0]
      );
      // setCount(_creativeConfig?.ads_freq || 5);
      setVideoLimitCount(_creativeConfig?.feed_max_videos || 10);
      setVideoPlacement(_creativeConfig?.is_ad_at_end_of_video || false);
      setSelectedDelay({
        value: _creativeConfig?.cta?.delay === 0 ? 0 : _creativeConfig?.cta?.delay || 3,
        label:
          _creativeConfig?.cta?.delay === 0
            ? 'Immediate(0s)'
            : `${_creativeConfig?.cta?.delay || 3}s`,
      });

      setSelectedVideoIndex(selectedIndices(_creativeConfig?.videos));

      if (_creativeConfig?.iab_cat && _creativeConfig?.iab_cat.length > 0) {
        const newArr: any = [];
        categories.forEach((e1: any) => {
          _creativeConfig?.iab_cat?.forEach((e2: any) => {
            if (e1.value === e2) {
              newArr.push(e1);
            }
          });
        });
        setSelectedCategories(newArr || []);
      }
    }

    if (_selectedVideos?.length > 0 && mode === 'create') {
      setSelectedVideos(_selectedVideos); // Use only for while create tag directly from videolibrary
    }
  }, [_creativeConfig, _creativeDetails, mode]);

  useEffect(() => {
    if (creativeDetails?.id && creativeConfig) {
      setCreativeDetatils(creativeDetails);
      setCreativeConfig(creativeConfig);
    }
  }, [creativeDetails, creativeConfig]);

  return (
    <>
      {section === '1' && (
        <>
          <div
            className='modal-body'
            style={{
              minHeight: '350px',
              overflowY: 'auto',
            }}
          >
            <VideoTable
              data={state?.data}
              preSelectedIndices={selectedVideosIndex}
              handleSelect={(_list: any, _rowIndex: any) => {
                setSelectedVideos(_list);
                setSelectedVideoIndex(_rowIndex);
              }}
              getNextPage={setPageIndex}
              hasMore={hasMoreVideos}
              isListLoading={isVideoLoading}
              kind={'profile'}
              dispatch={dispatch}
              genuinInfo={genuinUserInfo}
              handleFormSubmit={() => null}
            />
          </div>
          <div className='modal-footer d-flex justify-content-between'>
            <div>
              {selectedVideos?.length >= 10 && (
                <p className='text-danger'>You can select upto 10 videos per creative.</p>
              )}
            </div>
            <div className='d-flex gap-3 align-items-center'>
              <Button kind='secondary' type={'button'} text={'Cancel'} click={() => onHide()} />
              <Button
                kind={selectedVideos?.length > 0 ? 'primary' : 'disabled'}
                type={'button'}
                text={'Next'}
                click={() => {
                  setSection('2');
                  getCurrentSection('2');
                }}
              />
            </div>
          </div>
        </>
      )}

      {section === '2' && (
        <>
          <Formik
            validationSchema={CreativeSchema}
            initialValues={CreativeInitialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(e) => {
              hanleSubmit(e);
            }}
          >
            {({ errors, touched }) => (
              <Form className='rtb-form'>
                <div
                  className='modal-body p-3'
                  style={{
                    maxHeight: 'calc(100vh - 180px)',
                    minHeight: '350px',
                    overflowY: 'auto',
                  }}
                >
                  <div className='row align-items-start'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12 mb-2'>
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='text'
                              label={'Creative Name *'}
                              placeholder={'Enter Creative Name For Your Reference'}
                              name='creativeName'
                              maxLength={32}
                            />
                          </div>
                        </div>

                        <div className='col-12 mb-2'>
                          <Dropdown
                            label={'Feed Type'}
                            option={[
                              { value: 'fixed', label: 'Sequentially' },
                              { value: 'engaged_videos', label: 'Smart Feed' },
                            ]}
                            onchange={setSelectedFeedType}
                            value={selectedFeedType}
                            search={true}
                          />
                        </div>

                        <div className='col-12 mb-2'>
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='text'
                              label={'Google Tag Manager ID'}
                              placeholder={'Enter Google Tag Manager ID'}
                              name='gtmID'
                            />
                          </div>
                        </div>

                        <div className='col-12 mb-2'>
                          <Dropdown
                            label={'Select CTA delay'}
                            option={[
                              { value: 0, label: 'Immediate(0s)' },
                              { value: 3, label: '3s' },
                              { value: 4, label: '4s' },
                              { value: 5, label: '5s' },
                            ]}
                            onchange={setSelectedDelay}
                            value={selectedDelay}
                            search={false}
                          />
                        </div>

                        <div className='col-12 mb-2'>
                          <Dropdown
                            label={'Select tag size'}
                            option={newResolutionOpt}
                            onchange={setSelectedResolution}
                            value={selectedResolution}
                            search={false}
                          />
                        </div>

                        <div className='col-12 mb-2'>
                          <label className='label'>Configure elements on the creative</label>
                          <div className='d-flex flex-column gap-2'>
                            <p>
                              <Field type='checkbox' name='disable_profile_redirect' />
                              <span className='ms-2'>Disable profile redirect</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='show_spark' />
                              <span className='ms-2'>Spark</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='show_comments' />
                              <span className='ms-2'>Comments</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='show_share' />
                              <span className='ms-2'>Share Video</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='show_report' />
                              <span className='ms-2'>Report Video</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='show_owner_details' />
                              <span className='ms-2'>Show video owner details</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='show_logo' />
                              <span className='ms-2'>Show agency logo</span>
                            </p>
                            <p>
                              <Field type='checkbox' name='auto_swipe' />
                              <span className='ms-2'>Auto swipe</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-6 d-flex justify-content-end'>
                      {selectedFeedType?.value === 'fixed' && selectedVideos?.length > 0 && (
                        <div className='col col-3 justify-content-start position-relative'>
                          <CardSorting
                            cardList={selectedVideos.map((x: any, i: any) => ({
                              id: `${i}`,
                              ...x,
                            }))}
                            getUpdatedList={(_videos: any) => setSelectedVideos(_videos)}
                            mode={'create'}
                            boxHeight={'600px'}
                            genuinInfo={genuinUserInfo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={'modal-footer d-flex justify-content-end gap-2'}>
                  <Button
                    kind={isLoading ? 'disabled-secondary' : 'secondary'}
                    text={'Cancel'}
                    type='button'
                    click={() => onHide()}
                  />

                  {isLoading ? (
                    <Button
                      kind='spinner'
                      type='button'
                      text={mode === 'create' ? 'Generating...' : 'Updating...'}
                    />
                  ) : (
                    <>
                      {mode === 'create' ? (
                        <Button type='submit' kind={'primary'} text={'Generate Creative'} />
                      ) : (
                        <Button type='submit' kind={'primary'} text={'Update Creative'} />
                      )}
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}

      {section === '3' && (
        <>
          <div
            className='modal-body p-3'
            style={{
              maxHeight: 'calc(100vh - 180px)',
              minHeight: '350px',
              overflowY: 'auto',
            }}
          >
            {/* <div className='row mb-3'>
              <div className='col-4'>
                <Dropdown
                  label={'Select demo editorial'}
                  option={[{ value: 'us', label: 'USA Today' }]}
                  onchange={() => null}
                  value={{ value: 'us', label: 'USA Today' }}
                  search={false}
                />
              </div>
            </div> */}
            {/* <div className='row mb-3' style={{ backgroundColor: '#F0F0F0', padding: '10px' }}>
              <div className='col-4'></div>
              <div className='col-4 m-0 d-flex justify-content-between'>
                <Button text={'Mobile'} type='button' kind='primary' />
                <Button text={'Tablet'} type='button' kind='secondary' />
                <Button text={'Desktop'} type='button' kind='secondary' />
              </div>
              <div className='col-4'></div>
            </div> */}

            <NewCreativeDetails
              tagID={tag_id}
              selectedVideos={selectedVideos}
              feedType={selectedFeedType?.value}
              formSubmit={() =>
                hanleSubmit({
                  gtmID: _creativeConfig?.gtm_id,
                  creativeName: _creativeDetails?.name,
                  show_spark: _creativeConfig?.show_spark,
                  auto_swipe: _creativeConfig?.auto_swipe,
                  show_comments: _creativeConfig?.show_comments,
                  show_logo: _creativeConfig?.show_logo,
                  show_owner_details: _creativeConfig?.show_owner_details,
                  show_report: _creativeConfig?.show_report,
                  show_share: _creativeConfig?.show_share,
                })
              }
              getUpdatedList={(_videos: any) => setSelectedVideos(_videos)}
              genuinInfo={genuinUserInfo}
            />
          </div>

          <div className={'modal-footer d-flex justify-content-between'}>
            <Button
              kind={'link'}
              text={'Download CSV'}
              type={'button'}
              click={() => DownloadCreativeSizeCSV(tag_id, customerInfo?.id, genuin?.scriptURL)}
            />
            <Button kind='secondary' text={'Close'} type={'button'} click={() => onHide()} />
          </div>
        </>
      )}
    </>
  );
};

export default NewCreativeCreate;
