import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';

import DeleteReqDeal from '../../modals/delete-req-deal';
import TableSkeleton from '../../loaders/table';

import RequestDealSidebar from '../../sidebar/RequestDealSidebar';
import RequestedDealDetails from '../../helpers/requestedDealDetails';
import RequestedDealPublisher from '../../helpers/requestedDealPublisher';

import { activeRequestDealAPI, getRequestedDealListAPI } from '../../../services/deals';

import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import MsaSign from '../../modals/msa-sign';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { toast } from 'react-toastify';
import RequestDealModal from '../../modals/request-deal';
import Button from '../../widgets/button';
import store from '../../../states';
import NoDataFound from '../../../assets/images/no-data-found.svg';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

const DemandDeals = () => {
  const {
    getReqestDelasListAPI,
    getDemandInventoriesAPI,
    getRtbGenreListAPI,
    getCountriesAPI,
  }: any = useAuthentication();
  const {
    reqDealList,
    sReqDealList,
    deamndInventories,
    sDemandInventories,
    demandGenreList,
    sDemandGenreList,
    demandCountriesList,
    sDemandCountriesList,
  }: any = consumerData();

  const [empty, sEmpty] = useState(false);
  const [selectedDeal, sSelectedDeal] = useState('');
  const [dealDetails, sDealDetails] = useState<any>([]);
  const [dealConfigObj, sDealConfigObj] = useState<any>({});
  const [createDealModal, sCreateDealModal] = useState(false);
  const [deleteDealModal, sDeleteDealModal] = useState(false);
  const [editDealModal, sEditDealModal] = useState(false);
  const [activeTab, sActiveTab] = useState('details');
  //const [reqDealList, sReqDealList] = useState<any>([]);
  const [skeleton, sSkeleton] = useState<any>(true);
  const [newid, sNewid] = useState('');
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);
  //const [isCardLoading, sIsCardLoading] = useState(true);
  const [allData, setAllData] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'created_on',
      desc: true,
    },
  });
  const [paramsStatus, setParamsStatus] = useState(false);
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [glossary, sGlossary] = useState<any>({});

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* Context API's */
  const { msaDetails }: any = useContext(UserInfoContext);

  useEffect(() => {
    const dealsId = pathname.split('/sources/deals/')[1];
    //getRequestDealList(params, dealsId);
    if (reqDealList.length === 0 || params.query === '') {
      getRequestDealList(params, dealsId);
    } else {
      selectedCard(reqDealList[0].id);
    }
    if (paramsStatus === true) {
      getRequestDealList(params, dealsId);
    }
  }, [params.page, params.query, params.sortBy, paramsStatus]);

  const handleParamsChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
      setParamsStatus(status);
    }
    if (params.sortBy !== sortBy || params.query !== query) {
      sDealDetails({});
      sReqDealList([]);
    }
  };
  /***** Handle on scroll API's end *****/

  /***** Selected Card *****/
  const selectedCard = (id: any) => {
    if (id && id) sNewid(id);
    if (selectedDeal !== id) {
      history.push(id !== undefined ? `/sources/deals/${id}` : '/sources/deals');
    }
    if (selectedDeal !== id && id !== newid) {
      sSelectedDeal(id);
      sActiveTab('details');
    }
  };

  /* Get Request Deal List */
  const getRequestDealList = (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    sHasMore(true);
    sEmpty(false);
    setAllData(false);
    sSkeleton(true);
    getReqestDelasListAPI(payload)
      .then((data: any) => {
        if (data.status) {
          sSkeleton(false);
          sCurrentPage(params.page);
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sReqDealList([...reqDealList, ...data.data]);
            } else {
              if (reqDealList.length < 1) sEmpty(true);
              sHasMore(false);
              sReqDealList(reqDealList);
              if (selectedDeal !== undefined && selectedDeal !== '') {
                selectedCard(params.page === 0 ? reqDealList[0].id : selectedDeal);
              }
            }
          } else {
            sEmpty(data.data.length < 0 ? true : false);
            sReqDealList(data.data);

            // Whenever getting ID undefined so this execute
            if (_id !== undefined) {
              selectedCard(data.data.find((x: any) => x.id == _id)?.id || data?.data[0]?.id);
              sNewid(data.data.find((x: any) => x.id == _id)?.id || data?.data[0]?.id);
            } else {
              selectedCard(data?.data[0]?.id);
              sNewid(data.data[0].id);
            }
          }
          setAllData(true);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  /***** UPDATE REQUESTDEAL LIST START *****/
  const handleRequestDealsLists = (reqDealObject: any, kind: string) => {
    const newReqDealObject = { ...reqDealObject.data };
    if (kind === 'add') {
      const newArr = [newReqDealObject, ...reqDealList];
      sReqDealList(newArr);
      selectedCard(newReqDealObject.id);
    }

    if (kind === 'update') {
      const newArray = [...reqDealList];
      const index = newArray.findIndex((item) => item.id === newReqDealObject.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...newReqDealObject };
      }
      sReqDealList(newArray);
    }
  };

  useEffect(() => {
    if (reqDealList.length > 0) {
      sEmpty(false);
    }
    if (reqDealList.length === 0) {
      sEmpty(true);
    }
  }, [reqDealList]);

  /* Remove Requested deal from list*/
  const handleDeleteReqDeal = (id: number) => {
    sReqDealList((prevData: any) => {
      const indexToRemove = prevData.findIndex((item: any) => item.id === id);
      const newData = [...prevData.slice(0, indexToRemove), ...prevData.slice(indexToRemove + 1)];
      if (indexToRemove < prevData.length - 1) {
        const nextId = prevData[indexToRemove + 1].id;
        selectedCard(nextId);
      } else if (indexToRemove > 0) {
        const prevId = prevData[indexToRemove - 1].id;
        selectedCard(prevId);
      } else {
        selectedCard('');
      }

      return newData;
    });
  };
  /***** UPDATE REQUESTDEAL LIST END *****/

  const createDealReq = () => {
    if (msaDetails.status) {
      sCreateDealModal(true);
    }
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  const approveOfferDeal = (id: any) => {
    const payload = {
      req_deal_id: id,
    };
    activeRequestDealAPI(payload)
      .then((data: any) => {
        if (data.status) {
          handleRequestDealsLists(data, 'update');
          toast.success(glossary.dsp_offerdeal_approve_success_msg);
        }
      })
      .catch((error: any) => {
        // Error
      });
  };

  /* Sidebar */
  const [sidebar, sSidebar] = useState<'collapsed' | 'expanded'>('collapsed');
  const handleSidebar = (size: 'collapsed' | 'expanded') => {
    sSidebar(size);
  };

  useEffect(() => {
    if (selectedDeal !== '' && selectedDeal !== undefined) {
      const getReqDealDetails: any = reqDealList?.find((x: any) => x?.id == selectedDeal);
      sDealDetails(getReqDealDetails ?? {});
      sDealConfigObj(getReqDealDetails?.config ?? {});
      sSkeleton(false);
    }
    sActiveTab('details');
  }, [selectedDeal, reqDealList]);

  useEffect(() => {
    if (deamndInventories.length === 0) {
      getAllInventories();
    }
    if (demandGenreList.length === 0) {
      getGenreList();
    }
    if (demandCountriesList.length === 0) {
      getCountriesList();
    }
  }, []);

  const getAllInventories = () => {
    getDemandInventoriesAPI()
      .then((data: any) => {
        if (data.status) {
          sDemandInventories(data.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const getGenreList = () => {
    getRtbGenreListAPI()
      .then((data: any) => {
        if (data) {
          sDemandGenreList(data.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const getCountriesList = () => {
    getCountriesAPI()
      .then((data: any) => {
        sDemandCountriesList(data.data);
      })
      .catch(() => {
        //
      });
  };

  return (
    <>
      <MsaSign
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />

      <RequestDealModal
        show={createDealModal}
        onHide={() => {
          sCreateDealModal(false);
        }}
        dealTerms={{}}
        dealTermsConfig={{}}
        updateList={handleRequestDealsLists}
        publishersList={deamndInventories}
        genreList={demandGenreList}
        countriesList={demandCountriesList}
      />

      <RequestDealModal
        show={editDealModal}
        onHide={() => {
          sEditDealModal(false);
        }}
        dealTerms={dealDetails}
        dealTermsConfig={dealConfigObj}
        updateList={handleRequestDealsLists}
        publishersList={deamndInventories}
        genreList={demandGenreList}
        countriesList={demandCountriesList}
        edit
      />

      <DeleteReqDeal
        id={selectedDeal}
        show={deleteDealModal}
        onHide={() => sDeleteDealModal(false)}
        removeObj={handleDeleteReqDeal}
      />

      <div className='publisher-spo content d-flex'>
        <RequestDealSidebar
          title={glossary.dsp_reqdeal_sidebar_title}
          kind={'requestDeal'}
          size={'collapsed'}
          action={{
            icon: '',
            text: glossary.dsp_reqdeal_sidebar_add_button,
            className: 'tour-addnew-dsp',
            click: createDealReq,
          }}
          card={'expanded'}
          items={reqDealList}
          sidebar={handleSidebar}
          info={selectedCard}
          selected={selectedDeal}
          empty={empty}
          loading={skeleton}
          customerType={'demand'}
          icons={true}
          currentPage={currentPage}
          hasMore={hasMore}
          params={params}
          handleParams={handleParamsChange}
          allData={true}
        />
        <div className='view-section'>
          <Tab.Container
            defaultActiveKey={'details'}
            onSelect={(id: any) => sActiveTab(id)}
            activeKey={activeTab}
          >
            <div className='row align-items-center'>
              <div className='col-4 mt-0 d-flex align-items-center gap-2'>
                <h2 className='text-truncate'>{dealDetails.name}</h2>
                {dealDetails.name && dealDetails?.status !== 'offered' && (
                  <>
                    <MdDeleteOutline
                      className='svg-icon flex-shrink-0'
                      onClick={() => {
                        sDeleteDealModal(true);
                      }}
                      title='Delete'
                      fontSize={20}
                    />
                    <BiEditAlt
                      className='svg-icon flex-shrink-0'
                      onClick={() => {
                        sEditDealModal(true);
                      }}
                      title={'Edit'}
                      fontSize={20}
                    />
                  </>
                )}
              </div>
              <div className='col-4'>
                {reqDealList.length > 0 && (
                  <Nav variant='pills'>
                    <Nav.Item>
                      <Nav.Link eventKey='details'>
                        {glossary.dsp_reqdeal_section_tab_details}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='submitted-deals'>
                        {glossary.dsp_reqdeal_section_tab_submitted_deals}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                )}
              </div>
              <div className='col-4'></div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <Tab.Content>
                  <Tab.Pane eventKey={'details'}>
                    {skeleton ? (
                      <div style={{ marginTop: 65 }}>
                        <TableSkeleton columns={1} rows={12} rowSpacing={2} tableHeight={500} />
                      </div>
                    ) : (
                      <>
                        {empty ? (
                          <div className='text-center' style={{ marginTop: '100px' }}>
                            <img src={NoDataFound} alt='No Data Found' width={'30%'} />
                            <h5>{glossary.dsp_reqdeal_data_empty_msg}</h5>
                          </div>
                        ) : (
                          <div style={{ marginTop: 65 }}>
                            {dealDetails?.status === 'offered' && (
                              <div
                                style={{
                                  borderRadius: '4px',
                                  backgroundColor: '#ffffff',
                                  border: '1px solid #f9f9f9',
                                  padding: '16px',
                                  marginBottom: '16px',
                                }}
                              >
                                <h5 className='py-2'>{glossary.dsp_offerdeal_title}</h5>
                                <p>
                                  <strong>{dealConfigObj?.offer_publisher_name} </strong>
                                  {glossary.dsp_offerdeal_msg_one}{' '}
                                  <strong>{dealDetails?.name}</strong>.
                                </p>
                                <div className='d-flex justify-content-end gap-3 align-items-center'>
                                  <p
                                    className='text-button'
                                    onClick={() => sActiveTab('submitted-deals')}
                                  >
                                    {glossary.dsp_offerdeal_button_review_inventories}
                                  </p>
                                  <Button
                                    kind={'primary'}
                                    text={glossary.dsp_offerdeal_button_approve}
                                    click={() => approveOfferDeal(selectedDeal)}
                                  />
                                </div>
                              </div>
                            )}
                            <RequestedDealDetails
                              dealTerms={{
                                id: dealDetails?.id ?? '',
                                name: dealDetails?.name ?? '',
                                minCpm: dealDetails?.min_cpm_formatted ?? '',
                                maxCpm: dealDetails?.max_cpm_formatted ?? '',
                                startDate: dealDetails?.start_date_formatted ?? '',
                                requestedDealType: dealConfigObj.requested_deal_type,
                                impression: dealConfigObj.pg_impression,
                                description: dealConfigObj['content.description'] ?? '',
                                ctr: dealConfigObj['user.geo.country'] ?? [],
                                stt: dealConfigObj['user.geo.region'] ?? [],
                                cty: dealConfigObj['user.geo.city'] ?? [],
                                cat: dealConfigObj['content.cat'] ?? [],
                                dev: dealConfigObj['device.type'] ?? [],
                                p_type: dealConfigObj['platform.type'] ?? [],
                                artist: dealConfigObj['content.artist'] ?? '',
                                series: dealConfigObj['content.series'] ?? '',
                                title: dealConfigObj['content.title'] ?? '',
                                genre: dealConfigObj['content.genre'] ?? '',
                                isrc: dealConfigObj['content.isrc'] ?? '',
                                producerName: dealConfigObj['content.producer.name'] ?? '',
                                producerDomain: dealConfigObj['content.producer.domain'] ?? '',
                                contentrating: dealConfigObj['content.contentrating'] ?? '',
                                userrating: dealConfigObj['content.userrating'] ?? '',
                                qagmediarating: dealConfigObj['content.qagmediarating'] ?? '',
                                keywords: dealConfigObj['content.keywords'] ?? '',
                                len: dealConfigObj['content.len'] ?? '',
                                language: dealConfigObj['content.language'] ?? '',
                                networkDomain: dealConfigObj['content.network.domain'] ?? '',
                                networkName: dealConfigObj['content.network.name'] ?? '',
                                channelName: dealConfigObj['content.channel.name'] ?? '',
                                channelDomain: dealConfigObj['content.channel.domain'] ?? '',
                                context: dealConfigObj['content.context'] ?? '',
                                prodq: dealConfigObj['content.prodq'] ?? [],
                                livestream: dealConfigObj['content.livestream'] ?? [],
                                sourceRelationship:
                                  dealConfigObj['content.sourcerelationship'] ?? [],
                                embeddable: dealConfigObj['content.embeddable'] ?? [],
                                kwarray: dealConfigObj['content.kwarray'] ?? [],
                                applicationType: dealConfigObj['application.type'] ?? [],
                              }}
                              newid={newid}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey={'submitted-deals'}>
                    {!skeleton && empty ? (
                      <div className='text-center' style={{ marginTop: '100px' }}>
                        <img src={NoDataFound} alt='No Data Found' width={'30%'} />
                        <h5>{glossary.dsp_reqdeal_data_empty_msg}</h5>
                      </div>
                    ) : (
                      <RequestedDealPublisher dealID={selectedDeal} empty={empty} />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default DemandDeals;
