import { OverlayTrigger, Tooltip as Tool } from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';

interface Props {
  kind?: 'info' | 'general';
  id?: string;
  text?: string;
  item?: any;
  iconSize?: number;
  customClass?: string;
  placement?: 'top' | 'bottom';
}

const Tooltip = (props: Props) => {
  const { kind, id, text, item, iconSize, customClass, placement } = props;
  return (
    <>
      {kind === 'info' && (
        <OverlayTrigger placement={placement ?? 'auto'} overlay={<Tool id={id ?? ''}>{text}</Tool>}>
          <MdInfoOutline
            className={`${customClass} s-svg-icon ms-1`}
            style={{ marginBottom: '3px' }}
            fontSize={iconSize ?? 16}
          />
        </OverlayTrigger>
      )}

      {kind === 'general' && (
        <OverlayTrigger placement='auto' overlay={<Tool id={id ?? ''}>{text}</Tool>}>
          {item}
        </OverlayTrigger>
      )}
    </>
  );
};

export default Tooltip;
