import React, { useMemo, useState, useEffect } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from '../tables/scrollbarWidth';
import Switch from '../widgets/switch';
import {
  getEndpointTagsAPI,
  sourceTagsExcludeAPI,
  sourceTagsIncludeAPI,
  updateEndpointTagPercentageAPI,
  updateTagFloorpriceAPI,
} from '../../services/tags';
import { toast } from 'react-toastify';
import { IoIosArrowUp, IoIosArrowDown, IoIosCheckmark } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import { GrPowerReset } from 'react-icons/gr';
import {
  excludeTagSSPAPI,
  getTagListSSPAPI,
  includeTagSSPAPI,
  updateTagSSPAPI,
} from '../../services/spo';
import { MdInfoOutline } from 'react-icons/md';


const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className='search-wrap' style={{ zIndex: 2 }}>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        // placeholder={`${count} records...`}
        placeholder={'Search Traffic Data'}
        className='form-control'
        maxLength={32}
      />
    </div>
  );
};

const Table = ({ columns: userColumns, data }: any) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      //minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      //maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedRowIds,
    totalColumnsWidth,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      //defaultColumn,
      // getSubRows: (row) => row.inventories || [],
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    //useBlockLayout
    useFlexLayout
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <div {...cell.getCellProps()} className='td' key={index} title={cell.value}>
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className='table-wrap mt-0'>
        <div {...getTableProps()} className='table tags'>
          <div className='table-header text-break'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className='th'
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoIosArrowDown className='svg-icon' fontSize={20} />
                        ) : (
                          <IoIosArrowUp className='svg-icon' fontSize={20} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{ width: '100%', height: `calc(100vh - 340px)`, overflowX: 'hidden' }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </div>
    </>
  );
};

const PartnerTagsListTable = (props: any) => {
  const [updatedData, setUpdatedData] = useState();

  useEffect(() => {
    setUpdatedData(props.rows);
  }, [props.rows]);

  const tagsToggleInclude = (row: any, id: any) => {
    const payload = {
      spo_id: id,
      tag_id: row.original.id,
    };
    includeTagSSPAPI(payload)
      .then((data: any) => {
        if (data) {
          setUpdatedData(data?.data);
          if (id == row.original.id) {
            props.getEndpointListInfoAPI(data, 'list');
          }
          toast.success('Included Successfully.');
        }
      })
      .catch(() => {
        //ok
      });
  };

  const tagsToggleExclude = (row: any, id: any) => {
    const payload = {
      spo_id: id,
      tag_id: row.original.id,
    };
    excludeTagSSPAPI(payload)
      .then((data: any) => {
        if (data) {
          setUpdatedData(data?.data);
          if (id == row.original.id) {
            props.getEndpointListInfoAPI(data, 'list');
          }
          toast.success('Excluded Successfully.');
        }
      })
      .catch(() => {
        //ok
      });
  };

  useEffect(() => {
    if (props.selectedCustomer === 0) {
      getSourceTagsList(0);
    }
  }, [props.selectedCustomer]);

  const getSourceTagsList = (id: any) => {
    const payload = {
      partner_id: id,
    };
    getTagListSSPAPI(payload)
      .then((data: any) => {
        if (data) setUpdatedData(data);
      })
      .catch(() => {
        //ok
      });
  };

  const handleResetTag = (row: any, value: any, type: any) => {
    const payload = {
      floor_price: value && type === 'floor-price' ? Number(value) : row?.original?.floor_price,
      percentage: value && type === 'traffic_multiplier' ? Number(value) : row?.original?.traffic_multiplier,
      spo_id: row.original.partner_id,
      tag_id: row.original.id,
    };
    updateTagSSPAPI(payload)
      .then((data: any) => {
        if (data.status) {
          getSourceTagsList(row?.original?.partner_id);
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const handleUpdateTag = (row: any, value: any, type: any) => {
    if (row.original.id && value < 1) {
      tagsToggleExclude(row, row?.original?.partner_id);
    } else {
      const payload = {
        floor_price: value && type === 'floor-price' ? Number(value) : row?.original?.floor_price,
        percentage: value && type === 'traffic_multiplier' ? Number(value) : row?.original?.traffic_multiplier,
        spo_id: row.original.partner_id,
        tag_id: row.original.id,
      };
      updateTagSSPAPI(payload)
        .then((data: any) => {
          if (data.status) {
            getSourceTagsList(row?.original?.partner_id);
            toast.success(data?.message);
          } else {
            toast.error(data?.message);
          }
        })
        .catch((error: any) => {
          //ok
        });
    }
  };

  const updateTrafficAllocation = (row: any, value: any) => {
    if (row.original.id && value < 1) {
      tagsToggleExclude(row, row?.original?.id);
    } else {
      const payload = {
        tag_id: row.original.id,
        percentage: value ? Number(value) : row?.original?.traffic_multiplier,
        endpoint_id: row?.original?.end_point_id,
      };
      updateEndpointTagPercentageAPI(payload)
        .then((data: any) => {
          if (data?.message?.traffic_multiplier) {
            toast.error('Value must be between 0 to 100.');
            getSourceTagsList(row?.original?.end_point_id);
          } else {
            getSourceTagsList(row?.original?.end_point_id);
            toast.success('Traffic allocation updated successfully.');
          }
        })
        .catch((error: any) => {
          //ok
        });
    }
  };

  const TrafficAllocationInput = (row: any) => {
    const [updatedValue, setUpdatedValue] = useState(row?.row?.original?.traffic_multiplier ?? '');
    const [btnShow, setBtnShow] = useState(false);

    const changedValue = (e: any) => {
      const newValue = e.target.value;

      if (newValue == '') setBtnShow(true);
      if (/^[0-9]*$/.test(newValue) && newValue <= 100) {
        setUpdatedValue(newValue);
        setBtnShow(true);
      } else {
        //ok
      }
    };

    return (
      <>
        <input
          className='text-input-mini'
          type='text'
          defaultValue={row?.row?.original?.traffic_multiplier}
          value={updatedValue}
          onChange={changedValue}
          placeholder=''
          min={0}
          max={100}
          maxLength={3}
          style={{ maxWidth: '70px', minWidth: '40px', maxHeight: '30px', minHeight: '20px' }}
        />
        {btnShow && (
          <>
            <div className='d-flex align-items-center'>
              <div>
                <IoIosCheckmark
                  fontSize={30}
                  onClick={() => {
                    handleUpdateTag(row.row, updatedValue, 'traffic_multiplier');
                    setUpdatedValue(updatedValue || row?.row?.original?.traffic_multiplier);
                    setBtnShow(false);
                  }}
                  color={'#289679'}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div>
                <MdOutlineClose
                  fontSize={20}
                  onClick={() => {
                    setUpdatedValue(row?.row?.original?.traffic_multiplier);
                    setBtnShow(false);
                  }}
                  color={'#f1494a'}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const FloorPriceInput = (row: any) => {
    const [updatedValue, setUpdatedValue] = useState(row?.row?.original?.floor_price ?? '');
    const [btnShow, setBtnShow] = useState(false);

    const changedValue = (e: any) => {
      const newValue = e.target.value;

      if (newValue == '') setBtnShow(true);
      if (/^[0-9]*\.?\d{0,2}$/.test(newValue) && newValue <= 1000) {
        setUpdatedValue(newValue);
        setBtnShow(true);
      } else {
        //ok
      }
    };

    return (
      <>
        <input
          className='text-input-mini'
          type='text'
          defaultValue={row?.row?.original?.floor_price}
          value={updatedValue}
          onChange={changedValue}
          placeholder=''
          min={0}
          max={1000}
          maxLength={7}
          style={{ maxWidth: '70px', minWidth: '40px', maxHeight: '30px', minHeight: '20px' }}
        />
        {!btnShow ? (
          <div>
            <GrPowerReset
              fontSize={20}
              className='icon-onhover svg-icon'
              onClick={() => handleResetTag(row.row, '-1', 'floor-price')}
              title='Reset: After click on it that floor price updated with original tag floorprice.'
            />
          </div>
        ) : (
          <>
            <div className='d-flex align-items-center'>
              <div>
                <IoIosCheckmark
                  fontSize={30}
                  onClick={() => {
                    handleUpdateTag(row.row, updatedValue, 'floor-price');
                    setUpdatedValue(updatedValue || row?.row?.original?.floor_price);
                    setBtnShow(false);
                  }}
                  color={'#289679'}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div>
                <MdOutlineClose
                  fontSize={20}
                  onClick={() => {
                    setUpdatedValue(row?.row?.original?.floor_price);
                    setBtnShow(false);
                  }}
                  color={'#f1494a'}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Exclude/Include',
        accessor: 'included',
        Cell: ({ row }: any) => (
          <>
            <Switch
              key={row.id}
              api={(chec: any) => {
                !chec
                  ? tagsToggleInclude(row, row?.original?.partner_id)
                  : tagsToggleExclude(row, row?.original?.partner_id);
              }}
              status={updatedData ?? row.original.included}
            />
          </>
        ),
        width: 70,
      },
      {
        Header: () => (
          <>
            Actual Traffic (%) <MdInfoOutline className='svg-icon' fontSize={20} title='2% is actual traffic allocation decided by our platform based on serving'/>
          </>
        ),
        accessor: 'real_traffic_multiplier',
        width: 140,
      },
      {
        Header: () => (
          <>
            Traffic Allocation (%) <MdInfoOutline className='svg-icon' fontSize={20} title='Traffic Allocation % defines maximum amount of traffic capping for this Demand Source. Actual Traffic Allocation may be lesser depending upon serving from this Demand Source.'/>
          </>
        ),
        accessor: 'traffic_multiplier',
        Cell: ({ row }: any) => (
          <>
            {row.original.included === false ? (
              <p key={row.id} className='ms-1'>
                {row?.original?.traffic_multiplier}
              </p>
            ) : (
              <>
                <TrafficAllocationInput row={row} />
              </>
            )}
          </>
        ),
        width: 160,
      },
      {
        Header: 'Tags Name',
        accessor: 'name',
      },
      {
        Header: 'Tags Type',
        accessor: 'tag_type_formatted',
        width: 80,
      },
      {
        Header: 'Floor Price ($)',
        accessor: 'floor_price_formatted',
        Cell: ({ row }: any) => (
          <>
            <FloorPriceInput row={row} />
          </>
        ),
        width: 100,
      },
      {
        Header: 'Duration',
        accessor: 'duration_formatted',
        width: 100,
      },
      {
        Header: 'Soft Floor',
        accessor: 'is_soft_floor',
        Cell: ({ row }: any) => (
          <div className='disable-field'>
            <Switch
              key={row.id}
              api={(chec: any) => {
                // !chec
                //   ? tagsToggleExclude(row, row?.original?.end_point_id)
                //   : tagsToggleInclude(row, row?.original?.end_point_id);
              }}
              status={updatedData ?? row.original.is_soft_floor}
            />
          </div>
        ),
        // Cell: ({ row }: any) => <>{row.original.is_soft_floor === true ? 'On' : 'Off'}</>,
        width: 100,
      },
      {
        Header: 'Status',
        accessor: 'status_formatted',
        width: 100,
      },
      {
        Header: 'Created On',
        accessor: 'created_on_formatted',
        width: 120,
      },
    ],
    []
  );

  const data = updatedData ?? props.rows;

  return (
    <>
      <Table columns={columns} data={data} />
      <div className='d-felx gap-2'>
        <MdInfoOutline className='svg-icon' fontSize={20}/>
        <span>Traffic Allocation % defines maximum amount of traffic capping for this Demand Source. Actual Traffic Allocation may be lesser depending upon serving from this Demand Source.</span>
      </div>
    </>
  );
};

export default PartnerTagsListTable;
