import { headers, routes } from './constants';

export const creativeSendOtpAPI = (payload: any) => {
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeSendOtp, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeVerifyOtpAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeVerifyOtp, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeCompleteProfileAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeCompleteProfile, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeUserValidateAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeUserValidate, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetLoopListAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeLooplist, options)
      .then((data) => Promise.all([data.json(), data.status]))
      .then(([data, status]: any) => {
        const result = {
          code: status,
          data: data,
        };
        if (result?.code === 401 || result?.code === 422) localStorage.clear();
        resolve(result.data);
      })
      .catch((error) => reject(error));
  });
};

export const creativeGetUserProfileAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(`${routes.creativeUserProfile}/${payload.nickname}`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetLoopVideosAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeLoopVideos, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetProfileVideosAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeProfileVideos, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeSetVideoLibraryAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeSetVideoLibrary, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeSetSocialProfileAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeSetSocialProfile, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetSocialProfileAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeGetSocialProfile, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetSocialProfileNewAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeGetSocialProfileNew, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeAddSocialProfileAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeAddSocialProfile, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeRemoveSocialProfileAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeRemoveSocialProfile, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeSyncSocialProfileAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeSyncSocialProfile, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeBrandConfigLogoUploadAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeBrandConfigLogoUpload, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeAgencyInfoUpdateAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeAgencyInfoUpdate, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeProfileVideoUploadAPI = (payload: any) => {
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
    },
    method: 'POST',
    body: payload,
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeProfileVideoUpload, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetCommunitiesListAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeGetCommunitiesList, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const creativeGetCommunityLoopAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.creativeGetCommunityLoop, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
