import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { FixedSizeList } from 'react-window';
import TableSkeleton from '../loaders/table';
import logoPlaceholder from '../../assets/images/logo-placeholder.png';

const NewInviteCustomer = (props: any) => {
  const {
    rows,
    onNextPage,
    onSearchChage,
    hasMore,
    loading,
    type,
    currentPage,
    search,
    moveNextPage,
    selectedRowData,
    removeRow,
  } = props;

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');

  // Simulated API call for initial data
  useEffect(() => {
    setFilteredData(rows);
  }, [rows]);

  const handleCheckboxChange = useCallback(
    (item: any) => {
      const itemIndex = selectedItems.findIndex(
        (selectedItem: any) => selectedItem.domain === item.domain
      );

      if (itemIndex !== -1) {
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems.splice(itemIndex, 1);
        setSelectedItems(updatedSelectedItems);
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    },
    [selectedItems]
  );

  useMemo(() => {
    if (removeRow.domain) {
      setSelectedItems(selectedItems.filter((item: any) => item.domain !== removeRow.domain));
    }
  }, [removeRow.domain]);

  useMemo(() => {
    selectedRowData(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (searchText) {
      const getData: any = setTimeout(() => {
        onSearchChage(searchText);
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchText]);

  const onImageError = (e: any) => {
    e.target.src = logoPlaceholder;
  };

  const renderCard = ({ index, style }: any) => {
    const item: any = filteredData[index];
    return (
      <div
        key={item.name}
        style={{
          ...style,
          borderBottom: '1px solid #f4f4f4',
          borderRight: '1px solid #f4f4f4',
          borderLeft: '1px solid #f4f4f4',
        }}
        className={`d-flex align-items-center ${
          !selectedItems.some((x) => x.domain === item.domain) &&
          selectedItems.length >= 10 &&
          'disable'
        } `}
      >
        <div className='p-2' style={{ borderRight: '1px solid #f4f4f4' }}>
          <input
            type='checkbox'
            checked={selectedItems.some((x) => x.domain === item.domain)}
            onChange={() => handleCheckboxChange(item)}
            disabled={
              !selectedItems.some((x) => x.domain === item.domain) && selectedItems.length >= 10
                ? true
                : false
            }
          />
        </div>
        <div className='text-nowrap p-2'>
          <img
            src={item.logo_url}
            style={{
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
            onError={onImageError}
          />
          <a href={`http://www.${item?.domain}`} target='_blank' rel='noreferrer'>
            {item?.name}
            <GoLinkExternal className='svg-icon' fontSize={15} title='Redirect URL' />
          </a>
        </div>
      </div>
    );
  };

  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (
        !loading &&
        hasMore &&
        visibleStopIndex === filteredData.length - 1 &&
        filteredData[filteredData.length - 1]
      ) {
        onNextPage(currentPage + 1);
      }
    },
    [currentPage, filteredData.length, loading, hasMore]
  );

  useEffect(() => {
    if (filteredData?.every((item: any) => item?.is_exist) && moveNextPage) {
      onNextPage(currentPage + 1);
    }
  }, [moveNextPage, filteredData]);

  return (
    <>
      {search && (
        <div className='search-wrap mt-0'>
          <input
            onChange={(e: any) => {
              if (e.target.value.length > 0) {
                setSearchText(e.target.value || '');
                setFilteredData([]);
              } else {
                onSearchChage('');
                setFilteredData([]);
              }
            }}
            type='search'
            className='form-control'
            placeholder={type === 'spo-publisher' ? 'Search Agencies' : 'Search Publisher'}
            maxLength={64}
          />
        </div>
      )}

      <div className='spo-inventories'>
        <div style={{ display: 'flex', backgroundColor: '#f4f4f4', padding: '8px 10px' }}>
          <strong style={{ padding: '0px 30px' }}>Name</strong>
        </div>
        {filteredData.length === 0 &&
          searchText &&
          (loading ? (
            <div className='d-grid gap-2 mt-2'>
              <TableSkeleton columns={2} rows={5} rowSpacing={10} tableHeight={200} />
            </div>
          ) : (
            filteredData && <p className='text-center'>Data not found</p>
          ))}

        <div style={{ height: '100%' }}>
          <FixedSizeList
            height={1000}
            width='100%'
            itemCount={filteredData.length}
            itemSize={40}
            onItemsRendered={handleItemsRendered}
            style={{
              // width: '100%',
              height: `${
                loading && currentPage === 0 && filteredData.length === 0
                  ? '30px'
                  : 'calc(100vh - 360px)'
              }`,
              overflowY: 'auto',
            }}
          >
            {renderCard}
          </FixedSizeList>

          {loading && <TableSkeleton columns={2} rows={1} rowSpacing={0} tableHeight={30} />}
        </div>
      </div>
    </>
  );
};

export default NewInviteCustomer;
