import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CustomerTabs from 'react-bootstrap/Tabs';
import CustomerTab from 'react-bootstrap/Tab';
// import Pill from '../../widgets/pill';
import { routes } from '../../../services/constants';
import InfyTableComponent from '../../comman/table/InfyTableComponent';
import StripConnect from './stripConnect';
// import { UserInfoContext } from '../../contexts/userInfoContext';

// TODO:
// Display all stripe payment method list for Publisher & SPO

export default ({ toast, store }: any) => {
  // const [financeSection2, sFinanceSection2] = useState('1');
  const [payoutApiPath, sPayoutApiPath] = useState('');
  const [platFormCostApiPath, sPlatFormCostApiPath] = useState('');
  const [earningsApiPath, sEarningsApiPath] = useState('');

  const { pathname } = useLocation();
  // const { setBalanceHeader }: any = useContext(UserInfoContext);

  // const [glossary, sGlossary] = useState<any>({});
  // useEffect(() => {
  //   sGlossary(store.getState());
  //   const unsubscribe = store.subscribe(() => {
  //     sGlossary(store.getState());
  //   });
  //   return () => unsubscribe();
  // }, []);

  const tabConfig = [
    {
      key: 'partner',
      title: 'Earning By Partner',
      api: earningsApiPath,
      method: 'POST',
      tableName: 'Earning-By-Partner',
      downloadCSV: false,
      search: false,
    },
    {
      key: 'platform-cost',
      title: 'Platform Cost',
      api: platFormCostApiPath,
      method: 'POST',
      tableName: 'Platform Cost',
      downloadCSV: false,
      search: false,
    },
    {
      key: 'earning',
      title: 'Monthly Earning',
      api: payoutApiPath,
      method: 'POST',
      tableName: 'Monthly Earning',
      downloadCSV: false,
      search: false,
    },
    {
      key: 'details',
      title: 'Details',
      tableName: 'Publisher Customer Details',
      downloadCSV: false,
      search: false,
    },
  ];

  // TODO: Use for get Stripe payment method list
  // const getPaymentMethods = () => {
  //   sSkeletonCard(true);
  //   getPaymentMethodsAPI()
  //     .then((data: any) => {
  //       sMethods(data.data);
  //       sSkeletonCard(false);
  //     })
  //     .catch((error) => {
  //       // toast.error('Service unavailable, Check back after sometime.');
  //     });
  // };

  useEffect(() => {
    if (
      pathname === '/settings/payout' &&
      (localStorage.getItem('customer_type') === 'publisher' ||
        localStorage.getItem('customer_type') === 'supply')
    ) {
      sPayoutApiPath(routes.publisherPayout);
      sPlatFormCostApiPath(routes.publisherPlatformCost);
      sEarningsApiPath(routes.publisherEarningByPartner);
    }
  }, [pathname, routes]);

  return (
    <>
      <div
        className={
          'align-items-start flex-column gap-3 justify-content-start row flex-lg-row finance-container ssp'
        }
      >
        <div className={'col-auto'}>
          <h2 style={{ fontWeight: 200 }}>Finance</h2>
        </div>
        {/* <div className={'col col-auto'}>
          <Pill
            items={[
              { id: '1', label: glossary.ssp_payouts_tabs_button_earning_by_partner },
              { id: '2', label: glossary.ssp_payouts_tabs_button_platformcost },
              { id: '3', label: glossary.ssp_payouts_tabs_button_earnings },
              { id: '4', label: glossary.ssp_payouts_tabs_button_details },
              // { id: '1', label: glossary.ssp_payouts_tabs_button_payouts },
            ]}
            select={sFinanceSection2}
            selected={financeSection2}
          />
        </div>
        <div className='col'></div> */}

        <CustomerTabs defaultActiveKey={tabConfig[0]?.key} className='my-3'>
          {tabConfig?.map((tab: any) => (
            <CustomerTab key={tab.key} eventKey={tab.key} title={tab.title}>
              {tab.key === 'details' ? (
                <StripConnect />
              ) : (
                <>
                  {pathname === '/settings/payout' && (
                    <InfyTableComponent
                      columnWidth={130}
                      api={tab.api}
                      method={tab.method}
                      tableName={tab.tableName}
                      downloadCSV={tab.downloadCSV}
                      search={tab.search}
                    />
                  )}
                </>
              )}
            </CustomerTab>
          ))}
        </CustomerTabs>
      </div>
      {/* {financeSection2 === '1' && (
        <div style={{ marginTop: 40 }}>
          <InfyTableComponent
            columnWidth={130}
            api={earningsApiPath}
            tableName={'Earning-By-Partner'}
            method={'POST'}
            downloadCSV={false}
            search={false}
          />
        </div>
      )} */}

      {/* {financeSection2 === '2' && (
        <div style={{ marginTop: 40 }}>
          <InfyTableComponent
            columnWidth={130}
            api={platFormCostApiPath}
            method={'POST'}
            tableName={'Platform Cost'}
            downloadCSV={false}
            search={false}
          />
        </div>
      )} */}

      {/* {financeSection2 === '3' && (
        <div style={{ marginTop: 40 }}>
          <InfyTableComponent
            columnWidth={130}
            api={payoutApiPath}
            method={'POST'}
            downloadCSV={false}
            search={false}
            // insertNewData={newObject}
            // insertIndex={0}
          />
        </div>
      )} */}

      {/* {financeSection2 === '4' && (
        <div style={{ marginTop: '45px' }}>
          <StripConnect />
        </div>
      )} */}
    </>
  );
};
