import React, { useEffect, useState } from 'react';
import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';
import Tile from '../../widgets/tile';
import Line from '../../charts/line';
import Reports from '../../helpers/reports';

const GenuinReporting = ({
  singleTileData,
  random,
  loading,
  tagID,
  start,
  end,
  datatables,
  customerId,
}: any) => {
  const [selectedTile, setSelectedTile] = useState<any>(0);
  const [lineData, setLineData] = useState([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [attribute, sAttribute] = useState<any>(null);
  const [rawLineData, sRawLineData] = useState<any>([]);

  const selectTile = (id: number) => {
    setSelectedTile(id);
  };

  useEffect(() => {
    if (singleTileData) {
      setLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      /* replace new value array */
      sRefinedLineData({ labels: newValues, values: newlabels });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  return (
    <>
      {loading ? (
        // Data loading view
        <>
          <div className='col-6'>
            <div className='d-flex gap-4'>
              {[1, 2, 3, 4].map((index) => {
                return <Box key={index} />;
              })}
            </div>
          </div>
          <div className='col-12 mt-4'>
            <XYChart />
          </div>
        </>
      ) : (
        <div className={'col-12'}>
          {/* Tile View */}
          <div className='row'>
            {singleTileData?.map((tile: any, index: any) => {
              return (
                <div key={index} className={'col-auto'}>
                  {refinedLineData?.labels && refinedLineData?.values && (
                    <Tile
                      id={tile.id}
                      selected={selectedTile}
                      click={selectTile}
                      label={tile.label}
                      value={tile.value}
                      info={tile.info}
                      arrow={tile.arrow}
                    />
                  )}
                </div>
              );
            })}
          </div>

          {/* Graph View */}
          <div
            className={'row'}
            style={{
              height: '320px',
              marginTop: '24px',
            }}
          >
            <Line label={'Impressions'} data={refinedLineData} attribute={attribute} />
          </div>

          <div className={'row report-table'} style={{ marginTop: 24 }}>
            <Reports
              id={tagID}
              start={start}
              end={end}
              tabs={datatables}
              random={random}
              customerId={customerId}
              entityType={'extension'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GenuinReporting;
