import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import store from '../../states';
import { toast } from 'react-toastify';
import { demandAdTxtAddAPI } from '../../services/endpoint';
import FormikController from '../comman/form/FormikController';
import Button from '../widgets/button';

export default (props: any) => {
  const { show, onHide, api, reseller } = props;

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const domainReg =
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;

  // /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const AdstxtSchema = Yup.object().shape({
    selleraccountid: Yup.string()
      .required(glossary.setting_dsp_adstxt_add_reseller_popup_input_seller_empty_error)
      .min(2, glossary.setting_dsp_adstxt_add_reseller_popup_input_seller_min_error)
      .matches(
        /[^\s*].*[^\s*]/g,
        glossary.setting_dsp_adstxt_add_reseller_popup_input_blank_space_error
      ),
    exchangedomain: Yup.string()
      .required(glossary.setting_dsp_adstxt_add_reseller_popup_input_domain_empty_error)
      .matches(domainReg, glossary.setting_dsp_adstxt_add_reseller_popup_input_domain_valid_error),
    tagid: Yup.string()
      .min(2, glossary.setting_dsp_adstxt_add_reseller_popup_input_tagid_min_error)
      .matches(
        /[^\s*].*[^\s*]/g,
        glossary.setting_dsp_adstxt_add_reseller_popup_input_blank_space_error
      ),
  });

  const adsTxtFormInitialValue = {
    selleraccountid: reseller?.seller_account_id ?? '',
    exchangedomain: reseller?.exchange_domain ?? '',
    tagid: reseller?.tag_id ?? '',
  };

  const addAdTxt = (e: any) => {
    const payload = {
      // company_id: '',
      payment_type: 'reseller',
      seller_account_id: e.selleraccountid,
      exchange_domain: e.exchangedomain,
      tag_id: e.tagid,
    };
    setBtnDisabled(true);
    demandAdTxtAddAPI(payload)
      .then((data: any) => {
        setBtnDisabled(false);
        if (data.status === true) {
          if (reseller) {
            toast.success(glossary.setting_dsp_adstxt_update_reseller_success_msg);
            onHide();
            api();
          } else {
            toast.success(glossary.setting_dsp_adstxt_add_reseller_success_msg);
            onHide();
            api();
          }
        } else {
          toast.error(data.message);
        }
      })
      .catch(() => {
        //ok
      });
  };

  return (
    <Modal
      centered
      size={'lg'}
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <div className='modal-header px-3'>
        <h2>
          {!reseller
            ? glossary.setting_dsp_adstxt_add_reseller_popup_title
            : glossary.setting_dsp_adstxt_update_reseller_popup_title}
        </h2>
      </div>
      <Formik
        validationSchema={AdstxtSchema}
        initialValues={adsTxtFormInitialValue}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={(e) => {
          addAdTxt(e);
        }}
      >
        {({ errors, touched }) => (
          <Form className='rtb-form'>
            <div
              className={'modal-body p-3'}
              style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto',
              }}
            >
              <div className='row mb-3'>
                <div className='col gap-3'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={
                        glossary.setting_dsp_adstxt_add_reseller_popup_input_field_seller_label
                      }
                      placeholder={
                        glossary.setting_dsp_adstxt_add_reseller_popup_input_seller_placeholder
                      }
                      name='selleraccountid'
                      maxLength={256}
                    />
                  </div>

                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={
                        glossary.setting_dsp_adstxt_add_reseller_popup_input_field_domain_label
                      }
                      placeholder={
                        glossary.setting_dsp_adstxt_add_reseller_popup_input_domain_placeholder
                      }
                      name='exchangedomain'
                      maxLength={256}
                    />
                  </div>

                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.setting_dsp_adstxt_add_reseller_popup_input_field_tagid_label}
                      placeholder={
                        glossary.setting_dsp_adstxt_add_reseller_popup_input_tagid_placeholder
                      }
                      name='tagid'
                      maxLength={256}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Modal.Footer className='gap-2'>
              <Button
                type={'button'}
                kind={'secondary'}
                text={glossary.setting_dsp_adstxt_add_reseller_popup_button_cancel}
                click={() => {
                  onHide();
                }}
              />

              {btnDisabled ? (
                <Button
                  kind={'spinner'}
                  type={'button'}
                  text={reseller ? glossary.updating : glossary.loading}
                />
              ) : (
                <Button
                  type={'submit'}
                  kind={'primary'}
                  text={
                    reseller
                      ? glossary.setting_dsp_adstxt_update_reseller_popup_button_update
                      : glossary.setting_dsp_adstxt_add_reseller_popup_button_add
                  }
                />
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
