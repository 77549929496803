import { useState } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { sendEmailAPI } from '../../../services/tags';
import FormikController from '../../comman/form/FormikController';
import Button from '../../widgets/button';

const initialValues = {
  name: '',
};

const SendEmail = ({ tagId, glossary }: any) => {
  const [spinnerBtn, sSpinnerBtn] = useState(false);

  //Email Validation
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .email(glossary.ssp_createssai_success_email_valid_error)
      .required(glossary.ssp_createssai_success_email_empty_error),
  });

  //Send Mail Api Call
  const handleSendEmail = (e: any) => {
    if (tagId) {
      const id = tagId;
      const payload = {
        id,
        email: e.name,
        //ssai_url: tagFinalURLSSAI,
      };
      sSpinnerBtn(true);
      sendEmailAPI(payload)
        .then((data: any) => {
          if (data) {
            ReactGA.event('ssp_supply_ssai_email_send_response', {
              category: 'ssai',
              action: 'click',
              label: 'ssp_supply_ssai_email_send_response',
            });
            //sMailSent(true);
            toast.success(glossary.ssp_createssai_success_mailsent_msg);
            sSpinnerBtn(false);
          }
        })
        .catch(() => {
          //
        });
    }
  };

  return (
    <>
      <div className='col-6'>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(e) => {
            handleSendEmail(e);
          }}
        >
          {() => (
            <Form>
              <div className='d-flex'>
                <div className='form-group col-auto'>
                  <FormikController
                    kind='withouticon'
                    control='input'
                    type='text'
                    label={glossary.ssp_createssai_success_input_email}
                    placeholder={glossary.ssp_createssai_success_input_placeholder_email}
                    name='name'
                    length={320}
                  />
                </div>
                <div className='d-flex flex-column'>
                  <label className='label'>&nbsp;</label>
                  {spinnerBtn ? (
                    <Button
                      type='button'
                      kind={'spinner'}
                      customClass={'ms-3 col-auto'}
                      text={'Loading...'}
                    />
                  ) : (
                    <Button
                      type='submit'
                      kind={'primary'}
                      customClass={'ms-3'}
                      text={glossary.ssp_createssai_success_button_send}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SendEmail;
