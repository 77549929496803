import { useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import Button from '../widgets/button';
import { addGlossaryAPI } from '../../services/settings';
import FormikController from '../comman/form/FormikController';
// import store from '../../states';

export default (props: any) => {
  const { show, onHide, api } = props;

  const [isLoading, setIsLoading] = useState(false);

  // const [glossary, sGlossary] = useState<any>({});
  // useEffect(() => {
  //   sGlossary(store.getState());
  //   const unsubscribe = store.subscribe(() => {
  //     sGlossary(store.getState());
  //   });
  //   return () => unsubscribe();
  // }, []);

  const addGlossary = (e: any) => {
    const payload = {
      key: e.glossary_key,
      value: e.glossary_value,
    };
    setIsLoading(true);
    addGlossaryAPI(payload)
      .then((data: any) => {
        if (data) {
          api();
          onHide();
          if (data.message === 'glossary exist in table') {
            toast.error(data.message);
          } else {
            toast.success(data.message);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // props.toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const validationSchema = Yup.object().shape({
    glossary_key: Yup.string()
      .required('Enter glossary key.')
      .trim()
      .notOneOf([''], 'Enter valid key.'),
    glossary_value: Yup.string()
      .required('Enter glossary value.')
      .trim()
      .notOneOf([''], 'Enter valid glossary value.'),
  });

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2>Add Glossary</h2>
      </Modal.Header>

      <Formik
        initialValues={{
          glossary_key: '',
          glossary_value: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(e) => {
          addGlossary(e);
        }}
      >
        {({ values }) => (
          <Form>
            <Modal.Body>
              <div className='row'>
                <div className='col-12 mb-3'>
                  <FormikController
                    type='text'
                    control='input'
                    kind='withouticon'
                    name={'glossary_key'}
                    label={'Add Key *'}
                    placeholder={'Enter Glossary Key'}
                  />
                </div>
                <div className='col-12'>
                  <FormikController
                    type='text'
                    control='input'
                    kind='withouticon'
                    name={'glossary_value'}
                    label={'Add Value *'}
                    placeholder={'Enter Glossary Value'}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='gap-2'>
              <Button
                type='button'
                text={'Cancel'}
                kind={'secondary'}
                click={() => {
                  onHide();
                }}
              />
              {isLoading ? (
                <Button text={'Adding...'} kind={'spinner'} type={'button'} />
              ) : (
                <Button type='submit' text={'Add'} kind={'primary'} />
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
