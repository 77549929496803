import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../widgets/button';

import tour1 from '../../assets/images/tour-1.svg';
import tour2 from '../../assets/images/tour-2.svg';
import tour3 from '../../assets/images/tour-3.svg';
import tour4 from '../../assets/images/tour-4.svg';
import dspTour1 from '../../assets/images/rtg-1.svg';
import dspTour2 from '../../assets/images/rtg-2.svg';
import dspTour3 from '../../assets/images/rtg-3.svg';
import dspTour4 from '../../assets/images/rtg-4.svg';

import { updateTourAPI } from '../../services/tags';
import { useLocation } from 'react-router-dom';
import glossary from '../../states/glossary';

export default (props: any) => {
  const { show, onHide, tourCompleted } = props;

  const [section, sSection] = useState(1);
  const [tourDone, sTourDone] = useState(false);

  const updateTour = () => {
    const payload = {
      tour: 'info',
    };
    if (localStorage.getItem('token')) {
      updateTourAPI(payload)
        .then((data: any) => {
          tourCompleted();
          onHide();
          sTourDone(data.status);
          props.tourCompleted(tourDone);
        })
        .catch(() => {
          // ok
        });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'ArrowRight' && section <= 3) {
      sSection((prev: any) => prev + 1);
    } else {
      event.preventDefault();
    }

    if (event.key === 'ArrowLeft' && section >= 2) {
      sSection((prev: any) => prev - 1);
    } else {
      event.preventDefault();
    }
  };

  return (
    <div onKeyDown={handleKeyPress}>
      {localStorage.getItem('customer_type') === 'demand' ||
      localStorage.getItem('customer_type') === 'spo' ? (
        <Modal show={show} centered size={'lg'}>
          <div className={'modal-header px-3 border-bottom-0 pt-4'}>
            <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
              {section === 1 && <h2>{glossary.dsp_tour_title_one}</h2>}
              {section === 2 && <h2>{glossary.dsp_tour_title_two}</h2>}
              {section === 3 && <h2>{glossary.dsp_tour_title_three}</h2>}
              {section === 4 && <h2>{glossary.dsp_tour_title_four}</h2>}
            </h3>
          </div>
          <Modal.Body
            className='modal-body px-3 py-0'
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {section <= 4 && (
              <>
                <div
                  className='align-items-center d-flex justify-content-center'
                  style={{
                    border: '30px',
                    borderStyle: 'solid',
                    borderColor: '#731780',
                    textAlign: 'center',
                    height: '400px',
                    overflow: 'hidden',
                    padding: '20px',
                  }}
                >
                  {section === 1 && <img src={dspTour1} width={'w-auto'} />}
                  {section === 2 && <img src={dspTour2} width={'w-auto'} />}
                  {section === 3 && <img src={dspTour3} width={'w-auto'} />}
                  {section === 4 && <img src={dspTour4} width={'w-auto'} />}
                </div>
                <p className='my-3'>
                  {section === 1 && glossary.dsp_tour_instruction_one}
                  {section === 2 && glossary.dsp_tour_instruction_two}
                  {section === 3 && glossary.dsp_tour_instruction_three}
                  {section === 4 && glossary.dsp_tour_instruction_four}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className='justify-content-between px-3'>
            {section <= 4 && (
              <>
                <div className={'d-flex gap-2'}>
                  <div
                    onClick={() => sSection(1)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 1 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                  <div
                    onClick={() => sSection(2)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 2 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                  <div
                    onClick={() => sSection(3)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 3 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                  <div
                    onClick={() => sSection(4)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 4 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                </div>
                <div className='d-flex gap-3'>
                  <button
                    className={`button ${section === 1 ? 'button-disabled' : 'button-secondary'}`}
                    type='button'
                    onClick={(e: any) => {
                      if (section !== 1) {
                        sSection(section - 1);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    Previous
                  </button>

                  <Button
                    kind={'primary'}
                    text={section === 4 ? glossary.done_btn : glossary.next_btn}
                    click={() => {
                      if (section <= 3) {
                        sSection((prev: any) => prev + 1);
                      }
                      if (section === 4) {
                        updateTour();
                      }
                      sTourDone(true);
                    }}
                  />
                </div>
              </>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} centered size={'lg'}>
          <div className={'modal-header px-3 border-bottom-0 pt-4'}>
            <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
              {section === 1 && <h2>{glossary.ssp_tour_heading_one}</h2>}
              {section === 2 && <h2>{glossary.ssp_tour_heading_two}</h2>}
              {section === 3 && <h2>{glossary.ssp_tour_heading_three}</h2>}
              {section === 4 && <h2>{glossary.ssp_tour_heading_four}</h2>}
            </h3>
          </div>
          <Modal.Body
            className='modal-body px-3 py-0'
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {section <= 4 && (
              <>
                <div
                  className='align-items-center d-flex justify-content-center'
                  style={{
                    border: '30px',
                    borderStyle: 'solid',
                    borderColor: '#731780',
                    textAlign: 'center',
                    height: '400px',
                    overflow: 'hidden',
                    padding: '20px',
                  }}
                >
                  {section === 1 && <img src={tour1} width={'w-auto'} />}
                  {section === 2 && <img src={tour2} width={'w-auto'} />}
                  {section === 3 && <img src={tour3} width={'w-auto'} />}
                  {section === 4 && <img src={tour4} width={'w-auto'} />}
                </div>
                <p className='my-3'>
                  {section === 1 && glossary.ssp_tour_description_one}
                  {section === 2 && glossary.ssp_tour_description_two}
                  {section === 3 && glossary.ssp_tour_description_three}
                  {section === 4 && glossary.ssp_tour_description_four}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className='justify-content-between px-3'>
            {section <= 4 && (
              <>
                <div className={'d-flex gap-2'}>
                  <div
                    onClick={() => sSection(1)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 1 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                  <div
                    onClick={() => sSection(2)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 2 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                  <div
                    onClick={() => sSection(3)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 3 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                  <div
                    onClick={() => sSection(4)}
                    style={{
                      cursor: 'pointer',
                      height: 12,
                      width: 12,
                      background: section === 4 ? '#731780' : '#C4C4C4',
                      borderRadius: 50,
                      padding: 0,
                    }}
                  />
                </div>
                <div className='d-flex gap-3'>
                  <button
                    className={`button ${section === 1 ? 'button-disabled' : 'button-secondary'}`}
                    type='button'
                    onClick={(e: any) => {
                      if (section !== 1) {
                        sSection(section - 1);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    Previous
                  </button>

                  <Button
                    kind={'primary'}
                    text={section === 4 ? glossary.done_btn : glossary.next_btn}
                    click={() => {
                      if (section <= 3) {
                        sSection((prev: any) => prev + 1);
                      }
                      if (section === 4) {
                        updateTour();
                      }
                      sTourDone(true);
                    }}
                  />
                </div>
              </>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
