import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useHistory, useLocation, NavLink } from 'react-router-dom';
// import ProgressBar from 'react-bootstrap/ProgressBar';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
// import { Form } from 'react-bootstrap';
import { BsArrowLeftShort, BsWallet2 } from 'react-icons/bs';
import JWT from 'jsonwebtoken';
// import LogRocket from 'logrocket';
// import { useIntercom } from 'react-use-intercom';

import Tour from '../modals/tour';
import MsaSign from '../modals/msa-sign';
import store, { update } from '../../states';
import { api } from '../../services/constants';
// import logo from '../../assets/images/logo.svg';
import PublisherTour from '../components/tour/Publisher';
import { UserInfoContext } from '../contexts/userInfoContext';
// import TextSkeleton from '../loaders/text';
import ButtonSkeleton from '../loaders/button';
import consumerData from '../contexts/OverViewContext';

const PublisherHeader = ({ endpointStatus }: any) => {
  const { push } = useHistory();
  const location = useLocation();

  // Context API
  const { msaDetails, balanceHeader, customerInfo, userInfo, clearAll }: any =
    useContext(UserInfoContext);

  const { clearAllStates }: any = consumerData();

  const [topBar, sTopBar] = useState<any>('block');
  const [topBarAccount, sTopBarAccount] = useState(true);
  const [topBarPayout, sTopBarPayout] = useState(true);
  const [topBarCreateTag, sTopBarCreateTag] = useState(true);
  const [topBarCreateEndpoint, sTopBarCreateEndpoint] = useState<any>(true);
  const [topBarMSA, sTopBarMSA] = useState(true);
  const [tour, sTour] = useState(false);
  const [tourCompleted, sTourCompleted] = useState(false);
  const [steps, sSteps] = useState(false);
  const [tourStepDone, sTourStepDone] = useState(false);
  const [topBarProgress, sTopBarProgress] = useState<number>(0);
  const [companyLogo, sCompanyLogo] = useState();
  const [companyName, sCompanyName] = useState();
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [tagsStatus, sTagsStatus] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [loader, sLoader] = useState(false);
  const [loaderCustomer, sLoaderCustomer] = useState(false);
  const [tourStage, setTourStage] = useState('');
  const [isBarVisible, setIsBarVisible] = useState(true); // Onboard bar hide/show

  const [balance, setBalance] = useState({
    availableBalance: '$0.00',
    platformCost: '$0.00',
  });

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* Update Available Balance when user withdraw */
  useEffect(() => {
    if (customerInfo?.thumb) sCompanyLogo(customerInfo?.thumb);
    if (customerInfo?.company_name) sCompanyName(customerInfo?.company_name);
  }, [balanceHeader?.available_formatted, customerInfo.thumb, customerInfo?.company_name]);

  const completeTour = (status: any) => {
    sTourCompleted(status);
    setTourStage('info');
    // // @ts-ignore
    // document.getElementsByClassName('react-joyride__beacon')[0].click();
  };

  useEffect(() => {
    if (tourStage === 'info') {
      setTimeout(() => {
        //@ts-ignore
        document.getElementsByClassName('react-joyride__beacon')[0]?.click();
      }, 200);
    }
  }, [tourStage]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      sTopBar('none');
    }
  }, [location.pathname]);

  useEffect(() => {
    fetch(api + '/frontend-glossary/en')
      .then((response) => response.json())
      .then((result) => {
        store.dispatch(update(result.data));
      });
  }, []);

  useEffect(() => {
    // User Info
    if (userInfo?.user?.id) {
      sLoader(true);

      setTourStage(userInfo?.user?.tour); //Step Tour

      setBalance({
        availableBalance: userInfo?.balance?.available_media_cost_formatted ?? '-',
        platformCost: userInfo?.balance?.platform_cost_formatted ?? '-',
      });

      localStorage.setItem('iTour', userInfo?.user?.tour); //Intercom
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: userInfo?.user?.email ?? '',
          firstName: userInfo?.user?.firstname ?? '',
          lastName: userInfo?.user?.lastname ?? '',
          photo: userInfo?.user?.thumb ?? '',
        })
      );

      if (
        userInfo?.user?.tour !== 'info' &&
        userInfo?.user?.tour !== 'done' &&
        !userInfo?.user?.infyadmin
      ) {
        sTour(true);
      }

      // Step Tour
      if (userInfo?.user?.tour !== 'done' && !userInfo?.user?.infyadmin) {
        sSteps(true);
      }
    }

    // Milestone Functionality call
    if (customerInfo?.id) {
      sLoaderCustomer(true); // Optional removed it after verifying

      if (customerInfo?.milestone) {
        const milestones = customerInfo?.milestone;
        sTopBarProgress(customerInfo?.milestone_percent);
        sTagsStatus(customerInfo?.milestone.tag);
        if (customerInfo?.milestone_percent === 100) sTopBar('none');
        if (customerInfo?.milestone_percent < 100) {
          sTopBar('block');
        }
        localStorage.setItem('tagStatus', customerInfo?.milestone.tag);
        const keys = Object.keys(milestones);
        keys.forEach((key) => {
          switch (key) {
            case 'account':
              sTopBarAccount(milestones[key]);
              break;
            case 'tag':
              sTopBarCreateTag(milestones[key]);
              break;
            case 'endpoint':
              sTopBarCreateEndpoint(milestones[key]);
              break;
            case 'msa_status':
              sTopBarMSA(milestones[key]);
              break;
            case 'payout':
              sTopBarPayout(milestones[key]);
              break;
            default:
              break;
          }
        });
      }
    }
  }, [userInfo, customerInfo, isBarVisible]);

  // On boarding bar is hide for next 24 hour
  useEffect(() => {
    const shouldHideBar = localStorage.getItem('onboard_bar');

    if (shouldHideBar) {
      const currentTime = new Date().getTime();
      if (currentTime < parseInt(shouldHideBar)) {
        setIsBarVisible(false);
      } else {
        setIsBarVisible(true);
        localStorage.removeItem('onboard_bar');
      }
    }
  }, []);

  /* hide on click top bar */
  const hideTopBar = () => {
    sTopBar(!topBar);
    const currentTime = new Date().getTime();
    const hideUntil: any = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    localStorage.setItem('onboard_bar', hideUntil);
    setIsBarVisible(false);
  };

  /* open modal on click MSA execute */
  const onClickMsaSignButton = (msaData: any) => {
    if (!msaData.generate_button) {
      sShowMsaSign(true);
    }
    if (msaData.generate_button && !msaData.status) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  const [clickOnCreate, setClickOnCreate] = useState(false);
  const [countdown, setCountdown] = useState(100);
  const refCreate = useRef(null);

  const handleClickCreate = () => {
    setClickOnCreate(!clickOnCreate);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!tour && tourStepDone && tagsStatus) {
        //@ts-ignore
        refCreate?.current?.click();
      }
      setClickOnCreate(true);
      setCountdown(countdown - 1);
    }, 2000); //miliseconds
    return () => {
      clearTimeout(timeout);
    };
  }, [tour, tourStepDone, tagsStatus]);

  const [MSAStatusCHeck, sMSAStatusCHeck] = useState(false);
  const refCreate1 = useRef<any>(null);

  const handleClickCheckMSA = () => {
    sMSAStatusCHeck(true);
  };

  useEffect(() => {
    refCreate1?.current?.click();
  }, [MSAStatusCHeck]);

  // tour step
  const getTourDoneProps = (data: any) => {
    sTourStepDone(data);
  };

  useEffect(() => {
    if (tourStepDone) sSteps(false);
  }, [tourStepDone]);

  useEffect(() => {
    //@ts-ignore
    if (endpointStatus && endpointStatus.length > 1) {
      const checkbox = document.getElementById('checkEndpoint') as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = true;
      }
    }
  }, [endpointStatus?.length]);

  const onImageError = (e: any) => {
    e.target.src = 'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png';
  };

  // Intercom & LogRocket Initialization
  // const { boot } = useIntercom();

  // useEffect(() => {
  //   if (localStorage.getItem('iTour') === 'done' || tourStage === 'done') {
  //     const token = localStorage.getItem('token') || '';
  //     const decoded = JWT.decode(token, { complete: true });
  //     const { user_id, company_id: customer_id, identity: email }: any = decoded?.payload || {};
  //     const userName = JSON.parse(localStorage.getItem('user') || '{}').firstName || '';
  // bootWithProps({ user_id, customer_id, email, userName });
  // bootLogRocket({ user_id, email, userName });
  // }
  // }, [tourStage, localStorage.getItem('iTour')]);

  // const bootWithProps = ({ user_id, customer_id, email, userName }: any) => {
  //   boot({
  //     name: userName,
  //     email: email,
  //     customAttributes: { user_id: user_id, customerID: customer_id },
  //   });
  // };

  // const bootLogRocket = ({ user_id, email, userName }: any) => {
  //   LogRocket.identify(user_id, {
  //     email: email,
  //     name: userName,
  //     subscriptionType:
  //       (customerInfo?.customer_type === 'publisher' ? 'Publisher' : 'SPO') ||
  //       (localStorage.getItem('customer_type') === 'publisher' ? 'Publisher' : 'SPO'),
  //   });
  // };

  return (
    <>
      {/* MSA Function*/}
      {!MSAStatusCHeck && (
        <MsaSign
          show={showMsaSign}
          onHide={() => {
            sShowMsaSign(false);
          }}
        />
      )}

      {/* Step Tour */}
      {location.pathname.startsWith('/publisher') && tourStage === 'info' && (
        <PublisherTour
          tagsStatus={tagsStatus}
          steps={steps}
          loader={loader}
          tourDoneProps={getTourDoneProps}
        />
      )}

      {/* Modal Tour Start */}
      {location.pathname.startsWith('/publisher') && tour && (
        <Tour
          show={tour}
          onHide={() => {
            sTour(false);
          }}
          tourCompleted={completeTour}
        />
      )}
      {/* Modal Tour End */}

      {/* Header & Milestone bar */}
      <div
        className={
          // topBar === 'block' && loaderCustomer && isBarVisible ? 'view-top-bar header' : 'header'
          'header'
        }
      >
        {/* start top bar step */}
        {/* {topBarProgress !== 100 && loaderCustomer && isBarVisible && (
          <>
            {(location.pathname.startsWith('/publisher') ||
              location.pathname.startsWith('/settings')) && (
              <>
                <div
                  className={
                    topBar
                      ? 'top__bar d-flex justify-content-between align-items-center d-block'
                      : 'top__bar d-flex justify-content-between align-items-center d-none'
                  }
                >
                  <p>{glossary.onboarding_title}</p>
                  <Form.Group className='d-flex form-group gap-3'>
                    <div className='top__bar__child'>
                      <Form.Check
                        checked={topBarAccount}
                        disabled={topBarAccount}
                        type='checkbox'
                        label={glossary.onboarding_step_create_account}
                        className='m-0'
                      />
                    </div>
                    <div
                      className='top__bar__child'
                      onClick={() => {
                        document.getElementById('first-btn')?.children[0]?.click();
                      }}
                    >
                      <Form.Check
                        checked={topBarCreateTag}
                        disabled={topBarCreateTag}
                        type='checkbox'
                        label={'Create tag'}
                        className='m-0'
                      />
                    </div>
                    <div className='top__bar__child'>
                      <Form.Check
                        checked={topBarCreateEndpoint}
                        disabled={topBarCreateEndpoint}
                        type='checkbox'
                        label={glossary.ssp_onboarding_step_two_text}
                        className='m-0'
                        id='checkEndpoint'
                      />
                    </div>
                    <div
                      className='top__bar__child'
                      ref={refCreate}
                      onClick={() => {
                        if (msaDetails) onClickMsaSignButton(msaDetails);
                        if (!MSAStatusCHeck) handleClickCreate();
                      }}
                    >
                      <Form.Check
                        ref={refCreate1}
                        checked={topBarMSA || MSAStatusCHeck}
                        disabled={topBarMSA || MSAStatusCHeck}
                        type='checkbox'
                        label={glossary.ssp_onboarding_step_msa}
                        className='m-0'
                        onClick={() => {
                          handleClickCheckMSA();
                        }}
                        style={{ pointerEvents: 'none', cursor: 'default' }}
                      />
                    </div>
                    <div
                      className='top__bar__child'
                      onClick={() => {
                        push('/settings/payouts', { section: 'payouts' });
                      }}
                    >
                      <Form.Check
                        checked={topBarPayout}
                        disabled={topBarPayout}
                        type='checkbox'
                        label={glossary.ssp_onboarding_step_four_text}
                        className='m-0'
                      />
                    </div>
                  </Form.Group>
                  <div className='align-items-center d-flex gap-2' style={{ width: 150 }}>
                    <ProgressBar
                      style={{ width: 120 }}
                      now={topBarProgress}
                      label={`${topBarProgress}%`}
                    />
                    <small
                      className='fw-lighter pt-1'
                      style={{ fontSize: 11 }}
                    >{`${topBarProgress}%`}</small>
                  </div>
                  <p className='top__bar__hide' onClick={() => hideTopBar()}>
                    {glossary.onboarding_hide_button}
                  </p>
                </div>
              </>
            )}
          </>
        )} */}
        {/* end top bar step */}

        <div
          className={
            tourCompleted && !tourStepDone
              ? 'header-menu d-flex justify-content-between align-items-center my-0 w-auto tour-overlay'
              : 'header-menu d-flex justify-content-between align-items-center my-0 w-auto'
          }
        >
          <div className={'col col-auto header__left'}>
            {location.pathname.startsWith('/settings') && (
              <Link
                className='d-inline-flex align-items-center gap-2 cursor-pointer'
                to={{ pathname: '/publisher/supply', state: 'clickLogo' }}
              >
                <BsArrowLeftShort className='flex-shrink-1' size='32' strokeWidth='0.3' />
                <h5 className='m-0 p-0' style={{ fontWeight: 200 }}>
                  Home
                </h5>
                {/* <img src={logo} alt={'infy-tv-logo'} height='50px' width='150px' /> */}
              </Link>
            )}
          </div>
          {/* {!tagsStatus && (
            <div className={'col header__middle'}>
              <TextSkeleton height={20} width={80} lineCount={1} />
              <TextSkeleton height={20} width={80} lineCount={1} />
              <TextSkeleton height={20} width={80} lineCount={1} />
            </div>
          )} */}

          {tagsStatus && (
            <div className={'col header__middle'}>
              <NavLink
                to={{ pathname: '/publisher/supply', state: 'clickLogo' }}
                activeClassName='active'
              >
                {glossary.ssp_header_tab_button_supply}
              </NavLink>
              <NavLink to={{ pathname: '/publisher/demand/sources' }} activeClassName='active'>
                {glossary.ssp_header_tab_button_demand}
              </NavLink>
              {/* <NavLink to='/publisher/deals/my-deals' activeClassName='active'>
                {glossary.ssp_header_tab_button_deals}
              </NavLink> */}
            </div>
          )}

          <div className={'col col-auto header__right d-none d-md-block'}>
            <div className={'d-flex align-items-center'}>
              <>
                {/* {!tagsStatus && (
                  <>
                    <div className='header__right__wrap '>
                      <div className='header__right__wrap__box'>
                        <div className={'tour-payouts'}>
                          <ButtonSkeleton width={110} height={38} />
                        </div>
                      </div>
                      <div className='header__right__wrap__box tour-balance'>
                        <span className='header__right__wrap__box__lable'>
                          <TextSkeleton height={15} width={113} lineCount={1} />
                        </span>
                        <span className='header__right__wrap__box__value'>
                          <TextSkeleton height={20} width={70} lineCount={1} />
                        </span>
                      </div>
                      <div className='header__right__wrap__box tour-platform-cost'>
                        <span className='header__right__wrap__box__lable'>
                          <TextSkeleton height={15} width={113} lineCount={1} />
                        </span>
                        <span className='header__right__wrap__box__value'>
                          <TextSkeleton height={20} width={70} lineCount={1} />
                        </span>
                      </div>
                    </div>
                  </>
                )} */}

                <div className='header__right__wrap '>
                  {/* <div className='header__right__wrap__box'>
                    <div className={'tour-payouts'}>
                      <button
                        className='button button-secondary'
                        onClick={() => {
                          push('/settings/payouts', { section: 'payouts' });
                          localStorage.setItem('section', 'payouts');
                          localStorage.setItem('selectedTabSetting', 'payouts');
                        }}
                      >
                        <BsWallet2 className='svg-icon' strokeWidth='0.3' />
                        {glossary.ssp_header_button_payouts}
                      </button>
                    </div>
                  </div> */}
                  <div className='header__right__wrap__box tour-balance'>
                    <span className='header__right__wrap__box__lable'>
                      {glossary.ssp_header_available_balance}
                    </span>
                    <span className='header__right__wrap__box__value'>
                      {balance?.availableBalance}
                    </span>
                  </div>
                  <div className='header__right__wrap__box tour-platform-cost'>
                    <span className='header__right__wrap__box__lable'>
                      {glossary.ssp_header_platform_cost}
                    </span>
                    <span className='header__right__wrap__box__value'>{balance?.platformCost}</span>
                  </div>
                </div>

                {/* Header Menu start*/}
                {!companyName ? (
                  <ButtonSkeleton width={150} height={50} />
                ) : (
                  <Dropdown className='tour-settings'>
                    <Dropdown.Toggle
                      className={
                        'button button-secondary d-flex justify-content-end align-items-center'
                      }
                      style={{ minWidth: '110px' }}
                    >
                      {/* Any Issue width: '110px' */}
                      <span
                        className='text-truncate text-start'
                        style={{ maxWidth: '110px', minWidth: '70px' }}
                        title={companyName}
                      >
                        {companyName === '' ? 'New User' : companyName}
                      </span>
                      <img
                        src={
                          companyLogo ??
                          'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png'
                        }
                        height={42}
                        width={42}
                        className='rounded-circle ms-2'
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          objectFit: 'contain',
                        }}
                        onError={onImageError}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.removeItem('selectedTabSetting');
                          push('/settings/profile');
                        }}
                      >
                        {glossary.account_ddl_option_settings}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.clear();
                          push('/login');
                          clearAll();
                          clearAllStates();
                        }}
                      >
                        {glossary.account_ddl_option_logout}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {/* Header Menu end*/}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublisherHeader;
