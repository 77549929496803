import { useEffect, useState } from 'react';
import Switch from '../../widgets/switch';

import { ReactComponent as TagIcon } from '../../../assets/icons/tag.svg';
import { ReactComponent as FilledDollarIcon } from '../../../assets/icons/dollar-filled.svg';
import { ReactComponent as DemandIcon } from '../../../assets/icons/noun-demand.svg';
import { getNotificationsAPI, updateUserAPI } from '../../../services/settings';
import store from '../../../states';

export default ({ toast /*store*/ }: any) => {
  // const [firstName, sFirstName] = useState(
  //   // @ts-ignore
  //   JSON.parse(localStorage.getItem('user'))?.firstName ?? ''
  // );
  // const [lastName, sLastName] = useState(
  //   // @ts-ignore
  //   JSON.parse(localStorage.getItem('user'))?.lastName ?? ''
  // );
  const [notifications, sNotifications] = useState([]);
  const [disabledNotifications, sDisabledNotifications] = useState<any>([]);
  const [userIdentity, setUserIdentity] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const getNotifications = () => {
    getNotificationsAPI()
      .then((data: any) => {
        if (data.status) {
          sNotifications(data.data);
          const filtered = data.data.filter((x: any) => !x.active);
          const disabled = filtered.map((y: any) => y.id);
          sDisabledNotifications(disabled);
        }
      })
      .catch(() => {
        //
      });
  };

  const setNotifications = (id: any, active: boolean) => {
    if (!disabledNotifications.includes(id) && active) {
      const newDisabledNotifications = [...disabledNotifications, id];
      sDisabledNotifications(newDisabledNotifications);
      const payload = {
        email_settings: newDisabledNotifications,
      };
      updateUserAPI(payload)
        .then((data) => {
          // getNotifications();
        })
        .catch(() => {
          //
        });
    } else {
      const newDisabledNotifications = [...disabledNotifications].filter((x) => x !== id);
      sDisabledNotifications(newDisabledNotifications);
      const payload = {
        email_settings: newDisabledNotifications,
      };
      updateUserAPI(payload)
        .then((data) => {
          // getNotifications();
        })
        .catch(() => {
          //
        });
    }
  };

  // useEffect(() => {
  //   const payload = {
  //     // @ts-ignore
  //     //firstname: firstName ?? '',
  //     // @ts-ignore
  //     //lastname: lastName ?? '',
  //     //username: '',
  //     email_settings: disabledNotifications,
  //   };
  //   updateUserAPI(payload)
  //     .then((data) => {
  //       // getNotifications();
  //     })
  //     .catch(() => {
  //       //
  //     });
  // }, [disabledNotifications])

  useEffect(() => {
    getNotifications();
  }, []);

  /* Get customer id from JWT token */
  useEffect(() => {
    if (
      localStorage.getItem('customer_type') === 'demand' ||
      localStorage.getItem('customer_type') === 'spo'
    ) {
      setUserIdentity(true);
    }
  }, []);
  /* Get customer id from JWT token */

  return (
    <>
      {!userIdentity && (
        <>
          <div className={'row notification__title'}>
            <TagIcon className='svg-icon' height={32} width={32} />
            {/* <h2>{userIdentity ? 'Endpoint Notifications' : 'Tag Notifications'}</h2> */}
            <h2>
              {localStorage.getItem('customer_type') === 'creative'
                ? 'Creatives Notifications'
                : glossary.setting_email_notification_label_supply}
            </h2>
          </div>
          {notifications
            .filter(
              (x: any) =>
                x.category_id === (localStorage.getItem('customer_type') === 'creative' ? 7 : 2)
            )
            .map((_: any, index) => {
              return (
                <div key={index} className={'row notification__list'}>
                  <div className={'col'}>
                    <p>{_.title}</p>
                    <p className='notification__list__desc'>{_.description}</p>
                  </div>
                  <div className={'col col-auto'}>
                    <Switch api={() => setNotifications(_.id, _.active)} status={_.active} />
                  </div>
                </div>
              );
            })}
        </>
      )}

      {localStorage.getItem('customer_type') !== 'creative' && (
        <>
          <div
            className={!userIdentity ? 'row notification__title mt-4' : 'row notification__title'}
          >
            <DemandIcon className='svg-icon' height={40} width={40} />
            <h2 style={{ paddingLeft: '0px' }}>
              {glossary.setting_email_notification_label_demand}
            </h2>
          </div>
          {notifications
            .filter((x: any) => x.category_id === 4)
            .map((_: any, index) => {
              return (
                <div key={index} className={'row notification__list'}>
                  <div className='col'>
                    <p>{_.title}</p>
                    <p className='notification__list__desc'>{_.description}</p>
                  </div>
                  <div className='col col-auto'>
                    <Switch api={() => setNotifications(_.id, _.active)} status={_.active} />
                  </div>
                </div>
              );
            })}
          <div className={'row notification__title mt-4'}>
            <FilledDollarIcon className='svg-icon' height={32} width={32} />
            <h2>{glossary.setting_email_notification_label_finance}</h2>
          </div>
          {notifications
            .filter((x: any) => x.category_id === 3)
            .map((_: any, index) => {
              return (
                <div key={index} className={'row notification__list'}>
                  <div className={'col'}>
                    <p>{_.title}</p>
                    <p className='notification__list__desc'>{_.description}</p>
                  </div>
                  <div className={'col col-auto'}>
                    <Switch api={() => setNotifications(_.id, _.active)} status={_.active} />
                  </div>
                </div>
              );
            })}
          <br />
          <br />
        </>
      )}
    </>
  );
};
