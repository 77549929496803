import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import { useLocation, useHistory } from 'react-router';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Tile from '../../widgets/tile';
import Line from '../../charts/line';
import Reports from '../../helpers/reports';
import { tag as tagTiles } from '../../helpers/tiles';
import add from '../../../assets/icons/add.svg';
import MsaSign from '../../modals/msa-sign';
import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';
import { UserInfoContext } from '../../contexts/userInfoContext';
import SPOSidebar from '../../sidebar/SPO';
import InvitePublisherModal from '../../modals/partner-invite';
import { getPartnerReportAPI, getTagListSSPAPI } from '../../../services/spo';
import PartnerTagsListTable from '../../tables/partnerTagsListTable';
import TableSkeleton from '../../loaders/table';
import PartnerOverview from '../../components/overview/partner';
import store from '../../../states';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import InvitedComponent from '../../components/spo/invited/invited';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

export const headers: any = {
  Accept: 'application/xml',
};

interface Props {
  sExpandStatusProp: any;
  sSearchToggleProp: any;
}

const PartnersPage = (props: Props) => {
  /* Context API's */
  const { msaDetails }: any = useContext(UserInfoContext);
  const { getPartnersListAPI }: any = useAuthentication();
  const { publisherData, sPublisherData, dateRangePartners, sDateRangePartners }: any =
    consumerData();

  const history = useHistory();
  const { pathname } = useLocation();

  const [selectedTile, sSelectedTile] = useState(0);
  const [selectedCustomer, sSelectedCustomer] = useState('');
  //const [customer, sCustomer] = useState(false);
  const [singleTileData, sSingleTileData] = useState<any>([]);
  const [lineData, sLineData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  //const [empty, sEmpty] = useState(false);
  const [customerDetails, sCustomerDetails] = useState<any>(null);
  const [partnerDetails, sPartnerDetails] = useState<any>(null);
  const [pDatatables, sPDatatables] = useState([]);
  const [random, sRandom] = useState(Math.random());
  const [tableSkeleton, setTableSkeleton] = useState(true);
  //const [qps, setQps] = useState('');
  const [updatedData, setUpdatedData] = useState<any>([]);
  //const [vastXML, setVastXML] = useState<any>();
  const [showMsaSign, sShowMsaSign] = useState(false);
  //const [sidebarSkeleton, sSidebarSkeleton] = useState(false);
  const [btnSkeleton, sBtnSkeleton] = useState(true);
  // const [sidebarTabs, sSidebarTabs] = useState('1');
  const [customerLoader, sCustomerLoader] = useState(true);
  //const [isQpsUpdating, setIsQpsUpdating] = useState(false);
  //const [allData, setAllData] = useState(false);

  const [isEmpty, sIsEmpty] = useState(false);
  const [publisherList, sPublisherList] = useState(false);
  //const [publisherData, sPublisherData] = useState<any>([]);
  const [selectedPublisher, sSelectedPublisher] = useState('');
  const [partnerTagsData, setPartnerTagsData] = useState([]);
  const [publisherSidebarSkeleton, setPublisherSidebarSkeleton] = useState(false);

  /* Selected while you reload page */
  const [selectedTab, sSelectedTab] = useState('performance');
  //const [selectedTabSources, setSelectedTabSources] = useState('performance1');
  const [pubHasMore, sPubHasMore] = useState(true);
  const [pubCurrentPage, sPubCurrentPage] = useState(0);
  const [pubParams, setPubParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'impression',
      desc: true,
    },
  });

  const [paramsStatusPublisher, setParamsStatusPublisher] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // const [selectionRange, setSelectionRange] = useState<any>();

  // useEffect(() => {
  //   setSelectionRange(dateRangePartners);
  // }, [dateRangePartners]);

  const GetSelectionRangeProps = (data: any) => {
    // setSelectionRange(data);
    sDateRangePartners(data);
  };

  const handlePartnerTabs = (key: any) => {
    sSelectedTab(key);
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  /* overview data above chart */
  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  useEffect(() => {
    if (updatedData.length > 0) {
      //sPublisherData(updatedData ?? []);
      sPDatatables(updatedData.tables);
      //sCustomer(false);
      const getPartners: any = updatedData.find((x: any) => x.id === selectedPublisher);
      sPartnerDetails(getPartners);
    }
  }, [updatedData]);

  useEffect(() => {
    if (selectedPublisher && dateRangePartners?.endDate && dateRangePartners?.startDate) {
      getPartnerDetails(selectedPublisher);
    }
  }, [selectedPublisher, dateRangePartners]);

  /* Chart sata start */
  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      //sRefinedLineData({ labels: newlabels, values: newValues });
      sRefinedLineData({ labels: newValues, values: newlabels });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  /* Get costomer data */
  const getPartnerDetails = (id: string) => {
    const payload = {
      id,
      start: convert(dateRangePartners?.startDate) || '',
      end: convert(dateRangePartners?.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    //setAllData(false);
    if (dateRangePartners?.startDate !== dateRangePartners?.endDate) {
      sCustomerLoader(true);
      getPartnerReportAPI(payload)
        .then((data: any) => {
          if (data) {
            const tileData = tagTiles(data.total);
            sSingleTileData(tileData);
            sSelectedTile(0);
            sRandom(Math.random());
            sPDatatables(publisherData.find((x: any) => x.id === id)?.tables ?? []);
            sCustomerLoader(false);
            //setAllData(true);
          }
        })
        .catch(() => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
      // }
    }
  };

  /* select Partner card */
  const selectedPublisherCard = (id: string) => {
    if (selectedPublisher !== id) {
      history.push(`/publisher/demand/partners/${id}`);
      sPDatatables([]);
      sSingleTileData([]);
      sRefinedLineData([]);
      sLineData([]);
      sSelectedPublisher(id);
      //getPartnerDetails(id);
      //getPartnerTagsList(id);
      sSelectedTab('performance');
    }
  };

  useEffect(() => {
    if (selectedPublisher) getPartnerTagsList(selectedPublisher);
  }, [selectedPublisher]);

  // Get Source Tags
  const getPartnerTagsList = (id: any) => {
    const payload = {
      partner_id: id,
    };
    getTagListSSPAPI(payload)
      .then((data: any) => {
        if (data) {
          setTableSkeleton(false);
          setPartnerTagsData(data);
        }
      })
      .catch(() => {
        //ok
      });
  };

  useEffect(() => {
    const id = pathname.split('/publisher/demand/partners/')[1];
    if (publisherData.length === 0 || pubParams.query === '') {
      gePublisherListInfo(pubParams, id);
    }
    if (paramsStatusPublisher === true) {
      gePublisherListInfo(pubParams, id);
    }
  }, [pubParams.page, pubParams.query, pubParams.sortBy, paramsStatusPublisher]);

  const handlePublisherChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== pubParams.page ||
      query !== pubParams.query ||
      sortBy.id !== pubParams.sortBy.id ||
      sortBy.desc !== pubParams.sortBy.desc
    ) {
      setPubParams({ page, query, sortBy });
      setParamsStatusPublisher(status);
    }
    if (pubParams.sortBy !== sortBy || pubParams.query !== query) {
      sPublisherData([]);
    }
  };

  const gePublisherListInfo = (_params: any, _id: any) => {
    const payload = {
      page: _params.page,
      page_size: 500,
      query: _params.query,
      sort_by: {
        sortBy: [_params.sortBy],
      },
    };
    setPublisherSidebarSkeleton(true);
    getPartnersListAPI(payload)
      .then((data: any) => {
        if (data) {
          if (_params.page > 0) {
            if (data.data.length !== 0) {
              sPublisherData([...publisherData, ...data.data]);
            } else {
              selectedPublisherCard(_params.page === 0 ? publisherData[0].id : selectedPublisher);
              sPublisherData(publisherData);
              sPubHasMore(false);
              sIsEmpty(data.data.length < 1);
            }
          } else {
            sPublisherData(data.data);
            sIsEmpty(data.data.length < 1);
            //selectedPublisherCard(data.data[0].id);
            //compare id when type id in param
            // if (_id) {
            // selectedCard(data.data.find((x: any) => x.id == _id)?.id);
            // } else {
            // selectedCard(data.data[0].id);
            // }
          }
          sPubCurrentPage(_params.page);
          setPublisherSidebarSkeleton(false);
          sBtnSkeleton(false);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  /* Handle Card Toggle & Traffic Table Toggle of Endpoint */
  const manageToggleUpdatePartner = (data: any, kind: string) => {
    if (kind === 'card') {
      const newArray = [...publisherData];
      const index = newArray.findIndex((item) => item.id === data.customer_obj.id);
      if (index !== -1) {
        // newArray[index] = { ...newArray[index], ...data.customer_obj };
        newArray[index] = {
          ...newArray[index],
          assigned_status: data.customer_obj.assigned_status,
        };
      }
      sPublisherData(newArray);
      setUpdatedData(newArray);
      setPartnerTagsData(data.data);
    }
    if (kind === 'list') {
      setPartnerTagsData(data.data);
      const newArray = [...publisherData];
      const index = newArray.findIndex((item) => item.id === data.customer_obj.id);
      if (index !== -1) {
        //newArray[index] = { ...newArray[index], ...data.customer_obj };
        newArray[index] = {
          ...newArray[index],
          status: data.customer_obj.status,
        };
      }
      sPartnerDetails(data.customer_obj);
      sPublisherData(newArray);
      setUpdatedData(newArray);
      // }
    }
  };

  const handleUpdatePublisher = (customerObj: any, kind: string, req?: string) => {
    if (kind === 'add') {
      const newArray = [...publisherData];
      const index = newArray.findIndex((item) => item.id === customerObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...customerObj };
      }
      sPublisherData(newArray);
    }

    if (kind === 'update') {
      const newArray = [...publisherData];
      const index = newArray.findIndex((item) => item.id === customerObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...customerObj };
      }
      sPublisherData(newArray);
      if (req === 'reject' && customerObj.assigned_status !== 'pending') {
        //sSection('1');
        sSelectedCustomer(customerObj.id);
        sCustomerDetails(customerObj);
      }
    }
  };

  /***** UPDATE ENDPOINT LIST BY ADD/UPDATE ENDPOINT START *****/
  const handlePublisherLists = (publisherObject: any, kind: string) => {
    const newPublisherObject = { ...publisherObject.success };
    if (kind === 'add') {
      //const newArr = [newpublisherObject, ...endPointData];
      const newArr = [...Object.values(newPublisherObject), ...publisherData];
      sPublisherData(newArr);
      selectedPublisherCard(newPublisherObject[0].id);
      sPublisherList(false);
    }
  };

  const invitePublisher = () => {
    ReactGA.event('add_new_button', {
      category: 'end_point_header_biding',
      action: 'click',
      label: '+_add_new_button_click',
    });
    if (msaDetails.status) {
      sPublisherList(true);
    }
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  //Update QPS & Get VAST_XML Hash Code
  useEffect(() => {
    if (selectedPublisher) {
      //sMaxQpsBtn(false);
      const getCustomer: any = publisherData.find((x: any) => x.id === selectedPublisher);
      sPartnerDetails(getCustomer);
    }
  }, [selectedPublisher, publisherData]);

  useEffect(() => {
    if (selectedPublisher !== '') {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  // useEffect(() => {
  //   if (selectedPublisher) getPartnerDetails(selectedPublisher);
  // }, [selectedPublisher]);

  useEffect(() => {
    if (refinedLineData.values < 1 && refinedLineData.values < 1) {
      sCustomerLoader(true);
    } else {
      sCustomerLoader(false);
    }
  }, [refinedLineData]);

  const [expandStatus, sExpandStatus] = useState<any>(true);
  const [searchToggle, sSearchToggle] = useState<any>(true);

  const getExpandSidebar = (expand: boolean) => {
    sExpandStatus(expand);
  };
  const getSearchToggle = (expand: boolean) => {
    sSearchToggle(expand);
  };

  props.sExpandStatusProp(expandStatus);
  props.sSearchToggleProp(searchToggle);

  return (
    <>
      {/* Invite Publisher modal*/}
      <InvitePublisherModal
        show={publisherList}
        getEndPointList={handlePublisherLists}
        onHide={() => {
          sPublisherList(false);
        }}
        type={'spo-publisher'}
      />

      <MsaSign
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />

      <SPOSidebar
        title={glossary.dsp_dash_sidebar_title_partners}
        kind={'PublisherSPO'}
        size={'collapsed'}
        action={{
          icon: add,
          text: glossary.dsp_dash_sidebar_button_invite,
          click: invitePublisher,
        }}
        overviewAction={{
          text: glossary.overview_button,
          click: () => {
            sSelectedPublisher('');
            history.push('/publisher/demand/partners');
          },
          isOverview: pathname === '/publisher/demand/partners' ? true : false,
        }}
        card={'expanded'}
        items={publisherData}
        info={selectedPublisherCard}
        getTags={(updated: any) => {
          handleUpdatePublisher(updated, '');
          selectedPublisherCard(selectedPublisher ?? 0);
        }}
        empty={isEmpty}
        getEndpointApi={manageToggleUpdatePartner} //Get Publisher Endpoint list data for card toggle
        selected={selectedPublisher}
        currentPage={pubCurrentPage}
        loading={publisherSidebarSkeleton}
        hasMore={pubHasMore}
        params={pubParams}
        handleParams={handlePublisherChange}
        expandSidebar={getExpandSidebar}
        searchToggle={getSearchToggle}
        allData={true}
      />

      <div className='view-section'>
        {selectedPublisher === '' && publisherData ? (
          <PartnerOverview empty={isEmpty} />
        ) : (
          <>
            {partnerDetails?.assigned_status === 'registered' ||
            partnerDetails?.assigned_status === 'reject' ||
            partnerDetails?.assigned_status === 'pending' ? (
              <>
                <InvitedComponent
                  selectedCusomer={selectedPublisher}
                  //partnerDetails={partnerDetails}
                  glossary={glossary}
                  partnerDetails={publisherData?.find((x: any) => x.id === selectedPublisher)}
                  getCustomers={handleUpdatePublisher}
                />
              </>
            ) : (
              <Tab.Container
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                onSelect={(key: any) => handlePartnerTabs(key)}
              >
                <div className='row align-items-center'>
                  <div className='col-4 mt-0'>
                    <div className='d-flex gap-3 align-items-center'>
                      {selectedPublisher !== '' && partnerDetails && (
                        <h2 className='text-truncate'>{partnerDetails.company_name}</h2>
                      )}
                    </div>
                  </div>

                  <div className='col-4 mt-0'>
                    <Nav variant='pills'>
                      <Nav.Item>
                        <Nav.Link eventKey='performance'>Performance</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='management'>Management</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <div className='col-4 mt-0'>
                    <div
                      className={
                        selectedTab === 'performance'
                          ? 'calendar__wrap tour-calendar-wrap'
                          : 'calendar__wrap tour-calendar-wrap d-none'
                      }
                      style={{ position: 'relative' }}
                    >
                      <div className={'calendar__wrap tour-calendar-wrap'}>
                        {selectedPublisher !== '' && partnerDetails && (
                          <DateRangeFilter
                            onChange={GetSelectionRangeProps}
                            selectionRange={dateRangePartners}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <Tab.Content>
                      <Tab.Pane eventKey='performance'>
                        {selectedPublisher !== '' && partnerDetails && (
                          <>
                            {customerLoader ? (
                              <>
                                <div
                                  className={'row'}
                                  style={{
                                    marginTop: '56px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div className='col-6'>
                                    <div className='d-flex gap-4'>
                                      {[1, 2, 3, 4].map((index) => {
                                        return <Box key={index} />;
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className='row mt-4'>
                                  <div className='col w-100 m-auto'>
                                    <XYChart />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={'row'}
                                  style={{
                                    marginTop: '56px',
                                  }}
                                >
                                  {singleTileData.map((tile: any, index: any) => {
                                    return (
                                      <div key={index} className={'col-auto'}>
                                        {refinedLineData.labels < 1 &&
                                        refinedLineData.values < 1 ? null : (
                                          <Tile
                                            id={tile.id}
                                            selected={selectedTile}
                                            click={selectTile}
                                            label={tile.label}
                                            value={tile.value}
                                            info={tile.info}
                                            arrow={tile.arrow}
                                          />
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div
                                  className={'row tour-linechart-2'}
                                  style={{
                                    height: '320px',
                                    marginTop: '24px',
                                  }}
                                >
                                  <Line
                                    label={'Impressions'}
                                    data={refinedLineData}
                                    attribute={attribute}
                                  />
                                </div>
                              </>
                            )}
                            <div className={'row mt-4 report-table'}>
                              <Reports
                                id={selectedPublisher}
                                start={dateRangePartners?.startDate}
                                end={dateRangePartners?.endDate}
                                tabs={pDatatables}
                                random={random}
                                entityType={'partner'}
                              />
                            </div>
                          </>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey='management'>
                        <div className={'row position-relative'}>
                          {tableSkeleton || customerLoader ? (
                            <div className='row' style={{ marginTop: 56 }}>
                              <TableSkeleton
                                columns={5}
                                rows={9}
                                rowSpacing={2}
                                tableHeight={400}
                              />
                            </div>
                          ) : (
                            <div style={{ marginTop: 25 }}>
                              <PartnerTagsListTable
                                rows={partnerTagsData ?? []}
                                getEndpointListInfoAPI={manageToggleUpdatePartner}
                                selectedCustomer={selectedPublisher}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PartnersPage;
