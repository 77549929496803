import React, { useEffect, useMemo, forwardRef, useRef, useState } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
  useMountedLayoutEffect,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from '../tables/scrollbarWidth';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { inventoryToggleIncludeAPI, inventoryToggleExcludeAPI } from '../../services/inventory';
import Switch from '../widgets/switch';
import { toast } from 'react-toastify';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import defaultGlobalFilter from '../helpers/DefaultGlobalFilter';
import { GoLinkExternal } from 'react-icons/go';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
  const defaultRef: any = useRef();
  const resolvedRef: any = ref || defaultRef;
  const [checked, setChecked] = useState(rest.checked);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChecked = (event: any) => {
    setChecked(!checked);
    rest.onChange(event);
  };

  return (
    <>
      <input
        type='checkbox'
        ref={resolvedRef}
        {...rest}
        onClick={handleChecked}
        checked={checked}
      />
    </>
  );
});

// const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <div className='search-wrap'>
//       {/* <label className='label mb-0 me-1'>Search: </label>{' '} */}
//       <input
//         value={value || ''}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         //placeholder={`${count} records...`}
//         placeholder={`Search Inventory Data`}
//         className='form-control'
//       />
//     </div>
//   );
// };

const Table = ({ columns: userColumns, data, onSelectedRowsChange, globalFilter }: any) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      //minWidth: 30, // minWidth is only used as a limit for resizing
      //width: 150, // width is used for both the flex-basis and flex-grow
      //maxWidth: 200, // maxWidth is only used as a limit for resizing
      // Filter: DefaultColumnFilter, // Let's set up our default Filter UI
      width: 150,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);
  const filterOptions = { filteredIds: [] };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    //selectedRowIds,
    totalColumnsWidth,
    state: { selectedRowIds },
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      getSubRows: (row) => row.subRows || [],
      initialState: {
        //@ts-ignore
        globalFilter,
      },
      globalFilter: (rows: any, columnIds: any, filterValue: any) =>
        defaultGlobalFilter(rows, columnIds, filterValue, filterOptions),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    useFlexLayout
    //useBlockLayout
  );

  const { expanded } = state;

  React.useEffect(() => {
    setGlobalFilter(globalFilter || undefined);
  }, [globalFilter]);

  /* pass props selected row data */
  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null;
      });

    const rowIds: any = [];
    //const childRowIds: any = [];

    selectedFlatRows.forEach((row: any) => {
      rowIds.push(
        row?.original?.subRows?.map(function (id: any) {
          return id?.a_id;
        })
      );
      return rowIds.push(row?.original?.a_id);
    });

    // selectedFlatRows.forEach((row: any) => {
    //   return childRowIds.push(row?.original?.a_id);
    // });

    onSelectedRowsChange(rowIds?.flat(Infinity));
    //onSelectedRowsChange(childRowIds?.flat(Infinity));
  }, [onSelectedRowsChange, selectedRowIds]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <div {...cell.getCellProps()} className='td' key={index} title={cell.value}>
                <div
                  className={
                    cell?.column?.Header == 'Name/Bundle' ||
                    cell?.column?.Header == 'Inventory ID' ||
                    cell?.column?.Header == 'Platform Type' ||
                    cell?.column?.Header == 'Source'
                      ? 'macros-truncate'
                      : ''
                  }
                >
                  {cell.render('Cell')}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds, selectedFlatRows]
  );

  return (
    <>
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <div className='table-wrap mt-0'>
        <div {...getTableProps()} className='table inventories mb-0'>
          <div className='table-header'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className='th'
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                        ) : (
                          <IoMdArrowDropup className='svg-icon' fontSize={20} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{ width: '100%', height: `calc(100vh - 320px)`, overflowX: 'hidden' }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </div>
    </>
  );
};

const handleChildInventories = (inventoriesArr: any) => {
  const aIdArray: any = [];
  inventoriesArr.map((inventory: any) => {
    aIdArray.push(inventory.a_id);
  });
  return aIdArray;
};

const Inventories = (inventoriesData: any) => {
  const inventoryToggleInclude = (row: any) => {
    const payload = {
      a_id: [row.original.a_id],
      a_type: 'inventory',
    };
    inventoryToggleIncludeAPI(payload)
      .then((data: any) => {
        toast.success('Update successfully');
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const inventoryToggleExclude = (row: any) => {
    const payload = {
      a_id: [row.original.a_id],
      a_type: 'inventory',
    };
    inventoryToggleExcludeAPI(payload)
      .then((data: any) => {
        toast.success('Update successfully');
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const [globalFilter, setGlobalFilter] = React.useState('');
  const columns = useMemo(
    () => [
      // {
      //   expander: true,
      //   // Build our expander column
      //   id: 'expander', // Make sure it has an ID
      //   Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
      //     <span {...getToggleAllRowsExpandedProps()}>
      //       {isAllRowsExpanded ? (
      //         <MdOutlineKeyboardArrowDown className='svg-icon' fontSize={18} />
      //       ) : (
      //         <MdOutlineKeyboardArrowRight className='svg-icon' fontSize={18} />
      //       )}
      //     </span>
      //   ),
      //   Cell: ({ row }: any) =>
      //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      //     // to build the toggle for expanding a row

      //     row.canExpand ? (
      //       <span
      //         {...row.getToggleRowExpandedProps({
      //           style: {
      //             // We can even use the row.depth property
      //             // and paddingLeft to indicate the depth
      //             // of the row
      //             paddingLeft: `${row.depth * 2}rem`,
      //           },
      //         })}
      //       >
      //         {/* {console.log("row", row)} */}
      //         {row.isExpanded ? (
      //           <MdOutlineKeyboardArrowDown className='svg-icon' fontSize={18} />
      //         ) : (
      //           <MdOutlineKeyboardArrowRight className='svg-icon' fontSize={18} />
      //         )}
      //       </span>
      //     ) : null,
      //   width: 40,
      // },
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className='table-checkbox'>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: any) => (
          <div className='table-checkbox'>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        width: 40,
      },
      {
        Header: `Exclude/ Include`,
        accessor: 'excluded',
        Cell: ({ row }: any) => (
          <Switch
            api={(chec: any) => {
              chec === true ? inventoryToggleInclude(row) : inventoryToggleExclude(row);
            }}
            status={!row.original.excluded}
          />
        ),
        width: 70,
      },
      {
        Header: 'Name/Bundle',
        accessor: 'name',
        Cell: ({ row }: any) => (
          <>
            <div className='d-flex align-items-center gap-2'>
              {row.depth === 0 && row?.original?.url && (
                <a href={`http://${row?.original?.url}`} target='_blank' rel='noreferrer'>
                  <GoLinkExternal className='svg-icon' fontSize={16} title='Redirect URL' />
                </a>
              )}
              <p className='macros-truncate'>{row?.original?.name}</p>
            </div>
          </>
        ),
        width: 140,
      },
      {
        Header: 'Inventory ID',
        accessor: 'inventory_id',
        width: 140,
      },
      {
        Header: 'Platform',
        accessor: 'platform',
        width: 80,
      },
      {
        Header: 'Unique',
        accessor: 'unique',
        width: 100,
      },
      {
        Header: 'Daily Impression',
        accessor: 'impressions_formatted',
        width: 100,
      },
      {
        Header: 'Observed CPM',
        accessor: 'avg_cpm_formatted',
        width: 110,
      },
      {
        Header: 'Source',
        accessor: 'publisher_id',
        width: 80,
      },
    ],
    []
  );

  const data = inventoriesData.rows;

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    inventoriesData && inventoriesData?.selectedRowData(selectedRows);
  }, [inventoriesData, selectedRows]);

  const onChange = (e: any) => setGlobalFilter(e.target.value);

  return (
    <>
      <div className='search-wrap'>
        <input
          value={globalFilter}
          onChange={onChange}
          placeholder={`Search Inventory Data`}
          className='form-control'
          maxLength={32}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        expandedRows={true}
        subRowsKey='subRows' // inventories - subRows
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        globalFilter={globalFilter}
      />
    </>
  );
};

export default Inventories;
