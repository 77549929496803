import { useState, useEffect } from 'react';

interface Props {
  id: string;
  size?: 'mini' | 'full';
  selected: string;
  label?: string;
  error?: string;
  description?: string;
  disabled?: boolean;
  click(id: string): void;
}

export default (props: Props) => {
  const { id, size, label, selected, description, click, disabled } = props;

  const [className, sClassName] = useState('radio');

  useEffect(() => {
    if (id === selected) {
      sClassName('radio radio-selected');
    } else if (disabled === true) {
      sClassName('radio-button-disabled');
    } else {
      sClassName('radio');
    }
  }, [selected, disabled]);

  return (
    <>
      {size === 'mini' && (
        <div
          className={className + ' radio-mini'}
          onClick={(e) => (disabled ? e.preventDefault() : click(id))}
        >
          <div className='radio-row'>
            <div className={'radio-col'}>
              <div className={`${id === selected ? 'radio-o radio-o-selected' : 'radio-o'}`}>
                {id === selected && <div className={'radio-o-o'} />}
              </div>
            </div>
            <div className={'radio-col'}>
              <p style={{ marginLeft: 8 }}>{label}</p>
            </div>
          </div>
        </div>
      )}
      {size === 'full' && (
        <div className={className} onClick={() => click(id)}>
          <div className={'radio-row'}>
            <div className={'radio-col'}>
              <div className={`${id === selected ? 'radio-o radio-o-selected' : 'radio-o'}`}>
                {id === selected && <div className={'radio-o-o'} />}
              </div>
            </div>
            <div className={'radio-col'}>
              <p style={{ marginLeft: 8 }}>{label}</p>
            </div>
          </div>
          <div className={'radio-row'} style={{ marginTop: 8 }}>
            <label>
              {description ??
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus maximus quam.`}
            </label>
          </div>
        </div>
      )}
    </>
  );
};
