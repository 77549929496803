import React from 'react';
import ContentLoader from 'react-content-loader';

const XYChart = (props: any) => {
  return (
    <ContentLoader viewBox='0 0 400 110'>
      {/* X Axis */}
      <rect x='0' y='100' rx='3' ry='3' width='100%' height='1' />
      {/* Y Axis */}
      <rect x='0' y='0' rx='3' ry='3' width='1' height='100' />
      {/* Bars */}
      <rect x='0' y='10' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='20' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='30' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='40' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='50' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='60' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='70' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='80' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='90' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='100' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='110' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='120' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='130' rx='3' ry='3' width='100%' height='1' />
      <rect x='0' y='140' rx='3' ry='3' width='100%' height='1' />
    </ContentLoader>
  );
};

export default XYChart;
