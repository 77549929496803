import Button from '../../widgets/button';
import { useEffect, useState } from 'react';
import { TbEdit, TbCopy } from 'react-icons/tb';
import { MdDeleteOutline } from 'react-icons/md';
import { getDemandAdTxtAPI } from '../../../services/endpoint';
import ButtonSkeleton from '../../loaders/box';
import store from '../../../states';
import ResellerAdstxt from '../../modals/adstxt-reseller';
import DirectAdstxt from '../../modals/adstxt-direct';
import DeleteAdstxt from '../../modals/adstxt-delete';

export default ({ toast /* store */ }: any) => {
  const [directObj, setDirectObj] = useState<any>();
  const [resellerObj, setResellerObj] = useState<any>();
  const [allData, setAllData] = useState<any>([]);
  const [directModal, setDirectModal] = useState(false);
  const [resellerModal, setResellerModal] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteID] = useState('');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const directData = `${directObj?.exchange_domain || ''},${directObj?.seller_account_id || ''},${
    directObj?.payment_type || ''
  } ${directObj?.tag_id && ` ,${directObj?.tag_id || ''}`} `;

  const resellerData = `${resellerObj?.exchange_domain || ''},${
    resellerObj?.seller_account_id || ''
  },${resellerObj?.payment_type || ''} ${resellerObj?.tag_id && `,${resellerObj?.tag_id || ''}`}  `;

  useEffect(() => {
    if (
      localStorage.getItem('customer_type') === 'demand' ||
      localStorage.getItem('customer_type') === 'spo'
    ) {
      api();
    }
  }, []);

  const api = () => {
    getDemandAdTxtAPI()
      .then((data: any) => {
        if (data) {
          setSkeleton(false);
          setAllData(data);
          setDirectObj(data?.data?.direct);
          setResellerObj(data?.data?.reseller);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const handleAddDirect = () => {
    setDirectModal(true);
  };

  const handleAddReseller = () => {
    setResellerModal(true);
  };

  const handleDirectEditButton = (props: any) => {
    setDirectModal(true);
    setDirectObj(props);
  };

  const handleResellerEditButton = (props: any) => {
    setResellerModal(true);
    setResellerObj(props);
  };

  const handleDelete = (id: any) => {
    setShowModal(true);
    setDeleteID(id);
  };

  return (
    <>
      <DirectAdstxt
        show={directModal}
        onHide={() => {
          setDirectModal(false);
        }}
        // paymentType={paymentType}
        api={api}
        direct={allData?.data?.direct}
      />
      <ResellerAdstxt
        show={resellerModal}
        onHide={() => {
          setResellerModal(false);
        }}
        // paymentType={paymentType}
        api={api}
        reseller={allData?.data?.reseller}
      />

      {/* Delete Ads.txt */}
      <DeleteAdstxt
        onHide={() => {
          setShowModal(false);
        }}
        show={showModal}
        id={deleteID}
        api={api}
      />
      <div className='align-items-start flex-column gap-3 justify-content-start row flex-lg-row'>
        <div className={'col'}>
          <h2 style={{ fontWeight: 200 }}>{glossary.setting_dsp_adstxt_title}</h2>
        </div>

        {/* Direct Ads.Txt */}
        <div className='d-flex align-items-center mt-5'>
          <h3 className='me-3'>{glossary.setting_dsp_adstxt_field_label_direct}</h3>

          {!directObj ? (
            skeleton ? (
              <>
                <div
                  style={{
                    height: '40px',
                    overflow: 'hidden',
                  }}
                >
                  <ButtonSkeleton />
                </div>
              </>
            ) : (
              <>
                <Button
                  kind={'primary'}
                  text={glossary.setting_dsp_adstxt_button_add_direct}
                  click={handleAddDirect}
                />
              </>
            )
          ) : (
            <>
              <div className='d-flex align-items-center gap-2'>
                <div style={{ wordBreak: 'break-all' }}>
                  <p className='me-3' style={{ fontSize: '16px' }}>
                    {directData}
                  </p>
                </div>
                <div className='d-flex gap-2'>
                  <TbCopy
                    title={glossary.setting_dsp_adstxt_copy_icon_title}
                    className='svg-icon'
                    style={{ fontSize: '22px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(directData?.toString());
                      toast.success(glossary.setting_dsp_adstxt_copy_success_msg);
                    }}
                  />
                  <TbEdit
                    title={glossary.setting_dsp_adstxt_edit_icon_title}
                    className='svg-icon'
                    style={{ fontSize: '22px' }}
                    onClick={() => handleDirectEditButton(allData?.data?.direct)}
                  />
                  <MdDeleteOutline
                    title={glossary.setting_dsp_adstxt_delete_icon_title}
                    className='svg-icon'
                    style={{ fontSize: '22px' }}
                    onClick={() => handleDelete(allData?.data?.direct?.id)}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Reseller Ads.Txt */}
        <div className='d-flex mt-4 align-items-center'>
          <h3 className='me-3'>{glossary.setting_dsp_adstxt_field_label_reseller}</h3>
          {!resellerObj ? (
            skeleton ? (
              <>
                <div
                  style={{
                    height: '40px',
                    overflow: 'hidden',
                  }}
                >
                  <ButtonSkeleton />
                </div>
              </>
            ) : (
              <Button
                kind={'primary'}
                text={glossary.setting_dsp_adstxt_button_add_reseller}
                click={handleAddReseller}
              />
            )
          ) : (
            <div className='d-flex align-items-center gap-2'>
              <div style={{ wordBreak: 'break-all' }}>
                <p className='me-3' style={{ fontSize: '16px' }}>
                  {resellerData}
                </p>
              </div>
              <div className='d-flex gap-2'>
                <TbCopy
                  title={glossary.setting_dsp_adstxt_copy_icon_title}
                  className='svg-icon'
                  style={{ fontSize: '22px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(resellerData?.toString());
                    toast.success(glossary.setting_dsp_adstxt_copy_success_msg);
                  }}
                />
                <TbEdit
                  title={glossary.setting_dsp_adstxt_edit_icon_title}
                  className='svg-icon'
                  style={{ fontSize: '22px' }}
                  onClick={() => handleResellerEditButton(allData?.data?.reseller)}
                />
                <MdDeleteOutline
                  title={glossary.setting_dsp_adstxt_delete_icon_title}
                  className='svg-icon'
                  style={{ fontSize: '22px' }}
                  onClick={() => handleDelete(allData?.data?.reseller?.id)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
