import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import { useEffect, useState } from 'react';
import AddMacros from '../modals/macros-add';
import DeleteMacros from '../modals/macros-delete';
import JWT from 'jsonwebtoken';
import { updateMacrosAPI } from '../../services/macros';
import { toast } from 'react-toastify';

const MacrosTableManage = (props: any) => {
  const { accessor, row, apiCallBack } = props;

  const [deleteOpenModal, sDeleteOpenModal] = useState(false); // open and close modal
  const [editOpenModal, sEditOpenModal] = useState(false); // open and close modal

  const [companyID, setCompanyID] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    setCompanyID(decoded?.payload.company_id);
  }, []);

  // update macros api call
  const updateMacros = (id: any, macros: any, name: any, parameter: any, check: any) => {
    const payload = {
      platform_macro_id: id,
      macros: macros,
      name: name,
      parameters: parameter,
      object: 'Custom',
      default: check,
    };
    updateMacrosAPI(payload)
      .then((data: any) => {
        if (data.status) apiCallBack();
        toast.success(data.message);
      })
      .catch(() => {
        //ok
      });
  };

  return (
    <>
      <AddMacros
        show={editOpenModal}
        onHide={() => sEditOpenModal(false)}
        rowData={row}
        api={apiCallBack}
      />
      <DeleteMacros
        show={deleteOpenModal}
        onHide={() => sDeleteOpenModal(false)}
        data={row}
        api={apiCallBack}
      />
      {/* Toggle for all Included Columns */}
      {/* {accessor === 'action' && (
        <>
          <BiEditAlt
            key={row.id}
            className='svg-icon me-4'
            fontSize={19}
            onClick={() => {
              sEditOpenModal(true);
            }}
            title={'Edit'}
          />
          <MdDeleteOutline
            key={row.id}
            className='svg-icon'
            fontSize={19}
            onClick={() => {
              sDeleteOpenModal(true);
            }}
            title={'Delete'}
          />
        </>
      )} */}

      {accessor === 'action' && (
        <>
          {row?.customer_id === companyID && (
            <BiEditAlt
              key={row.id}
              className='svg-icon me-4'
              fontSize={19}
              onClick={() => {
                sEditOpenModal(true);
                //getMacrosData(row?.original);
              }}
              title={'Edit'}
            />
          )}

          {row?.customer_id === companyID ? (
            <>
              <MdDeleteOutline
                key={row.id}
                className='svg-icon'
                fontSize={19}
                onClick={() => {
                  sDeleteOpenModal(true);
                  //getMacrosData(row?.original);
                }}
                title={'Delete'}
              />
            </>
          ) : (
            '-'
          )}
        </>
      )}
      {accessor === 'default' && (
        <>
          <div
            key={row.id}
            className={
              row?.customer_id === companyID && row?.object === 'Custom'
                ? 'form-check'
                : 'form-check disable-field'
            }
          >
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              checked={row.default} // Set the 'checked' attribute based on row.default value
              onClick={() =>
                updateMacros(
                  row.id,
                  row.macros,
                  row.name,
                  row.parameter,
                  row.default ? false : true
                )
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default MacrosTableManage;
