import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Button from '../../widgets/button';
import { postPaymentMethodAPI } from '../../../services/stripe';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { getCustomerInfoAPI } from '../../../services/customers';
import store from '../../../states';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const useOptions = () => {
  const options: any = useMemo(
    () => ({
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'inherit',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};

const SplitForm = ({ modal, onHide }: any) => {
  // Context API
  const { setCustomerInfo }: any = useContext(UserInfoContext);

  const [isLoading, setIsLoading] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardDateError, setCardDateError] = useState('');
  const [cardCvvError, setCardCvvError] = useState('');

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const stripe: any = useStripe();
  const elements = useElements();
  const options = useOptions();

  const getCustomerInfo = () => {
    getCustomerInfoAPI()
      .then((res: any) => {
        setCustomerInfo(res.data);
      })
      .catch((error) => {
        //
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (payload?.error?.type !== 'validation_error') {
      toast.error(payload?.error?.message);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    if (payload?.paymentMethod?.id) {
      const newPayload = {
        payment_method_id: payload?.paymentMethod?.id,
      };
      postPaymentMethodAPI(newPayload)
        .then((data: any) => {
          if (data?.data?.url) getCustomerInfo();

          if (data.status === false) {
            toast.error(data?.message);
          }
          setIsLoading(false);
        })
        .catch((error: any) => {
          toast.error(glossary.service_unavailable);
        });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
        setIsLoading(true);
      }}
      className={'col-12'}
    >
      <div className='row'>
        <div className='col-12 mb-3'>
          <label className='label'>Card Number *</label>
          <CardNumberElement
            options={options}
            onChange={(e: any) => setCardNumberError(e?.error?.message ?? '')}
          />
          <p className='text-danger'>{cardNumberError}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          <label className='label'>Expiration Date *</label>
          <CardExpiryElement
            options={options}
            onChange={(e: any) => setCardDateError(e?.error?.message ?? '')}
          />
          <p className='text-danger'>{cardDateError}</p>
        </div>

        <div className='col-6'>
          <label className='label'>CVC *</label>
          <CardCvcElement
            options={options}
            onChange={(e: any) => setCardCvvError(e?.error?.message ?? '')}
          />
          <p className='text-danger'>{cardCvvError}</p>
        </div>
      </div>

      <div className={modal ? 'modal-footer mt-4' : 'mt-3'}>
        {!isLoading ? (
          <>
            {modal && (
              <Button
                type='button'
                text={'Cancel'}
                kind={'secondary'}
                customClass='mt-2 me-2'
                click={() => onHide()}
              />
            )}
            <button className='button stripe-btn mt-2' type='submit'>
              Submit
            </button>
          </>
        ) : (
          <button className='button stripe-btn' type='button'>
            <Spinner
              className='me-2'
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            {glossary.loading}
          </button>
        )}
      </div>
    </form>
  );
};

export default SplitForm;
