import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { createTagAPI, editTagAPI } from '../../../services/tags';
import { MdInfoOutline } from 'react-icons/md';

import Radio from '../../widgets/radio';
import Button from '../../widgets/button';

import store from '../../../states';
import Switch from '../../widgets/switch';
import MultiRangeSlider from 'multi-range-slider-react';
import FormB from 'react-bootstrap/Form';
import FormikController from '../../comman/form/FormikController';
import TagDetails from './VAST-details';
import { windowReload } from '../../helpers/functions';

const css = {
  col: {
    maxWidth: 'fit-content',
  },
};

const VastTag = ({
  kind,
  pSection,
  getTags,
  onHide,
  adsTxt,
  tagId,
  createTag,
  selectedTagDetails,
  scriptProp,
  initialCreate,
}: any) => {
  const initialValues = {
    name: createTag ? '' : selectedTagDetails?.name,
    floorPrice: createTag ? '' : selectedTagDetails?.floor_price,
  };

  const [id, sID] = useState('');
  const [section, sSection] = useState<any>(pSection);
  const [selectedAd, sSelectedAd] = useState(
    createTag ? 'preroll' : selectedTagDetails?.ad_placement
  );
  const [selectedTag, sSelectedTag] = useState('1');
  const [records, setRecords] = useState<any>([]);
  const [minValue, set_minValue] = useState<number>(
    createTag ? 15 : selectedTagDetails?.config?.min_duration
  );
  const [maxValue, set_maxValue] = useState<number>(
    createTag ? 300 : selectedTagDetails?.config?.max_duration
  );
  const [softFloor, sSoftFloor] = useState(createTag ? false : selectedTagDetails?.is_soft_floor);
  const [type, setType] = useState(
    createTag ? 'app' : selectedTagDetails?.config?.type?.toString()
  );
  const [slotCount, sSlotCount] = useState(
    createTag ? false : selectedTagDetails?.config?.is_slot_count
  );
  const [count, setCount] = useState(createTag ? 10 : selectedTagDetails?.config?.slot_count);
  const [spinnerBtn, sSpinnerBtn] = useState(false);

  useEffect(() => {
    setRecords(adsTxt);
  }, [adsTxt]);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(glossary.ssp_createtag_input_tagname_empty_error),
    floorPrice: Yup.number()
      .required(glossary.ssp_createtag_input_floorprice_empty_error)
      .test('superior', glossary.ssp_createtag_input_floorprice_zero_error, function (f1: any) {
        return f1 > 0;
      })
      .typeError(glossary.ssp_createtag_input_floorprice_valid_error)
      .max(99999, 'Floor Price allow 5 digits'),
  });

  const handleCreateTag = (e: any) => {
    const payload = {
      name: e.name,
      tag_type: 'normal', // selectedTag === '1' ? 'normal' : 'infy',
      ad_placement: selectedAd,
      minimum_cpm: e.floorPrice,
      duration: 0,
      config: {
        min_duration: minValue,
        max_duration: maxValue,
        is_soft_floor: softFloor === true ? true : false,
        type: type === 'app' ? 'app' : 'website',
        slot_count: slotCount === false ? 0 : count,
        is_slot_count: slotCount,
        pod_construction: slotCount === false ? 'pod_duration' : 'slot_count',
      },
    };
    sSpinnerBtn(true);
    createTagAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          sSection('2');
          if (data.script) {
            ReactGA.event('create_tag_response', {
              category: 'api_response',
              action: 'api_response',
              label: 'tag_api_response',
              tag_id: data.id,
            });
          }
          sID(data.data.id);
          //sScript(data.script);
          setRecords(data.ads_txt);
          getTags(data);
          sSpinnerBtn(false);
          toast.success(data.message);
        } else {
          sSpinnerBtn(false);
          toast.error(data.message);
        }
      })
      .catch(() => {
        //toast.error('Something went wrong, please try again later.');
      });
  };

  const handleUpdateTag = (e: any) => {
    const payload = {
      tag_id: tagId,
      name: e.name,
      tag_type: 'normal', // selectedTag === '1' ? 'normal' : 'infy',
      ad_placement: selectedAd,
      minimum_cpm: e.floorPrice,
      duration: 0,
      config: {
        min_duration: minValue,
        max_duration: maxValue,
        is_soft_floor: softFloor === true ? true : false,
        type: type === 'app' ? 'app' : 'website',
        slot_count: slotCount === false ? 0 : count,
        is_slot_count: slotCount,
        pod_construction: slotCount === false ? 'pod_duration' : 'slot_count',
      },
    };
    sSpinnerBtn(true);
    editTagAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          sSection('2');
          if (data.script) {
            ReactGA.event('create_tag_response', {
              category: 'api_response',
              action: 'api_response',
              label: 'tag_api_response',
              tag_id: data.id,
            });
          }
          sID(data.data.id);
          //sScript(data.script);
          setRecords(data.ads_txt);
          getTags(data.data);
          sSpinnerBtn(false);
          toast.success(data.message);
        } else {
          sSpinnerBtn(false);
          toast.error(data.message);
        }
      })
      .catch(() => {
        //toast.error('Something went wrong, please try again later.');
      });
  };

  const selectAd = (id: string) => sSelectedAd(id);
  const selectTag = (id: string) => sSelectedTag(id);

  useEffect(() => {
    sSection(pSection);
  }, [pSection]);

  const handleInputRange = (e: any) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  //get type id
  const selectType = (id: string) => {
    setType(id);
  };

  // increase swipe count
  function handlePlusClick() {
    if (count < 10) {
      setCount(count + 1);
    } else {
      toast.warning('Maximum value reached (10)');
    }
  }

  // decrease swipe count
  function handleMinusClick() {
    if (count > 1) {
      setCount(count - 1);
    }
  }
  return (
    <>
      {section === '1' && (
        <>
          {initialCreate && (
            <div className={'pt-4 px-3'}>
              <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                {glossary.ssp_createtag_title}
              </h3>
            </div>
          )}
          {kind === 'update' && (
            <div className='modal-header px-3'>
              <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                {glossary.ssp_updatetag_modal_title}
              </h3>
            </div>
          )}
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(e) => {
              if (kind === 'update') {
                handleUpdateTag(e);
              } else {
                handleCreateTag(e);
              }
            }}
          >
            {({ values }) => (
              <Form className='rtb-form'>
                <div
                  className='modal-body p-3'
                  style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto',
                  }}
                >
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <div className='form-group'>
                        <FormikController
                          kind='withouticon'
                          control='input'
                          type='text'
                          label={glossary.ssp_createtag_input_tagname_label}
                          placeholder={glossary.ssp_createtag_input_tagname_placeholder}
                          name='name'
                          length={32}
                        />
                      </div>
                    </div>
                    <div className='col-6'>
                      <label className='form-label'>
                        {glossary.ssp_createtag_field_label_ad_tagformat}
                      </label>
                      <div className='d-flex gap-3'>
                        <div className={'col-auto mt-0'} style={css.col}>
                          <Radio
                            size={'mini'}
                            label={glossary.ssp_createtag_radio_button_url}
                            id={'1'}
                            selected={selectedTag}
                            click={selectTag}
                          />
                        </div>
                        <div className={'col-auto mt-0'} style={css.col}>
                          <Radio
                            size={'mini'}
                            label={glossary.ssp_createtag_radio_button_xml}
                            id={'2'}
                            selected={selectedTag}
                            click={selectTag}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-4'>
                          <FormikController
                            kind='withiconstart'
                            control='input'
                            type='number'
                            label={glossary.ssp_createtag_input_floorprice_label}
                            placeholder={glossary.ssp_createtag_input_floorprice_placeholder}
                            name='floorPrice'
                          />
                        </div>
                        <div className='col-4'>
                          <div className='form-group'>
                            <label className='label'>
                              {glossary.ssp_createtag_softfloor_toggle_label}
                              {softFloor
                                ? ` ${glossary.ssp_createtag_softfloor_toggle_enabled_label}`
                                : ` ${glossary.ssp_createtag_softfloor_toggle_disabled_label}`}
                            </label>
                            <div className='mt-2'>
                              <Switch
                                api={(chec: any) => {
                                  chec === true ? sSoftFloor(true) : sSoftFloor(false);
                                }}
                                status={softFloor}
                                disabled={values?.floorPrice ? false : true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <label className='form-label'>
                        {glossary.ssp_createtag_field_label_ad_placement}
                      </label>
                      <div className='d-flex gap-3'>
                        <div className={'col-auto mt-0'}>
                          <Radio
                            size={'mini'}
                            label={glossary.ssp_createtag_radio_button_pre_roll}
                            id={'preroll'}
                            selected={selectedAd}
                            click={selectAd}
                          />
                        </div>
                        <div className={'col-auto mt-0'}>
                          <Radio
                            size={'mini'}
                            label={glossary.ssp_createtag_radio_button_mid_roll}
                            id={'midroll'}
                            selected={selectedAd}
                            click={selectAd}
                          />
                        </div>
                        <div className={'col-auto mt-0'}>
                          <Radio
                            size={'mini'}
                            label={glossary.ssp_createtag_radio_button_end_roll}
                            id={'endroll'}
                            selected={selectedAd}
                            click={selectAd}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-3'>
                      <div>
                        <label className='label'>
                          {glossary.ssp_createtag_pod_duration_rangebar_label}
                        </label>
                        <div className='multi-range-slider-1 px-0'>
                          <MultiRangeSlider
                            min={15}
                            max={300}
                            step={15}
                            label={false}
                            ruler={false}
                            stepOnly={true}
                            minValue={minValue}
                            maxValue={maxValue}
                            onChange={(e) => {
                              handleInputRange(e);
                            }}
                            style={{ border: 'none', boxShadow: 'none', padding: '15px 10px' }}
                            barLeftColor='#a2a2a2'
                            barInnerColor='#731780'
                            barRightColor='#a2a2a2'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='d-flex'>
                        <div className='form-group'>
                          <label className='label'>
                            {glossary.ssp_createtag_slot_count_rangebar_label}
                          </label>
                          <div className='mt-3'>
                            <Switch
                              api={(chec: any) => {
                                chec === true ? sSlotCount(true) : sSlotCount(false);
                              }}
                              status={slotCount}
                            />
                          </div>
                        </div>
                        {slotCount && (
                          <div className='d-flex' style={{ marginTop: 30 }}>
                            <Button
                              type='button'
                              kind={count <= 1 ? 'disabled-secondary' : 'primary'}
                              click={handleMinusClick}
                              text='-'
                              css={{
                                minWidth: 40,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            />
                            <div
                              className='d-flex align-items-center justify-content-center'
                              style={{
                                borderTop: '1px solid #cbcbcb',
                                borderBottom: '1px solid #cbcbcb',
                                width: 50,
                                height: 38,
                              }}
                            >
                              <p className='text-black'>{count}</p>
                            </div>
                            <Button
                              type='button'
                              kind={count >= 10 ? 'disabled-secondary' : 'primary'}
                              click={handlePlusClick}
                              text='+'
                              css={{
                                minWidth: 40,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='form-group'>
                        <label className='label'>
                          {glossary.ssp_createssai_type_radio_buttons_label}
                        </label>
                        <FormB.Group className='d-flex form-group button-radio'>
                          <Radio
                            label={glossary.ssp_createssai_type_radio_button_app}
                            size={'mini'}
                            id={'app'}
                            selected={type}
                            click={selectType}
                          />
                          <Radio
                            label={glossary.ssp_createssai_type_radio_button_website}
                            size={'mini'}
                            id={'website'}
                            selected={type}
                            click={selectType}
                          />
                        </FormB.Group>
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col'}>
                      {softFloor && (
                        <div className='d-flex align-items-center gap-1'>
                          <MdInfoOutline className='s-svg-icon' fontSize={18} />
                          <span className='d-flex gap-1 text-info-1'>
                            {glossary.ssp_createtag_instruction}
                            <a
                              href='https://help.infy.tv/en/'
                              target='_blank'
                              rel='noreferrer'
                              style={{ color: '#731780' }}
                            >
                              {glossary.ssp_createtag_instruction_hyperlink}
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={initialCreate ? 'px-3 pb-3' : 'modal-footer px-3'}>
                  <div className='d-flex w-100 justify-content-between'>
                    <div className='col-auto'>
                      <div className='d-flex gap-2 justify-content-around'>
                        <div
                          className={
                            section === '1' ? 'createtag-pagination active' : 'createtag-pagination'
                          }
                        ></div>
                        <div
                          className={
                            section === '2' ? 'createtag-pagination active' : 'createtag-pagination'
                          }
                        ></div>
                      </div>
                      <label className='mt-2'>{glossary.ssp_createssai_note_one}</label>
                    </div>
                    <div className='col-auto'>
                      <div className='d-flex gap-3'>
                        {!initialCreate && (
                          <Button kind='secondary' text='Close' type='button' click={onHide} />
                        )}
                        {spinnerBtn ? (
                          <Button
                            type='button'
                            kind={'spinner'}
                            customClass={'col-auto'}
                            text={'Loading...'}
                          />
                        ) : (
                          <Button
                            type='submit'
                            kind={'primary'}
                            text={
                              kind === 'update' ? 'Update' : glossary.ssp_createtag_create_button
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
      {section === '2' && (
        <>
          <div className={createTag ? 'd-none' : 'modal-header px-3'}>
            <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
              {glossary.ssp_createtag_success_title}
            </h3>
          </div>
          {/* {createTag &&
            (initialCreate ? (
              <div className={'p-3 px-3'}>
                <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                  {glossary.ssp_createtag_success_title}
                </h3>
              </div>
            ) : (
              <div className={createTag ? 'mt-4 px-3' : 'modal-header px-3'}>
                <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                  {glossary.ssp_createtag_success_title}
                </h3>
              </div>
            ))} */}
          <div
            className='modal-body p-3'
            style={{
              maxHeight: initialCreate ? '100%' : 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <TagDetails
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
              glossary={glossary}
              type={type}
              records={records}
              createID={id}
              selectedAdTagFormat={selectedTag}
              selectedAdPlacement={selectedAd}
            />
          </div>
          <div className={initialCreate ? 'px-3 p-3' : 'modal-footer px-3'}>
            <div
              className={
                createTag && section === '2'
                  ? 'd-flex w-100 justify-content-between'
                  : 'd-flex w-100 justify-content-end'
              }
            >
              <div className={createTag && section === '2' ? 'col-auto' : 'd-none'} style={css.col}>
                <div className='d-flex gap-2 justify-content-around'>
                  <div
                    className={
                      section === '1' ? 'createtag-pagination active' : 'createtag-pagination'
                    }
                  ></div>
                  <div
                    className={
                      section === '2' ? 'createtag-pagination active' : 'createtag-pagination'
                    }
                  ></div>
                </div>
                <label className='mt-2'>{glossary.ssp_createtag_success_note_one}</label>
              </div>
              <div className='col-auto gap-3 d-flex'>
                {!initialCreate && (
                  <Button
                    kind={'secondary'}
                    text={'Close'}
                    click={() => {
                      if (section === '2') {
                        onHide();
                      }
                    }}
                  />
                )}
                <Button
                  kind={'primary'}
                  text={glossary.ssp_createtag_success_button_done}
                  click={() => {
                    ReactGA.event('create_tag_section_2_done_button', {
                      category: 'tag',
                      action: 'click',
                      label: 'create_tag_done_button',
                    });
                    if (section === '2') {
                      initialCreate ? windowReload() : onHide();
                      //onHide(id ? id : null);
                      //   setTimeout(() => {
                      //     //pSSection('1');
                      //     //sMailSent(false);
                      //     //sTagForm(tagFormInitialValues);
                      //     //sErrors(tagFormInitialErrors);
                      //     // sSelectedTag('1');
                      //     // sSelectedAd('preroll');
                      //     // sSoftFloor(false);
                      //     // set_minValue(6);
                      //     // set_maxValue(300);
                      //     initialCreate ? window.location.reload() : onHide();
                      //   }, 500);
                      localStorage.setItem('create_vasttag_length', 'true');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VastTag;
