import { useContext, useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';

import { createMsaSignFormAPI, getMsaDetailsCAPI } from '../../services/msaSign';
import { UserInfoContext } from '../contexts/userInfoContext';

const MsaSign = (props: any) => {
  const { show, onHide, msaSignCheck } = props;
  const [loader, sLoader] = useState(true);
  const [redirectDocSignURL, sRedirectDocSignURL] = useState('');
  const [redirectStatus, sRedirectStatus] = useState(false);
  const [modalOpen, sModalOpen] = useState(false);
  const [reqBtnDisabled, setReqBtnDisabled] = useState(false);
  const [userEmail, sUserEmail] = useState('');
  const [userName, sUserName] = useState('');

  /* Context API's */
  const { setMsaDetails }: any = useContext(UserInfoContext);

  const MsaSignSchema = Yup.object().shape({
    msa: Yup.array().of(
      Yup.object().shape({
        signerName: Yup.string().required('Enter your name'),
        signerEmail: Yup.string().email('Invalid Email.').required('Enter your Email'),
        // legalTeamCC: Yup.array().of(
        //     Yup.object().shape({
        //         email: Yup.string().email('Invalid Email.').required("Enter your Email")
        //     })
        // )
      })
    ),
  });

  const initiateMsaDetailsCAPI = () => {
    getMsaDetailsCAPI()
      .then((data: any) => {
        if (data) {
          setMsaDetails((prev: any) => ({
            ...prev,
            generate_button: data.generate_button,
            message: data.message,
            status: data.status,
          }));
        }

        sRedirectStatus(data?.generate_button);
        if (data?.status === true) msaSignCheck(true);
        if (data.generate_button === true) sModalOpen(false);
        //@ts-ingore
      })
      .catch(() => {
        //toast.error('Something went wrong, please try again later.');
      });
  };

  const initiateMSAsign = (e: any, index: any) => {
    sLoader(true);
    setReqBtnDisabled(true);
    const payload = {
      customer_email: e?.msa[0]?.signerEmail,
      customer_name: e?.msa[0]?.signerName,
      legal_team_cc: e?.msa[0]?.legalTeamCC,
      notification_cc: e?.msa[0]?.legalNotificationCC,
      return_url: 'http://localhost:3000/sources',
    };
    createMsaSignFormAPI(payload)
      .then((data: any) => {
        sRedirectDocSignURL(data?.redirect_url);
        if (data.status) {
          onHide();
          initiateMsaDetailsCAPI();
          toast.success('MSA was sent to your email. Please check your mailbox.');
          setReqBtnDisabled(false);
        }
        if (data?.redirect_url) {
          sLoader(false);
        }
        // if (data) setReqBtnDisabled(false);
      })
      .catch(() => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  // useEffect(() => {
  //   if (localStorage.getItem('r') === 'customer') {
  //     initiateMsaDetailsCAPI();
  //   }
  //   if (!redirectStatus) {
  //     sModalOpen(true);
  //   } else {
  //     sModalOpen(false);
  //   }
  //   if (show === true && redirectStatus) {
  //     toast.success('MSA was sent to your email. Please check your mailbox.', {
  //       onClose: onHide(),
  //     });
  //   }
  // }, [show]);

  // useEffect(() => {
  //   if (reqBtnDisabled === true) {
  //     toast.success('MSA was sent to your email. Please check your mailbox.', {
  //       onClose: onHide(),
  //     });
  //   }
  // }, [reqBtnDisabled]);

  /* Get customer id from JWT token */
  const token: any = localStorage.getItem('user');

  useEffect(() => {
    const decoded = JSON.parse(token);
    sUserEmail(decoded?.email);
    sUserName(decoded?.firstName);
  }, [token, show, modalOpen]);

  /* Get customer id from JWT token */

  return (
    <Modal
      centered
      size={redirectDocSignURL ? 'xl' : 'lg'}
      show={show}
      onHide={() => {
        onHide();
        setReqBtnDisabled(false);
      }}
    >
      <Modal.Header className='px-3'>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>MSA Sign</h3>
      </Modal.Header>
      <Formik
        validationSchema={MsaSignSchema}
        initialValues={{
          msa: [
            {
              signerName: userName ?? '',
              signerEmail: userEmail ?? '',
              legalTeamCC: [
                {
                  name: '',
                  email: '',
                },
              ],
              legalNotificationCC: [
                {
                  name: '',
                  email: '',
                },
              ],
            },
          ],
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(e, i) => {
          initiateMSAsign(e, i);
        }}
      >
        {({ errors, touched, values }) => (
          <Form>
            <Modal.Body
              className={'p-3'}
              style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto',
              }}
            >
              <>
                {values.msa && values.msa.length > 0
                  ? values.msa.map((msa, index) => (
                      <div key={index}>
                        <div className='row mb-3'>
                          <div className='col-6'>
                            <label htmlFor={values.msa[index].signerName}>
                              Signing Authority Name *
                            </label>
                            <Field
                              type='text'
                              placeholder='Enter Signing Authority Name'
                              className='text-input'
                              name={`msa.${index}.signerName`}
                            />
                            {
                              //@ts-ignore
                              errors.msa &&
                                //@ts-ignore
                                errors?.msa[0]?.signerName &&
                                touched.msa &&
                                touched?.msa[0]?.signerName && (
                                  <div className='text-input-error-label small'>
                                    {
                                      //@ts-ignore
                                      errors.msa && errors?.msa[0]?.signerName
                                    }
                                  </div>
                                )
                            }
                          </div>
                          <div className='col-6'>
                            <label htmlFor={values.msa[index].signerEmail}>
                              Signing Authority Email *
                            </label>
                            <Field
                              type='text'
                              placeholder='Enter Signing Authority Email'
                              className='text-input'
                              name={`msa.${index}.signerEmail`}
                            />
                            {
                              //@ts-ignore
                              errors.msa &&
                                //@ts-ignore
                                errors?.msa[0]?.signerEmail &&
                                touched.msa &&
                                touched?.msa[0]?.signerEmail && (
                                  <div className='text-input-error-label small'>
                                    {
                                      //@ts-ignore
                                      errors.msa && errors?.msa[0]?.signerEmail
                                    }
                                  </div>
                                )
                            }
                          </div>
                        </div>
                        <FieldArray
                          //id={`msa.${index}.legalTeamCC`}
                          name={`msa.${index}.legalTeamCC`}
                          render={(arrayHelpers2) => {
                            return (
                              <div>
                                {values.msa[index].legalTeamCC &&
                                values.msa[index].legalTeamCC.length > 0 ? (
                                  <fieldset className='border p-3 rounded-2 mb-3'>
                                    <legend className='h5'>Add Legal Team for Review:</legend>
                                    {values.msa[index].legalTeamCC.map((q, index2) => {
                                      //console.log('q',index)
                                      return (
                                        <div className='row mb-3' key={index2}>
                                          <div className='col'>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <Field
                                                  id={`msa.${index}.legalTeamCC.${index2}.name`}
                                                  name={`msa.${index}.legalTeamCC.${index2}.name`}
                                                  placeholder='Enter Legal Person Name'
                                                  className='text-input'
                                                />
                                              </div>
                                              <div className='col-6'>
                                                <Field
                                                  id={`msa.${index}.legalTeamCC.${index2}.email`}
                                                  name={`msa.${index}.legalTeamCC.${index2}.email`}
                                                  placeholder='Enter Legal Person Email'
                                                  className='text-input'
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-auto'>
                                            <div className='d-flex gap-3'>
                                              {index2 > 0 && (
                                                <button
                                                  className='button'
                                                  type='button'
                                                  onClick={() => arrayHelpers2.remove(index2)}
                                                >
                                                  -
                                                </button>
                                              )}
                                              <button
                                                className='button button-primary'
                                                type='button'
                                                onClick={() => arrayHelpers2.push({})}
                                              >
                                                +
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </fieldset>
                                ) : (
                                  <button type='button' onClick={() => arrayHelpers2.push({})}>
                                    Add new hobby
                                  </button>
                                )}
                              </div>
                            );
                          }}
                        />
                        <FieldArray
                          //id={`msa.${index}.legalTeamCC`}
                          name={`msa.${index}.legalNotificationCC`}
                          render={(arrayHelpers2) => {
                            return (
                              <div>
                                {values.msa[index].legalNotificationCC &&
                                values.msa[index].legalNotificationCC.length > 0 ? (
                                  <fieldset className='border p-3 rounded-2 mb-3'>
                                    <legend className='h5'>
                                      Add Team Members to Notification:
                                    </legend>
                                    {values.msa[index].legalNotificationCC.map((q, index2) => {
                                      //console.log('q',index)
                                      return (
                                        <div className='row mb-3' key={index2}>
                                          <div className='col'>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <Field
                                                  id={`msa.${index}.legalNotificationCC.${index2}.name`}
                                                  name={`msa.${index}.legalNotificationCC.${index2}.name`}
                                                  placeholder='Enter Legal Person Name'
                                                  className='text-input'
                                                />
                                              </div>
                                              <div className='col-6'>
                                                <Field
                                                  id={`msa.${index}.legalNotificationCC.${index2}.email`}
                                                  name={`msa.${index}.legalNotificationCC.${index2}.email`}
                                                  placeholder='Enter Legal Person email'
                                                  className='text-input'
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-auto'>
                                            <div className='d-flex gap-3'>
                                              {index2 > 0 && (
                                                <button
                                                  className='button'
                                                  type='button'
                                                  onClick={() => arrayHelpers2.remove(index2)}
                                                >
                                                  -
                                                </button>
                                              )}
                                              <button
                                                className='button button-primary'
                                                type='button'
                                                onClick={() => arrayHelpers2.push({})}
                                              >
                                                +
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </fieldset>
                                ) : (
                                  <button type='button' onClick={() => arrayHelpers2.push({})}>
                                    Add new hobby
                                  </button>
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                    ))
                  : null}
              </>
            </Modal.Body>
            <Modal.Footer className='px-3 gap-2'>
              {redirectStatus === false ? (
                <>
                  <button
                    type='button'
                    className={'button button-large button-secondary float-end'}
                    onClick={() => {
                      onHide();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className={
                      reqBtnDisabled
                        ? 'button button-large button-disabled float-end'
                        : 'button button-large button-primary float-end'
                    }
                    disabled={reqBtnDisabled}
                  >
                    Request
                  </button>
                </>
              ) : (
                <button
                  type='button'
                  className={'button button-large button-secondary float-end'}
                  onClick={() => {
                    onHide();
                  }}
                >
                  Close
                </button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default MsaSign;
