import React, { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from './scrollbarWidth';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import store from '../../states';
import { CSVLink } from 'react-csv';
import Button from '../widgets/button';
import DeleteUser from '../modals/user-delete';
import SettingsUser from '../helpers/settingsUsers';
import ReportingTable from '../helpers/ReportingTableManage';
import MacrosTableManage from '../helpers/macrosTableManage';
import AddMacros from '../modals/macros-add';
import DeleteMacros from '../modals/macros-delete';
import CustomerDetailsManage from '../helpers/customersDetailsManage';
import AdminSettingsCustomerTable from '../helpers/adminSettingCusManage';
import TableSkeleton from '../loaders/table';
import PayoutRequestManage from '../helpers/payoutRequestManage';
import FinanceReceivableManage from '../helpers/financeReceivable';
import PlatformCostTable from '../helpers/PlatformCostManage';
import RequestedDealInventories from '../helpers/requestedDealInv';
import SspFinancePayoutManage from '../helpers/sspFinancePayoutManage';

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder,
}: any) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className='row align-items-sm-baseline'>
      <div className='search-wrap col mb-0'>
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          type={'search'}
          // placeholder={`${count} records...`}
          placeholder={placeholder}
          className='form-control'
          maxLength={64}
        />
      </div>
    </div>
  );
};

const Table = ({
  columns: userColumns,
  data,
  search,
  searchPlaceholder,
  download,
  kind,
  csvName,
  style,
}: any) => {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      //minWidth: 30, // minWidth is only used as a limit for resizing
      width: 200, // width is used for both the flex-basis and flex-grow
      //maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedRowIds,
    totalColumnsWidth,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      getSubRows: (row) => row.subRows || [],
      data,
      defaultColumn,
      // getSubRows: (row) => row.inventories || [],
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    //useBlockLayout
    useFlexLayout
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <>
                <div
                  {...cell.getCellProps()}
                  className={cell.column.class === 'sticky' ? 'td sticky-column' : 'td'}
                  key={index}
                  title={cell.value}
                >
                  <p
                    className={
                      cell?.column?.Header === 'Admin Email' || cell?.column?.Header === 'Action'
                        ? ''
                        : 'macros-truncate'
                    }
                  >
                    {cell.render('Cell')}
                  </p>
                </div>
              </>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );

  const [csvColumns, setCsvColumns] = useState<any>([]);

  useEffect(() => {
    const csv: any = [];
    if (userColumns.length > 0) {
      userColumns.forEach((item: any) => {
        csv.push({ label: item.Header, key: item.accessor });
      });
    }
    setCsvColumns(csv);
  }, [userColumns]);

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <div className={search ? 'd-wrap mb-3' : 'd-none'}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={searchPlaceholder}
          />
        </div>
        <div className={download ? 'd-wrap' : 'd-none'}>
          <CSVLink filename={`${csvName}.csv`} headers={csvColumns} data={data}>
            <Button kind='secondary' text='Download CSV' />
          </CSVLink>
        </div>
      </div>
      <div className='table-wrap mt-0'>
        <div {...getTableProps()} className='table tags'>
          <div className='table-header' style={{ width: ` calc(100vw - 460px)` }}>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className={
                      column.class === 'sticky' ? 'th text-break sticky-column' : 'th text-break'
                    }
                    key={index}
                    // style={{
                    //   width: 'fit-content',
                    // }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoIosArrowDown className='ms-1 svg-icon' />
                        ) : (
                          <IoIosArrowUp className='ms-1 svg-icon' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </div>
            ))}
          </div>
          <div
            className='table-body'
            {...getTableBodyProps()}
            style={{ width: 'calc(100vw - 460px)' }}
          >
            {style ? (
              <FixedSizeList
                height={600}
                itemCount={rows.length}
                itemSize={45}
                width={totalColumnsWidth + scrollBarSize}
                style={{
                  width: `calc(100vw - 460px)`,
                  height: rows.length > 5 ? `calc(100vh - 320px)` : 'auto',
                  overflowX: 'hidden',
                }}
              >
                {RenderRow}
              </FixedSizeList>
            ) : (
              <FixedSizeList
                height={600}
                itemCount={rows.length}
                itemSize={45}
                width={totalColumnsWidth + scrollBarSize}
                style={{
                  width: `calc(100vw - 460px)`,
                  height: `calc(100vh - 350px)`,
                  overflowX: 'inherit',
                  overflowY: 'inherit',
                }}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ReactTable = (props: any) => {
  const {
    tableColumns,
    tableRows,
    search,
    searchPlaceholder,
    download,
    csvName,
    kind,
    skeleton,
    //Setting Users Table Props
    role,
    userRole,
    getUsers,
    //Reporting Table Props
    tableType,
    customerId,
    selectedCardId,
    toggleInclude,
    toggleExclude,
    videoModal,
    video,
    // SSP Macros Table Props
    companyID,
    getMacrosList,
    //Customer Details Table
    selectedCustomer,
    getManagersList,
    //Admin Settings Customers Table
    getCustomers,
    //Payout Request Table Props
    getData,
    rejectRequest,
    pDownload,
    styletable,
    //Admin Receivable & Platform Cost Table
    controller,
    //Requested Deal ID
    dealID,
  } = props;

  //Dynamically columns rendering
  const columns = tableColumns?.map((item: any) => {
    if (kind === 'settings-users') {
      if (
        item.key === 'firstname' ||
        item.key === 'action' ||
        item.key === 'active' ||
        item.key === 'role_name'
      ) {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                {item?.key && kind === 'settings-users' && (
                  <SettingsUser
                    accessor={item?.key}
                    row={row}
                    kind={kind}
                    removeUser={removeUser}
                    accessControl={role}
                    userRole={userRole}
                    getUsersList={getUsers}
                  />
                )}
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: item.key === 'action' && kind === 'settings-users' ? 70 : 90,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width: item.key === 'email' ? 150 : item.key === 'activated_at' ? 120 : 90,
        };
      }
    } else if (kind === 'reporting') {
      if (
        item.key === 'included' ||
        item.key === 'advertiser_domain' ||
        item.key === 'creative_name'
      ) {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                {item?.key && kind === 'reporting' && (
                  <ReportingTable
                    accessor={item?.key}
                    row={row}
                    tableType={tableType}
                    customerId={customerId}
                    selectedCardId={selectedCardId}
                    toggleInclude={toggleInclude}
                    toggleExclude={toggleExclude}
                    videoModal={videoModal}
                    video={video}
                  />
                )}
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: item.key === 'included' ? 110 : 130,
          class: item.sticky === true ? 'sticky' : '',
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width: 130,
          class: item.sticky === true ? 'sticky' : '',
        };
      }
    } else if (kind === 'view-only') {
      return {
        Header: item.title,
        accessor: item.key,
        width:
          item.key === 'request_month'
            ? 50
            : item.key === 'request_year'
            ? 50
            : item.key === 'net_amount_formatted'
            ? 50
            : item.key === 'media_cost_formatted'
            ? 50
            : item.key === 'payment_term'
            ? 60
            : item.key === 'amount_formatted'
            ? 60
            : item.key === 'status_formatted'
            ? 80
            : item.key === 'date'
            ? 80
            : 100,
      };
    } else if (kind === 'ssp-settings-macros') {
      if (item.key === 'action') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <MacrosTableManage
                  accessor={item?.key}
                  row={row}
                  companyID={companyID}
                  setEditMacrosModal={setEditMacrosModal}
                  setDeleteMacrosModal={setDeleteMacrosModal}
                  getMacrosData={getMacrosData}
                />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
        };
      }
    } else if (kind === 'customer-details') {
      if (item.key === 'firstname' || item.key === 'assigned') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <CustomerDetailsManage
                  accessor={item?.key}
                  row={row}
                  selectedCustomer={selectedCustomer}
                  getManagersList={getManagersList}
                />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width: 130,
        };
      }
    } else if (kind === 'admin-settings-customer') {
      if (item.key === 'company_name' || item.key === 'action') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <AdminSettingsCustomerTable
                  accessor={item?.key}
                  row={row}
                  getCustomers={getCustomers}
                />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: item.key === 'action' ? 70 : 150,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width:
            item.key === 'email'
              ? 250
              : item.key === 'users'
              ? 70
              : item.key === 'status'
              ? 90
              : item.key === 'customer_type'
              ? 90
              : item.key === 'impression'
              ? 95
              : item.key === 'earning'
              ? 95
              : 150,
        };
      }
    } else if (kind === 'payout-request') {
      if (item.key === 'approve_reject' || item.key === 'payment_proof' || item.key === 'receipt') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <PayoutRequestManage
                  accessor={item?.key}
                  row={row}
                  api={getData}
                  reject={rejectRequest}
                  pDownload={pDownload}
                />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width:
            item.key === 'payment_proof'
              ? 50
              : item.key === 'receipt'
              ? 50
              : item.key === 'approve_reject'
              ? 70
              : 100,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width:
            item.key === 'id'
              ? 50
              : item.key === 'amount_formatted'
              ? 50
              : item.key === 'status_formatted'
              ? 50
              : item.key === 'created_on_formatted'
              ? 50
              : 100,
        };
      }
    } else if (kind === 'admin-finance-receivables') {
      if (item.key === 'action') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <FinanceReceivableManage accessor={item?.key} row={row} controller={controller} />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: item.key === 'action' ? 80 : 150,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width:
            item.key === 'amount_formatted'
              ? 50
              : item.key === 'request_month'
              ? 50
              : item.key === 'request_year'
              ? 50
              : item.key === 'status_formatted'
              ? 50
              : item.key === 'due_date_formatted'
              ? 60
              : 100,
        };
      }
    } else if (kind === 'admin-finance-platformcost') {
      if (item.key === 'action') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <PlatformCostTable accessor={item?.key} row={row} controller={controller} />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: 100,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width: item.key === 'date' ? 70 : 90,
        };
      }
    } else if (kind === 'requested-deal-publishers') {
      if (item.key === 'expand' || item.key === 'excluded' || item.key === 'subRows') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <RequestedDealInventories
                  accessor={item?.key}
                  row={row}
                  controller={controller}
                  dealID={dealID}
                />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: item.key === 'expand' ? 30 : 80,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width: 100,
        };
      }
    } else if (kind === 'ssp-finance-payout') {
      if (item.key === 'action') {
        return {
          Cell: ({ row }: any) => {
            return (
              <>
                <SspFinancePayoutManage accessor={item?.key} row={row} />
              </>
            );
          },
          Header: item.title,
          accessor: item.key,
          width: 50,
        };
      } else {
        return {
          Header: item.title,
          accessor: item.key,
          width: item.key === 'status_formatted' ? 70 : 100,
        };
      }
    }
  });

  // Table Rows Data
  const data = tableRows;

  //SSP Settings Macros Table
  const [editMacrosModal, setEditMacrosModal] = useState(false);
  const [deleteMacrosModal, setDeleteMacrosModal] = useState(false);
  const [macrosData, setMacrosData] = useState();

  const getMacrosData = (data: any) => {
    setMacrosData(data);
  };

  //Remove User & Get Users List Functionality
  const [userShow, sUserShow] = useState(false);
  const [rowId, setRowId] = useState('');

  const removeUser = (id: any) => {
    sUserShow(true);
    setRowId(id);
  };

  return (
    <>
      {/* SSP Macros table Start*/}
      <AddMacros
        show={editMacrosModal}
        onHide={() => setEditMacrosModal(false)}
        rowData={macrosData}
        api={getMacrosList}
      />
      <DeleteMacros
        show={deleteMacrosModal}
        onHide={() => setDeleteMacrosModal(false)}
        data={macrosData}
        api={getMacrosList}
      />
      {/* SSP Macros table End*/}

      {/* Remove User from Settings > Users List Start*/}
      <DeleteUser
        onHide={() => {
          sUserShow(false);
        }}
        show={userShow}
        id={rowId}
        api={getUsers}
      />
      {/* Remove User from Settings > Users List End*/}

      {skeleton ? (
        <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
      ) : data && data?.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          expandedRows={true}
          subRowsKey='subRows'
          // subRowsKey='dsps'
          search={search}
          searchPlaceholder={searchPlaceholder}
          download={download}
          csvName={csvName}
          kind={kind}
          style={styletable}
        />
      ) : (
        <h3 className='text-center mt-5'>Data not found</h3>
      )}
    </>
  );
};

export default ReactTable;
