import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import store from '../../../states';
import { sendEmailAPI } from '../../../services/tags';
import FormikController from '../../comman/form/FormikController';

const SendEmailExtensionValues = {
  endIntegrationInstruction: '',
};

const SendEmail = ({ tagId }: any) => {
  const [glossary, sGlossary] = useState<any>({});

  //GET glossary
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);
  //Extension Form Validation
  const extensionSchema = Yup.object().shape({
    extensionName: Yup.string().required('Enter your extension name.'),
  });

  //Email Validation
  const extensionEamilSendSchema = Yup.object().shape({
    endIntegrationInstruction: Yup.string()
      .email(glossary.ssp_createssai_success_email_valid_error)
      .required(glossary.ssp_createssai_success_email_empty_error),
  });

  //Send Mail Api Call
  const initiateSendEmailExtension = (e: any) => {
    if (tagId) {
      const id = tagId;
      const payload = {
        id,
        email: e.endIntegrationInstruction,
        //ssai_url: tagFinalURLSSAI,
      };
      sendEmailAPI(payload)
        .then((data: any) => {
          if (data) {
            ReactGA.event('ssp_supply_ssai_email_send_response', {
              category: 'ssai',
              action: 'click',
              label: 'ssp_supply_ssai_email_send_response',
            });
            //sMailSent(true);
            toast.success(glossary.ssp_createssai_success_mailsent_msg);
          }
        })
        .catch(() => {
          //
        });
    }
  };

  return (
    <>
      <Formik
        validationSchema={extensionEamilSendSchema}
        initialValues={SendEmailExtensionValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(e) => {
          initiateSendEmailExtension(e);
        }}
      >
        {({ errors, touched }) => (
          <Form className='rtb-form'>
            <div className='d-flex'>
              <div className='form-group'>
                <FormikController
                  kind='withouticon'
                  control='input'
                  type='text'
                  label={glossary.ssp_createssai_success_input_email}
                  placeholder={glossary.ssp_createssai_success_input_placeholder_email}
                  name='endIntegrationInstruction'
                  length={320}
                />
              </div>
              <div className='d-flex flex-column'>
                <label className='label'>&nbsp;</label>
                <button
                  type='submit'
                  className='button button-large button-primary ms-3'
                  // className={
                  //   mailSent
                  //     ? 'button-disabled button button-large button-primary ms-3'
                  //     : 'button button-large button-primary ms-3'
                  // }
                >
                  {glossary.ssp_createssai_success_button_send}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SendEmail;
