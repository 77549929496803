import React, { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from './scrollbarWidth';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { inventoryToggleIncludeAPI, inventoryToggleExcludeAPI } from '../../services/inventory';
import Switch from '../widgets/switch';
import { toast } from 'react-toastify';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import defaultGlobalFilter from '../helpers/DefaultGlobalFilter';
import store from '../../states';
import { GoLinkExternal } from 'react-icons/go';

const Table = ({ columns: userColumns, data, globalFilter, type }: any) => {
  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);
  const filterOptions = { filteredIds: [] };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedRowIds,
    totalColumnsWidth,
    state: { expanded },
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      //defaultColumn,
      getSubRows: (row) => row.subRows || [],
      initialState: {
        //@ts-ignore
        globalFilter,
      },
      globalFilter: (rows: any, columnIds: any, filterValue: any) =>
        defaultGlobalFilter(rows, columnIds, filterValue, filterOptions),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    useFlexLayout
    //useBlockLayout
  );

  React.useEffect(() => {
    setGlobalFilter(globalFilter || undefined);
  }, [globalFilter]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <div {...cell.getCellProps()} className='td' key={index} title={cell.value}>
                <div
                  className={
                    cell?.column?.Header === 'Name/Bundle' ||
                    cell?.column?.Header === 'Inventory ID' ||
                    cell?.column?.Header === 'Platform Type'
                      ? 'macros-truncate'
                      : ''
                  }
                >
                  {cell.render('Cell')}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );

  return (
    <>
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <div
        className={
          type === 'spo-inventories' ? 'spo-inventories table-wrap mt-0' : 'table-wrap mt-0'
        }
      >
        <div {...getTableProps()} className='table inventories'>
          <div>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className='th'
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                        ) : (
                          <IoMdArrowDropup className='svg-icon' fontSize={20} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{ width: '100%', height: `calc(100vh - 380px)`, overflowX: 'hidden' }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </div>
    </>
  );
};

// const handleChildInventories = (inventoriesArr: any) => {
//   const aIdArray: any = [];
//   inventoriesArr.map((inventory: any) => {
//     aIdArray.push(inventory.a_id);
//   });
//   return aIdArray;
// };

const DspInventories = (inventoriesData: any) => {
  const { getTrafficData, type } = inventoriesData;

  // const [toggleBtn, setToggleBtn] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const inventoryToggleInclude = (row: any) => {
    // setToggleBtn(true);
    const payload = {
      a_id: [row.original.a_id],
      a_type: 'inventory',
      r_type: 'end_point',
      r_id: inventoriesData.endpoint_id,
    };
    inventoryToggleIncludeAPI(payload)
      .then((data: any) => {
        if (data.status) {
          // setToggleBtn(false);
          toast.success('Update successfully');
          getTrafficData(inventoriesData.endpoint_id);
        } else {
          // setToggleBtn(false);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const inventoryToggleExclude = (row: any) => {
    const payload = {
      a_id: [row.original.a_id],
      a_type: 'inventory',
      r_type: 'end_point',
      r_id: inventoriesData.endpoint_id,
    };
    inventoryToggleExcludeAPI(payload)
      .then((data: any) => {
        if (data.status) {
          toast.success('Update successfully');
          getTrafficData(inventoriesData.endpoint_id);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const [globalFilter, setGlobalFilter] = React.useState('');
  const columns = useMemo(
    () => [
      {
        Header: `Exclude/
        Include`,
        accessor: 'excluded',
        Cell: ({ row }: any) => (
          <>
            <Switch
              api={(chec: any) => {
                chec === true ? inventoryToggleExclude(row) : inventoryToggleInclude(row);
              }}
              status={!row.original.excluded}
            />
          </>
        ),
        width: 70,
      },
      {
        Header: 'Name/Bundle',
        accessor: 'name',
        Cell: ({ row }: any) => (
          <>
            <div className='d-flex align-items-center gap-2'>
              {row?.original?.url && (
                <a href={`http://${row?.original?.url}`} target='_blank' rel='noreferrer'>
                  <GoLinkExternal className='svg-icon' fontSize={16} title={'Redirect URL'} />
                </a>
              )}
               <p className='macros-truncate'>{row?.original?.name}</p>
            </div>
          </>
        ),
        width: 140,
      },
      {
        Header: 'Inventory ID',
        accessor: 'inventory_id',
        width: 160,
      },
      {
        Header: 'Platform Type',
        accessor: 'platform',
        width: 80,
      },
      {
        Header: 'Unique',
        accessor: 'unique',
        width: 100,
      },
      {
        Header: 'Daily Impression',
        accessor: 'impressions',
        width: 110,
      },
      {
        Header: 'Observed CPM',
        accessor: 'avg_cpm',
        width: 90,
      },
    ],
    []
  );

  const data = inventoriesData.rows;

  const onChange = (e: any) => setGlobalFilter(e.target.value);

  return (
    <>
      {data.length > 0 ? (
        <>
          <div className='search-wrap'>
            <input
              value={globalFilter}
              onChange={onChange}
              placeholder={glossary.dsp_dash_traffic_input_field_placeholder_search}
              className='form-control'
              maxLength={32}
            />
          </div>
          <Table
            columns={columns}
            data={data}
            expandedRows={true}
            subRowsKey='subRows'
            dealsInv={inventoriesData.dealsInv}
            globalFilter={globalFilter}
            type={type}
          />
        </>
      ) : (
        <h3 className='text-center mt-5'>Data not found</h3>
      )}
    </>
  );
};

export default DspInventories;
