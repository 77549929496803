import { useEffect, useState, useMemo, useContext } from 'react';
import * as Yup from 'yup';
import Button from '../widgets/button';
import { CSVLink } from 'react-csv';
import { Formik, Form, FieldArray } from 'formik';
import FormikController from '../comman/form/FormikController';
import { Modal } from 'react-bootstrap';
import { UserInfoContext } from '../contexts/userInfoContext';
import { inventoryToggleExcludeAPI, inventoryToggleIncludeAPI } from '../../services/inventory';
import { toast } from 'react-toastify';
import store from '../../states';
import Radio from '../widgets/radio';
import { MdDeleteOutline, MdInfoOutline } from 'react-icons/md';

const BrandSafety = ({ onHide, show, entityType, selectedCardId, apiCallBack }: any) => {
  // Context API
  const { customerInfo }: any = useContext(UserInfoContext);

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [listType, setListType] = useState('whitelist');
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const fileReader = new FileReader();

  const handleBulkUpload = (e: any) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string: any) => {
    const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .split(',')
      .map((header: string) => header.replace(/"/g, ''));
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows
      .map((row: string) => {
        const values = row.split(',').map((value: string) => value.replace(/"/g, ''));
        const obj = csvHeader.reduce((object: any, header: any, index: any) => {
          object[header] = values[index];
          return object;
        }, {});

        // Check if any key has an empty or undefined value
        const hasEmptyValue = Object.values(obj).some(
          (value) => value === undefined || value === ''
        );
        if (!hasEmptyValue) {
          return obj;
        }
        return null;
      })
      .filter((obj: any) => obj !== null);

    setArray(array);
  };

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event: any) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  useMemo(() => {
    setListType('whitelist');
  }, [show]);

  //convert array as per payload structure
  const convertedArray = useMemo(() => {
    return array.map((item: any) => {
      return item;
    });
  }, [array]);

  const handleSubmit = (e: any, customerID: number) => {
    const payload = {
      a_id: e.customer.map((x: any) => x.advertiser_domain),
      a_type: 'advertiser',
      ...((entityType === 'customer' || entityType === 'partner') && {
        customer_id: selectedCardId,
      }),
      ...(entityType === 'tag' && {
        customer_id: customerID,
        r_id: selectedCardId,
        r_type: 'tag',
      }),
      ...(entityType === 'end_point' && {
        customer_id: customerID,
        r_id: selectedCardId,
        r_type: 'end_point',
      }),
    };

    setLoadingBtn(true);
    if (listType === 'whitelist') {
      inventoryToggleExcludeAPI(payload)
        .then((data: any) => {
          if (data.status) {
            apiCallBack();
            onHide();
            setArray([]);
            toast.success(glossary.brand_safety_whitelist_success_msg);
          } else {
            setArray([]);
            toast.error('Something went wrong, please try after later.');
          }

          setLoadingBtn(false);
        })
        .catch((error: any) => {
          //ok
        });
    } else {
      inventoryToggleIncludeAPI(payload)
        .then((data: any) => {
          if (data.status) {
            apiCallBack();
            onHide();
            setArray([]);
            toast.success(glossary.brand_safety_blacklist_success_msg);
          } else {
            setArray([]);
            toast.error('Something went wrong, please try after later.');
          }
          setLoadingBtn(false);
        })
        .catch((error: any) => {
          //
        });
    }
  };

  const validationSchema = Yup.object().shape({
    customer: Yup.array().of(
      Yup.object().shape({
        advertiser_domain: Yup.string()
          .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})$/,
            'Enter a valid domain'
          )
          .required('Enter advertiser domain'),
      })
    ),
  });

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
          setArray([]);
        }}
        centered
        size='lg'
      >
        <Modal.Header>
          <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
            {glossary.brand_safety_modal_title}
          </h3>
        </Modal.Header>
        {/* Your JSX code for the table */}
        <Formik
          initialValues={{ customer: convertedArray }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(e) => {
            handleSubmit(e, customerInfo?.id);
          }}
        >
          {({ values }) => (
            <>
              <Form>
                <Modal.Body>
                  {values.customer.length === 0 ? (
                    <div className='d-flex flex-column py-5 align-items-center'>
                      <h4>{glossary.brand_safety_modal_inst_heading}</h4>
                      <p style={{ color: '#a2a2a2' }}>{glossary.brand_safety_modal_inst_one}</p>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className='d-flex align-items-center pb-1'>
                          <Radio
                            label={glossary.brand_safety_radio_label_whitelist}
                            size={'mini'}
                            id={'whitelist'}
                            selected={listType}
                            click={setListType}
                          />
                          <MdInfoOutline
                            fontSize={18}
                            title={glossary.brand_safety_whitelist_inst}
                            className='me-3 svg-icon'
                          />
                          <Radio
                            label={glossary.brand_safety_radio_label_blacklist}
                            size={'mini'}
                            id={'blacklist'}
                            selected={listType}
                            click={setListType}
                          />
                          <MdInfoOutline
                            fontSize={18}
                            title={glossary.brand_safety_blacklist_inst}
                            className='svg-icon'
                          />
                        </div>
                        <div
                          className='d-flex align-items-center'
                          style={{ height: '35px', backgroundColor: '#e7e7e7' }}
                        >
                          <strong className='col-10 ps-2'>
                            {glossary.brand_safety_list_header_adv_domain}
                          </strong>
                          <strong className='col-10 ps-5'>
                            {glossary.brand_safety_list_header_remove}
                          </strong>
                        </div>
                      </div>
                      <div
                        className='mt-3'
                        style={{ maxHeight: `calc(100vh - 300px)`, overflowY: 'auto' }}
                      >
                        <FieldArray name='customer'>
                          {({ remove, push }) => (
                            <div>
                              {values.customer.map((customer, customerIndex) => (
                                <div className='row mb-3' key={customerIndex}>
                                  <div className='d-flex gap-3'>
                                    <div className='col'>
                                      <FormikController
                                        type='text'
                                        control='input'
                                        kind='withouticon'
                                        name={`customer[${customerIndex}].advertiser_domain`}
                                        maxLength={256}
                                      />
                                    </div>
                                    <div className='mt-1'>
                                      <button
                                        className='button button-error'
                                        type={'button'}
                                        onClick={() => remove(customerIndex)}
                                      >
                                        <MdDeleteOutline
                                          fontSize={20}
                                          onClick={() => remove(customerIndex)}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer className='px-3 gap-2'>
                  <Button
                    type='button'
                    kind={'secondary'}
                    customClass={'float-end'}
                    text={glossary.brand_safety_button_close}
                    click={() => {
                      onHide();
                      setArray([]);
                    }}
                  />
                  {!loadingBtn ? (
                    <Button
                      type='submit'
                      kind={values?.customer?.length > 0 ? 'primary' : 'disabled-secondary'}
                      customClass={'float-end'}
                      text={glossary.brand_safety_button_apply}
                    />
                  ) : (
                    <Button
                      type='button'
                      kind={'spinner'}
                      text={glossary.brand_safety_loading_button}
                    />
                  )}
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
        <div className='position-absolute' style={{ left: '15px', bottom: '15px' }}>
          <div className='d-flex align-items-center gap-3'>
            {/* <p className='fw-lighter'>{'Bulk Upload'}</p> */}
            <div className='upload-input'>
              <input
                type='file'
                id='csvFileInput'
                accept='.csv'
                onChange={handleBulkUpload}
                className='button'
              />
              <Button
                type='button'
                kind='secondary'
                text={glossary.brand_safety_button_upload_csv}
              />
            </div>
            <CSVLink
              filename={`SampleCSV.csv`}
              headers={[{ key: 'advertiser_domain', label: 'advertiser_domain' }]}
              data={[
                { advertiser_domain: 'www.example101.xyz' },
                { advertiser_domain: 'www.example102.xyz' },
                { advertiser_domain: 'www.example103.xyz' },
                { advertiser_domain: 'www.example104.xyz' },
                { advertiser_domain: 'www.example105.xyz' },
              ]}
            >
              <Button type='button' kind='primary' text={glossary.brand_safety_button_sample_csv} />
            </CSVLink>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BrandSafety;
