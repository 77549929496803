import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from '../widgets/button';
import { demandAdsTxtRemoveAPI } from '../../services/settings';
import store from '../../states';

export default ({ api, onHide, show, id }: any) => {

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const handeleDelete = (id: number) => {
    const payload = {
        adstxt_id: id,
    };
    demandAdsTxtRemoveAPI(payload).then((data: any) => {
    if (data.status === true) {
        toast.success(data.message);
        api();
    } else {
        toast.error(data.message);
    }
    });
  };

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2 style={{ margin: 10 }}>{glossary.dsp_setting_adstxt_remove_popup_title}</h2>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        {glossary.dsp_setting_adstxt_remove_popup_body}
      </Modal.Body>
      <Modal.Footer>
        <Button
          kind={'secondary'}
          text={glossary.setting_user_remove_popup_button_cancel}
          click={() => {
            onHide();
          }}
        />
        <Button
          kind={'primary'}
          text={glossary.setting_user_remove_popup_button_delete}
          click={() => {
            handeleDelete(id);
            onHide();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
