import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Button from './button';

import store from '../../states';
import AdminHeader from '../header/Admin';
import DemandHeader from '../header/Demand';
import PublisherHeader from '../header/Publisher';
import CreativeHeader from '../header/Creative';

import logo from '../../assets/images/logo.svg';

import { cretivesDomain } from '../helpers/functions';
import { GenuinIcon } from '../../assets/icons/genuin-logo';

export default () => {
  const location = useLocation();

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const customerType = localStorage.getItem('customer_type');

  const hasCreatives = cretivesDomain();

  return (
    <>
      {/* Admin header*/}
      {location.pathname !== '/login-password' && localStorage.getItem('r') === 'infy-admin' && (
        <AdminHeader />
      )}

      {/* Demand header*/}
      {location.pathname !== '/login-password' &&
        (customerType === 'demand' || customerType === 'spo') && <DemandHeader />}

      {/* Publisher header*/}
      {location.pathname !== '/login-password' &&
        (customerType === 'publisher' || customerType === 'supply') && <PublisherHeader />}

      {/* Creative header */}
      {location.pathname !== '/login-password' && customerType === 'creative' && <CreativeHeader />}

      {/* Login and Signup header*/}
      {(location.pathname.startsWith('/login') || location.pathname.startsWith('/signup')) && (
        <div className={'header'}>
          <div
            className={'header-menu d-flex justify-content-between align-items-center my-0 w-auto'}
          >
            <div className={'col col-auto header__left'}>
              <Link to='/login'>
                {hasCreatives ? (
                  // <img
                  //   src={'https://i.postimg.cc/prSWyYwS/logo-1.png'}
                  //   alt={'genuin-logo'}
                  //   height='30px'
                  //   className='my-2' // temporary for set height of bar
                  // />
                  <GenuinIcon.icon color='genuin' width={36} height={36} />
                ) : (
                  <img src={logo} alt={'infy-tv-logo'} height='50px' width='150px' />
                )}
              </Link>
            </div>
            <div className={'col col-auto header__right d-none d-md-block'}>
              <div className={'d-flex align-items-center'}>
                {/* Login and signup start*/}
                {location.pathname === '/signup' && (
                  <>
                    <p className='pe-2'>{glossary.signup_header_text}</p>
                    <Link to='/login'>
                      <Button
                        kind={'primary'}
                        text={glossary.signup_header_button}
                        click={() => {
                          ReactGA.event({
                            category: 'login',
                            action: 'click',
                            label: 'header_login_button',
                          });
                        }}
                      />
                    </Link>
                  </>
                )}
                {!hasCreatives &&
                  (location.pathname === '/login' || location.pathname === '/login-password') && (
                    <>
                      <p className='pe-2'>{glossary.login_header_text}</p>
                      <Link to='/signup'>
                        <Button
                          kind={'primary'}
                          text={glossary.login_header_create_account_button}
                          click={() => {
                            ReactGA.event({
                              category: 'signup',
                              action: 'click',
                              label: 'header_create_account_button',
                            });
                          }}
                        />
                      </Link>
                    </>
                  )}
                {/* Login and signup end*/}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
