import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from '../widgets/button';
import Card from '../widgets/card';
import Pill from '../widgets/pill';

import { ReactComponent as SizeIcon } from '../../assets/icons/size.svg';
import { ReactComponent as CompactIcon } from '../../assets/icons/compact.svg';

import CardLoader from '../loaders/card';
import store from '../../states';

import { MdSearch } from 'react-icons/md';
import { IoIosArrowForward, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Sorting from '../widgets/sorting';
import { FixedSizeList } from 'react-window';
import ButtonSkeleton from '../loaders/button';
import TableSkeleton from '../loaders/table';

interface Action {
  icon: string;
  text: string;
  click(): void;
  className?: string;
}

interface Props {
  title: string;
  kind: 'customer';
  size: 'collapsed' | 'expanded';
  card: 'collapsed' | 'expanded';
  action: Action | undefined;
  items: any[];
  sidebar?(size: string): void;
  info?(card: string): void;
  empty?: boolean;
  selectCardInfo?(item: any): void;
  selected?: string;
  currentPage?: any;
  loading?: boolean;
  hasMore?: boolean;
  params?: any;
  handleParams?: any;
  allData?: boolean;
}

const AdminSidebar = (props: Props) => {
  const {
    title,
    kind,
    size,
    card,
    action,
    items,
    sidebar,
    info,
    selectCardInfo,
    currentPage,
    loading,
    hasMore,
    params,
    handleParams,
    allData,
  } = props;

  const [selected, sSelected] = useState(props.selected);
  const [sideSize, sSideSize] = useState(size);
  const [cardSize, sCardSize] = useState(card);
  const [contextMenu, sContextMenu] = useState(false);
  // const [sortIcon, sSortIcon] = useState(sortDescendingIcon);
  const [displayItems, sDisplayItems] = useState(items);
  const [searchBar, sSearchBar] = useState(false);
  const [expandSearchBar, sExpandSearchBar] = useState(false);
  const [customerFilterSection, sCustomerFilterSection] = useState('1');
  const [searchContent, sSearchContent] = useState('');
  const [glossary, sGlossary] = useState<any>({});
  const [crSrt, sCrSrt] = useState(params.sortBy);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, [glossary]);

  const toggleFilterMenu = (e: any) => {
    const allow = document.getElementById(`filter-menu`);
    const allow2 = document.getElementById(`sort-menu`);
    const allow3 = document.getElementById(`filter-menu-main`);
    const allow4 = document.getElementById(`filter-menu-main-1`);
    const target = e.target;

    if (target === allow || target === allow2 || target === allow3 || target === allow4) {
      sContextMenu(true);
    } else {
      sContextMenu(false);
    }
  };

  useEffect(() => {
    if (props.selected) sSelected(props.selected);
  }, [props.selected]);

  const select = (id: string, item?: any) => {
    sSelected(id);
    if (info) info(id);
    if (selectCardInfo) selectCardInfo(item);
  };

  const toggleSearchBar = (e: Event) => {
    const allow1 = document.getElementById(`search-bar`);
    const allow3 = document.getElementById(`search-bar-expand`);
    const allow2 = document.getElementById(`search-btn`);
    const allow4 = document.getElementById(`search-btn-expand`);
    const target = e.target;
    if (target === allow1 || target === allow2 || target === allow3 || target === allow4) {
      //
    } else {
      sSearchBar(false);
      sExpandSearchBar(false);
    }
  };

  const sorter = (key: any, desc: boolean) => {
    if (key === crSrt.id && (desc || !desc)) {
      sCrSrt({
        id: key,
        desc: desc,
      });
    } else {
      sCrSrt({
        id: key,
        desc: desc,
      });
    }
  };

  useEffect(() => {
    sSideSize(size);
  }, [size]);

  useEffect(() => {
    document.addEventListener('click', toggleSearchBar, false);
    document.addEventListener('click', toggleFilterMenu, false);
    return () => {
      document.removeEventListener('click', toggleSearchBar, false);
      document.removeEventListener('click', toggleFilterMenu, false);
    };
  }, []);

  useMemo(() => {
    let filterCustomers: any = [];

    switch (customerFilterSection) {
      case '1':
        filterCustomers = items?.filter((x: any) =>
          ['supply', 'publisher'].includes(x?.customer_type)
        );
        break;
      case '2':
        filterCustomers = items?.filter((x: any) => x?.customer_type === 'demand');
        break;
      case '3':
        filterCustomers = items?.filter((x: any) => x?.customer_type === 'spo');
        break;
      case '4':
        filterCustomers = items?.filter((x: any) => x?.customer_type === 'creative');
        break;
      default:
        break;
    }

    if (filterCustomers.length > 0 && customerFilterSection) {
      sDisplayItems(filterCustomers);
      select(props?.selected ? props?.selected : filterCustomers[0]?.id);
    } else {
      sDisplayItems([]);
    }
  }, [customerFilterSection, items, props?.selected]);

  const [expand, setExpand] = useState(false);

  const handleSidebar = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  useEffect(() => {
    if (searchContent !== params.query) {
      const getData: any = setTimeout(() => {
        handleParams(0, searchContent, params.sortBy);
        sDisplayItems([]);
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchContent, params.sortBy]);

  /***** Handle Sorting Start *****/
  const handleSort = (sort: any) => {
    if (sort) {
      sCrSrt(sort);
      handleParams(0, params.query, sort);
      sDisplayItems([]);
    }
  };

  /***** Data Render on scroll *****/
  const memoizedRenderCard = useMemo(() => {
    // Define your renderCard function here
    const renderCard = ({ index, style }: any) => {
      const item = displayItems && displayItems[index];
      // Your card rendering logic here
      // You can access any relevant props or variables here
      // For example: displayItems[index]
      return (
        <div style={style}>
          <Card
            id={item.id}
            key={index}
            text={item.company_name}
            kind={kind}
            size={cardSize}
            info={{
              items: item,
              type: item.customer_type ?? item.tag_type ?? '',
              traffic: item.traffic,
              thumb: item.thumb,
              created: item.created_on,
              impressions: item.impression,
              fillRate: item.fill_rate,
              earning: item.earning,
              status: item.status,
            }}
            selected={selected ?? ''}
            click={() => select(item.id, item)}
            expandClass={expand} //add class name when open sidebar
            allData={allData}
          />
        </div>
      );
    };

    return renderCard;
  }, [
    displayItems,
    // kind,
    cardSize,
    selected,
    expand,
    allData,
    /* List of dependencies that should trigger a re-compute */
  ]);

  const lastItem = displayItems[displayItems.length - 1];
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (
        params.page === currentPage &&
        hasMore &&
        visibleStopIndex === displayItems.length - 1 &&
        lastItem
      ) {
        handleParams(params.page + 1, params.query, params.sortBy);
      }
    },
    [params.page, params.query, params.sortBy, displayItems.length, lastItem]
  );

  return (
    <>
      <div className={expand ? 'newsidebar open' : 'newsidebar'}>
        <div className={'sidebar-row sidebar-row-first'}>
          {!searchBar && !expand && (
            <div className={'sidebar-col'}>
              <h3>{title}</h3>
            </div>
          )}
          {expand && (
            <div className={'sidebar-col'}>
              <h3>{title}</h3>
            </div>
          )}
          <div className={'sidebar-col'}>
            <div className={'sidebar-row'}>
              {searchBar && (
                <div className={'sidebar-col'}>
                  <input
                    autoFocus
                    onChange={(e) => {
                      if (e.target.value === '') {
                        handleParams(0, '', params.sortBy);
                        sDisplayItems([]);
                      }
                      sSearchContent(e.target.value);
                    }}
                    id={'search-bar'}
                    value={searchContent}
                    type={'text'}
                    placeholder={glossary.admin_sidebar_search_placeholder}
                    className='search-input ps-0'
                    maxLength={32}
                    style={{
                      width: '245px',
                      maxWidth: '245px',
                      height: '35px',
                      marginBottom: '5px',
                    }}
                  />
                </div>
              )}
              <div className={'sidebar-col'}>
                <MdSearch
                  className='svg-icon'
                  id={'search-btn'}
                  onClick={() => {
                    sSearchBar(searchBar ? false : true);
                  }}
                  title={'Search'}
                  fontSize={20}
                  style={{ marginLeft: 8, marginRight: 5 }}
                />
              </div>
              {/* {!searchBar && ( */}
              <div className={!searchBar ? 'sidebar-col' : 'd-none'}>
                <Sorting kind={'customer'} sortBy={handleSort} sortedBy={crSrt} />
              </div>
              {/* )} */}

              {!searchBar && (
                <>
                  <div
                    className={'sidebar-col compact-card'}
                    onClick={() => sCardSize(cardSize === 'collapsed' ? 'expanded' : 'collapsed')}
                  >
                    {cardSize === 'expanded' ? (
                      <SizeIcon
                        className='svg-icon'
                        width={20}
                        height={20}
                        title='Collapse'
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    ) : (
                      <CompactIcon
                        className='svg-icon'
                        width={20}
                        height={20}
                        title='Expand'
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    )}
                  </div>
                </>
              )}

              {displayItems?.length > 0 && (
                <div className='sidebar-col toggle ms-1' onClick={handleSidebar}>
                  <IoIosArrowForward fontSize={18} className='svg-icon' />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={'sidebar-row sidebar-row-secound admin'}>
          <div className={'sidebar-col'} id='first-btn'>
            {action && (
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                {params.page === 0 && loading ? (
                  <div className='invite-loader'>
                    <ButtonSkeleton height={38} width={120} />
                  </div>
                ) : (
                  <Button
                    kind={'primary'}
                    icon={action.icon}
                    text={action.text}
                    click={action.click}
                    customClass={action.className}
                  />
                )}
              </div>
            )}
          </div>
          <div className={'sidebar-col'}>
            {params.page === 0 && loading ? (
              <ButtonSkeleton height={40} width={160} />
            ) : (
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Pill
                  items={[
                    { id: '1', label: glossary.admin_sidebar_tab_button_publisher },
                    { id: '2', label: glossary.admin_sidebar_tab_button_demand },
                    { id: '3', label: glossary.admin_sidebar_tab_button_spo },
                    { id: '4', label: glossary.admin_sidebar_tab_button_creative },
                  ]}
                  select={sCustomerFilterSection}
                  selected={customerFilterSection}
                />
              </div>
            )}
          </div>
        </div>

        {expand && (
          <div className='sidebar-expand'>
            <div className='sidebar-expand-item title'>
              <div
                onClick={() => sorter('name', !crSrt.desc)}
                className={crSrt.id === 'name' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.admin_sidebar_expand_column_name}
                {crSrt.id === 'name' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'name' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item date'>
              <div
                onClick={() => sorter('created_on', !crSrt.desc)}
                className={crSrt.id === 'created_on' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.admin_sidebar_expand_column_created}
                {crSrt.id === 'created_on' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'created_on' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item impression'>
              <div
                onClick={() => sorter('impression', !crSrt.desc)}
                className={crSrt.id === 'impression' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.admin_sidebar_expand_column_impressions}
                {crSrt.id === 'impression' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'impression' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item earnings'>
              <div
                onClick={() => sorter('earning', !crSrt.desc)}
                className={crSrt.id === 'earning' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.admin_sidebar_expand_column_earnings}
                {crSrt.id === 'earning' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'earning' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item fillrate'>
              <div
                onClick={() => sorter('fill_rate', !crSrt.desc)}
                className={crSrt.id === 'fill_rate' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.admin_sidebar_expand_column_fill_rate}
                {crSrt.id === 'fill_rate' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'fill_rate' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item status'>
              <div
                onClick={() => sorter('status', !crSrt.desc)}
                className={crSrt.id === 'status' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.admin_sidebar_expand_column_status}
                {crSrt.id === 'status' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'status' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div />
          </div>
        )}

        <div className={'sidebar-list'} style={{ minWidth: 300 }}>
          {/***** List of Sidebar Card *****/}
          <FixedSizeList
            height={1000}
            width={300}
            itemCount={displayItems.length}
            itemSize={expand || cardSize === 'collapsed' ? 75 : 130}
            onItemsRendered={handleItemsRendered}
            style={{
              width: '100%',
              height: displayItems.length > 0 ? `calc(100vh - 140px)` : `0px`,
              overflowY: 'auto',
            }}
          >
            {memoizedRenderCard}
          </FixedSizeList>

          {/***** Skeleton View *****/}
          {loading && !expand && (
            <>
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </>
          )}

          {/***** Skeleton View for Expand View *****/}
          {loading && expand && (
            <>
              <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
            </>
          )}

          {/***** When data not found on search *****/}
          {(!displayItems || displayItems.length < 1) && searchContent.length > 0 && !loading && (
            <>{props.empty && <p style={{ textAlign: 'center', padding: 24 }}>No Results</p>}</>
          )}
        </div>
      </div>
      <div className={expand ? 'overlay' : ''} onClick={handleSidebar}></div>
    </>
  );
};

export default AdminSidebar;
