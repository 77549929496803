import { useContext, useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import HeaderBidder from '../../components/headerBidder/create';
import Addrtbendpoint from '../../components/RTB/RTB-endpoint';
import store from '../../../states';
import InsertionOrder from '../../components/insertion-order/InsertionOrder';
import { UserInfoContext } from '../../contexts/userInfoContext';

interface Props {
  endpointStatus?: boolean;
  customerID?: number;
}

const DemandOnboarding = (props: Props) => {
  const { endpointStatus, customerID } = props;

  const { customerInfo }: any = useContext(UserInfoContext);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className={'content on-bording'} style={{ padding: 36 }}>
      <Tabs defaultActiveKey='RTBEndpoint' className='on-bording-tabs'>
        {/* Create RTB Endpoint */}
        <Tab eventKey='RTBEndpoint' title='RTB Endpoint Integration'>
          {/* Start RTB End points form */}
          <div className='row'>
            <div className='col-12'>
              <div
                className={
                  endpointStatus
                    ? 'mb-3 light-shadow rounded-1 p-4'
                    : 'mb-4 light-shadow rounded-1 p-4'
                }
              >
                <h3 className='mb-4' style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                  {glossary.dsp_add_endpoint_title}
                </h3>
                <Addrtbendpoint
                  endpontDataStatus={endpointStatus}
                  customerID={customerID}
                  initialCreate={true}
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey='HeaderBidder' title='Header Bidding'>
          {/* Create Header Bidding */}
          <HeaderBidder
            endpontDataStatus={endpointStatus}
            customerID={customerID}
            initialCreate={true}
          />
        </Tab>
        <Tab eventKey='InsertionOrder' title='Insertion Order'>
          {/* Create Insertion Order */}
          <div className='row'>
            <div className='col-12'>
              <div
                className={
                  endpointStatus
                    ? 'mb-3 light-shadow rounded-1 p-4'
                    : 'mb-4 light-shadow rounded-1 p-4 tour-insertion-order'
                }
              >
                <h3 className='mb-4' style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                  {glossary.dsp_add_io_popup_title}
                </h3>
                <InsertionOrder endpontDataStatus={endpointStatus} customerID={customerID} />
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default DemandOnboarding;
