import { useEffect, useState } from 'react';
import { BsPlayFill } from 'react-icons/bs';
import AdreelPlayer from '../components/video-popup/AdreelPlayer';

export function Cell({
  value: initialValue,
  row: { index, original },
  column: { id, type, options },
  dataDispatch,
  onKeyDownHook,
  genuinInfo,
  handleVideoConfigForm,
}: any) {
  const [value, setValue] = useState({ value: initialValue, update: false });
  const [showPlayer, setShowPlayer] = useState(false);

  const onChange = (e: any) => {
    setValue({ value: e.target.value, update: false });
  };

  const handleVideo = () => {
    setShowPlayer(!showPlayer);
  };

  const onImageError = (e: any) => {
    e.target.src = 'https://i.postimg.cc/tC1GW5fV/default-placeholder.png';
  };

  // Function to remove all occurrences of "http://" or "https://"
  const removeProtocols = (url: string) => {
    return url.replace(/(https?:\/\/)/g, '');
  };

  const validateURL = (url: string) => {
    let newValue = url?.trim();

    // Remove all occurrences of protocols
    newValue = removeProtocols(newValue);

    // Add "https://" only if the string is not empty and doesn't already start with it
    if (newValue !== '' && !newValue.startsWith('https://')) {
      return (newValue = 'https://' + newValue);
    }

    return newValue;
  };

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({ type: 'update_cell', columnId: id, rowIndex: index, value: value.value });
    }
  }, [value, dataDispatch, id, index]);

  // const fieldCharacterLength: number = id === 'cta_text' ? 24 : 60;

  let fieldCharLimit;
  let fieldPlaceholder;

  switch (id) {
    case 'cta_text':
      fieldCharLimit = 24;
      fieldPlaceholder = 'Please enter CTA text not exceeding 24 characters including spaces';
      break;
    case 'cta_link':
      fieldPlaceholder = 'When a user clicks the CTA button, where do you want to land them on?';
      break;
    case 'ad_copy':
      fieldCharLimit = 60;
      fieldPlaceholder =
        'Keep under 30 characters for best representation. Max limit: 60 characters';
      break;
    case 'pixel_link':
      fieldPlaceholder = 'Please put your pixel URL here';
      break;
    default:
      fieldPlaceholder = '';
      break;
  }

  let element;
  switch (type) {
    case 'text':
      element = (
        <textarea
          value={value.value}
          onChange={onChange}
          onBlur={() => setValue((old) => ({ value: old.value, update: true }))}
          style={{
            height: '100px',
            resize: 'none',
          }}
          maxLength={fieldCharLimit}
          placeholder={fieldPlaceholder}
          className='text-input'
          onKeyDown={onKeyDownHook}
        />
      );
      break;
    case 'url':
      element = (
        <textarea
          value={value.value}
          onChange={onChange}
          onBlur={() => {
            // let newValue = value?.value?.trim();
            // if (
            //   !newValue.startsWith('https://') &&
            //   !newValue.startsWith('http://') &&
            //   newValue !== ''
            // ) {
            //   newValue = 'https://' + newValue;
            // }
            // if (newValue === 'https://' || newValue === 'http://') {
            //   newValue = '';
            // }
            // setValue((old) => ({ value: newValue || old.value, update: true }));

            setValue((old) => ({ value: validateURL(value?.value), update: true }));
          }}
          style={{
            height: '100px',
            resize: 'none',
          }}
          placeholder={fieldPlaceholder}
          className='text-input'
          onKeyDown={onKeyDownHook}
        />
      );
      break;
    case 'boolean':
      element = (
        <input
          type='checkbox'
          defaultChecked={value?.value}
          onChange={() => {
            setValue((old) => ({ value: !old.value, update: true }));
            onKeyDownHook();
          }}
        />
      );
      break;
    case 'video':
      element = (
        <>
          <AdreelPlayer
            show={showPlayer}
            onHide={() => setShowPlayer(false)}
            items={original}
            genuinInfo={genuinInfo}
            showConfigure={true}
            configFormSubmit={(rowData: any) => {
              dataDispatch({ type: 'update_row', value: rowData });
              handleVideoConfigForm();
            }}
          />

          <div className='d-flex align-items-center'>
            <div className='video-col'>
              <BsPlayFill
                className='play-icon'
                fontSize={26}
                color='#fff'
                onClick={() => {
                  handleVideo();
                }}
              />
              <span className='duration'>{original?.duration}</span>
              <img
                src={original?.video_thumbnail_s}
                alt={original?.video_thumbnail_s}
                height={130}
                width={80}
                onClick={() => {
                  handleVideo();
                }}
                onError={onImageError}
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>

            <div className='ms-2'>
              <label style={{ color: '#a2a2a2', fontSize: '14px', lineHeight: '16px' }}>
                Added On:
              </label>
              <p style={{ fontSize: '14px', lineHeight: '16px' }}>{original?.import_date}</p>
              <br />
              <label style={{ color: '#a2a2a2', fontSize: '14px', lineHeight: '16px' }}>
                Added From:
              </label>
              <p style={{ fontSize: '14px', lineHeight: '16px' }}>{original?.source}</p>
            </div>
          </div>
        </>
      );
      break;
    default:
      element = <span></span>;
      break;
  }

  return element;
}

export const HashtagCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  dataDispatch,
  onKeyDownHook,
}: any) => {
  // We need to keep and update the state of the cell normally
  const [inputText, setInputText] = useState(initialValue?.join(' '));
  const [resultText, setResultText] = useState('');

  const processInput = (text: string) => {
    const tags = text.split(' ')?.filter((tag: string) => tag !== '');
    const filteredTags = tags?.filter((tag: string) =>
      tag === '#' ? tags?.indexOf(tag) < 4 : true
    );
    return filteredTags?.join(' ');
  };

  const handleInputChange = (event: any) => {
    const newText = event?.target?.value;

    if (newText?.includes('  ')) {
      return;
    }

    if (newText?.split('#').length > 4) {
      return;
    }

    if (newText?.endsWith(' ') && newText?.split('#')?.length === 4) {
      return;
    }

    if (!newText?.startsWith('#')) {
      return;
    }

    setInputText(newText);
    const processedText = processInput(newText);
    setResultText(processedText);
  };

  const handleKeyDown = (event: any) => {
    onKeyDownHook();
    if (event?.key === 'Enter') {
      event?.preventDefault();
    }

    if (event?.key === 'Backspace' && inputText?.endsWith('#')) {
      setInputText(inputText?.slice(0, -1));
      const processedText = processInput(inputText?.slice(0, -1));
      setResultText(processedText);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    dataDispatch({
      type: 'update_cell',
      columnId: id,
      rowIndex: index,
      value: inputText?.length > 0 ? inputText?.split(' ') : [],
    });
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setInputText(initialValue?.join(' '));
  }, [initialValue]);

  return (
    <textarea
      key={index}
      className='text-input'
      placeholder={'Up to 3 hashtags separated by spaces which you want to go with the video'}
      value={inputText ?? ''}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      style={{
        height: '100px',
        resize: 'none',
      }}
    />
  );
};
