import { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import JWT from 'jsonwebtoken';

import Button from '../../widgets/button';
import User from '../../modals/user';
import store from '../../../states';

import { routes } from '../../../services/constants';
import { useLocation } from 'react-router-dom';
// import { getCustomerUsersAPI } from '../../../services/customers';
import InfyTableComponent from '../../comman/table/InfyTableComponent';
import { UserInfoContext } from '../../contexts/userInfoContext';

export default ({ toast /*store*/ }: any) => {
  // Context API
  const { userInfo }: any = useContext(UserInfoContext);

  const { pathname } = useLocation();

  const [userModal, sUserModal] = useState(false);
  const [customerUsers, sCustomerUsers] = useState({});
  const [userRole, sUserRole] = useState();
  const [apiPath, sApiPath] = useState('');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const getUsers = (data: any) => {
    sCustomerUsers(data);
    // getCustomerUsersAPI()
    //   .then((data: any) => {
    //     sCustomerUsers(data);
    //   })
    //   .catch((error) => {
    //     // toast.error('Service unavailable, Check back after sometime.');
    //   });
  };

  useEffect(() => {
    //getUsers();
    const token = localStorage.getItem('token') || '';
    const decoded: any = JWT.decode(token, { complete: true });

    if (userInfo?.user?.role_id || decoded?.payload?.role) {
      sUserRole(userInfo?.user?.role_id || decoded?.payload?.role);
    }
  }, [userInfo]);

  useEffect(() => {
    if (pathname === '/settings/user') {
      sApiPath(routes.getCustomerUsers);
    }
  }, [pathname, routes]);

  return (
    <>
      <User
        toast={toast}
        getUsers={getUsers}
        show={userModal}
        onHide={() => {
          sUserModal(false);
        }}
        userRole={userRole}
      />
      <div className={'row'} style={{ justifyContent: 'space-between' }}>
        <div className={'col'}>
          <h2 style={{ fontWeight: 200 }}>{glossary.setting_users_title}</h2>
        </div>
        {userRole === 6 || localStorage.getItem('role_id') === '2' ? (
          <p></p>
        ) : (
          <div className='col align-items-end'>
            <Button
              kind={'primary'}
              text={glossary.setting_users_button_invite}
              click={() => {
                sUserModal(true);
                ReactGA.event('sidebar_users_invite_button', {
                  category: 'invite',
                  action: 'click',
                  label: 'invite_button_click',
                });
              }}
            />
          </div>
        )}
      </div>
      <div className={'row table__responsive'}>
        <InfyTableComponent
          columnWidth={130}
          api={apiPath}
          method={'GET'}
          tableName='Admin User'
          downloadCSV={false}
          search={true}
          insertNewData={customerUsers}
          insertIndex={1}
          searchPlaceholder={glossary.setting_users_search_placeholder}
        />
      </div>
    </>
  );
};
