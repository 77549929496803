import { useState } from 'react';
import ContentLoader from 'react-content-loader';

import Bar from '../charts/bar';

const Loader = (props: any) => {
  const { empty } = props;
  const [show, sShow] = useState(false);
  return (
    <>
      {empty ? (
        <div
          style={{ display: 'flex', columnGap: 45, minHeight: 300 }}
          onMouseEnter={() => sShow(true)}
          onMouseLeave={() => sShow(false)}
        >
          {show ? (
            <Bar
              data={{
                labels: [
                  'Your Advert Tag',
                  'Your Advert Tag',
                  'Your Advert Tag',
                  'Your Advert Tag',
                  'Your Advert Tag',
                  'Your Advert Tag',
                ],
                values: [40, 35, 30, 25, 20, 15],
              }}
            />
          ) : (
            <ContentLoader
              title=''
              speed={0}
              width={'100%'}
              height={300}
              viewBox='0 0 460 250'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
              {...props}
            >
              <rect x='9' y='25' rx='0' ry='0' width='90' height='5' />
              <rect x='106' y='18' rx='0' ry='0' width='338' height='20' />
              <rect x='10' y='64' rx='0' ry='0' width='90' height='5' />
              <rect x='107' y='57' rx='0' ry='0' width='302' height='20' />
              <rect x='10' y='104' rx='0' ry='0' width='90' height='5' />
              <rect x='107' y='97' rx='0' ry='0' width='260' height='20' />
              <rect x='10' y='144' rx='0' ry='0' width='90' height='5' />
              <rect x='107' y='137' rx='0' ry='0' width='220' height='20' />
              <rect x='10' y='184' rx='0' ry='0' width='90' height='5' />
              <rect x='107' y='177' rx='0' ry='0' width='180' height='20' />
              <rect x='10' y='224' rx='0' ry='0' width='90' height='5' />
              <rect x='107' y='217' rx='0' ry='0' width='140' height='20' />
            </ContentLoader>
          )}
        </div>
      ) : (
        <ContentLoader
          title=''
          speed={0}
          width={460}
          height={250}
          viewBox='0 0 460 250'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          {...props}
        >
          <rect x='9' y='25' rx='0' ry='0' width='90' height='5' />
          <rect x='106' y='18' rx='0' ry='0' width='338' height='20' />
          <rect x='10' y='64' rx='0' ry='0' width='90' height='5' />
          <rect x='107' y='57' rx='0' ry='0' width='302' height='20' />
          <rect x='10' y='104' rx='0' ry='0' width='90' height='5' />
          <rect x='107' y='97' rx='0' ry='0' width='260' height='20' />
          <rect x='10' y='144' rx='0' ry='0' width='90' height='5' />
          <rect x='107' y='137' rx='0' ry='0' width='220' height='20' />
          <rect x='10' y='184' rx='0' ry='0' width='90' height='5' />
          <rect x='107' y='177' rx='0' ry='0' width='180' height='20' />
          <rect x='10' y='224' rx='0' ry='0' width='90' height='5' />
          <rect x='107' y='217' rx='0' ry='0' width='140' height='20' />
        </ContentLoader>
      )}
    </>
  );
};

export default Loader;
